
type TSettings = Readonly<{
    templatePath: string;
    transactionsPath: string;
    transactionsTemplate: string;
    referralTemplate: string;
}>;

type TGetPathImage = (
    imageId?: string,
    width?: number,
    height?: number,
    crop?: string,
    gravity?: string,
    radius?: string,
    effect?: string,
) => string;

const cloudaryUrl = 'https://res.cloudinary.com/myreferralagent';

export const transactionsTemplate: string =
    `${cloudaryUrl}/raw/upload/My-Referral-Agent-Transaction-Log.xls`;
    
const referralTemplate: string =
    'http://res.cloudinary.com/myreferralagent/raw/upload/Sample-Referral-Agreement_kxwxcr.pdf';

const cloudinarySettings: TSettings = {
    templatePath: `${cloudaryUrl}/image/upload/`,
    transactionsPath: `${cloudaryUrl}/raw/upload/`,
    transactionsTemplate,
    referralTemplate,
};

const argNames = ['w', 'h', 'c', 'g', 'r', 'e'];

export const getPathImage: TGetPathImage = (imageId, ...rest) => {
    const { templatePath } = cloudinarySettings;

    const params: string = rest.map(
        (value, index) => `${argNames[index]}_${value}`,
    ).join(',');
    
    return `${templatePath}${params}/${imageId}.png`;
};
