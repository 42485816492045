import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthenticatedNavBar from './AuthenticatedNavBar';
import { FooterCommon } from '../components/FooterCommon';
import { Dashboard } from './Dashboard';
import { AgentProfile } from './AgentProfile/AgentProfile';
import { NewReferralOutbound } from './NewReferralOutbound/NewReferralOutbound';
import { Logout } from './Logout';
import { PaymentResult } from './AgentProfile/PaymentResult';
import { AgentProfilePublic } from './AgentProfile/AgentProfilePublic';
import Modal from 'react-modal';
import { WaitContainer } from '../components/WaitContainer';
import { toast } from 'react-toastify';
import { postSupportEmail } from '../utils/api';
import { Page404 } from '../components/Page404/Page404';
import { ReferralTimeline } from './ReferralTimeline/ReferralTimeline';
import { getDeepLink, resetDeepLink } from '../utils/deep-link';

type Props = {
    mainLogout: () => void;
}
const Authenticated: React.FunctionComponent<Props> = ({ mainLogout }) => {

    const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);
    const onOpenSupport = () => {
        setIsSupportModalOpen(true);
    }
    const [supportRequestText, setSupportRequestText] = useState<string>('');
    const sendSupportRequest = () => {
        console.log("Support Text: ", supportRequestText);
        setIsSendingSupportMessage(true);

        postSupportEmail({ message: supportRequestText })
            .then(() => {
                toast.info("Thank you for contacting support. We will reach out very soon!");
                setIsSendingSupportMessage(false);
                setIsSupportModalOpen(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const [isSendingSupportMessage, setIsSendingSupportMessage] = useState<boolean>(false);

    return <>
        <Modal
            isOpen={isSupportModalOpen}
            contentLabel={`My Referral Agent - Quick Support`}
            style={{
                overlay: {
                    zIndex: 1000,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                },
                content: {
                    top: '15%',
                    margin: '0 auto',
                    width: '800px',
                    height: '500px',
                    borderRadius: '16px',
                    overflow: 'none',
                },
            }}
            ariaHideApp={false}
        >
            <div className="flex flex-1 h-[90%] flex-col gap-[20px] p-[20px]">
                <div className="flex flex-row gap-[20px]">
                    <h1 className="flex flex-1 items-center justify-start">{`My Referral Agent - Quick Support`}</h1>
                    <div className="items-center align-middle">
                        <button
                            disabled={isSendingSupportMessage}
                            className="items-center justify-center cursor-pointer w-[100px] [border:none] bg-brand-red rounded-md h-10 box-border gap-[10px]"
                            onClick={() => {
                                setIsSupportModalOpen(false);
                                setSupportRequestText('');
                                setIsSendingSupportMessage(false);
                            }}
                        >
                            <div className="relative text-sm leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center">
                                Close
                            </div>
                        </button>
                    </div>
                </div>
                <div className="flex flex-1 h-[85%] flex-col gap-[30px]">
                    <WaitContainer shouldWaitIf={isSendingSupportMessage} >
                        <div className="flex flex-1 self-stretch w-full ">
                            <textarea
                                className='font-regular w-full resize-none overflow-y-auto text-xl bg-light-background-background-1 rounded-[5px] p-[30px] items-center justify-start text-left border-[1px] border-solid border-gray-400'
                                rows={5}
                                cols={100}
                                maxLength={500}
                                onKeyUp={(e) => setSupportRequestText(e.currentTarget.value)}
                                onBlur={(e) => setSupportRequestText(e.currentTarget.value)}
                                placeholder='Type your message here and we will respond as soon as we can ... '
                            />
                        </div>
                        <div className="flex w-full self-stretch items-center justify-end">
                            <button
                                className="items-center justify-center cursor-pointer w-[200px] [border:none] bg-brand-secondary rounded-md h-10 box-border gap-[10px]"
                                onClick={sendSupportRequest}
                            >
                                <div
                                    className="relative text-sm leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center"
                                    
                                >
                                    Send Message
                                </div>
                            </button>
                        </div>
                    </WaitContainer>
                </div>
            </div>
        </Modal>

        <div className='flex flex-col flex-grow'>

           <AuthenticatedNavBar mainLogout={mainLogout}  />

            <div className="h-[88px]" />

            <Routes>
                <Route path='*' element={<Page404 />} />
                <Route path='/' element={<Dashboard />} />
                <Route path='/pslink' element={<Dashboard />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/referral' element={<NewReferralOutbound />} />
                <Route path='/referral/:newDirectReferralAgentId' element={<NewReferralOutbound />} />
                <Route path='/referrals/:referralId' element={<ReferralTimeline />} />
                <Route path='/pay/verdict/:verdict/:checkoutSessionId' element={<PaymentResult />} />
                <Route path='/pay/verdict/:verdict' element={<PaymentResult />} />
                <Route path='/profile' element={<AgentProfile />} />
                <Route path='/members/:profileId' element={<AgentProfilePublic />} />                
            </Routes>

            <div className="h-[25px]" />            
            <FooterCommon onOpenSupport={onOpenSupport} />
        </div>
    </>
};

export { Authenticated };
