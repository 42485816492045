import React, { FunctionComponent, RefObject } from "react";
import { NewReferralFormStoreType } from "../NewReferralOutbound";

type InviteNonMemberProps = {
    formDataRef: RefObject<NewReferralFormStoreType>;
    onChangeNonMraInviteeFirstName: (any) => void;
    onChangeNonMraInviteeLastName: (any) => void;
    onChangeNonMraInviteePhone: (any) => void;
    onChangeNonMraInviteeEmail: (any) => void;
    onEmailKeyUp: (any) => void;
};

const InviteNonMember: FunctionComponent<InviteNonMemberProps> = ({
    formDataRef,
    onChangeNonMraInviteeFirstName,
    onChangeNonMraInviteeLastName,
    onChangeNonMraInviteePhone,
    onChangeNonMraInviteeEmail,
    onEmailKeyUp,
}) => {

    const formDataStep = formDataRef.current?.form.candidateSelection.nonMraCandidates[0];
    console.log("Step5-NonMRA: FormDataStep: ", formDataStep);

    const ensurePhoneFormatInline = (event): void => {
        const { value } = event.target;
        const formattedValue = value.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        event.target.value = formattedValue;
    }

    const toPhoneFormat = (str): string => {
        return str.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    
    return (
        <div
            className="self-stretch rounded-lg  flex flex-row py-4 px-5 items-start justify-start gap-[50px]"
        >
            <div className="flex w-[350px] flex-col items-start justify-start gap-[20px]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative tracking-[0.1px] leading-[24px]">
                        <span className="font-semibold">
                            Agent&apos;s First Name
                        </span>
                        <span className="text-brand">*</span>
                    </div>
                    <input
                        className="w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-start border-[1px] border-solid border-gray-400"
                        type="text"
                        name="inviteeFirstName"
                        placeholder=""
                        // onBlur={onChangeNonMraInviteeFirstName}
                        onChange={onChangeNonMraInviteeFirstName}
                        defaultValue={formDataStep?.firstName ?? ''}
                        autoComplete="off"
                        tabIndex={1}
                        required
                    />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative tracking-[0.1px] leading-[24px]">
                        <span className="font-semibold">Agent&apos;s Phone</span>
                        <span className="text-brand">*</span>
                    </div>
                    <input
                        className="w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-start border-[1px] border-solid border-gray-400"
                        type="tel"
                        name="inviteePhone"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        placeholder=""
                        onKeyUp={ensurePhoneFormatInline}
                        // onBlur={onChangeNonMraInviteePhone}
                        onChange={onChangeNonMraInviteePhone}
                        defaultValue={formDataStep?.phone ? toPhoneFormat(formDataStep?.phone) as string : ''}
                        autoComplete="off"
                        tabIndex={3}
                        required
                    />
                </div>
            </div>
            <div className="flex w-[350px] flex-col items-start justify-start gap-[20px]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative tracking-[0.1px] leading-[24px]">
                        <span className="font-semibold">Agent&apos;s Last Name</span>
                        <span className="text-brand">*</span>
                    </div>
                    <input
                        className="w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-start border-[1px] border-solid border-gray-400"
                        type="text"
                        name="inviteeLastName"
                        placeholder=""
                        // onBlur={onChangeNonMraInviteeLastName}
                        onChange={onChangeNonMraInviteeLastName}
                        defaultValue={formDataStep?.lastName ?? ''}
                        autoComplete="off"
                        tabIndex={2}
                        required
                    />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative tracking-[0.1px] leading-[24px]">
                        <span className="font-semibold">Agent&apos;s Email</span>
                        <span className="text-brand">*</span>
                    </div>
                    <input
                        className="w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-start border-[1px] border-solid border-gray-400"
                        type="email"
                        name="inviteeEmail"
                        placeholder=""
                        onKeyUp={onEmailKeyUp}
                        onBlur={onChangeNonMraInviteeEmail}
                        onChange={onChangeNonMraInviteeEmail}
                        defaultValue={formDataStep?.email ?? ''}
                        autoComplete="off"
                        tabIndex={4}
                        required
                    />
                </div>
            </div>
        </div>
    );
}

export { InviteNonMember };