import defaultAvatar from '../assets/images/default-avatar.png';
import defaultMuted from '../assets/images/default-avatar-muted.png';
import { getPathImage } from './cloudinary';


export const getAvatar = (image: string | null, size: number): string => {
    if (image) {
        return getPathImage(image, size, size, 'fill', 'face', '0');
    }
    return defaultAvatar;
};

export const getAvatarRoundedCorners = (image: string | null, size: number): string => {
    if (image) {
        return getPathImage(image, size, size, 'fill', 'face', '10');
    }
    return defaultAvatar;
};

export const getAvatarRound = (image: string | null, size: number): string => {
    if (image) {
        return getPathImage(image, size, size, 'fill', 'face', 'max');
    }
    return defaultAvatar;
};

export const getImage = (image: string | null, size?: number): string => {
    if (image) {
        if (size) {
            return getPathImage(image, size, size);
        }
        
        return getPathImage(image);
    }
    return defaultAvatar;
};

export const getImageByWidthAspectRatio = (image: string | null, width?: number): string => {
    if (image) {
        if (width) {
            return getPathImage(image, width);
        }
        
        return getPathImage(image);
    }
    return defaultAvatar;
};

export const getAvatarMuted = (): string => defaultMuted;
