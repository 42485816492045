import { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from 'react-modal';
import HelloSign from 'hellosign-embedded';
import { getAllSkyflowW9, getDropboxSignEmbeddedSignUrl, getDropboxSignSignatureFileUrl, getDropboxSignSignatureRequestById, getReferralsAcceptRefferal, getReferralsInbound, getSkyflowW9DownloadUrl, postNotionMRAReviewTask, postReferralNote, postReferralReviewForUser, postReferralsCalls, postReferralsDeclineReferral, postReferralsUpdateContactStatus, postUploadSignedAgreement } from "../../utils/api";
import { ReferralAction, ReferralActionStr, ReferralOwner, ReferralSource, ReferralSourceStr, ReferralStatus, ReferralTimeFrame, ReferralTimeFrameStr, ReferralType, ReferralTypeStr, TReferralAction, TSendReferralsCallsData, TSendReferralsNoteData, TStateViewReferralInbound, TUsState, UpdateReferralStatus } from "../../types";
import { getAvatar } from "../../utils/get-avatar";
import { getLocaleDateTimeWithTzStrFullDay, getLocaleDateTimeWithTzStrShortForTimeline, getLocaleDateWithTzStrFullDay, getLocaleDateWithTzStrShort } from "../../utils/get-locale-date-str";
import { DropboxSignUtil } from "./DropboxSignUtil";
import { WaitContainer } from "../../components/WaitContainer";
import { getIsProduction } from "../../utils/get-is-production";
import { toCurrency } from "../../utils/to-currency";
import useZustandAuthStore from "../../_Zustand/auth-store";
import { DEFAULT_AVATAR_PATH } from "../AuthenticatedNavBar";
import _ from "lodash";
import ToDoIcon from "../../components/ToDoIcon";
import GenericActionModal from "../../components/GenericActionModal";
import Select from "react-select";
import { validateZipcode } from "../../utils/validate-zipcode";
import { Page404 } from "../../components/Page404/Page404";
import { globalReactSelectTheme } from "../../constants/react-select-theme";
import { isIPhoneBrowser, isMobileBrowser } from "../../utils/mobile";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type TParams = Readonly<{
    referralId?: number;
}>;

const InboundReferralTimeline: FunctionComponent<TParams> = ({ referralId }) => {

    const { authState, usStates } = useZustandAuthStore();
    const usStatesList = usStates as Array<TUsState>;
    const stateOptions = Object.values(usStatesList).map((s) => {
        return { label: s.name, value: s.id, obj: s };
    });

    const defaultAvatar = DEFAULT_AVATAR_PATH;

    const [referral, setReferral] = useState<TStateViewReferralInbound | null>();
    const [currReferralAction, setCurrReferralAction] = useState<TReferralAction>({} as TReferralAction);

    const [referralRequestedDate, setReferralRequestedDate] = useState<string>();
    const [hiddenClientInfo, setHiddenClientInfo] = useState<boolean>(false);

    const [awaitingReferralAcceptance, setAwaitingReferralAcceptance] = useState<boolean>(false);
    const [awaitingAgreement, setAwaitingAgreement] = useState<boolean>(false);
    const [referralDeclined, setReferralDeclined] = useState<boolean>(false);
    const [referralCancelled, setReferralCancelled] = useState<boolean>(false);
    
    const [referralReturned, setReferralReturned] = useState<boolean>(false);
    const [referralReturnedNotBuying, setReferralReturnedNotBuying] = useState<boolean>(false);
    const [referralReturnedHiredAnother, setReferralReturnedHiredAnother] = useState<boolean>(false);
    
    const [awaitingAgreementSignature, setAwaitingAgreementSignature] = useState<boolean>(false);
    const [awaitingAgreementSignatureAcceptance, setAwaitingAgreementSignatureAcceptance] = useState<boolean>(false);
    const [agreementDeclined, setAgreementDeclined] = useState<boolean>(false);

    const [agreementSignatureAccepted, setAgreementSignatureAccepted] = useState<boolean>(false);
    const [clientContacted, setClientContacted] = useState<boolean>(false);
    const [clientAppointmentSet, setClientAppointmentSet] = useState<boolean>(false);
    const [showingHouses, setShowingHouses] = useState<boolean>(false);
    const [clientAgreementSigned, setClientAgreementSigned] = useState<boolean>(false);
    const [homeUnderContract, setHomeUnderContract] = useState<boolean>(false);
    const [contractClosed, setContractClosed] = useState<boolean>(false);
    const [referralCheckIssued, setReferralCheckIssued] = useState<boolean>(false);
    const [referralCheckReceived, setReferralCheckReceived] = useState<boolean>(false);

    const [reviewISent, setReviewISent] = useState<any>(null);
    const [reviewReceived, setReviewReceived] = useState<any>(null);

    const [peerW9List, setPeerW9List] = useState<any[]>([]);


    const buildStageMessage = (obj, message): any => {
        return {
            type: 'stageMessage',
            ...obj,
            message,
        };
    };

    const [isValidReferral, setIsValidReferral] = useState<boolean>(true);
    const referralInvitationId = useRef<number>(-1);
    const [agentCreatedInboundReferral, setAgentCreatedInboundReferral] = useState<boolean>(false);
    const getReferral = (callback?): void => {
        getReferralsInbound(referralId as number)
            .then((referralInfo) => {

                console.log(referralInfo);
                setReferral(referralInfo);

                let _agentCreatedInboundReferral = false;
                if (referralInfo.referral.owner === ReferralOwner.agent) {
                    setAgentCreatedInboundReferral(true);
                    _agentCreatedInboundReferral = true;
                }

                const reviewIWrote = referralInfo.reviews.find((r) => r.reviewerId === authState.id);
                setReviewISent(reviewIWrote);

                const reviewIGot = referralInfo.reviews.find((r) => r.userId === authState.id && r.status === 1);
                setReviewReceived(reviewIGot);

                const currAction = (referralInfo.referral.actions as TReferralAction[])
                    .filter((a) => ![
                        ReferralAction.addedNote,
                        ReferralAction.agentPhoned,
                        ReferralAction.clientNotContacted,
                        ReferralAction.choosingAgent,
                        ReferralAction.referralUnresponsive,
                        ReferralAction.resentAgreementRequest,
                        ReferralAction.sentStatusUpdateRequest,
                        ReferralAction.appointmentNotScheduled,
                    ].includes(a.type))
                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
                setCurrReferralAction(currAction);
                setReferralRequestedDate(currAction?.date);

                const agentSelectedActionSelected = referralInfo.referral.actions.find((a) => a.type === ReferralAction.agentSelected);
                if (agentSelectedActionSelected) {
                    const refInviteId = agentSelectedActionSelected?.invitationId as number;
                    referralInvitationId.current = refInviteId;
                    console.log("refInviteId: ", refInviteId);
                }
                const agentSelectedActionInvited = referralInfo.referral.actions.find((a) => a.type === ReferralAction.agentInvited);
                if (agentSelectedActionInvited) {
                    const refInviteId = agentSelectedActionInvited?.invitationId as number;
                    referralInvitationId.current = refInviteId;
                    console.log("refInviteId: ", refInviteId);
                }

                determineInboundReferralStates(referralInfo, _agentCreatedInboundReferral);

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.message.includes('Referral not found') || error.message.includes("You don't have permission to view user data")) {
                    setIsValidReferral(false);
                }
                if (callback) {
                    callback();
                }
            });
    };
    const determineInboundReferralStates = async (referral, _agentCreatedInboundReferral): Promise<void> => {

        const referralStatus = referral?.referral.status as number;
        console.log("referralStatus: ", referralStatus);

        // set true: if inbound agent should not see client info till agreement is signed
        setHiddenClientInfo([
                ReferralStatus.incomplete, ReferralStatus.sentToAgent, ReferralStatus.sentInvitation,
                ReferralStatus.accepted, ReferralStatus.declined, ReferralStatus.agreementSent,
                ReferralStatus.signedAgreementUploaded, ReferralStatus.signedAgreementDeclined
            ].includes(referralStatus)
            &&
            !_agentCreatedInboundReferral
        );

        // true: show button to accept or decline referral
        // setAwaitingReferralAcceptance([
        //     ReferralStatus.sentToAgent,
        //     ReferralStatus.sentInvitation,
        // ].includes(referralStatus));

        // true: show text that the inbound referral declined referral
        setReferralDeclined([
            ReferralStatus.declined
        ].includes(referralStatus));

        // true: show text that the outbound referral cancelled referral
        setReferralCancelled([
            ReferralStatus.canceled
        ].includes(referralStatus));

        // true: show text that the receiving agent has returned referral
        setReferralReturned([
            ReferralStatus.returned
        ].includes(referralStatus));

        // true: show text that the receiving agent has returned referral because client decided not to buy
        setReferralReturnedNotBuying([
            ReferralStatus.returnedNotIntendToBuy
        ].includes(referralStatus));
        
        setReferralReturnedHiredAnother([
            ReferralStatus.hiredAnotherAgent
        ].includes(referral?.referral.status as ReferralStatus));

        // true: show text that the referring agent is yet to send an agreement
        setAwaitingAgreement([
            // ReferralStatus.accepted,
            ReferralStatus.sentToAgent,
            ReferralStatus.sentInvitation,
        ].includes(referralStatus));

        // true: show buttons to view, accept & sign or decline agreement
        const waitingForSignature = [
            ReferralStatus.agreementSent
        ].includes(referralStatus);

        if (waitingForSignature) {
            const signatureRequestInfo = signatureRequestRef.current;
            if (signatureRequestInfo && !signatureRequestInfo.error) {
                const isSigned = signatureRequestInfo.signature_request.signatures[0].status_code === "signed";
                if (isSigned) {
                    setAwaitingAgreementSignature(false);
                }
                else {
                    setAwaitingAgreementSignature(true);
                }
            }
            else {
                // Get signature_request from hello-sign
                const { signatureRequestId } = JSON.parse(referral?.referral.agreement as string);

                getDropboxSignSignatureRequestById(signatureRequestId, referralId as number)
                    .then((signatureRequestInfo) => {
                        console.log("signatureRequest: ", signatureRequestInfo);
                        signatureRequestRef.current = signatureRequestInfo;

                        const isSigned = signatureRequestInfo.signature_request.signatures[0].status_code === "signed";
                        if (isSigned) {
                            setAwaitingAgreementSignature(false);
                        }
                        else {
                            setAwaitingAgreementSignature(true);
                        }
                    });
            }

        }

        // true: show button to view & sign agreement and button to decline agreement
        setAwaitingAgreementSignatureAcceptance([
            ReferralStatus.signedAgreementUploaded
        ].includes(referralStatus));

        // true: show text that you declined agreement
        setAgreementDeclined([
            ReferralStatus.signedAgreementDeclined
        ].includes(referralStatus));

        setAgreementSignatureAccepted([
            ReferralStatus.signedAgreementConfirmed,
            ReferralStatus.clientContinueContacting,
            ReferralStatus.clientNotContacted,
        ].includes(referralStatus));

        setClientContacted([
            ReferralStatus.clientContacted,
        ].includes(referralStatus));

        setClientAppointmentSet([
            ReferralStatus.appointmentSet
        ].includes(referralStatus));

        setShowingHouses([
            ReferralStatus.lookingHouses
        ].includes(referralStatus));

        setClientAgreementSigned([
            ReferralStatus.clientAgreementSigned
        ].includes(referralStatus));

        setHomeUnderContract([
            ReferralStatus.underContract,
            ReferralStatus.underContractClosingDate,
        ].includes(referralStatus));

        setContractClosed([
            ReferralStatus.contractClosed
        ].includes(referralStatus));

        const isReadyToShowW9 = [
            ReferralStatus.underContract,
            ReferralStatus.underContractClosingDate,
            ReferralStatus.contractClosed,
            ReferralStatus.referralCheckIssued,
            ReferralStatus.referralCheckReceived,
        ].includes(referralStatus)
        
        if (isReadyToShowW9) {
            getAllSkyflowW9(referral.referral.userId)
                .then((response) => {
                    if (Array.isArray(response.records) && response.records.length > 0) {
                        const w9List = response.records;
                        setPeerW9List(w9List);
                        console.log(w9List);
                    } else {
                        setPeerW9List([]);
                        console.log('No W9s');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        setReferralCheckIssued([
            ReferralStatus.referralCheckIssued
        ].includes(referralStatus));

        setReferralCheckReceived([
            ReferralStatus.referralCheckReceived
        ].includes(referralStatus));
    };

    const downloadW9 = (): void => {
        const referringAgentId = referral?.referral.userId as number;
        getSkyflowW9DownloadUrl(referringAgentId)
            .then((result) => {
                const url = result.w9Url;

                if (!url) {
                    return;
                }

                const fileName = url.split("/").pop();

                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            })
            .catch((error) => {
                console.error(error);
            })
    };

    const goToProfile = (publicProfile): void => {
        const url = `/members/${publicProfile}`;
        const w = window.open(url, '_blank');
        if (w) {
            w.focus();
        }
    }


    // ACCEPT/DECLINE REFERRAL
    const [acceptingOrDecliningReferral, setAcceptingOrDecliningReferral] = useState<boolean>(false);
    const acceptDeclineModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '15%',
            margin: '0 auto',
            width: '600px',
            height: '500px',
            overflow: 'none',
            borderRadius: '16px',
        },
    };

    const finalizeAcceptReferral = (): void => {
        const loadingId = toast.loading(`Accepting Referral from ${referral?.referral.userFirstName} ${referral?.referral.userLastName}`);
        setAcceptingOrDecliningReferral(true);
        getReferralsAcceptRefferal(referralInvitationId.current)
            .then((result) => {
                console.log(result);

                getReferral(() => {
                    toast.dismiss(loadingId);
                    setAcceptingOrDecliningReferral(false);
                    toast.info(`Referral Accepted! ${referral?.referral.userFirstName} ${referral?.referral.userLastName} will be notified!`);
                });
            })
            .catch((error) => {
                toast.dismiss(loadingId);
                setAcceptingOrDecliningReferral(false);
                toast.error(`Error: ${error.message}`);
                console.log(error.message);
            });
    };

    const MAX_REFERRAL_DECLINE_CHARS = 500;
    const [showDeclineReferralModal, setShowDeclineReferralModal] = useState<boolean>(false);
    const [declineReferralMessage, setDeclineReferralMessage] = useState<string>("");
    const [declineReferralMessageCharsLeft, setDeclineReferralMessageCharsLeft] = useState<number>(MAX_REFERRAL_DECLINE_CHARS);
    const declineReferralMessageLengthCheck = (e): void => {
        const str = e.target.value;
        setDeclineReferralMessage(str);
        setDeclineReferralMessageCharsLeft(MAX_REFERRAL_DECLINE_CHARS - str.length);
    }
    const declineReferral = (): void => {
        setDeclineReferralMessage("");
        setShowDeclineReferralModal(true);
    };
    const finalizeDeclineReferral = (message?: string): void => {
        setAcceptingOrDecliningReferral(true);
        const loadingId = toast.loading(`Declining Referral from ${referral?.referral.userFirstName} ${referral?.referral.userLastName}`);
        const data = { message: message ?? declineReferralMessage };
        postReferralsDeclineReferral(referralInvitationId.current, data)
            .then((result) => {
                console.log(result);

                getReferral(() => {
                    setAcceptingOrDecliningReferral(false);
                    setDeclineReferralMessage("");
                    setShowDeclineReferralModal(false);

                    toast.dismiss(loadingId);
                    toast.info(`Referral Declined! ${referral?.referral.userFirstName} ${referral?.referral.userLastName} will be notified.`);
                });
                window.location.reload();
            })
            .catch((error) => {
                setAcceptingOrDecliningReferral(false);
                setDeclineReferralMessage("");
                setShowDeclineReferralModal(false);

                toast.dismiss(loadingId);
                toast.error(`Error: ${error.message}`);
                console.error(error.message);
            });
    };



    // SIGN REFERRAL AGREEMENT
    const signatureRequestRef = useRef<any>(null);
    const dropboxSignClient = new HelloSign();
    dropboxSignClient.on('open', (data) => {
        console.log("Dropbox Sign Embed Opened: ", data);
    });

    dropboxSignClient.on('cancel', (data) => {
        console.log("Dropbox Sign Embed Canceled: ", data);
        setSigningAgreement(false);
    });

    dropboxSignClient.on('sign', (data) => {
        console.log("Dropbox Sign Embed Document Signed: ", data);
        signatureCompletionToastRef.current = toast.loading("Thank you for Signing! Processing, please wait ...");

        if (([ReferralStatus.agreementSent].includes(referral?.referral.status as ReferralStatus))) {

            const { signatureRequestId } = JSON.parse(referral?.referral.agreement as string);

            // Get updated signature_request from hello-sign
            // DropboxSignUtil.getSignatureRequestByRequestId(signatureRequestId)
            getDropboxSignSignatureRequestById(signatureRequestId, referralId as number)
                .then((signatureRequestInfo) => {
                    console.log("signatureRequest: ", signatureRequestInfo);
                    signatureRequestRef.current = signatureRequestInfo;

                    // update database with signed agreement info
                    const formData = new FormData();
                    formData.append('blob', new Blob([signatureRequestInfo.signature_request.files_url]), 'SignedFile');

                    postUploadSignedAgreement(referral?.referral.id as number, formData).then((result) => {
                        console.log(result);

                        getReferral(() => {
                            setAwaitingAgreementSignature(false);
                            setSigningAgreement(false);
                            toast.dismiss(signatureCompletionToastRef.current);
                        });
                        
                    }).catch((error) => {
                        setAwaitingAgreementSignature(false);
                        setSigningAgreement(false);

                        toast.dismiss(signatureCompletionToastRef.current);
                        toast.error(`Error: ${error.message}`)

                        throw error;
                    });
                });


        }
    });

    dropboxSignClient.on('close', (data) => {
        console.log("Dropbox Sign Embed Closed: ", data);
    });

    dropboxSignClient.on('error', (error) => {
        console.log("Dropbox Sign Embed Error: ", error);
        toast.error("There was an error with the Signing Application. Please try again later!");
        setSigningAgreement(false);
    });

    const signatureCompletionToastRef = useRef<typeof ToastContainer.prototype.loading>({});
    const [signingAgreement, setSigningAgreement] = useState<boolean>(false);
    const viewAndSignAgreement = async (): Promise<void> => {
        const { signatureId } = JSON.parse(referral?.referral.agreement as string);

        try {
            setSigningAgreement(true);
            const response = await getDropboxSignEmbeddedSignUrl(signatureId, referralId as number);
            const signUrl = response.embedded.sign_url;

            const options = {
                clientId: DropboxSignUtil.DROPBOX_SIGN_CLIENT_ID,
                skipDomainVerification: !getIsProduction(),
            };

            dropboxSignClient.open(signUrl, options);

        } catch (error) {
            console.error(error);
            setSigningAgreement(false);
        }
    };
    
    const [agreementModalVisible, setAgreementModalVisible] = useState<boolean>(false);
    const [agreementModalLabel, setAgreementModalLabel] = useState<string>("");
    const [agreementUrl, setAgreementUrl] = useState<string>("");
    const agreementModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            borderRadius: '16px',
            margin: '0 auto',
            width: '1150px',
        },
    };
    const viewAgreement = async (): Promise<void> => {
        const vloading = toast.loading(`Retrieving Referral Agreement with ${referral?.referral.agentFirstName} ${referral?.referral.agentLastName}!`);

        try {
            const signatureInfo: any = JSON.parse(referral?.referral.agreement as string)
            const { signatureRequestId } = signatureInfo;

            console.log("Getting file URL for Signature Request: ", signatureRequestId);

            const response = await getDropboxSignSignatureFileUrl(signatureRequestId, referralId as number);
            console.log(response);
            const fileUrl = `${response.data_uri}`;

            toast.dismiss(vloading);

            // if (isMobile) {
            //     setTimeout(() => {
            //         downloadAgreementOld(fileUrl);
            //     }, 100);

            //     alert("File download has begun, please check the notification bar for download update.");

            //     // const downloadFName = `ReferralAgreement_${referralId}_${referral?.referral.userFirstName}${referral?.referral.userLastName}_${referral?.referral.agentFirstName}${referral?.referral.agentLastName}.pdf`;
            //     // downloadAgreement(fileUrl, (f) => {
            //     //     saveDownloadAgreement(f, 'application/pdf', downloadFName);
            //     //     // toast.info("File download has begun, please check the notification bar for download update.");
            //     //     alert("File download has begun, please check the notification bar for download update.");
            //     // });

            //     return;
            // }

            const templateViewerLabel = `Agreement with ${referral?.referral.agentFirstName} ${referral?.referral.agentLastName}`;

            setAgreementModalLabel(templateViewerLabel);
            setAgreementUrl(fileUrl);
            setAgreementModalVisible(true);

        } catch (error: any) {
            toast.dismiss(vloading);
            console.error("FileURL Fetch Error: ", error);
        }
    };




    // CLIENT CONTACTED AND ALTERNATIVES
    const [enabledContactWithClientModal, setEnabledContactWithClientModal] = useState<boolean>(false);
    const [contactWithClientMessage, setContactWithClientMessage] = useState<string>('');
    const [indicatingContactWithClient, setIndicatingContactWithClient] = useState<boolean>(false);
    const indicateContactWithClient = (): void => {
        setContactWithClientMessage('');
        setEnabledContactWithClientModal(true);
    };
    const finalizeContactWithClient = (): void => {
        setIndicatingContactWithClient(true);

        const message = contactWithClientMessage ?? '';
        const toastId = toast.loading(`Indicating that you have spoken to the client ...`);
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status: UpdateReferralStatus.contactedClient, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingContactWithClient(false);
                    setEnabledContactWithClientModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingContactWithClient(false);
                setEnabledContactWithClientModal(false);
                toast.dismiss(toastId);
            });
    };

    // not yet contacted
    const [enabledClientNotContactedModal, setEnabledClientNotContactedModal] = useState<boolean>(false);
    const [clientNotContactedMessage, setClientNotContactedMessage] = useState<string>('');
    const [indicatingClientNotContacted, setIndicatingClientNotContacted] = useState<boolean>(false);
    const indicateClientNotContacted = (): void => {
        setClientNotContactedMessage('');
        setEnabledClientNotContactedModal(true);
    };
    const finalizeClientNotContacted = (): void => {
        setIndicatingClientNotContacted(true);

        const message = clientNotContactedMessage ?? '';
        const toastId = toast.loading(`Indicating that you have not yet spoken to the client ...`);
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status: UpdateReferralStatus.notSpoken, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientNotContacted(false);
                    setEnabledClientNotContactedModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientNotContacted(false);
                setEnabledClientNotContactedModal(false);
                toast.dismiss(toastId);
            });
    };

    // contacted but no response
    const [enabledClientContactedNoResponseModal, setEnabledClientContactedNoResponseModal] = useState<boolean>(false);
    const [clientContactedNoResponseMessage, setClientContactedNoResponse] = useState<string>('');
    const [indicatingClientContactedNoResponse, setIndicatingClientContactedNoResponse] = useState<boolean>(false);
    const indicateClientContactedNoResponse = (): void => {
        setClientContactedNoResponse('');
        setEnabledClientContactedNoResponseModal(true);
    };
    const finalizeClientContactedNoResponse = (): void => {
        setIndicatingClientContactedNoResponse(true);

        const message = (clientContactedNoResponseMessage ?? '');
        const toastId = toast.loading(`Indicating that you made attempts to contact the client but received no response!`);
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status: UpdateReferralStatus.referralUnresponsive, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientContactedNoResponse(false);
                    setEnabledClientContactedNoResponseModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientContactedNoResponse(false);
                setEnabledClientContactedNoResponseModal(false);
                toast.dismiss(toastId);
            });
    };

    // returning referral at client contacted
    const [enabledClientContactedReturnReferralModal, setEnabledClientContactedReturnReferralModal] = useState<boolean>(false);
    const [clientContactedReturnReferralMessage, setClientContactedReturnReferralMessage] = useState<string>('');
    const [indicatingClientContactedReturnReferral, setIndicatingClientContactedReturnReferral] = useState<boolean>(false);
    const [clientContactReturnReason, setClientContactedReturnReason] = useState<number>(-1);
    const enum clientContactReturnReasonOptionsEnum {
        clientDoesNotWishToBuy = 0,
        clientHiredAnotherAgentBeforeMeeting = 1,
        clientHiredAnotherAgentAfterMeeting = 2,
        other = 3,
        clientStillDecidingOnAgent = 4,
    };
    const clientContactReturnReasonOptions = [
        { label: 'Client Does Not Wish To Buy', value:  clientContactReturnReasonOptionsEnum.clientDoesNotWishToBuy },
        { label: 'Client Hired Another Agent Before Meeting Me', value:  clientContactReturnReasonOptionsEnum.clientHiredAnotherAgentBeforeMeeting },
        { label: 'Client Hired Another Agent After Meeting Me', value:  clientContactReturnReasonOptionsEnum.clientHiredAnotherAgentAfterMeeting },
        { label: 'Other', value:  clientContactReturnReasonOptionsEnum.other },
    ];
    const returnReferralAtClientContacted = (): void => {
        setClientContactedReturnReferralMessage('');
        setEnabledClientContactedReturnReferralModal(true);
    };
    const finalizeReturnReferralAtClientContacted = (): void => {

        if (clientContactReturnReason === -1) {
            toast.error("Please select a reason to return this referral!");
            return;
        }

        setIndicatingClientContactedReturnReferral(true);

        const message = (clientContactedReturnReferralMessage ?? '');
        const toastId = toast.loading(`Indicating that you are returning this referral!`);
        let status = UpdateReferralStatus.other;
        switch(clientContactReturnReason) {
            case clientContactReturnReasonOptionsEnum.clientDoesNotWishToBuy:
                status = UpdateReferralStatus.notIntendToBuyReturnReferral;
                break;
            case clientContactReturnReasonOptionsEnum.clientHiredAnotherAgentBeforeMeeting:
                status = UpdateReferralStatus.hiredAnotherPrior;
                break;
            case clientContactReturnReasonOptionsEnum.clientHiredAnotherAgentAfterMeeting:
                status = UpdateReferralStatus.hiredAnotherAfter;
                break;
            case clientContactReturnReasonOptionsEnum.other:
                status = UpdateReferralStatus.returnReferralOther;
                break;
            default:
                break;
        }
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientContactedReturnReferral(false);
                    setEnabledClientContactedReturnReferralModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientContactedReturnReferral(false);
                setEnabledClientContactedReturnReferralModal(false);
                toast.dismiss(toastId);
            });
    };




    // CLIENT APPOINTMENT SCHEDULED AND ALTERNATIVES
    const clientAppointmentSetModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '2%',
            margin: '0 auto',
            width: '550px',
            height: '830px',
            borderRadius: '16px',
            overflow: 'none',
        },
    };
    const [enabledClientAppointmentSetModal, setEnabledClientAppointmentSetModal] = useState<boolean>(false);
    const [clientAppointmentSetMessage, setClientAppointmentSetMessage] = useState<string>('');
    const [indicatingClientAppointmentSet, setIndicatingClientAppointmentSet] = useState<boolean>(false);
    const [clientAppointmentDate, setClientAppointmentDate] = useState<any>(null);    
    const indicateClientAppointmentSet = (): void => {
        setClientAppointmentSetMessage('');
        setEnabledClientAppointmentSetModal(true);
    };
    const finalizeClientAppointmentSet = (): void => {

        if (!clientAppointmentDate) {
            toast.error("Please select the appointment date.")
            return;
        }

        const message = clientAppointmentSetMessage;
        const appointmentDate = clientAppointmentDate as Date;
        
        const toastId = toast.loading(`Indicating that you scheduled an appointment with the client!`);
        setIndicatingClientAppointmentSet(true);
        
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status: UpdateReferralStatus.setAppointment, message, date: appointmentDate.toJSON() })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientAppointmentSet(false);
                    setEnabledClientAppointmentSetModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientAppointmentSet(false);
                setEnabledClientAppointmentSetModal(false);
                toast.dismiss(toastId);
            });
    };

    // client appointment "not" yet scheduled
    const [enabledClientAppointmentNotSetModal, setEnabledClientAppointmentNotSetModal] = useState<boolean>(false);
    const [clientAppointmentNotSetMessage, setClientAppointmentNotSetMessage] = useState<string>('');
    const [indicatingClientAppointmentNotSet, setIndicatingClientAppointmentNotSet] = useState<boolean>(false);
    const indicateClientAppointmentNotSet = (): void => {
        setClientAppointmentNotSetMessage('');
        setEnabledClientAppointmentNotSetModal(true);
    };
    const finalizeClientAppointmentNotSet = (): void => {
        setIndicatingClientAppointmentNotSet(true);

        const message = clientAppointmentNotSetMessage ?? '';
        const toastId = toast.loading(`Indicating that you not yet scheduled an appointment with the client!`);
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status: UpdateReferralStatus.notSetAppointment, message  })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientAppointmentNotSet(false);
                    setEnabledClientAppointmentNotSetModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientAppointmentNotSet(false);
                setEnabledClientAppointmentNotSetModal(false);
                toast.dismiss(toastId);
            });
    };

    // returning referral at appointment scheduled
    const [enabledAppointmentSetReturnReferralModal, setEnabledAppointmentSetReturnReferralModal] = useState<boolean>(false);
    const [appointmentSetReturnReferralMessage, setAppointmentSetReturnReferralMessage] = useState<string>('');
    const [indicatingAppointmentSetReturnReferral, setIndicatingAppointmentSetReturnReferral] = useState<boolean>(false);
    const [appointmentSetReturnReason, setAppointmentSetReturnReason] = useState<number>(-1);
    const enum appointmentSetReturnReasonsEnum {
        clientDoesNotWishToBuy = 0,
        clientHiredAnotherAgentBeforeMeeting = 1,
        clientHiredAnotherAgentAfterMeeting = 2,
        other = 3,
        clientStillDecidingOnAgent = 4,
    };
    const appointmentSetReturnReasonOptions = [
        { label: 'Client Does Not Wish To Buy', value:  appointmentSetReturnReasonsEnum.clientDoesNotWishToBuy },
        { label: 'Client Hired Another Agent Before Meeting Me', value:  appointmentSetReturnReasonsEnum.clientHiredAnotherAgentBeforeMeeting },
        { label: 'Client Hired Another Agent After Meeting Me', value:  appointmentSetReturnReasonsEnum.clientHiredAnotherAgentAfterMeeting },
        { label: 'Other', value:  appointmentSetReturnReasonsEnum.other },
    ];
    const returnReferralAtAppointmentSet = (): void => {
        setAppointmentSetReturnReferralMessage('');
        setEnabledAppointmentSetReturnReferralModal(true);
    };
    const finalizeReturnReferralAtAppointmentSet = (): void => {

        if (appointmentSetReturnReason === -1) {
            toast.error("Please select a reason to return this referral!");
            return;
        }

        setIndicatingAppointmentSetReturnReferral(true);

        const message = (appointmentSetReturnReferralMessage ?? '');
        const toastId = toast.loading(`Indicating that you are returning this referral!`);
        let status = UpdateReferralStatus.other;
        switch(appointmentSetReturnReason) {
            case appointmentSetReturnReasonsEnum.clientDoesNotWishToBuy:
                status = UpdateReferralStatus.notIntendToBuyReturnReferral;
                break;
            case appointmentSetReturnReasonsEnum.clientHiredAnotherAgentBeforeMeeting:
                status = UpdateReferralStatus.hiredAnotherPrior;
                break;
            case appointmentSetReturnReasonsEnum.clientHiredAnotherAgentAfterMeeting:
                status = UpdateReferralStatus.hiredAnotherAfter;
                break;
            case appointmentSetReturnReasonsEnum.other:
                status = UpdateReferralStatus.other;
                break;
            default:
                break;
        }
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingAppointmentSetReturnReferral(false);
                    setEnabledAppointmentSetReturnReferralModal(false);
                    toast.dismiss(toastId);
                });                
            })
            .catch((error) => {
                console.error(error);
                setIndicatingAppointmentSetReturnReferral(false);
                setEnabledAppointmentSetReturnReferralModal(false);
                toast.dismiss(toastId);
            });
    };
    



    // CLIENT AGREEMENT SIGNED
    const [enabledClientAgreementSignedModal, setEnabledClientAgreementSignedModal] = useState<boolean>(false);
    const [clientAgreementSignedMessage, setClientAgreementSignedMessage] = useState<string>('');
    const [indicatingClientAgreementSigned, setIndicatingClientAgreementSigned] = useState<boolean>(false);
    const indicateClientAgreementSigned = (): void => {
        setClientAgreementSignedMessage('');
        setEnabledClientAgreementSignedModal(true);
    };
    const finalizeClientAgreementSigned = (): void => {
        setIndicatingClientAgreementSigned(true);

        const toastId = toast.loading(`Indicating that the client has signed an exclusive agreement with you! Nice!`);
        
        const message = (clientAgreementSignedMessage ?? '');
        const status = UpdateReferralStatus.clientAgreementSigned;
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientAgreementSigned(false);
                    toast.dismiss(toastId);
                });                
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientAgreementSigned(false);
                toast.dismiss(toastId);
                // toast.error(`Error: ${error.message}`);
            });
    };

    // client still deciding on an agent
    const [enabledClientStillDecidingModal, setEnabledClientStillDecidingModal] = useState<boolean>(false);
    const [clientStillDecidingMessage, setClientStillDecidingMessage] = useState<string>('');
    const [indicatingClientStillDeciding, setIndicatingClientStillDeciding] = useState<boolean>(false);
    const indicateClientStillDeciding = (): void => {
        setClientStillDecidingMessage('');
        setEnabledClientStillDecidingModal(true);
    };
    const finalizeClientStillDeciding = (): void => {
        setIndicatingClientStillDeciding(true);

        const toastId = toast.loading(`Indicating that the client is still deciding on an agent!`);

        const status = UpdateReferralStatus.choosingAgent;
        const message = clientStillDecidingMessage ?? '';
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message  })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientStillDeciding(false);
                    setEnabledClientStillDecidingModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientStillDeciding(false);
                setEnabledClientStillDecidingModal(false);
                toast.dismiss(toastId);
            });
    };

    // returning referral at client agreement signed
    const [enabledClientAgreementReturnReferralModal, setEnabledClientAgreementReturnReferralModal] = useState<boolean>(false);
    const [clientAgreementReturnReferralMessage, setClientAgreementReturnReferralMessage] = useState<string>('');
    const [indicatingClientAgreementReturnReferral, setIndicatingClientAgreementReturnReferral] = useState<boolean>(false);
    const [clientAgreementReturnReason, setClientAgreementReturnReason] = useState<number>(-1);
    const enum clientAgreementReturnReasonsEnum {
        clientDoesNotWishToBuy = 0,
        other = 1,
    };
    const clientAgreementReturnReasonOptions = [
        { label: 'Client Does Not Wish To Buy', value:  clientAgreementReturnReasonsEnum.clientDoesNotWishToBuy },
        { label: 'Other', value:  clientAgreementReturnReasonsEnum.other },
    ];
    const returnReferralAtClientAgreement = (): void => {
        setClientAgreementReturnReferralMessage('');
        setEnabledClientAgreementReturnReferralModal(true);
    };
    const finalizeReturnReferralAtClientAgreement = (): void => {

        if (clientAgreementReturnReason === -1) {
            toast.error("Please select a reason to return this referral!");
            return;
        }

        setIndicatingClientAgreementReturnReferral(true);

        const toastId = toast.loading(`Indicating that you are returning this referral!`);

        const message = (clientAgreementReturnReferralMessage ?? '');
        let status = UpdateReferralStatus.other;
        switch(clientAgreementReturnReason) {
            case appointmentSetReturnReasonsEnum.clientDoesNotWishToBuy:
                status = UpdateReferralStatus.notIntendToBuyReturnReferral;
                break;
            case appointmentSetReturnReasonsEnum.other:
                status = UpdateReferralStatus.other;
                break;
            default:
                break;
        }
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientAgreementReturnReferral(false);
                    setEnabledClientAgreementReturnReferralModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingAppointmentSetReturnReferral(false);
                setEnabledClientAgreementReturnReferralModal(false);
                toast.dismiss(toastId);
            });
    };



    // TOURING HOMES
    const [enabledShowingHomesModal, setEnabledShowingHomesModal] = useState<boolean>(false);
    const [showingHomesMessage, setShowingHomesMessage] = useState<string>('');
    const [indicatingShowingHomes, setIndicatingShowingHomes] = useState<boolean>(false);
    const indicateShowingHomes = (): void => {
        setShowingHomesMessage('');
        setEnabledShowingHomesModal(true);
    };
    const finalizeShowingHomes = (): void => {
        setIndicatingShowingHomes(true);

        const toastId = toast.loading(`Indicating Showing/Viewing Properties with Client ...`);
        const status = UpdateReferralStatus.showingHouses;
        const message = (showingHomesMessage ?? '');
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message  })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingShowingHomes(false);
                    setEnabledShowingHomesModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingShowingHomes(false);
                setEnabledShowingHomesModal(false);
                toast.dismiss(toastId);
            });
    };

    // HOME UNDER CONTRACT
    const underContractModalFormRef = useRef<HTMLFormElement>(null);
    const [contractClosureDate, setContractClosureDate] = useState<Value | null>(null);
    const [contractClosurePrice, setContractClosurePrice] = useState<number>(-1);
    const toCurrencyInline = (event): void => {
        const str = event.target.value;
        const currencyStr = toCurrency(str);
        event.target.value = currencyStr;
        formatAndUpdateContractClosurePrice({target: {value: currencyStr}})
    };
    const formatAndUpdateContractClosurePrice = (event): void => {
        const strValue = event.target.value;
        if (strValue === '') {
            setContractClosurePrice(-1);
            return;
        }
        const intValue = parseInt(strValue.replace(/\D/g, ''), 10);
        if (intValue <= 99999999) {
            setContractClosurePrice(intValue);
        }
    }
    const contractClosureModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '5%',
            margin: '0 auto',
            width: '950px',
            height: '770px',
            borderRadius: '16px',
            overflow: 'none',
        },
    };
    const [contractClosureAddress1, setContractClosureAddress1] = useState<string>('');
    const [contractClosureAddress2, setContractClosureAddress2] = useState<string>('');
    const [contractClosureCity, setContractClosureCity] = useState<string>('');
    const selectStateStyle = {
        control: (base) => ({
            ...base,
            height: 35,
            minHeight: 35,
        }),
    };
    const [contractClosureStateId, setContractClosureStateId] = useState<number>(-1);
    const [contractClosureZipcode, setContractClosureZipcode] = useState<string>('');
    const validateContractClosureObjWithToasts = (obj): boolean => {
        if (obj.contractClosurePrice === -1) {
            toast.error("Error: Please enter a contract closure price!");
            return false;
        }
        if (referral?.referral.type === ReferralType.buy) {
            if (obj.contractClosureAddress1 === '') {
                toast.error("Error: Please enter an address in the 'Address Line 1' field!");
                return false;
            }
            if (obj.contractClosureCity === '') {
                toast.error("Error: Please enter a city!");
                return false;
            }
            if (obj.contractClosureStateId === -1) {
                toast.error("Error: Please select a state!");
                return false;
            }
            if (!validateZipcode(obj.contractClosureZipcode)) {
                toast.error("Error: Please enter a valid zip code!");
                return false;
            }
        }
        if (obj.contractClosureDate === null) {
            toast.error("Error: Please select a contract closure date!");
            return false;
        }
        return true;
    }
    const [enabledHomeUnderContractModal, setEnabledHomeUnderContractModal] = useState<boolean>(false);
    const [homeUnderContractMessage, setHomeUnderContractMessage] = useState<string>('');
    const [indicatingHomeUnderContract, setIndicatingHomeUnderContract] = useState<boolean>(false);
    const indicateHomeUnderContract = (): void => {
        setHomeUnderContractMessage('');

        setContractClosureDate(null);
        setContractClosurePrice(-1);
        setContractClosureCity('');
        setContractClosureZipcode('');
        setContractClosureStateId(-1);
        
        setEnabledHomeUnderContractModal(true);
    };
    const finalizeHomeUnderContract = (): void => {        
        const status = UpdateReferralStatus.underContractClosingDate;
        const message = homeUnderContractMessage;
        const isSell = referral?.referral.type === ReferralType.sell;
        const messageObj = buildStageMessage({
            contractClosureDate, contractClosurePrice: (contractClosurePrice === -1) ? (isSell ? referral?.referral.sellTargetPrice : contractClosurePrice) : contractClosurePrice,
            contractClosureAddress1, contractClosureAddress2, contractClosureCity, contractClosureStateId, 
            contractClosureStateAbbr: usStatesList.find((s) => s.id === contractClosureStateId)?.abbreviation, contractClosureZipcode,
        }, message);

        underContractModalFormRef.current?.reportValidity();

        if (!validateContractClosureObjWithToasts(messageObj)) {
            return;
        }

        let data;
        if (referral?.referral.type === ReferralType.buy) {
            data = {
                status,
                message,
                price: contractClosurePrice,
                date: (contractClosureDate as Date).toJSON(),
                address1: contractClosureAddress1,
                address2: contractClosureAddress2,
                city: contractClosureCity,
                stateId: contractClosureStateId,
                zip: contractClosureZipcode,
            }
        }
        else {
            data = {
                status,
                message,
                price: (contractClosurePrice === -1) ? (isSell ? referral?.referral.sellTargetPrice : contractClosurePrice) : contractClosurePrice,
                date: (contractClosureDate as Date).toJSON(),
                address1: referral?.referral.sellAddress1,
                address2: referral?.referral.sellAddress2,
                city: referral?.referral.sellCity,
                stateId: referral?.referral.sellStateId,
                zip: referral?.referral.sellZip,
            }
        }

        const toastId = toast.loading(`Indicating that the client is under contract for a property!`);
        setIndicatingHomeUnderContract(true);

        postReferralsUpdateContactStatus(referral?.referral.id as number, data)
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingHomeUnderContract(false);
                    setEnabledHomeUnderContractModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingHomeUnderContract(false);
                setEnabledHomeUnderContractModal(false);
                toast.dismiss(toastId);
            });
    }


    // CONTRACT TERMS HAVE CHANGED
    // TODO: Day 2 Feature
    const [contractClosureObj, setContractClosureObj] = useState<any>(null);
    const [contractClosureDateChanged, setContractClosureDateChanged] = useState<Value | Date>(null);
    const [contractClosurePriceChanged, setContractClosurePriceChanged] = useState<number>(-1);
    const formatAndUpdateContractClosurePriceChanged = (event): void => {
        console.log(event.target.value);
        const strValue = event.target.value;
        const intValue = parseInt(strValue.replace(/\D/g, ''), 10);
        if (intValue <= 99999999) {
            setContractClosurePriceChanged(intValue);
        }
    }
    const [contractClosureAddress1Changed, setContractClosureAddress1Changed] = useState<string>('');
    const [contractClosureAddress2Changed, setContractClosureAddress2Changed] = useState<string>('');
    const [contractClosureCityChanged, setContractClosureCityChanged] = useState<string>('');
    const [contractClosureStateIdChanged, setContractClosureStateIdChanged] = useState<number>(-1);
    const [contractClosureZipcodeChanged, setContractClosureZipcodeChanged] = useState<string>('');
    const [enabledContractClosureChangedModal, setEnabledContractClosureChangedModal] = useState<boolean>(false);
    const [contractClosureChangedMessage, setContractClosureChangedMessage] = useState<string>('');
    const [indicatingContractClosureChanged, setIndicatingContractClosureChanged] = useState<boolean>(false);
    const indicateContractClosureChanged = (): void => {
        setContractClosureChangedMessage('');

        // get the most recent under contract object
        const underContractAction = referral?.referral.actions
                                    .filter((a) => a.type === ReferralAction.underContractClosingDate)
                                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
        if (underContractAction) {
            setContractClosureObj(underContractAction);
            
            setContractClosureDateChanged(new Date(underContractAction.date));
            setContractClosurePriceChanged(underContractAction.price as number);
            setContractClosureAddress1Changed(underContractAction.address1);
            setContractClosureAddress2Changed(underContractAction.address2);
            setContractClosureCityChanged(underContractAction.city);
            setContractClosureStateIdChanged(underContractAction.stateId);
            setContractClosureZipcodeChanged(underContractAction.zip);
        }

        setEnabledContractClosureChangedModal(true);
    };
    const finalizeContractClosureChanged = (): void => {        
        const status = UpdateReferralStatus.termsChanged;
        const message = contractClosureChangedMessage;
        const messageObj = buildStageMessage({
            contractClosureDateChanged, contractClosurePriceChanged,
            contractClosureAddress1Changed, contractClosureAddress2Changed, contractClosureCityChanged, contractClosureStateIdChanged,
            contractClosureStateAbbrChanged: usStatesList.find((s) => s.id === contractClosureStateIdChanged)?.abbreviation, contractClosureZipcodeChanged,
        }, message);

        if (!validateContractClosureObjWithToasts(messageObj)) {
            return;
        }

        const toastId = toast.loading(`Indicating that the contract terms have changed with updated values!`);
        setIndicatingContractClosureChanged(true);

        postReferralsUpdateContactStatus(referral?.referral.id as number, {
                status,
                message,
                price: contractClosurePriceChanged,
                date: (contractClosureDateChanged as Date).toJSON(),
                address1: contractClosureAddress1Changed,
                address2: contractClosureAddress2Changed,
                city: contractClosureCityChanged,
                stateId: contractClosureStateIdChanged,            
                zip: contractClosureZipcodeChanged,
            })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingContractClosureChanged(false);
                    setEnabledContractClosureChangedModal(false);
                    toast.dismiss(toastId);
                });                
            })
            .catch((error) => {
                console.error(error);
                setIndicatingContractClosureChanged(false);
                setEnabledContractClosureChangedModal(false);
                toast.dismiss(toastId);
            });
    };


    // CONTRACT FELL THROUGH
    const [enabledContractFellModal, setEnabledContractFellModal] = useState<boolean>(false);
    const contractFellModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '15%',
            margin: '0 auto',
            width: '600px',
            height: '400px',
            borderRadius: '16px',
            overflow: 'none',
        },
    };
    const [contractFellMessage, setContractFellMessage] = useState<string>('');
    const [indicatingContractFell, setIndicatingContractFell] = useState<boolean>(false);
    const indicateContractFell = (): void => {
        setContractFellMessage('');
        setEnabledContractFellModal(true);
    };
    const finalizeContractFell = (): void => {        
        const status = UpdateReferralStatus.contractFell;
        const message = contractFellMessage;
        
        const toastId = toast.loading(`Indicating that the contract fell!`);
        setIndicatingContractFell(true);

        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingContractFell(false);
                    setEnabledContractFellModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingContractFell(false);
                setEnabledContractFellModal(false);
                toast.dismiss(toastId);
            });
    };


    // CONTRACT CLOSED!
    const contractClosedModalFormRef = useRef<HTMLFormElement>(null);
    const [contractClosedDate, setContractClosedDate] = useState<Value | null>(null);
    const [contractClosedPrice, setContractClosedPrice] = useState<number>(-1);
    const formatAndUpdateContractClosedPrice = (event): void => {
        console.log(event.target.value);
        const strValue = event.target.value;
        const intValue = parseInt(strValue.replace(/\D/g, ''), 10);
        if (intValue <= 99999999) {
            setContractClosedPrice(intValue);
        }
    }
    const [contractClosedAddress1, setContractClosedAddress1] = useState<string>('');
    const [contractClosedAddress2, setContractClosedAddress2] = useState<string>('');
    const [contractClosedCity, setContractClosedCity] = useState<string>('');
    const [contractClosedStateId, setContractClosedStateId] = useState<number>(-1);
    const [contractClosedZipcode, setContractClosedZipcode] = useState<string>('');
    const validateContractClosedObjWithToasts = (obj): boolean => {
        if (obj.contractClosedPrice === -1) {
            toast.error("Error: Please enter a contract closure price!");
            return false;
        }
        if (referral?.referral.type === ReferralType.buy) {
            if (obj.contractClosedAddress1 === '') {
                toast.error("Error: Please enter an address in the 'Address Line 1' field!");
                return false;
            }
            if (obj.contractClosedCity === '') {
                toast.error("Error: Please enter a city!");
                return false;
            }
            if (obj.contractClosedStateId === -1) {
                toast.error("Error: Please select a state!");
                return false;
            }
            if (!validateZipcode(obj.contractClosedZipcode)) {
                toast.error("Error: Please enter a valid zip code!");
                return false;
            }
        }
        if (obj.contractClosedDate === null) {
            toast.error("Error: Please select a contract closure date!");
            return false;
        }
        return true;
    }
    const [enabledContractClosedModal, setEnabledContractClosedModal] = useState<boolean>(false);
    const [contractClosedMessage, setContractClosedMessage] = useState<string>('');
    const [indicatingClientContractClosed, setIndicatingClientContractClosed] = useState<boolean>(false);
    const indicateContractClosed = (): void => {
        
        setContractClosedMessage('');

        // get the most recent under contract object
        const underContractAction = referral?.referral.actions
                                    .filter((a) => a.type === ReferralAction.underContractClosingDate)
                                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
        if (underContractAction) {
            setContractClosureObj(underContractAction);
            
            setContractClosedDate(new Date(underContractAction.actionDate as string));
            setContractClosedPrice(underContractAction.price as number);
            setContractClosedAddress1(underContractAction.address1);
            setContractClosedAddress2(underContractAction.address2);
            setContractClosedCity(underContractAction.city);
            setContractClosedStateId(underContractAction.stateId);
            setContractClosedZipcode(underContractAction.zip);
        }

        setEnabledContractClosedModal(true);
    };
    const finalizeContractClosed = (): void => {        
        const status = UpdateReferralStatus.contractClosed;
        const message = contractClosedMessage;
        const isSell = referral?.referral.type === ReferralType.sell;
        const messageObj = buildStageMessage({
            contractClosedDate, contractClosedPrice: (contractClosedPrice === -1) ? (isSell ? referral?.referral.sellTargetPrice : contractClosedPrice) : contractClosedPrice,
            contractClosedAddress1, contractClosedAddress2, contractClosedCity, contractClosedStateId,
            contractClosedStateAbbr: usStatesList.find((s) => s.id === contractClosedStateId)?.abbreviation, contractClosedZipcode,
        }, message);

        if (!contractClosedModalFormRef.current?.reportValidity()) {
            return;   
        }

        if (!validateContractClosedObjWithToasts(messageObj)) {
            return;
        }

        let data;
        if (referral?.referral.type === ReferralType.buy) {
            data = {
                status,
                message,
                price: contractClosedPrice,
                date: contractClosedDate,
                address1: contractClosedAddress1,
                address2: contractClosedAddress2,
                city: contractClosedCity,
                stateId: contractClosedStateId,            
                zip: contractClosedZipcode,
            }
        }
        else {
            data = {
                status,
                message,
                price: (contractClosedPrice === -1) ? (isSell ? referral?.referral.sellTargetPrice : contractClosedPrice) : contractClosedPrice,
                date: (contractClosedDate as Date).toJSON(),
                address1: referral?.referral.sellAddress1,
                address2: referral?.referral.sellAddress2,
                city: referral?.referral.sellCity,
                stateId: referral?.referral.sellStateId,
                zip: referral?.referral.sellZip,
            }
        }

        const toastId = toast.loading(`Indicating that the client contract is closed! Congratulations!!`);
        setIndicatingClientContractClosed(true);

        postReferralsUpdateContactStatus(referral?.referral.id as number, data)
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingClientContractClosed(false);
                    setEnabledContractClosedModal(false);
                    toast.dismiss(toastId);
                });                
            })
            .catch((error) => {
                console.error(error);
                setIndicatingClientContractClosed(false);
                setEnabledContractClosedModal(false);
                toast.dismiss(toastId);
            });
    }



    // REFERRAL CHECK ISSUED
    const [enabledReferralCheckIssuedModal, setEnabledReferralCheckIssuedModal] = useState<boolean>(false);
    const [referralCheckIssuedMessage, setReferralCheckIssuedMessage] = useState<string>('');
    const [indicatingReferralCheckIssued, setIndicatingReferralCheckIssued] = useState<boolean>(false);
    const indicateReferralCheckIssued = (): void => {
        setReferralCheckIssuedMessage("");
        setEnabledReferralCheckIssuedModal(true);
    };
    const finalizeReferralCheckIssued = (): void => {
        setIndicatingReferralCheckIssued(true);
        const toastId = toast.loading(`Notifying that the referral check is issued to Referring Agent ${referral?.referral.userFirstName} ${referral?.referral.userFirstName}. Congratulations!!`);
        
        const status = UpdateReferralStatus.referralCheckIssued;
        const message = referralCheckIssuedMessage;

        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingReferralCheckIssued(false);
                    setEnabledReferralCheckIssuedModal(false);
                    setReferralCheckIssuedMessage("");
                    toast.dismiss(toastId);
                });                
            })
            .catch((error) => {
                console.error(error);
                setIndicatingReferralCheckIssued(false);
                setEnabledReferralCheckIssuedModal(false);
                setReferralCheckIssuedMessage("");
                toast.dismiss(toastId);
            });
    }


    // SEND A MESSAGE
    const MAX_MSG_CHARS = 400;
    const [msgContent, setMsgContent] = useState<string>("");
    const [msgCharsLeft, setMsgCharsLeft] = useState<number>(MAX_MSG_CHARS);
    const [isSendingMsg, setIsSendingMsg] = useState<boolean>(false);
    const msgLengthCheck = (e): void => {
        const str = e.target.value;

        setMsgContent(str);
        setMsgCharsLeft(MAX_MSG_CHARS - str.length);
    };
    const sendMessage = (): void => {
        console.log(msgContent);

        if (msgContent === '') {
            toast.error("Error: Please enter a valid message!");
            return;
        }

        setIsSendingMsg(true);

        const data = {
            agentId: authState.id,
            note: msgContent,
            referralId: referral?.referral.id,
            date: new Date().toString()
        };

        postReferralNote(data as TSendReferralsNoteData).then((result) => {

            console.log(result)
            getReferral(() => {
                setIsSendingMsg(false);

                setMsgContent("");
                setMsgCharsLeft(MAX_MSG_CHARS);
            });

        }).catch((error) => {
            console.error(error);
        })
    };


    // POST A REVIEW
    const MAX_REVIEW_CHARS = 400;
    const [reviewContent, setReviewContent] = useState<string>("");
    const [reviewRating, setReviewRating] = useState<number>(-1);
    const [reviewCharsLeft, setReviewCharsLeft] = useState<number>(MAX_REVIEW_CHARS);
    const [isSendingReview, setIsSendingReview] = useState<boolean>(false);


    // STATUS MESSAGE Widget
    const MAX_STATUS_MESG_CHARS = 500;
    const [statusMessageText, setStatusMessageText] = useState<string>('');
    const [statusMessageCharsLeft, setStatusMessageCharsLeft] = useState<number>(0);
    const statusMessageLengthCheck = (e): void => {
        const str = e.target.value;
        setStatusMessageText(str);
        setStatusMessageCharsLeft(MAX_STATUS_MESG_CHARS - str.length);
    };
    const resetStatusMessageBox = (): void => {
        setStatusMessageText('');
        setStatusMessageCharsLeft(MAX_STATUS_MESG_CHARS);
    };
    

    // LOG A CALL
    const [enabledCallLogModal, setEnabledCallLogModal] = useState<boolean>(false);
    const [isSubmittingCallLog, setIsSubmittingCallLog] = useState<boolean>(false);
    const callLogModalLabel = 'Log A Client Call';
    const callLogModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '15%',
            margin: '0 auto',
            width: '600px',
            height: '500px',
            borderRadius: '16px',
            overflow: 'none',
        },
    };
    const showCallLogModal = (): void => {
        resetStatusMessageBox();
        setEnabledCallLogModal(true);
    };
    const submitCallLog = (): void => {
        setIsSubmittingCallLog(true);
        
        const data: TSendReferralsCallsData = {
            referralId: referral?.referral.id as number,
            calleeId: referral?.referral.userId as number,
            notes: statusMessageText,
        };

        postReferralsCalls(data)
            .then(() => {
                toast.info("Call Details Logged!");
                
                setIsSubmittingCallLog(false);
                setEnabledCallLogModal(false);
            })
            .catch((error) => {
                console.error(error);

                setIsSubmittingCallLog(false);
                setEnabledCallLogModal(false);
            });
    };


    // RETURN REFERRAL
    const [enabledReturnReferralModal, setEnabledReturnReferralModal] = useState<boolean>(false);
    const [returnReferralMessage, setReturnReferralMessage] = useState<string>('');
    const [indicatingReturnReferral, setIndicatingReturnReferral] = useState<boolean>(false);
    const indicateReturnReferral = (): void => {
        setReturnReferralMessage('');
        setEnabledReturnReferralModal(true);
    };
    const finalizeReturnReferral = (): void => {
        
        setIndicatingReturnReferral(true);

        const message = (returnReferralMessage ?? '');
        const toastId = toast.loading(`Indicating that you are returning this referral!`);
        let status = UpdateReferralStatus.returnReferral;

        postReferralsUpdateContactStatus(referral?.referral.id as number, { status, message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingReturnReferral(false);
                    setEnabledReturnReferralModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setIndicatingReturnReferral(false);
                setEnabledReturnReferralModal(false);
                toast.dismiss(toastId);
            });
    };
    const returnReferralModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '15%',
            margin: '0 auto',
            width: '600px',
            height: '400px',
            borderRadius: '16px',
            overflow: 'none',
        },
    };

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isIPhone, setIsIPhone] = useState<boolean>(false);
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false);
    useEffect(() => {
        setIsFirstLoading(true);
        setIsMobile(isMobileBrowser());
        setIsIPhone(isIPhoneBrowser());

        getReferral(() => {
            setIsFirstLoading(false);
        });

        return () => {
            setReferral(null);
        };
    }, []);

    const GET_REFERRAL_INTERVAL_MS = 10000;
    useEffect(() => {
        const interval = setInterval(getReferral, GET_REFERRAL_INTERVAL_MS);
        return () => {
            clearInterval(interval);
            setReferral(null);
        }
    }, []);

    const [numPages, setNumPages] = useState<number>(-1);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};
    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
	const goToNextPage = () => setPageNumber( (pageNumber + 1 >= numPages) ? numPages : pageNumber + 1);

    return (
        <>
            {/* Signature Viewer Modal */}
            <Modal
                isOpen={agreementModalVisible}
                contentLabel={agreementModalLabel}
                style={agreementModalStyle}
                ariaHideApp={false}
            >
                <div className="flex flex-1 h-[95%] flex-col gap-[20px] p-[10px]">
                    <div className="flex flex-row gap-[20px]">
                        <h1 className="flex flex-1 items-center justify-start">{agreementModalLabel}</h1>
                        <div className="items-center align-middle">
                            <button
                                className="items-center justify-center cursor-pointer w-[100px] [border:none] bg-brand-red rounded-md h-10 box-border gap-[10px]"
                                onClick={() => { setAgreementModalVisible(false) }}
                            >
                                <div className="relative text-sm leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center">
                                    Close
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-1 h-[85%]">
                        {isMobile
                            ? <div className="flex flex-col gap-[20px] items-center">
                                <nav className="w-full flex flex-row gap-[20px] ml-[70px] items-center justify-start">
                                    <div className="flex bg-secondary text-white p-[10px] cursor-pointer rounded-lg" onClick={goToPrevPage}>Prev</div>
                                    <div className="flex bg-secondary text-white p-[10px] cursor-pointer rounded-lg" onClick={goToNextPage}>Next</div>
                                    <span className="flex text-3xl-1 font-semibold">Page {pageNumber} of {numPages}</span>
                                    {!isIPhone &&
                                        <a className="flex text-5xl font-semibold cursor-pointer text-secondary no-underline hover:underline" href={agreementUrl} download={`ReferralAgreement_${referralId}_${referral?.referral.userFirstName}${referral?.referral.userLastName}_${referral?.referral.agentFirstName}${referral?.referral.agentLastName}.pdf`}>Download</a>
                                    }
                                </nav>
                                <Document file={agreementUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} width={1100} />
                                </Document>
                                <div className="w-full">
                                    <br /><br />
                                </div>
                            </div>
                            : <object type="application/pdf" data={agreementUrl} style={{ height: '100%', width: '100%' }}>
                                <p>Oops! Your browser doesn't support PDFs!</p>
                                <p><a href={agreementUrl} download={`ReferralAgreement_${referralId}_${referral?.referral.userFirstName}${referral?.referral.userLastName}_${referral?.referral.agentFirstName}${referral?.referral.agentLastName}.pdf`}>Download Instead</a></p>
                            </object>
                        }
                    </div>
                </div>
            </Modal>

            {/* Call Log Modal */}
            <Modal
                isOpen={enabledCallLogModal}
                contentLabel={callLogModalLabel}
                style={callLogModalStyle}
                ariaHideApp={false}
            >
                <div className="flex flex-col h-[95%] gap-0 p-[10px]">

                    <div className="flex flex-col gap-[20px]">
                    
                        <h2 className="flex items-center justify-start">
                            Log a call with the client
                        </h2>
                    
                        <div className="flex flex-row gap-[20px]">
                            <div className="flex flex-col gap-[10px]">
                                <div><b>Client Name:</b> {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                <div><b>Client Phone:</b> {referral?.referral.clientPhone}</div>
                                <div><b>Client Email:</b> {referral?.referral.clientEmail}</div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-[5px] justify-end">
                            <textarea className="flex resize-none overflow-y-auto text-md font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border border-solid border-gray-400 outline-0"
                                rows={9}
                                cols={50}
                                maxLength={MAX_STATUS_MESG_CHARS}
                                onChange={statusMessageLengthCheck}
                                placeholder="Add additional details here ..."
                                value={statusMessageText}
                                disabled={isSubmittingCallLog}
                            />
                            <span>{statusMessageCharsLeft} characters left</span>
                        </div>
                        
                        <div className="self-stretch w-full flex flex-row justify-end">
                            <WaitContainer shouldWaitIf={isSubmittingCallLog}>
                                <div className="flex flex-row gap-[20px]">
                                    <button
                                        className="items-center justify-center cursor-pointer w-[100px] [border:none] rounded-md h-10 box-border gap-[10px]"
                                        onClick={() => setEnabledCallLogModal(false)}
                                    >
                                        <div className="relative text-sm leading-[21px] font-semibold font-large-med text-secondary text-center">
                                            Cancel
                                        </div>
                                    </button>
                                    <button
                                        className="items-center justify-center cursor-pointer w-[100px] [border:none] bg-secondary rounded-md h-10 box-border gap-[10px]"
                                        onClick={submitCallLog}
                                    >
                                        <div className="relative text-sm leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center">
                                            Submit Call
                                        </div>
                                    </button>
                                </div>
                            </WaitContainer>
                        </div>
                        
                    </div>
                </div>
            </Modal>


            {!isValidReferral
                ? <div className="w-full flex flex-row gap-[40px] justify-center">
                    <div className="w-[60%] mt-[5%] relative rounded-lg flex flex-col p-20 box-border items-start justify-start gap-[20px] text-base bg-light-background-background-1 shadow-xl">
                        <Page404 />
                    </div>
                </div>
                : <WaitContainer shouldWaitIf={isFirstLoading} message="Loading Referral ... " waitSizePx={100}>
                    <div className="relative mt-[50px] w-[1280px] h-full mx-auto flex flex-col gap-[20px] text-left text-primary font-small-semibold">

                        {/* BREADCRUMBS */}
                        <div className="relative flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-center justify-start gap-[7px]">
                                <div className="relative leading-[21px]">{`Referrals  `}</div>
                                <img
                                    className="relative w-2.5 h-2.5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/mra-referral-timeline/iconchevronrightsolid.svg"
                                />
                                <div className="relative leading-[21px] text-tertiary">
                                    {referral?.referral.clientFirstName} {referral?.referral.clientLastName}
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex flex-row gap-[40px]">

                            {/* REFERRAL */}
                            <div className="relative rounded-lg flex flex-1 flex-col p-4 box-border items-start justify-start gap-[20px] text-base bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]">

                                {/* REFERRAL HEADING */}
                                <div className="self-stretch flex flex-row pt-0 px-0 pb-4 items-start justify-start text-lg">
                                    
                                    {/* Referral Info */}
                                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                        <div className="relative flex flex-row items-start justify-start gap-[8px] leading-[140%] font-medium">
                                            <img
                                                className="relative w-6 h-6 overflow-hidden shrink-0"
                                                alt=""
                                                src="/img/mra-dashboard/inbound.svg"
                                            />
                                            <span>Inbound {ReferralTypeStr[ReferralType[referral?.referral.type as ReferralType]]} Referral</span>

                                            {/* <img
                                                className="relative w-6 h-6 overflow-hidden shrink-0"
                                                alt=""
                                                src="/img/mra-referral-timeline/iconstars.svg"
                                            /> */}
                                        </div>
                                        <div className="self-stretch relative text-base leading-[24px] text-tertiary flex flex-col gap-[8px]">
                                            <span>
                                                Received on {getLocaleDateTimeWithTzStrFullDay(referralRequestedDate)}.
                                            </span>
                                            {agentCreatedInboundReferral
                                                && <span>
                                                    You created this Inbound Referral and invited {referral?.referral.userFirstName}.
                                                </span>
                                            }
                                        </div>
                                        {/* <div className="self-stretch relative text-base leading-[24px] text-tertiary">
                                            Status: {ReferralStatusStr[ReferralStatus[referral?.referral.status as ReferralStatus]]}
                                        </div> */}

                                        {/* Display Referral Agreement Download Link */}
                                        {referral?.referral.signedAgreement === "DOCUSIGN"
                                            && <div className="self-stretch relative text-base leading-[24px] text-tertiary flex flex-row gap-[8px] items-center">
                                                <div className="flex flex-row text-link-blue cursor-pointer hover:underline items-center gap-[10px]" onClick={viewAgreement}>
                                                    Signed Referral Agreement 
                                                </div>
                                                <img
                                                    className="relative w-[20px] h-[20px] overflow-hidden shrink-0 rounded-xl cursor-pointer"
                                                    alt=""
                                                    src="/img/mra-referral-timeline/icon-open.svg"
                                                />
                                            </div>
                                        }

                                        {/* Display Sending Agent's W-9 Link */}
                                        {(homeUnderContract || contractClosed || referralCheckIssued || referralCheckReceived) && (peerW9List.length > 0)
                                            && <div className="self-stretch relative text-base leading-[24px] text-tertiary flex flex-row gap-[8px] items-center">
                                                <div className="flex flex-row text-link-blue cursor-pointer hover:underline items-center gap-[10px]" onClick={() => { downloadW9(peerW9List[0].fields.w9_year) }}>
                                                    Download {referral?.referral.userFirstName} {referral?.referral.userLastName}&apos;s broker&apos;s W-9
                                                    <img
                                                        className="relative w-[20px] h-[20px] overflow-hidden shrink-0 rounded-xl cursor-pointer"
                                                        alt=""
                                                        src="/img/mra-referral-timeline/icon-open.svg"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {/* Referring Agent Info */}
                                    <div className="flex flex-col gap-[16px]">
                                        <div className="self-stretch rounded-sm flex flex-row items-center justify-start text-lg">
                                            <b className="relative leading-[25px]">Referring Agent</b>
                                        </div>
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[18px] text-tertiary">
                                            <div className="flex-1 flex flex-col items-start justify-start gap-[20px]">

                                                <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[18px] text-tertiary">
                                                    
                                                    {/* Referring Agent Photo */}
                                                    <img
                                                        className="relative w-[70px] h-[70px] shrink-0 rounded-lg"
                                                        alt=""
                                                        src={referral?.referral.userPrimaryImage ? getAvatar(referral?.referral.userPrimaryImage, 200) : defaultAvatar}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = defaultAvatar;
                                                        }}
                                                    />

                                                    
                                                    <div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
                                                        
                                                        {/* Referring Agent Profile Link */}
                                                        <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
                                                            <div className="self-stretch flex flex-row items-start justify-start gap-[6px] text-base text-brand-primary">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    {referral?.referral.userFirstName} {referral?.referral.userLastName}
                                                                </div>
                                                            </div>
                                                            <img
                                                                className="relative w-[20px] h-[20px] overflow-hidden shrink-0 rounded-xl cursor-pointer"
                                                                alt=""
                                                                title={`Visit ${referral?.referral.agentFirstName}'s Profile`}
                                                                src="/img/mra-referral-timeline/icon-open.svg"
                                                                onClick={() => goToProfile(referral?.referral.userPublicProfile)}
                                                            />
                                                        </div>
                                                        
                                                        {/* Referring Agent Phone */}
                                                        <div className="flex-1 flex flex-col items-start justify-start gap-[6px] text-sm text-secondary mr-[16px]">
                                                        {!!referral?.referral.userCellPhone
                                                                && <span>
                                                                    Cell: <a className="text-link-blue no-underline hover:underline" href={`tel:${referral?.referral.userCellPhone}`}>{referral?.referral.userCellPhone}</a>
                                                                </span>
                                                            }
                                                            {!!referral?.referral.userOfficePhone
                                                                && <span>
                                                                    Office: <a className="text-link-blue no-underline hover:underline" href={`tel:${referral?.referral.userOfficePhone}`}>{referral?.referral.userOfficePhone}</a>
                                                                </span>
                                                            }
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* CURRENT STATE AND ACTIVITY */}
                                <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg">

                                    {/* DECLINED/CANCELED/RETURNED */}
                                    {(
                                        referralCancelled
                                        || referralReturned || referralReturnedNotBuying || referralReturnedHiredAnother
                                        || referralDeclined
                                    ) &&
                                        <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg ">

                                            {/* DECLINED/CANCELED/RETURNED HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start rounded-lg ">
                                                <div className="flex-1 bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start rounded-lg ">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Declined/Canceled/Returned
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(currReferralAction.date)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* OPPORTUNITY STEPS */}
                                            <div className="flex flex-row gap-[16px] p-[20px]">
                                                {/* You have declined the referral */}
                                                {referralDeclined &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>You have declined the referral from {referral?.referral.userFirstName} {referral?.referral.userLastName}!</span>
                                                    </div>
                                                }

                                                {/* Sender has canceled the referral */}
                                                {referralCancelled &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>Unfortunately, {referral?.referral.userFirstName} {referral?.referral.userLastName} has canceled this referral!</span>
                                                        <div className="flex flex-col gap-[4px] ml-[20px]">
                                                            <b>Reason: </b>
                                                            <div className="whitespace-pre-line leading-snug">
                                                                {currReferralAction.message ?? "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                
                                                {/* Recipient has returned the referral */}
                                                {(referralReturned || referralReturnedNotBuying || referralReturnedHiredAnother)
                                                    && <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">
                                                            {ReferralActionStr[ReferralAction[currReferralAction?.type]]}
                                                            {referralReturnedNotBuying && <span>: Client Does Not Wish To Buy</span>}
                                                            {referralReturnedHiredAnother && <span>: Client Hired Another Agent</span>}
                                                        </b>
                                                        <span>{referral?.referral.agentFirstName} has returned this referral!</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }


                                    {/* OPPORTUNITY */}
                                    {(
                                        awaitingReferralAcceptance || awaitingAgreement || agreementDeclined
                                        || awaitingAgreementSignature || awaitingAgreementSignatureAcceptance
                                    ) &&
                                        <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg ">

                                            {/* OPPORTUNITY HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start rounded-lg ">
                                                <div className="flex-1 bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start rounded-lg ">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Opportunity
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referralRequestedDate)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* OPPORTUNITY STEPS */}
                                            <div className="flex flex-row gap-[16px] p-[20px]">

                                                {/* <img
                                                    className="relative w-[105px] h-[105px] overflow-hidden shrink-0 rounded-xl"
                                                    alt=""
                                                    src={referral?.referral.userPrimaryImage ? getAvatar(referral?.referral.userPrimaryImage, 200) : defaultAvatar}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = defaultAvatar;
                                                    }}
                                                /> */}

                                                {/* You have yet to accept or decline the referral */}
                                                {awaitingReferralAcceptance &&
                                                    <div className="flex flex-col gap-[16px] p-[20px]">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <div className="flex flex-col gap-[16px] justify-start items-start">
                                                                <b className="capitalize">New Referral Opportunity</b>
                                                                <span>Congratulations! {referral?.referral.userFirstName} {referral?.referral.userLastName} selected you as an agent to own this referral.</span>
                                                                <span className=""><b>Next:</b> Accept or Decline the Referral!</span>
                                                            </div>

                                                            <div className="flex flex-row gap-[16px] items-start">
                                                                
                                                                <WaitContainer shouldWaitIf={acceptingOrDecliningReferral}>
                                                                    <button
                                                                        className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                        onClick={finalizeAcceptReferral}
                                                                    >
                                                                        <div className="uppercase w-[358px] relative text-base leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                            Next: Accept This Referral
                                                                        </div>
                                                                        {/* <img
                                                                            className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                            alt=""
                                                                            src="/img/mra-dashboard/iconthumbsupsolid.svg"
                                                                        /> */}
                                                                    </button>
                                                                    
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        onClick={declineReferral}
                                                                    >
                                                                        <div className="uppercase relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Decline This Referral
                                                                        </div>
                                                                        {/* <img
                                                                            className="relative w-[18px] h-[18px] overflow-hidden shrink-0 rotate-180"
                                                                            alt=""
                                                                            src="/img/mra-dashboard/iconthumbsupsolid.svg"
                                                                        /> */}
                                                                    </button>
                                                                </WaitContainer>

                                                                <Modal
                                                                    isOpen={showDeclineReferralModal}
                                                                    contentLabel="Decline This Referral"
                                                                    style={acceptDeclineModalStyle}
                                                                    ariaHideApp={false}
                                                                >
                                                                    <div className="flex flex-col h-[95%] gap-0 p-[10px]">
                                                                        <div className="flex flex-col gap-[20px]">
                                                                            <h2 className="flex items-center justify-start">Decline This Referral</h2>
                                                                            <div className="flex flex-row gap-[20px]">
                                                                                <img
                                                                                    className="relative w-[85px] h-[85px] overflow-hidden shrink-0 rounded-xl"
                                                                                    alt=""
                                                                                    src={referral?.referral.userPrimaryImage ? getAvatar(referral?.referral.userPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                                                    onError={({ currentTarget }) => {
                                                                                        currentTarget.onerror = null; // prevents looping
                                                                                        currentTarget.src = DEFAULT_AVATAR_PATH;
                                                                                    }}
                                                                                />
                                                                                <div className="flex flex-col gap-[10px]">
                                                                                    <div className="text-lg font-bold">
                                                                                        {referral?.referral.userFirstName} {referral?.referral.userLastName} (Referring Agent)
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="flex flex-col gap-[5px] justify-end">
                                                                                <textarea className="flex resize-none overflow-y-auto text-md font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border border-solid border-gray-400 outline-0"
                                                                                    rows={9}
                                                                                    cols={50}
                                                                                    maxLength={MAX_REFERRAL_DECLINE_CHARS}
                                                                                    onChange={declineReferralMessageLengthCheck}
                                                                                    placeholder="Please type a reason to decline this referral ..."
                                                                                    value={declineReferralMessage}
                                                                                    disabled={acceptingOrDecliningReferral}
                                                                                />
                                                                                <span>{declineReferralMessageCharsLeft} characters left</span>
                                                                            </div>

                                                                            <div className="self-stretch w-full flex flex-row justify-end">
                                                                                <WaitContainer shouldWaitIf={acceptingOrDecliningReferral}>
                                                                                    <div className="flex flex-row gap-[20px]">
                                                                                        <button
                                                                                            className="items-center justify-center cursor-pointer w-[100px] [border:none] rounded-md h-10 box-border gap-[10px]"
                                                                                            onClick={() => setShowDeclineReferralModal(false)}
                                                                                        >
                                                                                            <div className="relative text-sm leading-[21px] font-semibold font-large-med text-secondary text-center">
                                                                                                Cancel
                                                                                            </div>
                                                                                        </button>
                                                                                        <button
                                                                                            className="flex flex-row items-center justify-center cursor-pointer [border:none] bg-darkslategray-100 rounded-md h-10 box-border gap-[5px] px-[20px]"
                                                                                            onClick={() => finalizeDeclineReferral() }
                                                                                        >
                                                                                            <div className="relative text-sm leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center">
                                                                                                Continue To Decline Referral
                                                                                            </div>
                                                                                            <img
                                                                                                className="relative w-[18px] h-[18px] overflow-hidden shrink-0 rotate-180"
                                                                                                alt=""
                                                                                                src="/img/mra-dashboard/iconthumbsupsolid.svg"
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                </WaitContainer>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Modal>

                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* You are waiting for Referring Agent to send you the Agreement */}
                                                {awaitingAgreement &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        {agentCreatedInboundReferral
                                                            ? <span>You created this Inbound Referral and invited {referral?.referral.userFirstName} {referral?.referral.userLastName}.</span>
                                                            : <span>You have accepted a referral from {referral?.referral.userFirstName}.</span>
                                                        }
                                                        <span className=""><b>Next:</b> Waiting for the Referral Agreement from {referral?.referral.userFirstName}.</span>
                                                    </div>
                                                }

                                                {/* Agreement is Sent: You have to sign */}
                                                {/* Also create function to sign and update state */}
                                                {awaitingAgreementSignature &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            {/* <b>{getLocaleDateTimeWithTzStr(referral?.referral.actions.find((a: TReferralAction) => [ReferralAction.agreementSent].includes(a.type))?.date as string)}</b> */}
                                                            <b>{currReferralAction?.type === ReferralAction.agreementSent && "Referral Agreement Received"}</b>
                                                            <span>
                                                                Congratulations! {referral?.referral.userFirstName} has sent you the Referral Agreement!
                                                            </span>
                                                            <span className=""><b>Next:</b> Sign the Referral Agreement</span>
                                                        </div>
                                                        <div>
                                                            <WaitContainer waitSizePx={28} shouldWaitIf={signingAgreement}>
                                                                <div className="flex flex-row gap-[16px] justify-start items-start">
                                                                    <button
                                                                        className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                        onClick={viewAndSignAgreement}
                                                                    >
                                                                        <div className="uppercase w-[378px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                            Next: Accept &amp; Sign Referral Agreement
                                                                        </div>
                                                                        {/* <img
                                                                            className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                            alt=""
                                                                            src="/img/mra-referral-timeline/iconthumbsupsolid.svg"
                                                                        /> */}
                                                                    </button>

                                                                    {!showDeclineReferralModal
                                                                        ? <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                            onClick={declineReferral}
                                                                        >
                                                                            <div className="uppercase relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                                Decline Referral
                                                                            </div>
                                                                            {/* <img
                                                                                className="relative w-[18px] h-[18px] overflow-hidden shrink-0 rotate-180"
                                                                                alt=""
                                                                                src="/img/mra-dashboard/iconthumbsupsolid.svg"
                                                                            /> */}
                                                                        </button>
                                                                        : <div className="flex flex-col items-end justify-end gap-[8px]">
                                                                            <textarea className="flex resize-none overflow-y-auto text-md font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border border-solid border-gray-400 outline-0"
                                                                                rows={4}
                                                                                cols={50}
                                                                                maxLength={MAX_REFERRAL_DECLINE_CHARS}
                                                                                onChange={declineReferralMessageLengthCheck}
                                                                                placeholder="Reason to decline? (optional)"
                                                                                value={declineReferralMessage}
                                                                            />
                                                                            <div className="flex flex-row items-center gap-[16px]">
                                                                                <div
                                                                                    className="text-link-blue cursor-pointer"
                                                                                    onClick={() => setShowDeclineReferralModal(false)}
                                                                                >
                                                                                    Cancel
                                                                                </div>
                                                                                <button
                                                                                    className="cursor-pointer [border:none] py-2 px-4 bg-darkslategray-100 text-white flex-1 rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                                    onClick={() => finalizeDeclineReferral()}
                                                                                >
                                                                                    <div className="relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                                        Decline Referral
                                                                                    </div>
                                                                                    <img
                                                                                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0 rotate-180"
                                                                                        alt=""
                                                                                        src="/img/mra-dashboard/iconthumbsupsolid.svg"
                                                                                    />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </WaitContainer>
                                                        </div>
                                                        
                                                    </div>
                                                }

                                                {/* You declined the Agreement */}
                                                {agreementDeclined &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        {/* <b>{getLocaleDateTimeWithTzStr(referral?.referral.actions.find((a: TReferralAction) => [ReferralAction.referralDeclined].includes(a.type))?.date as string)}</b> */}
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>You declined the Referral Agreement from {referral?.referral.userFirstName}</span>
                                                    </div>
                                                }

                                                {/* Waiting for Referring Agent to accept your Signature */}
                                                {awaitingAgreementSignatureAcceptance &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>Congratulations! You signed the Referral Agreement!</span>
                                                        <span className=""><b>Next:</b> Waiting for {referral?.referral.userFirstName} to accept your Signature!</span>
                                                        
                                                        {/* Other Update Options */}
                                                        {/* <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateReturnReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledReturnReferralModal}
                                                                        genericActionTitle="Sorry That You're Returning This Referral!"
                                                                        genericActionMessage={returnReferralMessage}
                                                                        setGenericActionMessage={setReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferral}
                                                                        isPerformingAction={indicatingReturnReferral}
                                                                        genericActionMessagePlaceholder="Please let us know why you are returning this referral ... "
                                                                        styleOverride={returnReferralModalStyle}
                                                                    >
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }


                                    {/* ACTIVE */}
                                    {(agreementSignatureAccepted || clientContacted || clientAppointmentSet || clientAgreementSigned || showingHouses)
                                        && <div className="self-stretch flex flex-col items-start justify-start bg-whitesmoke">

                                            {/* ACTIVE HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Referral Active
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions.find((a: TReferralAction) => [ReferralAction.signedAgreementConfirmed].includes(a.type))?.date as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* ACTIVE STEPS */}
                                            <div className="flex flex-row gap-[16px] p-[20px]">
                                                {/* <img
                                                    className="relative w-[105px] h-[105px] overflow-hidden shrink-0 rounded-xl"
                                                    alt=""
                                                    src={referral?.referral.userPrimaryImage ? getAvatar(referral?.referral.userPrimaryImage, 200) : defaultAvatar}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = defaultAvatar;
                                                    }}
                                                /> */}

                                                {/* Referring Agent is now waiting for you to indicate that you have contacted the client */}
                                                {agreementSignatureAccepted &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>Congratulations! {referral?.referral.userFirstName} has confirmed your signature on the Referral Agreement!</span>
                                                            <span>The referral is now active and the hidden client information is now revealed.</span>
                                                            <span className=""><b>Next:</b> Indicate when you have contacted the client.</span>
                                                        </div>

                                                        <div className="flex flex-row flex-grow items-top justify-start gap-[13px]">
                                                            <button
                                                                className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                onClick={indicateContactWithClient}
                                                            >
                                                                <div className="uppercase w-[310px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                    Next: Client Contacted
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledContactWithClientModal}
                                                                setEnabledGenericActionModal={setEnabledContactWithClientModal}
                                                                genericActionTitle="You have contacted the client"
                                                                genericActionMessage={contactWithClientMessage}
                                                                setGenericActionMessage={setContactWithClientMessage}
                                                                genericActionButtonLabel="I Have Contacted The Client"
                                                                genericActionFunction={finalizeContactWithClient}
                                                                isPerformingAction={indicatingContactWithClient}
                                                                genericActionMessagePlaceholder="Add additional details, if any ... "
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[10px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>

                                                            <button
                                                                className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                autoFocus={false}
                                                                onClick={indicateClientNotContacted}
                                                            >
                                                                <div className="uppercase relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                    Not Contacted
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledClientNotContactedModal}
                                                                setEnabledGenericActionModal={setEnabledClientNotContactedModal}
                                                                genericActionTitle="You have not yet contacted the client."
                                                                genericActionMessage={clientNotContactedMessage}
                                                                setGenericActionMessage={setClientNotContactedMessage}
                                                                genericActionButtonLabel="I Have Not Yet Contacted The Client"
                                                                genericActionFunction={finalizeClientNotContacted}
                                                                isPerformingAction={indicatingClientNotContacted}
                                                                genericActionMessagePlaceholder="Add a reason for not contacting the client ... "
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[10px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>

                                                            <button
                                                                className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                autoFocus={false}
                                                                onClick={indicateClientContactedNoResponse}
                                                            >
                                                                <div className="uppercase relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                    Client Not Responding
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledClientContactedNoResponseModal}
                                                                setEnabledGenericActionModal={setEnabledClientContactedNoResponseModal}
                                                                genericActionTitle="You attempted to contact the client but received no response!"
                                                                genericActionMessage={clientContactedNoResponseMessage}
                                                                setGenericActionMessage={setClientContactedNoResponse}
                                                                genericActionButtonLabel="Contacted Client, No Response"
                                                                genericActionFunction={finalizeClientContactedNoResponse}
                                                                isPerformingAction={indicatingClientContactedNoResponse}
                                                                genericActionMessagePlaceholder="Add additional details, if any ... "
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[10px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>
                                                        </div>

                                                        {/* Other Update Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Log a Call */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={showCallLogModal}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Log A Client Call
                                                                        </div>
                                                                    </button>

                                                                    {/* No Response: Return Referral */}                                                        
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-secondary rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateReturnReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-white text-center">
                                                                            No Response: Return Referral
                                                                        </div>
                                                                    </button> */}

                                                                    {/* Return Referral */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateReturnReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledReturnReferralModal}
                                                                        genericActionTitle="Sorry That You're Returning This Referral!"
                                                                        genericActionMessage={returnReferralMessage}
                                                                        setGenericActionMessage={setReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferral}
                                                                        isPerformingAction={indicatingReturnReferral}
                                                                        genericActionMessagePlaceholder="Please let us know why you are returning this referral ... "
                                                                        styleOverride={returnReferralModalStyle}
                                                                    >
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Referring Agent is now waiting for you to indicate that you have signed the client agreement */}
                                                {clientContacted &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>You have contacted the client.</span>
                                                            <span className=""><b>Next:</b> Indicate whether you scheduled an appointment with the client.</span>
                                                        </div>

                                                        <div className="flex flex-row gap-[16px] justify-start items-start">
                                                            <button
                                                                className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                onClick={indicateClientAppointmentSet}
                                                            >
                                                                <div className="uppercase w-[358px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                    Next: Client Appointment Scheduled
                                                                </div>
                                                                {/* <img
                                                                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                    alt=""
                                                                    src="/img/mra-referral-timeline/iconthumbsupsolid.svg"
                                                                /> */}
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledClientAppointmentSetModal}
                                                                setEnabledGenericActionModal={setEnabledClientAppointmentSetModal}
                                                                genericActionTitle="Congratulations! You have scheduled an appointment with the client."
                                                                genericActionMessage={clientAppointmentSetMessage}
                                                                setGenericActionMessage={setClientAppointmentSetMessage}
                                                                genericActionButtonLabel="Appointment Scheduled With Client"
                                                                genericActionFunction={finalizeClientAppointmentSet}
                                                                isPerformingAction={indicatingClientAppointmentSet}
                                                                styleOverride={clientAppointmentSetModalStyle}
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[30px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                        <div className="flex flex-row text-lg font-bold gap-[10px]">
                                                                            <div className="flex flex-col text-lg font-bold gap-[10px]">
                                                                                <span>Appointment Date?</span>
                                                                                <Calendar
                                                                                    onChange={(date) => setClientAppointmentDate(date)}
                                                                                    defaultActiveStartDate={new Date()}
                                                                                />
                                                                            </div>
                                                                            {/* <div className="flex flex-col text-lg font-bold gap-[10px]">
                                                                                <form ref={clientAppointmentTimeFormRef} className="flex flex-col text-lg font-bold gap-[10px]">
                                                                                    <span>Time?</span>
                                                                                    <Select
                                                                                        className="w-[120px]"
                                                                                        name="appointmentSetTimeHrs"
                                                                                        theme={globalReactSelectTheme}
                                                                                        options={clientAppointmentTimeHrOptions}
                                                                                        onChange={(selected) => setClientAppointmentTimeHr(selected.value)}
                                                                                        placeholder="Hour"
                                                                                        required
                                                                                    />
                                                                                    <Select
                                                                                        className="w-[120px]"
                                                                                        name="appointmentSetTimeMins"
                                                                                        theme={globalReactSelectTheme}
                                                                                        options={clientAppointmentTimeMinOptions}
                                                                                        onChange={(selected) => setClientAppointmentTimeMin(selected.value)}
                                                                                        placeholder="Min"
                                                                                        required
                                                                                    />
                                                                                    <Select
                                                                                        className="w-[120px]"
                                                                                        name="appointmentSetTimeAmPm"
                                                                                        theme={globalReactSelectTheme}
                                                                                        options={clientAppointmentTimeAmPmOptions}
                                                                                        onChange={(selected) => setClientAppointmentTimeAmPm(selected.value)}
                                                                                        placeholder="AM/PM"
                                                                                        required
                                                                                    />
                                                                                </form>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>

                                                            <button
                                                                className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                autoFocus={false}
                                                                onClick={indicateClientAppointmentNotSet}
                                                            >
                                                                <div className="uppercase relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                    Did Not Schedule Appointment
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledClientAppointmentNotSetModal}
                                                                setEnabledGenericActionModal={setEnabledClientAppointmentNotSetModal}
                                                                genericActionTitle="You have not yet scheduled an appointment with the client."
                                                                genericActionMessage={clientAppointmentNotSetMessage}
                                                                setGenericActionMessage={setClientAppointmentNotSetMessage}
                                                                genericActionButtonLabel="Appointment Not Yet Set With The Client"
                                                                genericActionFunction={finalizeClientAppointmentNotSet}
                                                                isPerformingAction={indicatingClientAppointmentNotSet}
                                                                genericActionMessagePlaceholder="Add a reason for not scheduling an appointment with the client ... "
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[10px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>
                                                        </div>

                                                        {/* Other Update Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Log a Call */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={showCallLogModal}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Log A Client Call
                                                                        </div>
                                                                    </button>

                                                                    {/* Return Referral */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={returnReferralAtClientContacted}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral w/Reasons
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledClientContactedReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledClientContactedReturnReferralModal}
                                                                        genericActionTitle="Returning This Referral"
                                                                        genericActionMessage={clientContactedReturnReferralMessage}
                                                                        setGenericActionMessage={setClientContactedReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferralAtClientContacted}
                                                                        isPerformingAction={indicatingClientContactedReturnReferral}
                                                                        genericActionMessagePlaceholder="Add other details for returning this referral here ... "
                                                                    >
                                                                        <div className="flex flex-row gap-[20px]">
                                                                            <div className="flex flex-col gap-[10px]">
                                                                                <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            </div>
                                                                        </div>                                                                

                                                                        <div className="flex flex-row gap-[20px]">
                                                                            <Select
                                                                                className='w-full'
                                                                                theme={globalReactSelectTheme}
                                                                                styles={{
                                                                                    control: (base) => ({
                                                                                        ...base,
                                                                                        height: 35,
                                                                                        minHeight: 35,
                                                                                    }),
                                                                                }}
                                                                                options={clientContactReturnReasonOptions}
                                                                                onChange={(selected: any) => setClientContactedReturnReason(selected.value)}
                                                                                placeholder={'Select Reason For Returning This Referral'}
                                                                            />
                                                                        </div>
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                {/* Referring Agent is now waiting for you to indicate that you are touring homes */}
                                                {clientAppointmentSet &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>You have scheduled an appointment with the client.</span>
                                                            <span><b>Appointment On</b>: {getLocaleDateWithTzStrShort(currReferralAction.actionDate as string)}</span>
                                                            <span className=""><b>Next:</b> Indicate when you sign the client representation agreement.</span>
                                                        </div>
                                                        <div className="flex flex-row gap-[16px] justify-start items-start">
                                                            <button
                                                                className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                onClick={indicateClientAgreementSigned}
                                                            >
                                                                <div className="uppercase w-[380px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                    Next: Representation Agreement Signed
                                                                </div>
                                                                {/* <img
                                                                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                    alt=""
                                                                    src="/img/mra-referral-timeline/iconthumbsupsolid.svg"
                                                                /> */}
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledClientAgreementSignedModal}
                                                                setEnabledGenericActionModal={setEnabledClientAgreementSignedModal}
                                                                genericActionTitle="Congratulations! You have signed an Client Representation Agreement."
                                                                genericActionMessage={clientAgreementSignedMessage}
                                                                setGenericActionMessage={setClientAgreementSignedMessage}
                                                                genericActionButtonLabel="Yes, I Have Signed The Client Representation Agreement"
                                                                genericActionFunction={finalizeClientAgreementSigned}
                                                                isPerformingAction={indicatingClientAgreementSigned}
                                                                styleOverride={callLogModalStyle}
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[30px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>

                                                            
                                                            {/* Still Deciding On An Agent */}                                                        
                                                            <button
                                                                className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                autoFocus={false}
                                                                onClick={indicateClientStillDeciding}
                                                            >
                                                                <div className="uppercase relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                    Client Still Deciding On An Agent
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledClientStillDecidingModal}
                                                                setEnabledGenericActionModal={setEnabledClientStillDecidingModal}
                                                                genericActionTitle="The client is still deciding on an agent"
                                                                genericActionMessage={clientStillDecidingMessage}
                                                                setGenericActionMessage={setClientStillDecidingMessage}
                                                                genericActionButtonLabel="Client Is Still Deciding An Agent"
                                                                genericActionFunction={finalizeClientStillDeciding}
                                                                isPerformingAction={indicatingClientStillDeciding}
                                                                styleOverride={callLogModalStyle}
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[30px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>
                                                        </div>

                                                        {/* Other Update Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Log a Call */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={showCallLogModal}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Log A Client Call
                                                                        </div>
                                                                    </button>


                                                                    {/* Return Referral */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={returnReferralAtAppointmentSet}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral w/Reasons
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledAppointmentSetReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledAppointmentSetReturnReferralModal}
                                                                        genericActionTitle="Returning This Referral"
                                                                        genericActionMessage={appointmentSetReturnReferralMessage}
                                                                        setGenericActionMessage={setAppointmentSetReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferralAtAppointmentSet}
                                                                        isPerformingAction={indicatingAppointmentSetReturnReferral}
                                                                        genericActionMessagePlaceholder="Add other details for returning this referral here ... "
                                                                    >
                                                                        <div className="flex flex-row gap-[20px]">
                                                                            <div className="flex flex-col gap-[10px]">
                                                                                <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            </div>
                                                                        </div>                                                                

                                                                        <Select
                                                                            className='w-full'
                                                                            theme={globalReactSelectTheme}
                                                                            styles={{
                                                                                control: (base) => ({
                                                                                    ...base,
                                                                                    height: 35,
                                                                                    minHeight: 35,
                                                                                }),
                                                                            }}
                                                                            options={appointmentSetReturnReasonOptions}
                                                                            onChange={(selected: any) => setAppointmentSetReturnReason(selected.value)}
                                                                            placeholder={'Select Reason For Returning This Referral'}
                                                                        />                                               
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                {/* Referring Agent is now waiting for you to indicate that you are touring properties */}
                                                {clientAgreementSigned &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">

                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>Congratulations! The client has signed an agreement with you!</span>
                                                            {referral?.referral.type === ReferralType.buy
                                                                ? <span className=""><b>Next:</b> Indicate when you are touring properties with the client.</span>
                                                                : <span className=""><b>Next:</b> Indicate when the client's property is on the market.</span>
                                                            }
                                                        </div>
                                                        <div>
                                                            {/*  Touring Properties Button */}
                                                            <button
                                                                className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                onClick={indicateShowingHomes}
                                                            >
                                                                {referral?.referral.type === ReferralType.buy
                                                                    ? <div className="uppercase w-[358px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">                                                            
                                                                        Next: Touring Properties
                                                                    </div>
                                                                    : <div className="uppercase w-[358px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">                                                            
                                                                        Next: Property Is On The Market
                                                                    </div>
                                                                }

                                                                {/* <img
                                                                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                    alt=""
                                                                    src="/img/mra-referral-timeline/iconthumbsupsolid.svg"
                                                                /> */}
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledShowingHomesModal}
                                                                setEnabledGenericActionModal={setEnabledShowingHomesModal}
                                                                genericActionTitle={`${referral?.referral.type === ReferralType.buy ? "Congratulations! You have begun touring properties with the client!" : "Congratulations! The property is on the market!"}`}
                                                                genericActionMessage={showingHomesMessage}
                                                                setGenericActionMessage={setShowingHomesMessage}
                                                                genericActionButtonLabel={`${referral?.referral.type === ReferralType.buy ? "Yes, I'm Touring Properties With The Client" : "Yes, The Property Is On The Market"}`}
                                                                genericActionFunction={finalizeShowingHomes}
                                                                isPerformingAction={indicatingShowingHomes}
                                                                styleOverride={callLogModalStyle}
                                                            >
                                                                <div className="flex flex-row gap-[20px]">
                                                                    <div className="flex flex-col gap-[30px]">
                                                                        <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>

                                                        </div>

                                                        {/* Other Update Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Log a Call */}                                                        
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={showCallLogModal}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Log A Client Call
                                                                        </div>
                                                                    </button> */}


                                                                    {/* Return Referral */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={returnReferralAtClientAgreement}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledClientAgreementReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledClientAgreementReturnReferralModal}
                                                                        genericActionTitle="Returning This Referral"
                                                                        genericActionMessage={clientAgreementReturnReferralMessage}
                                                                        setGenericActionMessage={setClientAgreementReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferralAtClientAgreement}
                                                                        isPerformingAction={indicatingClientAgreementReturnReferral}
                                                                        genericActionMessagePlaceholder="Add other details for returning this referral here ... "
                                                                    >
                                                                        <div className="flex flex-row gap-[20px]">
                                                                            <div className="flex flex-col gap-[10px]">
                                                                                <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            </div>
                                                                        </div>                                                                

                                                                        <Select
                                                                            className='w-full'
                                                                            theme={globalReactSelectTheme}
                                                                            styles={{
                                                                                control: (base) => ({
                                                                                    ...base,
                                                                                    height: 35,
                                                                                    minHeight: 35,
                                                                                }),
                                                                            }}
                                                                            options={clientAgreementReturnReasonOptions}
                                                                            onChange={(selected: any) => setClientAgreementReturnReason(selected.value)}
                                                                            placeholder={'Select Reason For Returning This Referral'}
                                                                        />                                               
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Referring Agent is now waiting for you to indicate that the property is under contract */}
                                                {showingHouses &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            {referral?.referral.type === ReferralType.buy
                                                                ? (
                                                                    <>
                                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                                        <span>You are touring properties with the client.</span>
                                                                    </>
                                                                )
                                                                : (
                                                                    <>
                                                                        <b className="capitalize">Open House</b>
                                                                        <span>You are showing the property to potential buyers.</span>
                                                                    </>
                                                                )
                                                            }
                                                            <span className=""><b>Next:</b> Indicate when the client goes under contract.</span>
                                                        </div>

                                                        <div className="flex flex-row items-top justify-start gap-[16px]">
                                                            <button
                                                                className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                onClick={indicateHomeUnderContract}
                                                            >
                                                                <div className="uppercase w-[358px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                    Next: Property Under Contract
                                                                </div>
                                                                {/* <img
                                                                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                    alt=""
                                                                    src="/img/mra-referral-timeline/iconthumbsupsolid.svg"
                                                                /> */}
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledHomeUnderContractModal}
                                                                setEnabledGenericActionModal={setEnabledHomeUnderContractModal}
                                                                genericActionTitle="Congratulations! Your client has the property under contract!"
                                                                genericActionMessage={homeUnderContractMessage}
                                                                setGenericActionMessage={setHomeUnderContractMessage}
                                                                genericActionButtonLabel="Yes, Property Is Under Contract"
                                                                genericActionFunction={finalizeHomeUnderContract}
                                                                isPerformingAction={indicatingHomeUnderContract}
                                                                styleOverride={contractClosureModalStyle}
                                                            >
                                                                <div className="flex flex-col gap-[20px] items-center justify-center">
                                                                    <div className="flex flex-row gap-[20px] items-center">
                                                                        <div className="flex flex-col w-fit h-fit gap-[10px]">
                                                                            <b className='text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Pick The Contract Closure Date <span className="text-red-700">*</span></b>
                                                                            <Calendar
                                                                                onChange={(date) => setContractClosureDate(date)}
                                                                                defaultActiveStartDate={new Date()}
                                                                                minDate={new Date(referral?.referral.actions.find((a: TReferralAction) => a.type === ReferralAction.referralCreated)?.date as string)}
                                                                                calendarType="US"
                                                                            />
                                                                        </div>
                                                                        <form ref={underContractModalFormRef} className="flex flex-col w-full h-full items-start justify-start gap-[12px]" autoComplete="off">
                                                                            
                                                                            <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            
                                                                            {/* price */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Price <span className="text-red-700">*</span></b>                                                                        
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <input
                                                                                            className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                            type='text'
                                                                                            maxLength={12}
                                                                                            onKeyUp={toCurrencyInline}
                                                                                            onBlur={formatAndUpdateContractClosurePrice}
                                                                                            autoComplete="off"
                                                                                            defaultValue={toCurrency(referral.referral.sellTargetPrice)}
                                                                                            required
                                                                                        />
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        maxLength={12}
                                                                                        onKeyUp={toCurrencyInline}
                                                                                        onBlur={formatAndUpdateContractClosurePrice}
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            
                                                                            {/* address 1 */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 1  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellAddress1}</span>
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosureAddress1(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>

                                                                            {/* address 2 */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 2</b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellAddress2}</span>
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosureAddress2(e.target.value)}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                }
                                                                            </div>

                                                                            {/* city */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>City  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellCity}</span>
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosureCity(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>

                                                                            {/* state */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>State  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{usStates?.find((s) => s.id === referral?.referral.sellStateId)?.name}</span>
                                                                                    : <Select
                                                                                        className='w-[200px]'
                                                                                        theme={globalReactSelectTheme}
                                                                                        styles={selectStateStyle}
                                                                                        options={stateOptions}
                                                                                        onChange={(select: any) => setContractClosureStateId(select.value)}
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>

                                                                            {/* zip */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Zip Code  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellZip}</span>
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosureZipcode(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>

                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>
                                                        </div>

                                                        {/* Other Update Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Log a Call */}                                                        
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={showCallLogModal}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Log A Client Call
                                                                        </div>
                                                                    </button> */}


                                                                    {/* Return Referral */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={returnReferralAtClientAgreement}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledClientAgreementReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledClientAgreementReturnReferralModal}
                                                                        genericActionTitle="Returning This Referral"
                                                                        genericActionMessage={clientAgreementReturnReferralMessage}
                                                                        setGenericActionMessage={setClientAgreementReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferralAtClientAgreement}
                                                                        isPerformingAction={indicatingClientAgreementReturnReferral}
                                                                        genericActionMessagePlaceholder="Add other details for returning this referral here ... "
                                                                    >
                                                                        <div className="flex flex-row gap-[20px]">
                                                                            <div className="flex flex-col gap-[10px]">
                                                                                <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            </div>
                                                                        </div>                                                                

                                                                        <Select
                                                                            className='w-full'
                                                                            theme={globalReactSelectTheme}
                                                                            styles={{
                                                                                control: (base) => ({
                                                                                    ...base,
                                                                                    height: 35,
                                                                                    minHeight: 35,
                                                                                }),
                                                                            }}
                                                                            options={clientAgreementReturnReasonOptions}
                                                                            onChange={(selected: any) => setClientAgreementReturnReason(selected.value)}
                                                                            placeholder={'Select Reason For Returning This Referral'}
                                                                        />                                               
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    }

                                    {/* UNDER CONTRACT */}
                                    {(homeUnderContract || referralCheckIssued) &&
                                        <div className="self-stretch flex flex-col items-start justify-start bg-whitesmoke">

                                            {/* UNDER CONTRACT HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Under Contract
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions.find((a: TReferralAction) => [ReferralAction.underContractClosingDate].includes(a.type))?.date as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* UNDER CONTRACT STEPS */}
                                            <div className="self-stretch flex flex-row gap-[16px] p-[20px]">
                                                
                                                {/* Referring Agent is now waiting for you to indicate that you have contacted the client */}
                                                {/* TODO: COMPLICATED WITH TERMS HAVE CHANGED */}
                                                {homeUnderContract &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>Congratulations! The client has selected a property and is Under Contract.</span>
                                                            <span>{referral?.referral.userFirstName}&apos;s broker&apos;s W9 is now available for download above.</span>
                                                            <span>
                                                                <b>Contract Closure:</b> {getLocaleDateWithTzStrFullDay(
                                                                                            referral?.referral.actions?.find((a: TReferralAction) => a.type === ReferralAction.underContractClosingDate)?.actionDate as string
                                                                                        ) ?? ""}
                                                            </span>                                                            
                                                            <span className=""><b>Next:</b> Indicate when {referral?.referral.userFirstName}&apos;s referral fee is included in the disbursement instructions.</span>
                                                        </div>
                                                        <div className="flex flex-row gap-[16px] justify-start items-start">
                                                            <button
                                                                className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                onClick={indicateReferralCheckIssued}
                                                            >
                                                                <div className="uppercase w-[520px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                    Next: Referral Fee in Disbursement instructions
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledReferralCheckIssuedModal}
                                                                setEnabledGenericActionModal={setEnabledReferralCheckIssuedModal}
                                                                genericActionTitle={`Awesome! ${referral?.referral.userFirstName}'s referral fee is included in the disbursement instructions`}
                                                                genericActionMessage={referralCheckIssuedMessage}
                                                                setGenericActionMessage={setReferralCheckIssuedMessage}
                                                                genericActionButtonLabel="Yes, Referral Fee Is Included In The Disbursement Instructions"
                                                                genericActionFunction={finalizeReferralCheckIssued}
                                                                isPerformingAction={indicatingReferralCheckIssued}
                                                                genericActionMessagePlaceholder="Add additional details here ..."
                                                                styleOverride={contractFellModalStyle}
                                                            />

                                                            {/* Contract Fell Through */}                                                        
                                                            {/* <button
                                                                className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                autoFocus={false}
                                                                onClick={indicateContractFell}
                                                            >
                                                                <div className="uppercase relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                    Contract Fell
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledContractFellModal}
                                                                setEnabledGenericActionModal={setEnabledContractFellModal}
                                                                genericActionTitle="Sorry, The Contract Fell Through!"
                                                                genericActionMessage={contractFellMessage}
                                                                setGenericActionMessage={setContractFellMessage}
                                                                genericActionButtonLabel="The Contract Fell Through"
                                                                genericActionFunction={finalizeContractFell}
                                                                isPerformingAction={indicatingContractFell}
                                                                genericActionMessagePlaceholder="Write any additional details here ..."
                                                                styleOverride={contractFellModalStyle}
                                                            /> */}
                                                        </div>

                                                        {/* Other Update Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">

                                                                    {/* Contract Terms Changed */}
                                                                    {/* TODO: Complicated!! Need to make sure the most recently updated terms are being updated to allow for sequential term updates. */}
                                                                    {false
                                                                        && <>
                                                                            <button
                                                                                className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                                autoFocus={false}
                                                                                onClick={indicateContractClosureChanged}
                                                                            >
                                                                                <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                                    Terms Have Changed
                                                                                </div>
                                                                                <ToDoIcon />
                                                                            </button>
                                                                            <GenericActionModal
                                                                                enabledGenericActionModal={enabledContractClosureChangedModal}
                                                                                setEnabledGenericActionModal={setEnabledContractClosureChangedModal}
                                                                                genericActionTitle="Contract Terms Have Changed!"
                                                                                genericActionMessage={contractClosureChangedMessage}
                                                                                setGenericActionMessage={setContractClosureChangedMessage}
                                                                                genericActionButtonLabel="Update The Contract Terms"
                                                                                genericActionFunction={finalizeContractClosureChanged}
                                                                                isPerformingAction={indicatingContractClosureChanged}
                                                                                genericActionMessagePlaceholder="Please specify which terms have changed here ...  "
                                                                                styleOverride={contractClosureModalStyle}
                                                                            >
                                                                                <div className="flex flex-col gap-[20px] items-center justify-center">
                                                                                    <div className="flex flex-row gap-[20px] items-center">
                                                                                        <div className="flex flex-col w-fit h-fit gap-[10px]">
                                                                                            <b className='text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Pick The Contract Closure Date <span className="text-red-700">*</span></b>
                                                                                            <Calendar
                                                                                                onChange={(date) => setContractClosureDateChanged(date as Value)}
                                                                                                defaultActiveStartDate={new Date()}
                                                                                                defaultValue={contractClosureObj ? new Date(contractClosureObj.contractClosureDate): new Date()}
                                                                                                minDate={new Date(referral?.referral.actions.find((a: TReferralAction) => a.type === ReferralAction.referralCreated)?.date as string)}
                                                                                                calendarType="US"
                                                                                            />
                                                                                        </div>
                                                                                        <form className="flex flex-col w-full h-full items-start justify-start gap-[10px]" autoComplete="off">
                                                                                            <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                                            {/* price */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Price <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    maxLength={12}
                                                                                                    onKeyUp={toCurrencyInline}
                                                                                                    onBlur={formatAndUpdateContractClosurePriceChanged}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? toCurrency(contractClosureObj.contractClosurePrice): ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* address 1 */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 1  <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureAddress1Changed(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureAddress1: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* address 2 */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 2</b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureAddress2Changed(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureAddress2: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* city */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>City  <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureCityChanged(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureCity: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* state */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>State  <span className="text-red-700">*</span></b>
                                                                                                <Select
                                                                                                    className='w-[200px]'
                                                                                                    theme={globalReactSelectTheme}
                                                                                                    styles={selectStateStyle}
                                                                                                    options={stateOptions}
                                                                                                    onChange={(select: any) => setContractClosureStateIdChanged(select.value)}
                                                                                                    defaultValue={contractClosureObj ? stateOptions.find((s) => s.value === contractClosureObj.contractClosureStateId) : {}}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* zip */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Zip Code  <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureZipcodeChanged(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureZipcode: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>                                       
                                                                            </GenericActionModal>
                                                                        </>
                                                                    }

                                                                    {/* Return Referral */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={returnReferralAtClientAgreement}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledClientAgreementReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledClientAgreementReturnReferralModal}
                                                                        genericActionTitle="Returning This Referral"
                                                                        genericActionMessage={clientAgreementReturnReferralMessage}
                                                                        setGenericActionMessage={setClientAgreementReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferralAtClientAgreement}
                                                                        isPerformingAction={indicatingClientAgreementReturnReferral}
                                                                        genericActionMessagePlaceholder="Add other details for returning this referral here ... "
                                                                    >
                                                                        <div className="flex flex-row gap-[20px]">
                                                                            <div className="flex flex-col gap-[10px]">
                                                                                <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            </div>
                                                                        </div>                                                                

                                                                        <Select
                                                                            className='w-full'
                                                                            theme={globalReactSelectTheme}
                                                                            styles={{
                                                                                control: (base) => ({
                                                                                    ...base,
                                                                                    height: 35,
                                                                                    minHeight: 35,
                                                                                }),
                                                                            }}
                                                                            options={clientAgreementReturnReasonOptions}
                                                                            onChange={(selected: any) => setClientAgreementReturnReason(selected.value)}
                                                                            placeholder={'Select Reason For Returning This Referral'}
                                                                        />                                               
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Disbursement Instructions includes Referral Fee */}
                                                {referralCheckIssued &&
                                                    <div className="self-stretch flex flex-1 flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>Congratulations! The client has selected a property and is Under Contract.</span>
                                                            <span>Also, you have confirmed that the disbursement instructions included {referral?.referral.userFirstName}&apos;s referral fee!</span>
                                                            <span>
                                                                <b>Contract Closure:</b> {getLocaleDateWithTzStrFullDay(
                                                                                            referral?.referral.actions?.find((a: TReferralAction) => a.type === ReferralAction.underContractClosingDate)?.actionDate as string
                                                                                        ) ?? ""}
                                                            </span>
                                                            <span className=""><b>Next:</b> Indicate when the contract is closed.</span>
                                                        </div>
                                                        <div className="self-stretch flex flex-1 flex-row gap-[16px] justify-start items-start">
                                                            <button
                                                                className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                onClick={indicateContractClosed}
                                                            >
                                                                <div className="uppercase w-[358px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                    Next: Contract Closed
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledContractClosedModal}
                                                                setEnabledGenericActionModal={setEnabledContractClosedModal}
                                                                genericActionTitle="Congratulations! The contract is closed!"
                                                                genericActionMessage={contractClosedMessage}
                                                                setGenericActionMessage={setContractClosedMessage}
                                                                genericActionButtonLabel="Yes, Contract Is Closed"
                                                                genericActionFunction={finalizeContractClosed}
                                                                isPerformingAction={indicatingClientContractClosed}
                                                                styleOverride={contractClosureModalStyle}
                                                            >
                                                                <div className="flex flex-col gap-[20px] items-center justify-center">
                                                                    <div className="flex flex-row gap-[20px] items-center">
                                                                        <div className="flex flex-col w-fit h-fit gap-[10px]">
                                                                            <b className='text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Pick The Contract Closure Date <span className="text-red-700">*</span></b>
                                                                            <Calendar
                                                                                onChange={(date) => setContractClosedDate(date)}
                                                                                defaultActiveStartDate={new Date()}
                                                                                defaultValue={contractClosedDate ?? new Date()}
                                                                                minDate={new Date(referral?.referral.actions.find((a: TReferralAction) => a.type === ReferralAction.referralCreated)?.date as string)}
                                                                                calendarType="US"
                                                                            />
                                                                        </div>
                                                                        <form ref={contractClosedModalFormRef} className="flex flex-col w-full h-full items-start justify-start gap-[10px]" autoComplete="off">
                                                                            <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            
                                                                            {/* price */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Price <span className="text-red-700">*</span></b>                                                                        
                                                                                <input
                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                    type='text'
                                                                                    maxLength={12}
                                                                                    onKeyUp={toCurrencyInline}
                                                                                    onBlur={formatAndUpdateContractClosedPrice}
                                                                                    autoComplete="off"
                                                                                    defaultValue={contractClosureObj ? toCurrency(contractClosureObj.price): ''}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                            
                                                                            {/* address 1 */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 1  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellAddress1}</span>
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosedAddress1(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        defaultValue={contractClosureObj ? contractClosureObj.address1: ''}
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            {/* address 2 */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 2</b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellAddress2}</span>
                                                                                        : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosedAddress2(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        defaultValue={contractClosureObj ? contractClosureObj.address2: ''}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            {/* city */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>City  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellCity}</span>
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosedCity(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        defaultValue={contractClosureObj ? contractClosureObj.city: ''}
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            {/* state */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>State  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{usStates?.find((s) => s.id === referral?.referral.sellStateId)?.name}</span>
                                                                                    : <Select
                                                                                        className='w-[200px]'
                                                                                        theme={globalReactSelectTheme}
                                                                                        styles={selectStateStyle}
                                                                                        options={stateOptions}
                                                                                        onChange={(select: any) => setContractClosedStateId(select.value)}
                                                                                        defaultValue={contractClosureObj ? stateOptions.find((s) => s.value === contractClosureObj.stateId) : {}}
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            {/* zip */}
                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Zip Code  <span className="text-red-700">*</span></b>
                                                                                {referral?.referral.type === ReferralType.sell
                                                                                    ? <span className="text-secondary pl-[15px]">{referral?.referral.sellZip}</span>
                                                                                    : <input
                                                                                        className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                        type='text'
                                                                                        onBlur={(e) => setContractClosedZipcode(e.target.value)}
                                                                                        autoComplete="off"
                                                                                        defaultValue={contractClosureObj ? contractClosureObj.zip : ''}
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </GenericActionModal>
                                                        

                                                            {/* Contract Fell Through */}                                                        
                                                            <button
                                                                className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                autoFocus={false}
                                                                onClick={indicateContractFell}
                                                            >
                                                                <div className="uppercase relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                    Contract Fell
                                                                </div>
                                                            </button>
                                                            <GenericActionModal
                                                                enabledGenericActionModal={enabledContractFellModal}
                                                                setEnabledGenericActionModal={setEnabledContractFellModal}
                                                                genericActionTitle="Sorry, The Contract Fell Through!"
                                                                genericActionMessage={contractFellMessage}
                                                                setGenericActionMessage={setContractFellMessage}
                                                                genericActionButtonLabel="The Contract Fell Through"
                                                                genericActionFunction={finalizeContractFell}
                                                                isPerformingAction={indicatingContractFell}
                                                                genericActionMessagePlaceholder="Write any additional details here ..."
                                                                styleOverride={contractFellModalStyle}
                                                            />
                                                        </div>

                                                        {/* Other Update Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Other Update Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">

                                                                    {/* Contract Terms Changed */}
                                                                    {/* TODO: Complicated!! Need to make sure the most recently updated terms are being updated to allow for sequential term updates. */}
                                                                    {false
                                                                        && <>
                                                                            <button
                                                                                className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                                autoFocus={false}
                                                                                onClick={indicateContractClosureChanged}
                                                                            >
                                                                                <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                                    Terms Have Changed
                                                                                </div>
                                                                                <ToDoIcon />
                                                                            </button>
                                                                            <GenericActionModal
                                                                                enabledGenericActionModal={enabledContractClosureChangedModal}
                                                                                setEnabledGenericActionModal={setEnabledContractClosureChangedModal}
                                                                                genericActionTitle="Contract Terms Have Changed!"
                                                                                genericActionMessage={contractClosureChangedMessage}
                                                                                setGenericActionMessage={setContractClosureChangedMessage}
                                                                                genericActionButtonLabel="Update The Contract Terms"
                                                                                genericActionFunction={finalizeContractClosureChanged}
                                                                                isPerformingAction={indicatingContractClosureChanged}
                                                                                genericActionMessagePlaceholder="Please specify which terms have changed here ...  "
                                                                                styleOverride={contractClosureModalStyle}
                                                                            >
                                                                                <div className="flex flex-col gap-[20px] items-center justify-center">
                                                                                    <div className="flex flex-row gap-[20px] items-center">
                                                                                        <div className="flex flex-col w-fit h-fit gap-[10px]">
                                                                                            <b className='text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Pick The Contract Closure Date <span className="text-red-700">*</span></b>
                                                                                            <Calendar
                                                                                                onChange={(date) => setContractClosureDateChanged(date as Value)}
                                                                                                defaultActiveStartDate={new Date()}
                                                                                                defaultValue={contractClosureObj ? new Date(contractClosureObj.contractClosureDate): new Date()}
                                                                                                minDate={new Date(referral?.referral.actions.find((a: TReferralAction) => a.type === ReferralAction.referralCreated)?.date as string)}
                                                                                                calendarType="US"
                                                                                            />
                                                                                        </div>
                                                                                        <form className="flex flex-col w-full h-full items-start justify-start gap-[10px]" autoComplete="off">
                                                                                            <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                                            {/* price */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Price <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    maxLength={12}
                                                                                                    onKeyUp={toCurrencyInline}
                                                                                                    onBlur={formatAndUpdateContractClosurePriceChanged}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? toCurrency(contractClosureObj.contractClosurePrice): ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* address 1 */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 1  <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureAddress1Changed(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureAddress1: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* address 2 */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Address Line 2</b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureAddress2Changed(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureAddress2: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* city */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>City  <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureCityChanged(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureCity: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* state */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>State  <span className="text-red-700">*</span></b>
                                                                                                <Select
                                                                                                    className='w-[200px]'
                                                                                                    theme={globalReactSelectTheme}
                                                                                                    styles={selectStateStyle}
                                                                                                    options={stateOptions}
                                                                                                    onChange={(select: any) => setContractClosureStateIdChanged(select.value)}
                                                                                                    defaultValue={contractClosureObj ? stateOptions.find((s) => s.value === contractClosureObj.contractClosureStateId) : {}}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            {/* zip */}
                                                                                            <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                                                                <b className='w-[130px] text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Zip Code  <span className="text-red-700">*</span></b>
                                                                                                <input
                                                                                                    className='w-[400px] font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                                                                    type='text'
                                                                                                    onBlur={(e) => setContractClosureZipcodeChanged(e.target.value)}
                                                                                                    autoComplete="off"
                                                                                                    defaultValue={contractClosureObj ? contractClosureObj.contractClosureZipcode: ''}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>                                       
                                                                            </GenericActionModal>
                                                                        </>
                                                                    }

                                                                    {/* Return Referral */}                                                        
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={returnReferralAtClientAgreement}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Return Referral
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledClientAgreementReturnReferralModal}
                                                                        setEnabledGenericActionModal={setEnabledClientAgreementReturnReferralModal}
                                                                        genericActionTitle="Returning This Referral"
                                                                        genericActionMessage={clientAgreementReturnReferralMessage}
                                                                        setGenericActionMessage={setClientAgreementReturnReferralMessage}
                                                                        genericActionButtonLabel="Return This Referral"
                                                                        genericActionFunction={finalizeReturnReferralAtClientAgreement}
                                                                        isPerformingAction={indicatingClientAgreementReturnReferral}
                                                                        genericActionMessagePlaceholder="Add other details for returning this referral here ... "
                                                                    >
                                                                        <div className="flex flex-row gap-[20px]">
                                                                            <div className="flex flex-col gap-[10px]">
                                                                                <div className="text-lg font-bold">Client: {referral?.referral.clientFirstName} {referral?.referral.clientLastName}</div>
                                                                            </div>
                                                                        </div>                                                                

                                                                        <Select
                                                                            className='w-full'
                                                                            theme={globalReactSelectTheme}
                                                                            styles={{
                                                                                control: (base) => ({
                                                                                    ...base,
                                                                                    height: 35,
                                                                                    minHeight: 35,
                                                                                }),
                                                                            }}
                                                                            options={clientAgreementReturnReasonOptions}
                                                                            onChange={(selected: any) => setClientAgreementReturnReason(selected.value)}
                                                                            placeholder={'Select Reason For Returning This Referral'}
                                                                        />                                               
                                                                    </GenericActionModal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                     {/* CONTRACT CLOSED */}
                                     {(contractClosed) &&
                                        <div className="self-stretch flex flex-col items-start justify-start bg-whitesmoke">

                                            {/* CONTRACT CLOSED HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Contract Closed
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions.find((a: TReferralAction) => [ReferralAction.underContractClosingDate].includes(a.type))?.date as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* CONTRACT CLOSED STEPS */}
                                            <div className="self-stretch flex flex-row gap-[16px] p-[20px]">

                                                {/* Referring Agent is now waiting for you to send the check */}
                                                {contractClosed &&
                                                    <div className="self-stretch flex flex-1 flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>Congratulations! The client contract is closed!</span>
                                                            <span>
                                                                <b>Contract Closed On:</b> {getLocaleDateWithTzStrFullDay(
                                                                                            referral?.referral.actions?.find((a: TReferralAction) => a.type === ReferralAction.contractClosed)?.actionDate as string
                                                                                        ) ?? ""}
                                                            </span>
                                                            <span className=""><b>Next:</b> Waiting for {referral?.referral.userFirstName} to indicate that the referral fee was received!</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/* CLOSED */}
                                    {referralCheckReceived &&
                                        <div className="self-stretch flex flex-col items-start justify-start bg-whitesmoke">

                                            {/* CLOSED HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start gap-[10px]">
                                                    <img
                                                        className=""
                                                        src="/img/mra-referral-timeline/iconstars.svg"
                                                    />
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px] text-lg">
                                                        Referral Complete!
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions.find((a: TReferralAction) => a.type === ReferralAction.referralCheckReceived)?.date as string)}
                                                    </b>
                                                </div>
                                            </div>


                                            <div className="flex flex-row gap-[16px] p-[20px] justify-center">
                                                <img
                                                    className="relative w-[105px] h-[105px] overflow-hidden shrink-0 rounded-xl"
                                                    alt=""
                                                    src={referral?.referral.userPrimaryImage ? getAvatar(referral?.referral.userPrimaryImage, 200) : defaultAvatar}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = defaultAvatar;
                                                    }}
                                                />

                                                {/* Check is received. FINISHED! */}
                                                {referralCheckReceived &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize text-lg">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span className="text-lg">Congratulations!! {referral?.referral.userFirstName} has received your check!</span>
                                                        <span className="text-lg">This Referral is now Complete!</span>
                                                        {/* {!reviewISent
                                                            && <div><b>Next:</b> <a className="text-link-blue underline" href="#write-review">Write a review</a> for {referral?.referral.agentFirstName}!</div>
                                                        } */}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                
                                {/* Review Section */}
                                {referralCheckReceived
                                    && <div className="self-stretch flex flex-col items-start justify-center rounded-lg text-lg gap-[20px]">
                                            {reviewReceived
                                                && <div className="self-stretch flex flex-col gap-[5px] p-5 border border-solid border-gray-300 rounded-lg bg-slate-100">
                                                    <div className="text-3xl-1">Review By {referral?.referral.userFirstName}</div>
                                                    <div className="flex flex-row gap-[10px]">
                                                        <b>Rating: </b>
                                                        <div className="flex flex-row gap-0 items-center">
                                                            {[...Array(Math.trunc(reviewReceived.rating as number) + 1).keys()].slice(1).map((i) =>
                                                                <img key={`overall-rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                            )}
                                                        </div>
                                                        {reviewReceived.rating as number}
                                                    </div>
                                                    <div className="flex flex-row gap-[10px]"><b>Feedback: </b> {reviewReceived.feedback}</div>
                                                </div>
                                            }
                                            {/* {reviewISent
                                                ? <div className="self-stretch flex flex-col gap-[5px] p-5 border border-solid border-gray-300 rounded-lg bg-slate-100">
                                                    <div className="text-3xl-1">Review You Posted for {referral?.referral.agentFirstName} {reviewISent.status !== 1 && <i>(Pending Approval)</i>}</div>
                                                    <div className="flex flex-row gap-[10px]">
                                                        <b>Rating: </b>
                                                        <div className="flex flex-row gap-0 items-center">
                                                            {[...Array(Math.trunc(reviewISent.rating as number) + 1).keys()].slice(1).map((i) =>
                                                                <img key={`overall-rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                            )}
                                                        </div>
                                                        {reviewISent.rating as number}
                                                    </div>
                                                    <div className="flex flex-row gap-[10px]"><b>Feedback: </b> {reviewISent.feedback}</div>
                                                </div>
                                                : <>
                                                    <div className="text-5xl">
                                                        Write a Review for {referral?.referral.userFirstName}
                                                    </div>
                                                    <WaitContainer shouldWaitIf={isSendingReview}>
                                                        <div className="flex flex-row gap-[10px]">
                                                            <span>Select A Rating:</span>
                                                            {Array.from({ length: 5 }, (_, i) => i + 1).map((i) =>
                                                                <div key={i} className="cursor-pointer" onClick={() => setReviewRating(i)}>
                                                                    <label className='cursor-pointer' htmlFor={`rating-${i}`}>
                                                                        {i}
                                                                        <input className='cursor-pointer' type="radio" id={`rating-${i}`} name="review-rating" value={i} />
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="self-stretch flex flex-col items-start justify-center gap-[16px] rounded-md bg-light-background-background-1 p-4 text-tertiary border-[1px] border-solid border-gray-400">
                                                            <textarea
                                                                className="flex resize-none overflow-y-auto text-lg font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border-[none] outline-0"
                                                                rows={5}
                                                                cols={77}
                                                                maxLength={MAX_REVIEW_CHARS}
                                                                onChange={reviewLengthCheck}
                                                                placeholder="Type your review here ... "
                                                                value={reviewContent}
                                                            />
                                                            <div className="w-full flex flex-row">
                                                                <div className="flex flex-1 items-center justify-start">
                                                                    <div className="flex flex-1 items-start justify-start">
                                                                        {reviewCharsLeft} characters left
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="cursor-pointer flex items-center justify-end px-5 py-2 rounded-full border border-solid border-gray-300 text-md hover:bg-gray-50"
                                                                    onClick={postReview}
                                                                >
                                                                    Post Review
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </WaitContainer>
                                                </>
                                            } */}
                                        </div>
                                }

                                {/* MESSAGES */}
                                <div className="self-stretch flex flex-col items-start justify-center rounded-lg">
                                    <WaitContainer shouldWaitIf={isSendingMsg}>
                                        <div className="self-stretch flex flex-col items-start justify-center gap-[16px] rounded-md bg-light-background-background-1 p-4 text-tertiary border-[1px] border-solid border-gray-400">
                                            <textarea
                                                className="w-[770px] flex flex-1 resize-none overflow-y-auto text-lg font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left  border-[none] outline-0"
                                                rows={5}
                                                // cols={77}
                                                maxLength={MAX_MSG_CHARS}
                                                onChange={msgLengthCheck}
                                                placeholder="Type a message here ..."
                                                value={msgContent}
                                            />
                                            <div className="w-full flex flex-row">
                                                <div className="flex flex-1 items-center justify-start">
                                                    <div className="flex flex-1 items-start justify-start">
                                                        {msgCharsLeft} characters left
                                                    </div>
                                                </div>
                                                {/* <div className="flex flex-1 items-center justify-end">
                                                    <img
                                                        className="cursor-pointer"
                                                        alt="Send Message"
                                                        src="/img/mra-referral-timeline/SendMessageButton.svg"
                                                        onClick={sendMessage}
                                                    />
                                                </div> */}

                                                <div
                                                    className="cursor-pointer flex items-center justify-end px-5 py-2 rounded-full border border-solid border-gray-300 text-md text-white bg-secondary"
                                                    onClick={sendMessage}
                                                >
                                                    Send Message
                                                </div>
                                            </div>
                                        </div>
                                    </WaitContainer>
                                </div>


                                {/* THE ACTIVITY TIMELINE */}
                                <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg">

                                    {/* Activity Timeline Header */}
                                    <div className="self-stretch flex flex-row items-start justify-start">
                                        <div className="flex-1 flex flex-row py-4 px-[13px] items-center justify-start rounded-lg">
                                            <b className="flex-1 relative tracking-[0.1px] leading-[24px] text-lg">
                                                Activity Timeline
                                            </b>
                                        </div>
                                    </div>

                                    {/* The Timeline */}
                                    <div className="self-stretch flex flex-col items-start justify-start pb-[16px] px-[16px]">

                                        <div className="w-full grid grid-cols-10 striped gap-[24px]">
                                            {referral?.referral.actions?.map((a) => a)
                                                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                                                .map((a) => (
                                                    <Fragment key={a.date}>

                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.referralCheckReceived &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2 border-l border-solid border-black" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-award.svg"
                                                                    />
                                                                    Completed
                                                                </div>
                                                            </div>
                                                        }



                                                        {/* Timestamp */}
                                                        <div className="flex flex-col col-span-2 justify-start pt-[5px]">
                                                            {getLocaleDateTimeWithTzStrShortForTimeline(a.date).split("\n").map((line, i) => <div key={i}>{line}</div>)}
                                                        </div>

                                                        {/* The Stage */}
                                                        <div className="col-span-8 items-top justify-start flex flex-col gap-0 pb-[10px]">
                                                            <div className="flex flex-row items-center justify-start font-bold gap-[20px]">
                                                                {a.changedByFirstName === referral.referral.agentFirstName
                                                                    ? <img
                                                                        className="relative w-[50px] h-[50px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src={referral?.referral.agentPrimaryImage ? getAvatar(referral?.referral.agentPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = DEFAULT_AVATAR_PATH;
                                                                        }}
                                                                    />
                                                                    : <img
                                                                        className="relative w-[50px] h-[50px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src={referral?.referral.userPrimaryImage ? getAvatar(referral?.referral.userPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = DEFAULT_AVATAR_PATH;
                                                                        }}
                                                                    />
                                                                }

                                                                <div>
                                                                    {referral.referral.type === ReferralType.buy
                                                                        ? <>
                                                                            {(referral.referral.userFirstName === a.changedByFirstName)
                                                                                ? <div>{ReferralActionStr[ReferralAction[a.type]]} by {a.changedByFirstName}</div>
                                                                                : <div>{ReferralActionStr[ReferralAction[a.type]]} by you</div>
                                                                            }
                                                                        </>
                                                                        : <div>
                                                                            {a.type === ReferralAction.showingHouses
                                                                                ? "Property is on the market"
                                                                                : <>
                                                                                    {(referral.referral.userFirstName === a.changedByFirstName)
                                                                                        ? <div>{ReferralActionStr[ReferralAction[a.type]]} by {a.changedByFirstName}</div>
                                                                                        : <div>{ReferralActionStr[ReferralAction[a.type]]} by you</div>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                            {/* Stage Note/Message/Details */}
                                                            {(!!a.message || !!a.actionDate)
                                                                && <div className={`flex flex-col w-full pl-[70px] rounded-md leading-snug pb-2 pt-0 `}>
                                                                    
                                                                    {/* <div className={`flex flex-col w-[82.5%] p-[16px] gap-[10px] ${(referral.referral.agentFirstName !== a.changedByFirstName) ? 'bg-honeydew':'bg-white'} border border-solid border-gray-300 rounded-lg`}> */}
                                                                    <div className={`flex flex-col w-[82.5%] p-[16px] gap-[10px] bg-white border border-solid border-gray-300 rounded-lg`}>
                                                                        {a.message &&
                                                                            <span>{a.message.replace(/\\n/g, '\n').split('\n').map((line, idx) => <span key={idx}>{line}<br /></span>)}</span>
                                                                        }

                                                                        {/* Include Terms for Under Contract and Contract Closed */}
                                                                        {[
                                                                            ReferralAction.underContractClosingDate,
                                                                            ReferralAction.termsChanged,
                                                                            ReferralAction.contractClosed,
                                                                        ].includes(a.type)
                                                                            && <div className="flex flex-col p-5 text-md border border-dashed border-gray-200 bg-gray-50 rounded-md gap-[6px]">
                                                                                <div><b>Closing Date: </b> {getLocaleDateWithTzStrFullDay(a.actionDate as string)}</div>
                                                                                <div className="flex flex-row gap-[5px]">
                                                                                    <b>{a.type === ReferralAction.contractClosed ? 'Closing' : 'Under Contract'} Price: </b>
                                                                                    {toCurrency(a.price)}
                                                                                    {(referral?.referral.type === ReferralType.sell)
                                                                                        && <>
                                                                                            {a.price !== referral.referral.sellTargetPrice
                                                                                                && <span>(Target Price was {toCurrency(referral.referral.sellTargetPrice)})</span>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                                <div className="flex flex-row">
                                                                                    <b>Address: </b>
                                                                                    <div className="flex flex-row pl-2">
                                                                                        {a.address}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {[ReferralAction.appointmentScheduled].includes(a.type)
                                                                            && <div className="flex flex-col p-5 text-md border border-dashed border-gray-200 bg-gray-50 rounded-md gap-[6px]">
                                                                                <div><b>Appointment On: </b> {getLocaleDateWithTzStrFullDay(a.actionDate as string)}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>



                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.underContractClosingDate &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2 border-l border-solid border-black" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-chevron-dbl-up.svg"
                                                                    />
                                                                    Under Contract
                                                                </div>
                                                            </div>
                                                        }

                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.clientContacted &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2 border-l border-solid border-black" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-chevron-dbl-up.svg"
                                                                    />
                                                                    Referral Active
                                                                </div>
                                                            </div>
                                                        }

                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.referralCreated &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-chevron-dbl-up.svg"
                                                                    />
                                                                    New Opportunity
                                                                </div>
                                                            </div>
                                                        }

                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* CLIENT DETAILS */}
                            <div className="relative">
                                <div className="rounded-lg p-[16px] bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] w-[420px] flex flex-col box-border items-start justify-start gap-[16px]">
                                
                                    <div className="self-stretch flex flex-row items-start justify-start text-lg">
                                        <b className="relative leading-[25px]">Client Details</b>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Client
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {hiddenClientInfo
                                                ? <i>HIDDEN</i>
                                                : `${referral?.referral.clientFirstName} ${referral?.referral.clientLastName}`
                                            }
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Email
                                        </div>
                                        <div className="flex w-[320px] break-all relative leading-[21px] text-tertiary">
                                            {hiddenClientInfo
                                                ? <i>HIDDEN</i>
                                                : `${referral?.referral.clientEmail}`
                                            }
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Phone
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {hiddenClientInfo
                                                ? <i>HIDDEN</i>
                                                : `${referral?.referral.clientPhone}`
                                            }
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Source
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {ReferralSourceStr[ReferralSource[referral?.referral.source as ReferralSource]]}
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Type
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {ReferralTypeStr[ReferralType[referral?.referral.type as ReferralType]]}
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Fee
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {referral?.referral.fee}%
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            {referral?.referral.type === ReferralType.buy && `Location(s)`}
                                            {referral?.referral.type === ReferralType.sell && `Property Address`}
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {referral?.referral.type === ReferralType.buy && referral?.referral.buyLocations?.join(', ')}
                                            {(referral?.referral.type === ReferralType.sell)
                                                && <>
                                                    {(referral.referral.actions.filter((a) => a.type === ReferralAction.signedAgreementConfirmed).length >= 1)
                                                        ? <div className="flex flex-col">
                                                            <span>
                                                                {referral?.referral.sellAddress1},

                                                                {referral?.referral.sellAddress2
                                                                    && <>
                                                                        <br />
                                                                        {referral?.referral.sellAddress2},
                                                                    </>
                                                                }

                                                                <br />
                                                                {referral?.referral.sellCity}, {usStates?.find((s) => s.id === referral?.referral.sellStateId)?.abbreviation}, {referral?.referral.sellZip}
                                                            </span>
                                                        </div>
                                                        : <div className="flex flex-col">
                                                            <span>{referral?.referral.sellCity}, {usStates?.find((s) => s.id === referral?.referral.sellStateId)?.abbreviation}</span>
                                                            <i>FULL ADDRESS HIDDEN</i>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Property Type
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {referral?.referral.type === ReferralType.buy && referral?.referral.buyPropertyType}
                                            {referral?.referral.type === ReferralType.sell && referral?.referral.sellPropertyType}
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            {referral?.referral.type === ReferralType.buy && `Price Range`}
                                            {referral?.referral.type === ReferralType.sell && `Target Price`}
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {referral?.referral.type === ReferralType.buy && referral.referral.buyRangeMin && `${toCurrency(referral.referral.buyRangeMin)} - ${toCurrency(referral.referral.buyRangeMax)}`}
                                            {referral?.referral.type === ReferralType.sell && `${toCurrency(referral.referral.sellTargetPrice)}`}
                                        </div>
                                    </div>

                                    {referral?.referral.type === ReferralType.buy &&
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                            <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                                Timeframe
                                            </div>
                                            <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                                {referral?.referral.type === ReferralType.buy && ReferralTimeFrameStr[ReferralTimeFrame[referral.referral.buyTimeFrame as ReferralTimeFrame]]}
                                                {/* {referral?.referral.type === ReferralType.sell && ReferralTimeFrameStr[ReferralTimeFrame[referral.referral.sellTimeFrame as ReferralTimeFrame]]} */}
                                            </div>
                                        </div>
                                    }

                                    {referral?.referral.type === ReferralType.buy && referral?.referral.buyNote &&
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                            <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                                Comments
                                            </div>
                                            <div className="flex w-[320px] relative leading-[21px] text-tertiary whitespace-pre-wrap">
                                                {referral?.referral.buyNote}
                                            </div>
                                        </div>
                                    }
                                    {referral?.referral.type === ReferralType.sell && referral?.referral.sellNote &&
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                            <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                                Comments
                                            </div>
                                            <div className="flex w-[320px] relative leading-[21px] text-tertiary whitespace-pre-wrap">
                                                {referral?.referral.sellNote}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </WaitContainer>
            }
        </>
    )
}

export { InboundReferralTimeline };
