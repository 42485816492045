import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import {
    LicenseStatus,
    TManageDesignationList,
    TManageSpecialtyList,
    TPrivateProfileView,
    TProfileUserData,
    TUsStateList,
} from "./types";
import { WaitSpinner } from "./components/WaitSpinner";
import {
    getCurrentSubscriptionByStripeId,
    getDesignationsTypes,
    getPrivateProfileById,
    getProfileUserData,
    getSpecialtiesTypes,
    getUsStates,
} from "./utils/api";
import useZustandAuthStore from "./_Zustand/auth-store";
import { Registration } from "./_Anonymous/Registration";
import { RegistrationOptions } from "./_Anonymous/Registration/RegistrationOptions";
import RegistrationNavBar from "./_Anonymous/Registration/RegistrationNavBar";
import { Dashboard } from "./_Authenticated/Dashboard";
import { Authenticated } from "./_Authenticated/Authenticated";
import { getIsProduction } from "./utils/get-is-production";
import { RegistrationEmailVerify } from "./_Anonymous/Registration/RegistrationEmailVerify";
import { ReactSession } from 'react-client-session';
import { LicenseVerificationPending } from "./_Anonymous/LicenseVerificationPending";
import { Anonymous } from "./_Anonymous/Anonymous";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { followDeepLinkRedirect, getDeepLink, resetDeepLink, writeDeepLink } from "./utils/deep-link";

ReactSession.setStoreType("localStorage");

const Auth: React.FunctionComponent = () => {
    const { authState, setAuthState, setUsStateList, setDesignationList, setSpecialtiesList, setPrivateProfileData, setReferralInvitation, resetZustandState, privateProfileData } = useZustandAuthStore();

    const { isAuthenticated, isLoading, user, getIdTokenClaims, getAccessTokenSilently, logout } = useAuth0();
    
    const [isAuthenticatedButUserNotExists, setIsAuthenticatedButUserNotExists] = useState<boolean>(false);        
    const [isFinishedDeterminingSession, setIsFinishedDeterminingSession] = useState<boolean>(false);    
    const [isUserDataFullyLoaded, setIsUserDataFullyLoaded] = useState<boolean>(false);
    const [couldNotAuthenticate, setCouldNotAuthenticate] = useState<boolean>(false);
    const [emailNotVerified, setEmailNotVerified] = useState<boolean>(false);
    const [licenseVerified, setLicenseVerified] = useState<boolean>(false);
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

    const mainLogout = (): void => {
        setIsLoggingOut(true);
        toast.dismiss(); // dismiss all toasts
        
        logout({ logoutParams: { returnTo: window.location.origin } })
            .then((args) => {
                resetZustandState();
                window.localStorage.clear();                
          
                console.log("post logout.");
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onApiLogout = (): void => {
        console.log("Is Authenticated: ", isAuthenticated);
        getIdTokenClaims()
            .then(async (idTokenClaims) => {
                console.log("ID Token Claims: ", idTokenClaims);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // setApiLogout(onApiLogout);

    const navigate = useNavigate();
    const location = useLocation();

    console.log("isAuthenticated: ", isAuthenticated);
    console.log("isLoading: ", isLoading);
    console.log("user: ", user);
    console.log("Production? ", getIsProduction());

    const getAllSupportedData = async (): Promise<void> => {
        // Getting this nevertheless: needed for registration form for license verification
        getUsStates()
            .then((usStates: unknown): void => {
                console.log("usStates: ", usStates);
                setUsStateList(usStates as TUsStateList); // for zustand state mgmt
            })
            .catch((error) => console.error(error));

        getDesignationsTypes()
            .then((designations: TManageDesignationList): void => {
                console.log("designations", designations);
                setDesignationList(designations as TManageDesignationList); // for zustand state mgmt
            })
            .catch((error) => console.error(error));

        // get specialties
        getSpecialtiesTypes()
            .then((specialties: TManageSpecialtyList): void => {
                console.log("specialties", specialties);
                setSpecialtiesList(specialties as TManageSpecialtyList); // for zustand state mgmt
            })
            .catch((error) => console.error(error));
    };
    
    const decodeJwt = (jwt): any => {
        var base64Url = jwt.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };
    
    const loadUserData = (): void => {
        console.log(isAuthenticated);

        if (isAuthenticated && user?.email) {
            Sentry.setUser({ email: user?.email });
            if (window["hj"]) {
                window["hj"]('identify', user?.email, {});
            }
        }

        getAllSupportedData();

        setIsUserDataFullyLoaded(false);

        getAccessTokenSilently({cacheMode: "off"})
            .then((accessToken) => {

                const apiToken = accessToken;
                const tokenPayload = decodeJwt(apiToken);

                // const apiTokenExpiresInSec = accessTokenObj.expires_in;
                const apiTokenExpiresAtEpochMs = tokenPayload.exp * 1000; //Date.now() + (apiTokenExpiresInSec * 1000) - 10000;

                ReactSession.set("jwt", apiToken);                
                ReactSession.set("jwtExpiresAt", apiTokenExpiresAtEpochMs);

                const deepLink = getDeepLink();
                if (deepLink) {
                    followDeepLinkRedirect(deepLink);
                }

                getIdTokenClaims()
                    .then(async (idTokenClaims) => {

                        const auth = {
                            id: -1,
                            user,
                            apiToken,
                            idTokenClaims,
                            profileUserData: undefined,
                        };

                        setAuthState({ ...auth }); // for zustand state mgmt

                        getProfileUserData(false)
                            .then(async (profileUserData: TProfileUserData) => {

                                console.log(profileUserData);
                                const { id } = profileUserData;

                                const auth = {
                                    id,
                                    user,
                                    apiToken,
                                    idTokenClaims,
                                };

                                setAuthState({ ...auth, profileUserData }); // for zustand state mgmt
                                
                                getPrivateProfileById()
                                    .then(
                                        (
                                            privateProfileData: TPrivateProfileView,
                                        ) => {
                                            const privProfileDataCopy = {...privateProfileData};

                                            if (!privProfileDataCopy.stripeId) {
                                                privProfileDataCopy["product"] = "FREE";

                                                setPrivateProfileData(privProfileDataCopy);
                                                console.log("Private Profile: ", privProfileDataCopy);

                                                continueToValidUser(privProfileDataCopy);
                                            }
                                            else {
                                                getCurrentSubscriptionByStripeId(privProfileDataCopy.stripeId)
                                                    .then((subscription) => {
                                                        if(subscription && subscription.product) {
                                                            const product = subscription.product;
                                                            privProfileDataCopy["product"] = product;
                                                        }
                                                        else {
                                                            privProfileDataCopy["product"] = "FREE";
                                                        }

                                                        setPrivateProfileData(privProfileDataCopy);
                                                        console.log("Private Profile: ", privProfileDataCopy);

                                                        continueToValidUser(privProfileDataCopy);
                                                    })
                                                    .catch((error) => {
                                                        console.error(error);
                                                        setPrivateProfileData(privProfileDataCopy);

                                                        continueToValidUser(privProfileDataCopy);
                                                    });
                                            }
                                        },
                                    )
                                    .catch((error) => {
                                        console.error(error);
                                        setIsFinishedDeterminingSession(true);
                                    });
                            })
                            .catch((error) => {
                                
                                // HERE IS WHERE WE CHECK FOR A BETTER ERROR
                                if (error.message.includes("User not found")) {
                                    console.log("We redirect to registration page!");
                                    // toast.error("We could not find this user in our system. Redirecting to the registration page ... ");
                                    const auth = {
                                        id: -1,
                                        user,
                                        apiToken,
                                        idTokenClaims,
                                    };

                                    setAuthState({
                                        ...auth,
                                        profileUserData: undefined,
                                    }); // for zustand state mgmt

                                    setIsAuthenticatedButUserNotExists(true);

                                    setIsFinishedDeterminingSession(true);
                                }
                                else if (error.message.includes("User banned")) {
                                    console.error(error);
                                    console.log("This user is banned! ", user);

                                    toast.error("There was an issue accessing your account. Please reach out to support@myreferralagent.com.");

                                    setIsAuthenticatedButUserNotExists(true);

                                    setIsFinishedDeterminingSession(true);

                                    setTimeout(mainLogout, 6000);
                                }
                                else {
                                    console.error(error);
                                    toast.error("There was an issue accessing your account. Please reach out to support@myreferralagent.com.");

                                    setIsAuthenticatedButUserNotExists(true);

                                    setIsFinishedDeterminingSession(true);

                                    setTimeout(mainLogout, 6000);
                                }
                            });
                        //* /
                    })
                    .catch((error) => {
                        setIsFinishedDeterminingSession(true);
                        console.error(error)
                    });
            })
            .catch((error) => {
                if (error.error_description && error.error_description.includes('Unknown or invalid refresh token')) {
                    mainLogout();
                    return;
                }

                console.error(error);
                setIsFinishedDeterminingSession(true);
                if (error.message.includes("Missing Refresh Token")) {
                    mainLogout();
                }
            });
    };

    const continueToValidUser = (privateProfileData: TPrivateProfileView) => {

        setIsUserDataFullyLoaded(true);

        const _licenseVerified: boolean = !!privateProfileData?.stateLicenses && (privateProfileData.stateLicenses.filter((l) => l.status === LicenseStatus.verified).length > 0);
        setLicenseVerified(_licenseVerified);

        setIsFinishedDeterminingSession(true);

        const path = window.location.pathname;
        if (_licenseVerified)
        {
            console.log("location.pathname: ", path);

            if (path.includes("/pslink")) {
                // already signed up ignore pslink
                toast.warn(`Ignoring Partnerstack Invite. As you are already a member. ${(privateProfileData.invitedById && privateProfileData.invitedById !== -1) ? `Also you were invited by ${privateProfileData.invitedByAgentFirstName} ${privateProfileData.invitedByAgentLastName}.`: ''}`)
            }

            if (path.includes("auth")) {
                navigate("/dashboard");
            }
            else {
                navigate(path);
            }
        }
        else {
            if (path.includes("/members/")) {
                navigate(path);
            }
        }
    };

    const ONE_MINUTE_IN_MS = 60_1000;
    const API_TOKEN_EXPIRY_CHECKER_INTERVAL_MS = 20_000; // every 20 seconds
    const checkApiTokenExpiry = (): void => {
            
        const apiTokenExpiresAtEpochMs = parseInt(ReactSession.get("jwtExpiresAt"), 10);
        const NOW = Date.now();


        // console.log(">>>    tokenExpiry: ", apiTokenExpiresAtEpochMs);
        // console.log(">>>    NOW        : ", NOW);
        
        if (apiTokenExpiresAtEpochMs === -1) {
            return;
        }
        const tokenExpiresInMs = apiTokenExpiresAtEpochMs - NOW;

        // console.log(">>> CHECKING TOKEN EXPIRY ... ");
        // console.log(">>>    Current AuthState: ", authState);

        console.log(">>>     >> TOKEN WILL EXPIRE in ", ~~(tokenExpiresInMs/1000), " sec");

        if (tokenExpiresInMs < ONE_MINUTE_IN_MS) {
            console.log(">>>     >> TOKEN WILL EXPIRE WITHIN 1 MINUTE");
            getAccessTokenSilently({cacheMode: "off"})
                .then((accessToken) => {

                    const apiToken = accessToken;
                    const tokenPayload = decodeJwt(apiToken);
                    const apiTokenExpiresAtEpochMs = tokenPayload.exp * 1000; // Date.now() + (apiTokenExpiresInSec * 1000) - 10000;

                    ReactSession.set("jwt", apiToken);
                    ReactSession.set("jwtExpiresAt", apiTokenExpiresAtEpochMs);

                    getIdTokenClaims()
                        .then(async (idTokenClaims) => {

                            const auth = {
                                id: authState.profileUserData?.id as number,
                                user,
                                apiToken,
                                idTokenClaims,
                                profileUserData: authState.profileUserData,
                            };

                            console.log(">>>     >> New AuthState: ", authState);
    
                            setAuthState(auth); // for zustand state mgmt
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    if (error.error_description && error.error_description.includes('Unknown or invalid refresh token')) {
                        mainLogout();
                        return;
                    }
    
                    console.error(error);
                });
        }
    };


    // API Token Expiry Checker
    useEffect(() => {
        if (!authState || !isAuthenticated) return;        
        const interval = setInterval(checkApiTokenExpiry, API_TOKEN_EXPIRY_CHECKER_INTERVAL_MS);
        return () => {
            clearInterval(interval);
        }
    }, [authState, isAuthenticated]);

    useEffect(() => {
        if (!isLoading) {
            
            if (isAuthenticated) {
                if (user?.sub?.includes("google-oauth2")) {
                    loadUserData();
                }
                else if (user?.email === user?.name && !user?.email_verified) {
                    setEmailNotVerified(true);
                    setIsFinishedDeterminingSession(true);
                }
                else {
                    loadUserData();
                }
                
            } else {
                setIsUserDataFullyLoaded(false);
                // resetAuth();
                setCouldNotAuthenticate(true);
                setIsFinishedDeterminingSession(true);
                
                writeDeepLink();
            }
        } else {
            setIsUserDataFullyLoaded(false);
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {

        // pauseIdleTimer();

        const path = window.location.pathname;
        if (path.includes("view-referral")) {
            const referralInvitation = path.split("view-referral/")[1];
            if(referralInvitation) {
                setReferralInvitation(referralInvitation);
                ReactSession.set("referralInvitation", referralInvitation);
                console.log(referralInvitation);
            }
        }
        if (path.includes("pslink")) {
            if (window["growsumo"] && window["growsumo"].data) {
                console.log("Invited by: ", window["growsumo"].data);

                // const partnerKey = window["growsumo"].data.partner_key;

                //TODO: save this for this email address in the backend to address cross-device/browser ps-link memory
                // at registration this will be taken as a /register data prop
            }
        }

        if (authState && isAuthenticated) {
            checkApiTokenExpiry();
        }
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-surface-primary">
            {(!isFinishedDeterminingSession || isLoggingOut)
                    && <>
                        <div className={`${isLoggingOut ? 'bg-colors-gs-surface-secondary-dark pointer-events-none opacity-100' : 'blur-sm'} absolute top-0 left-0 z-40 w-full `} />
                        <div className={`${isLoggingOut && 'pointer-events-none opacity-100'} absolute top-0 left-0 z-50 w-full h-full flex flex-col justify-center items-center gap-[50px]`}>
                            <WaitSpinner sizePx={100} />
                            {isLoggingOut && <div className="text-21xl">Signing Out ... </div>}
                        </div>
                    </>
            }
            {!isLoggingOut
                && <>
                    {(!isAuthenticated && couldNotAuthenticate)
                        && <>
                            {location.pathname.startsWith("/members/")
                                ? <Anonymous />
                                : <>
                                    <RegistrationNavBar />
                                    <RegistrationOptions />
                                </>
                            }
                        </>
                    }

                    {(isAuthenticated && emailNotVerified)
                        && <>
                            <RegistrationNavBar />
                            <RegistrationEmailVerify />
                        </>
                    }

                    {(isAuthenticated && isAuthenticatedButUserNotExists) // && isSocialGoogle 
                        && <>
                            <Routes>
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />
                            </Routes>
                            <RegistrationNavBar />
                            <Registration mainLogout={mainLogout} />
                        </>
                    }

                    {(isAuthenticated && isUserDataFullyLoaded)
                        && <>
                            {licenseVerified
                                ? <Authenticated mainLogout={mainLogout} />
                                : <>
                                    <RegistrationNavBar />
                                    <LicenseVerificationPending mainLogout={mainLogout} />
                                </>
                            }
                        </>
                    }
                </>
            }
        </div>
    );
};

export { Auth };
