import React, { FunctionComponent, RefObject } from "react";
import { NewReferralFormStoreType } from "../NewReferralOutbound";

type Step2PropsType = {
    formDataRef: RefObject<NewReferralFormStoreType>;
    onChangeClientFirstName: (any) => void;
    onChangeClientLastName: (any) => void;
    onChangeClientPhone: (any) => void;
    onChangeClientEmail: (any) => void;
    refStep2: (RefObject<HTMLFormElement>);
};

const Step2Client: FunctionComponent<Step2PropsType> = ({
    formDataRef,
    onChangeClientFirstName,
    onChangeClientLastName,
    onChangeClientPhone,
    onChangeClientEmail,
    refStep2,
}) => {

    // logger.info("\tRendering: Step2");
    const formDataStep = formDataRef.current?.form.client;
    console.log("Step2: FormDataStep: ", formDataStep);

    const ensurePhoneFormat = (event): void => {
        const { value } = event.target;
        const formattedValue = value.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        event.target.value = formattedValue;
    }

    return (
        <form ref={refStep2} id="formstep2" role="presentation" autoComplete="off">
            <div className="flex-1 flex flex-col p-5 items-start justify-start gap-[24px] text-base">
                
                {/* confidential disclosure text */}
                <div className="rounded-xl w-fit bg-amber-100 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-row py-3.5 px-5 items-center justify-start gap-[5px] text-sm text-peru">
                    <div className="rounded-xl bg-orange flex flex-row p-2 items-center justify-center">
                        <img
                            className="relative w-4 h-4 overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-newref-out/iconlock.svg"
                        />
                    </div>
                    <div className="relative leading-[15px] font-medium flex-wrap md:break-all">
                        Client information will be kept completely confidential until you wish to award the referral
                    </div>
                </div>
                
                <div className="self-stretch flex flex-row items-start justify-start gap-[50px]">
                    <div className="flex flex-col items-start justify-start gap-[20px]">
                        <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Client First Name</span>
                                <span className="text-brand">*</span>
                            </div>
                            <input
                                    className="order-1 font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    type="text"
                                    placeholder="Client first name"
                                    tabIndex={1}
                                    autoComplete="off"
                                    // onBlur={onChangeClientFirstName}
                                    onChange={onChangeClientFirstName}
                                    defaultValue={formDataStep?.clientFirstName}
                                    required
                                />
                        </div>
                        <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Client Phone (US Number 10 digits)</span>
                                <span className="text-brand">*</span>
                            </div>
                            <input
                                    className="font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    type="tel"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    maxLength={12}
                                    placeholder="Client phone (US Number 10 digits)"
                                    tabIndex={3}
                                    autoComplete="off"
                                    // onBlur={onChangeClientPhone}
                                    onChange={onChangeClientPhone}
                                    defaultValue={formDataStep?.clientPhone}
                                    onKeyUp={ensurePhoneFormat}
                                    required
                                />
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start gap-[20px]">
                        <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Client Last Name</span>
                                <span className="text-brand">*</span>
                            </div>
                            <input
                                    className="order-2 font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    type="text"
                                    pattern="^(?!\s*$).+"
                                    placeholder="Client last name"
                                    tabIndex={2}
                                    autoComplete="off"
                                    // onBlur={onChangeClientLastName}
                                    onChange={onChangeClientLastName}
                                    defaultValue={formDataStep?.clientLastName}
                                    required
                                />
                        </div>
                        <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Client Email</span>
                                <span className="text-brand">*</span>
                            </div>
                            <input
                                    className="font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    type="text"
                                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                    placeholder="Client email address"
                                    tabIndex={4}
                                    autoComplete="off"
                                    // onBlur={onChangeClientEmail}
                                    onChange={onChangeClientEmail}
                                    defaultValue={formDataStep?.clientEmail}
                                    required
                                />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Step2Client;
