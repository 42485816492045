import { FunctionComponent } from "react";
import {
    ReferralAction,
    ReferralStatus,
    ReferralTimeFrame,
    ReferralTimeFrameStr,
    ReferralType,
    TViewReferralInboundModel,
} from "../../../types";
import { getLocaleDateTime } from "../../../utils/get-locale-date-str";
import { toCurrency } from "../../../utils/to-currency";
import { useNavigate } from "react-router-dom";
import useZustandAuthStore from "../../../_Zustand/auth-store";

type PropsType = {
    referral: TViewReferralInboundModel;
};

const NewReferralCard: FunctionComponent<PropsType> = ({ referral }) => {
    const navigate = useNavigate();

    const { usStates } = useZustandAuthStore();
    console.log(referral);

    const viewReferralTimeline = (): void => {
        navigate(`/referrals/${referral.id}`);
    };

    return (
        <div className="bg-white rounded-md w-[308px] h-full flex flex-col p-3.5 box-border gap-[10px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]">
            {/* Row 1 */}
            <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                <div className="flex-1 flex flex-row items-start justify-start gap-[6px]">
                    <img
                        className="relative w-4 h-4"
                        alt=""
                        src="/img/mra-dashboard/inbound.svg"
                    />
                    <div className="relative leading-[16px] font-black text-lg">
                        {referral.type === ReferralType.buy
                            ? "Buyer"
                            : "Seller"}
                    </div>
                </div>
                <div className="flex-1 flex flex-row items-start justify-start text-sm">
                    {(referral.status === ReferralStatus.sentToAgent ||
                        referral.status === ReferralStatus.sentInvitation || 
                        referral.status === ReferralStatus.agreementSent) && (
                        <div className="flex-1 relative font-semibold text-orangered">
                            Not yet accepted
                        </div>
                    )}
                </div>
            </div>

            {/* Row 2 */}
            {/* Column 1 */}
            <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                    {/* hidden */}
                    <div className="hidden self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                            Client
                        </div>
                        <div className="self-stretch relative text-xs leading-[17px] text-gray-800 blur-sm">
                            {/* {referral.clientFirstName} {referral.clientLastName} */}
                            Hidden
                        </div>
                    </div>

                    <div className="self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                            Referring Agent
                        </div>
                        <div className="self-stretch relative text-xs leading-[17px]">
                            {referral.userFirstName ?? "-"}{" "}
                            {referral.userLastName ?? "-"}
                        </div>
                    </div>
                    {referral.type === ReferralType.buy ? (
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Budget
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start text-xs text-gray-800">
                                <div className="relative leading-[17px]">{`${toCurrency(
                                    referral.buyRangeMin,
                                )} - ${toCurrency(referral.buyRangeMax)}`}</div>
                            </div>
                        </div>
                    ) : (
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Sale Target
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start text-xs text-gray-800">
                                <div className="relative leading-[17px]">{`${toCurrency(
                                    referral.sellTargetPrice,
                                )}`}</div>
                            </div>
                        </div>
                    )}
                    {referral.type === ReferralType.buy && (
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Pre-Approved
                            </div>
                            {referral.clientPreapproved ? (
                                <img
                                    className="relative w-4 h-4 overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/mra-dashboard/iconcheckthick.svg"
                                />
                            ) : (
                                <>No</>
                            )}
                        </div>
                    )}
                </div>

                {/* Column 2 */}
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
                    <div className="self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                            Submitted on
                        </div>
                        <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                            {getLocaleDateTime(
                                referral.actions.find(
                                    (a) =>
                                        a.type ===
                                        ReferralAction.referralCreated,
                                )?.date,
                            )}
                        </div>
                    </div>
                    {referral.type === ReferralType.buy && (
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Timeframe
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                {referral.type === ReferralType.buy ? (
                                    <>
                                        {" "}
                                        {referral.buyTimeFrame
                                            ? ReferralTimeFrameStr[
                                                  ReferralTimeFrame[
                                                      referral.buyTimeFrame
                                                  ]
                                              ]
                                            : "-"}{" "}
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        {referral.sellTimeFrame
                                            ? ReferralTimeFrameStr[
                                                  ReferralTimeFrame[
                                                      referral.sellTimeFrame
                                                  ]
                                              ]
                                            : "-"}{" "}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                            Fee Percentage
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start text-xs text-gray-800">
                            <div className="relative leading-[17px]">
                                {referral.fee}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="self-stretch flex flex-1 flex-col items-start justify-start">
                <div className="self-stretch relative text-xs">Location(s)</div>
                {referral.type === ReferralType.buy ? (
                    <div className="self-stretch flex flex-row flex-wrap items-start justify-start text-xs text-gray-800">
                        <div className="relative leading-[17px]">
                            {referral.buyLocations?.join(", ")}
                        </div>
                    </div>
                ) : (
                    <div className="self-stretch flex flex-row items-start justify-start text-xs text-gray-800">
                        <div className="relative leading-[17px]">{`${
                            referral.sellCity
                        }, ${usStates?.find(
                            (s) => s.id === referral.sellStateId,
                        )?.abbreviation}`}</div>
                    </div>
                )}
            </div>

            {/* Accept / Open */}
            <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                <button
                    disabled
                    className="hidden cursor-not-allowed [border:none] py-2 px-4 bg-darkslategray-100 text-white flex-1 rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                >
                    <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0 hidden"
                        alt=""
                        src="/img/mra-dashboard/icongiftsolid.svg"
                    />
                    <div className="relative text-sm leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                        Accept
                    </div>
                    <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src="/img/mra-dashboard/iconthumbsupsolid.svg"
                    />
                </button>

                <button
                    className="cursor-pointer py-2 px-4 bg-white rounded-md box-border h-10 flex-1 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-lightgray-100"
                    type="button"
                    onClick={viewReferralTimeline}
                >
                    <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0 hidden"
                        alt=""
                        src="/img/mra-dashboard/icongiftsolid1.svg"
                    />
                    <div className="relative text-sm leading-[21px] font-semibold font-small-medium text-dark text-center">
                        Open
                    </div>
                    <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src="/img/mra-dashboard/iconopen.svg"
                    />
                </button>
            </div>
        </div>
    );
};

export { NewReferralCard };
