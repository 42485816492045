import React, { FunctionComponent } from "react";
// import { useSelector } from "react-redux";
import { SocialMediaIdEnum, TPublicProfileView, TUserData } from "../../../types";
import { getAvatar } from "../../../utils/get-avatar";
import { MRAVerifiedLogo } from "../../../components/MRAVerifiedLogo";
import useZustandAuthStore from "../../../_Zustand/auth-store";
import { DEFAULT_AVATAR_PATH } from "../../AuthenticatedNavBar";

type Props = {
    member: TPublicProfileView;
    isMemberSelected: boolean;
    licensesVerified: boolean;
    pickMember?: (any) => void;
    enableViewProfileLink?: boolean;
}
const MemberSearchResultCard: FunctionComponent<Props> = ({ member, isMemberSelected, licensesVerified, pickMember, enableViewProfileLink = true }) => {

    const { usStates } = useZustandAuthStore();
    
    const toYrsMonthsStr = (fromYear, fromMonth = 1): string => {
        if (fromYear === null) {
            return "-";
        }
        const diff = new Date(new Date().getTime() - new Date(fromYear, fromMonth).getTime());
        return `${diff.toISOString().slice(0, 4) - 1970} Yrs ${diff.getMonth() + 1} Months`;
    }

    const capitalize = (string): string => {
        return string.split(' ').map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
    }
    
    return (
        <div className={`w-[310px] rounded-md flex flex-col items-start justify-start gap-[16px] border-solid p-[20px] bg-white ${isMemberSelected ? 'border-[3px] border-[#78AFD5] shadow-2xl':'border-[1px] border-gray-300 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'}`}>

            <div className="flex flex-col items-start justify-start gap-[10px]">
                
                {/* Name, Brokerage, Selector */}
                <div className="w-full flex flex-row items-start justify-start gap-[16px] text-xl">

                    {/* Profile Pic/Avatar */}
                    <div className="w-fit flex flex-col items-start justify-start">
                        <img
                            alt={`${member.firstName} ${member.lastName}`}
                            src={getAvatar(member.primaryImage as string, 100)}
                            className="rounded-xl w-[80px] h-[80px]"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DEFAULT_AVATAR_PATH;
                            }}
                        />
                    </div>

                    <div className="w-fit flex flex-col items-start justify-start gap-[0px]">

                        {/* Ratings */}
                        <div className="flex flex-row h-[40px] pt-0 px-0 pb-[7px] items-center justify-start text-xs gap-[4px]">
                            {(member.reviews && Array.isArray(member.reviews) && member.reviews.length > 0)
                                ? <div className="flex flex-col gap-[6px] items-center">
                                    <div className="flex flex-row gap-[10px] items-center">
                                        <div className="flex flex-row gap-0 items-center">
                                            {[...Array(Math.trunc((member.reviews?.reduce((sum, r) => sum + r.rating, 0)/(member.reviews?.length ?? 1) as number) as number) + 1).keys()].slice(1).map((i) => 
                                                <img key={`overall-rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-referral-timeline/iconstar_full.svg' />
                                            )}
                                            {( (member.reviews?.reduce((sum, r) => sum + r.rating, 0)/(member.reviews?.length ?? 1) as number) % 1 > 0 )
                                                && <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-referral-timeline/icon-star-half-rounded.svg' />
                                            }
                                        </div>
                                        <div className="flex flex-row gap-0 items-center">
                                            {(member.reviews?.reduce((sum, r) => sum + r.rating, 0)/(member.reviews?.length ?? 1) as number).toFixed(1)}
                                        </div>
                                    </div>
                                    <div className="self-stretch flex flex-row gap-0 items-start justify-start">
                                        ({member.reviews?.length} reviews)
                                    </div>
                                </div>
                                : <i>No Reviews Information Yet</i>
                            }
                        </div>
                        
                        {/* Member Name */}
                        <b className="w-full text-md h-[40px]">{member.firstName} {member.lastName}</b>
                    </div>
                    

                    {/* <img
                        className="hidden relative"
                        alt=""
                        src="/img/mra-newref-out/iconfavorite10.svg"
                        width={20}
                        height={20}
                    /> */}

                    {/* {licensesVerified &&
                        <MRAVerifiedLogo sizePx={16} />
                    } */}

                    {/* Member Selector */}
                    {pickMember &&
                        <div className="flex flex-row grow justify-end mr-[10px]">
                            <div className="flex w-fit cursor-pointer" >
                                <input
                                    className="cursor-pointer relative w-4 h-4"
                                    type="radio"
                                    name="memberSelectionRadio"
                                    onClick={() => pickMember(member)}
                                    required
                                />
                            </div>
                        </div>
                    }
                    
                </div>

                {/* Brokerage Name & Address */}
                <div className="w-[290px] flex flex-col items-start justify-start gap-[5px] text-sm">
                    {/* Brokerage Name */}
                    <div className="w-full text-sm font-bold">{member.brokerageName}</div>

                    {/* Brokerage Address */}
                    <div className="flex flex-col text-sm gap-[2px] ">
                        <div>
                            {member?.officeAddress1 ? `${member?.officeAddress1}, ` : ''}
                            {member?.officeAddress2 ? `${member?.officeAddress2}` : ''}
                        </div>
                        <div>
                            {member?.officeCity ? `${member?.officeCity}, ` : ''}
                            {member?.officeStateId ? `${usStates?.find((s) => s.id === member?.officeStateId)?.abbreviation}, ` : ''}
                            {member?.officeZip ? `${member?.officeZip}` : ''}
                        </div>
                    </div>
                </div>

                {/* Contact: Cell, website, email */}
                <div className="w-[290px] h-[112px] flex flex-col items-start justify-start gap-[8px] bg-surface-primary p-[12px] rounded-md text-sm">
                    
                    {/* Cell Phone */}
                    {member.cellPhoneNumber
                        && <div className="w-[290px] flex flex-row gap-[12px]">
                                <div className="text-sm text-gray-800 font-bold">
                                    Cell:
                                </div>
                                <div className="text-sm text-gray-800">
                                    <a className="no-underline hover:underline text-link-blue" href={`tel:${member.cellPhoneNumber}`}>{member.cellPhoneNumber}</a>
                                </div>
                            </div>
                    }

                    {/* Email */}
                    <a
                        className='w-[290px] text-link-blue no-underline hover:underline text-sm truncate'
                        title={member.email as string}
                        target="_blank"
                        href={`mailto:${member.email}`}
                    >
                        {member.email}
                    </a>
                    
                    {/* Website */}
                    {member.primaryWebsite
                        && <a
                            className='w-[290px] text-link-blue no-underline hover:underline text-sm truncate'
                            href={member.primaryWebsite}
                            target="_blank"
                            title={member.primaryWebsite}
                        >
                                {member.primaryWebsite}
                        </a>
                    }

                    {/* Social Media */}
                    <div className="self-stretch flex flex-row gap-[6px] mt-[4px]">
                        {member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url
                            && <a
                                    target="_blank"
                                    className='no-underline cursor-pointer'
                                    title={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                    href={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                >
                                    <img src='/img/mra-social-media/facebook.svg' className='w-[25px]' />
                                </a>
                        }
                        {member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url
                            && <a
                                    target="_blank"
                                    className='hover:underline text-link-blue no-underline cursor-pointer'
                                    title={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                    href={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                >
                                    <img src='/img/mra-social-media/instagram.svg' className='w-[25px]' />
                                </a>
                        }
                        {member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url
                            && <a
                                    target="_blank"
                                    className='no-underline'
                                    title={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                    href={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                >
                                    <img src='/img/mra-social-media/twitter.svg' className='w-[25px]' />
                                </a>
                        }
                        {member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url
                            && <a
                                    target="_blank"
                                    className='hover:underline text-link-blue no-underline cursor-pointer'
                                    title={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                    href={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                >
                                    <img src='/img/mra-social-media/youtube.svg' className='w-[25px]' />
                                </a>
                        }
                        {member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url
                            && <a
                                    target="_blank"
                                    className='hover:underline text-link-blue no-underline cursor-pointer'
                                    title={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                    href={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                >
                                    <img src='/img/mra-social-media/tiktok.svg' className='w-[25px]' />
                                </a>
                        }
                        {member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url
                            && <a
                                    target="_blank"
                                    className='hover:underline text-link-blue no-underline cursor-pointer'
                                    title={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                    href={member?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                >
                                    <img src='/img/mra-social-media/linkedin.svg' className='w-[25px]' />
                                </a>
                        }
                    </div>
                </div>
            
                {/* Primary Location(s) */}
                <div className="w-[290px] h-[80px] flex flex-col items-start justify-start gap-[5px] bg-surface-primary p-[12px] rounded-md">
                    <div className="text-sm font-bold">
                        Locations Served
                    </div>
                    <div className="cursor-default wrap text-sm "
                        // title={member.servedCities.map((c) => `${capitalize(c.city)}, ${usStates?.find((s) => s.id === c.stateId)?.abbreviation}`).join(' - ')}
                        // title="Locations Served"
                    >
                        {member.servedCities && Array.isArray(member.servedCities) && member.servedCities.length > 0 && member.servedCities.map((c) => (
                            <span key={`${c.city}_${c.stateId}`} className="text-secondary">
                                <span className="capitalize">{c.city}</span>
                                <span className="capitalize">{' '}</span>
                                <span>{usStates?.find((s) => s.id === c.stateId)?.abbreviation}</span>
                            </span>
                        ))
                        .reduce((prev, curr) => [prev, ', ', curr])}
                    
                        {member.servedCities && Array.isArray(member.servedCities) && member.servedCities.length === 0 &&
                            <i className="text-xs">No Location Information Yet</i>
                        }
                    </div>
                </div>


                {/* Experience and Profile Link */}
                <div className="w-[full] self-stretch flex flex-row items-start justify-start gap-[5px] rounded-md text-sm">

                    <div className="flex flex-1 flex-col gap-[5px] bg-surface-primary p-[12px] rounded-md">
                        <div className="self-stretch relative leading-[17px] opacity-[0.75]  font-semibold">
                            Experience
                        </div>
                        <div className="self-stretch relative text-sm">
                            {!member.firstYearOfService
                                ? <i className="text-xs">-</i>
                                : `${toYrsMonthsStr(member.firstYearOfService)}`
                            }
                        </div>
                    </div>

                    {/* MRA Profile */}
                    {enableViewProfileLink
                        && <div className="flex flex-col self-stretch items-end justify-end gap-[5px] p-[12px] rounded-md text-lg">
                            <a
                                className='hover:text-link-blue text-secondary no-underline hover:underline flex flex-row gap-[10px] items-center'
                                target="_blank"
                                href={`/members/${member.publicProfile}`}
                            >
                                <span>View profile</span>
                                <img
                                    className="relative w-[12px] h-[12px]"
                                    alt="outbound"
                                    src="/img/mra-newref-out/icon-arrow-right.svg"
                                />
                            </a>
                        </div>
                }
                </div>
                

                {/* Sales Volume */}
                {/* <div className="hidden rounded bg-[#f2f2f2] w-[100px] flex flex-col py-2 px-3 box-border items-center justify-start gap-[4px]">
                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium text-md">
                        Sales Volume
                    </div>
                    <div className="self-stretch relative leading-[26px] font-semibold text-xl">
                        {member.lastYearSales}
                    </div>
                </div> */}

                {/* Last Years Transactions */}
                {/* <div className="hidden rounded bg-[#f2f2f2] w-[100px] flex flex-col py-2 px-3 items-center justify-start gap-[4px]">
                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium text-md">
                        {new Date().getFullYear() - 1} Transactions
                    </div>
                    <div className="self-stretch relative leading-[26px] font-semibold text-xl">
                        {member.lastYearTransactions}
                    </div>
                </div> */}


            </div>

        </div>
    )};

export default MemberSearchResultCard;