type TResponseJson = Readonly<{
    success?: boolean;
    error?: string;
    errorMessage?: string;
    // eslint-disable-next-line camelcase
    access_token?: string;
    // eslint-disable-next-line camelcase
    error_description?: string;
    result?: unknown;
}>;

const handleApiResponse = async (response: Response): Promise<unknown> => {

    if (response.status === 401) {
        // const apiLogout = getApiLogout();
        // if (apiLogout) apiLogout();
        throw new Error('Unauthorized: Access is denied due to invalid credentials.');
    }
    if (response.status === 403) {
        // const apiLogout = getApiLogout();
        // if (apiLogout) apiLogout();
        throw new Error('Please update your application and try again.');
    }

    const isPdf: boolean | undefined = response?.headers.get('Content-Type')?.includes('application/pdf');
    const isJson: boolean | undefined = response?.headers.get('Content-Type')?.includes('application/json');
    const isText: boolean | undefined = response?.headers.get('Content-Type')?.includes('text/plain');
    const isHtml: boolean | undefined = response?.headers.get('Content-Type')?.includes('text/html');
    
    let jsonResponse: TResponseJson | null = null;
    if (isJson) {
        try {
            jsonResponse = await response.json();
        } catch (error) {
            const textResponse = await response.text();
            try {
                jsonResponse = JSON.parse(textResponse);
            }
            catch (error) {
                console.error('handleApiResponse', 'Bad JSON. Text: ', textResponse);
                return textResponse;
            }
        }

        if (jsonResponse) {
            if(jsonResponse.access_token) {
                return jsonResponse;
            }

            if(jsonResponse.success) {
                return jsonResponse.result;
            }
            else // not success
            {
                if(jsonResponse.error) {
                    throw new Error(jsonResponse.error);
                }
                
                if(jsonResponse.error_description) {
                    throw new Error(jsonResponse.error_description);
                }
            }
        }
        else {
            console.error('Could not parse response. ', jsonResponse)
        }
    }
    else if (isPdf) {
        return await response.blob();
    }
    else if (isText) {
        const textResponse = await response.text();
        try {
            jsonResponse = JSON.parse(textResponse);
            return jsonResponse;
        }
        catch (error) {
            console.error('Not JSON. Returning Text: ', textResponse);
            return textResponse;
        }
    }
    else if (isHtml) {
        return await response.text();
    }
    else {
        throw new Error(`Invalid Response Content Type. Content-Type: ${response?.headers.get('Content-Type')}`);
    }
};

export default handleApiResponse;
