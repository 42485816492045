import { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { useConfirm } from "material-ui-confirm";
import { getAllSkyflowW9, getDropboxSignSignatureFileUrl, getPrivateProfileById, getReferralById, postCancelReferral, postConfirmAgreement, postDropboxSignEmbeddedSignatureRequestInbound, postNotionMRAReviewTask, postReferralNote, postReferralReviewForUser, postReferralsCancelInvitation, postReferralsResendInvitation, postReferralsUpdateContactStatus, updateReferral } from "../../utils/api";
import { ReferralAction, ReferralActionStr, ReferralOwner, ReferralPropertyType, ReferralPropertyTypeStr, ReferralSource, ReferralSourceStr, ReferralStatus, ReferralTimeFrame, ReferralTimeFrameStr, ReferralType, ReferralTypeStr, TPrivateProfileView, TReferralAction, TReferralModel, TResponseGetExistReferral, TSendReferralsNoteData, TUsStateList, UpdateReferralStatus } from "../../types";
import { getAvatar } from "../../utils/get-avatar";
import { getLocaleDateTimeWithTzStrFullDay, getLocaleDateTimeWithTzStrShortForTimeline, getLocaleDateWithTzStrFullDay, getLocaleDateWithTzStrShort } from "../../utils/get-locale-date-str";
import "react-toastify/dist/ReactToastify.css";
import { toCurrency } from "../../utils/to-currency";
import { WaitContainer } from "../../components/WaitContainer";
import useZustandAuthStore from "../../_Zustand/auth-store";
import { DEFAULT_AVATAR_PATH } from "../AuthenticatedNavBar";
import ToDoIcon from "../../components/ToDoIcon";
import GenericActionModal from "../../components/GenericActionModal";
import { Page404 } from "../../components/Page404/Page404";
import { isIPhoneBrowser, isMobileBrowser } from "../../utils/mobile";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type TParams = Readonly<{
    referralId?: number;
}>;

const OutboundReferralTimeline: FunctionComponent<TParams> = ({ referralId }) => {

    const { authState, usStates } = useZustandAuthStore();
    const usStatesRef = useRef<TUsStateList | null>();
    usStatesRef.current = usStates;

    const userAccount = authState.profileUserData;

    const userPrimaryImage = userAccount?.primaryImage;

    // const params = useParams<TParams>();
    const confirm = useConfirm();

    // const referralId = +(params.referralId as string);
    const [referral, setReferral] = useState<TResponseGetExistReferral | null>();
    const [currReferralAction, setCurrReferralAction] = useState<TReferralAction>({} as TReferralAction);

    const [awaitingReferralAcceptance, setAwaitingReferralAcceptance] = useState<boolean>(false);
    const [referralDeclined, setReferralDeclined] = useState<boolean>(false);
    const [referralIncomplete, setReferralIncomplete] = useState<boolean>(false);
    const [referralCancelled, setReferralCancelled] = useState<boolean>(false);
    
    const [referralReturned, setReferralReturned] = useState<boolean>(false);
    const [referralReturnedNotBuying, setReferralReturnedNotBuying] = useState<boolean>(false);
    const [referralReturnedHiredAnother, setReferralReturnedHiredAnother] = useState<boolean>(false);

    const [awaitingAgreement, setAwaitingAgreement] = useState<boolean>(false);
    const [awaitingAgreementSignature, setAwaitingAgreementSignature] = useState<boolean>(false);
    const [awaitingAgreementSignatureAcceptance, setAwaitingAgreementSignatureAcceptance] = useState<boolean>(false);
    const [agreementDeclined, setAgreementDeclined] = useState<boolean>(false);
    const [isViewedSignature, setIsViewedSignature] = useState<boolean>(false);
    const [agreementSignatureAccepted, setAgreementSignatureAccepted] = useState<boolean>(false);
    const [clientContacted, setClientContacted] = useState<boolean>(false);
    
    const [clientAppointmentSet, setClientAppointmentSet] = useState<boolean>(false);
    
    const [clientAgreementSigned, setClientAgreementSigned] = useState<boolean>(false);
    const [showingHouses, setShowingHouses] = useState<boolean>(false);
    
    const [homeUnderContract, setHomeUnderContract] = useState<boolean>(false);
    const [contractClosed, setContractClosed] = useState<boolean>(false);
    const [referralCheckIssued, setReferralCheckIssued] = useState<boolean>(false);
    const [referralCheckReceived, setReferralCheckReceived] = useState<boolean>(false);

    const [reviewISent, setReviewISent] = useState<any>(null);
    // const [reviewReceived, setReviewReceived] = useState<any>(null);

    const [isValidReferral, setIsValidReferral] = useState<boolean>(true);
    const [agentCreatedInboundReferral, setAgentCreatedInboundReferral] = useState<boolean>(false);

    const getReferral = (callback?): void => {
        getReferralById(referralId as number)
        .then((referralInfo) => {
            console.log(referralInfo);

            let _agentCreatedInboundReferral = false;

            setReferral(referralInfo);

            if (referralInfo.referral.owner == ReferralOwner.agent) {
                setAgentCreatedInboundReferral(true);
                _agentCreatedInboundReferral = true;
            }

            const reviewIWrote = referralInfo.reviews.find((r) => r.reviewerId === authState.id);
            setReviewISent(reviewIWrote);

            // const reviewIGot = referralInfo.reviews.find((r) => r.userId === authState.id && r.status === 1);
            // setReviewReceived(reviewIGot);

            const currAction = (referralInfo.referral.actions as TReferralAction[])
                        .filter((a) => ![
                            ReferralAction.addedNote,
                            ReferralAction.agentPhoned,
                            ReferralAction.clientNotContacted,
                            ReferralAction.choosingAgent,
                            ReferralAction.referralUnresponsive,
                            ReferralAction.resentAgreementRequest,
                            ReferralAction.sentStatusUpdateRequest,
                            ReferralAction.appointmentNotScheduled,
                        ].includes(a.type))
                        .sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
            setCurrReferralAction(currAction);

            determineOutboundReferralStates(referralInfo, _agentCreatedInboundReferral);

            if (callback) {
                callback();
            }
        }).catch((error) => {
            console.log(error);
            if (error.message.includes('Referral not found') || error.message.includes("You don't have permission to view user data")) {
                setIsValidReferral(false);
            }
            if (callback) {
                callback();
            }
            
        });
    };

    const determineOutboundReferralStates = (referral, agentCreatedInboundReferral): void => {
        // true: show text that requested agent has yet to acccept referral
        setAwaitingReferralAcceptance(
            [
                ReferralStatus.sentToAgent,
                ReferralStatus.sentInvitation,
            ].includes(referral?.referral.status as ReferralStatus)
            && agentCreatedInboundReferral
        );

        // true: show text that the requested agent has declined referral
        setReferralDeclined([
            ReferralStatus.declined
        ].includes(referral?.referral.status as ReferralStatus));

        setReferralIncomplete([
            ReferralStatus.incomplete
        ].includes(referral?.referral.status as ReferralStatus));

        setReferralCancelled([
            ReferralStatus.canceled
        ].includes(referral?.referral.status as ReferralStatus));

        setReferralReturned([
            ReferralStatus.returned
        ].includes(referral?.referral.status as ReferralStatus));

        setReferralReturnedHiredAnother([
            ReferralStatus.hiredAnotherAgent
        ].includes(referral?.referral.status as ReferralStatus));

        setReferralReturnedNotBuying([
            ReferralStatus.returnedNotIntendToBuy
        ].includes(referral?.referral.status as ReferralStatus));


        
        // true: show button to send agreement
        setAwaitingAgreement([
            // ReferralStatus.accepted,
            ReferralStatus.sentToAgent,
            ReferralStatus.sentInvitation,
        ].includes(referral?.referral.status as ReferralStatus));
        /*
        if (referralAccepted && referral?.referral.useGeneratedAgreement) {
            if (!referral?.referral.agreement) {
                console.log("Generating Signature Request and Updating Referral");
                setIsGeneratingSignatureRequest(true);
                const doit = async (referral): Promise<void> => {
                    try {
                        console.log("Creating Signature Request");
                        const { signatureRequestId, signatureId } = await createDropboxSignSignatureRequest(referral, { userBrokerage, userEmail, userFullName, userOfficeAddress, userOfficePhone, usStates: usStatesRef.current });

                        console.log("Saving Referral Agreement prop with SignatureRequest Info");
                        const agreement = JSON.stringify({ signatureRequestId, signatureId });

                        const data = buildReferralUpdateData(referral);
                        data.agreement = agreement;

                        updateReferral(referral.referral.id, data).then((result) => {
                            console.log(result);
                            getReferral(() => {
                                setIsGeneratingSignatureRequest(false);
                            });
                        }).catch((error) => {
                            console.error(error);
                            setIsGeneratingSignatureRequest(false);
                        });
                    }
                    catch (error) {
                        console.error(error);
                        setIsGeneratingSignatureRequest(false);
                    }
                };

                doit(referral).then(() => {}).catch(() => {});
            };
            
        }
        */

        // true: show text that requested agent is yet to sign/decline agreement
        setAwaitingAgreementSignature([
            ReferralStatus.agreementSent
        ].includes(referral?.referral.status as ReferralStatus));

        // true: show text that requested agent has declined signing the agreement
        setAgreementDeclined([
            ReferralStatus.signedAgreementDeclined
        ].includes(referral?.referral.status as ReferralStatus));

        // true: show docusign link of signed agreement AND button to accept agreement signature
        setAwaitingAgreementSignatureAcceptance([
            ReferralStatus.signedAgreementUploaded
        ].includes(referral?.referral.status as ReferralStatus));

        setAgreementSignatureAccepted([
            ReferralStatus.signedAgreementConfirmed,
            ReferralStatus.clientContinueContacting,
            ReferralStatus.clientNotContacted,
        ].includes(referral?.referral.status as ReferralStatus));


        setClientContacted([
            ReferralStatus.clientContacted
        ].includes(referral?.referral.status as ReferralStatus));

        setClientAppointmentSet([
            ReferralStatus.appointmentSet
        ].includes(referral?.referral.status as ReferralStatus));

        setShowingHouses([
            ReferralStatus.lookingHouses
        ].includes(referral?.referral.status as ReferralStatus));

        setClientAgreementSigned([
            ReferralStatus.clientAgreementSigned
        ].includes(referral?.referral.status as ReferralStatus));

        setHomeUnderContract([
            ReferralStatus.underContractClosingDate
        ].includes(referral?.referral.status as ReferralStatus));

        // disbursement instruction stuff
        setReferralCheckIssued([
            ReferralStatus.referralCheckIssued
        ].includes(referral?.referral.status as ReferralStatus));

        setContractClosed([
            ReferralStatus.contractClosed
        ].includes(referral?.referral.status as ReferralStatus));

        setReferralCheckReceived([
            ReferralStatus.referralCheckReceived
        ].includes(referral?.referral.status as ReferralStatus));

    }

    const [resendingInvitation, setResendingInvitation] = useState<boolean>(false);
    const resendInvitation = (): void => {
        setResendingInvitation(true);
        const toastId = toast.loading('Resending This Referral Invitation ... ');
        postReferralsResendInvitation(referral?.referral.id as number)
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setResendingInvitation(false);
                    toast.dismiss(toastId);
                    toast.info("Invitation Resent Successfully!");
                });
                
            })
            .catch((error) => {
                console.error(error);
                setResendingInvitation(false);
                toast.dismiss(toastId);
            });
    };

    const MAX_MSG_CHARS = 400;
    const [msgContent, setMsgContent] = useState<string>("");
    const [msgCharsLeft, setMsgCharsLeft] = useState<number>(MAX_MSG_CHARS);
    const msgLengthCheck = (e): void => {
        const str = e.target.value;
        
        setMsgContent(str);
        setMsgCharsLeft(MAX_MSG_CHARS - str.length);
    };
    const [isSendingMsg, setIsSendingMsg] = useState<boolean>(false);
    const sendMessage = (): void => {
        console.log(msgContent);

        if (msgContent === '') {
            toast.error("Error: Please enter a valid message!");
            return;
        }

        setIsSendingMsg(true);
        const data = {
            agentId: authState.id,
            note: msgContent,
            referralId: referral?.referral.id,
            date: new Date().toString()
        };

        postReferralNote(data as TSendReferralsNoteData).then((result) => {

            console.log(result)
            
            getReferral(() => {
                setIsSendingMsg(false);

                setMsgContent("");
                setMsgCharsLeft(MAX_MSG_CHARS);
            });

        }).catch((error) => {
            console.error(error);

            setIsSendingMsg(false);

            setMsgContent("");
            setMsgCharsLeft(MAX_MSG_CHARS);
        })
    };

    const [agreementTemplateModalVisible, setAgreementTemplateModalVisible] = useState<boolean>(false);
    const [agreementTemplateModalLabel, setAgreementTemplateModalLabel] = useState<string>("");
    const [agreementTemplateUrl, setAgreementTemplateUrl] = useState<string>("");
    const downloadAgreementOld = (fileUrl) => {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = `ReferralAgreement_${referralId}_${referral?.referral.userFirstName}${referral?.referral.userLastName}_${referral?.referral.agentFirstName}${referral?.referral.agentLastName}.pdf`;
        
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    const createDropboxSignSignatureRequest = async (
        referral: TReferralModel,
        referralId,
        usStates,
    ): Promise<any> => {
        try {
            
            const response = await postDropboxSignEmbeddedSignatureRequestInbound(referral, referralId, usStates);
            console.log(response);
            const signatureRequestId = response.signature_request.signature_request_id;
            const signatureId = response.signature_request.signatures[0].signature_id;

            console.log("signatureId", signatureId);
            console.log("signatureRequestId", signatureRequestId);

            return { signatureId, signatureRequestId };
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
    
    const [generatingAgreement, setGeneratingAgreement] = useState<boolean>(false);
    const createSignatureRequestAndUpdateReferral = () => {

        setGeneratingAgreement(true);

        // Creating Signature Request for this Referral
        createDropboxSignSignatureRequest(referral?.referral, referral?.referral.id, usStates as TUsStateList)
            .then((response) => {
                console.log("Done: Result: ", response);
                const { signatureRequestId, signatureId } = response;
        
                const _ref = {...(referral?.referral)};

                _ref["status"] = ReferralStatus.agreementSent;
                _ref["agreement"] = JSON.stringify({ signatureRequestId, signatureId });
                
                // Adding Signature Details to Referral
                updateReferral(referral?.referral.id as number, _ref)
                    .then((result) => {

                        getReferral(() => {
                            setGeneratingAgreement(false);
                        });

                    }).catch((error) => {
                        console.error(error);
                        toast.error("Something went wrong when adding Signature Details to Referral. Please contact support with help to resolve this.");
                        setGeneratingAgreement(false);
                    });
        })
        .catch((error) => {
            console.error(error);
            toast.error("Something went wrong with creating the signature request. Please contact support with help to resolve this.");
            setGeneratingAgreement(false);
        });
    };

    /*
    const downloadAgreement = (file, callback) => {
        const request = new XMLHttpRequest();
        request.responseType = 'blob';
        // request.addEventListener('load', log('load ' + file));
        // request.addEventListener('error', log('error ' + file));
        request.onprogress = (e) => {
            if (e.lengthComputable) {
                console.log("Downloading ... " + ((e.loaded / e.total) * 100) + " %");
            }
        };
        request.onloadend = () => {
            callback(request.response);
        };
        request.onreadystatechange = (e) => {
            if (request.readyState === 4) {
                console.log("download ready");
            }
        };

        request.open('GET', file);
        request.send();
      }
      
      const saveDownloadAgreement = (object, mime, name) => {
        var a = document.createElement('a');
        var url = URL.createObjectURL(object);
        a.href = url;
        a.download = name;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    */
    
    const viewAgreement = async (): Promise<void> => {
        const vloading = toast.loading(`Retrieving Referral Agreement with ${referral?.referral.agentFirstName} ${referral?.referral.agentLastName}!`);

        try {
            const signatureInfo: any = JSON.parse(referral?.referral.agreement as string)
            const { signatureRequestId } = signatureInfo;

            console.log("Getting file URL for Signature Request: ", signatureRequestId);

            const response = await getDropboxSignSignatureFileUrl(signatureRequestId, referralId as number);
            console.log(response);
            const fileUrl = `${response.data_uri}`;

            // const response = await getDropboxSignSignatureFileDownloadUrl(signatureRequestId, referralId);
            // console.log(response);
            // const fileUrl = `${response.file_url}`;

            toast.dismiss(vloading);

            // if (isMobile) {
            //     setTimeout(() => {
            //         downloadAgreementOld(fileUrl);
            //     }, 100);

            //     alert("File download has begun, please check the notification bar for download update.");

            //     // const downloadFName = `ReferralAgreement_${referralId}_${referral?.referral.userFirstName}${referral?.referral.userLastName}_${referral?.referral.agentFirstName}${referral?.referral.agentLastName}.pdf`;
            //     // downloadAgreement(fileUrl, (f) => {
            //     //     saveDownloadAgreement(f, 'application/pdf', downloadFName);
            //     //     // toast.info("File download has begun, please check the notification bar for download update.");
            //     //     alert("File download has begun, please check the notification bar for download update.");
            //     // });

            //     return;
            // }

            const templateViewerLabel = `Agreement with ${referral?.referral.agentFirstName} ${referral?.referral.agentLastName}`;

            setAgreementTemplateModalLabel(templateViewerLabel);
            setAgreementTemplateUrl(fileUrl);
            setAgreementTemplateModalVisible(true);

        } catch (error: any) {
            toast.dismiss(vloading);
            console.error("FileURL Fetch Error: ", error);
        }
    }
    const viewSignedAgreement = (): void => {
        
        viewAgreement();

        setTimeout(() => {
            setIsViewedSignature(true);
        }, 2000);
    }

    
    const [confirmingSignedAgreement, setConfirmingSignedAgreement] = useState<boolean>(false);
    const confirmSignedAgreement = (): void => {
        setConfirmingSignedAgreement(true);
        postConfirmAgreement(referral?.referral.id as number)
            .then((result) => {
                console.log(result);

                getReferral(() => {
                    setConfirmingSignedAgreement(false);
                });
            })
            .catch((error) => {
                setConfirmingSignedAgreement(false);
                toast.error(`Error: ${error.message}`);

                console.error(error);
            });
    };

    const [indicatingReferralCheckReceived, setIndicatingReferralCheckReceived] = useState<boolean>(false);
    const indicateReferralCheckReceived = (): void => {
        setIndicatingReferralCheckReceived(true);

        const toastId = toast.loading(`Indicating Referral Check is Received from Referred Agent ${referral?.referral.agentFirstName} ${referral?.referral.agentLastName}! Congratulations!!`);
        postReferralsUpdateContactStatus(referral?.referral.id as number, { status: UpdateReferralStatus.referralCheckReceived })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setIndicatingReferralCheckReceived(false);
                    toast.dismiss(toastId);
                });
                
            })
            .catch((error) => {
                console.error(error);
                setIndicatingReferralCheckReceived(false);
                toast.dismiss(toastId);
                // toast.error(`Error: ${error.message}`);
            });
    }

    const agreementModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            borderRadius: '16px',
            margin: '0 auto',
            width: '1150px',
        },
    };

    const goToProfile = (publicProfile): void => {
        const url = `/members/${publicProfile}`;
        const w = window.open(url, '_blank');
        if (w) {
            w.focus();
        }
    };

    // Common Status Message Widget
    const MAX_STATUS_MESG_CHARS = 500;
    const [statusMessageText, setStatusMessageText] = useState<string>('');
    const [statusMessageCharsLeft, setStatusMessageCharsLeft] = useState<number>(0);
    const statusMessageLengthCheck = (e): void => {
        const str = e.target.value;
        setStatusMessageText(str);
        setStatusMessageCharsLeft(MAX_STATUS_MESG_CHARS - str.length);
    };
    const resetStatusMessageBox = (): void => {
        setStatusMessageText('');
        setStatusMessageCharsLeft(MAX_STATUS_MESG_CHARS);
    };

    const [enabledCancelReferralInvitationModal, setEnabledCancelReferralInvitationModal] = useState<boolean>(false);
    const [cancelReferralInvitationMessage, setCancelReferralInvitationMessage] = useState<string>('');
    const [cancellingReferralInvitation, setCancellingReferralInvitation] = useState<boolean>(false);
    const indicateCancelReferralInvitation = (): void => {
        setCancelReferralInvitationMessage('');
        setEnabledCancelReferralInvitationModal(true);
    };
    const cancelThisReferralInvitation = (): void => {
        const message = cancelReferralInvitationMessage;

        const toastId = toast.loading(`Cancelling This Referral ... `);
        setCancellingReferralInvitation(true);

        postReferralsCancelInvitation(referral?.referral.id as number, {notifyAgent: true, message})
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setCancellingReferralInvitation(false);
                    setEnabledCancelReferralInvitationModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setCancellingReferralInvitation(false);
                setEnabledCancelReferralInvitationModal(false);
                toast.dismiss(toastId);
            });
    };
    
    // CANCEL REFERRAL
    const [enabledCancelReferralModal, setEnabledCancelReferralModal] = useState<boolean>(false);
    const [cancelReferralMessage, setCancelReferralMessage] = useState<string>('');
    const [cancellingReferral, setCancellingReferral] = useState<boolean>(false);
    const indicateCancelReferral = (): void => {
        setCancelReferralMessage('');
        setEnabledCancelReferralModal(true);
    };
    const cancelThisReferral = (): void => {
        const message = cancelReferralMessage;

        const toastId = toast.loading(`Cancelling This Referral ... `);
        setCancellingReferral(true);

        postCancelReferral(referral?.referral.id as number, { message })
            .then((result) => {
                console.log(result);
                getReferral(() => {
                    setCancellingReferral(false);
                    setEnabledCancelReferralModal(false);
                    toast.dismiss(toastId);
                });
            })
            .catch((error) => {
                console.error(error);
                setCancellingReferral(false);
                setEnabledCancelReferralModal(false);
                toast.dismiss(toastId);
            });
    };
    const cancelThisReferralModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '15%',
            margin: '0 auto',
            width: '600px',
            height: '400px',
            borderRadius: '16px',
            overflow: 'none',
        },
    };

    
    // REVIEW FOR REFERRED AGENT
    const MAX_REVIEW_CHARS = 400;
    const [reviewContent, setReviewContent] = useState<string>("");
    const [reviewCharsLeft, setReviewCharsLeft] = useState<number>(MAX_REVIEW_CHARS);
    const [reviewRating, setReviewRating] = useState<number>(-1);
    const [starRatingHover, setStarRatingHover] = useState<number>(-1);
    const [mouseOverRatings, setMouseOverRatings] = useState<boolean>(false);
    const reviewLengthCheck = (e): void => {
        const str = e.target.value;
        
        setReviewContent(str);
        setReviewCharsLeft(MAX_REVIEW_CHARS - str.length);
    };
    const [isSendingReview, setIsSendingReview] = useState<boolean>(false);
    const postReview = (): void => {
        
        if (reviewRating === -1) {
            toast.error("Please pick a rating between 1 and 5 stars.")
            return;
        }

        const data = {
            rating: reviewRating,
            feedback: reviewContent
        };

        setIsSendingReview(true);
        postReferralReviewForUser(referral?.referral.agentId as number, referral?.referral.id as number, data).then((result) => {

            console.log(result)
            const reviewId = parseInt(result, 10);
        
            const data = {
                reviewId,
                feedback: reviewContent
            };

            postNotionMRAReviewTask(data)
                .then((result) => {
                    getReferral(() => {
                        setIsSendingReview(false);                        
                    });
                })
                .catch((error) => {
                    console.error(error);

                    setIsSendingReview(false);
                    setReviewContent("");
                    setReviewRating(-1);
                    setReviewCharsLeft(MAX_REVIEW_CHARS);
                });

        }).catch((error) => {
            console.error(error);

            setIsSendingReview(false);
            setReviewContent("");
            setReviewRating(-1);
            setReviewCharsLeft(MAX_REVIEW_CHARS);

        });
    };

    const navigateToDashboard = () => {
        location.href = "/dashboard";
    }
    
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isIPhone, setIsIPhone] = useState<boolean>(false);
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false);
    useEffect(() => {
        setIsFirstLoading(true);
        setIsMobile(isMobileBrowser());
        setIsIPhone(isIPhoneBrowser());

        getReferral(() => {
            setIsFirstLoading(false);
        });

        return () => {
            setReferral(null);
        }
    }, []);

    const [ pendingTasks, setPendingTasks ] = useState<Array<any>>([{init: -1}]);
    const [ privateProfileData, setPrivateProfileData ] = useState<TPrivateProfileView | null>(null);
    const determinePendingTasks = (profile, privateProfileData) => {
        
        const pTasks: any[] = [];
        if (!profile?.officeAddress1 || !profile.officeCity || !profile.officeStateId || !profile.officeZip) {
            pTasks.push({link: '/profile#set-office-address', content: [{type: 'text', content: 'Add your '}, {type: 'link', content: 'Office Address'}, {type: 'text', content: ' to your profile.'}]});
        }
        if (!profile?.officePhoneNumber) {
            pTasks.push({link: '/profile#set-office-phone-number', content: [{type: 'text', content: 'Add your '}, {type: 'link', content: 'Office Phone Number'}, {type: 'text', content: ' to your profile.'}]});
        }
        if (!profile?.cellPhoneNumber) {
            pTasks.push({link: '/profile#add-mobile-phone-number', content: [{type: 'text', content: 'Add your '}, {type: 'link', content: 'Cell Phone Number'}, {type: 'text', content: ' to your profile.'}]});
        }
        if (privateProfileData.servedCities.length === 0 && privateProfileData.servedZipCodes.length === 0) {
            pTasks.push({link: '/profile#add-a-location', content: [{type: 'text', content: 'Add atleast one '}, {type: 'link', content: 'Location'}, {type: 'text', content: ' to your profile.'}]});
        }

        getSkyflowW9Status(pTasks);        
    };
    const getSkyflowW9Status = (pTasks) => {
        const w9TaskToAdd = {link: '/profile#add-broker-w9', content: [{type: 'text', content: "Add your "}, {type: 'link', content: "Broker's W-9"}, {type: 'text', content: ' to your profile.'}]};
        getAllSkyflowW9(authState.id)
            .then((response) => {
                console.log(response);
                if (Array.isArray(response.records) && response.records.length > 0) {
                    const w9List = response.records;
                    if (!w9List[0].fields.w9_file) {                    
                        pTasks.push(w9TaskToAdd);                    
                    }
                } else {
                    pTasks.push(w9TaskToAdd);
                }
                setPendingTasks(pTasks);
            })
            .catch((error) => {
                console.error(error);
                if (error.status === 404) {
                    pTasks.push(w9TaskToAdd);
                }
                setPendingTasks(pTasks);
            });
    };

    // get pending profile tasks once
    useEffect(() => {
        if (!authState.id) {
            return;
        }

        getPrivateProfileById()
            .then((_privateProfileData: TPrivateProfileView) => {
                setPrivateProfileData(_privateProfileData);
                determinePendingTasks(authState.profileUserData, _privateProfileData);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [authState.id]);

    const GET_REFERRAL_INTERVAL_MS = 10000;
    useEffect(() => {
        const interval = setInterval(getReferral, GET_REFERRAL_INTERVAL_MS);
        if (!isValidReferral) {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
            setReferral(null);
        }
    }, []);


    const [numPages, setNumPages] = useState<number>(-1);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};
    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
	const goToNextPage = () => setPageNumber( (pageNumber + 1 >= numPages) ? numPages : pageNumber + 1);

    return (
        <>
            {/* Signature Viewer Modal */}
            <Modal
                isOpen={agreementTemplateModalVisible}
                contentLabel={agreementTemplateModalLabel}
                style={agreementModalStyle}
                ariaHideApp={false}
            >
                <div className="flex flex-1 h-[95%] flex-col gap-[20px] p-[10px]">
                    <div className="flex flex-row gap-[20px]">
                        <h1 className="flex flex-1 items-center justify-start">{agreementTemplateModalLabel}</h1>
                        <div className="items-center align-middle">
                            <button
                                className="items-center justify-center cursor-pointer w-[100px] [border:none] bg-brand-red rounded-md h-10 box-border gap-[10px]"
                                onClick={() => { setAgreementTemplateModalVisible(false) }}
                            >
                                <div className="relative text-md leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center">
                                    Close
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-1 h-[85%]">
                        {isMobile
                            ? <div className="flex flex-col gap-[20px] items-center">
                                <nav className="w-full flex flex-row gap-[20px] ml-[70px] items-center justify-start">
                                    <div className="flex bg-secondary text-white p-[10px] cursor-pointer rounded-lg" onClick={goToPrevPage}>Prev</div>
                                    <div className="flex bg-secondary text-white p-[10px] cursor-pointer rounded-lg" onClick={goToNextPage}>Next</div>
                                    <span className="flex text-3xl-1 font-semibold">Page {pageNumber} of {numPages}</span>
                                    {!isIPhone &&
                                        <a className="flex text-5xl font-semibold cursor-pointer text-secondary no-underline hover:underline" href={agreementTemplateUrl} download={`ReferralAgreement_${referralId}_${referral?.referral.userFirstName}${referral?.referral.userLastName}_${referral?.referral.agentFirstName}${referral?.referral.agentLastName}.pdf`}>Download</a>
                                    }
                                </nav>
                                <Document file={agreementTemplateUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} width={1100} />
                                </Document>
                                <div className="w-full">
                                    <br /><br />
                                </div>
                            </div>
                            : <object type="application/pdf" data={agreementTemplateUrl} style={{ height: '100%', width: '100%' }}>
                                <p>Oops! Your browser doesn't support PDFs!</p>
                                <p><a href={agreementTemplateUrl} download={`ReferralAgreement_${referralId}_${referral?.referral.userFirstName}${referral?.referral.userLastName}_${referral?.referral.agentFirstName}${referral?.referral.agentLastName}.pdf`}>Download Instead</a></p>
                            </object>
                        }
                    </div>
                </div>
            </Modal>
            
            {/* Cancel Referral Modal */}
            <GenericActionModal
                enabledGenericActionModal={enabledCancelReferralModal}
                setEnabledGenericActionModal={setEnabledCancelReferralModal}
                genericActionTitle="Cancel this referral!"
                genericActionMessage={cancelReferralMessage}
                setGenericActionMessage={setCancelReferralMessage}
                genericActionButtonLabel="Cancel This Referral"
                genericActionFunction={cancelThisReferral}
                isPerformingAction={cancellingReferral}
                genericActionMessagePlaceholder="Please provide the reason for cancelling this referral ..."
                styleOverride={cancelThisReferralModalStyle}
            />

            {!isValidReferral
                ? <div className="w-full flex flex-row gap-[40px] justify-center">
                    <div className="w-[60%] mt-[5%] relative rounded-lg flex flex-col p-20 box-border items-start justify-start gap-[20px] text-base bg-light-background-background-1 shadow-xl">
                        <Page404 />
                    </div>
                </div>
                : <WaitContainer shouldWaitIf={isFirstLoading} message="Loading Referral ... " waitSizePx={100}>
                    <div className="relative mt-[50px] w-[1280px] h-full mx-auto flex flex-col gap-[20px] text-left text-primary font-small-semibold">
                        {/* BREADCRUMBS */}
                        <div className="relative flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-center justify-start gap-[7px]">
                                <div className="relative leading-[21px]">{`Referrals  `}</div>
                                <img
                                    className="relative w-2.5 h-2.5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/mra-referral-timeline/iconchevronrightsolid.svg"
                                />
                                <div className="relative leading-[21px] text-tertiary">
                                    {referral?.referral.clientFirstName} {referral?.referral.clientLastName}
                                </div>
                            </div>
                        </div>


                        <div className="w-full flex flex-row gap-[40px]">

                            {/* REFERRAL */}
                            <div className="relative rounded-lg flex flex-1 flex-col p-4 box-border items-start justify-start gap-[20px] text-base bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]">

                                {/* REFERRAL HEADING */}
                                <div className="self-stretch flex flex-row pt-0 px-0 pb-4 items-start justify-start text-lg">

                                    {/* Referral Info */}
                                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                        <div className="flex flex-row items-start justify-start gap-[8px]">
                                            <img
                                                className="relative w-6 h-6 overflow-hidden shrink-0"
                                                alt=""
                                                src="/img/mra-dashboard/outbound.svg"
                                            />
                                            <div className="relative leading-[140%] font-medium">
                                                Outbound {ReferralTypeStr[ReferralType[referral?.referral.type as ReferralType]]} Referral
                                            </div>
                                        </div>
                                        <div className="self-stretch relative text-base leading-[24px] text-tertiary">
                                            Sent on {getLocaleDateTimeWithTzStrFullDay(referral?.referral.addedDate as string)}
                                        </div>
                                        {/* <div className="self-stretch relative text-base leading-[24px] text-tertiary">
                                            Status: {ReferralStatusStr[ReferralStatus[referral?.referral.status as ReferralStatus]]}
                                        </div> */}
                                        {referral?.referral.agreementFileName === "DOCUSIGN"
                                            && <div
                                                    className="flex flex-row relative text-base leading-[24px] cursor-pointer text-link-blue hover:underline items-center gap-[8px]"
                                                    onClick={viewAgreement}
                                                >
                                                    <span>Signed Referral Agreement</span>
                                                    <img
                                                        className="relative w-[16px] h-[16px] overflow-hidden shrink-0 rounded-xl"
                                                        alt=""
                                                        src="/img/mra-referral-timeline/icon-open.svg"
                                                    />
                                            </div>
                                        }
                                    </div>

                                    {/* Referred Agent Info */}
                                    <div className="flex flex-col gap-[10px]">
                                        <div className="relative leading-[140%] font-medium">
                                            <b>Referred Agent</b>
                                        </div>
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[18px] text-tertiary">
                                            <div className="flex-1 flex flex-col items-start justify-start gap-[20px]">

                                                <div className="self-stretch rounded-sm flex flex-row items-center justify-start gap-[18px] text-tertiary">
                                                    
                                                    {/* Referred Agent Photo */}
                                                    <img
                                                        className="relative w-[68px] h-[68px] shrink-0 rounded-lg"
                                                        alt=""
                                                        src={referral?.referral.agentPrimaryImage ? getAvatar(referral?.referral.agentPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = DEFAULT_AVATAR_PATH;
                                                        }}
                                                    />

                                                    <div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
                                                    
                                                        {/* Referred Agent Profile Link */}
                                                        <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
                                                            <div className="self-stretch flex flex-row items-center justify-start gap-[6px] text-base text-brand-primary">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    {referral?.referral.agentFirstName} {referral?.referral.agentLastName}
                                                                </div>
                                                            </div>
                                                            <img
                                                                className="relative w-[20px] h-[20px] overflow-hidden shrink-0 rounded-xl cursor-pointer"
                                                                alt=""
                                                                title={`Visit ${referral?.referral.agentFirstName}'s Profile`}
                                                                src="/img/mra-referral-timeline/icon-open.svg"
                                                                onClick={() => goToProfile(referral?.referral.agentPublicProfile)}
                                                            />
                                                        </div>

                                                        {/* Referred Agent Phone */}
                                                        <div className="flex-1 flex flex-col items-start justify-start gap-[6px] text-sm text-secondary mr-[16px]">
                                                            {!!referral?.referral.agentCellPhoneNumber
                                                                && <span>
                                                                    Cell: <a className="text-link-blue no-underline hover:underline" href={`tel:${referral?.referral.agentCellPhoneNumber}`}>{referral?.referral.agentCellPhoneNumber}</a>
                                                                </span>
                                                            }
                                                            {!!referral?.referral.agentOfficePhoneNumber
                                                                && <span>
                                                                    Office: <a className="text-link-blue no-underline hover:underline" href={`tel:${referral?.referral.agentOfficePhoneNumber}`}>{referral?.referral.agentOfficePhoneNumber}</a>
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="self-stretch rounded-sm flex flex-col items-start justify-start gap-[18px]" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* CURRENT STATE AND ACTIVITY */}
                                <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg">

                                    {/* DECLINED/CANCELED/RETURNED */}
                                    {(
                                        referralCancelled
                                        || referralReturned || referralReturnedNotBuying || referralReturnedHiredAnother
                                        || referralDeclined
                                    ) &&
                                        <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg ">

                                            {/* DECLINED/CANCELED/RETURNED HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start rounded-lg ">
                                                <div className="flex-1 bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start rounded-lg ">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Declined/Canceled/Returned
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(currReferralAction.date)}
                                                    </b>
                                                </div>
                                            </div>

                                            <div className="flex flex-row gap-[16px] p-[20px]">
                                                
                                                {/* Requested agent has declined the referral */}
                                                {referralDeclined &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        {/* <b>{getLocaleDateTimeWithTzStr(referral?.referral.actions?.find((a: TReferralAction) => [ReferralAction.referralDeclined].includes(a.type))?.date as string)}</b> */}
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>Unfortunately, {referral?.referral.agentFirstName} {referral?.referral.agentLastName} has declined your referral!</span>
                                                        <div className="flex flex-col gap-[4px] ml-[20px]">
                                                            <b>Reason to Decline</b>
                                                            <div className="whitespace-pre-line leading-snug">
                                                                {referral?.referral.actions?.find((a: TReferralAction) => a.type === ReferralAction.referralDeclined)?.message ?? "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Referring Agent has canceled the referral */}
                                                {referralCancelled &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        {/* <b>{getLocaleDateTimeWithTzStr(referral?.referral.actions?.find((a: TReferralAction) => [ReferralAction.referralDeclined].includes(a.type))?.date as string)}</b> */}
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>You have canceled this referral!</span>
                                                        <div className="flex flex-col gap-[4px] ml-[20px]">
                                                            <b>Reason: </b>
                                                            <div className="whitespace-pre-line leading-snug">
                                                                {currReferralAction.message ?? "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Referred Agent has returned this referral */}
                                                {(referralReturned || referralReturnedNotBuying || referralReturnedHiredAnother)
                                                    && <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">
                                                            {ReferralActionStr[ReferralAction[currReferralAction?.type]]}
                                                            {referralReturnedNotBuying && <span>: Client Does Not Wish To Buy</span>}
                                                            {referralReturnedHiredAnother && <span>: Client Hired Another Agent</span>}
                                                        </b>
                                                        <span>{referral?.referral.agentFirstName} has returned this referral!</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/* OPPORTUNITY */}
                                    {(
                                        awaitingReferralAcceptance /*|| awaitingAgreement*/ || agreementDeclined
                                        || awaitingAgreementSignature || awaitingAgreementSignatureAcceptance
                                    ) &&
                                        <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg ">

                                            {/* OPPORTUNITY HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start rounded-lg ">
                                                <div className="flex-1 bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start rounded-lg ">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Opportunity
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.addedDate as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            <div className="flex flex-row gap-[16px] p-[20px]">

                                                {/* Waiting for requested agent to accept referral */}
                                                {awaitingReferralAcceptance && 
                                                    <> {agentCreatedInboundReferral
                                                            ? <div className="flex flex-col gap-[16px] justify-start items-start">
                                                                    <b className="capitalize">New Referral Opportunity: {ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                                    <span className="">{referral?.referral.agentFirstName} {referral?.referral.agentLastName} created this referral on your behalf.</span>
                                                                    {(!!privateProfileData && pendingTasks && pendingTasks.length > 0)
                                                                        ? <>
                                                                            <span className="font-semibold text-xl">Attention! Complete your pending tasks listed on the <a onClick={navigateToDashboard} className="cursor-pointer text-link-blue no-underline hover:underline">Dashboard</a>!</span>
                                                                        </>
                                                                        : <>
                                                                            <span className=""><b>Next:</b> Send Agreement</span>
                                                                            <WaitContainer waitSizePx={28} shouldWaitIf={generatingAgreement}>
                                                                                <button
                                                                                    className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                                    onClick={createSignatureRequestAndUpdateReferral}
                                                                                >
                                                                                    <div className="uppercase w-[458px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                                        Next: Generate and Send Referral Agreement
                                                                                    </div>
                                                                                </button>
                                                                                
                                                                            </WaitContainer>
                                                                        </>    

                                                                    }

                                                                    
                                                                    
                                                                    {/* Options */}
                                                                    <div className="self-stretch flex flex-col items-start justify-start gap-[13px]">
                                                                        <div className="tracking-[0.1px] leading-[24px] font-semibold">
                                                                            Options
                                                                        </div>

                                                                        <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                            
                                                                            {/* Cancel Invitation */}
                                                                            <button
                                                                                className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                                autoFocus={false}
                                                                                onClick={indicateCancelReferralInvitation}
                                                                            >
                                                                                <div className="relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                                    Decline Referral with {referral?.referral.agentFirstName}
                                                                                </div>
                                                                            </button>
                                                                            <GenericActionModal
                                                                                enabledGenericActionModal={enabledCancelReferralInvitationModal}
                                                                                setEnabledGenericActionModal={setEnabledCancelReferralInvitationModal}
                                                                                genericActionTitle="Cancel this referral invitation!"
                                                                                genericActionMessage={cancelReferralInvitationMessage}
                                                                                setGenericActionMessage={setCancelReferralInvitationMessage}
                                                                                genericActionButtonLabel="Cancel This Referral Invitation"
                                                                                genericActionFunction={cancelThisReferralInvitation}
                                                                                isPerformingAction={cancellingReferralInvitation}
                                                                                genericActionMessagePlaceholder="Please provide the reason for cancelling this referral ..."
                                                                                styleOverride={cancelThisReferralModalStyle}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : <div className="flex flex-col gap-[16px] justify-start items-start">
                                                                    <b className="capitalize">New Referral Opportunity: {ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                                    <span className=""><b>Next:</b> Awaiting their acceptance</span>
                                                                    
                                                                    {/* Options */}
                                                                    <div className="self-stretch flex flex-col items-start justify-center">
                                                                        <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                            <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                                Options
                                                                            </div>

                                                                            <div className="self-stretch flex flex-row items-start justify-center gap-[13px]">
                                                                                
                                                                                {/* Cancel Invitation */}
                                                                                <button
                                                                                    className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                                    autoFocus={false}
                                                                                    onClick={indicateCancelReferralInvitation}
                                                                                >
                                                                                    <div className="relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                                        Cancel Invitation to {referral?.referral.agentFirstName}
                                                                                    </div>
                                                                                </button>
                                                                                <GenericActionModal
                                                                                    enabledGenericActionModal={enabledCancelReferralInvitationModal}
                                                                                    setEnabledGenericActionModal={setEnabledCancelReferralInvitationModal}
                                                                                    genericActionTitle="Cancel this referral invitation!"
                                                                                    genericActionMessage={cancelReferralInvitationMessage}
                                                                                    setGenericActionMessage={setCancelReferralInvitationMessage}
                                                                                    genericActionButtonLabel="Cancel This Referral Invitation"
                                                                                    genericActionFunction={cancelThisReferralInvitation}
                                                                                    isPerformingAction={cancellingReferralInvitation}
                                                                                    genericActionMessagePlaceholder="Please provide the reason for cancelling this referral ..."
                                                                                    styleOverride={cancelThisReferralModalStyle}
                                                                                />

                                                                                {/* Resend Invitation */}
                                                                                <WaitContainer shouldWaitIf={resendingInvitation}>
                                                                                    <button
                                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                                        autoFocus={false}
                                                                                        onClick={() => {
                                                                                            confirm({ description: "Are you sure you wish to resend this invitation?" })
                                                                                                .then(resendInvitation)
                                                                                                .catch(() => {})
                                                                                        }}
                                                                                    >
                                                                                        <div className="relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                                            Resend Invitation to {referral?.referral.agentFirstName}
                                                                                        </div>
                                                                                    </button>
                                                                                </WaitContainer>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                                }

                                                {/* Agreement is Sent: Waiting for Signature */}
                                                {awaitingAgreementSignature &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span className=""><b>Next:</b> Waiting for {referral?.referral.agentFirstName} to sign your Agreement.</span>
                                                        
                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    
                                                                    {/* Cancel Invitation */}
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-start justify-start gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferralInvitation}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Cancel Invitation to {referral?.referral.agentFirstName}
                                                                        </div>
                                                                    </button>
                                                                    <GenericActionModal
                                                                        enabledGenericActionModal={enabledCancelReferralInvitationModal}
                                                                        setEnabledGenericActionModal={setEnabledCancelReferralInvitationModal}
                                                                        genericActionTitle="Cancel this referral invitation!"
                                                                        genericActionMessage={cancelReferralInvitationMessage}
                                                                        setGenericActionMessage={setCancelReferralInvitationMessage}
                                                                        genericActionButtonLabel="Cancel This Referral Invitation"
                                                                        genericActionFunction={cancelThisReferralInvitation}
                                                                        isPerformingAction={cancellingReferralInvitation}
                                                                        genericActionMessagePlaceholder="Please provide the reason for cancelling this referral ..."
                                                                        styleOverride={cancelThisReferralModalStyle}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Requested agent has declined agreement */}
                                                {agreementDeclined &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>{referral?.referral.agentFirstName} {referral?.referral.agentLastName} has declined your referral!</span>
                                                    </div>
                                                }

                                                {/* Signature Received: Requested Agent is waiting for confirmation */}
                                                {awaitingAgreementSignatureAcceptance &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <div className="flex flex-col gap-[16px] justify-start items-start">
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>Congratulations! {referral?.referral.agentFirstName} has signed your Agreement!</span>
                                                            <span className=""><b>Next:</b> View the signature on the agreement and Accept the Signature</span>
                                                        </div>

                                                        
                                                        <div className="flex flex-row gap-[20px]">
                                                            {!isViewedSignature &&
                                                                <button
                                                                    className="cursor-pointer [border:none] py-2 px-4 bg-darkslategray-100 text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                    onClick={viewSignedAgreement}
                                                                >
                                                                    <div className="uppercase w-[358px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                        Next: View Signed Agreement
                                                                    </div>
                                                                    {/* <img
                                                                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/iconsearch.svg"
                                                                    /> */}
                                                                </button>
                                                            }
                                                            {isViewedSignature &&
                                                                <WaitContainer waitSizePx={28} shouldWaitIf={confirmingSignedAgreement}>
                                                                    <button
                                                                        className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                        onClick={confirmSignedAgreement}
                                                                    >
                                                                        <div className="uppercase w-[358px] relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                            Next: Accept Signature
                                                                        </div>
                                                                        {/* <img
                                                                            className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                            alt=""
                                                                            src="/img/mra-referral-timeline/iconcheckthick.svg"
                                                                        /> */}
                                                                    </button>
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        onClick={viewSignedAgreement}
                                                                    >
                                                                        <div className="uppercase relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            View Signed Agreement
                                                                        </div>
                                                                        {/* <img
                                                                            className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                            alt=""
                                                                            src="/img/mra-referral-timeline/iconsearch.svg"
                                                                        /> */}
                                                                    </button>

                                                                    {false
                                                                        && <button
                                                                            className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                            onClick={() => {}}
                                                                        >
                                                                            <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                                Request Changes In Agreement
                                                                            </div>
                                                                            {/* <img
                                                                                className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                                                                                alt=""
                                                                                src="/img/mra-referral-timeline/iconcheckthick.svg"
                                                                            /> */}
                                                                            <ToDoIcon />
                                                                        </button>
                                                                    }
                                                                </WaitContainer>
                                                            }
                                                        </div>
                                                        
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    }


                                    {/* ACTIVE */}
                                    {(agreementSignatureAccepted || clientContacted || clientAppointmentSet || clientAgreementSigned || showingHouses)
                                        && <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke">

                                            {/* ACTIVE HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Referral Active
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions?.find((a: TReferralAction) => [ReferralAction.signedAgreementConfirmed].includes(a.type))?.date as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* ACTIVE STEPS */}
                                            <div className="flex flex-row gap-[16px] p-[20px]">
                                                {/* <img
                                                    className="relative w-[105px] h-[105px] overflow-hidden shrink-0 rounded-xl"
                                                    alt=""
                                                    src={referral?.referral.agentPrimaryImage ? getAvatar(referral?.referral.agentPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = DEFAULT_AVATAR_PATH;
                                                    }}
                                                /> */}


                                                {/* Waiting for requested agent to contact client */}
                                                {agreementSignatureAccepted &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>Congratulations! You have confirmed {referral?.referral.agentFirstName}&apos;s Signature!</span>
                                                        <span>The referral is now active and the client information is now accessible to {referral?.referral.agentFirstName}.</span>
                                                        <span className=""><b>Next:</b> Waiting for the {referral?.referral.agentFirstName} to contact the client.</span>
                                                        
                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Request An Update */}
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateRequestUpdate}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Request An Update
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button> */}

                                                                    {/* Cancel Referral */}
                                                                    <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Cancel Referral
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Waiting for agent to set an appointment with client */}
                                                {clientContacted &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>{referral?.referral.agentFirstName} has contacted the client.</span>
                                                        <span className=""><b>Next:</b> Waiting for {referral?.referral.agentFirstName} to set an appointment with the client.</span>
                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Request An Update */}
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateRequestUpdate}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Request An Update
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button> */}

                                                                    {/* Cancel Referral */}
                                                                    <button
                                                                        className="cursor-pointer py-2 bg-white px-4 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Cancel Referral
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                {/* Waiting for client to sign agreement with agent */}
                                                {clientAppointmentSet &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <>
                                                            <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                            <span>{referral?.referral.agentFirstName} has scheduled an appointment with the client.</span>
                                                            <span><b>Appointment On</b>: {getLocaleDateWithTzStrShort(currReferralAction.actionDate as string)}</span>
                                                            <span className=""><b>Next:</b> Waiting for {referral?.referral.agentFirstName} to sign a client agreement.</span>
                                                        </>
                                                        {/* {referral?.referral.type === ReferralType.buy
                                                            ? ( 
                                                                <>
                                                                    <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                                    <span>{referral?.referral.agentFirstName} has scheduled an appointment with the client.</span>
                                                                    <span><b>Appointment On</b>: {getLocaleDateTimeWithTzStrShort(currReferralAction.actionDate as string)}</span>
                                                                    <span className=""><b>Next:</b> Waiting for {referral?.referral.agentFirstName} to sign a client agreement.</span>
                                                                </>     
                                                             )
                                                            : (
                                                                <>
                                                                    <b className="capitalize">Open House</b>
                                                                    <span>The property is being viewed by prospective buyers.</span>
                                                                    <span className=""><b>Next:</b> Waiting for the client to sign a client agreement.</span>
                                                                </>
                                                            ) 
                                                        } */}

                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Request an Update */}
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateRequestUpdate}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Request An Update
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button> */}

                                                                    {/* Cancel Referral */}
                                                                    <button
                                                                        className="cursor-pointer py-2 bg-white px-4 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Cancel Referral
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                {/* Waiting for requested agent tour properties client */}
                                                {clientAgreementSigned &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>{referral?.referral.agentFirstName} has signed an agreement with the client.</span>
                                                        {referral?.referral.type === ReferralType.buy
                                                            ? <span className=""><b>Next:</b> Waiting for {referral?.referral.agentFirstName} to tour properties with the client.</span>
                                                            : <span className=""><b>Next:</b> Waiting for {referral?.referral.agentFirstName} to show the client's property to prospective buyers.</span>
                                                        }

                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Request An Update */}
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateRequestUpdate}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Request An Update
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button> */}

                                                                    {/* Cancel Referral */}
                                                                    <button
                                                                        className="cursor-pointer py-2 bg-white px-4 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Cancel Referral
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                {/* Waiting for client to go under contract */}
                                                {showingHouses &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        {referral?.referral.type === ReferralType.buy
                                                            ? (
                                                                <>
                                                                    <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                                    <span>{referral?.referral.agentFirstName} is now touring properties with the client.</span>
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    <b className="capitalize">Open House</b>
                                                                    <span>{referral?.referral.agentFirstName} is now showing the client&apos;s property to potential buyers.</span>
                                                                </>
                                                            )
                                                        }
                                                        
                                                        <span className=""><b>Next:</b> Waiting to go Under Contract.</span>
                                                        
                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Request An Update */}
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateRequestUpdate}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Request An Update
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button> */}

                                                                    {/* Cancel Referral */}
                                                                    <button
                                                                        className="cursor-pointer py-2 bg-white px-4 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Cancel Referral
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }


                                    {/* UNDER CONTRACT */}
                                    {(homeUnderContract || referralCheckIssued) &&
                                        <div className="self-stretch flex flex-col items-start justify-start bg-whitesmoke">

                                            {/* UNDER CONTRACT HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Under Contract
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions?.find((a: TReferralAction) => [ReferralAction.underContractClosingDate].includes(a.type))?.date as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* UNDER CONTRACT STEPS */}
                                            <div className="flex flex-row gap-[16px] p-[20px] justify-center">
                                                
                                                {/* Under Contract: Waiting for referral fee to be included in the disbursement instructions! */}
                                                {homeUnderContract &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>The client is now Under Contract!</span>
                                                        <span>
                                                                <b>Contract Closure:</b> {getLocaleDateWithTzStrFullDay(
                                                                                            referral?.referral.actions?.find((a: TReferralAction) => a.type === ReferralAction.underContractClosingDate)?.actionDate as string
                                                                                        ) ?? ""}
                                                        </span>
                                                        {/* <span className=""><b>Next:</b> Waiting for the contract to close!</span> */}
                                                        <span className=""><b>Next:</b> Waiting for {referral?.referral.agentFirstName} to include the referral fee in the disbursement instructions.</span>
                                                        
                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Request An Update */}
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateRequestUpdate}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Request An Update
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button> */}

                                                                    {/* Cancel Referral */}
                                                                    <button
                                                                        className="cursor-pointer py-2 bg-white px-4 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Cancel Referral
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Under Contract: Referral Fee included in Disbursement Instructions */}
                                                {referralCheckIssued &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>The client is Under Contract!</span>
                                                        <span>Also, {referral?.referral.agentFirstName} has indicated that the referral fee was included in the disbursement instructions!</span>
                                                        <span className=""><b>Next:</b> Waiting for the contract to close.</span>

                                                        {/* Options */}
                                                        <div className="self-stretch flex flex-col items-start justify-center">
                                                            <div className="self-stretch flex flex-col items-start justify-center gap-[13px]">
                                                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                                                    Options
                                                                </div>

                                                                <div className="self-stretch flex flex-row items-start justify-start gap-[13px]">
                                                                    {/* Request An Update */}
                                                                    {/* <button
                                                                        className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateRequestUpdate}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-colors-gs-brand-primary text-center">
                                                                            Request An Update
                                                                        </div>
                                                                        <ToDoIcon />
                                                                    </button> */}

                                                                    {/* Cancel Referral */}
                                                                    <button
                                                                        className="cursor-pointer py-2 bg-white px-4 rounded-md box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                                                                        autoFocus={false}
                                                                        onClick={indicateCancelReferral}
                                                                    >
                                                                        <div className="relative text-base tracking-[0.1px] leading-[21px] font-semibold font-small-medium text-center">
                                                                            Cancel Referral
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/* CONTRACT CLOSED */}
                                    {(contractClosed) &&
                                        <div className="self-stretch flex flex-col items-start justify-start bg-whitesmoke">

                                            {/* CONTRACT CLOSED */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start">
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px]">
                                                        Contract Closed
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions?.find((a: TReferralAction) => [ReferralAction.underContractClosingDate].includes(a.type))?.date as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            {/* CONTRACT CLOSED STEPS */}
                                            <div className="flex flex-row gap-[16px] p-[20px] justify-center">
                                                {/* Contract Closed. Waiting for Referral Check! */}
                                                {contractClosed &&
                                                    <div className="flex flex-col gap-[16px] justify-start items-start">
                                                        <b className="capitalize">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>
                                                        <span>Congratulations! The client contract is closed!</span>
                                                        <span>
                                                                <b>Contract Closed On:</b> {getLocaleDateWithTzStrFullDay(
                                                                                            referral?.referral.actions?.find((a: TReferralAction) => a.type === ReferralAction.contractClosed)?.actionDate as string
                                                                                        ) ?? ""}
                                                        </span>                                                        
                                                        <div className="flex flex-col gap-[10px]">
                                                            <span className=""><b>Next:</b> Notify {referral?.referral.agentFirstName} when you have received the referral fee.</span>
                                                            <WaitContainer waitSizePx={28} shouldWaitIf={indicatingReferralCheckReceived}>
                                                                <button
                                                                    className="cursor-pointer [border:none] py-2 px-4 bg-secondary text-white rounded-md h-10 flex flex-row box-border items-center justify-center gap-[8px]"
                                                                    onClick={indicateReferralCheckReceived}
                                                                >
                                                                    <div className="uppercase relative text-md leading-[21px] font-semibold font-small-medium text-light-background-background-1 text-center">
                                                                        Next: I Have Received The Referral Check
                                                                    </div>
                                                                </button>
                                                            </WaitContainer>
                                                            <br />
                                                            
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }


                                    {/* CLOSED */}
                                    {referralCheckReceived &&
                                        <div className="self-stretch flex flex-col items-start justify-start bg-whitesmoke">
                                            
                                            {/* CLOSED HEADER */}
                                            <div className="self-stretch flex flex-row items-start justify-start">
                                                <div className="flex-1 rounded bg-surface-secondary flex flex-row py-4 px-[13px] items-center justify-start gap-[10px]">
                                                    <img
                                                        className=""
                                                        src="/img/mra-referral-timeline/iconstars.svg"
                                                    />
                                                    <b className="flex-1 relative tracking-[0.1px] leading-[24px] text-lg">
                                                        Referral Complete!
                                                    </b>
                                                    <b className="self-stretch relative tracking-[0.1px] leading-[24px] inline-block text-right w-96 shrink-0">
                                                        {getLocaleDateWithTzStrFullDay(referral?.referral.actions?.find((a: TReferralAction) => [ReferralAction.referralCheckReceived].includes(a.type))?.date as string)}
                                                    </b>
                                                </div>
                                            </div>

                                            <div className="flex flex-row gap-[16px] p-[20px] justify-center">
                                                <img
                                                    className="relative w-[105px] h-[105px] overflow-hidden shrink-0 rounded-xl"
                                                    alt=""
                                                    src={referral?.referral.agentPrimaryImage ? getAvatar(referral?.referral.agentPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = DEFAULT_AVATAR_PATH;
                                                    }}
                                                />

                                                {/* Check is received. FINISHED! */}
                                                <div className="flex flex-col gap-[16px] justify-start items-start">
                                                    <b className="capitalize text-lg">{ReferralActionStr[ReferralAction[currReferralAction?.type]]}</b>                                            
                                                    <span className="text-lg">Congratulations on getting paid!</span>
                                                    <span className="text-lg">This Referral is now Complete!</span>                                           

                                                    {/* Review */}
                                                    <div className="self-stretch flex flex-col items-start justify-start rounded-lg text-lg gap-[10px]">
                                                        <>
                                                            {/* {reviewReceived
                                                                && <div className="self-stretch flex flex-col p-5 gap-[5px] border border-solid border-gray-300 rounded-lg bg-slate-100">
                                                                <div className="text-3xl-1">Review You Received from {referral?.referral.agentFirstName}</div>
                                                                <div className="flex flex-row gap-[10px]">
                                                                    <b>Rating: </b>
                                                                    <div className="flex flex-row gap-0 items-center">
                                                                        {[...Array(Math.trunc(reviewReceived.rating as number) + 1).keys()].slice(1).map((i) => 
                                                                            <img key={`overall-rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                        )}
                                                                    </div>
                                                                    {reviewReceived.rating as number}
                                                                </div>
                                                                <div className="flex flex-row gap-[10px]"><b>Feedback: </b> {reviewReceived.feedback}</div>
                                                            </div>
                                                            } */}
                                                        </>
                                                        {reviewISent
                                                            ? <div className="self-stretch flex flex-col p-3 gap-[5px] border border-solid border-gray-300 rounded-lg bg-slate-100">
                                                                <div className="text-3xl-1">Review You Posted for {referral?.referral.agentFirstName} {reviewISent.status !== 1 && <i>(Pending Approval)</i>}</div>
                                                                <div className="flex flex-row gap-[10px]">
                                                                    <b>Rating: </b>
                                                                    <div className="flex flex-row gap-0 items-center">
                                                                        {[...Array(Math.trunc(reviewISent.rating as number) + 1).keys()].slice(1).map((i) => 
                                                                            <img key={`overall-rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                        )}
                                                                    </div>
                                                                    {reviewISent.rating as number}
                                                                </div>
                                                                <div className="flex flex-row gap-[10px]"><b>Feedback: </b> {reviewISent.feedback}</div>
                                                            </div>
                                                            : <div className="self-stretch flex flex-col rounded-md gap-[12px]">
                                                                <div className="text-lg font-bold">
                                                                    Next: Write a review for {referral?.referral.agentFirstName}
                                                                </div>
                                                                <WaitContainer shouldWaitIf={isSendingReview}>
                                                                    <div className="flex flex-col gap-[10px]">
                                                                        <div className="flex flex-row gap-[10px] items-center justify-start">
                                                                            <span>Pick a rating:</span>
                                                                            <div
                                                                                className="flex flex-row items-center justify-start gap-[2px]"
                                                                                onMouseEnter={() => {
                                                                                    setMouseOverRatings(true);
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    setStarRatingHover(-1);
                                                                                    setMouseOverRatings(false);
                                                                                }}
                                                                            >
                                                                                {Array.from({length: 5}, (_, i) => i + 1).map((i) => 
                                                                                    <div
                                                                                        key={i}
                                                                                        className={`flex items-center cursor-pointer bg-cover bg-center w-[26px] h-[26px] ${((i <= starRatingHover && mouseOverRatings) || (i <= reviewRating && !mouseOverRatings) ) ? "bg-[url('/img/mra-referral-timeline/iconstar_full.svg')]" : "bg-[url('/img/mra-referral-timeline/iconstar_empty.svg')]"} `}
                                                                                        onClick={() => setReviewRating(i)}
                                                                                        onMouseEnter={() => setStarRatingHover(i)}
                                                                                        onMouseLeave={() => setStarRatingHover(i)}
                                                                                    ></div>
                                                                                )}                                                            
                                                                            </div>
                                                                            <div>{mouseOverRatings ? starRatingHover : (reviewRating > -1 ? <b>{reviewRating}</b> : '')}</div>
                                                                        </div>
                                                                        <div className="flex flex-col items-start justify-center gap-[16px] rounded-md bg-light-background-background-1 p-4 text-tertiary border-[1px] border-solid border-gray-400">
                                                                            <textarea
                                                                                className="w-[600px] flex resize-none overflow-y-auto text-lg font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border-[none] outline-0"
                                                                                rows={6}
                                                                                // cols={77}
                                                                                maxLength={MAX_REVIEW_CHARS}
                                                                                onChange={reviewLengthCheck}
                                                                                placeholder="Type your review here ... "
                                                                                value={reviewContent}
                                                                            />
                                                                            <div className="w-full flex flex-row">
                                                                                <div className="flex flex-1 items-center justify-start">
                                                                                    <div className="flex flex-1 items-start justify-start">
                                                                                        {reviewCharsLeft} characters left
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="cursor-pointer flex items-center justify-end px-5 py-2 rounded-full border border-solid border-gray-300 text-md bg-secondary text-white"
                                                                                    onClick={postReview}
                                                                                >
                                                                                    Post Review
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </WaitContainer>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* MESSAGES */}
                                <div className="self-stretch flex flex-col items-start justify-center rounded-lg">
                                    <WaitContainer shouldWaitIf={isSendingMsg}>
                                        <div className="self-stretch flex flex-col items-start justify-center gap-[16px] rounded-md bg-light-background-background-1 p-4 text-tertiary border-[1px] border-solid border-gray-400">
                                            <textarea
                                                className="w-[770px] flex flex-1 resize-none overflow-y-auto text-lg font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border-[none] outline-0"
                                                rows={5}
                                                // cols={77}
                                                maxLength={MAX_MSG_CHARS}
                                                onChange={msgLengthCheck}
                                                placeholder="Type a message here ... "
                                                value={msgContent}
                                            />
                                            <div className="w-full flex flex-row">
                                                <div className="flex flex-1 items-center justify-start">
                                                    <div className="flex flex-1 items-start justify-start">
                                                        {msgCharsLeft} characters left
                                                    </div>
                                                </div>
                                                {/* <div className="flex flex-1 items-center justify-end">
                                                    <img
                                                        className="cursor-pointer"
                                                        alt="Send Message"
                                                        src="/img/mra-referral-timeline/SendMessageButton.svg"
                                                        onClick={sendMessage}
                                                    />
                                                </div> */}
                                                <div
                                                    className="cursor-pointer flex items-center justify-end px-5 py-2 rounded-full border border-solid border-gray-300 text-md text-white bg-secondary"
                                                    onClick={sendMessage}
                                                >
                                                    Send Message
                                                </div>
                                            </div>
                                        </div>
                                    </WaitContainer>
                                </div>


                                {/* THE ACTIVITY TIMELINE */}
                                <div className="self-stretch flex flex-col items-start justify-center bg-whitesmoke rounded-lg p-4">

                                    {/* Activity Timeline Header */}
                                    <div className="self-stretch flex flex-row items-start justify-start">
                                        <div className="flex-1 flex flex-row pb-[12px] items-center justify-start rounded-lg">
                                            <b className="flex-1 relative tracking-[0.1px] leading-[24px] text-lg">
                                                Activity Timeline
                                            </b>
                                        </div>
                                    </div>

                                    {/* The Timeline */}
                                    <div className="self-stretch flex flex-col items-start justify-start">

                                        <div className="w-full grid grid-cols-10 striped gap-[24px]">
                                            {referral?.referral.actions?.map((a) => a)
                                                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                                                .map((a) => (
                                                    <Fragment key={a.date}>

                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.referralCheckReceived &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2 border-l border-solid border-black" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-award.svg"
                                                                    />
                                                                    Completed
                                                                </div>
                                                            </div>
                                                        }



                                                        {/* Timestamp */}
                                                        <div className="flex flex-col col-span-2 justify-start pt-[5px]">
                                                            {getLocaleDateTimeWithTzStrShortForTimeline(a.date).split("\n").map((line, i) => <div key={i}>{line}</div>)}
                                                        </div>

                                                        {/* The Stage */}
                                                        <div className="items-top justify-start col-span-7 flex flex-col gap-[6px] pb-[8px]">
                                                            <div className="flex flex-row items-center justify-start font-bold gap-[20px]">
                                                                {a.changedByFirstName === referral.referral.agentFirstName
                                                                    ? <img
                                                                        className="relative w-[50px] h-[50px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src={referral?.referral.agentPrimaryImage ? getAvatar(referral?.referral.agentPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = DEFAULT_AVATAR_PATH;
                                                                        }}
                                                                    />
                                                                    : <img
                                                                        className="relative w-[50px] h-[50px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src={userPrimaryImage ? getAvatar(userPrimaryImage, 200) : DEFAULT_AVATAR_PATH}
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = DEFAULT_AVATAR_PATH;
                                                                        }}
                                                                    />
                                                                }

                                                                <div>
                                                                    {referral.referral.type === ReferralType.buy
                                                                        ? <>
                                                                            {(referral.referral.agentFirstName === a.changedByFirstName)
                                                                                ? <div>{ReferralActionStr[ReferralAction[a.type]]} by {a.changedByFirstName}</div>
                                                                                : <div>{ReferralActionStr[ReferralAction[a.type]]} by you</div>
                                                                            }
                                                                        </>
                                                                        : <div>
                                                                            {a.type === ReferralAction.showingHouses
                                                                                ? "Property is on the market"
                                                                                : <>
                                                                                    {(referral.referral.agentFirstName === a.changedByFirstName)
                                                                                        ? <div>{ReferralActionStr[ReferralAction[a.type]]} by {a.changedByFirstName}</div>
                                                                                        : <div>{ReferralActionStr[ReferralAction[a.type]]} by you</div>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                            {/* Stage Note/Message/Details */}
                                                            
                                                            <div className={`flex flex-col w-full pl-[70px] rounded-md leading-snug pb-2 pt-0 `}>

                                                                {/* <div className={`flex flex-col w-[95%] p-[16px] gap-[10px] ${(referral.referral.agentFirstName === a.changedByFirstName) ? 'bg-honeydew':'bg-white'} border border-solid border-gray-300 rounded-lg`}> */}
                                                                {(!!a.message || !!a.actionDate)
                                                                    &&
                                                                    <div className={`flex flex-col w-[95%] p-[16px] gap-[10px] bg-white border border-solid border-gray-300 rounded-lg`}>
                                                                        
                                                                        {a.message &&
                                                                            <span>{a.message.replace(/\\n/g, '\n').split('\n').map((line, idx) => <span key={idx}>{line}<br /></span>)}</span>
                                                                        }

                                                                        {/* Include Terms for Under Contract and Contract Closed */}
                                                                        {[
                                                                            ReferralAction.underContractClosingDate,
                                                                            ReferralAction.termsChanged,
                                                                            ReferralAction.contractClosed,
                                                                        ].includes(a.type)
                                                                            && <div className="flex flex-col p-5 text-md border border-dashed border-gray-200 bg-gray-50 rounded-md gap-[6px]">
                                                                                <div><b>Closing Date: </b> {getLocaleDateWithTzStrFullDay(a.actionDate as string)}</div>
                                                                                <div className="flex flex-row gap-[5px]">
                                                                                    <b>{a.type === ReferralAction.contractClosed ? 'Closing' : 'Under Contract'} Price: </b>
                                                                                    {toCurrency(a.price)}
                                                                                    {(referral?.referral.type === ReferralType.sell)
                                                                                        && <>
                                                                                            {a.price !== referral.referral.sellTargetPrice
                                                                                                && <span>(Target Price was {toCurrency(referral.referral.sellTargetPrice)})</span>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                                <div className="flex flex-row">
                                                                                    <b>Address: </b>
                                                                                    <div className="flex flex-row pl-2">
                                                                                        {a.address}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        
                                                                        {[ReferralAction.appointmentScheduled].includes(a.type)
                                                                            && <div className="flex flex-col p-5 text-md border border-dashed border-gray-200 bg-gray-50 rounded-md gap-[6px]">
                                                                                <div><b>Appointment On: </b> {getLocaleDateWithTzStrFullDay(a.actionDate as string)}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            
                                                        </div>



                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.underContractClosingDate &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2 border-l border-solid border-black" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-chevron-dbl-up.svg"
                                                                    />
                                                                    Under Contract
                                                                </div>
                                                            </div>
                                                        }

                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.clientContacted &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2 border-l border-solid border-black" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-chevron-dbl-up.svg"
                                                                    />
                                                                    Referral Active
                                                                </div>
                                                            </div>
                                                        }

                                                        {/* Stage Separator */}
                                                        {a.type === ReferralAction.referralCreated &&
                                                            <div className="col-span-10 grid grid-cols-10 gap-4 items-center justify-center text-center py-4">
                                                                <div className="col-span-2" />
                                                                <div className="flex col-span-8 capitalize text-3xl-1 font-bold justify-start items-center gap-[5px]">
                                                                    <img
                                                                        className="relative w-[32px] h-[32px] overflow-hidden shrink-0 rounded-full"
                                                                        alt=""
                                                                        src="/img/mra-referral-timeline/icon-chevron-dbl-up.svg"
                                                                    />
                                                                    New Opportunity
                                                                </div>
                                                            </div>
                                                        }

                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* CLIENT DETAILS */}
                            <div className="relative">
                                <div className="rounded-lg p-[16px] bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] w-[420px] flex flex-col box-border items-start justify-start gap-[16px]">
                                    <div className="self-stretch flex flex-row items-start justify-start text-lg">
                                        <b className="relative leading-[25px]">Client Details</b>
                                    </div>
                                    
                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Client
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary items-start justify-start">
                                            {referral?.referral.clientFirstName} {referral?.referral.clientLastName}
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Email
                                        </div>
                                        <div className="flex w-[320px] break-all relative leading-[21px] text-tertiary justify-start">
                                            {referral?.referral.clientEmail}
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Phone
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {referral?.referral.clientPhone}
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Source
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {ReferralSourceStr[ReferralSource[referral?.referral.source as ReferralSource]]}
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Type
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {ReferralTypeStr[ReferralType[referral?.referral.type as ReferralType]]}
                                        </div>
                                    </div>
                                    
                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Fee
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {referral?.referral.fee}%
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            {referral?.referral.type === ReferralType.buy && `Location(s)`}
                                            {referral?.referral.type === ReferralType.sell && `Property Address`}
                                        </div>
                                        <div className="flex w-[320px] flex-wrap relative leading-[21px] text-tertiary">
                                            {referral?.referral.type === ReferralType.buy && !!referral?.referral.buyLocations?.length && referral?.referral.buyLocations?.length > 0 && referral?.referral.buyLocations?.map((c) => (
                                                <span key={`${c.city}_${c.stateId}`}>
                                                    <span className="capitalize">{c.city} {c.stateAbbreviation}</span>
                                                </span>
                                                // eslint-disable-next-line react/no-array-index-key
                                            )).reduce((prev, curr) => [prev, ', ', curr])}
                                            {(referral?.referral.type === ReferralType.sell)
                                                && <>
                                                    <div className="flex flex-col">
                                                        <span>
                                                            {referral?.referral.sellAddress1},

                                                            {referral?.referral.sellAddress2
                                                                && <>
                                                                    <br />
                                                                    {referral?.referral.sellAddress2},
                                                                </>
                                                            }

                                                            <br />
                                                            {referral?.referral.sellCity}, {usStates?.find((s) => s.id === referral?.referral.sellState)?.abbreviation}, {referral?.referral.sellZip}
                                                        </span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            Property Type
                                        </div>
                                        <div className="flex w-[320px] flex-wrap relative leading-[21px] text-tertiary">
                                            {referral?.referral.type === ReferralType.buy &&
                                                referral?.referral.buyPropertyTypes?.map((p: ReferralPropertyType) => ReferralPropertyTypeStr[ReferralPropertyType[p]]).reduce((prev, curr) => [prev, ', ', curr])
                                            }
                                            {referral?.referral.type === ReferralType.sell &&
                                                ReferralPropertyTypeStr[ReferralPropertyType[referral?.referral.sellPropertyType as number]]
                                            }
                                        </div>
                                    </div>

                                    <div className="self-stretch flex rounded-sm flex-row items-start justify-start gap-[8px]">
                                        <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                            {referral?.referral.type === ReferralType.buy && `Price Range`}
                                            {referral?.referral.type === ReferralType.sell && `Target Price`}
                                        </div>
                                        <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                            {referral?.referral.type === ReferralType.buy && `${toCurrency(referral.referral.buyRangeMin)} - ${toCurrency(referral.referral.buyRangeMax)}`}
                                            {referral?.referral.type === ReferralType.sell && `${toCurrency(referral.referral.sellTargetPrice)}`}
                                        </div>
                                    </div>

                                    {referral?.referral.type === ReferralType.buy &&
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                            <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                                Timeframe
                                            </div>
                                            <div className="flex w-[320px] relative leading-[21px] text-tertiary">
                                                {referral?.referral.type === ReferralType.buy && ReferralTimeFrameStr[ReferralTimeFrame[referral.referral.buyTimeFrame as ReferralTimeFrame]]}
                                                {/* {referral?.referral.type === ReferralType.sell && ReferralTimeFrameStr[ReferralTimeFrame[referral.referral.sellTimeFrame as ReferralTimeFrame]]} */}
                                            </div>
                                        </div>
                                    }

                                    {referral?.referral.type === ReferralType.buy && referral?.referral.buyNote &&
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                            <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                                Comments
                                            </div>
                                            <div className="flex w-[320px] relative leading-[21px] text-tertiary whitespace-pre-wrap">
                                                {referral?.referral.buyNote}
                                            </div>
                                        </div>
                                    }
                                    {referral?.referral.type === ReferralType.sell && referral?.referral.sellNote &&
                                        <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[8px]">
                                            <div className="flex w-[110px] relative leading-[21px] font-semibold">
                                                Comments
                                            </div>
                                            <div className="flex w-[320px] relative leading-[21px] text-tertiary whitespace-pre-wrap">
                                                {referral?.referral.sellNote}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                </div>
                </WaitContainer>
            }
        </>
    )
}

export { OutboundReferralTimeline };
