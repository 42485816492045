import React, { FunctionComponent } from 'react';

type WaitSpinnerProps = {
    sizePx?: number;
}

const WaitSpinner: FunctionComponent<WaitSpinnerProps> = ({ sizePx = 28 }) => {
    return <img className={`w-[${sizePx}px] h-[${sizePx}px] border-0`} src="/img/dual_ring_loading.gif" alt='Loading ... ' height={`${sizePx}`} width={`${sizePx}`} />;
    // <div className='flex flex-col gap-[10px] justify-center items-center bg-transparent'>
        {/* <img className='' src="/img/mra-bright.png" height="100" /> */}
        
    // </div>;
}

export { WaitSpinner };
