/* eslint-disable max-len */
import {
    TApiResultToken,
    TStateCounters,
    TProfileUserData,
    ReferralAgentSide,
    ReferralNotificationDirection,
    TReferralTableFilter,
    TAgentReferralInboundModel,
    TPartialRegisterUserModel,
    TStepName,
    TNameLogo,
    TLicensePayload,
    TNumberList,
    TCountyList,
    TUserTransaction,
    TViewPastReferralList,
    TStringList,
    TPastReferralSendModel,
    TRatingData,
    TRatingDataWithCounts,
    TNotificationSettingsModel,
    TSearchUsersQueryModel,
    TResponseGetInvites,
    TKnowledgeBaseCategoryViewModelList,
    TStateUsers,
    TResponseGetExistReferral,
    TReferralSaveModel,
    TGenerateReferralAgreement,
    TKnowledgeBaseArticleSearchResultList,
    TKnowledgeBaseArticleDetailView,
    TKnowledgeBaseArticleEdit,
    TKnowledgeBaseCategoryEdit,
    TKnowledgeBaseRelatedArticleList,
    TImageInfoList,
    TKnowledgeBaseArticleSave,
    TKnowledgeBaseCategoryExpandedView,
    TUpdateCategoryArticles,
    TKnowledgeBaseCategoryUpdateList,
    TViewFeatures,
    TPayloadGetFeatures,
    TManageStateLicenseList,
    TPayloadPutStateLicenses,
    TUserNoteRequest,
    TUserNoteList,
    UserNoteType,
    TManageFeatureSearchFilter,
    TManageFeatures,
    TAddFeature,
    UserRepositorySearchFilter,
    TResponseGetManageUsers,
    TPayloadGetDesignations,
    TManageDesignationList,
    TManageUserDesignationList,
    UpdateUserDesignation,
    TSendDesignationQuestion,
    TManageTransactions,
    TConfirmTransactionsUser,
    TSendTransactionsQuestion,
    SendTransactionsRequestModel,
    TManagePastReferralsRatingsQuery,
    TManagePastReferrals,
    TManageRejectedPastReferralsQuery,
    TManagePastReferralsQuery,
    TManagePastReferralRating,
    TConfirmReferral,
    TManageReferralsQuery,
    TManageReferrals,
    TManageReferralRatingsQuery,
    TClientEmailUpdateResult,
    TSendEmail,
    TDiscountCodeSearch,
    TDiscountCodesResponse,
    TEditDiscountCode,
    TPartialMemberElevention,
    TResponseRenewMembership,
    TEmailConfirmation,
    TResponseEmailConfirmation,
    TInvitationStatusResult,
    TInvitationAccept,
    TInvitationAcceptResponse,
    TResetPassword,
    TPastReferralRatingInfo,
    TPastReferralRating,
    TPublicProfileView,
    TResponseGetDeclinedReferral,
    TStateViewReferral,
    TStateViewReferralError,
    TSearchAgentQuery,
    TSearchAgentMapResult,
    TMemberAbsenceEmail,
    TStateReferralsUserOutbound,
    TStateReferralsUserInbound,
    TResponseGetUserReferralsWithAgentCount,
    TName,
    TQuickSearchClientList,
    TQuickSearchMessageList,
    TQuickSearchMemberList,
    UserStatusEnum,
    TUserStatusEnumObject,
    TUserHistoryList,
    TStateReferralExistInbound,
    TStateProfileEmulated,
    TIdApiKey,
    TUserApiKeyUpdateModel,
    TApiKeyModelList,
    TUserApiKeyFilter,
    TStataNotifications,
    TAgentProfileData,
    TCreateUserApiKeyResult,
    TCheckCodeResponse,
    TUserApiKeyUsageDataList,
    TUserApiKeyUsageFilter,
    TStateViewReferralInbound,
    TSearchAgentIndicesList,
    TResponseGetNewAgentInboundReferralData,
    TUpdateReferralContactStatusData,
    TManageSpecialtyList,
    TPrivateProfileView,
    TSendReferralsNoteData,
    TSendInviteAgentData,
    TUserWebsiteList,
    TUserMediaList,
    TSendReferralsCallsData,
    TReferralModel,
    TUsStateList,
    TNotificationSettingsModelOnlyEmails,
} from '../types';
import { post, postNApi } from './post';
import { get, getNApi } from './get';
import { put, putNApi } from './put';
import { del } from './del';
import { getParamsFromObject } from './get-params-from-object';
import { createQueryParams } from './create-query-params';
import { createInitByHeaders } from './create-init-by-headers';
import { nodeDomainApi } from '../constants/domain-api';

export const putAccountChangePassword = (data: { currentPassword: string; newPassword: string }): Promise<void> => put('/api/api/account/change-password', data);

export const putAccountChangePasswordAdmin = (
    data: {
        userId: number;
        newPassword: string;
    },
    headers: HeadersInit,
): Promise<void> => put('/api/api/account/change-password-admin', data, createInitByHeaders(headers));

export const getAccountSettings = (): Promise<unknown> => get('/api/api/account/settings');

export const deleteAccountExpansionTeams = (id: number): Promise<unknown> => del(`/api/api/account/expansion-teams/${id}`);

export const putAccountSettings = (data: TNotificationSettingsModel): Promise<void> => put('/api/api/account/settings', data);
export const putAccountStatisticEmails = (data: TNotificationSettingsModelOnlyEmails): Promise<void> => put('/api/api/account/statisticEmails', data);

export const getAccountEmulate = (userId: number, headers: HeadersInit): Promise<TStateProfileEmulated> => get(`/api/api/account/emulate/${userId}`, createInitByHeaders(headers));

export const getAccountExpansionTeams = (headers: HeadersInit): Promise<unknown> => get('/api/api/account/expansion-teams', createInitByHeaders(headers));

export const getAccountNames = (searchTerm: string, headers: HeadersInit): Promise<unknown> => get(`/api/api/account/names?searchTerm=${searchTerm}`, createInitByHeaders(headers));

export const postAccountConfirmEmail = (data: TEmailConfirmation): Promise<TResponseEmailConfirmation> => post('/api/api/account/confirm-email', data);

export const postToken = (userName: string, password: string, showRenew: boolean): Promise<TApiResultToken> =>
    post(
        '/api/token',
        ['grant_type=password', '&username=', encodeURIComponent(userName), '&password=', encodeURIComponent(password), showRenew ? '&sr=true' : ''].join(''),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            mode: 'cors',
        },
        true,
    );

export const getTokenResponse = (showRenew: boolean = false): Promise<TApiResultToken> => get(`/api/api/user/token-response${showRenew ? '?sr=true' : ''}`);

const headersPostAccountResetPassword = {
    'Content-Type': 'application/json',
};

export const postAccountResetPassword = (data: { email: string }): Promise<unknown> => post('/api/api/account/resetpassword', JSON.stringify(data), { headers: headersPostAccountResetPassword });

export const getProfileUserData = (showRenew?: boolean): Promise<TProfileUserData> => get(`/api/api/profile/user-data${showRenew ? '?sr=true' : ''}`);

export const postAccountSendEmailConfirmation = (data: { email: string }): Promise<void> => post('/api/api/account/send-email-confirmation', data);

export const postAccountUpdatePassword = (data: TResetPassword): Promise<void> => post('/api/api/account/updatePassword', JSON.stringify(data));

export const getDashboardNotifications = (params: { direction: ReferralNotificationDirection; count: number; skip: number }): Promise<TStataNotifications> =>
    get(`/api/api/dashboard/notifications${getParamsFromObject(params)}`);

export const getDashboardUsers = (ids: Array<number>): Promise<unknown> => get(`/api/api/dashboard/usersarr/${ids.join(',')}`);

export const getDashboardUser = (id: number): Promise<unknown> => get(`/api/api/dashboard/user/${id}`);

export const postDesignationsDocument = (id: number, formData: FormData): Promise<void> => post(`/api/api/designations/${id}/document`, formData);

export const putDesignationsById = (id: number, data: UpdateUserDesignation): Promise<void> => put(`/api/api/designations/${id}`, data);

export const getDesignationsTypes = (): Promise<TManageDesignationList> => get('/api/api/designations/types');

export const getDesignations = (params: TPayloadGetDesignations): Promise<TManageUserDesignationList> => get(`/api/api/designations${getParamsFromObject(params)}`);

export const postDesignationsSendQuestion = (data: TSendDesignationQuestion): Promise<void> => post('/api/api/designations/send-question', data);

export const getSpecialtiesTypes = (): Promise<TManageSpecialtyList> => get('/api/api/specialties/types');

export const postFeatures = (data: { description: string; title: string }): Promise<void> => post('/api/api/features', data);

export const putFeaturesApprove = (id: number, data: TAddFeature): Promise<unknown> => put(`/api/api/features/${id}/approve`, data);

export const putFeaturesComplete = (id: number): Promise<void> => put(`/api/api/features/${id}/complete`);

export const deleteFeatures = (id: number): Promise<void> => del(`/api/api/features/${id}`);

export const getFeatures = (params: TPayloadGetFeatures): Promise<TViewFeatures> => get(`/api/api/features${getParamsFromObject(params)}`);

export const getFeaturesManage = (params: TManageFeatureSearchFilter): Promise<TManageFeatures> => get(`/api/api/features/manage${getParamsFromObject(params)}`);

export const getFeaturesVoted = (searchTerm?: string | null): Promise<TViewFeatures> => get(`/api/api/features/voted${getParamsFromObject({ searchTerm })}`);

export const postFeaturesSendQuestion = (data: { userId: number; body: string }): Promise<unknown> => post('/api/api/features/send-question', data);

export const postFeaturesUnvote = (id: number): Promise<TNumberList> => post(`/api/api/features/${id}/unvote`);

export const putFeatures = (id: number, data: TAddFeature): Promise<void> => put(`/api/api/features/${id}`, data);

export const postFeaturesVote = (id: number): Promise<TNumberList> => post(`/api/api/features/${id}/vote`);

export const getInvite = (): Promise<TResponseGetInvites> => get('/api/api/invites');

export const postInvitesMessages = (data: { message: string; emails: TStringList }): Promise<unknown> => post('/api/api/invites/send', data);

export const postKnowledgeBaseArticle = (data: TKnowledgeBaseArticleSave): Promise<number> => post('/api/api/knowledge-base/articles', data);

export const deleteKnowledgeBaseArticle = (id: number): Promise<void> => del(`/api/api/knowledge-base/articles/${id}`);

export const deleteImages = (fileName: string): Promise<void> => del(`/api/api/images?fileName=${fileName}`);

export const getKnowledgeBaseArticle = (id: number): Promise<TKnowledgeBaseArticleDetailView> => get(`/api/api/knowledge-base/articles/${id}`);

export const getKnowledgeBaseArticlesEdit = (id: number): Promise<TKnowledgeBaseArticleEdit> => get(`/api/api/knowledge-base/articles/${id}/edit`);

export const getKnowledgeBaseAutocompleteArticles = (searchTerm: string): Promise<TKnowledgeBaseRelatedArticleList> => get(`/api/api/knowledge-base/autocomplete-articles?searchTerm=${searchTerm}`);

export const getKnowledgeBaseCategories = (): Promise<TKnowledgeBaseCategoryViewModelList> => get('/api/api/knowledge-base/categories?includeArticles=true&articleNumber=4');

export const getKnowledgeCategoriesById = (id: number): Promise<TKnowledgeBaseCategoryExpandedView> => get(`/api/api/knowledge-base/categories/${id}`);

export const getKnowledgeBaseCategoryList = (): Promise<TKnowledgeBaseCategoryEdit> => get('/api/api/knowledge-base/categories');

export const getImages = (): Promise<TImageInfoList> => get('/api/api/images/list');

export const postImages = (formData: FormData): Promise<void> => post('/api/api/images', formData);

export const getKnowledgeBaseArticlesSearch = (
    searchTerm: string, // searchTerm must be encoded
): Promise<TKnowledgeBaseArticleSearchResultList> => get(`/api/api/knowledge-base/articles/search?searchTerm=${searchTerm}`);

export const putKnowledgeBaseArticles = (id: number, data: TKnowledgeBaseArticleSave): Promise<void> => put(`/api/api/knowledge-base/articles/${id}`, data);

export const putKnowledgeBaseCategories = (data: TKnowledgeBaseCategoryUpdateList): Promise<void> => put('/api/api/knowledge-base/categories', data);

export const putKnowledgeBaseCategoryArticles = (id: number, data: TUpdateCategoryArticles): Promise<void> => put(`/api/api/knowledge-base/categories/${id}/articles`, data);

export const getDashboardCounters = (): Promise<TStateCounters> => get('/api/api/dashboard/counters');

export const postSupportEmail = (data: { message: string }): Promise<unknown> => post('/api/api/support-email', data);

export const getUsers = (params: UserRepositorySearchFilter): Promise<TResponseGetManageUsers> => get(`/api/api/users${getParamsFromObject(params)}`);

export const getUserNotes = (userId: number, type: keyof typeof UserNoteType): Promise<TUserNoteList> => get(`/api/api/user/${userId}/notes${type ? `/${type}` : ''}`);

export const postUserNotesById = (userId: number, data: TUserNoteRequest): Promise<void> => post(`/api/api/user/${userId}/notes`, data);

export const postPastReferralsUser = (userId: number, data: TPastReferralSendModel): Promise<number> => post(`/api/api/past-referrals/user/${userId}`, data);

export const putPastVerify = (
    id: number,
    data: {
        note: string;
    },
): Promise<void> => put(`/api/api/past-referrals/${id}/verify`, data);

export const deletePastReferrals = (id: number): Promise<unknown> => del(`/api/api/past-referrals/${id}`);

export const getPastReferral = (id: number): Promise<unknown> => get(`/api/api/past-referrals/${id}`);

export const getPastReferralsRatings = (params: TManagePastReferralsRatingsQuery): Promise<TManagePastReferrals> => get(`/api/api/past-referrals/ratings${getParamsFromObject(params)}`);

export const getPastReferrals = (params: TManagePastReferralsQuery): Promise<TManagePastReferrals> => get(`/api/api/past-referrals${getParamsFromObject(params)}`);

export const getPastReferralsRejected = (params: TManageRejectedPastReferralsQuery): Promise<TManagePastReferrals> => get(`/api/api/past-referrals/rejected${getParamsFromObject(params)}`);

export const getUsStates = (): Promise<unknown> => get('/api/api/locations/states');

export const getPastReferralsUser = (userId: number): Promise<TViewPastReferralList> => get(`/api/api/past-referrals/user/${userId}`);

export const postPastReferralsUpload = (formData: FormData): Promise<string> => post('/api/api/past-referrals/upload', formData);

export const putPastReferralReject = (id: number): Promise<void> => put(`/api/api/past-referrals/${id}/reject`);

export const postPastReferralsSendQuestion = (data: { userId: number; body: string }): Promise<void> => post('/api/api/past-referrals/send-question', data);

export const postPastReferralsSupport = (
    referralId: number,
    data: {
        message: string;
    },
): Promise<unknown> => post(`/api/api/past-referrals/${referralId}/support-request`, data);

export const putPastReferral = (id: number, data: TPastReferralSendModel): Promise<unknown> => put(`/api/api/past-referrals/${id}`, data);

export const postDiscountCodes = (data: TEditDiscountCode): Promise<void> => post('/api/api/discount-codes', data);

export const putDiscountCodesDisable = (id: number): Promise<void> => put(`/api/api/discount-codes/${id}/disable`);

export const putDiscountCodesEnable = (id: number): Promise<void> => put(`/api/api/discount-codes/${id}/enable`);

export const getDiscountCodesById = (id: number): Promise<TEditDiscountCode> => get(`/api/api/discount-codes/${id}`);

export const getDiscountCodes = (params: TDiscountCodeSearch): Promise<TDiscountCodesResponse> => get(`/api/api/discount-codes${getParamsFromObject(params)}`);

export const putDiscountCodes = (id: number, data: TEditDiscountCode): Promise<void> => put(`/api/api/discount-codes/${id}`, data);

export const getPublicProfile = (profileId: string): Promise<TPublicProfileView> => get(`/api/api/public-profile/${profileId}/`);
export const getPublicProfileByEmail = (email: string): Promise<TPublicProfileView> => get(`/api/api/public-profile/by-email/${email}`);

export const getPublicProfileById = (): Promise<TPublicProfileView> => get(`/api/api/profile/public/`);
export const getPrivateProfileById = (): Promise<TPrivateProfileView> => get(`/api/api/profile/private/`);

export const getUserWebsites = (userId: number): Promise<TUserWebsiteList> => get(`/api/api/profile/${userId}/websites`);
export const postUserWebsite = (userId: number, url: string): Promise<void> => post(`/api/api/profile/${userId}/website/${url}`);
export const delUserWebsite = (userId: number, websiteId: number): Promise<void> => del(`/api/api/profile/${userId}/website/${websiteId}`);

export const getUserMedias = (userId: number): Promise<TUserMediaList> => get(`/api/api/profile/${userId}/media`);
export const postUserMediaYoutubeUrl = (userId: number, youtubeUrl: string): Promise<void> => post(`/api/api/profile/${userId}/media/youtube/${youtubeUrl}`);
export const postUserMediaUploadImage = (userId: number, data: FormData): Promise<void> => post(`/api/api/profile/${userId}/media/upload-image`, data);
export const delUserMedia = (userId: number, mediaId: number): Promise<void> => del(`/api/api/profile/${userId}/media/${mediaId}`);

export const putRatingConfirm = (id: number, data: TConfirmReferral): Promise<void> => put(`/api/api/rating/${id}/confirm`, data);

export const getRatingPastReferral = (id: string): Promise<TPastReferralRatingInfo> => get(`/api/api/rating/past-referral/${id}`);

export const getRatingById = (id: number): Promise<TManagePastReferralRating> => get(`/api/api/rating/${id}`);

export const getRating = (userId: number, skip: number, take: number, isReturnCounts: boolean): Promise<TRatingData | TRatingDataWithCounts> =>
    get(['/api/api/rating', `?returnCounts=${isReturnCounts}`, `&userId=${userId}`, skip ? `&skip=${skip}` : '', take ? `&take=${take}` : ''].join(''));

export const putRatingReject = (id: number): Promise<void> => put(`/api/api/rating/${id}/reject`);

export const postRatingPastReferral = (id: string, data: TPastReferralRating): Promise<void> => post(`/api/api/rating/past-referral/${id}`, data);

export const postRatingAdminSendRequest = (
    ratingId: number,
    data: {
        message: string;
        emails: TStringList;
    },
): Promise<void> => post(`/api/api/rating/${ratingId}/admin-send-request`, data);

export const postRatingSendRequest = (
    ratingId: number,
    data: {
        message: string;
    },
): Promise<void> => post(`/api/api/rating/${ratingId}/send-request`, data);

export const putRating = (id: number, data: TConfirmReferral): Promise<void> => put(`/api/api/rating/${id}`, data);

export const getReferralsAcceptRefferal = (invitationId: number, viewOnly?: string): Promise<unknown> =>
    get(['/api/api/referrals/accept-referral/', invitationId, viewOnly ? `?viewOnly=${viewOnly}` : ''].join(''));

export const postReferralsAcceptOutbound = (invitationId: number): Promise<unknown> => post(`/api/api/referrals/accept-outbound/${invitationId}`);

export const postReferralsCalls = (
    data: TSendReferralsCallsData,
): Promise<unknown> => post('/api/api/referrals/calls', data);

export const postReferralNote = (data: TSendReferralsNoteData): Promise<unknown> => post('/api/api/referrals/notes', data);

export const postReferralsCancelInvitation = (
    id: number,
    data: {
        notifyAgent: boolean;
        message?: string;
    },
): Promise<void> => post(`/api/api/referrals/${id}/cancel-invitation`, data);

export const postCancelReferral = (
    id: number,
    data: {
        message: string;
    },
): Promise<unknown> => post(`/api/api/referrals/${id}/cancel`, data);

export const postConfirmAgreement = (id: number): Promise<unknown> => post(`/api/api/referrals/${id}/confirm-agreement`);

export const postContactingClient = (id: number): Promise<unknown> => post(`/api/api/referrals/${id}/contacting-client`);

export const postDeclineAgreement = (
    referralId: number,
    data: {
        message: string;
    },
): Promise<unknown> => post(`/api/api/referrals/${referralId}/decline-agreement`, data);

export const postReferralsDeclineReferral = (
    invitationId: number,
    data: {
        message?: string | null;
    },
): Promise<void> => post(`/api/api/referrals/decline-referral/${invitationId}`, data);

export const postReferralsDeleteInbound = (
    id: number,
    data: {
        message: string;
    },
): Promise<unknown> => post(`/api/api/referrals/${id}/delete-inbound`, data);

export const deleteReferral = (id: number): Promise<void> => del(`/api/api/referrals/${id}`);

export const postGenerateAgreement = (referralId: number, data: TGenerateReferralAgreement): Promise<string> => post(`/api/api/referrals/${referralId}/generate-agreement`, data);

// export const postGenerateInboundAgreement = (
//     referralId: number,
//     data: TGenerateInboundReferralAgreementModel,
// ): Promise<unknown> => post(`/api/api/referrals/${referralId}/generate-inbound-referral-agreement`, data);

export const getReferralsAgentInbound = (id: number): Promise<TStateReferralExistInbound> => get(`/api/api/referrals/agent-inbound-referrals/${id}`);

export const getReferralsAgentProfile = (
    userId: number,
    params: {
        contactInfo: boolean;
        countView: boolean;
    },
): Promise<TAgentProfileData> => get(`/api/api/referrals/agent-profile/${userId}${getParamsFromObject(params)}`);

export const getAutocompleteBrokerage = (params: { search: string }): Promise<unknown> => get(`/api/api/referrals/autocomplete-brokerage?search=${params.search}`);

export const getReferralsCall = (referralId: number, side: ReferralAgentSide): Promise<unknown> => get(`/api/api/referrals/${referralId}/calls/${side}`);

export const getAutocompleteCities = (params: { search: string }): Promise<unknown> => get(`/api/api/referrals/autocomplete-cities?search=${params.search}`);

export const getAutocompleteCounties = (params: { search: string }): Promise<unknown> => get(`/api/api/referrals/autocomplete-counties?search=${params.search}`);

export const getAutocompleteZipCodes = (params: { search: string }): Promise<unknown> => get(`/api/api/referrals/autocomplete-zip-codes?search=${params.search}`);

export const getReferralsDeclineReferral = (invitationId: number): Promise<TResponseGetDeclinedReferral> => get(`/api/api/referrals/decline-referral/${invitationId}`);

export const getReferralsEmails = (referralId: number): Promise<unknown> => get(`/api/api/referrals/${referralId}/emails`);

export const getReferralsClientEmail = (referralId: number): Promise<TClientEmailUpdateResult> => get(`/api/api/referrals/${referralId}/client-email-update-result`);

export const getReferralsGenerateAgreement = (id: number): Promise<Blob> => get(`/api/api/referrals/${id}/generate-agreement`);

export const getReferralsInbound = (id: number): Promise<TStateViewReferralInbound> => get(`/api/api/referrals/${id}/inbound`);

export const getReferralsUsersInbound = (userId: number, params: TReferralTableFilter): Promise<TStateReferralsUserInbound> =>
    get(`/api/api/referrals/users/${userId}/inbound${getParamsFromObject(params)}`);

export const getReferralsUsersWithAgentInbound = (userId: number, agentId: number, filter: TReferralTableFilter): Promise<TStateReferralsUserInbound> =>
    get([`/api/api/referrals/user/${userId}/agent/${agentId}/referrals/`, `inbound${getParamsFromObject(filter)}`].join(''));

export const postReferralsUsersWithAgentFilteredInbound = (userId: number, agentId: number, filters: ReadonlyArray<TReferralTableFilter>): Promise<TStateReferralsUserInbound> =>
    post(`/api/api/referrals/user/${userId}/agent/${agentId}/filter-referrals/inbound`, filters);

export const postReferralsUsersMapRequest = (data: TSearchAgentQuery): Promise<TSearchAgentMapResult> => post('/api/api/referrals/users/map-request', data);

export const postReferralsUsersRequest = (data: TSearchAgentQuery): Promise<TStateUsers> => post('/api/api/referrals/users/request', data);

export const postReferralsUsersRequestIndices = (data: TSearchAgentQuery): Promise<unknown> => post('/api/api/referrals/users/request-indeces', data);

export const getReferralsAgentInboundNew = (userId?: number): Promise<TResponseGetNewAgentInboundReferralData> =>
    get(['/api/api/referrals/agent-inbound-referrals/new', userId ? `?userId=${userId}` : ''].join(''));

export const getReferralsNew = (userId?: number, agentId?: number): Promise<unknown> =>
    get(['/api/api/referrals/new', userId || agentId ? `?userId=${userId || 0}&agentId=${agentId || 0}` : ''].join(''));

export const getReferralsAgentNotes = (referralId: number, side: ReferralAgentSide): Promise<unknown> => get(`/api/api/referrals/${referralId}/agent-notes/${side}`);

export const getReferralsViewOutboundInvitation = (
    invitationId: number,
    params: {
        accept: boolean;
    },
): Promise<TStateViewReferral | TStateViewReferralError> => get(`/api/api/referrals/view-outbound-invitation/${invitationId}?accept=${params.accept}`);

export const getReferralsUsersInboundPending = (userId: number, params: TReferralTableFilter): Promise<TStateReferralsUserInbound> =>
    get(`/api/api/referrals/users/${userId}/inbound-pending${getParamsFromObject(params)}`);

export const getReferralsUsersWithAgentInboundPending = (userId: number, agentId: number, filter: TReferralTableFilter): Promise<TStateReferralsUserInbound> =>
    get([`/api/api/referrals/user/${userId}/agent/${agentId}/`, `inbound-pending${getParamsFromObject(filter)}`].join(''));

export const getReferralsRating = (params: TManageReferralRatingsQuery): Promise<TManageReferrals> => get(`/api/api/referrals/ratings${getParamsFromObject(params)}`);

export const getReferralById = (id: number): Promise<TResponseGetExistReferral> => get(`/api/api/referrals/${id}`);

export const getReferrals = (params: TManageReferralsQuery): Promise<TManageReferrals> => get(`/api/api/referrals${getParamsFromObject(params)}`);

// export const getReferralsSearchAgent = (
//     params: TLoadSearchAgentData,
// ): Promise<TStateSearchAgent> => get(
//     `/api/api/referrals/search-agent${getParamsFromObject(params)}`,
// );

// export const getReferralsUsersSearchAgentIndices = (
//     params: TLoadSearchAgentIndicesData,
// ): Promise<TSearchAgentIndicesList> => get(
//     `/api/api/referrals/users/search-agent-indeces${getParamsFromObject(params)}`,
// );

export const getTimeline = (side: number, referralId: number): Promise<unknown> => get(`/api/api/referrals/${referralId}/timeline/${side}`);

export const getReferralsUserOutboundInvitations = (userId: number, params: TReferralTableFilter): Promise<unknown> =>
    get(`/api/api/referrals/user/${userId}/outbound-invitations${getParamsFromObject(params)}`);

export const getReferralsUserWithAgentOutboundInvitations = (userId: number, agentId: number, filter: TReferralTableFilter): Promise<TStateReferralsUserOutbound> =>
    get([`/api/api/referrals/user/${userId}/agent/${agentId}/`, `outbound-invitations${getParamsFromObject(filter)}`].join(''));

export const getReferralsUser = (userId: number, params: TReferralTableFilter): Promise<unknown> => get(`/api/api/referrals/user/${userId}${getParamsFromObject(params)}`);

export const getReferralsUserWithAgent = (userId: number, agentId: number, filter: TReferralTableFilter): Promise<TStateReferralsUserOutbound> =>
    get([`/api/api/referrals/user/${userId}/agent/${agentId}/referrals`, getParamsFromObject(filter)].join(''));

export const postReferralsUserWithAgentFiltered = (userId: number, agentId: number, filters: ReadonlyArray<TReferralTableFilter>): Promise<ReadonlyArray<TStateReferralsUserOutbound>> =>
    post(`/api/api/referrals/user/${userId}/agent/${agentId}/filter-referrals`, filters);

export const getReferralsView = (id: number): Promise<unknown> => get(`/api/api/referrals/${id}/view`);

export const postReferralsInviteAgentEmailOnly = (
    data: TSendInviteAgentData,
): Promise<unknown> => post('/api/api/referrals/invite-agent-email-only', data);

export const postReferralsInviteAgent = (
    data: TSendInviteAgentData,
): Promise<unknown> => post('/api/api/referrals/invite-agent', data);

export const postReferralsPublicDeclineReferral = (
    guid: string,
    data: {
        message?: string | null;
    },
): Promise<void> => post(`/api/api/referrals/public/decline-referral/${guid}`, data);

export const getReferralsPublicDeclineReferral = (guid: string): Promise<TResponseGetDeclinedReferral> => get(`/api/api/referrals/public/decline-referral/${guid}`);

export const postResendAgreementRequest = (id: number): Promise<unknown> => post(`/api/api/referrals/${id}/resend-agreement-request`);

export const postReferralsResendInvitation = (id: number): Promise<unknown> => post(`/api/api/referrals/${id}/resend-invitation`);

export const postReferralsMemberAbsence = (data: TMemberAbsenceEmail): Promise<void> => post('/api/api/referrals/member-absence', data);

export const postReferralsClientEmailUpdate = (
    referralId: number,
    data: {
        newEmail: string;
        reason: string;
    },
): Promise<unknown> => post(`/api/api/referrals/${referralId}/client-email-update-request`, data);

// export const postReferralsSendEmail = (
//     data: TSendReferralsEmailData,
// ): Promise<void> => post('/api/api/referrals/send-email', data);

export const postReferralsClientEmailUpdateResult = (referralId: number, data: TSendEmail): Promise<void> => post(`/api/api/referrals/${referralId}/client-email-update-result`, data);

export const postReferralsSendFeeStatusUpdate = (
    referralId: number,
    data: {
        message: string;
    },
): Promise<unknown> => post(`/api/api/referrals/${referralId}/send-fee-status-update`, data);

export const postSendStatusUpdate = (
    referralId: number,
    data: {
        message: string;
    },
): Promise<unknown> => post(`/api/api/referrals/${referralId}/send-status-update`, data);

export const postReferralsNextStep = (
    referralId: number,
    data: {
        action: number;
    },
): Promise<unknown> => post(`/api/api/referrals/${referralId}/next-step`, data);

export const putReferralsAgentInbound = (id: number, data: TAgentReferralInboundModel): Promise<unknown> => put(`/api/api/referrals/agent-inbound-referrals/${id}`, data);

export const postNewReferral = (id: number, data: TReferralSaveModel): Promise<void> => put(`/api/api/referrals/${id}/new`, data);
export const postNewReferralInbound = (id: number, data: TReferralSaveModel): Promise<void> => put(`/api/api/referrals/${id}/new/inbound`, data);
export const updateReferral = (id: number, data: TReferralSaveModel): Promise<void> => put(`/api/api/referrals/${id}`, data);

export const postReferralsUpdateContactStatus = (id: number, data: TUpdateReferralContactStatusData): Promise<unknown> => post(`/api/api/referrals/${id}/update-contact-status`, data);

// export const postReferralUpdateFeeStatus = (
//     referralId: number,
//     data: TUpdateReferralFeeStatusData,
// ): Promise<unknown> => post(`/api/api/referrals/${referralId}/update-fee-status`, data);

export const getAccountCheckCode = (params: { c: string }): Promise<TCheckCodeResponse> => get(`/api/api/account/check-code?c=${params.c}`);

export const getAccountReferralInvitation = (id: string): Promise<TInvitationStatusResult> => get(`/api/api/account/referral-invitation/${id}`);

export const getAccountExpansionNetworks = (params: { searchTerm: string }): Promise<unknown> => get(`/api/api/account/expansion-networks?searchTerm=${params.searchTerm}`);

export const getAccountRegistrationAvailable = (params: { inviteCode?: string | null; discountCode?: string | null }): Promise<unknown> =>
    get(
        [
            '/api/api/account/registration-available',
            createQueryParams([
                ['c', params.inviteCode],
                ['d', params.discountCode],
            ]),
        ].join(''),
        { credentials: 'include' },
    );

// export const postAccountPartialRegister = (
//     data: TPartialRegisterUserModel,
// ): Promise<unknown> => post('/api/api/account/partialregister', data);

export const postAccountRegister = (data: TPartialRegisterUserModel): Promise<unknown> => post('/api/api/account/register', data);

export const postAccountInvitationalPreRegister = (data: TPartialRegisterUserModel): Promise<any> => post('/api/api/account/invitational-pre-registration', data);
export const getAccountInvitationalPreRegister = (): Promise<any> => get(`/api/api/account/invitational-preregistered`);


export const getAccountIsUserExist = (email: string): Promise<unknown> => get(`/api/api/account/isUserExist?email=${email}`);

export const postAccountAcceptReferralInvitation = (id: string, data: TInvitationAccept): Promise<TInvitationAcceptResponse> => post(`/api/api/account/accept-referral-invitation/${id}`, data);

export const postReferralsUploadAgreement = (data: FormData): Promise<unknown> => post('/api/api/referrals/upload-agreement', data);

export const postReferralsUploadAgreementByReferralId = (referralId: number, data: FormData): Promise<unknown> => post(`/api/api/referrals/${referralId}/upload-agreement`, data);

export const postSetTransactionClosed = (referralId: number): Promise<unknown> => post(`/api/api/referrals/${referralId}/upload-signed-agreement`);

export const postUploadSignedAgreement = (referralId: number, formData: FormData): Promise<unknown> => post(`/api/api/referrals/${referralId}/upload-signed-agreement`, formData);

export const postAccountRenew = (data: { cardToken: string }): Promise<TResponseRenewMembership | void> => post('/api/api/account/renew', data);

export const postAccountRenewalDowngrade = (): Promise<string> => post('/api/api/account/renewal-downgrade');

export const postAccountUpgrade = (data: TPartialMemberElevention): Promise<string> => post('/api/api/account/upgrade', data);

export const postSearchMembersFavorites = (id: number): Promise<unknown> => post(`/api/api/search-members/favorites/${id}`);

export const deleteSearchMembersFavorites = (id: number): Promise<unknown> => del(`/api/api/search-members/favorites/${id}`);

// export const getSearchMembersAll = (
//     params: TLoadSearchMembersAllData,
// ): Promise<TStateSearchMembersAll> => get(
//     `/api/api/search-members/all${getParamsFromObject(params)}`,
// );

export const getSearchMembersAutocomplete = (params: { search: string }): Promise<unknown> => get(`/api/api/search-members/autocomplete?search=${params.search}`);

export const postSearchMembersIndices = (data: TSearchUsersQueryModel): Promise<unknown> => post('/api/api/search-members/indices', data);

export const getSearchMembers = (): Promise<unknown> => get('/api/api/search-members');

export const postSearchMembers = (data: TSearchUsersQueryModel): Promise<TStateUsers> => post('/api/api/search-members', data);

export const getStateLicenses = (searchTerm?: string | null): Promise<TManageStateLicenseList> => get(`/api/api/state-licenses${getParamsFromObject({ searchTerm })}`);

export const putStateLicense = (id: number, data: TPayloadPutStateLicenses): Promise<void> => put(`/api/api/state-licenses/${id}`, data);

export const putStateLicenseDocument = (id: number, data: FormData): Promise<void> => post(`/api/api/state-licenses/${id}/document`, data);

export const postStateLicenseSendQuestion = (data: { userId: number; body: string }): Promise<void> => post('/api/api/state-licenses/send-question', data);

export const postSubscribe = (data: { email: string | null }): Promise<void> => post('/api/api/subscribe', data);

export const postTransactionsConfirm = (data: TConfirmTransactionsUser): Promise<void> => post('/api/api/transactions/confirm', data);

export const getTransactions = (params: { searchTerm: string }): Promise<TManageTransactions> => get(`/api/api/transactions${getParamsFromObject(params)}`);

export const getTransactionsOpenedPeriods = (userId: number): Promise<unknown> => get(`/api/api/transactions/user/${userId}/opened-periods`);

export const postTransactionsReject = (data: TNumberList): Promise<void> => post('/api/api/transactions/reject', data);

export const putTransactionsSave = (data: TUserTransaction): Promise<void> => put(`/api/api/transactions/${data.id}`, data);

export const postTransactionsSave = (data: TUserTransaction, userId: number): Promise<number> => post(`/api/api/transactions/user/${userId}`, data);

export const postTransactionsUserUpload = (formData: FormData, userId: number): Promise<string> => post(`/api/api/transactions/user/${userId}/upload`, formData);

// export const postTransactionsSendError = (
//     data: TPayloadSendErrorUpload,
// ): Promise<void> => post('/api/api/transactions/send-error', data);

export const postTransactionsSendQuestion = (data: TSendTransactionsQuestion): Promise<void> => post('/api/api/transactions/send-question', data);

export const postTransactionsSendRequest = (data: SendTransactionsRequestModel): Promise<void> => post('/api/api/transactions/send-request', data);

export const putProfileData = (action: TStepName, data: {model: unknown; step: number;}, userId: number ): Promise<unknown> => put(`/api/api/profile/${userId}/${action}`, data);
export const postProfileSocialMedium = (data, userId): Promise<unknown> => post(`/api/api/profile/${userId}/user-social-medium`, data);

export const putUserPartnerstackKey = (userId: number, partnerstackKey: string): Promise<unknown> => put(`/api/api/profile/${userId}/partnerstackKey/${partnerstackKey}`);
export const putUserGoogleReviewPlaceId = (userId: number, googleReviewPlaceId: string): Promise<unknown> => put(`/api/api/profile/${userId}/googleReviewPlaceId/${googleReviewPlaceId}`);

export const putStripeId = (userId: number, stripeId: string): Promise<unknown> => put(`/api/api/profile/${userId}/stripe-id/${stripeId}`);

export const postProfileLicenses = (userId: number, data: TLicensePayload): Promise<number> => post(`/api/api/profile/${userId}/licenses`, data);
export const deleteProfileLicenses = (id: number, userId: number): Promise<void> => del(`/api/api/profile/${userId}/licenses/${id}`);
export const deleteProfileLogo = (userId: number, url: TNameLogo): Promise<void> => del(`/api/api/profile/${userId}/${url}`);

export const postProfileLogo = (userId: number, method: TNameLogo, data: FormData): Promise<string> => post(`/api/api/profile/${userId}/${method}`, data);

export const postBannerImage = (userId: number, data: FormData): Promise<string> => post(`/api/api/profile/${userId}/banner`, data);
export const deleteBannerImage = (userId: number): Promise<string> => del(`/api/api/profile/${userId}/banner`);

export const getReferralReviewsForUser = (forUserId: number): Promise<string> => get(`/api/api/profile/${forUserId}/referral-reviews`);
export const postReferralReviewForUser = (forUserId: number, referralId: number, data: any): Promise<string> => post(`/api/api/profile/${forUserId}/referral-reviews/${referralId}`, data);

export const getProfile = (userId: number): Promise<unknown> => get(`/api/api/profile/${userId}`);

export const getProfileCities = (params: { states: TNumberList; startsWith: string }): Promise<unknown> => get(`/api/api/profile/cities${getParamsFromObject(params)}`);

export const getProfileCounties = (params: { states: TNumberList; startsWith: string }): Promise<TCountyList> => get(`/api/api/profile/counties${getParamsFromObject(params)}`);

export const getProfileZipcodes = (params: { states: TNumberList; startsWith: string }): Promise<unknown> => get(`/api/api/profile/zipcodes${getParamsFromObject(params)}`);

export const getAutocompleteQuickSearchClients = (searchTerm: string): Promise<TQuickSearchClientList> => get(`/api/api/referrals/autocomplete-qiuck-search/clients?search=${searchTerm}`);

export const getAutocompleteQuickSearchMessages = (searchTerm: string): Promise<TQuickSearchMessageList> => get(`/api/api/referrals/autocomplete-qiuck-search/messages?search=${searchTerm}`);

export const getAutocompleteQuickSearchMembers = (searchTerm: string): Promise<TQuickSearchMemberList> => get(`/api/api/referrals/autocomplete-qiuck-search/members?search=${searchTerm}`);

export const getUserReferralsWithAgentCount = (userId: number, agentId: number): Promise<TResponseGetUserReferralsWithAgentCount> => get(`/api/api/referrals/user/${userId}/agent/${agentId}/count`);

export const getIdFromAuth0Id = (auth0Id: string): Promise<number> => get(`/api/api/user/id/${auth0Id}`);

export const getUserAgentName = (agentId: number): Promise<TName> => get(`/api/api/user/${agentId}/agent-name`);

export const getUserStatus = (userId: number): Promise<UserStatusEnum> => get(`/api/api/user/${userId}/status`);

export const putUserStatus = (userId: number, data: TUserStatusEnumObject): Promise<UserStatusEnum> => put(`/api/api/user/${userId}/status`, data);

export const getUserHistories = (userId: number): Promise<TUserHistoryList> => get(`/api/api/user/${userId}/histories`);

export const postIsAlive = (headers?: HeadersInit): Promise<void> => post('/api/api/user-sessions/is-alive', null, createInitByHeaders(headers));

export const deleteUserApiKey = (id: TIdApiKey): Promise<void> => del(`/api/api/user-api-keys/${id}`);

export const editUserApiKey = (id: TIdApiKey, data: TUserApiKeyUpdateModel): Promise<TCreateUserApiKeyResult> => put(`/api/api/user-api-keys/update/${id}`, data);

export const createUserApiKey = (data: TUserApiKeyUpdateModel): Promise<TCreateUserApiKeyResult> => post('/api/api/user-api-keys', data);

export const getUserApiKeyList = (filter: TUserApiKeyFilter): Promise<TApiKeyModelList> => get(`/api/api/user-api-keys${getParamsFromObject(filter)}`);

export const getUserApiKeyUsageData = (filter: TUserApiKeyUsageFilter): Promise<TUserApiKeyUsageDataList> => get(`/api/api/user-api-keys/usages${getParamsFromObject(filter)}`);

export const deleteExpansionNetwork = (userId: number): Promise<void> => del(`/api/api/account/${userId}/expansion-network`);

// REALIFY API
export const postQueryRealifyLicensee = (data): Promise<any> => postNApi(`/api/api/realify`, data);
export const postNotionDesignationTask = (data): Promise<any> => postNApi(`/api/api/notion/new-designation-task`, data);
export const postNotionMRAReviewTask = (data): Promise<any> => postNApi(`/api/api/notion/new-referral-review-task`, data);

// Skyflow API
import { ReactSession } from 'react-client-session';
export const getSkyflowBearerToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        const ajax = new XMLHttpRequest();
        ajax.onreadystatechange = () => {
            if (ajax.readyState === 4) {
                if (ajax.status === 200) {
                    resolve(ajax.responseText);
                } else {
                    reject(new Error(`HTTP STATUS: ${ajax.status}`));
                }
            }
        };
        ajax.onerror = (error) => {
            reject(error);
        };
        const jwt = ReactSession.get('jwt');
        const url = `${nodeDomainApi}/api/skyflow/authorize`;
        ajax.open('POST', url);
        ajax.setRequestHeader('Authorization', 'Bearer ' + jwt);
        ajax.send();
    });
};
export const postGetSkyflowIdW9 = (agentId: number): Promise<any> => postNApi(`/api/api/skyflow/w9/${agentId}`);
export const getAllSkyflowW9 = (agentId: number): Promise<any> => getNApi(`/api/api/skyflow/w9/${agentId}`);
export const getSkyflowW9DownloadUrl = (referringAgentId: number): Promise<any> => getNApi(`/api/api/skyflow/w9/download/${referringAgentId}`);

// Stripe API: /pay
export const getIsPaidUserId = (id: number): Promise<any> => getNApi(`/api/api/pay/is-paid/${id}`);
export const getCurrentSubscriptionByStripeId = (stripeId: string): Promise<any> => getNApi(`/api/api/pay/subscriptions/by-stripe-id/${stripeId}`);
export const getCurrentSubscriptionByEmail = (email: string): Promise<any> => getNApi(`/api/api/pay/subscriptions/by-email/${email}`);
export const getCustomerByEmail = (email: string): Promise<any> => getNApi(`/api/api/pay/customer-by-email/${email}`);
export const createCheckoutSessionByEmail = (email: string, id: number): Promise<any> => postNApi(`/api/api/pay/by-email/${email}/${id}`);
export const createCheckoutSessionByStripeId = (email: string, id: number, stripeId: string): Promise<any> => postNApi(`/api/api/pay/by-stripe-id/${email}/${id}/${stripeId}`);
export const createCheckoutSessionByEmailWithPsKey = (email: string, id: number, invitorPartnerstackKey: string): Promise<any> => postNApi(`/api/api/pay/by-email/${email}/${id}/${invitorPartnerstackKey}`);
export const createCheckoutSessionByStripeIdWithPsKey = (email: string, id: number, stripeId: string, invitorPartnerstackKey: string): Promise<any> => postNApi(`/api/api/pay/by-stripe-id/${email}/${id}/${stripeId}/${invitorPartnerstackKey}`);
export const getCheckoutSession = (id: string): Promise<any> => getNApi(`/api/api/pay/checkout-session/${id}`);
export const postSendUpgradeSuccessEmail = (data: any): Promise<any> => postNApi(`/api/api/pay/send-upgrade-success-email`, data);

// Dropbox-Sign (Hello-Sign) API
export const postDropboxSignEmbeddedSignatureRequest = (referral: TReferralModel, referralId: number, usStates: TUsStateList): Promise<any> => postNApi(`/api/api/dropbox-sign/signature-request/${referralId}`, {referral, referralId, usStates});
export const postDropboxSignEmbeddedSignatureRequestInbound = (referral: TReferralModel, referralId: number, usStates: TUsStateList): Promise<any> => postNApi(`/api/api/dropbox-sign/signature-request-for-inbound/${referralId}`, {referral, referralId, usStates});
export const getDropboxSignSignatureRequestById = (signatureRequestId: string, referralId: number): Promise<any> => getNApi(`/api/api/dropbox-sign/signature-request/${signatureRequestId}/${referralId}`);
export const getDropboxSignEmbeddedSignUrl = (signatureId: string, referralId: number): Promise<any> => getNApi(`/api/api/dropbox-sign/embedded-sign-url/${signatureId}/${referralId}`);
export const getDropboxSignSignatureFileUrl = (signatureRequestId: string, referralId: number): Promise<any> => getNApi(`/api/api/dropbox-sign/signature-file-url/${signatureRequestId}/${referralId}`);
export const getDropboxSignSignatureFileDownloadUrl = (signatureRequestId: string, referralId: number): Promise<any> => getNApi(`/api/api/dropbox-sign/signature-file-download-url/${signatureRequestId}/${referralId}`);

// Auth0-Mgmt API
export const putAuth0EmailVerified = (auth0Id: string, referralInvitationGuid: string, email: string): Promise<any> => putNApi(`/api/api/users/set-email-verified/${auth0Id}/${referralInvitationGuid}/${email}`);

// Partnerstack API
export const postCreatePartnerstackPartner = (data): Promise<any> => postNApi(`/api/api/partnerstack/create-partner`, data);
export const postCreatePartnerstackCustomer = (data): Promise<any> => postNApi(`/api/api/partnerstack/create-customer`, data);
export const postCreatePartnerstackTransaction = (data): Promise<any> => postNApi(`/api/api/partnerstack/create-transaction`, data);
export const postRetrievePartnerstackLink = (encodedEmail): Promise<any> => postNApi(`/api/api/partnerstack/retrieve-pslink/${encodedEmail}`);
export const getGMapsAddressForZipCode = (zipcode: string): Promise<any> => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "address": {
            "regionCode": "US",
            "addressLines": [zipcode]
        }
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    return fetch(
        `https://addressvalidation.googleapis.com/v1:validateAddress?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`,
        requestOptions
    );
};
