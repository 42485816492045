import React, { FunctionComponent } from "react";
import { WaitSpinner } from "../../../components/WaitSpinner";
import { WaitContainer } from "../../../components/WaitContainer";

type SaveAndContinueProps = {
    stepNumber: number;
    candidatesSelected: boolean;
    sendingReferral: boolean;
    onCancel: () => void;
    onPrevious: () => void;
    onContinue: () => void;
    onSendReferral: () => void;
};

const SaveAndContinue: FunctionComponent<SaveAndContinueProps> = ({
    stepNumber,
    candidatesSelected,
    sendingReferral,
    onCancel,
    onPrevious,
    onContinue,
    onSendReferral,
}) => {

    return (
        // <div className="self-stretch flex flex-col items-center justify-center gap-[16px] text-center text-link-blue border-t border-gray-400">
        <div className="self-stretch flex flex-row items-center justify-end">
            <div className="flex flex-1 flex-row items-center justify-end gap-[16px] pt-4 ">
                <WaitContainer waitSizePx={28} shouldWaitIf={sendingReferral}>
                    <div
                        className="relative tracking-[0.1px] leading-[24px] cursor-pointer text-link-blue hover:underline"
                        onClick={onCancel}
                        tabIndex={20}
                    >
                        Cancel
                    </div>

                    {stepNumber > 1 &&
                        <div>
                            <button
                                disabled={sendingReferral}
                                className={`${sendingReferral ? 'cursor-not-allowed bg-gray-200 text-white' : 'cursor-pointer bg-white text-secondary'} rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] border-gray-400 border-solid border`}
                                onClick={onPrevious}
                                tabIndex={19}
                            >
                                <div className="flex-1 relative text-base tracking-[0.1px] leading-[24px] font-[600] font-sans text-center">
                                    Previous
                                </div>
                            </button>
                        </div>
                    }

                    {(stepNumber === 5 && candidatesSelected)
                        ? <button
                                disabled={sendingReferral}
                                className={`${sendingReferral ? 'bg-red-200 cursor-wait ' : 'bg-red-600 cursor-pointer '} rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px]`}
                                onClick={onSendReferral}
                                tabIndex={18}
                            >
                                <div className="relative flex flex-row gap-[10px] items-center text-base tracking-[0.1px] leading-[24px] font-[600] font-sans text-light-background-background-1 text-center text-white">
                                    Send Referral
                                </div>
                            </button>

                        : <button
                            className="cursor-pointer bg-dark rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px]"
                            onClick={onContinue}
                            tabIndex={18}
                        >
                            <div className="flex-1 relative text-base tracking-[0.1px] leading-[24px] font-[600] font-sans text-center text-white">
                                Continue
                            </div>
                        </button>
                    }
                </WaitContainer>
            </div>
        </div>
    );
};

export default SaveAndContinue;
