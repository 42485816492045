import logger from './logger';

const handleNApiResponse = async (response: Response): Promise<unknown> => {
    console.log(response);

    if (response.status >= 200 && response.status <= 299) {
        
        const contentType = response?.headers.get('Content-Type');
        if (contentType?.startsWith('application/json')) {
            try {
                const json = await response.json();                
                return json;
            } catch (error: unknown) {
                logger.warn('handleApiResponse', 'Bad JSON.', await response.text());
                throw new Error('Failed to parse the server response.');
            }
        }
        else {
            try {
                const textResponse = await response.text();
                try {
                    const jsonResponse = JSON.parse(textResponse);
                    return jsonResponse;
                }
                catch (error) {
                    console.error('Not JSON. Returning Text: ', textResponse);
                    return textResponse;
                }
            } catch (error: unknown) {
                logger.warn('handleApiResponse', 'Bad text.');
                throw new Error('Failed to parse the server response.');
            }
        }
    }
    else {
        if (response.status === 401) {
            // const apiLogout = getApiLogout();
            // if (apiLogout) apiLogout();
            throw new Error('Unauthorized: Access is denied due to invalid credentials.');
        }
        else if (response.status === 403) {
            // const apiLogout = getApiLogout();
            // if (apiLogout) apiLogout();
            throw new Error('Error ' + response.status + ': Please update your application and try again.');
        }
        else {
            throw ({ status: response.status, message: (await response.json()).message});
        }
    }
    
    
};

export default handleNApiResponse;
