import ReactDOM from 'react-dom/client'
import reportWebVitals from './report-web-vitals';
import './main.css'
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
