import React, { FunctionComponent } from "react";

type Props = {
    onClick: (e: any) => any;
    canEdit: boolean;
    sizePx?: number;
    title?: string;
}
const EditPencilIcon: FunctionComponent<Props> = ({onClick, canEdit, sizePx = 24, title = '' }) => {
    return <div className="flex items-center">
        {canEdit &&
            // <img className="cursor-pointer" onClick={onClick} src="/img/mra-profile/iconpentosquare.svg" alt='Edit' width={sizePx} />
            <img className="cursor-pointer" title={title} onClick={onClick} src="/img/mra-profile/icon-pen.svg" alt='Edit' width={sizePx} />
        }
    </div>;
}

export { EditPencilIcon };
