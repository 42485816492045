import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

const Page404: FunctionComponent = () => {

    const navigateToDashboard = () => {
        location.href = "/";
    };

    return <div className='relative mt-[25px] w-full h-full flex flex-col xs:items-start lg:items-center'>
        <div className='relative h-full p-0 flex flex-col items-center justify-center text-21xl text-primary font-small-semibold gap-[20px]'>
            <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-center text-66xl pb-[20px]">
                <img src="/img/Page404.svg" />
            </div>
            <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-center  text-11xl leading-10 pb-[10px]">
                This page does not exist
            </div>
            <div className="self-stretch flex flex-row pt-0 px-0 pb-4 items-center justify-center  text-lg leading-10 text-link-blue cursor-pointer" onClick={navigateToDashboard}>
                Home Page
            </div>
        </div>
    </div>;
}

export { Page404 };
