import React, { FunctionComponent } from "react";
import {
    DIRECTION_INBOUND_STR,
    DIRECTION_OUTBOUND_STR,
    ReferralStatus,
    ReferralTimeFrame,
    ReferralTimeFrameStr,
    ReferralType,
    statusesInOpportunity,
} from "../../../types";
import { toCurrency } from "../../../utils/to-currency";
import useZustandAuthStore from "../../../_Zustand/auth-store";
import { getLocaleDateWithTzStrShort } from "../../../utils/get-locale-date-str";
import { getIsProduction } from "../../../utils/get-is-production";

type Props = {
    referral: any;
};
const ReferralActivityCard: FunctionComponent<Props> = ({ referral }) => {
    const { usStates } = useZustandAuthStore();
    return (
        <div
            className="rounded-md bg-colors-gs-white w-[281px] h-[230px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] "
            // title={`${referral.direction}, ID: ${referral.id}, Status: ${referral.status}`}
            title={`Click to Open.${getIsProduction() ? '': ` ${referral.direction}, ID: ${referral.id}, Status: ${referral.status}}`}`}
        >
            <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                    <div className="flex-1 flex flex-col items-start justify-center">
                        <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                            <div className="flex flex-row items-start justify-start gap-[6px]">
                                <img
                                    className="relative w-4 h-4"
                                    alt=""
                                    src={`/img/mra-dashboard-activity/${
                                            referral.direction === DIRECTION_OUTBOUND_STR
                                                ? "iconarrowupright"
                                                : "iconarrowdownleft2"
                                    }.svg`}
                                />
                                <div className="relative leading-[16px] font-black">
                                    {referral.type === ReferralType.buy
                                        ? `Buyer`
                                        : `Seller`}
                                </div>
                            </div>
                            {[
                                ReferralStatus.sentToAgent,
                                ReferralStatus.sentInvitation,
                            ].includes(referral.status) && (
                                <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                    <div className="relative font-semibold">
                                        Not yet accepted
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="self-stretch relative text-smi font-semibold">
                            {referral.direction === DIRECTION_INBOUND_STR &&
                            statusesInOpportunity.includes(referral.status) ? (
                                <i>Name Hidden</i>
                            ) : (
                                `${referral.clientFirstName} ${referral.clientLastName}`
                            )}
                        </div>
                    </div>
                    {/* <img
                        className="relative w-8 h-8"
                        alt=""
                        src="/img/mra-dashboard-activity/btnsecondary.svg"
                    /> */}
                </div>
                <div className="self-stretch relative text-xs capitalize leading-[17px]">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {/* {referral.type === ReferralType.buy
                        ? referral.direction === DIRECTION_OUTBOUND_STR
                            ? referral.buyLocations
                                  .map((l) => `${l.city}, ${usStates?.find((s) => s.id === l.stateId)?.abbreviation}`)
                                  .join(" - ")
                            : referral.buyLocations?.join(" - ")
                        : `${referral.sellCity}, ${usStates?.find(
                              (s) => s.id === referral.sellStateId,
                          )?.abbreviation}`
                    } */}
                    {referral.type === ReferralType.buy
                        ? referral.buyLocations
                                  .map((l) => `${l.city}, ${usStates?.find((s) => s.id === l.stateId)?.abbreviation}`)
                                  .join(" - ")
                        : `${referral.sellCity}, ${usStates?.find(
                              (s) => s.id === referral.sellStateId,
                          )?.abbreviation}`
                    }
                </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                    {referral.direction === DIRECTION_OUTBOUND_STR ? (
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Referred Agent
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                {referral.agentFirstName}{" "}
                                {referral.agentLastName}
                            </div>
                        </div>
                    ) : (
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Referring Agent
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                {referral.userFirstName} {referral.userLastName}
                            </div>
                        </div>
                    )}

                    <div className="self-stretch flex flex-col items-start justify-start">
                        {referral.type === ReferralType.buy ? (
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Budget
                            </div>
                        ) : (
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Target Price
                            </div>
                        )}
                        {referral.type === ReferralType.buy && (
                            <div className="relative text-xs leading-[17px] text-gray-800">
                                {toCurrency(referral.buyRangeMin)} -{" "}
                                {toCurrency(referral.buyRangeMax)}
                            </div>
                        )}
                        {referral.type === ReferralType.sell && (
                            <div className="relative text-xs leading-[17px] text-gray-800">
                                {referral.direction ===
                                    DIRECTION_OUTBOUND_STR &&
                                    toCurrency(referral.sellTargetPrice)}
                                {referral.direction === DIRECTION_INBOUND_STR &&
                                    toCurrency(referral.sellTargetPrice)}
                            </div>
                        )}
                    </div>
                    {referral.type === ReferralType.buy && (
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                Pre-Approved
                            </div>
                            {referral.clientPreapproved ? (
                                <img
                                    className="relative w-4 h-4 overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                />
                            ) : (
                                `No`
                            )}
                        </div>
                    )}
                </div>
                <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                    <div className="self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                            Submitted on
                        </div>
                        <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                            {getLocaleDateWithTzStrShort(referral.addedDate)}
                        </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                            Timeframe
                        </div>
                        <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                            {referral.type === ReferralType.buy &&
                                ReferralTimeFrameStr[
                                    ReferralTimeFrame[referral.buyTimeFrame]
                                ]}
                            {referral.type === ReferralType.sell &&
                                ReferralTimeFrameStr[
                                    ReferralTimeFrame[referral.sellTimeFrame]
                                ]}
                        </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                            Fee Percentage
                        </div>
                        <div className="relative text-xs leading-[17px] text-gray-800">
                            {referral.fee}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferralActivityCard;
