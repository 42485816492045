import React, { FunctionComponent } from "react";

type Props = {
    title?: string;
    description?: string;
    children?: React.ReactNode;
    lockIf?: boolean;
    horizontal?: boolean;
    onClick?: () => void;
}

const LockContainer: FunctionComponent<Props> = ({ title = "", description = "", children, lockIf = true, horizontal = false, onClick }) => {

    return <>
        {lockIf
            ? <div className="relative flex self-stretch grow group bg-transparent p-0">
                
                {children}
                
                <div className="flex rounded-lg absolute w-full h-full self-stretch top-[-6px] left-[-6px] opacity-[0.78] bg-black p-[7px]" />
                <div className={`flex ${horizontal?'flex-row':'flex-col'} absolute z-20 w-full h-full self-stretch top-0 bg-transparent justify-center items-center gap-[10px] font-thin text-colors-gs-white cursor-pointer`}  title="Click to Upgrade Now" onClick={() => {if (onClick) onClick();}}>
                    <img
                        className="relative w-[32.3px] h-[32.3px]"
                        alt=""
                        src="/img/mra-profile/productlock.svg"
                    />
                    {!!title &&
                        <b className="relative text-3xl-1 tracking-[0.26px] leading-[30px]">
                            {title}
                        </b>
                    }
                    {!!description &&
                        <div className="relative text-xl leading-[29.4px]">
                            {description}
                        </div>
                    }
                </div>
            </div>
            : <>
                {children}
            </> 
        }
        </>

};

export { LockContainer };