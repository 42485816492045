import React, { FunctionComponent, RefObject, useEffect, useState } from "react";
import Modal from 'react-modal';
import { NewReferralFormStoreType } from "../NewReferralOutbound";

type Step4AgreementPropsType = {
    formDataRef: RefObject<NewReferralFormStoreType>;
    onChangeFeePercent:(any) => void;
    onChangeAutogeneratedAgreement:(any) => void;
    refStep4: (RefObject<HTMLFormElement>);
};
const Step4Agreement: FunctionComponent<Step4AgreementPropsType> = ({
    formDataRef,
    onChangeFeePercent,
    onChangeAutogeneratedAgreement,
    refStep4,
}) => {

    // logger.info("\tRendering: Step4");
    const formDataStep = formDataRef.current?.form.agreement;
    console.log("Step4: FormDataStep: ", formDataStep);

    const [range, setRange] = useState<number>(25);

    const updateFeePercent = (event): void => {
        const value = parseFloat(event.target.value);
        setRange(value);
        onChangeFeePercent(event);
    };

    const [agreementPreviewType, setAgreementPreviewType] = useState<string>('');
    const [isAgreementPreviewModalOpen, setIsAgreementPreviewModalOpen] = useState<boolean>(false);
    const previewBuyerAgreement = () => {
        setAgreementPreviewType('BUYER');
        setIsAgreementPreviewModalOpen(true);
    };
    const previewSellerAgreement = () => {
        setAgreementPreviewType('SELLER');
        setIsAgreementPreviewModalOpen(true);
    };

    useEffect(() => {
        // preloading preview images
        try {
            const img1 = new Image();
                img1.src = '/img/mra-referral-agreement/ReferralAgreement-Buyer-FilledSample.jpg';
            
            const img2 = new Image();
                img2.src = '/img/mra-referral-agreement/ReferralAgreement-Seller-FilledSample.jpg';
        } catch (error) {
            console.error(error);
        }
    }, []);

    return <>
        <Modal
            isOpen={isAgreementPreviewModalOpen}
            contentLabel={`MRA Agreement Preview - ${agreementPreviewType}`}
            style={{
                overlay: {
                    zIndex: 1000,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                },
                content: {
                    margin: '0 auto',
                    borderRadius: '16px',
                    width: '1280px',
                },
            }}
            ariaHideApp={false}
        >
            <div className="flex flex-col self-stretch p-[10px] items-center justify-center gap-[20px]">
                    <div className="flex self-stretch items-end justify-end cursor-pointer">
                        <img
                            className="w-[30px] h-[30px]"
                            src="/img/mra-dashboard/close-search-button.svg"
                            onClick={() => {
                                setAgreementPreviewType('');
                                setIsAgreementPreviewModalOpen(false);
                            }}
                            
                        />
                    </div>
                    <div className="border border-radius flex flex-col w-[1280px] self-stretch items-center justify-center">
                        <h1 className="capitalize">Referral Agreement {agreementPreviewType.toLowerCase()} Sample</h1>
                        <img
                            src={agreementPreviewType === 'BUYER'
                                    ? '/img/mra-referral-agreement/ReferralAgreement-Buyer-FilledSample.jpg'
                                    : '/img/mra-referral-agreement/ReferralAgreement-Seller-FilledSample.jpg'
                            }
                            className="w-[1200px]"
                        />
                    </div>
                </div>
        </Modal>
        <form ref={refStep4} role="presentation" autoComplete="off">
            <div className="self-stretch flex-1 flex flex-row p-5 items-start justify-start text-base">
                <div className="self-stretch flex-1 flex flex-row py-0 pr-[145px] pl-0 items-start justify-start gap-[134px]">
                
                    <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[20px]">
                    
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            <span className="font-semibold">What is your Referral Fee?</span>
                            <span className="text-tertiary">*</span>
                        </div>
                        <div className="self-stretch flex-1 relative text-sm leading-[21px] text-tertiary flex items-center">
                            My Referral Agent takes no percentage of the referral fee and
                            plays no part in the delivery of the agreed upon commission.
                            Delivery and receipt of commission is the sole responsibility of
                            you and your referral agent.
                        </div>
                        <div className="text-5xl">{range}%</div>
                        <div className="flex flex-col w-full gap-0">
                            <input
                                type="range"
                                className="w-full h-2 bg-gray-200 rounded-lg cursor-pointer dark:bg-gray-700"
                                id="feeRange"
                                min={0}
                                max={100}
                                step={1}
                                defaultValue={formDataStep?.feePercent}
                                onChange={updateFeePercent}
                            />
                            <ul className="flex justify-between w-full px-[3px] text-sm">
                                <li className="flex justify-center relative"><span className="absolute">0%</span></li>
                                <li className="flex justify-center relative"><span className="absolute">20%</span></li>
                                <li className="flex justify-center relative"><span className="absolute">40%</span></li>
                                <li className="flex justify-center relative"><span className="absolute">60%</span></li>
                                <li className="flex justify-center relative"><span className="absolute">80%</span></li>
                                <li className="flex justify-center relative"><span className="absolute">100%</span></li>
                            </ul>
                        </div>
                    
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start gap-[20px]">
                        <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                            Referral Agreement
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                            <input
                                className="cursor-pointer relative w-4 h-4"
                                type="radio"
                                name="agreementType"
                                value="mra"
                                defaultChecked
                                onClick={onChangeAutogeneratedAgreement}
                                required
                            />
                            <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                                <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                    Use our Autogenerated Referral Agreement.
                                </div>
                                <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                    <span>{`This agreement will be sent to your selected agent(s) once they've accepted the referral. `}</span>
                                    <span className="flex flex-row gap-[10px]">
                                        <span>Agreement Samples: </span>
                                        <span className="text-link-blue cursor-pointer hover:underline" onClick={previewBuyerAgreement}>Buyer Agreement</span>
                                        <span>|</span>
                                        <span className="text-link-blue cursor-pointer hover:underline" onClick={previewSellerAgreement}>Seller Agreement</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Custom Agreement - Disabled for now */}
                        {false
                            && <>
                                <div className="hidden self-stretch flex flex-row items-start justify-start gap-[12px]">
                                    <input
                                    className="cursor-pointer relative w-4 h-4"
                                    type="radio"
                                    name="agreementType"
                                    value="custom"
                                    disabled
                                />
                                    <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                                        <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                            Upload a Custom Referral Agreement (disabled)
                                        </div>
                                        <div className="blur self-stretch relative text-sm leading-[21px] text-tertiary">
                                            This should include the client&apos;s name but not specific contact
                                            information.
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="self-stretch hidden flex-row pt-[18px] px-0 pb-0 items-start justify-center text-center text-lg text-tertiary">
                                    <div className="self-stretch rounded-md bg-surface-primary shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] box-border w-[326px] flex flex-row py-7 px-0 items-start justify-center border-[1px] border-solid border-surface-secondary">
                                        <div className="relative leading-[26px]">
                                            <p className="m-0">Upload signed</p>
                                            <p className="m-0">referral agreement</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </form>
    </>
};

export default Step4Agreement;
