import React, { FunctionComponent, RefObject, useEffect, useState } from "react";
import Select from "react-select";
import { InviteNonMember } from "./InviteNonMember";
import { ReferralType, TPublicProfileView } from "../../../types";
import type { AddressType, LocationType, NewReferralFormStoreType } from "../NewReferralOutbound";
import BuyerLocationsInput from "./BuyerLocationsInput";
import MemberSearchResults from "./MemberSearchResults";

// eslint-disable-next-line import/no-cycle
import { CandidateTypeEnum } from "../NewReferralOutbound";
import useZustandAuthStore from "../../../_Zustand/auth-store";
import { globalReactSelectTheme } from "../../../constants/react-select-theme";

type Step5CandidatesPropsType = {
    formDataRef: RefObject<NewReferralFormStoreType>;
    referralType: ReferralType;
    sellerAddress: AddressType | any;
    
    directSelectedAgent: TPublicProfileView;

    isInbound: boolean;

    buyerLocations: Array<LocationType>;
    onLocationsUpdated: (locations: Array<LocationType>) => void;

    onChangeAgentSelectionType: (any) => void;
    onChangeSearchCandidates: (any) => void;

    onChangeNonMraInviteeFirstName: (any) => void;
    onChangeNonMraInviteeLastName: (any) => void;
    onChangeNonMraInviteePhone: (any) => void;
    onChangeNonMraInviteeEmail: (any) => void;
    onEmailKeyUp: (any) => void;

    refStep5: (RefObject<HTMLFormElement>);
};
const Step5SelectCandidates: FunctionComponent<Step5CandidatesPropsType> = ({
    formDataRef,
    referralType,
    sellerAddress,

    directSelectedAgent,

    isInbound,

    buyerLocations,
    onLocationsUpdated,

    onChangeAgentSelectionType,
    onChangeSearchCandidates,

    onChangeNonMraInviteeFirstName,
    onChangeNonMraInviteeLastName,
    onChangeNonMraInviteePhone,
    onChangeNonMraInviteeEmail,
    onEmailKeyUp,

    refStep5,
}) => {
    
    // logger.info("\tRendering: Step5");
    const formDataStep = formDataRef.current?.form.candidateSelection;
    console.log("Step5: FormDataStep: ", formDataStep, ", isInbound: ", isInbound);

    const { usStates } = useZustandAuthStore();
    const [agentSelectionType, setAgentSelectionType] = useState<CandidateTypeEnum>(formDataStep?.candidateType as CandidateTypeEnum);

    const SEARCH_MEMBERS_TEXT = "Pick MRA Members";
    const INVITE_NON_MEMBERS_TEXT = "Invite a Non-Member Agent I Have In Mind";

    const agentSelectionOption_SearchMRAMembers = {label: SEARCH_MEMBERS_TEXT, value: CandidateTypeEnum.SEARCH_MRA_MEMBERS};
    const agentSelectionOption_InviteNewMember = {label: INVITE_NON_MEMBERS_TEXT, value: CandidateTypeEnum.INVITE_NON_MRA_MEMBERS};
    const agentSelectionOptions = [
        {...agentSelectionOption_SearchMRAMembers},
        {...agentSelectionOption_InviteNewMember},
    ];

    const updateAgentSelectionType = (selected): void => {
        setAgentSelectionType(selected.value);
        onChangeAgentSelectionType(selected);
        if (selected.value === CandidateTypeEnum.INVITE_NON_MRA_MEMBERS) {
            onChangeSearchCandidates([]);
        }
    };

    const formatSellerAddress = (sellerAddress: AddressType): string => {
        const { addressLine1, addressLine2, city, state: stateId, zipcode} = sellerAddress;
        return `${addressLine1}, ${addressLine2}, ${city}, ${usStates?.find((s) => s.id === stateId)?.abbreviation}, ${zipcode}`;
    }

    const [inboundValue, setInboundValue] = useState<boolean>(false);
    useEffect(() => {
        console.log("Setting inbound: ", isInbound);
        setInboundValue(isInbound);
        if (isInbound) {
            updateAgentSelectionType({...agentSelectionOption_InviteNewMember})
        }
    }, [isInbound])

    return (
        <form ref={refStep5} role="presentation" autoComplete="off">
            <div className="self-stretch flex flex-col p-5 items-start justify-start text-base gap-[20px]">
            
                <div className="relative w-full tracking-[0.1px] leading-[24px] flex flex-col gap-[5px]">
                    {referralType === ReferralType.buy
                    ? <>
                        <span>Buyer Location(s)</span>
                        <BuyerLocationsInput
                            key="searchMember.buyerinput"
                            onLocationsUpdated={onLocationsUpdated}
                            locations={buyerLocations}
                            required
                        />
                    </>
                    : <div className="flex flex-row gap-[12px] text-lg items-center">
                        <span className="font-semibold">Seller Address:</span>
                        <span className="p-[10px] rounded-md bg-stone-100 text-secondary">{formatSellerAddress(sellerAddress)}</span>
                    </div>
                }
                </div>
            
                {directSelectedAgent
                    ? <div className="text-5xl">Agent Selected: {directSelectedAgent.firstName} {directSelectedAgent.lastName}</div>
                    : <>
                        <div className="relative w-full tracking-[0.1px] leading-[24px] items-center flex flex-row gap-[20px]">
                            {inboundValue
                                ? <span className="font-semibold text-xl ml-[20px] mt-[20px]">This is an Inbound Referral. You can only invite a new MRA member.</span>
                                : <>
                                    <span className="font-semibold text-lg">{/*Select the agent(s)*/}Who will receive this referral? *</span>
                                    <Select
                                        className="w-[400px]"
                                        onChange={updateAgentSelectionType}
                                        theme={globalReactSelectTheme}
                                        options={[...agentSelectionOptions]}
                                        defaultValue={{...agentSelectionOption_SearchMRAMembers}}
                                        required
                                    />
                                </>
                            }
                        </div>

                        {agentSelectionType === CandidateTypeEnum.SEARCH_MRA_MEMBERS
                            ? <MemberSearchResults
                                referralType={referralType}
                                buyerLocations={ [ ...buyerLocations ]}
                                onChangeSearchCandidates={onChangeSearchCandidates}
                            />
                            : <InviteNonMember
                                formDataRef={formDataRef}
                                onChangeNonMraInviteeFirstName={onChangeNonMraInviteeFirstName}
                                onChangeNonMraInviteeLastName={onChangeNonMraInviteeLastName}
                                onChangeNonMraInviteePhone={onChangeNonMraInviteePhone}
                                onChangeNonMraInviteeEmail={onChangeNonMraInviteeEmail}
                                onEmailKeyUp={onEmailKeyUp}
                            />
                        }
                    </>
                }
            </div>
        </form>
    );
};

export default Step5SelectCandidates;
