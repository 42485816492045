import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

const Logout: React.FunctionComponent = () => {

    const { logout } = useAuth0();
    
    useEffect(() => {
        console.log("Logout();");
        logout({logoutParams: {returnTo: window.location.origin}});
    }, []);

    return <></>
};

export { Logout };
