import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { WaitContainer } from '../../components/WaitContainer';
import { getProfileUserData, postAccountRegister, postProfileLicenses, postQueryRealifyLicensee, putStateLicense } from '../../utils/api';
import { LicenseStatus, TLicensePayload, TPartialRegisterUserModel, TProfileUserData, TUsState } from '../../types';
import useZustandAuthStore from '../../_Zustand/auth-store';
import { globalReactSelectTheme } from '../../constants/react-select-theme';

type Props = {
    mainLogout: () => void;
};

export const numberRange = function(a, b, step){
    var A: number[] = [];
    A[0] = a;
    step = step || 1;
    while(a+step <= b){
        A[A.length]= a+= step;
    }
    return A;
}

const Registration: React.FunctionComponent<Props> = ({ mainLogout }) => {
    const { authState, setAuthState, usStates } = useZustandAuthStore();
    const { isAuthenticated, user } = useAuth0();

    const doLogout = () => {
        setIsRegisteringAgent(true);
        mainLogout();
    }

    const [isSocialGoogle, setIsSocialGoogle] = useState<boolean>(false);
    const [isUserCheckLoading, setIsUserCheckLoading] = useState<boolean>(false);
    const [isUserAlreadyExists, setIsUserAlreadyExists] = useState<boolean>(false);
    const [isValidatingForm, setIsValidatingForm] = useState<boolean>(false);
    const [isRegisteringAgent, setIsRegisteringAgent] = useState<boolean>(false);
    const [registrationMessage, setRegistrationMessage] = useState<string>('');

    const usStatesList = usStates as Array<TUsState>;
    const stateOptions = Object.values(usStatesList).map((s) => {
        return { label: s.name, value: s.id, obj: s };
    });
    const selectStyle = {
        control: (base) => ({
            ...base,
            height: 35,
            minHeight: 35,
        }),
    };
    const licenseYearOptions = numberRange(1900, new Date().getFullYear(), 1).reverse().map((s) => {
        return { label: s, value: s };
    });

    type RegistrationFormDataType = {
        form: {
            firstName: string;
            lastName: string;
            brokerageName: string;
            phone: string;
            email: string;
        };
    };
    const initialRegistrationFormData = (): any => {
        return Object.create({
            form: {
                firstName: '',
                lastName: '',
                brokerageName: '',
                phone: '',
                email: '',
                licenseStateId: -1,
                licenseNumber: '',
                licenseYear: -1,
            },
        });
    };

    type LicenseFormDataType = {
        form: {
            licenseNumber: string,
            stateLicensed: TUsState,
            yearLicensed: number,
        };
    };
    const initialLicenseFormData = (): any => {
        return Object.create({
            form: {
                licenseNumber: -1,
                stateLicensed: {},
                yearLicensed: -1,
            },
        });
    };

    const registrationFormDataRef = useRef<RegistrationFormDataType>(initialRegistrationFormData());
    const licenseFormDataRef = useRef<LicenseFormDataType>(initialLicenseFormData());
    const formSignUpRef = useRef<HTMLFormElement>(null);

    const onChangeFirstName = (event?): void => {
        if (user?.sub?.includes('google-oauth2') as boolean) {
            
            registrationFormDataRef.current.form.firstName = user?.given_name as string;
            return;
        }

        const { value } = event.target;
        registrationFormDataRef.current.form.firstName = value;
    };

    const onChangeLastName = (event?): void => {
        if (user?.sub?.includes('google-oauth2') as boolean) {
            
            registrationFormDataRef.current.form.lastName = user?.family_name as string;
            return;
        }

        const { value } = event.target;
        registrationFormDataRef.current.form.lastName = value;
    };

    const onChangeEmail = (event?): void => {
        if (user?.sub?.includes('auth0') as boolean) {
            registrationFormDataRef.current.form.email = user?.email as string;
            return;
        }

        if (user?.sub?.includes('google-oauth2') as boolean) {
            registrationFormDataRef.current.form.email = user?.email as string;
            return;
        }

        const { value } = event.target;
        const clientEmail = value;
        if (clientEmail === '') {
            return;
        }
        if (!event.target.checkValidity()) {
            event.target.reportValidity();
        }
    };

    const onChangeBrokerageName = (event): void => {
        const { value } = event.target;
        registrationFormDataRef.current.form.brokerageName = value;
    };

    const toPhoneFormat = (value): string => {
        return value.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    };
    const ensurePhoneFormat = (event): void => {
        const { value } = event.target;
        event.target.setCustomValidity(``);
        event.target.value = toPhoneFormat(value);
    };    
    const onChangePhoneNumber = (event): void => {
        const phone = event.target.value;
        registrationFormDataRef.current.form.phone = phone;
    };

    const onChangeLicenseState = (select): void => {
        const { value, obj } = select;
        licenseFormDataRef.current.form.stateLicensed = obj;
    };
    
    const onChangeLicenseNumber = (event): void => {
        const { value } = event.target;
        licenseFormDataRef.current.form.licenseNumber = value;
    };
    
    const onChangeLicenseYear = (select): void => {
        const { value } = select;
        licenseFormDataRef.current.form.yearLicensed = value;
    };

    const ensureGoogleDataFilled = (): void => {
        onChangeFirstName();
        onChangeLastName();
        onChangeEmail();
    };

    const onValidateForm = (): boolean => {
        if (isSocialGoogle) {
            ensureGoogleDataFilled();
        }

        if (!formSignUpRef.current?.checkValidity()) {
            formSignUpRef.current?.reportValidity();
            return false;
        }
        return true;
    };

    const [ isTermsAccepted, setIsTermsAccepted ] = useState<boolean>(false);
    const handleTermsAcceptedCheckbox = (e) => {
        const isChecked = e.target.checked;
        setIsTermsAccepted(isChecked);
    };
    const termsOfServiceUrl = "https://www.myreferralagent.com/privacy-policy-and-terms-of-service";
    const privacyPolicyUrl = "https://www.myreferralagent.com/privacy-policy-and-terms-of-service";
    useEffect(() => {
        if (isTermsAccepted) {
            toast.dismiss();
        }
    }, [isTermsAccepted])

    const addLicense = (id, firstName, lastName, email, callback, errorCallback): void => {
        const data: TLicensePayload = {
            licenseNumber: licenseFormDataRef.current.form.licenseNumber,
            stateLicensed: usStatesList.find((s) => s.id === licenseFormDataRef.current.form.stateLicensed.id) as TUsState,
            yearLicensed: licenseFormDataRef.current.form.yearLicensed as number,
        };

        // save license to database
        postProfileLicenses(id, data)
            .then((result) => {
                console.log(result);

                const userStateLicenseId = result;
                const licenseNumber = data.licenseNumber;
                const state = data.stateLicensed.abbreviation;

                // determine license is legit
                postQueryRealifyLicensee({ licenseNumber, state, firstName, lastName, email, dbUserStateLicenseId: userStateLicenseId, dbUserId: id })
                .then((result) => {
                    console.log(result);

                    let licenseVerified: boolean = false;
                    let yearLicensed: number | undefined = undefined;
                    let licenseExpiryDate: Date | undefined = undefined;
                    let verificationUrl: string = '';

                    if (result === 'INNER_CIRCLE_AUTO_APPROVED') {
                        verificationUrl = 'INNER_CIRCLE_AUTO_APPROVED';
                        licenseVerified = true;
                    }
                    else if (!!result.licenseNumber && result.licenseNumber === licenseNumber) {
                        verificationUrl = 'REALIFY_API';
                        yearLicensed = data.yearLicensed || -1; // || (result.issued ? parseInt(result.issued.split("-")[0], 10) : -1);
                        licenseExpiryDate = result.expires ? new Date(result.expires) : undefined;
                        licenseVerified = true;
                    }
                    else {
                        licenseVerified = false;
                    }

                    if (licenseVerified) {

                        // update verified flag if auto-verified
                        putStateLicense(userStateLicenseId, {
                            status: LicenseStatus.verified,
                            verificationUrl,
                            yearLicensed,
                            licenseExpiryDate,
                        })
                        .then((result) => {
                            console.log(result);
                            callback();
                        })
                        .catch((error) => {
                            console.error(error);
                            errorCallback(error);
                        });   
                    }
                    else {
                        callback();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    errorCallback(error);
                });
            })
            .catch((error) => {
                console.error(error);
                errorCallback(error);
            });
    };
    const register = (): void => {

        if (!isTermsAccepted) {
            toast.error("Please accept the Privacy Policy and Terms of Service");
            return;
        }

        setIsRegisteringAgent(true);
        setRegistrationMessage('Registering ....');
        if (!onValidateForm()) {
            setIsRegisteringAgent(false);
            return;
        }

        if (user?.sub?.includes('auth0') as boolean) {
            registrationFormDataRef.current.form.email = user?.email as string;
        }

        if (user?.sub?.includes('google-oauth2') as boolean) {
            registrationFormDataRef.current.form.firstName = user?.given_name as string;
            registrationFormDataRef.current.form.lastName = user?.family_name as string;
        }

        const { firstName, lastName, email, phone, brokerageName } = registrationFormDataRef.current.form;
        const registerData: TPartialRegisterUserModel = { firstName, lastName, email, phone, brokerageName };

        // handle partnerstack links stored in growsumo cookie
        // TODO: Does not work across browsers/incognito/devices the cookie is lost
        if (window["growsumo"] && window["growsumo"].data) {
            console.log("Invited by: ", window["growsumo"].data);

            const partnerKey = window["growsumo"].data.partner_key;
            registerData["invitedByPartnerKey"] = partnerKey;
        }

        postAccountRegister(registerData)
            .then((result) => {

                getProfileUserData(false)
                    .then(async (profileUserData: TProfileUserData) => {
                        console.log(profileUserData);
                        const { id } = profileUserData;

                        const auth = { id, user, apiToken: authState.apiToken, idTokenClaims: authState.idTokenClaims };

                        setAuthState({ ...auth, profileUserData }); // for zustand state mgmt

                        // adding license and verifying automatically
                        addLicense(
                            id,
                            firstName,
                            lastName,
                            email,
                            () => {
                                toast.info("Successfully Registered and Added License.");
                                setRegistrationMessage('Registration Successful!!!');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 3000);
                            },
                            (error) => {
                                console.error(error);
                                console.error(new Error(`License Verification Issue: ${user?.email} - ${firstName} - ${lastName} - UserID: ${id}`));
                                toast.warn("Successfully Registered but something went wrong with adding/verifying your license! Please reach out to us for help.");
                                setRegistrationMessage('Registration Successful!!!');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 4000);
                            },
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        setIsRegisteringAgent(false);
                    });
            })
            .catch((error) => {
                console.error(error);
                toast.error("Something went wrong. Please try again later or reach us at support@myreferralagent.com.");
                setIsRegisteringAgent(false);
            });
    };

    const [invitorDetails, setInvitorDetails] = useState<any>(null);
    useEffect(() => {
        if (isAuthenticated) {

            if (user?.sub?.includes('auth0') as boolean) {
                registrationFormDataRef.current.form.email = user?.email as string;
            }

            if (user?.sub?.includes('google-oauth2') as boolean) {
                setIsSocialGoogle(true);
                
                registrationFormDataRef.current.form.firstName = user?.given_name as string;
                registrationFormDataRef.current.form.lastName = user?.family_name as string;
            }

            setIsUserCheckLoading(true);
            getProfileUserData(false)
                .then((profileUserData: TProfileUserData) => {
                    const { id, invitedById, invitedByFirstName, invitedByLastName } = profileUserData;
                    setIsUserCheckLoading(false);
                    setIsUserAlreadyExists(false);
                    window.location.reload();
                })
                .catch((error) => {
                    if (error.message === 'User not found') {                        

                        setIsUserCheckLoading(false);
                        setIsUserAlreadyExists(false);

                        // get the Referral if it was an invitation
                        // this will not work since there's no auth0 id set for the user
                        // getAccountInvitationalPreRegister()
                        //     .then((_invitorDetails: any) => {
                        //         console.log(_invitorDetails);

                        //         setInvitorDetails(_invitorDetails);
                        //         setIsUserCheckLoading(false);
                        //         setIsUserAlreadyExists(false);
                        //     })
                        //     .catch((error) => {
                        //         console.error(error);
                        //         // setIsUserCheckLoading(false);
                        //         // setIsUserAlreadyExists(false);
                        //         // window.location.reload();
                        //         toast.error("Something went wrong!");
                        //     });

                        // but can be done for partnerstack link
                    }
                    else {
                        console.error(error);
                    }
                });
        }
    }, []);

    return (
        <div className="flex flex-grow items-top justify-center ">
            <div className="w-full flex flex-row xs:flex-col lg:flex-row justify-center">
                
                {/* left pane */}
                <div className="xs:hidden lg:flex flex-1 flex-col justify-top pr-[30px] py-[10px] bg-brown">
                    <div className="ml-[30px] flex flex-col items-center justify-between text-white">
                        <div className="flex flex-1 flex-col xs:px-[16px] xs:py-[50px] lg:pt-[113px] items-center justify-between text-white bg-brown">
                            <div className="xs:w-fit lg:w-[450px] flex flex-col justify-center items-center gap-[50px]">

                                <div className="relative xs:text-21xl lg:text-37xl xs:leading-[40px] lg:leading-[62px] inline-block">
                                    Send, receive and track referrals with some of the best real estate
                                    agents on earth
                                </div>

                                <div className="self-stretch relative text-lg leading-[25px] font-dm-sans justify-start">
                                    We&apos;d love for you to join us!
                                </div>

                                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] font-dm-sans">
                                    <div className="flex flex-row items-center justify-start">
                                        <div className="relative rounded-21xl w-9 h-9">
                                            {/* <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white" /> */}
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image1@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image2@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image3@2x.png"
                                            />
                                        </div>
                                    </div>
                                    <div className="relative leading-[150%] font-sm">
                                        {/* Join 2.5M+ users */}
                                        Be part of an elite group of referrers!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Column: Sign Up Form */}
                <div className='flex flex-1 flex-col pt-[60px] xs:px-[20px] lg:w-[450px] items-center justify-center bg-surface-secondary text-secondary'>

                    <WaitContainer waitSizePx={100} shouldWaitIf={isUserCheckLoading}>
                        {(isAuthenticated && !isUserAlreadyExists && !isUserCheckLoading) && (
                            <WaitContainer shouldWaitIf={isValidatingForm || isRegisteringAgent} message={registrationMessage}>                                
                                <div className='lg:w-[450px] flex flex-col justify-center gap-[24px] '>
                                    <form ref={formSignUpRef} className='flex flex-col items-start justify-start gap-[30px]'>

                                        <div className='text-5xl font-bold'>Complete your registration with MRA</div>
                                        
                                        {/* Email */}
                                        {/* <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>                                         */}
                                            {isSocialGoogle
                                                ? <div className='flex flex-col gap-[3px]'>
                                                    <div className='relative text-xl font-small-medium rounded-2xl text-left'>
                                                        <img alt='' src={user?.picture} className='ml-3 float-right rounded-md shadow-md w-[45px] h-[45px]' />
                                                        <div className='font-semibold relative text-xl text-colors-gs-brand-secondary text-left'>
                                                            You have successfully logged in with your Google Account.
                                                        </div>
                                                    </div>
                                                    <input
                                                        className='font-small-medium text-base bg-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                        value={isSocialGoogle ? user?.email : ''}
                                                        name='email'
                                                        type='hidden'
                                                        readOnly={isSocialGoogle}
                                                    />
                                                    <div className='h-[10px]'></div>
                                                    <div className='flex flex-row flex-wrap gap-[5px] lg:items-center'>
                                                        <div className="ml-[-8px] flex w-fit rounded-md px-2 py-1 bg-surface-primary font-semibold">
                                                            {user?.email}
                                                        </div>
                                                        <div className='flex flex-row gap-0 items-center'>
                                                            (<div
                                                                    className='cursor-pointer text-link-blue'
                                                                    title='Use another email?'
                                                                    onClick={(e) => {
                                                                        if (!formSignUpRef.current?.checkValidity) {
                                                                            e.preventDefault();
                                                                        }
                                                                        doLogout();
                                                                    }}
                                                                >
                                                                Logout
                                                                </div>)
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className='flex flex-col gap-[3px]'>
                                                    <input
                                                        className='font-small-medium text-base bg-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                        value={user?.email ?? ''}
                                                        name='email'
                                                        type='hidden'
                                                        readOnly={isSocialGoogle}
                                                    />
                                                    <div className='flex flex-row'>
                                                        <div className='font-semibold relative text-xl text-colors-gs-brand-secondary text-left'>
                                                            You have successfully logged in with your email address.
                                                        </div>
                                                        {/* <img alt='' src={user?.picture} className='ml-3 rounded-md shadow-md w-[55px] h-[55px]' /> */}
                                                    </div>
                                                    <div className='h-[10px]'></div>
                                                    <div className='flex flex-row flex-wrap gap-[5px] lg:items-center'>
                                                        <div className="ml-[-8px] flex w-fit rounded-md px-2 py-1 bg-surface-primary font-semibold">
                                                            {user?.email}
                                                        </div>
                                                        <div className='flex flex-row gap-0 items-center'>
                                                            (<div
                                                                    className='cursor-pointer text-link-blue'
                                                                    title='Use another email?'
                                                                    onClick={(e) => {
                                                                        if (!formSignUpRef.current?.checkValidity()) {
                                                                            e.preventDefault();
                                                                        }
                                                                        doLogout();
                                                                    }}
                                                                >
                                                                Logout
                                                                </div>)
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        {/* </div> */}

                                        {/* Text Content: To receive or send .. blah blah */}
                                        <div className='flex flex-col items-end justify-start gap-[20px]'>
                                            <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-small-medium text-colors-gs-brand-secondary text-left'>
                                                To receive or accept a referral from someone you know, we just need a few more things from you.
                                            </div>

                                            {(invitorDetails && invitorDetails.invitedByFirstName && invitorDetails.invitedByLastName)
                                                && <div className='text-sm text-secondary rounded-xl bg-white p-[20px]'>
                                                    You were invited by {invitorDetails.invitedByFirstName} {invitorDetails.invitedByLastName}.
                                                    <br /><br />
                                                    Some fields below are pre-filled with values provided by {invitorDetails.invitedByFirstName} {invitorDetails.invitedByLastName} when they referred you.
                                                    <br /><br />
                                                    Please feel free to update any fields with the correct values.
                                                </div>}
                                        </div>

                                        {/* First Name */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>First Name</b>
                                            <>
                                                {(invitorDetails && invitorDetails.agentFirstName)
                                                    ? <>
                                                        <input
                                                            className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                            name='firstName'
                                                            type='text'
                                                            defaultValue={invitorDetails.agentFirstName}
                                                            onBlur={onChangeFirstName}
                                                            required
                                                        />
                                                        <i className='text-sm'>First Name was provided by {invitorDetails.invitedByFirstName} {invitorDetails.invitedByLastName}</i>
                                                    </>
                                                    : <input
                                                        className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                        name='firstName'
                                                        type='text'
                                                        defaultValue={isSocialGoogle ? user?.given_name : ''}
                                                        onBlur={onChangeFirstName}
                                                        required
                                                    />
                                                }
                                            </>
                                        </div>

                                        {/* Last Name */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Last Name</b>
                                            <>
                                                {(invitorDetails && invitorDetails.agentFirstName)
                                                    ? <>
                                                        <input
                                                            className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                            name='lastName'
                                                            type='text'
                                                            defaultValue={invitorDetails.agentLastName}
                                                            onBlur={onChangeLastName}
                                                            required
                                                        />
                                                        <i className='text-sm'>Last Name was provided by {invitorDetails.invitedByFirstName} {invitorDetails.invitedByLastName}</i>
                                                    </>
                                                    : <input
                                                            className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                            name='lastName'
                                                            type='text'
                                                            defaultValue={isSocialGoogle ? user?.family_name : ''}
                                                            onBlur={onChangeLastName}
                                                            required
                                                        />
                                                    }
                                                </>
                                        </div>


                                        {/* Phone Number */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Cell Phone Number</b>
                                            {(invitorDetails && invitorDetails.invitorProvidedAgentCellPhoneNumber)
                                                ? <>
                                                    <input
                                                        className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                        type='tel'
                                                        pattern='^\d{3}-\d{3}-\d{4}$'
                                                        defaultValue={toPhoneFormat(invitorDetails.invitorProvidedAgentPhoneNumber)}
                                                        onBlur={onChangePhoneNumber}
                                                        onKeyUp={ensurePhoneFormat}
                                                        ref={(c) => c?.setAttribute("setCustomValidity", `Please input a valid US phone number (10 digits)`)}
                                                        onInvalid={(e) => e.target["setCustomValidity"](`Please input a valid US phone number (10 digits)`)}                                                    
                                                        required
                                                    />
                                                    <i className='text-secondary text-sm'>Cell Phone Number was provided by {invitorDetails.invitedByFirstName} {invitorDetails.invitedByLastName}</i>
                                                </>
                                                : <input
                                                    className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                    type='tel'
                                                    pattern='^\d{3}-\d{3}-\d{4}$'
                                                    onBlur={onChangePhoneNumber}
                                                    onKeyUp={ensurePhoneFormat}
                                                    ref={(c) => c?.setAttribute("setCustomValidity", `Please input a valid US phone number (10 digits)`)}
                                                    onInvalid={(e) => e.target["setCustomValidity"](`Please input a valid US phone number (10 digits)`)}                                                    
                                                    required
                                                />
                                            }
                                        </div>

                                        {/* Brokerage Name */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Brokerage Name</b>
                                            <input
                                                className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                type='text'
                                                pattern='[a-zA-Z0-9\-\x27\s]{3,50}'
                                                onChange={(e) => e.target.setCustomValidity('')}
                                                onBlur={onChangeBrokerageName}
                                                required
                                            />
                                        </div>

                                        {/* License State */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Primary Licensed State</b>
                                            <Select
                                                className='w-full'
                                                styles={selectStyle}
                                                theme={globalReactSelectTheme}
                                                options={stateOptions}
                                                onChange={onChangeLicenseState}
                                                onFocus={e => {
                                                    if (e.target.autocomplete) {
                                                        e.target.autocomplete = "new-password";
                                                    }
                                                }}
                                                required
                                            />
                                        </div>

                                        {/* License Number */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>License ID/Number</b>
                                            <input
                                                className='font-small-medium text-base bg-colors-gs-white self-stretch rounded-8xs overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-colors-gray-400'
                                                onBlur={onChangeLicenseNumber}
                                                type='text'
                                                required
                                            />
                                        </div>

                                        {/* License Year */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>Primary License Year</b>
                                            <Select
                                                className='w-full'
                                                styles={selectStyle}
                                                theme={globalReactSelectTheme}
                                                options={licenseYearOptions}
                                                onChange={onChangeLicenseYear}
                                                required
                                            />
                                        </div>

                                        {/* Referral Agreement Selection */}
                                        {false
                                            && <div className='hidden xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                                <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>
                                                    Referral Agreement
                                                    <span className='ml-[10px] text-xs tracking-[0.26px] leading-[18px] font-medium font-small-medium text-link-blue'>Learn More</span>
                                                </b>
                                                <div className='self-stretch flex flex-row items-center justify-start'>
                                                    <div className='flex-1 flex flex-col items-start justify-start gap-[8px]'>
                                                        <div className='py-2 px-2.5 bg-colors-gs-white self-stretch rounded-3xs flex flex-col items-start justify-start gap-[10px] border-[1px] border-solid border-darkgray'>
                                                            <div className='flex flex-row items-center justify-center gap-[10px]'>
                                                                <label className='cursor-pointer flex flex-row items-center justify-center gap-[10px] relative text-sm leading-[21px] font-medium font-small-medium text-colors-gs-brand-secondary text-left'>
                                                                    <input className='relative w-4 h-4' type='radio' name='agreementTypeRadio' value='mra' />
                                                                    Use MRA&apos;s
                                                                </label>
                                                            </div>
                                                            <div className='self-stretch flex flex-row items-center justify-start'>
                                                                <div className='flex-1 flex flex-row items-start justify-start gap-[8px]'>
                                                                    <label className='relative text-xs leading-[17px] font-small-medium text-colors-gray-700 text-left'>Powered by</label>
                                                                    <img className='relative w-[104px] h-[16.55px] object-cover' alt='' src='/img/mra-reg-1/image-132@2x.png' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='self-stretch flex flex-row items-center justify-center gap-[4px]'>
                                                            <div
                                                                className='relative text-sm leading-[21px] font-small-medium text-link-blue text-center cursor-pointer'
                                                                onClick={() => {
                                                                    toast.info('not implemented');
                                                                }}
                                                            >
                                                                Preview Agreement
                                                            </div>
                                                            <img className='relative w-6 h-6 overflow-hidden shrink-0' alt='' src='/img/mra-reg-1/iconfileimport.svg' />
                                                        </div>
                                                    </div>
                                                    <button className='[border:none] pt-[22px] px-2.5 pb-0 bg-[transparent] self-stretch rounded-3xs flex flex-row items-start justify-center'>
                                                        <label className='relative text-sm leading-[21px] font-medium font-small-medium text-colors-gs-brand-secondary text-center'>OR</label>
                                                    </button>
                                                    <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[10px]'>
                                                        <div className='py-2 px-2.5 bg-colors-gs-white self-stretch rounded-3xs box-border h-16 flex flex-col items-start justify-start gap-[10px] border-[1px] border-solid border-darkgray'>
                                                            <div className='flex flex-row items-center justify-center gap-[10px]'>
                                                                <label className='flex flex-row items-center justify-center gap-[10px] cursor-pointer relative text-sm leading-[21px] font-medium font-small-medium text-colors-gs-brand-secondary text-left'>
                                                                    <input className='relative w-4 h-4' type='radio' name='agreementTypeRadio' value='custom' />
                                                                    Upload my own
                                                                </label>
                                                            </div>
                                                            <div className='self-stretch flex flex-row items-center justify-start'>
                                                                <div
                                                                    className='relative text-xs leading-[17px] font-small-medium text-colors-gray-700 text-left'
                                                                    onClick={() => {
                                                                        toast.info('not implemented');
                                                                    }}
                                                                >
                                                                    Manual Signing Process
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='self-stretch flex flex-row items-center justify-center gap-[4px]'>
                                                            <div
                                                                className='relative text-sm leading-[21px] font-small-medium text-link-blue text-center cursor-pointer'
                                                                onClick={() => {
                                                                    toast.info('not implemented');
                                                                }}
                                                            >
                                                                Preview Agreement
                                                            </div>
                                                            <img className='relative w-6 h-6 overflow-hidden shrink-0' alt='' src='/img/mra-reg-1/iconfileimport.svg' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* W-9 Uploader */}
                                        {false
                                            && <div className='hidden xs:w-[330px] lg:w-[450px] flex flex-col items-start justify-start gap-[4px]'>
                                            <b className='relative text-base tracking-[0.1px] leading-[24px] font-small-medium text-colors-gs-brand-secondary text-left'>
                                                Upload Your W-9
                                                <span className='ml-[10px] text-xs tracking-[0.26px] leading-[18px] font-medium font-small-medium text-link-blue'>Learn More</span>
                                            </b>
                                            <div className='w-[205px] flex flex-col items-center justify-center gap-[10px]'>
                                                <button className='cursor-pointer py-2 px-2.5 bg-colors-gs-white rounded-3xs box-border w-[205px] flex flex-row items-center justify-center border-[1px] border-solid border-darkgray'>
                                                    <div className='flex flex-row items-center justify-center gap-[10px]'>
                                                        <img className='relative w-9 h-9 overflow-hidden shrink-0' alt='' src='/img/mra-reg-1/iconuploadthin1.svg' />
                                                        <label className='cursor-pointer relative text-sm leading-[21px] font-medium font-small-medium text-colors-gs-brand-secondary text-center'>
                                                            Upload W-9
                                                        </label>
                                                    </div>
                                                </button>
                                                <div className='flex flex-row items-center justify-center gap-[4px]'>
                                                    <div
                                                        className='relative text-sm leading-[21px] font-small-medium text-link-blue text-center cursor-pointer'
                                                        onClick={() => {
                                                            toast.info('not implemented yet');
                                                        }}
                                                    >
                                                        Preview W-9
                                                    </div>
                                                    <img className='relative w-6 h-6 overflow-hidden shrink-0' alt='' src='/img/mra-reg-1/iconfileimport.svg' />
                                                </div>
                                            </div>
                                            </div>
                                        }

                                        {/* Accept Terms & Conditions */}
                                        <div className='xs:w-[330px] lg:w-[450px] flex flex-row items-center justify-start gap-[28px]'>
                                            <input className="cursor-pointer" type="checkbox" onClick={handleTermsAcceptedCheckbox} />
                                            <div className='flex flex-row flex-wrap items-center gap-[5px] text-xl'>
                                                <span>I agree to the</span>
                                                <a className="text-link-blue no-underline hover:underline" target="_blank" href={privacyPolicyUrl}>
                                                    Privacy Policy and Terms of Service
                                                </a>
                                            </div>
                                        </div>

                                        {/* Sign Up Button */}
                                        <div
                                            className={`${isTermsAccepted ? 'bg-colors-gs-brand-secondary cursor-pointer' : 'bg-gray-800 cursor-not-allowed'} xs:w-[330px] lg:w-[450px] mt-[25px] w-full h-[50px] [border:none] py-2 px-4 rounded-md flex flex-row box-border items-center justify-center gap-[20px]`}
                                            autoFocus={false}
                                            onClick={register}
                                            title={`${isTermsAccepted ? '' : 'Please accept the Privacy Policy and Terms of Service.'}`}
                                        >
                                            <div className='relative text-base tracking-[0.1px] leading-[24px] font-semibold font-small-medium text-colors-gs-white text-center'>Sign Up</div>
                                        </div>

                                        <div className='h-[70px]' />
                                    </form>
                                </div>
                            </WaitContainer>
                        )}
                    </WaitContainer>
                </div>
            </div>
        </div>
    );
};

export { Registration };
