import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AnonymousNavbar from './AnonymousNavBar';
import { AgentProfilePublic } from '../_Authenticated/AgentProfile/AgentProfilePublic';
import { Page404 } from '../components/Page404/Page404';
import { Logout } from '../_Authenticated/Logout';

const Anonymous: React.FunctionComponent = () => {

    return <div className="w-full bg-surface-primary">            
            <AnonymousNavbar />
            <Routes>
                <Route path='/members/:profileId' element={<AgentProfilePublic />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='*' element={<Page404 />} />
            </Routes>
        </div>
};

export { Anonymous };
