import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Modal from 'react-modal';
import { getAvatar, getImage, getImageByWidthAspectRatio } from '../../utils/get-avatar';
import { WaitContainer } from '../../components/WaitContainer';
import BuyerLocationsInput from '../NewReferralOutbound/components/BuyerLocationsInput';
import {
    createCheckoutSessionByEmail,
    createCheckoutSessionByEmailWithPsKey,
    createCheckoutSessionByStripeId,
    createCheckoutSessionByStripeIdWithPsKey,
    delUserMedia,
    delUserWebsite,
    deleteBannerImage,
    getAllSkyflowW9,
    getCurrentSubscriptionByStripeId,
    getPrivateProfileById,
    getSkyflowW9DownloadUrl,
    postBannerImage,
    postNotionDesignationTask,
    postProfileLicenses,
    postProfileLogo,
    postProfileSocialMedium,
    postQueryRealifyLicensee,
    postGetSkyflowIdW9,
    postUserMediaUploadImage,
    postUserMediaYoutubeUrl,
    postUserWebsite,
    putProfileData,
    putStateLicense,
    putUserGoogleReviewPlaceId,
    postRetrievePartnerstackLink,
    putAccountStatisticEmails,
} from '../../utils/api';
import { LicenseStatus, SocialMediaIdEnum, TLicensePayload, TLocationItemList, TPrivateProfileView, TUsState, UserDesignationStatus } from '../../types';
import { LocationType } from '../NewReferralOutbound/NewReferralOutbound';
import { DEFAULT_BANNER_ID, FREE_CITIES_LIMIT, FREE_ZIPCODES_LIMIT, MAX_GALLERY_ITEMS, MAX_MISC_WEBSITES, PAID_CITIES_LIMIT, PAID_ZIPCODES_LIMIT, reactSelectColorStyles } from '../constants';
import { W9FileUploader } from './W9FileUploader';
import { DEFAULT_AVATAR_PATH } from '../AuthenticatedNavBar';
import useZustandAuthStore from '../../_Zustand/auth-store';
import { EditPencilIcon } from '../../components/EditPencilIcon';
import { useDeepCompareEffect } from '../../utils/use-deep-compare-effect';
import { LockContainer } from '../../components/LockContainer';
import GooglePlaceLookup from './GooglePlaceLookup';
import GooglePlaceReviewCardSet from './GooglePlaceReviewCardSet';
import { getValidYoutubeId, isFacebookUrlValid, isInstagramUrlValid, isLinkedInUrlValid, isTiktokUrlValid, isXUrlValid, isYoutubeChannelUrlValid, isYoutubeUrlValid } from '../../utils/validate-urls';
import { validateZipcode } from '../../utils/validate-zipcode';
import { globalReactSelectTheme } from '../../constants/react-select-theme';
import { ProductPlans } from './ProductPlans';
import { getLocaleDateWithTzStrShort } from '../../utils/get-locale-date-str';
import { useMakeSkyflowStyles } from 'skyflow-react-js';
import { getIsProduction } from '../../utils/get-is-production';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import Quill from 'quill';
import { useConfirm } from 'material-ui-confirm';
import { useLocation } from 'react-router-dom';

export enum SubscriptionTypeEnum {
    FREE = 'FREE',
    PROFESSIONAL = 'PROFESSIONAL',
    TEAMS = 'TEAMS',
}

export enum LicenseVerificationTypeEnum {
    Online = 0,
    UploadedDocument = 1,
    Realify = 2,
}

export enum GalleryItemTypeEnum {
    YOUTUBE = "YOUTUBE",
    IMAGE = "IMAGE",
};


const AgentProfile: FunctionComponent = () => {
    const { hash: hashComponent } = useLocation();
    const { authState, usStates, designationList, specialtiesList, setAuthState, privateProfileData, setPrivateProfileData } = useZustandAuthStore();

    /// TO CHECK IF SOMEONE IS VIEWING SOMEONE ELSE'S PROFILE
    /// ... USE URL PARAMS
    /// FOR NOW ONLY MY OWN PROFILE
    const canEdit = true;

    const primaryImage = privateProfileData?.primaryImage;
    const avatarUrl: string = primaryImage ? getAvatar(primaryImage, 200) : DEFAULT_AVATAR_PATH;

    const { profileUserData, user, id } = authState;
    const authEmail = user?.email as string;
    const stripeMgmtLinkId = getIsProduction() ? "bIY8wL7aQfc4cRW6oo" : "test_fZeeUX8hVaQrgXm144";
    const stripeMgmtUrl = `https://pay.myreferralagent.com/p/login/${stripeMgmtLinkId}?prefilled_email=${encodeURIComponent(authEmail)}`;

    const [citiesLimit, setCitiesLimit] = useState<number>(FREE_CITIES_LIMIT);
    const [zipcodesLimit, setZipcodesLimit] = useState<number>(FREE_ZIPCODES_LIMIT);

    const [currSubscription, setCurrSubscription] = useState<any>(null);
    
    // Fetching Private Profile Data
    const getPrivateProfile = (callback?): void => {
        getPrivateProfileById()
            .then((privateProfileData: TPrivateProfileView) => {

                const privProfileDataCopy = { ...privateProfileData };

                if (!privProfileDataCopy.stripeId) {
                    privProfileDataCopy['product'] = SubscriptionTypeEnum.FREE;

                    setPrivateProfileData(privProfileDataCopy);
                    console.log('Private Profile: ', privProfileDataCopy);

                    if (callback) {
                        callback();
                    }
                } else {
                    getCurrentSubscriptionByStripeId(privProfileDataCopy.stripeId)
                        .then((subscription) => {
                            if (subscription && subscription.product) {

                                console.log("CurrSubscription: ", subscription);
                                setCurrSubscription(subscription);

                                const product = subscription.product;
                                privProfileDataCopy['product'] = product;

                                populateGallery(privateProfileData);
                            } else {
                                privProfileDataCopy['product'] = SubscriptionTypeEnum.FREE;
                            }

                            setPrivateProfileData(privProfileDataCopy);
                            console.log('Private Profile: ', privProfileDataCopy);

                            if (callback) {
                                callback();
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setPrivateProfileData(privProfileDataCopy);
                        });
                }
            })
            .catch((error) => {
                console.error(error);
                if (callback) {
                    callback();
                }
            });
    };
    
    const [product, setProduct] = useState<string>(privateProfileData?.product as string);
    useDeepCompareEffect(() => {
        console.log('Product', privateProfileData?.product);
        setProduct(privateProfileData?.product as string);

        if (privateProfileData?.product === SubscriptionTypeEnum.FREE) {
            setCitiesLimit(FREE_CITIES_LIMIT);
            setZipcodesLimit(FREE_ZIPCODES_LIMIT);
        }
        else {
            setCitiesLimit(PAID_CITIES_LIMIT);
            setZipcodesLimit(PAID_ZIPCODES_LIMIT);

            postRetrievePartnerstackLink(encodeURIComponent(user?.email as string))
                .then((result) => {
                    console.log("PSLink API result: ", result);
                    const rdata = JSON.parse(result.rdata);
                    const psLink = rdata.link;
                    console.log("PSLink: ", psLink);
                    setPartnerstackLink(psLink);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [privateProfileData?.product]);
    
    useEffect(() => {
        if (privateProfileData?.officeAddress1) {
            setOfficeAddress1(privateProfileData?.officeAddress1);
        }

        if (privateProfileData?.officeAddress2) {
            setOfficeAddress2(privateProfileData?.officeAddress2);
        }

        if (privateProfileData?.officeCity) {
            setOfficeCity(privateProfileData?.officeCity);
        }

        if (privateProfileData?.officeZip) {
            setOfficeZip(privateProfileData?.officeZip);
        }

        if (privateProfileData?.officeStateId) {
            setOfficeStateId(privateProfileData?.officeStateId);
        }
    }, [
        privateProfileData?.officeAddress1,
        privateProfileData?.officeAddress2,
        privateProfileData?.officeCity,
        privateProfileData?.officeZip,
        privateProfileData?.officeStateId
    ]);

    // Profile Banner Image Updater
    const bannerUploadFileRef = useRef<HTMLInputElement>(null);
    const [isUploadingBanner, setIsUploadingBanner] = useState<boolean>(false);
    const [showBannerUploader, setShowBannerUploader] = useState<boolean>(false);
    const enableBannerUploader = (): void => {
        setShowBannerUploader(true);
    }
    const disableBannerUploader = (): void => {
        setShowBannerUploader(false);
    }
    const [isRemovingBannerImage, setIsRemovingBannerImage] = useState<boolean>(false);
    const removeBannerImage = (): void => {
        setIsRemovingBannerImage(true);
        deleteBannerImage(id)
            .then((result) => {
                console.log('Result Upload Banner: ', result);
                getPrivateProfile(() => {
                    toast.info("Banner Image Successfully Deleted!");
                    setIsRemovingBannerImage(false);
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error('Error: ', error.message);
                setIsRemovingBannerImage(false);
            });
    }
    const uploadBannerImage = (event): void => {
        const fileRef = bannerUploadFileRef.current;
        if (!fileRef || fileRef.value === '' || !fileRef.files) {
            toast.error("Please pick a file to upload!");
            return;
        }
        setIsUploadingBanner(true);

        const file = fileRef.files[0];
        const formData = new FormData();
        formData.append('file', file);

        postBannerImage(id, formData)
            .then((result) => {
                console.log('Result Upload Banner: ', result);
                getPrivateProfile(() => {
                    setTimeout(() => {
                        toast.info("Banner Image Successfully Uploaded!");
                        setShowBannerUploader(false);
                        setIsUploadingBanner(false);
                    }, 2000);
                });
            })
            .catch((error) => {
                console.error(error);
                if (error.message.includes("File size too large")) {
                    toast.error("Error: File size was larger than 10 MB.")
                }
                else {
                    toast.error('Error: ' + error.message);
                }
                setIsUploadingBanner(false);
                setShowBannerUploader(false);
            });
    };

    // Profile Image Updater
    const [isUploadingDP, setIsUploadingDP] = useState<boolean>(false);
    const openDPUploader = (): void => {
        formRef.current?.dpfile.click();
    };
    const uploadDP = (event): void => {
        setIsUploadingDP(true);

        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        postProfileLogo(id, 'primary-logo', formData)
            .then((result) => {
                console.log('Result Upload Logo: ', result);
                getPrivateProfile(() => {
                    setTimeout(() => setIsUploadingDP(false), 2000);
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error('Error: ', error.message);
                setIsUploadingDP(false);
            });
    };

    // Office City and State Updater
    const [officeAddress1, setOfficeAddress1] = useState<string>('INIT');
    const [officeAddress2, setOfficeAddress2] = useState<string>('INIT');
    const [officeCity, setOfficeCity] = useState<string>('INIT');
    const [officeStateId, setOfficeStateId] = useState<number>(-999);
    const [officeZip, setOfficeZip] = useState<string>('INIT');
    const [showEditorOfficeAddress, setShowEditorOfficeAddress] = useState<boolean>(false);
    const [isUpdatingOfficeAddress, setIsUpdatingOfficeAddress] = useState<boolean>(false);
    const enableEditOfficeAddress = (): void => {
        setShowEditorOfficeAddress(true);
    };
    const isValidOfficeAddressComponents = () => {

        if (officeAddress1 === 'INIT' || officeAddress1 === '') {
            toast.error("Please enter Office Address Line 1");
            return false;
        }

        if (officeCity === 'INIT' || officeCity === '') {
            toast.error("Please enter an Office City");
            return false;
        }

        if (officeStateId === -1) {
            toast.error("Please select a valid Office State");
            return false;
        }

        if (officeZip === 'INIT' || !validateZipcode(officeZip)) {
            toast.error("Please enter a valid Office Zip Code");
            return false;
        }

        return true;
    };
    const isOfficeAddressChanged = () => {
        if (officeAddress1 !== privateProfileData?.officeAddress1) {
            return true;
        }

        if (officeAddress2 !== privateProfileData?.officeAddress2) {
            return true;
        }

        if (officeCity !== privateProfileData?.officeCity) {
            return true;
        }

        if (officeStateId !== privateProfileData?.officeStateId) {
            return true;
        }

        if (officeZip !== privateProfileData?.officeZip) {
            return true;
        }

        return false;
    };
    const updateOfficeAddress = () => {

        if (!isOfficeAddressChanged()) {
            toast.warning("No changes made.");
            setShowEditorOfficeAddress(false);
            return;
        }
        
        if (!isValidOfficeAddressComponents()) {
            return;
        }

        const model:any = {};
        const as = JSON.parse(JSON.stringify(authState));

        if (officeAddress1 !== 'INIT') {
            if (! /^[A-Za-z0-9 ,.'#]+$/.test(officeAddress1)) {
                toast.error("Office Address 1 has invalid characters. Allowed: alphanumerics, commas, periods, apostrophes and spaces.")
                return;
            }
            model.officeAddress1 = officeAddress1;
            as.profileUserData.officeAddress1 = officeAddress1;        
        }
            
        if (officeAddress2 !== "INIT") {
            if (! /^[A-Za-z0-9 ,.'#]*$/.test(officeAddress2)) {
                toast.error("Office Address 2 has invalid characters. Allowed: alphanumerics, commas, periods, apostrophes and spaces.")
                return;
            }
            model.officeAddress2 = officeAddress2;
            as.profileUserData.officeAddress2 = officeAddress2;
        }

        if (officeCity !== "INIT") {
            if (! /^[A-Za-z ]+$/.test(officeCity)) {
                toast.error("Office City has invalid characters. Only alphabets and space allowed.")
                return;
            }
            model.officeCity = officeCity;
            as.profileUserData.officeCity = officeCity;
        }

        if (officeStateId !== 999) {
            model.officeStateId = officeStateId;
            as.profileUserData.officeStateId = officeStateId;            
        }

        if (officeZip !== "INIT") {
            model.officeZip = officeZip;
            as.profileUserData.officeZip = officeZip;
        }
        
        setAuthState(JSON.parse(JSON.stringify(as)));

        setShowEditorOfficeAddress(false);
        setIsUpdatingOfficeAddress(true);

        const data = { model, step: 0 };
        putProfileData('primary', data, id)
            .then((result) => {
                console.log(result);

                getPrivateProfile(() => {
                    setIsUpdatingOfficeAddress(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setIsUpdatingOfficeAddress(false);
            });
    };

    // First Year of Service Updater
    const [firstYearOfServiceFormField, setFirstYearOfServiceFormField] = useState<string>('');
    const [showEditorFirstYearOfService, setShowEditorFirstYearOfService] = useState<boolean>(false);
    const [isUpdatingFirstYearOfService, setIsUpdatingFirstYearOfService] = useState<boolean>(false);
    const enableEditFirstYearOfService = (): void => {
        setShowEditorFirstYearOfService(true);
    };
    const updateFirstYearOfService = async (): Promise<void> => {
        setIsUpdatingFirstYearOfService(true);
        formRef.current?.firstYearOfService.setCustomValidity(``);

        const currYear = new Date().getFullYear();

        if (firstYearOfServiceFormField === '') {
            toast.error("Please provide a value for First Year of Service!");
            setIsUpdatingFirstYearOfService(false);
            return;
        }

        if (firstYearOfServiceFormField !== '' && !isNaN(parseInt(firstYearOfServiceFormField, 10)) && parseInt(firstYearOfServiceFormField, 10) < 1900) {
            toast.error("First Year of Service must be > 1900");
            setIsUpdatingFirstYearOfService(false);
            return;
        }

        const firstYearOfService = parseInt(firstYearOfServiceFormField, 10);

        if (formRef.current?.firstYearOfService) {
            if (firstYearOfService > currYear) {
                formRef.current?.firstYearOfService.setCustomValidity(`First Year of Service cannot be higher than ${currYear}`);
                setIsUpdatingFirstYearOfService(false);
                return;
            }

            formRef.current?.firstYearOfService.setCustomValidity(``);
        }

        const data = {
            model: { firstYearOfService },
            step: 0,
        };

        putProfileData('primary', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setShowEditorFirstYearOfService(false);
                    setIsUpdatingFirstYearOfService(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorFirstYearOfService(false);
                setIsUpdatingFirstYearOfService(false);
            });
    };

    // Bio Updater
    const MAX_BIO_CHARS = 2500;
    const [bioCharsLeft, setBioCharsLeft] = useState<number>(MAX_BIO_CHARS);
    const bioLengthCheck = (e): void => {
        const str = e.target.value;
        setBioCharsLeft(MAX_BIO_CHARS - str.length);
    };
    const [bioFormField, setBioFormField] = useState<string>('');
    const [showEditorBio, setShowEditorBio] = useState<boolean>(false);
    const enableEditBio = (): void => {
        setShowEditorBio(true);
        // setBioCharsLeft(MAX_BIO_CHARS - (privateProfileData?.general.length || 0));
        setBioCharsLeft(MAX_BIO_CHARS);
    };
    const disableEditBio = (): void => {
        setShowEditorBio(false);        
    };
    const [isUpdatingBio, setIsUpdatingBio] = useState<boolean>(false);
    const updateBio = async (): Promise<void> => {

        if (!bioFormField || bioFormField  === '') {
            toast.info("Please enter some text.");
            return;
        }

        setIsUpdatingBio(true);

        const data = {
            model: { general: bioFormField },
            step: 0,
        };

        putProfileData('background', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setIsUpdatingBio(false);
                    setShowEditorBio(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setIsUpdatingBio(false);
                setShowEditorBio(false);
            });
    };

    // util
    const ensurePhoneFormat = (event): void => {
        const { value } = event.target;
        const formattedValue = value.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        event.target.value = formattedValue;
    };

    // Cell
    const [showEditorCell, setShowEditorCell] = useState<boolean>(false);
    const enableEditCell = (): void => {
        setShowEditorCell(true);
    };
    const disableEditCell = (): void => {
        setShowEditorCell(false);
    };
    const [cell, setCell] = useState<string>('');
    const [isUpdatingCell, setIsUpdatingCell] = useState<boolean>(false);
    const updateCell = async (): Promise<void> => {
        
        let model: any = {};

        if (cell !== '') {
            if (/^[0-9]{10}$/.test(cell.replaceAll('-', ''))) {
                model['cellPhoneNumber'] = cell;
            }
            else {
                toast.error("Please enter a valid cell phone number!");
                return;
            }
        }

        if (!model.cellPhoneNumber) {
            return;
        }

        setIsUpdatingCell(true);

        const data = {
            model: { cellPhoneNumber: cell },
            step: 0,
        };

        putProfileData('primary', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setIsUpdatingCell(false);
                    setShowEditorCell(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setIsUpdatingCell(false);
                setShowEditorCell(false);
            });
    };

    // Office
    const [showEditorOffice, setShowEditorOffice] = useState<boolean>(false);
    const enableEditOffice = (): void => {
        setShowEditorOffice(true);
    };
    const disableEditOffice = (): void => {
        setShowEditorOffice(false);
    };
    const [office, setOffice] = useState<string>('');
    const [officeExtension, setOfficeExtension] = useState<string>('');
    const [isUpdatingOffice, setIsUpdatingOffice] = useState<boolean>(false);
    const updateOffice = async (): Promise<void> => {
        let model: any = {};

        if (office !== '') {
            if (/^[0-9]{10}$/.test(office.replaceAll('-', ''))) {
                model['officePhoneNumber'] = office;
            }
            else {
                toast.error("Please enter a valid phone number! 10 digits (hyphens '-' allowed)");
                return;
            }
        }

        if (officeExtension !== '') {
            if (/^[0-9]{3,6}$/.test(officeExtension)) {
                model['officePhoneExtension'] = officeExtension;
            }
            else {
                toast.error("Please enter a valid phone extension (3 - 6 digits)!")
                return;
            }
        }
        
        if (!model.officePhoneNumber) {
            return;
        }

        setIsUpdatingOffice(true);

        const data = {
            model,
            step: 0,
        };

        putProfileData('primary', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setIsUpdatingOffice(false);
                    setShowEditorOffice(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setIsUpdatingOffice(false);
                setShowEditorOffice(false);
            });
    };

    // Website
    const [showEditorWebsite, setShowEditorWebsite] = useState<boolean>(false);
    const enableEditWebsite = (): void => {
        setShowEditorWebsite(true);
    };
    const disableEditWebsite = (): void => {
        setShowEditorWebsite(false);
    };
    const [website, setWebsite] = useState<string>('');
    const [isUpdatingWebsite, setIsUpdatingWebsite] = useState<boolean>(false);
    const updateWebsite = async (): Promise<void> => {

        // if (website === '' || !website) {
        //     toast.error("Please enter a website URL.");
        //     return;
        // }

        try {
            let url = website;

            if (website !== '') {
                if (url.startsWith("https://")) {
                    // do nothing
                }
                else if (url.startsWith("http://")) {
                    url.replace("http://", "https://");
                }
                else  {
                    url = `https://${url}`;
                }
            }

            setIsUpdatingWebsite(true);

            const data = {
                model: { primaryWebsite: url },
                step: 0,
            };

            putProfileData('primary', data, id)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingWebsite(false);
                        setShowEditorWebsite(false);
                        setWebsite('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingWebsite(false);
                    setShowEditorWebsite(false);
                    setWebsite('');
                    
                });
        } catch (error) {
            toast.error("Please ensure 'Website' follows a standard URL format!");
            setIsUpdatingWebsite(false);
            setShowEditorWebsite(false);
            setWebsite('');
        }
    };

    // Partnerstack Link
    const [partnerstackLink, setPartnerstackLink] = useState<string>('');
    const followPsLink = () => {
        if(partnerstackLink && partnerstackLink !== '') {
            window.open(partnerstackLink, '_blank');
        }
    };
    const copyPsLinkToClipboard = () => {
        if (partnerstackLink && partnerstackLink !== '') {
            navigator.clipboard.writeText(partnerstackLink);
            toast.info("Copied your invite link to the clipboard!")
        }
    };

    // Referral CC Additional Email
    const [showEditorRefCCEmail, setShowEditorRefCCEmail] = useState<boolean>(false);
    const enableEditRefCCEmail = (): void => {
        setShowEditorRefCCEmail(true);
    };
    const disableEditRefCCEmail = (): void => {
        setShowEditorRefCCEmail(false);
    };
    const [refCCEmail, setRefCCEmail] = useState<string>('');
    const [isUpdatingRefCCEmail, setIsUpdatingRefCCEmail] = useState<boolean>(false);
    const isMultipleEmails = (email) => {
        const find = '@';
        let cnt = 0, i = 0;
        while (~(i = email.indexOf (find, i + find.length))) cnt++;
        return cnt > 1;
    };
    const updateRefCCEmail = async (): Promise<void> => {
        
        const copyEmail = refCCEmail.toLowerCase().trim();

        if (copyEmail === '') {
            toast.error("Please provide a referral notification email address.");
            return;
        }

        if (copyEmail === authEmail) {
            toast.error("Primary account email entered. They will receive duplicate emails.");
            return;
        }

        if (isMultipleEmails(copyEmail)) {
            toast.error("Only one email address allowed!");
            return;
        }

        const formField = formRef.current?.referral_cc_statistic_email;
        if (formField && !formField.checkValidity()) {
            formField.reportValidity();
            return;
        }
        
        setIsUpdatingRefCCEmail(true);

        try {
            const data = { notificationEmails: [ copyEmail ] };

            putAccountStatisticEmails(data)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingRefCCEmail(false);
                        setShowEditorRefCCEmail(false);
                        setRefCCEmail('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingRefCCEmail(false);
                    setShowEditorRefCCEmail(false);
                    setRefCCEmail('');
                    
                });
        } catch (error) {
            console.error(error);
            setIsUpdatingRefCCEmail(false);
            setShowEditorRefCCEmail(false);
            setRefCCEmail('');
        }
    };

    const confirm = useConfirm();
    const deleteRefCCEmail = async (): Promise<void> => {
        confirm({
                description: "Are you sure you wish to stop this email address from receiving referral notification emails?",
                confirmationText: "Yes, Delete This Email"
            })
            .then(() => {
                setIsUpdatingRefCCEmail(true);

                try {
                    const data = { notificationEmails: null };

                    putAccountStatisticEmails(data)
                        .then((result) => {
                            console.log(result);
                            getPrivateProfile(() => {
                                setIsUpdatingRefCCEmail(false);
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            setIsUpdatingRefCCEmail(false);
                        });
                } catch (error) {
                    console.error(error);
                    setIsUpdatingRefCCEmail(false);
                }
            })
            .catch((error) => {
                console.log("Delete additional email canceled");
                setIsUpdatingRefCCEmail(false);
            });
    };

    // Social Media: Facebook
    const [showEditorSocialFacebook, setShowEditorSocialFacebook] = useState<boolean>(false);
    const enableEditSocialFacebook = (): void => {
        setShowEditorSocialFacebook(true);
    };
    const disableEditSocialFacebook = (): void => {
        setShowEditorSocialFacebook(false);
    };
    const [socialFacebook, setSocialFacebook] = useState<string>('');
    const [isUpdatingSocialFacebook, setIsUpdatingSocialFacebook] = useState<boolean>(false);
    const updateSocialFacebook = async (): Promise<void> => {
        try {

            if (socialFacebook === '' || !socialFacebook || !isFacebookUrlValid(socialFacebook)) {
                toast.error("Please enter a valid Facebook url.");
                return;
            }

            // const url = new URL(socialFacebook);
            let url = socialFacebook;
            if (url.startsWith("https://")) {
                // do nothing
            }
            else if (url.startsWith("http://")) {
                url.replace("http://", "https://");
            }
            else  {
                url = `https://${url}`;
            }

            setIsUpdatingSocialFacebook(true);

            const data = {
                url,
                socialMediaId: SocialMediaIdEnum.facebook_business,
            };

            postProfileSocialMedium(data, authState.id)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingSocialFacebook(false);
                        setShowEditorSocialFacebook(false);
                        setSocialFacebook('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingSocialFacebook(false);
                    setShowEditorSocialFacebook(false);
                    setSocialFacebook('');
                });

        } catch (error) {
            // toast.error("Please ensure 'Website' follows a standard URL format!");
            console.error(error);
            setIsUpdatingSocialFacebook(false);
            setShowEditorSocialFacebook(false);
            setSocialFacebook('');
        }
    };

    // Social Media: Instagram
    const [showEditorSocialInstagram, setShowEditorSocialInstagram] = useState<boolean>(false);
    const enableEditSocialInstagram = (): void => {
        setShowEditorSocialInstagram(true);
    };
    const disableEditSocialInstagram = (): void => {
        setShowEditorSocialInstagram(false);
    };
    const [socialInstagram, setSocialInstagram] = useState<string>('');
    const [isUpdatingSocialInstagram, setIsUpdatingSocialInstagram] = useState<boolean>(false);
    const updateSocialInstagram = async (): Promise<void> => {
        try {

            if (socialInstagram === '' || !socialInstagram || !isInstagramUrlValid(socialInstagram)) {
                toast.error("Please enter a valid Instagram url.");
                return;
            }

            // const url = new URL(socialInstagram);
            let url = socialInstagram;
            if (url.startsWith("https://")) {
                // do nothing
            }
            else if (url.startsWith("http://")) {
                url.replace("http://", "https://");
            }
            else  {
                url = `https://${url}`;
            }

            setIsUpdatingSocialInstagram(true);

            const data = {
                url,
                socialMediaId: SocialMediaIdEnum.instagram,
            };

            postProfileSocialMedium(data, authState.id)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingSocialInstagram(false);
                        setShowEditorSocialInstagram(false);
                        setSocialInstagram('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingSocialInstagram(false);
                    setShowEditorSocialInstagram(false);
                    setSocialInstagram('');
                });

        } catch (error) {
            // toast.error("Please ensure 'Website' follows a standard URL format!");
            console.error(error);
            setIsUpdatingSocialInstagram(false);
            setShowEditorSocialInstagram(false);
            setSocialInstagram('');
        }
    };

    // Social Media: Twitter
    const [showEditorSocialTwitter, setShowEditorSocialTwitter] = useState<boolean>(false);
    const enableEditSocialTwitter = (): void => {
        setShowEditorSocialTwitter(true);
    };
    const disableEditSocialTwitter = (): void => {
        setShowEditorSocialTwitter(false);
    };
    const [socialTwitter, setSocialTwitter] = useState<string>('');
    const [isUpdatingSocialTwitter, setIsUpdatingSocialTwitter] = useState<boolean>(false);
    const updateSocialTwitter = async (): Promise<void> => {
        try {

            if (socialTwitter === '' || !socialTwitter || !isXUrlValid(socialTwitter)) {
                toast.error("Please enter a valid X url.")
                return;
            }

            // const url = new URL(socialTwitter);
            let url = socialTwitter;
            if (url.startsWith("https://")) {
                // do nothing
            }
            else if (url.startsWith("http://")) {
                url.replace("http://", "https://");
            }
            else  {
                url = `https://${url}`;
            }

            setIsUpdatingSocialTwitter(true);

            const data = {
                url,
                socialMediaId: SocialMediaIdEnum.twitter,
            };

            postProfileSocialMedium(data, authState.id)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingSocialTwitter(false);
                        setShowEditorSocialTwitter(false);
                        setSocialTwitter('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingSocialTwitter(false);
                    setShowEditorSocialTwitter(false);
                    setSocialTwitter('');
                });

        } catch (error) {
            // toast.error("Please ensure 'Website' follows a standard URL format!");
            console.error(error);
            setIsUpdatingSocialTwitter(false);
            setShowEditorSocialTwitter(false);
            setSocialTwitter('');
        }
    };

    // Social Media: YouTubeChannel
    const [showEditorSocialYouTubeChannel, setShowEditorSocialYouTubeChannel] = useState<boolean>(false);
    const enableEditSocialYouTubeChannel = (): void => {
        setShowEditorSocialYouTubeChannel(true);
    };
    const disableEditSocialYouTubeChannel = (): void => {
        setShowEditorSocialYouTubeChannel(false);
    };
    const [socialYouTubeChannel, setSocialYouTubeChannel] = useState<string>('');
    const [isUpdatingSocialYouTubeChannel, setIsUpdatingSocialYouTubeChannel] = useState<boolean>(false);
    const updateSocialYouTubeChannel = async (): Promise<void> => {
        try {

            if (socialYouTubeChannel === '' || !socialYouTubeChannel || (!isYoutubeChannelUrlValid(socialYouTubeChannel)) && (!isYoutubeUrlValid(socialYouTubeChannel))) {
                toast.error("Please enter a valid YouTube Channel url.")
                return;
            }

            // const url = new URL(socialYouTubeChannel);
            let url = socialYouTubeChannel;
            if (url.startsWith("https://")) {
                // do nothing
            }
            else if (url.startsWith("http://")) {
                url.replace("http://", "https://");
            }
            else  {
                url = `https://${url}`;
            }

            setIsUpdatingSocialYouTubeChannel(true);

            const data = {
                url,
                socialMediaId: SocialMediaIdEnum.youTube,
            };

            postProfileSocialMedium(data, authState.id)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingSocialYouTubeChannel(false);
                        setShowEditorSocialYouTubeChannel(false);
                        setSocialYouTubeChannel('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingSocialYouTubeChannel(false);
                    setShowEditorSocialYouTubeChannel(false);
                    setSocialYouTubeChannel('');
                });

        } catch (error) {
            console.error(error);
            setIsUpdatingSocialYouTubeChannel(false);
            setShowEditorSocialYouTubeChannel(false);
            setSocialYouTubeChannel('');
        }
    };

    // Social Media: TikTok
    const [showEditorSocialTikTok, setShowEditorSocialTikTok] = useState<boolean>(false);
    const enableEditSocialTikTok = (): void => {
        setShowEditorSocialTikTok(true);
    };
    const disableEditSocialTikTok = (): void => {
        setShowEditorSocialTikTok(false);
    };
    const [socialTikTok, setSocialTikTok] = useState<string>('');
    const [isUpdatingSocialTikTok, setIsUpdatingSocialTikTok] = useState<boolean>(false);
    const updateSocialTikTok = async (): Promise<void> => {
        try {

            if (socialTikTok === '' || !socialTikTok || !isTiktokUrlValid(socialTikTok)) {
                toast.error("Please enter a valid TikTok url.")
                return;
            }

            // const url = new URL(socialTikTok);
            let url = socialTikTok;
            if (url.startsWith("https://")) {
                // do nothing
            }
            else if (url.startsWith("http://")) {
                url.replace("http://", "https://");
            }
            else  {
                url = `https://${url}`;
            }

            setIsUpdatingSocialTikTok(true);

            const data = {
                url,
                socialMediaId: SocialMediaIdEnum.tiktok,
            };

            postProfileSocialMedium(data, authState.id)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingSocialTikTok(false);
                        setShowEditorSocialTikTok(false);
                        setSocialTikTok('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingSocialTikTok(false);
                    setShowEditorSocialTikTok(false);
                    setSocialTikTok('');
                });

        } catch (error) {
            console.error(error);
            setIsUpdatingSocialTikTok(false);
            setShowEditorSocialTikTok(false);
            setSocialTikTok('');
        }
    };

    // Social Media: LinkedIn
    const [showEditorSocialLinkedIn, setShowEditorSocialLinkedIn] = useState<boolean>(false);
    const enableEditSocialLinkedIn = (): void => {
        setShowEditorSocialLinkedIn(true);
    };
    const disableEditSocialLinkedIn = (): void => {
        setShowEditorSocialLinkedIn(false);
    };
    const [socialLinkedIn, setSocialLinkedIn] = useState<string>('');
    const [isUpdatingSocialLinkedIn, setIsUpdatingSocialLinkedIn] = useState<boolean>(false);
    const updateSocialLinkedIn = async (): Promise<void> => {
        try {

            if (socialLinkedIn === '' || !socialLinkedIn || !isLinkedInUrlValid(socialLinkedIn)) {
                toast.error("Please enter a valid LinkedIn url.")
                return;
            }

            // const url = new URL(socialLinkedIn);
            let url = socialLinkedIn;
            if (url.startsWith("https://")) {
                // do nothing
            }
            else if (url.startsWith("http://")) {
                url.replace("http://", "https://");
            }
            else  {
                url = `https://${url}`;
            }

            setIsUpdatingSocialLinkedIn(true);

            const data = {
                url,
                socialMediaId: SocialMediaIdEnum.linkedIn,
            };

            postProfileSocialMedium(data, authState.id)
                .then((result) => {
                    console.log(result);
                    getPrivateProfile(() => {
                        setIsUpdatingSocialLinkedIn(false);
                        setShowEditorSocialLinkedIn(false);
                        setSocialLinkedIn('');
                    });
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdatingSocialLinkedIn(false);
                    setShowEditorSocialLinkedIn(false);
                    setSocialLinkedIn('');
                });

        } catch (error) {
            console.error(error);
            setIsUpdatingSocialLinkedIn(false);
            setShowEditorSocialLinkedIn(false);
            setSocialLinkedIn('');
        }
    };
    

    // Misc Websites (PRO Only)
    const [miscWebsiteUrl, setMiscWebsiteUrl] = useState<string>('');
    const [isAddingMiscWebsite, setIsAddingMiscWebsite] = useState<boolean>(false);
    const [showAddMiscWebsite, setShowAddMiscWebsite] = useState<boolean>(false);
    const enableMiscWebsiteAddition = (): void => {
        if (product === SubscriptionTypeEnum.FREE) {
            toast.error(`Cannot add miscellaneous websites! Upgrade to get up to 6.`);
            return;
        }
        else {
            if (privateProfileData?.userWebsites && privateProfileData?.userWebsites.length > MAX_MISC_WEBSITES) {
                toast.error(`Cannot add more than ${MAX_MISC_WEBSITES} websites!`);
                return;
            }
        }
        setShowAddMiscWebsite(true);
    }
    const addMiscWebsite = (): void => {
        if (miscWebsiteUrl === '') { // || !isValidUrl(miscWebsiteUrl)) {
            toast.error("Please enter a website URL");
            return;
        }

        if (Array.isArray(privateProfileData?.userWebsites) && privateProfileData?.userWebsites.length >= MAX_MISC_WEBSITES) {
            toast.error(`Sorry, you cannot add more than ${MAX_MISC_WEBSITES} websites.`);
            return;
        }

        let mwUrl = miscWebsiteUrl;
        if (mwUrl.startsWith("https://")) {
            // do nothing
        }
        else if (mwUrl.startsWith("http://")) {
            mwUrl.replace("http://", "https://");
        }
        else  {
            mwUrl = `https://${mwUrl}`;
        }

        const encodedUrl = btoa(encodeURI(mwUrl));
        if (privateProfileData?.userWebsites?.map((w) => w.url).includes(encodedUrl)) {
            toast.error(`Website ${miscWebsiteUrl} already in profile!`);
            return;
        }

        setIsAddingMiscWebsite(true);
        postUserWebsite(id, encodedUrl)
            .then((result) => {
                console.log("Added Website");
                getPrivateProfile(() => {
                    setIsAddingMiscWebsite(false);
                    setShowAddMiscWebsite(false);
                });
            })
            .catch((error) => {
                setIsAddingMiscWebsite(false);
                setShowAddMiscWebsite(false);
            });
        
    }
    const delMiscWebsite = (websiteId: number): void => {
        setIsAddingMiscWebsite(true);
        delUserWebsite(id, websiteId)
            .then((result) => {
                console.log("Deleted Website: ", websiteId);
                getPrivateProfile(() => {
                    setIsAddingMiscWebsite(false);
                });
            })
            .catch((error) => {
                setIsAddingMiscWebsite(false);
            });
    }

    // Locations: City Editor
    const [locationSpecialties, setLocationSpecialties] = useState<Array<LocationType>>([]);
    const [showEditorLocations, setShowEditorLocations] = useState<boolean>(false);
    const [isUpdatingLocations, setIsUpdatingLocations] = useState<boolean>(false);
    const enableEditLocationSpecialties = (): void => {

        const currCityLocs = privateProfileData?.servedCities;
        const newCityCount = currCityLocs?.length as number;

        const currZipLocs = privateProfileData?.servedZipCodes;
        const newZipCount = currZipLocs?.length as number;

        let crossedCityLimit = false;
        let crossedZipLimit = false;
        if (product === SubscriptionTypeEnum.FREE) {
            // Free Limit filter
            if (newCityCount >= FREE_CITIES_LIMIT) {
                crossedCityLimit = true;
            }

            if (newZipCount >= FREE_ZIPCODES_LIMIT) {
                crossedZipLimit = true;
            }

            let mesg = "";
            const trailingMesg = "";
            if (crossedCityLimit) {
                mesg = `${mesg} You have exhausted your Free limit of ${FREE_CITIES_LIMIT} cities.`
            }
            if (crossedZipLimit) {
                mesg = `${mesg} ${crossedCityLimit?'Also, You':'You'} have exhausted your Free limit of ${FREE_ZIPCODES_LIMIT} zipcodes.`
            }
            if (crossedCityLimit || crossedZipLimit) {
                toast.warn(`${mesg}${trailingMesg}`);
            }
            if (crossedCityLimit && crossedZipLimit) {
                return;
            }
        }
        else {
            // Paid Limit filter
            if (newCityCount > PAID_CITIES_LIMIT) {
                crossedCityLimit = true;
            }

            if (newZipCount > PAID_ZIPCODES_LIMIT) {
                crossedZipLimit = true;
            }

            let mesg = "Warning: ";
            const trailingMesg = "";
            if (crossedCityLimit) {
                mesg = `${mesg} You have exhausted your Free limit of ${PAID_CITIES_LIMIT} cities.`
            }
            if (crossedZipLimit) {
                mesg = `${mesg}${crossedCityLimit?'Also, You':'You'} have exhausted your Free limit of ${PAID_ZIPCODES_LIMIT} zipcodes.`
            }

            if (crossedCityLimit || crossedZipLimit) {
                toast.warn(`${mesg}${trailingMesg}`);
            }
            if (crossedCityLimit && crossedZipLimit) {
                return;
            }
        }

        setLocationSpecialties([]);
        setShowEditorLocations(true);
    };
    const disableEditLocationSpecialties = (): void => {
        setShowEditorLocations(false);
        setLocationSpecialties([]);
    };
    const addLocationsCitiesOrZipcodes = (locationsToAdd): void => {
        // cities
        const currCityLocs = privateProfileData?.servedCities;
        const _newCityLocs = locationsToAdd.filter((l) => !l.zipcode);
        const newCityLocsDups = [..._newCityLocs].filter((l) => (((currCityLocs?.map((cl) => `${cl.city}${cl.stateId}`)) ?? []).includes(`${l.city}${l.stateId}`)));
        if (newCityLocsDups.length > 0) {
            const dupCities = newCityLocsDups.map((l) => `${l.city},${l.stateAbbreviation}`).join(", ");
            toast.warn(`${dupCities} is already present in your current city list. Removing from new city input.`);
        }
        const newCityLocs = _newCityLocs.filter((l) => !(((currCityLocs?.map((cl) => `${cl.city}${cl.stateId}`)) ?? []).includes(`${l.city}${l.stateId}`)));
        const newCityCount = currCityLocs?.length + newCityLocs.length;

        // zipcodes
        const currZipLocs = privateProfileData?.servedZipCodes;
        const _newZipLocs = locationsToAdd.filter((l) => !!l.zipcode);
        const newZipLocsDups = [..._newZipLocs].filter((l) => (((currZipLocs?.map((cl) => `${cl.zip}`)) ?? []).includes(`${l.zipcode}`)));
        if (newZipLocsDups.length > 0) {
            const dupZips = newZipLocsDups.map((l) => `${l.zipcode}`).join(", ");
            toast.warn(`${dupZips} is already present in your current zipcode list. Removing from new zipcode input.`);
        }
        const newZipLocs = _newZipLocs.filter((l) => !(((currZipLocs?.map((cl) => `${cl.zip}`)) ?? []).includes(`${l.zipcode}`)));
        const newZipCount = currZipLocs?.length + newZipLocs.length;

        let newLocations: any[] = [];
        let isBeyondCityLimit = false;
        let isBeyondZipLimit = false;
        if (product === SubscriptionTypeEnum.FREE) {
            // Free Limit filter
            newLocations = [];

            const remainingCityAllowed = FREE_CITIES_LIMIT - (currCityLocs as TLocationItemList).length;
            console.log("remainingCityAllowed: ", remainingCityAllowed);
            if (newCityCount > FREE_CITIES_LIMIT) {
                isBeyondCityLimit = true;
                newLocations = [...newLocations, ...newCityLocs.slice(0, remainingCityAllowed)];
            }
            else {
                newLocations = [...newLocations, ...newCityLocs];
            }

            const remainingZipAllowed = FREE_ZIPCODES_LIMIT - (currZipLocs as TLocationItemList).length;
            console.log("remainingZipAllowed: ", remainingZipAllowed);
            if (newZipCount > FREE_ZIPCODES_LIMIT) {
                isBeyondZipLimit = true;
                newLocations = [...newLocations, ...newZipLocs.slice(0, remainingZipAllowed)];
            }
            else {
                newLocations = [...newLocations, ...newZipLocs];
            }

            let mesg = "";
            const trailingMesg = " Removing locations beyond allowed limits.";
            if (isBeyondCityLimit) {
                mesg = `${mesg} You have exhausted your Free limit of ${FREE_CITIES_LIMIT} cities.`
            }
            if (isBeyondZipLimit) {
                mesg = `${mesg}${isBeyondCityLimit?'Also, You':'You'} have exhausted your Free limit of ${FREE_ZIPCODES_LIMIT} zipcodes.`
            }
            if (isBeyondCityLimit || isBeyondZipLimit) {
                toast.error(`${mesg}${trailingMesg}`);
            }
        }
        else {
            // Paid Limit filter
            newLocations = [];

            const remainingCityAllowed = PAID_CITIES_LIMIT - (currCityLocs as TLocationItemList).length;
            console.log("remainingCityAllowed: ", remainingCityAllowed);
            if (newCityCount > PAID_CITIES_LIMIT) {
                isBeyondCityLimit = true;
                newLocations = [...newLocations, ...newCityLocs.slice(0, PAID_CITIES_LIMIT - remainingCityAllowed)];
            }
            else {
                newLocations = [...newLocations, ...newCityLocs];
            }

            const remainingZipAllowed = PAID_ZIPCODES_LIMIT - (currZipLocs as TLocationItemList).length;
            console.log("remainingZipAllowed: ", remainingZipAllowed);
            if (newZipCount > PAID_ZIPCODES_LIMIT) {
                isBeyondZipLimit = true;
                newLocations = [...newLocations, ...newZipLocs.slice(0, PAID_ZIPCODES_LIMIT - remainingZipAllowed)];
            }
            else {
                newLocations = [...newLocations, ...newZipLocs];
            }

            let mesg = "";
            const trailingMesg = " Removing locations beyond allowed limits.";
            if (isBeyondCityLimit) {
                mesg = `${mesg} You have exhausted your limit of ${PAID_CITIES_LIMIT} cities.`
            }
            if (isBeyondZipLimit) {
                mesg = `${mesg}${isBeyondCityLimit?'Also, You':'You'} have exhausted your limit of ${PAID_ZIPCODES_LIMIT} zipcodes.`
            }
            if (isBeyondCityLimit || isBeyondZipLimit) {
                toast.error(`${mesg}${trailingMesg}`);
            }
        }

        console.log('Setting Locations', newLocations);
        setLocationSpecialties(newLocations);
    };
    const buildLocationModelData = (): any => {
        return {
            model: {
                // states: [...new Set(locationsCities.filter((l: any) => !!l.stateId).map((l: any) => l.stateId))],
                states: [],
                counties: [],
                cities: [
                        ...locationSpecialties
                        .filter((l: any) => !l.zipcode) // if stateId prop exists in 'l'
                        .map((l: any) => ({
                            name: l.city, // yes it's name in the model
                            county: null,
                            stateId: l.stateId,
                            stateAbbreviation: l.stateAbbreviation,
                            cityHash: `${l.stateId}${l.city}`,
                        })),
                        ...(privateProfileData?.servedCities as Array<any>)
                        .map((c) => ({
                            locationId: c.locationId,
                            name: c.city, // yes it's name in the model
                            county: c.county,
                            stateId: c.stateId,
                            stateAbbreviation: c.stateAbbreviation,
                            cityHash: `${c.stateId}${c.city}`,
                        })),
                ],
                zipCodes: [
                    ...locationSpecialties
                        .filter((l: any) => !!l.zipcode) // if zipcode prop exists in 'l'
                        .map((l: any) => {
                            return {
                                zip: l.zipcode,
                                city: null,
                                county: null,
                                stateId: l.stateId,
                                stateAbbreviation: l.stateAbbreviation,
                            };
                        }),
                    ...(privateProfileData?.servedZipCodes as Array<any>),
                ],
            },
            step: 0,
        };
    };
    const updateLocationsCities = async (): Promise<void> => {
        console.log(locationSpecialties);
        setIsUpdatingLocations(true);

        const data = buildLocationModelData();

        putProfileData('location-specialties', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setShowEditorLocations(false);
                    setIsUpdatingLocations(false);
                    setLocationSpecialties([]);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorLocations(false);
                setIsUpdatingLocations(false);
                setLocationSpecialties([]);
            });
    };
    const removeCityLocationSpecialty = (locationId): void => {
        setIsUpdatingLocations(true);

        const data = buildLocationModelData();
        data.model.cities = data.model.cities.filter((c) => c.locationId !== locationId);
        console.log(data);

        putProfileData('location-specialties', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setShowEditorLocations(false);
                    setIsUpdatingLocations(false);
                    setLocationSpecialties([]);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorLocations(false);
                setIsUpdatingLocations(false);
                setLocationSpecialties([]);
            });
    };
    const removeZipCodeLocationSpecialty = (locationId): void => {
        setIsUpdatingLocations(true);

        const data = buildLocationModelData();
        data.model.zipCodes = data.model.zipCodes.filter((c) => c.locationId !== locationId);
        console.log(data);

        putProfileData('location-specialties', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setShowEditorLocations(false);
                    setIsUpdatingLocations(false);
                    setLocationSpecialties([]);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorLocations(false);
                setIsUpdatingLocations(false);
                setLocationSpecialties([]);
            });
    };

    // License Editor
    const usStatesList = usStates as Array<TUsState>;
    const stateOptions = Object.values(usStatesList).map((s) => {
        return { label: s.name, value: s.id };
    });
    const selectStyle = {
        control: (base) => ({
            ...base,
            height: 35,
            minHeight: 35,
        }),
    };
    const [showAddLicense, setShowAddLicense] = useState<boolean>(false);
    const [license, setLicense] = useState<string>('');
    const [isAddingLicense, setIsAddingLicense] = useState<boolean>(false);
    const [licenseState, setLicenseState] = useState<number>();
    const [licenseYear, setLicenseYear] = useState<number>();
    const [licenseYearExpiry, setLicenseYearExpiry] = useState<number>();
    const onChangeLicenseState = (select): void => {
        setLicenseState(select.value);
    };
    const validateLicenseForm = (): boolean => {
        return false;
    };
    const updateLicense = (): void => {
        // if (!validateLicenseForm()) {
        //     return;
        // }

        setIsAddingLicense(true);

        const data: TLicensePayload = {
            licenseNumber: license,
            stateLicensed: usStatesList.find((s) => s.id === licenseState) as TUsState,
            yearLicensed: licenseYear as number,
        };

        postProfileLicenses(id, data)
            .then((result) => {
                console.log(result);

                const userStateLicenseId = result;
                const licenseNumber = license;
                const state = usStatesList.find((s) => s.id === licenseState)?.abbreviation as string;
                const firstName = authState.profileUserData?.firstName;
                const lastName = authState.profileUserData?.lastName;

                postQueryRealifyLicensee({ licenseNumber, state, firstName, lastName, dbUserStateLicenseId: userStateLicenseId, dbUserId: id })
                .then((result) => {

                    console.log(result);
                    let licenseVerified = false;

                    if (!!result.licenseNumber && result.licenseNumber === licenseNumber) {
                        console.log("License Verified!")
                        licenseVerified = true;
                    }

                    // save verification status to database
                    if (licenseVerified) {
                        let yearLicensed = parseInt(result.issued.split("-")[0], 10);
                        let licenseExpiryDate = new Date(result.expires);
                    
                        putStateLicense(userStateLicenseId, {
                            status: LicenseStatus.verified,
                            verificationUrl: 'REALIFY API',
                            yearLicensed,
                            licenseExpiryDate,
                        })
                        .then((result) => {
                            console.log(result);
                            getPrivateProfile();
                        })
                        .catch((error) => {
                            console.error(error);
                            getPrivateProfile();
                        });
                    }
                    else {
                        console.log("Notion Task Created.")
                        getPrivateProfile();
                    }

                })
                .catch((error) => {
                    getPrivateProfile();
                    console.error(error);
                });
                // }
                
                getPrivateProfile();
                setIsAddingLicense(false);
                setShowAddLicense(false);
            })
            .catch((error) => {
                console.error(error);
                getPrivateProfile();
                setIsAddingLicense(false);
                setShowAddLicense(false);
            });
    };

    // Designations Updater
    const [userDesignations, setUserDesignations] = useState<any>([]);
    const [showEditorDesignations, setShowEditorDesignations] = useState<boolean>(false);
    const [isUpdatingUserDesignations, setIsUpdatingUserDesignations] = useState<boolean>(false);
    const enableEditorUserDesignations = (): void => {
        setShowEditorDesignations(true);
    };
    const disableEditorUserDesignations = (): void => {
        setShowEditorDesignations(false);
    };
    const buildUserDesignationsModel = (): any => {
        return {
            model: {
                designations: [
                    ...(privateProfileData?.designations as Array<any>),
                    ...userDesignations.map((ud) => {
                        return {
                            designationId: ud.value,
                            status: UserDesignationStatus.pending,
                        };
                    }),
                ],
            },
            step: 0,
        };
    };
    const updateUserDesignations = async (): Promise<void> => {
        setIsUpdatingUserDesignations(true);

        console.log(userDesignations);

        const data = buildUserDesignationsModel();

        putProfileData('designations', data, id)
            .then((result) => {
                console.log(result);

                const taskData = {
                    designations: userDesignations,
                    firstName: authState.profileUserData?.firstName,
                    lastName: authState.profileUserData?.lastName,
                    userId: authState.id,
                };

                if (product !== SubscriptionTypeEnum.FREE) {
                    postNotionDesignationTask(taskData)
                        .then((result) => {
                            console.log(result);
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                }

                getPrivateProfile(() => {
                    setShowEditorDesignations(false);
                    setIsUpdatingUserDesignations(false);
                    setUserDesignations([]);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorDesignations(false);
                setIsUpdatingUserDesignations(false);
                setUserDesignations([]);
            });
    };
    const removeUserDesignation = (designationToDelete): void => {
        setIsUpdatingUserDesignations(true);

        const data = buildUserDesignationsModel();
        data.model.designations = data.model.designations.filter((d) => d.designationId !== designationToDelete.designationId);

        // data.model.zipCodes = data.model.zipCodes.filter((c) => c.locationId !== locationId);
        console.log(data);

        putProfileData('designations', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setShowEditorDesignations(false);
                    setIsUpdatingUserDesignations(false);
                    setUserDesignations([]);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorDesignations(false);
                setIsUpdatingUserDesignations(false);
                setUserDesignations([]);
            });
    };
    const isUserDesigationAlreadySelected = (d): boolean => {
        return privateProfileData?.designations.findIndex((da) => da.designationId === d.id) !== -1;
    };

    // Specialties Updater
    const [userSpecialties, setUserSpecialties] = useState<any>([]);
    const [showEditorUserSpecialties, setShowEditorUserSpecialties] = useState<boolean>(false);
    const [isUpdatingUserSpecialties, setIsUpdatingUserSpecialties] = useState<boolean>(false);
    const enableEditorUserSpecialties = (): void => {
        setShowEditorUserSpecialties(true);
    };
    const disableEditorUserSpecialties = (): void => {
        setShowEditorUserSpecialties(false);
    };
    const buildUserSpecialtiesModel = (): any => {
        return {
            model: {
                specialties: [
                    ...(privateProfileData?.specialties as Array<any>),
                    ...userSpecialties.map((ud) => {
                        return {
                            specialtyId: ud.value,
                            description: null,
                            order: 1,
                            name: null,
                        };
                    }),
                ],
            },
            step: 0,
        };
    };
    const updateUserSpecialties = async (): Promise<void> => {
        setIsUpdatingUserSpecialties(true);

        const data = buildUserSpecialtiesModel();
        console.log(data);

        putProfileData('specialties', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setShowEditorUserSpecialties(false);
                    setIsUpdatingUserSpecialties(false);
                    setUserSpecialties([]);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorUserSpecialties(false);
                setIsUpdatingUserSpecialties(false);
                setUserSpecialties([]);
            });
    };
    const removeUserSpecialty = async (specialtyToDelete): Promise<void> => {
        setIsUpdatingUserSpecialties(true);
        console.log('Specialty To Delete: ', specialtyToDelete);

        const data = buildUserSpecialtiesModel();
        data.model.specialties = data.model.specialties.filter((d) => d.specialtyId !== specialtyToDelete.specialtyId);
        console.log(data);

        putProfileData('specialties', data, id)
            .then((result) => {
                console.log(result);
                getPrivateProfile(() => {
                    setShowEditorUserSpecialties(false);
                    setIsUpdatingUserSpecialties(false);
                    setUserSpecialties([]);
                });
            })
            .catch((error) => {
                console.error(error);
                setShowEditorUserSpecialties(false);
                setIsUpdatingUserSpecialties(false);
                setUserSpecialties([]);
            });
    };
    const isUserSpecialtyAlreadySelected = (o): boolean => {
        return privateProfileData?.specialties.findIndex((sp) => sp.specialtyId === o.id) !== -1;
    };
    const filteredSpecialtyList = (): any => {
        console.log('Filtering Specialty List ... ');
        return specialtiesList
            ?.map((s) => {
                return {
                    label: s.name,
                    value: s.id,
                    specialty: s,
                };
            })
            .filter((o) => !isUserSpecialtyAlreadySelected(o.specialty));
    };

    const formRef = useRef<HTMLFormElement>(null);

    const [w9SkyflowId, setW9SkyflowId] = useState<string>('');
    const [showW9Uploader, setShowW9Uploader] = useState<boolean>(false);
    const [isW9UploaderReady, setIsW9UploaderReady] = useState<boolean>(false);
    const [isW9FileSelectedForUpload, setIsW9FileSelectedForUpload] = useState<boolean>(false);
    const [isW9FileSelectedValid, setIsW9FileSelectedValid] = useState<boolean>(false);
    const w9UploadStyleClasses = useMakeSkyflowStyles({
        inputStyles: {
          base: {
            border: '1px solid #aaa',
            backgroundColor: '#fafaff',
            borderRadius: '4px',
            color: '#1d1d1d',
            padding: '10px 16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            verticalAlign: 'middle',
            cursor: 'pointer',
          },
          complete: {
            color: '#4caf50',
          },
          empty: {},
          focus: {},
          invalid: {
            color: '#f44336',
          },
          cardIcon: {
            position: 'absolute',
            left: '8px',
            bottom: 'calc(50% - 12px)',
          },
        },
        labelStyles: {
          base: {
            fontSize: '16px',
            fontWeight: 'bold',
          },
        },
        errorTextStyles: {
          base: {
            color: 'white',
            display: 'hidden',
          },
        },
    })();
    const removeStaleSkyflowCollectIframes = () => {
        Array.from(document.querySelectorAll('iframe'))
                .filter((e) => e.id.includes('collect_controller'))
                .forEach((e) => {
                    var frame = document.getElementById(e.id);
                    frame?.parentNode?.removeChild(frame as HTMLElement);
                });
    };
    const showW9Form = async (): Promise<void> => {
        
        // could be dangerous to remove 
        // ... if there are collect containers in other locations of the app
        // removeStaleSkyflowCollectIframes();

        setShowW9Uploader(true);
        setIsW9UploaderReady(false);
        setIsW9FileSelectedForUpload(false);

        postGetSkyflowIdW9(authState.id)
            .then((res) => {
                console.log(res);
                if (res.records && res.records.length > 0) {
                    const skyflowId = res.records[0].fields.skyflow_id || res.records[0].fields.id;                    
                    setW9SkyflowId(skyflowId);
                } else {
                    setIsW9UploaderReady(false);
                    setShowW9Uploader(false);
                    setW9SkyflowId('');
                }
            })
            .catch((e) => {
                console.error(e);

                if (e.message.includes('already exists')) {
                    toast.error('Something went wrong! You already have a W-9 uploaded! Please contact us for assistance!');
                }
                else {
                    toast.error('Something went wrong! Please contact us for assistance!');
                }

                setIsW9UploaderReady(false);
                setShowW9Uploader(false);
                setW9SkyflowId('');
            });
    };
    const [w9Container, setW9Container] = useState<any>();
    const onW9Container = (w9Container): void => {
        console.log('W9 Container received: ', w9Container);
        setW9Container(w9Container);
        setShowW9Uploader(true);
    };
    const [isUploadingW9, setIsUploadingW9] = useState<boolean>(false);
    const uploadW9 = async (): Promise<void> => {

        if (!isW9FileSelectedForUpload) {
            toast.error('Please pick a W9 file for upload!');
            return;
        }

        console.log('Uploading w9');        
        setIsUploadingW9(true);
        const response = w9Container?.uploadFiles({});
        response
            ?.then((res: any) => {
                console.log(JSON.stringify(res));

                toast.info('W9 securely and successfully uploaded!');

                setIsUploadingW9(false);
                setShowW9Uploader(false);
                setIsW9FileSelectedForUpload(false);
                setW9SkyflowId('');
                
                getW9s();
            })
            .catch((e: any) => {
                console.error(e);

                toast.error('Something went wrong!');

                setIsUploadingW9(false);
                setShowW9Uploader(false);
                setIsW9FileSelectedForUpload(false);
                setW9SkyflowId('');
            });
    };
    const cancelUploadW9 = (): void => {
        setIsUploadingW9(false);
        setShowW9Uploader(false);
        setW9SkyflowId('');
    };
    const [currW9, setCurrW9] = useState<string | null>(null);
    const getW9s = (): void => {

        getAllSkyflowW9(id)
            .then((response) => {
                console.log(response);
                if (Array.isArray(response.records) && response.records.length > 0) {
                    const w9List = response.records;
                    if (w9List[0].fields.w9_file) {                    
                        setCurrW9(w9List[0].fields.id);
                    }
                } else {
                    console.log('No W9s');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const downloadW9 = (): void => {
        getSkyflowW9DownloadUrl(id)
        .then((result) => {
            console.log(result);
            const url = result.w9Url;

            if (!url) {
                return;
            }

            const ext = url.split("/").pop().split(".").pop().split("&")[0];
            const fileName = `${privateProfileData?.firstName}${privateProfileData?.lastName}_Broker_W9.${ext}`;
            
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((error) => {
            console.error(error);
        })
    };


    const [isShowingProductOptionsModal, setIsShowingProductOptionsModal] = useState<boolean>(false);
    const showProductOptionsModal = () => {
        setIsShowingProductOptionsModal(true);
    };

    const [startingCheckoutSession, setStartingCheckoutSession] = useState<boolean>(false);
    const upgradeTo = (type): void => {
        console.log("Upgrade to");
        if (startingCheckoutSession) {
            console.log('Already Started Checkout!');
            return;
        }
        setStartingCheckoutSession(true);
        switch (type) {
            default:
                return;
            case SubscriptionTypeEnum.PROFESSIONAL: {

                // renewal: stripe ID already exists
                if (privateProfileData?.stripeId) {
                    let args: any[] = [];
                    let createCheckoutSessionfn;
                    if (privateProfileData.invitedByPartnerstackKey) {
                        args = [
                            authState.user?.email as string, 
                            authState.id, 
                            privateProfileData.stripeId,
                            privateProfileData?.invitedByPartnerstackKey as string
                        ];
                        createCheckoutSessionfn = createCheckoutSessionByStripeIdWithPsKey;
                    }
                    else {
                        args = [
                            authState.user?.email as string, 
                            authState.id, 
                            privateProfileData.stripeId,
                        ];
                        createCheckoutSessionfn = createCheckoutSessionByStripeId;
                    }

                    createCheckoutSessionfn(...args)
                        .then((result) => {
                            console.log(result);
                            const checkoutUrl = result.url;
                            window.location.href = checkoutUrl;
                            setStartingCheckoutSession(false);
                        })
                        .catch((error) => {
                            console.error(error);
                            setStartingCheckoutSession(false);
                        });
                }
                else { // first time upgrade - no stripe ID in DB
                    
                    let args: any[] = [];
                    let createCheckoutSessionfn;
                    if (privateProfileData?.invitedByPartnerstackKey) {
                        args = [
                            authState.user?.email as string, 
                            authState.id, 
                            privateProfileData?.invitedByPartnerstackKey as string
                        ];
                        createCheckoutSessionfn = createCheckoutSessionByEmailWithPsKey;
                    }
                    else {
                        args = [
                            authState.user?.email as string, 
                            authState.id,
                        ];
                        createCheckoutSessionfn = createCheckoutSessionByEmail;
                    }
                    
                    createCheckoutSessionfn(...args)
                        .then((result) => {
                        console.log(result);
                        const checkoutUrl = result.url;
                        window.location.href = checkoutUrl;
                        setStartingCheckoutSession(false);
                    }).catch((error) => {
                        console.error(error);
                        setStartingCheckoutSession(false);
                    });
                }
                break;
            }
        }
    };

    const [newGoogleReviewPlace, setNewGoogleReviewPlace] = useState<any>(null);
    const [isShowingGoogleReviewEditor, setIsShowingGoogleReviewEditor] = useState<boolean>(false);
    const [isUpdatingGoogleReviewEditor, setisUpdatingGoogleReviewEditor] = useState<boolean>(false);
    const updateGoogleReviewPlaceId = () => {
        if (newGoogleReviewPlace) {
            setisUpdatingGoogleReviewEditor(true);
            putUserGoogleReviewPlaceId(id, newGoogleReviewPlace.place_id)
            .then(() => {
                getPrivateProfile(() => {
                    setIsShowingGoogleReviewEditor(false);
                    setisUpdatingGoogleReviewEditor(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setIsShowingGoogleReviewEditor(false);
                setisUpdatingGoogleReviewEditor(false);
            });
        }
    }
    
    const mediaUploadFileRef = useRef<HTMLInputElement>(null);
    const [mediaAddOptionsVisible, setMediaAddOptionsVisible] = useState<boolean>(false);
    const [showMediaFileUploader, setShowMediaFileUploader] = useState<boolean>(false);
    const [showMediaYoutubeAdder, setShowMediaYoutubeAdder] = useState<boolean>(false);
    const [isUploadingMediaFile, setIsUploadingMediaFile] = useState<boolean>(false);
    const [currYoutubeUrl, setCurrYoutubeUrl] = useState<string>('');
    const [lgCurrGalleryIndex, setLgCurrGalleryIndex] = useState<number>(-1);
    const [lgGalleryItems, setLgGalleryItems] = useState<any>([]);
    const [imageCache, setImageCache] = useState<any[]>([]);
    const preloadImages = (items) => {
        const imCache = items
                            .filter((i) => i.type === GalleryItemTypeEnum.IMAGE)
                            .map((i) => {
                                const img = new Image();
                                img.src = i.src;
                                return img; 
                            });
        setImageCache(imCache);
    };
    const populateGallery = (privateProfileData): void => {
        const lgGalleryItems = privateProfileData?.userMedias?.map((m) => {
            if (m.type === GalleryItemTypeEnum.YOUTUBE) {
                return {
                    type: m.type,
                    src: `https://www.youtube.com/embed/${m.content}`,
                    thumb: `https://img.youtube.com/vi/${m.content}/mqdefault.jpg`,
                    poster: `https://img.youtube.com/vi/${m.content}/maxresdefault.jpg`,
                    subHtml: '<span></span>',
                    id: m.id,
                };
            }
            else {
                return {
                    type: m.type,
                    src: getImageByWidthAspectRatio(m.content, 1280),
                    thumb: getImage(m.content, 200),
                    subHtml: '<span></span>',
                    id: m.id,
                }
            }
        });
        console.log("lgGalleryItems: ", lgGalleryItems);

        preloadImages(lgGalleryItems);
        setLgGalleryItems(lgGalleryItems);
        if (Array.isArray(lgGalleryItems) && lgGalleryItems.length > 0) {
            setLgCurrGalleryIndex(0);
        }
    }
    const addNewMediaItem = () => {
        if (Array.isArray(lgGalleryItems) && lgGalleryItems.length === MAX_GALLERY_ITEMS) {
            toast.error(`Error: Maximum limit of ${MAX_GALLERY_ITEMS} reached. Cannot add new media.`)
            return;
        }

        setMediaAddOptionsVisible(true);
    };
    const addMediaImage = () => {
        setShowMediaFileUploader(true);
        setMediaAddOptionsVisible(false);
    };
    const uploadMediaFile = () => {
        const fileRef = mediaUploadFileRef.current;
        if (!fileRef || fileRef.value === '' || !fileRef.files) {
            toast.error("Please pick a file to upload!");
            return;
        }

        setIsUploadingMediaFile(true);
        const file = fileRef.files[0];
        const formData = new FormData();
        formData.append('file', file);

        postUserMediaUploadImage(id, formData)
            .then((result: any) => {
                if (!!result.Message && result.Message.includes("File size too large.")) {
                    toast.error("File size too large. Max allowed: 10 MB.");
                    setIsUploadingMediaFile(false);
                    return;
                }
                toast.info("Image successfully uploaded!");
                getPrivateProfile(() => {
                    setShowMediaFileUploader(false);
                    setIsUploadingMediaFile(false);
                });
            })
            .catch((error) => {
                console.error(error);
                getPrivateProfile(() => {
                    setShowMediaFileUploader(false);
                    setIsUploadingMediaFile(false);
                })
            });
    };
    const addMediaYoutube = () => {
        setShowMediaYoutubeAdder(true);
        setMediaAddOptionsVisible(false);
    };
    const saveYoutubeUrl = () => {
        if (!isYoutubeUrlValid(currYoutubeUrl)) {
            toast.error("Please enter a valid YouTube URL");
            return;
        }

        setIsUploadingMediaFile(true);

        const youtubeId = getValidYoutubeId(currYoutubeUrl);        
        postUserMediaYoutubeUrl(id, youtubeId)
            .then((result: any) => {
                toast.info("YouTube video is added to your gallery!");
                getPrivateProfile(() => {
                    setShowMediaYoutubeAdder(false);
                    setIsUploadingMediaFile(false);
                });
            })
            .catch((error) => {
                console.error(error);
                getPrivateProfile(() => {
                    setIsUploadingMediaFile(false);
                    setShowMediaYoutubeAdder(false);
                })
            });
    };
    const viewLgMediaItem = (index) => {
        setLgCurrGalleryIndex(index);
    };
    const setLgMediaPrev = () => {
        if (!lgGalleryItems || !Array.isArray(lgGalleryItems)) {
            return;
        }

        if (lgGalleryItems.length === 1)
        {
            setLgCurrGalleryIndex(0);
        }

        let newIndex = 0;
        if (lgCurrGalleryIndex === 0) {
            newIndex = lgGalleryItems.length - 1;
        }
        else {
            newIndex = lgCurrGalleryIndex - 1;
        }

        setLgCurrGalleryIndex(newIndex);
    };
    const setLgMediaNext = () => {
        if (!lgGalleryItems || !Array.isArray(lgGalleryItems)) {
            return;
        }

        if (lgGalleryItems.length === 1)
        {
            setLgCurrGalleryIndex(0);
        }

        let newIndex = 0;
        if (lgCurrGalleryIndex === lgGalleryItems.length - 1) {
            newIndex = 0;
        }
        else {
            newIndex = lgCurrGalleryIndex + 1;
        }

        setLgCurrGalleryIndex(newIndex);
    };
    const [isDeletingMediaItem, setIsDeletingMediaItem] = useState<boolean>(false);
    const deleteMediaItem = (mediaId) => {
        setIsDeletingMediaItem(true);
        delUserMedia(id, mediaId)
            .then((result) => {
                console.log("Deleted mediaId");
                getPrivateProfile(() => {
                    setIsDeletingMediaItem(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setIsDeletingMediaItem(false);
            });
    };
    const [isGalleryImageExpanded, setIsGalleryImageExpanded] = useState<boolean>(false);
    const [expandedGalleryImageSrc, setExpandedGalleryImageSrc] = useState<string>('');
    const [expandedScreenTailwind, setExpandedScreenTailwind] = useState<string>('');
    const expandGalleryImage = (imgSrc): void => {
        setExpandedScreenTailwind(' h-screen ');
    
        setIsGalleryImageExpanded(true);
        setExpandedGalleryImageSrc(imgSrc);

    };
    const hideExpandedGalleryImage = (): void => {
        setIsGalleryImageExpanded(false);
        setExpandedGalleryImageSrc('');
    };

    const POLLING_INTERVAL_MS = 5 * 60_000;
    useEffect(() => {
        const w9Interval = setInterval(getW9s, POLLING_INTERVAL_MS);
        const privProfileInterval = setInterval(getPrivateProfile, POLLING_INTERVAL_MS);
        return () => {
            clearInterval(w9Interval);
            clearInterval(privProfileInterval);
        };
    }, []);

    useEffect(() => {
        history.replaceState('', 'MRA Profile', '/profile');
        history.pushState('', 'MRA Profile', '/profile');

        getPrivateProfile();
        getW9s();
    }, []);


    const setOfficeAddressRef = useRef<HTMLDivElement>(null);
    const setOfficePhoneNumberRef = useRef<HTMLDivElement>(null);
    const setCellPhoneNumberRef = useRef<HTMLDivElement>(null);
    const addALocationRef = useRef<HTMLDivElement>(null);
    const addBrokerW9Ref = useRef<HTMLDivElement>(null);
    const mraSubscriptionRef = useRef<HTMLDivElement>(null);
    // const [hashComponent, setHashComponent] = useState<string>('');
    
    // useEffect(() => {
    //     if (window.location.hash !== '') {
    //         setHashComponent(window.location.hash);
    //     }
    // }, [window.location.hash]);

    function scrollToComponent() {
        if (hashComponent === '#set-office-address' && setOfficeAddressRef && setOfficeAddressRef.current) {
            setOfficeAddressRef.current?.scrollIntoView();
            setOfficeAddressRef.current?.focus();
        }
        if (hashComponent === '#mra-subscription' && mraSubscriptionRef && mraSubscriptionRef.current) {
            mraSubscriptionRef.current?.scrollIntoView();
            mraSubscriptionRef.current?.focus();
        }
        if (hashComponent === '#set-office-phone-number' && setOfficePhoneNumberRef && setOfficePhoneNumberRef.current) {
            setOfficePhoneNumberRef.current?.scrollIntoView();
            setOfficePhoneNumberRef.current?.focus();
        }
        if (hashComponent === '#add-mobile-phone-number' && setCellPhoneNumberRef && setCellPhoneNumberRef.current) {
            setCellPhoneNumberRef.current?.scrollIntoView();
            setCellPhoneNumberRef.current?.focus();
        }
        if (hashComponent === '#add-a-location' && addALocationRef && addALocationRef.current) {
            addALocationRef.current?.scrollIntoView();
            addALocationRef.current?.focus();
        }
        if (hashComponent === '#add-broker-w9' && addBrokerW9Ref && addBrokerW9Ref.current) {
            addBrokerW9Ref.current?.scrollIntoView();
            addBrokerW9Ref.current?.focus();
        }

        if(!!hashComponent && hashComponent !== '') {
            window.history.replaceState("", document.title, window.location.pathname);
        }
    }
    
    useEffect(() => {
        if (!privateProfileData) return;
        scrollToComponent();
    }, [
        setOfficeAddressRef,
        setOfficeAddressRef.current,

        setOfficePhoneNumberRef,
        setOfficePhoneNumberRef.current,

        setCellPhoneNumberRef,
        setCellPhoneNumberRef.current,

        addALocationRef,
        addALocationRef.current,

        privateProfileData,
    ]);

    // Use a ref to access the quill instance directly
    const quillRef = useRef();
    

    return (
        <div className='relative mt-[25px] w-full h-full flex flex-col xs:items-start lg:items-center'>
            {/* Product Options Modal */}
            <Modal
                isOpen={isShowingProductOptionsModal}
                contentLabel="MRA Product Plans"
                style={{
                    overlay: {
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    },
                    content: {
                        margin: '0 auto',
                        width: '900px',
                        borderRadius: '16px',
                    },
                }}
                ariaHideApp={false}
            >
                <div className="flex flex-col self-stretch p-[10px] items-center justify-center gap-[20px]">
                    <div className="flex self-stretch items-end justify-end cursor-pointer">
                        <img
                            className="w-[30px] h-[30px]"
                            onClick={() => setIsShowingProductOptionsModal(false)}
                            src="/img/mra-dashboard/close-search-button.svg"
                        />
                    </div>    
                    <ProductPlans upgradeTo={upgradeTo} />
                </div>
            </Modal>
            <div className='relative w-[1240px] h-full p-0 flex flex-col items-center justify-center text-21xl text-primary font-small-semibold'>
                <form ref={formRef} className='w-full flex flex-col gap-[50px] p-0' onSubmit={(e) => e.preventDefault()}>
                
                    {/* Hero Image */}
                    <LockContainer
                        onClick={showProductOptionsModal}
                        lockIf={product === SubscriptionTypeEnum.FREE}
                        title='Showcase Your Spotlight Photo'
                        description='Go Premium to unlock this feature!'
                    >
                        <div className="flex flex-col w-full self-stretch items-start justify-center">
                            <div className='flex flex-row gap-[10px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                {product !== SubscriptionTypeEnum.FREE
                                    && <>
                                        <span>Profile Banner</span>
                                        <EditPencilIcon canEdit={canEdit} onClick={enableBannerUploader} />
                                    </>
                                }

                                {/* File Uploader UI */}
                                {showBannerUploader
                                    && <div className='flex flex-1 flex-row self-stretch text-md gap-[10px]'>
                                        <span>Upload (PNG/JPG only, Max 10MB, Best size: 1280 x 320)</span>
                                        <input
                                            className='flex w-[200px] stretch cursor-pointer text-black border border-solid border-gray-400 rounded-md px-4 py-2 items-center justify-end gap-[8px]'
                                            ref={bannerUploadFileRef}
                                            name='bannerfile'
                                            id='bannerfile'
                                            type='file'
                                            accept='image/png,image/jpeg'
                                        />
                                        <WaitContainer shouldWaitIf={isUploadingBanner}>
                                            <button
                                                className='cursor-pointer bg-secondary rounded-md px-4 py-2 flex items-center justify-end text-white'
                                                onClick={uploadBannerImage}
                                            >
                                                Upload
                                            </button>
                                            <button
                                                className='cursor-pointer bg-brown rounded-md px-4 py-2 flex items-center justify-end text-white'
                                                onClick={disableBannerUploader}
                                            >
                                                Cancel
                                            </button>
                                        </WaitContainer>
                                    </div>
                                }

                                {/* Delete button */}
                                {(privateProfileData?.heroImage && product !== SubscriptionTypeEnum.FREE)
                                    && <WaitContainer shouldWaitIf={isRemovingBannerImage}>
                                        <img
                                            className='w-[30px] cursor-pointer'
                                            title='Click here to remove this banner'
                                            src='/img/mra-profile/icon-trash.svg'
                                            onClick={removeBannerImage}
                                        />
                                    </WaitContainer>
                                }
                            </div>
                            
                            {/* The Banner */}
                            {product === SubscriptionTypeEnum.FREE
                                ? <div className='self-stretch flex flex-col h-[320px] p-0 grow items-start justify-start text-lg'>
                                    <img
                                        className="w-full h-[320px] rounded-md"
                                        alt=""
                                        src={getImageByWidthAspectRatio(DEFAULT_BANNER_ID, 1280)}
                                    />
                                </div>
                                : <> {privateProfileData?.heroImage
                                        ? <div className='self-stretch flex flex-col h-[320px] p-0 grow items-start justify-start text-lg'>
                                            <img
                                                className="h-full w-full object-left-top object-cover rounded-md"
                                                alt=""
                                                src={getImageByWidthAspectRatio(privateProfileData?.heroImage, 1280)}
                                            />
                                        </div>
                                        : <div className='self-stretch flex flex-col h-[50px] p-0 grow items-start justify-start text-lg'>
                                            <i>No Profile Banner Uploaded Yet.</i>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </LockContainer>

                    {/* Profile Info and Contact Info*/}
                    <div className='flex flex-row items-start justify-between gap-[20px]'>
                        {/* Avatar  */}
                        <WaitContainer shouldWaitIf={isUploadingDP} waitSizePx={75}>
                            <div className='flex w-fit cursor-pointer z-20 bg-transparent relative group items-center justify-start' onClick={openDPUploader}>
                                <img className='cursor-pointer relative w-[186px] h-[186px] rounded-full' alt='Profile Pic' src={avatarUrl} />
                                <input name='dpfile' type='file' className='hidden' accept='image/*' onChange={uploadDP} onClick={(event) => ((event.target as any).value = null)} />
                                <div className='rounded-md p-[10px] opacity-0 group-hover:opacity-70 duration-300 absolute inset-x-0 bottom-0 flex justify-center items-center bg-black text-white text-sm'>
                                    Click To Replace
                                </div>
                            </div>
                        </WaitContainer>

                        {/* Profile Info & Contact Info */}
                        <div className='flex flex-1 flex-row items-center justify-start gap-[20px]'>
                            
                            {/* Name and Brokerage */}
                            <div className='flex flex-1 flex-col items-start justify-start gap-[8px]'>
                                {/* Name  */}
                                <div className='flex flex-row flex-wrap items-center justify-start gap-[10px]'>
                                    <div className='relative text-5xl font-bold'>
                                        {privateProfileData?.firstName} {privateProfileData?.lastName}
                                    </div>
                                    <a className='text-md text-link-blue no-underline hover:underline' href={`/members/${profileUserData?.publicProfile}`}>View Public Profile</a>
                                    <img className='hidden relative w-[26px] h-[26px]' alt='' src='/img/mra-profile/iconsolidheart.svg' />
                                </div>

                                {/* Brokerage */}
                                <div ref={setOfficeAddressRef} className='flex flex-col items-start justify-start gap-[14px] text-lg'>
                                    
                                    {/* Brokerage Name And Address*/}
                                    <div className='flex flex-col items-start justify-end'>

                                        {/* Brokerage Name */}
                                        <div className='relative text-3xl-1 tracking-[-0.24px] leading-[26px]'>
                                            {privateProfileData?.brokerageName}
                                        </div>

                                        {/* Office City and State */}                                        
                                        <WaitContainer shouldWaitIf={isUpdatingOfficeAddress}>
                                            {showEditorOfficeAddress && (
                                                <div className='flex flex-col gap-[6px] justify-start items-start py-[16px]'>

                                                    {/* <span>Location: </span> */}
                                                    <div className='flex flex-row gap-[10px] justify-start items-center'>
                                                        <span className='w-[160px]'>Office Address 1 *</span>
                                                        <input
                                                            className='w-[300px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-start justify-start text-start border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='officeAddress1'
                                                            defaultValue={privateProfileData?.officeAddress1 ?? ''}
                                                            placeholder='Enter Office Address Line 1'
                                                            onBlur={(e) => setOfficeAddress1(e.target.value)}
                                                            autoComplete='profileofficeAddress1'
                                                            autoFocus={true}
                                                        />
                                                    </div>
                                                    <div className='flex flex-row gap-[10px] justify-start items-center'>
                                                        <span className='w-[160px]'>Office Address 2</span>
                                                        <input
                                                            className='w-[300px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-start text-start border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='officeAddress2'
                                                            defaultValue={privateProfileData?.officeAddress2 ?? ''}
                                                            placeholder='Enter Office Address Line 2'
                                                            onBlur={(e) => setOfficeAddress2(e.target.value)}
                                                            autoComplete='profileofficeAddress2'
                                                        />
                                                    </div>
                                                    {/* <div className='flex flex-row gap-[10px] justify-start items-center'>
                                                        <span className='flex w-[170px]'>City/State:</span>
                                                        <BuyerLocationsInput
                                                            key='officeCityState'
                                                            includeZipCode={false}
                                                            isMultiple={false}
                                                            // onLocationsUpdated={updateOfficeCityState}
                                                            onLocationsUpdated={setOfficeCityState}
                                                            buyerLocations={[
                                                                
                                                            ]}
                                                        />
                                                    </div> */}
                                                    <div className='flex flex-row gap-[10px] justify-start items-center'>
                                                        <span className='w-[160px]'>Office City *</span>
                                                        <input
                                                            className='w-[300px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-start justify-start text-start border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='officeCity'
                                                            defaultValue={privateProfileData?.officeCity ?? ''}
                                                            placeholder='Enter Office City'
                                                            onBlur={(e) => setOfficeCity(e.target.value)}
                                                            autoComplete='profileofficeCity'
                                                        />
                                                    </div>
                                                    <div className='flex flex-row gap-[10px] justify-start items-center'>
                                                        <span className='w-[160px]'>Office State *</span>
                                                        <Select
                                                            className='w-[320px]'
                                                            defaultValue={stateOptions.find((s) => s.value as number === profileUserData?.officeStateId)}
                                                            styles={selectStyle}
                                                            theme={globalReactSelectTheme}
                                                            options={stateOptions}
                                                            placeholder="Select/Type Office State"
                                                            onChange={(option) => setOfficeStateId(option?.value as number)}
                                                            onFocus={e => {
                                                                if (e.target.autocomplete) {
                                                                    e.target.autocomplete='new-password';
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='flex flex-row gap-[10px] justify-start items-center'>
                                                        <span className='w-[160px]'>Office Zipcode *</span>
                                                        <input
                                                            className='w-[300px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-start text-start border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            maxLength={5}
                                                            name='officeZip'
                                                            defaultValue={profileUserData?.officeZip ?? ''}
                                                            placeholder='Enter Office Zipcode'
                                                            onBlur={(e) => setOfficeZip(e.target.value)}
                                                            autoComplete='off'
                                                        />
                                                    </div>
                                                    <div className='flex w-full flex-row gap-[10px] justify-end items-end pt-[10px]'>
                                                        <button
                                                            className='self-stretch cursor-pointer bg-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateOfficeAddress}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='self-stretch cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={() => setShowEditorOfficeAddress(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                        
                                                    </div>
                                                </div>
                                            )}
                                            {!showEditorOfficeAddress
                                                && <div className='flex mr-[16px]'> 
                                                    {(!privateProfileData?.officeCity || !privateProfileData?.officeStateId)
                                                        ? <div className='flex justify-center items-center gap-[5px]'>
                                                            Set Office Address <EditPencilIcon canEdit={canEdit} onClick={enableEditOfficeAddress} />
                                                        </div>
                                                        : <div className='flex flex-row items-start justify-start gap-[12px]'>
                                                            <div className='flex flex-col items-start justify-start relative leading-[140%] font-medium text-xl mt-[6px]'>
                                                                {/* <img src="/img/mra-profile/iconlocationdot.svg" /> */}
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                    {privateProfileData?.officeAddress1 ? `${privateProfileData?.officeAddress1} ` : ''}
                                                                    <div className="self-stretch flex items-start">
                                                                        <EditPencilIcon title="Edit Address" canEdit={canEdit} onClick={enableEditOfficeAddress} />
                                                                    </div>
                                                                </div>
                                                                <div className="items-start justify-start">
                                                                    {privateProfileData?.officeAddress2 ? `${privateProfileData?.officeAddress2} ` : ''}
                                                                </div>
                                                                <div className="items-start justify-start">
                                                                    {privateProfileData?.officeCity ? `${privateProfileData?.officeCity}, ` : ''}
                                                                    {privateProfileData?.officeStateId ? `${usStates?.find((s) => s.id === privateProfileData?.officeStateId)?.abbreviation}, ` : ''}
                                                                    {privateProfileData?.officeZip ? `${privateProfileData?.officeZip}` : ''}
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    }
                                                    </div>
                                                }
                                        </WaitContainer>
                                    </div>

                                    {/* First Year or Service */}
                                    <div className='flex flex-col items-start justify-end'>
                                        {/* First Year of Service */}
                                        <WaitContainer shouldWaitIf={isUpdatingFirstYearOfService}>
                                            {showEditorFirstYearOfService && (
                                                <div className='w-full flex flex-row gap-[5px] tracking-[0.1px] leading-[24px] justify-center items-center py-[10px]'>
                                                    <input
                                                        className='w-[150px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                        type='number'
                                                        name='firstYearOfService'
                                                        min={1900}
                                                        max={new Date().getFullYear()}
                                                        defaultValue={privateProfileData?.firstYearOfService ?? new Date().getFullYear()}
                                                        placeholder='Realtor Since'
                                                        onBlur={(e) => setFirstYearOfServiceFormField(e.target.value)}
                                                    />
                                                    <button
                                                        className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                        onClick={updateFirstYearOfService}
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                        onClick={() => setShowEditorFirstYearOfService(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            )}
                                            {!showEditorFirstYearOfService &&
                                                (privateProfileData?.firstYearOfService ? (
                                                    <div className='flex justify-start items-center gap-[5px] text-3xl-1'>
                                                        <span>Realtor since {privateProfileData?.firstYearOfService}</span>
                                                        <EditPencilIcon canEdit={canEdit} onClick={enableEditFirstYearOfService} />
                                                    </div>
                                                ) : (
                                                    <div className='flex justify-start items-center gap-[5px] text-3xl-1'>
                                                        Realtor since? <EditPencilIcon canEdit={canEdit} onClick={enableEditFirstYearOfService} />
                                                    </div>
                                                ))}
                                        </WaitContainer>

                                        {/* {privateProfileData?.dateJoined && (
                                            <div className='relative tracking-[0.1px] leading-[24px]'>MRA Member since {new Date(privateProfileData?.dateJoined).getFullYear()}</div>
                                        )} */}
                                    </div>
                                </div>
                            </div>

                            {/* Contact Info*/}
                            <div ref={setOfficePhoneNumberRef} className='min-w-[380px] w-[650px] flex flex-col items-start justify-start gap-[8px]'>
                                
                                {/* Contact Info */}
                                <div ref={setCellPhoneNumberRef} className='flex flex-col self-stretch grow rounded-lg bg-colors-gs-white p-[20px] items-start justify-start gap-[8px] text-sm shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>                                    
                                    
                                    {/* Contact Info Header */}
                                    <div className='self-stretch flex flex-row items-start justify-start gap-[16px] text-lg font-semibold'>
                                        <div className='relative leading-[140%] text-xl'>Contact Info</div>
                                    </div>

                                    {/* Phone Number(s) */}
                                    <div className='self-stretch w-full rounded-sm flex flex-row items-center justify-start gap-[8px]'>                                
                                        
                                        {/* Cell Phone Number */}
                                        <div className='self-stretch rounded-sm flex-1 flex-row items-center justify-start text-lg'>
                                            <div className='flex font-semibold'>Cell</div>
                                            <div className='flex flex-1 relative leading-[21px]'>
                                                <WaitContainer shouldWaitIf={isUpdatingCell}>
                                                    {showEditorCell ? (
                                                        <div className='w-fit flex flex-col gap-[5px] tracking-[0.1px] leading-[24px] justify-center items-center py-[10px] pr-[5px]'>
                                                            <input
                                                                className='w-[150px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                                type='text'
                                                                name='cellPhoneNumber'
                                                                max={new Date().getFullYear()}
                                                                defaultValue={privateProfileData?.cellPhoneNumber}
                                                                placeholder='Cell'
                                                                onKeyUp={ensurePhoneFormat}
                                                                onBlur={(e) => setCell(e.target.value)}
                                                                autoFocus={true}
                                                            />
                                                            <div className='w-full flex flex-row justify-end items-center gap-[5px]'>
                                                                <button
                                                                    className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                    onClick={updateCell}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                    onClick={disableEditCell}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='flex flex-row gap-[8px] items-center'>
                                                            {privateProfileData?.cellPhoneNumber
                                                                && <a className='text-link-blue no-underline hover:underline' href={`tel:+1 ${privateProfileData?.cellPhoneNumber}`}>{privateProfileData?.cellPhoneNumber}</a>
                                                            }
                                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditCell} />
                                                        </div>
                                                    )}
                                                </WaitContainer>
                                            </div>
                                        </div>

                                        {/* Office Phone Number */}
                                        <div className='self-stretch rounded-sm flex-1 flex-row items-center justify-start text-lg'>
                                            <div className='relative font-semibold'>Office</div>
                                            <div className='flex-1 relative'>
                                                <WaitContainer shouldWaitIf={isUpdatingOffice}>
                                                    {showEditorOffice ? (
                                                        <div className='w-fit flex flex-col gap-[5px] tracking-[0.1px] leading-[24px] justify-center items-center py-[10px] pr-[5px]'>
                                                            <div className='w-fit flex flex-row gap-[5px] '>
                                                                <input
                                                                    className='w-[150px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                                    type='text'
                                                                    name='officePhoneNumber'
                                                                    defaultValue={privateProfileData?.officePhoneNumber ?? ''}
                                                                    placeholder='Office #'
                                                                    onKeyUp={ensurePhoneFormat}
                                                                    onBlur={(e) => setOffice(e.target.value)}
                                                                    autoFocus={true}
                                                                />
                                                                <input
                                                                    className='w-[70px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                                    type='text'
                                                                    name='officeExtension'
                                                                    maxLength={6}
                                                                    defaultValue={privateProfileData?.officePhoneExtension ?? ''}
                                                                    placeholder='Ext #'
                                                                    onBlur={(e) => setOfficeExtension(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className='w-full flex flex-row justify-end items-center gap-[5px]'>
                                                                <button
                                                                    className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                    onClick={updateOffice}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                    onClick={disableEditOffice}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='flex flex-row gap-[8px] items-center'>
                                                            {privateProfileData?.officePhoneNumber
                                                                && <>
                                                                    <a className='text-link-blue no-underline hover:underline' href={`tel:+1 ${privateProfileData?.officePhoneNumber}`}>{privateProfileData?.officePhoneNumber}</a>
                                                                    &nbsp;
                                                                    {privateProfileData?.officePhoneExtension
                                                                        && <>
                                                                            (Ext: {privateProfileData?.officePhoneExtension})
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditOffice} />
                                                        </div>
                                                    )}
                                                </WaitContainer>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[8px] text-lg'>
                                        <div className='font-semibold'>Email</div>
                                        <a href={`mailto: ${authEmail}`} className='leading-[21px] text-link-blue no-underline hover:underline'>{authEmail}</a>
                                    </div>

                                    {/* Primary Website */}
                                    <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[8px] text-lg'>
                                        <div className='self-stretch items-start font-semibold'>Website</div>
                                        <div className='flex w-full items-start justify-start'>
                                            <WaitContainer shouldWaitIf={isUpdatingWebsite}>
                                                {showEditorWebsite ? (
                                                    <div className='w-full max-w-[400px] flex flex-col gap-[5px] tracking-[0.1px] leading-[24px] justify-start items-start pr-[5px]'>
                                                        <input
                                                            className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='website'
                                                            defaultValue={privateProfileData?.primaryWebsite as string}
                                                            placeholder='Primary Website'
                                                            onBlur={(e) => setWebsite(e.target.value)}
                                                            autoFocus={true}
                                                            autoComplete='off'
                                                        />
                                                        <div className='w-full flex flex-row justify-start items-center gap-[5px]'>
                                                            <button
                                                                className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                onClick={updateWebsite}
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                onClick={disableEditWebsite}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='flex flex-row gap-[10px]'>
                                                        {privateProfileData?.primaryWebsite
                                                            &&  <a className='text-link-blue max-w-[500px] break-words no-underline hover:underline' target='_blank' href={privateProfileData?.primaryWebsite as string}>
                                                                    {privateProfileData?.primaryWebsite}
                                                                </a>
                                                        }
                                                        <EditPencilIcon canEdit={canEdit} onClick={enableEditWebsite} />
                                                    </div>
                                                )}
                                            </WaitContainer>
                                        </div>
                                    </div>

                                    {/* Partnerstack Link */}
                                    {(partnerstackLink && partnerstackLink !== '')
                                        && <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[12px] text-lg'>
                                            <div className='relative font-semibold '>Invite Link</div>
                                            <div className='relative text-link-blue cursor-pointer no-underline hover:underline' onClick={followPsLink}>
                                                {partnerstackLink}
                                            </div>
                                            <img src='/img/mra-profile/copy-icon.svg' className="w-[20px] h-[20px] cursor-pointer" onClick={copyPsLinkToClipboard} />
                                        </div>
                                    }

                                </div>

                                    
                            </div>
                            
                        </div>
                    </div>

                    {/* MRA Subscription & W9 */}
                    <div ref={mraSubscriptionRef} className='flex flex-row items-start justify-start gap-[20px]'>
                        
                        {/* MRA Subscription */}
                        <div className='self-stretch w-[30%] flex flex-col items-start justify-start gap-[20px] rounded-xl'>
                            
                            {/* MRA Subscription Header */}
                            <div className='w-full flex flex-row items-center gap-[16px]'>
                                <div className='flex justify-start relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>MRA Subscription</div>
                            </div>

                            <div className='self-stretch flex flex-col grow p-5 items-start justify-start text-lg gap-[12px] rounded-lg bg-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                {product && (
                                    <div className='self-stretch flex flex-col w-full items-start justify-start gap-[20px]'>  
                                        <div className='flex items-start justify-start capitalize text-5xl font-bold text-secondary'>
                                            {product.toLowerCase()} Plan
                                        </div>
                                        {product === SubscriptionTypeEnum.FREE && (
                                            <WaitContainer shouldWaitIf={startingCheckoutSession} waitSizePx={75}>
                                                <div className='flex flex-col items-start justify-start gap-[20px]'>
                                                    {product == SubscriptionTypeEnum.FREE
                                                        && <>
                                                            <button
                                                                className='cursor-pointer p-[20px] rounded-full bg-tomato text-white text-xl'
                                                                onClick={showProductOptionsModal}
                                                            >
                                                                Upgrade - See Plans
                                                            </button>
                                                            <span>Securely processed with <a className='text-link-blue hover:underline no-underline' href='https://www.stripe.com/' target='_blank'>Stripe</a></span>
                                                        </>
                                                    }
                                                </div>
                                            </WaitContainer>
                                        )}

                                        {/* {product === SubscriptionTypeEnum.FREE && (
                                            <a href="https://www-myreferralagent.webflow.io/pricing" target="_blank" className='text-xl text-link-blue cursor-pointer justify-end items-end'>
                                                Compare Plans
                                            </a>
                                        )} */}
                                    </div>
                                )}
                                
                                {(currSubscription && currSubscription.status && currSubscription.status === 'active')
                                    && <div className="self-stretch flex flex-col justify-start items-start text-secondary gap-[10px]">
                                        <div className="self-stretch flex flex-row justify-start items-start text-secondary gap-[5px]">
                                            <span className="capitalize">
                                                {currSubscription.status}: {getLocaleDateWithTzStrShort(new Date(currSubscription.current_period_start * 1000).toString())} - {getLocaleDateWithTzStrShort(new Date(currSubscription.current_period_end * 1000).toString())}
                                            </span>
                                        </div>
                                        <a
                                            className="mt-[10px] flex flex-row items-center justify-center no-underline px-4 py-2 border border-solid rounded-full text-secondary"
                                            target='_blank'
                                            href={stripeMgmtUrl}
                                        >
                                            Manage Subscription
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* W-9s */}
                        <div ref={addBrokerW9Ref} className='self-stretch w-[30%] flex flex-col items-start justify-start gap-[20px] rounded-xl '>
                            {/* W-9s Header */}
                            <div className='w-full flex flex-row items-center gap-[16px]'>
                                <div className='flex justify-start relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>W-9 Tax Form</div>
                            </div>

                            {/* W-9 Uploader/Downloader */}
                            <div className='self-stretch flex h-full flex-col grow p-5 items-start justify-start text-lg rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                <div className='flex flex-col w-full h-full items-start justify-start gap-[12px] text-xl'>
                                    {currW9
                                        ?  <div className='self-stretch flex flex-col w-full h-full relative tracking-[0.1px] leading-[24px] gap-[16px]'>
                                            <div className='text-3xl-1'>Broker&apos;s W-9</div>
                                            <div className='flex flex-col gap-[12px]'>
                                                <div
                                                    className='flex w-fit border border-solid px-4 p-2 text-secondary rounded-full cursor-pointer items-center justify-center'
                                                    onClick={downloadW9}
                                                >
                                                    Download
                                                </div>
                                                {/* <div className='border border-solid px-4 p-2 text-secondary rounded-full cursor-pointer' onClick={showW9Form}>
                                                    Replace
                                                </div> */}
                                            </div>
                                        </div>
                                        : <WaitContainer shouldWaitIf={isUploadingW9}>
                                            <div className='self-stretch w-full relative tracking-[0.1px] leading-[24px] flex flex-col gap-[16px]'>
                                                {!showW9Uploader ? <>
                                                    <div className='text-3xl-1'>Broker&apos;s W-9</div>
                                                    <div
                                                        className='flex w-fit items-center justify-center text-secondary border border-solid rounded-full px-4 py-2 cursor-pointer'
                                                        onClick={showW9Form}
                                                    >
                                                        Upload
                                                    </div>
                                                    <div className='flex flex-grow text-sm text-orange-700 justify-start'>* W9 filename should not contain special characters or spaces. </div>
                                                </> : (
                                                    <WaitContainer shouldWaitIf={w9SkyflowId === ''} waitSizePx={24}>
                                                        <div className='flex flex-col gap-[16px] items-center justify-start'>
                                                            <div className='flex flex-col gap-[16px] items-center justify-center'>
                                                                <div className='flex mt-2 cursor-pointer flex-col gap-[10px] text-md'>
                                                                    Click 'Choose File' below to select a file
                                                                    <br />
                                                                    File Types: pdf, png, jpg
                                                                    {(w9SkyflowId !== '')
                                                                        && <W9FileUploader
                                                                                id='w9-file-collect-element'
                                                                                table='table_w9_records'
                                                                                column='w9_file'
                                                                                skyflowID={w9SkyflowId}
                                                                                onW9Container={onW9Container}
                                                                                classes={w9UploadStyleClasses}
                                                                                onChange={(e) => {
                                                                                    console.log("w9 File onChange: ", e);
                                                                                    setIsW9FileSelectedForUpload(true);
                                                                                    setIsW9FileSelectedValid(e.isValid);
                                                                                }}
                                                                                onReady={() => {
                                                                                    const frames = document.getElementsByTagName('iframe');
                                                                                    for (const frame of frames) {
                                                                                        frame.setAttribute("data-hj-allow-iframe", "true");
                                                                                    }
                                                                                    setIsW9UploaderReady(true);
                                                                                }}
                                                                                onBlur={(e) => {
                                                                                    console.log(e);
                                                                                }}
                                                                            />
                                                                    }
                                                                </div>
                                                                {!isW9FileSelectedValid
                                                                    && <div className='flex w-full self-stretch text-sm text-tomato items-center justify-center'>
                                                                            * W9 filename should not contain spaces or special characters.
                                                                        </div>
                                                                }
                                                                {(w9SkyflowId !== '' && isW9UploaderReady)
                                                                    && <div className='flex flex-row gap-[12px]'>
                                                                            {isW9FileSelectedValid
                                                                                && <button
                                                                                    className='cursor-pointer bg-tomato rounded-md px-4 py-2 h-[40px] flex flex-row items-center justify-end gap-[8px] text-white'
                                                                                    onClick={uploadW9}
                                                                                >
                                                                                    Upload
                                                                                </button>
                                                                            }
                                                                            <button
                                                                                className='cursor-pointer bg-brand-secondary rounded-md px-4 py-2 h-[40px] flex flex-row items-center justify-end gap-[8px] text-white'
                                                                                onClick={cancelUploadW9}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                }
                                                            </div>                                                            
                                                        </div>
                                                    </WaitContainer>
                                                )}
                                            </div>
                                        </WaitContainer>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Referral Notification Copy Email */}
                        <div className='self-stretch flex flex-1'>
                            <LockContainer
                                onClick={showProductOptionsModal}
                                lockIf={product === SubscriptionTypeEnum.FREE}
                                description='Upgrade to add an additional notifier'
                            >
                                
                                <div className='self-stretch w-full flex flex-col items-start justify-start gap-[20px] rounded-xl'>
                                    
                                    {/* Referral Notification Copy Email Header */}
                                    <div className='w-full flex flex-row items-center gap-[1px]'>
                                        <div className='flex justify-start relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                            Referral Notifications
                                        </div>
                                        {/* <MRATooltip
                                            id="agent-profile-cc-tooltip"
                                            message="Referral notifications will be sent to<br />this additional email address"
                                        /> */}
                                    </div>

                                    <div className='self-stretch flex h-full flex-col grow p-5 items-start justify-start text-lg rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>                                
                                        {/* Referral Notification Copy Email Editor */}
                                        <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[12px] text-lg'>
                                            
                                            <div className='flex-1 relative'>
                                                <WaitContainer shouldWaitIf={isUpdatingRefCCEmail}>
                                                    {showEditorRefCCEmail ? (
                                                        <div className='w-full flex flex-col gap-[12px] justify-start items-start'>
                                                            <div className="w-full flex flex-row items-start justify-start gap-[10px] text-3xl-1">
                                                                Add An Additional Email
                                                            </div>
                                                            <input
                                                                className='w-full max-w-[400px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                                type='email'
                                                                name='referral_cc_statistic_email'
                                                                defaultValue={privateProfileData?.statisticEmails?.split('\t')[0] as string || ''}
                                                                placeholder='Referral Notification Email Address'
                                                                onBlur={(e) => setRefCCEmail(e.target.value)}
                                                                autoComplete='off'
                                                                autoFocus={true}
                                                            />
                                                            <div className='text-red-500 text-md'>* Do not enter the primary account email address.</div>
                                                            <div className='w-full flex flex-row justify-start items-start gap-[5px]'>
                                                                <button
                                                                    className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                    onClick={updateRefCCEmail}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                    onClick={disableEditRefCCEmail}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='flex flex-col gap-[10px]'>
                                                            <div className="w-full flex flex-row items-center justify-start gap-[10px] text-3xl-1">
                                                                Additional Email Option
                                                                <EditPencilIcon canEdit={canEdit} onClick={enableEditRefCCEmail} />
                                                                {(privateProfileData?.statisticEmails && privateProfileData?.statisticEmails.split('\t')[0])
                                                                    && <img
                                                                            className='w-[28px] cursor-pointer z-50'
                                                                            title='Click to remove this additional referral notifier.'
                                                                            src='/img/mra-profile/icon-trash.svg'
                                                                            onClick={deleteRefCCEmail}
                                                                        />
                                                                }
                                                                
                                                            </div>
                                                            {(privateProfileData?.statisticEmails && privateProfileData?.statisticEmails.split('\t')[0])
                                                                && <div className="flex flex-row gap-[10px] items-center">
                                                                        <a 
                                                                            className='text-link-blue max-w-[500px] break-words no-underline hover:underline'
                                                                            target='_blank'
                                                                            href={`mailto: ${privateProfileData?.statisticEmails.split('\t')[0] as string}`}
                                                                        >
                                                                            {privateProfileData?.statisticEmails.split('\t')[0]}
                                                                        </a>
                                                                    </div>
                                                            }
                                                        </div>
                                                    )}
                                                    
                                                    <div className="text-lg flex-wrap mt-[10px] leading-[28px]">
                                                        By default, the primary email address receives referral notification emails.
                                                        This additional email address will also receive copies of those referral notifications.
                                                    </div>
                                                </WaitContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </LockContainer>
                        </div>
                    </div>
                    
                    {/* Bio */}
                    <div className='flex flex-col items-start justify-start gap-[20px]'>
                        
                        {/* Bio Header */}
                        <div className='flex flex-row justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold gap-[20px]'>
                            <span>About Me</span>
                            <EditPencilIcon canEdit={canEdit} onClick={enableEditBio} />
                        </div>

                        {/*  Bio  */}
                        <div className='w-full flex flex-col self-stretch items-start justify-start p-[12px] gap-[20px] bg-white rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>
                            
                            {/* Bio Updater */}
                            <WaitContainer shouldWaitIf={isUpdatingBio} waitSizePx={20}>
                                <div className='self-stretch flex flex-row items-start justify-start p-[10px] text-[20px]'>
                                    {showEditorBio && (
                                        <div className='w-full flex flex-col gap-[10px] justify-start items-start'>
                                            <RichTextEditor
                                                ref={quillRef}
                                                options={{
                                                    theme: 'snow',
                                                    modules: {
                                                        toolbar: [
                                                            [{ header: [1, 2, 3, false] }],
                                                            ['bold', 'italic'],
                                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                        ]
                                                    },
                                                    placeholder: 'Please tell us about yourself',
                                                }}
                                                readOnly={false}
                                                defaultValue={`${privateProfileData?.general}`}
                                                onSelectionChange={(range) => {}}
                                                onTextChange={(deltaObj) => {
                                                    const quill = quillRef.current as unknown as Quill;
                                                    const textLength = quill.getLength();
                                                    const html = quill.getSemanticHTML();
                                                    console.log("HTMLLLL", html);

                                                    setBioFormField(html);
                                                    setBioCharsLeft(MAX_BIO_CHARS - textLength + 1);
                                                }}
                                                limit={MAX_BIO_CHARS}                                                
                                            />

                                            {/* <textarea
                                                className='w-full resize-none overflow-y-auto font-regular text-xl bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border-[1px] border-solid border-gray-400'
                                                rows={24}
                                                cols={100}
                                                defaultValue={privateProfileData?.general}
                                                maxLength={MAX_BIO_CHARS}
                                                onKeyUp={bioLengthCheck}
                                                onBlur={(e) => setBioFormField(e.target.value)}
                                            /> */}
                                            
                                            <div className='w-full flex flex-row gap-[5px] items-end justify-end'>
                                                <div className='flex flex-1 items-start justify-start'>{bioCharsLeft} characters left</div>
                                                <div className='flex flex-1 items-end justify-end gap-[5px]'>
                                                    <button
                                                        className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                        onClick={updateBio}
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                        onClick={disableEditBio}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!showEditorBio
                                        && <>
                                            {privateProfileData?.general
                                                ? <div
                                                    className='w-fit text-[20px] leading-[30px] p-[10px]'
                                                    dangerouslySetInnerHTML={{ __html: privateProfileData?.general }}
                                                />
                                                : <i>Please tell us about yourself</i>
                                            }
                                        </>
                                    }
                                </div>
                            </WaitContainer>
                        </div>
                    </div>


                    {/* Social Media and Additional Websites */}
                    <div className='flex flex-row items-center justify-center gap-[20px]'>

                        {/* Social Media */}
                        <div className='self-stretch w-3/5 flex flex-col items-start justify-start gap-[20px]'>

                            {/* Social Media Header */}
                            <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                <span>Social Media</span>
                            </div>

                            {/* Social Media List */}
                            <LockContainer onClick={showProductOptionsModal}
                                lockIf={product === SubscriptionTypeEnum.FREE}
                                description='Upgrade to showcase your various social media'
                            >
                                <div className='self-stretch flex flex-col gap-[24px] rounded-lg bg-white p-5 items-start justify-start text-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>
                                    
                        
                                    {/* Social Media - Facebook */}
                                    <div className='truncate w-full self-stretch flex flex-col items-start justify-start gap-[6px] text-xl text-secondary'>
                                        <div className='self-stretch flex flex-row gap-[10px]'>
                                            <div className='text-2xl font-bold flex flex-row items-center gap-[10px]'>
                                                <img src='/img/mra-social-media/facebook.svg' className='w-[30px]' />
                                                Facebook
                                            </div>
                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditSocialFacebook} />
                                        </div>
                                        {showEditorSocialFacebook && (
                                            <WaitContainer shouldWaitIf={isUpdatingSocialFacebook}>
                                                <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <input
                                                            className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='twitterId'
                                                            placeholder='Enter A Valid Facebook Profile/Page URL'
                                                            onBlur={(e) => setSocialFacebook(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                        <button
                                                            className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateSocialFacebook}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={disableEditSocialFacebook}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </WaitContainer>
                                        )}
                                        {!showEditorSocialFacebook && (privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)) && 
                                            <a
                                                target="_blank"
                                                className='ml-[40px] hover:underline text-link-blue no-underline text-lg cursor-pointer'
                                                title={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                                href={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                            >
                                                {privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                            </a>
                                        }
                                    </div>

                                    {/* Social Media - Instagram */}
                                    <div className='truncate w-full self-stretch flex flex-col items-start justify-start gap-[6px] text-xl text-secondary'>
                                        <div className='self-stretch flex flex-row gap-[10px]'>
                                            <div className='text-2xl font-bold flex flex-row items-center gap-[10px]'>
                                                <img src='/img/mra-social-media/instagram.svg' className='w-[30px]' />
                                                Instagram
                                            </div>
                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditSocialInstagram} />
                                        </div>
                                        {showEditorSocialInstagram && (
                                            <WaitContainer shouldWaitIf={isUpdatingSocialInstagram}>
                                                <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <input
                                                            className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='twitterId'
                                                            placeholder='Enter A Valid Instagram URL'
                                                            onBlur={(e) => setSocialInstagram(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                        <button
                                                            className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateSocialInstagram}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={disableEditSocialInstagram}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </WaitContainer>
                                        )}
                                        {!showEditorSocialInstagram && (privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)) && 
                                            <a
                                                target="_blank"
                                                className='ml-[40px] hover:underline text-link-blue no-underline text-lg cursor-pointer'
                                                title={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                                href={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                            >
                                                {privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                            </a>
                                        }
                                    </div>
            
                                    {/* Social Media - Twitter */}
                                    <div className='truncate w-full self-stretch flex flex-col items-start justify-start gap-[6px] text-xl text-secondary'>
                                        <div className='self-stretch flex flex-row gap-[10px]'>
                                            <div className='text-2xl font-bold flex flex-row items-center gap-[10px]'>
                                                <img src='/img/mra-social-media/twitter.svg' className='w-[30px]' />
                                                X (formerly Twitter)
                                            </div>
                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditSocialTwitter} />
                                        </div>
                                        {showEditorSocialTwitter && (
                                            <WaitContainer shouldWaitIf={isUpdatingSocialTwitter}>
                                                <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <input
                                                            className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='twitterId'
                                                            placeholder='Enter A Valid X Feed URL'
                                                            defaultValue={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url ?? ''}
                                                            onBlur={(e) => setSocialTwitter(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                        <button
                                                            className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateSocialTwitter}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={disableEditSocialTwitter}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </WaitContainer>
                                        )}
                                        {!showEditorSocialTwitter && (privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)) && 
                                            <a
                                                target="_blank"
                                                className='ml-[40px] hover:underline text-link-blue no-underline text-lg cursor-pointer'
                                                title={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                                href={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                            >
                                                {privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                            </a>
                                        }
                                    </div>

                                    {/* Social Media - YouTube Channel */}
                                    <div className='truncate w-full self-stretch flex flex-col items-start justify-start gap-[6px] text-xl text-secondary'>
                                        <div className='self-stretch flex flex-row gap-[10px]'>
                                            <div className='text-2xl font-bold flex flex-row items-center gap-[10px]'>
                                                <img src='/img/mra-social-media/youtube.svg' className='w-[30px]' />
                                                YouTube Channel
                                            </div>
                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditSocialYouTubeChannel} />
                                        </div>
                                        {showEditorSocialYouTubeChannel && (
                                            <WaitContainer shouldWaitIf={isUpdatingSocialYouTubeChannel}>
                                                <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <input
                                                            className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='twitterId'
                                                            placeholder='Enter A Valid YouTube Channel URL'
                                                            onBlur={(e) => setSocialYouTubeChannel(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                        <button
                                                            className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateSocialYouTubeChannel}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={disableEditSocialYouTubeChannel}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </WaitContainer>
                                        )}
                                        {!showEditorSocialYouTubeChannel && (privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)) && 
                                            <a
                                                target="_blank"
                                                className='ml-[40px] hover:underline text-link-blue no-underline text-lg cursor-pointer'
                                                title={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                                href={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                            >
                                                {privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                            </a>
                                        }
                                    </div>

                                    {/* Social Media - TikTok */}
                                    <div className='truncate w-full self-stretch flex flex-col items-start justify-start gap-[6px] text-xl text-secondary'>
                                        <div className='self-stretch flex flex-row gap-[10px]'>
                                            <div className='text-2xl font-bold flex flex-row items-center gap-[10px]'>
                                                <img src='/img/mra-social-media/tiktok.svg' className='w-[30px]' />
                                                TikTok
                                            </div>
                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditSocialTikTok} />
                                        </div>
                                        {showEditorSocialTikTok && (
                                            <WaitContainer shouldWaitIf={isUpdatingSocialTikTok}>
                                                <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <input
                                                            className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='tiktokId'
                                                            placeholder='Enter A Valid TikTok URL'
                                                            onBlur={(e) => setSocialTikTok(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                        <button
                                                            className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateSocialTikTok}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={disableEditSocialTikTok}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </WaitContainer>
                                        )}
                                        {!showEditorSocialTikTok && (privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)) && 
                                            <a
                                                target="_blank"
                                                className='ml-[40px] hover:underline text-link-blue no-underline text-lg cursor-pointer'
                                                title={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                                href={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                            >
                                                {privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                            </a>
                                        }
                                    </div>
                            
                                    {/* Social Media - LinkedIn */}
                                    <div className='truncate w-full self-stretch flex flex-col items-start justify-start gap-[6px] text-xl text-secondary'>
                                        <div className='self-stretch flex flex-row gap-[10px]'>
                                            <div className='text-2xl font-bold flex flex-row items-center gap-[10px]'>
                                                <img src='/img/mra-social-media/linkedin.svg' className='w-[30px]' />
                                                LinkedIn
                                            </div>
                                            <EditPencilIcon canEdit={canEdit} onClick={enableEditSocialLinkedIn} />
                                        </div>
                                        {showEditorSocialLinkedIn && (
                                            <WaitContainer shouldWaitIf={isUpdatingSocialLinkedIn}>
                                                <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <input
                                                            className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='twitterId'
                                                            placeholder='Enter A Valid LinkedIn Profile URL'
                                                            onBlur={(e) => setSocialLinkedIn(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                        <button
                                                            className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateSocialLinkedIn}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={disableEditSocialLinkedIn}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </WaitContainer>
                                        )}
                                        {!showEditorSocialLinkedIn && (privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)) && 
                                            <a
                                                target="_blank"
                                                className='ml-[40px] hover:underline text-link-blue no-underline text-lg cursor-pointer'
                                                title={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                                href={privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                            >
                                                {privateProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                            </a>
                                        }
                                    </div>
                                </div>

                            </LockContainer>
                        </div>

                        {/* Additional Websites */}
                        <div className='self-stretch w-2/5 flex flex-col items-start justify-start gap-[20px]'>

                            {/* Websites Header */}
                            <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                <span>Additional Websites</span>
                                {(product !== SubscriptionTypeEnum.FREE && !showAddMiscWebsite)
                                    && <div className='flex flex-row text-md justify-start items-center gap-[10px] cursor-pointer' onClick={() => setShowAddMiscWebsite(true)}>
                                        Add +
                                    </div>
                                }
                            </div>

                            {/* Websites List */}
                            <LockContainer onClick={showProductOptionsModal}
                                lockIf={product === SubscriptionTypeEnum.FREE}
                                description='Upgrade to show more sites'
                            >
                                <div className='self-stretch flex grow flex-col rounded-lg bg-white p-5 items-start justify-start text-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>
                                    <div className='self-stretch flex flex-col items-start justify-start gap-[11px]'>
                                        <div className='self-stretch flex flex-col items-start justify-start gap-[12px] text-xl'>
                                            {showAddMiscWebsite && (
                                                <WaitContainer shouldWaitIf={isAddingMiscWebsite}>
                                                    <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                            <input
                                                                className='w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                                type='text'
                                                                name='websiteId'
                                                                placeholder='Additional Website URL'
                                                                onBlur={(e) => setMiscWebsiteUrl(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                            <button
                                                                className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                onClick={addMiscWebsite}
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                                onClick={() => setShowAddMiscWebsite(false)}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </WaitContainer>
                                            )}
                                            {product === SubscriptionTypeEnum.FREE
                                                ? <i>-</i>
                                                : <>
                                                    {(privateProfileData?.userWebsites && privateProfileData.userWebsites.length > 0)
                                                        && <>
                                                            {privateProfileData?.userWebsites.map((s, index) => (
                                                                <div
                                                                    className='flex flex-row gap-[5px] items-center'
                                                                    key={'view-' + s.id + '' + s.userId + '' + index}
                                                                >
                                                                    <a
                                                                        href={atob(decodeURI(s.url))}
                                                                        target='_blank'
                                                                        className='text-link-blue cursor-pointer no-underline hover:underline'
                                                                    >
                                                                        {atob(decodeURI(s.url))}
                                                                    </a>
                                                                    <div
                                                                        key={'close-' + s.id + '' + s.userId + '' + index}
                                                                        className='flex items-center h-full px-[10px] font-bold cursor-pointer text-sm rounded-md hover:bg-red-100 text-red-300'
                                                                        onClick={() => delMiscWebsite(s.id)}
                                                                    >X</div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    }
                                                    {!isAddingMiscWebsite && privateProfileData?.userWebsites && privateProfileData.userWebsites.length === 0 && <i>No Additional Websites Added</i>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </LockContainer>
                        </div>

                    </div>
                    

                    {/* Locations & Licenses */}
                    <div ref={addALocationRef} className='flex flex-row items-start justify-start gap-[20px]'>
                        
                        {/* Location Served */}
                        <div className='w-3/5 flex flex-col self-stretch items-start justify-start gap-[20px] rounded-xl'>
                            <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                <span>Locations Served</span>
                                {!showEditorLocations && (
                                    <div className='flex flex-row text-md justify-start items-center gap-[10px] cursor-pointer' onClick={enableEditLocationSpecialties}>
                                        Add +
                                    </div>
                                )}
                            </div>
                            <div className='self-stretch rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start gap-[16px] text-base'>
                                <WaitContainer shouldWaitIf={isUpdatingLocations}>
                                    <div className='w-full flex flex-col items-start justify-start gap-[16px]'>
                                        <div className='w-full flex flex-col gap-[16px] items-start justify-start'>
                                            {showEditorLocations && (
                                                <div className='w-full h-full flex flex-row gap-[10px] justify-center items-center'>
                                                    <BuyerLocationsInput locations={locationSpecialties} isMultiple includeZipCode onLocationsUpdated={addLocationsCitiesOrZipcodes} />
                                                    {locationSpecialties && locationSpecialties.length > 0 && (
                                                        <button
                                                            className='h-full stretch cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateLocationsCities}
                                                        >
                                                            Save
                                                        </button>
                                                    )}
                                                    <button
                                                        className='h-full stretch cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                        onClick={disableEditLocationSpecialties}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            )}
                                            <div className='w-full self-stretch flex flex-row justify-start items-center gap-[10px] tracking-[0.02em] leading-[24px]'>
                                                <div>Cities ({privateProfileData?.servedCities.length}/{citiesLimit})</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch rounded-sm flex flex-row flex-wrap items-start justify-start gap-[8px] text-center text-base-6 text-colors-gs-tertiary'>
                                            {(privateProfileData?.servedCities && !!privateProfileData?.servedCities.length)
                                                && privateProfileData?.servedCities.map((c) => (
                                                    
                                                    <div
                                                        key={c.locationId}
                                                        className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.40000057220459px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                    >
                                                        <div className='relative font-semibold min-w-[20px] flex flex-row justify-start items-center gap-[10px]'>
                                                            <span>
                                                                {c.city}, {usStates?.find((s) => s.id === c.stateId)?.abbreviation}
                                                            </span>
                                                            <div
                                                                className='flex h-full items-center px-[10px] font-bold cursor-pointer text-sm rounded-md hover:bg-red-100 text-red-300'
                                                                onClick={() => removeCityLocationSpecialty(c.locationId)}
                                                            >
                                                                X
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {(privateProfileData?.servedCities && !!privateProfileData?.servedCities.length)
                                                && (product === SubscriptionTypeEnum.FREE && privateProfileData?.servedCities.length >= citiesLimit)
                                                    && <img width='234' height='43' src='/img/mra-profile/upgrade-lock-cities.svg' />
                                            }
                                            {(!privateProfileData?.servedCities || privateProfileData?.servedCities.length === 0) && <i>&nbsp;&nbsp;No Cities Provided</i>}
                                        </div>

                                        {/* Zipcode Preferences */}
                                        <div className='self-stretch flex flex-col items-start justify-start'>
                                            <div className='relative tracking-[0.02em] leading-[24px]'>Zip Codes ({privateProfileData?.servedZipCodes.length}/{zipcodesLimit})</div>
                                        </div>
                                        <div className='self-stretch rounded-sm flex flex-row flex-wrap items-start justify-start gap-[8px] text-center text-base-6 text-colors-gs-tertiary'>
                                            {(privateProfileData?.servedZipCodes && !!privateProfileData?.servedZipCodes.length)
                                             && privateProfileData?.servedZipCodes.map((c) => (
                                                    <div
                                                        key={c.locationId}
                                                        className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.40000057220459px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                    >
                                                        <div className='relative font-semibold min-w-[20px] flex flex-row justify-start items-center gap-[10px]'>
                                                            <span>{c.zip}</span>
                                                            <div
                                                                className='flex h-full items-center px-[10px] font-bold cursor-pointer text-sm rounded-md hover:bg-red-100 text-red-300'
                                                                onClick={() => removeZipCodeLocationSpecialty(c.locationId)}
                                                            >
                                                                X
                                                            </div>
                                                        </div>
                                                    </div>
                                            ))}
                                            {(privateProfileData?.servedZipCodes && !!privateProfileData?.servedZipCodes.length)
                                                && (product === SubscriptionTypeEnum.FREE && privateProfileData?.servedZipCodes.length >= zipcodesLimit)
                                                    && <img width='234' height='43' src='/img/mra-profile/upgrade-lock-zipcodes.svg' />
                                            }
                                            {!privateProfileData?.servedZipCodes || (privateProfileData?.servedZipCodes.length === 0 && <i>&nbsp;&nbsp;No Zip Codes Provided</i>)}
                                        </div>
                                    </div>
                                </WaitContainer>
                            </div>
                        </div>

                        {/* Licenses */}
                        <div className='w-2/5 flex flex-col self-stretch items-start justify-start gap-[20px] rounded-xl'>
                            <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                <span>Licenses/Registrations</span>
                                {!showAddLicense && (
                                    <div className='flex flex-row text-md justify-start items-center gap-[10px] cursor-pointer' onClick={() => setShowAddLicense(true)}>
                                        Add +
                                    </div>
                                )}
                            </div>
                            <div className='self-stretch flex-1 flex flex-col rounded-lg bg-colors-gs-white p-5 items-start justify-start text-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>
                                <div className='self-stretch flex flex-col items-start justify-start gap-[11px]'>
                                    <div className='self-stretch flex flex-col items-start justify-start gap-[12px] text-xl'>
                                        {showAddLicense && (
                                            <WaitContainer shouldWaitIf={isAddingLicense}>
                                                <div className='self-stretch flex flex-col items-center justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='w-[200px]'>
                                                            <Select
                                                                className='w-full'
                                                                styles={selectStyle}
                                                                theme={globalReactSelectTheme}
                                                                options={stateOptions}
                                                                onChange={onChangeLicenseState}
                                                                placeholder={'State'}
                                                                required
                                                            />
                                                        </div>
                                                        <input
                                                            className='w-[150px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='text'
                                                            name='licenseId'
                                                            placeholder='License #/ID'
                                                            onBlur={(e) => setLicense(e.target.value)}
                                                            required
                                                        />
                                                        <input
                                                            className='w-[150px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='number'
                                                            name='licenseYear'
                                                            max={new Date().getFullYear()}
                                                            placeholder='License Year'
                                                            onBlur={(e) => setLicenseYear(parseInt(e.target.value))}
                                                            required
                                                        />
                                                        {/* <input
                                                            className='w-[100px] font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-center text-center border-[1px] border-solid border-gray-400'
                                                            type='number'
                                                            name='licenseYearExpiry'
                                                            max={new Date().getFullYear()}
                                                            placeholder='License Expiry Year'
                                                            onBlur={(e) => setLicenseYearExpiry(parseInt(e.target.value))}
                                                            required
                                                        /> */}
                                                    </div>
                                                    <div className='self-stretch flex flex-row items-center justify-end gap-[12px]'>
                                                        <button
                                                            className='h-full cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateLicense}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className='h-full cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={() => setShowAddLicense(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </WaitContainer>
                                        )}
                                        {privateProfileData?.stateLicenses && privateProfileData.stateLicenses.length > 0 && (
                                            <div className="flex flex-col gap-[10px]">
                                                {privateProfileData?.stateLicenses.map((s, index) => (
                                                    <div
                                                        key={s.stateId + '' + s.yearLicensed + '' + index}
                                                        className={`${s.status === LicenseStatus.rejected && 'line-through'} flex flex-row gap-[6px] items-center`}
                                                    >   
                                                        {/* {s.status === LicenseStatus.verified && <MRAVerifiedLogo />} */}
                                                        {usStates?.find((us) => us.id === s.stateId)?.name}, {s.number}, since {s.yearLicensed}
                                                        
                                                        {s.status === LicenseStatus.none && <i>(Pending Approval)</i>}
                                                        {s.status === LicenseStatus.rejected && <b>Rejected</b>}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {privateProfileData?.stateLicenses && privateProfileData.stateLicenses.length === 0 && <i>No Licenses Added</i>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Production History */}
                    <div className='hidden flex flex-col items-start justify-start gap-[20px] text-11xl text-dark font-h3'>
                        <div className='relative tracking-[-0.8px] leading-[137%] font-semibold'>Production History</div>
                        <LockContainer
                            lockIf={product === SubscriptionTypeEnum.FREE}
                            title='Showcase Your History'
                            description='Upgrade to show your production history to other referring agents'
                        >
                            <div className='rounded-lg bg-colors-gs-white shadow-md flex flex-col py-4 px-0 box-border items-start justify-start text-base'>
                                <div className='self-stretch flex flex-col items-start justify-start gap-[12px]'>
                                    <div className='self-stretch h-[22px] flex flex-row py-0 px-4 box-border items-center justify-start gap-[16px]'>
                                        <div className='flex-1 flex flex-row py-2 px-0 items-center justify-start'>
                                            <div className='relative tracking-[0.02em] leading-[24px]'>2022</div>
                                        </div>
                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                            <div className='relative tracking-[0.02em] leading-[24px]'>2021</div>
                                        </div>
                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                            <div className='relative tracking-[0.02em] leading-[24px]'>2020</div>
                                        </div>
                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                            <div className='relative tracking-[0.02em] leading-[24px]'>2019</div>
                                        </div>
                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                            <div className='relative tracking-[0.02em] leading-[24px]'>2018</div>
                                        </div>
                                    </div>
                                    <div className='self-stretch flex flex-row py-0 px-4 items-center justify-start gap-[16px] text-sm'>
                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>65</div>
                                            </div>
                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator1.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$36,879,000</div>
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator2.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>54</div>
                                            </div>
                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator3.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$9,230,000</div>
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator4.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>39</div>
                                            </div>
                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator5.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$1,752,000</div>
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator6.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>47</div>
                                            </div>
                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator7.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$879,000</div>
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[24px]'>
                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator8.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>38</div>
                                            </div>
                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator9.svg' />
                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$745,000</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LockContainer>
                    </div>


                    {/* Designations & Specialties */}
                    <div className='flex flex-row items-start justify-center gap-[20px]'>
                        {/* Designations */}
                        <div className='w-1/2 flex flex-col self-stretch items-start justify-start rounded-xl gap-[20px]'>
                            <div className='flex flex-row items-center gap-[16px]'>
                                <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold'>Designations</div>
                                {(product !== SubscriptionTypeEnum.FREE && !showEditorDesignations) && (
                                    <div className='flex flex-row text-md justify-start items-center gap-[10px] cursor-pointer' onClick={enableEditorUserDesignations}>
                                        Add +
                                    </div>
                                )}
                            </div>
                            <LockContainer onClick={showProductOptionsModal} lockIf={product === SubscriptionTypeEnum.FREE} horizontal description='Upgrade to showcase Designations'>
                                <div className='flex flex-col self-stretch rounded-lg bg-colors-gs-white p-5 items-start justify-start text-base font-large shadow-md'>
                                    <WaitContainer shouldWaitIf={isUpdatingUserDesignations} waitSizePx={32}>
                                        <div className='self-stretch w-full flex flex-col items-start justify-start gap-[20px]'>
                                            {showEditorDesignations && (
                                                <div className='self-stretch w-full flex flex-row items-center justify-start gap-[10px]'>
                                                    <div className='self-stretch flex-1'>
                                                        <Select
                                                            isMulti
                                                            placeholder="Select Designation(s)"
                                                            options={designationList
                                                                ?.map((d) => {
                                                                    return {
                                                                        label: d.abbreviation,
                                                                        value: d.id,
                                                                        designation: d,
                                                                    };
                                                                })
                                                                .filter((o) => !isUserDesigationAlreadySelected(o.designation))}
                                                            styles={reactSelectColorStyles}
                                                            theme={globalReactSelectTheme}
                                                            onChange={(selected) => setUserDesignations(selected)}
                                                        />
                                                    </div>
                                                    {userDesignations.length > 0 && (
                                                        <button
                                                            className='cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateUserDesignations}
                                                        >
                                                            Save
                                                        </button>
                                                    )}
                                                    <button
                                                        className='cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                        onClick={disableEditorUserDesignations}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            )}
                                            <div className='self-stretch relative tracking-[0.1px] leading-[24px] flex flex-col gap-[0px]'>
                                                {privateProfileData && privateProfileData.designations && (
                                                    <div className='flex flex-col gap-[3px]'>
                                                        {privateProfileData?.designations?.length === 0 ? (
                                                            <i> No Designations</i>
                                                        ) : (
                                                            <ol>
                                                                {(privateProfileData?.designations as Array<any>)
                                                                    .map((d) => {
                                                                        const { abbreviation, description } = designationList?.find((s) => s.id === d.designationId) as any;
                                                                        return {
                                                                            ...d,
                                                                            abbreviation,
                                                                            description,
                                                                        };
                                                                    })
                                                                    .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation))
                                                                    .map((s) => {
                                                                        return (
                                                                            <li key={s.designationId}>
                                                                                <div className='my-[5px] rounded-8xs-8 w-fit bg-colors-gs-surface-primary box-border overflow-hidden flex flex-row p-[5px] px-[10px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'>
                                                                                    <div>
                                                                                        {s?.abbreviation}
                                                                                        {s?.description ? ` - ${s?.description}` : ''}
                                                                                    </div>
                                                                                    <div
                                                                                        className='flex h-full items-center px-[10px] font-bold cursor-pointer text-sm rounded-md hover:bg-red-100 text-red-300'
                                                                                        onClick={() => removeUserDesignation(s)}
                                                                                    >
                                                                                        X
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })}
                                                            </ol>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </WaitContainer>
                                </div>
                            </LockContainer>
                        </div>

                        {/* Specialties */}
                        <div className='w-1/2 flex flex-col self-stretch items-start justify-start rounded-xl gap-[20px]'>
                            <div className='w-full flex flex-row items-center gap-[16px]'>
                                <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold'>Specialties</div>
                                {(product !== SubscriptionTypeEnum.FREE && !showEditorUserSpecialties) && (
                                    <div className='flex flex-row text-md justify-start items-center gap-[10px] cursor-pointer' onClick={enableEditorUserSpecialties}>
                                        Add +
                                    </div>
                                )}
                            </div>
                            <LockContainer onClick={showProductOptionsModal} lockIf={product === SubscriptionTypeEnum.FREE} horizontal description='Upgrade to showcase Specialties'>
                                <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white flex flex-col p-5 items-start justify-start text-lg font-large shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                    <WaitContainer shouldWaitIf={isUpdatingUserSpecialties} waitSizePx={32}>
                                        <div className='self-stretch w-full flex flex-col items-start justify-start gap-[20px]'>
                                            {showEditorUserSpecialties && (
                                                <div className='self-stretch w-full flex flex-row items-center justify-start gap-[10px]'>
                                                    <div className='flex flex-1 self-stretch'>
                                                        <Select isMulti options={filteredSpecialtyList()} theme={globalReactSelectTheme} styles={reactSelectColorStyles} onChange={(selected) => setUserSpecialties(selected)} />
                                                    </div>
                                                    {userSpecialties.length > 0 && (
                                                        <button
                                                            className='cursor-pointer bg-colors-gs-brand-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                            onClick={updateUserSpecialties}
                                                        >
                                                            Save
                                                        </button>
                                                    )}
                                                    <button
                                                        className='cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                        onClick={disableEditorUserSpecialties}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            )}
                                            <div className='self-stretch relative tracking-[0.1px] leading-[24px] flex flex-col gap-[0px]'>
                                                {privateProfileData && privateProfileData.specialties && (
                                                    <div className='flex flex-row flex-wrap items-start justify-start gap-[8px] text-center text-base-6 text-black'>
                                                        {privateProfileData?.specialties.length == 0 ? (
                                                            <i>No Specialties</i>
                                                        ) : (
                                                            privateProfileData?.specialties.map((s) => {
                                                                const sp = specialtiesList?.find((l) => l.id === s.specialtyId);
                                                                return (
                                                                    <div
                                                                        key={s.specialtyId}
                                                                        className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.5px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                                    >
                                                                        <span>{sp?.name}</span>
                                                                        <span
                                                                            className='flex h-full items-center px-[10px] font-bold cursor-pointer text-sm rounded-md hover:bg-red-100 text-red-300'
                                                                            onClick={() => removeUserSpecialty(s)}
                                                                        >
                                                                            X
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </WaitContainer>
                                </div>
                            </LockContainer>
                        </div>
                    </div>

                    {/* Media Gallery Images/Youtube*/}
                    <div className='flex flex-col items-start justify-start gap-[20px]'>
                        <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold gap-[20px]'>
                            Gallery
                            {product !== SubscriptionTypeEnum.FREE
                                && <>
                                    {(!mediaAddOptionsVisible && !showMediaFileUploader && !showMediaYoutubeAdder)
                                        && <div className='text-md justify-start items-center cursor-pointer' onClick={addNewMediaItem}>
                                            Add +
                                        </div>
                                    }
                                    {mediaAddOptionsVisible
                                        && <div className='flex flex-row items-center justify-start text-md gap-[10px]'>
                                            <span>Choose Media Kind?</span>
                                            <div className='cursor-pointer text-link-blue hover:underline' onClick={addMediaImage}>Image</div>
                                            <span>or</span>
                                            <div className='cursor-pointer text-link-blue hover:underline' onClick={addMediaYoutube}>YouTube</div>
                                        </div>
                                    }
                                    {showMediaFileUploader
                                        && <div className='flex flex-row items-center justify-start gap-[10px] text-base'>
                                            <span>Upload An Image (PNG/JPG only &amp; Max Size 10MB)</span>
                                            <input
                                                className='flex w-[400px] stretch cursor-pointer text-black border border-solid border-gray-400 rounded-md px-4 py-2 items-center justify-end gap-[8px]'
                                                ref={mediaUploadFileRef}
                                                name='mediafile'
                                                id='mediafile'
                                                type='file'
                                                accept='image/png,image/jpeg'
                                            />
                                            <WaitContainer shouldWaitIf={isUploadingMediaFile}>
                                                <button
                                                    className='stretch cursor-pointer bg-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                    onClick={uploadMediaFile}
                                                >
                                                    Upload
                                                </button>
                                                <button
                                                    className='stretch cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                    onClick={() => setShowMediaFileUploader(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </WaitContainer>
                                        </div>
                                    }
                                    {showMediaYoutubeAdder
                                        && <div className='flex flex-row items-center justify-start gap-[10px] text-base'>
                                            <span>Enter a YouTube URL:</span>
                                            <input
                                                className='flex w-[400px] stretch cursor-pointer text-black border border-solid border-gray-400 rounded-md px-4 py-2 items-center justify-end gap-[8px]'
                                                name='youtube_url'
                                                type='text'
                                                onKeyUp={(e) => setCurrYoutubeUrl(e.currentTarget.value)}
                                                onBlur={(e) => setCurrYoutubeUrl(e.currentTarget.value)}
                                            />
                                            <WaitContainer shouldWaitIf={isUploadingMediaFile}>
                                                <button
                                                    className='stretch cursor-pointer bg-secondary rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                    onClick={saveYoutubeUrl}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className='stretch cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-end gap-[8px] text-white'
                                                    onClick={() => setShowMediaYoutubeAdder(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </WaitContainer>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        
                        <LockContainer onClick={showProductOptionsModal} lockIf={product === SubscriptionTypeEnum.FREE} horizontal description='Upgrade to showcase a Media Gallery'>
                            <div className='self-stretch w-full h-full rounded-lg bg-colors-gs-white shadow-md flex flex-col box-border items-center justify-start text-md p-[20px]'>
                                {(lgGalleryItems && Array.isArray(lgGalleryItems) && lgGalleryItems.length > 0)
                                    ? <div className='h-[760px] rounded-lg self-stretch w-full flex flex-col items-center justify-center gap-0'>
                                        {/* CURRENT IMAGE */}
                                        <div className="h-[620px] relative top-0 flex grow group rounded-lg self-stretch w-full justify-center items-start gap-[10px]">
                                            {(lgGalleryItems && lgCurrGalleryIndex > -1)
                                                && <>
                                                    {lgGalleryItems[lgCurrGalleryIndex].type === GalleryItemTypeEnum.YOUTUBE
                                                        ? <iframe
                                                            className="cursor-pointer self-stretch w-[90%] h-[620px] flex rounded-lg"
                                                            height="620"
                                                            src={lgGalleryItems[lgCurrGalleryIndex].src}>
                                                        </iframe>
                                                        : <>
                                                            <img
                                                                className={`max-h-[620px] flex rounded-lg self-stretch shadow-md cursor-zoom-in`}
                                                                onClick={() => expandGalleryImage(lgGalleryItems[lgCurrGalleryIndex].src)}
                                                                src={lgGalleryItems[lgCurrGalleryIndex].src}
                                                            />

                                                            {/* Zoomed Image */}
                                                            {isGalleryImageExpanded
                                                                && <>
                                                                    <div
                                                                        className='fixed top-0 left-0 w-screen h-screen bg-black opacity-90 cursor-zoom-out z-30'
                                                                        onClick={hideExpandedGalleryImage}
                                                                    ></div>
                                                                    <div
                                                                        className='fixed top-0 left-0 w-screen h-screen cursor-zoom-out z-40'
                                                                        onClick={hideExpandedGalleryImage}
                                                                    >
                                                                        <img
                                                                            className={`${expandedScreenTailwind} absolute top-0 left-0 right-0 bottom-0 m-auto rounded-md cursor-zoom-out z-50 shadow-md`}
                                                                            onClick={hideExpandedGalleryImage}
                                                                            src={expandedGalleryImageSrc}
                                                                        />
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    <div className="absolute top-0 right-0">
                                                        <WaitContainer shouldWaitIf={isDeletingMediaItem} message='Deleting'>
                                                            <img
                                                                className='w-[50px] cursor-pointer z-50'
                                                                title='Click to delete this media'
                                                                src='/img/mra-profile/icon-trash.svg'
                                                                onClick={() => deleteMediaItem(lgGalleryItems[lgCurrGalleryIndex].id)}
                                                            />
                                                        </WaitContainer>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {/* THUMBNAILS */}
                                        <div className="flex grow rounded-lg self-stretch w-full justify-center items-center gap-[10px]">
                                            {(lgGalleryItems.length > 1)
                                                && <div
                                                        className='cursor-pointer hover:bg-gray-100 hover:rounded-md p-[10px]'
                                                        onClick={setLgMediaPrev}
                                                    >
                                                        ← PREV
                                                    </div>
                                            }
                                            {lgGalleryItems.map((i, index) =>
                                                <div
                                                    key={i.thumb}
                                                    className={`${(index === lgCurrGalleryIndex) ? 'bg-tomato' : 'bg-transparent'} p-[4px] relative w-[120px] h-[100px] flex self-stretch rounded-md`}
                                                    onClick={() => viewLgMediaItem(index)}
                                                >
                                                    
                                                    <img
                                                        className="cursor-pointer w-[120px] h-[100px] flex rounded-md self-stretch"
                                                        src={i.thumb}
                                                    />

                                                    {i.type === "YOUTUBE" &&
                                                        <div className="flex cursor-pointer absolute items-center justify-center w-full h-full ">
                                                            <img
                                                                key={`overlay-${i.thumb}`}
                                                                className="w-[40px]"
                                                                src="/img/mra-profile/youtube-play-btn.svg"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            {(lgGalleryItems.length > 1)
                                                && <div
                                                        className='cursor-pointer hover:bg-gray-100 hover:rounded-md p-[10px]'
                                                        onClick={setLgMediaNext}
                                                    >
                                                        NEXT →
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    : <i className='self-stretch w-full h-[75px] flex text-md items-center justify-start px-4'>No Media Uploaded</i>
                                }
                            </div>
                        </LockContainer>
                    </div>

                    {/* Google Reviews Setup */}
                    <div className='flex flex-col items-start justify-start gap-[20px]'>
                        <div className='flex flex-row gap-[20px] justify-start relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold items-center'>
                            <span>Google Reviews</span>
                            {(product !== SubscriptionTypeEnum.FREE && !isShowingGoogleReviewEditor)
                                && <>
                                {privateProfileData?.googleReviewPlaceId
                                    ? <EditPencilIcon canEdit={canEdit} onClick={() => setIsShowingGoogleReviewEditor(true)} />
                                    : <div className='text-md justify-start items-center cursor-pointer' onClick={() => setIsShowingGoogleReviewEditor(true)}>
                                        Add +
                                    </div>
                                }
                                </>
                            }
                        </div>
                        <LockContainer onClick={showProductOptionsModal} lockIf={product === SubscriptionTypeEnum.FREE} horizontal description='Upgrade to showcase Google Reviews'>
                            <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white flex flex-col p-5 items-start justify-start text-lg font-large shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                <WaitContainer shouldWaitIf={isUpdatingGoogleReviewEditor} waitSizePx={32}>
                                    <div className='self-stretch w-full flex flex-col items-start justify-start gap-[20px]'>
                                        {isShowingGoogleReviewEditor
                                            && <div className='self-stretch w-full flex flex-col items-start justify-start gap-[10px]'>
                                                <div className='flex flex-col w-full self-stretch items-start justify-start gap-[10px]'>
                                                    <div className='flex self-stretch w-full items-start justify-start'>
                                                        <GooglePlaceLookup
                                                            placeholder='To find your review source, type your brokerage name or address'
                                                            onPlaceSelected={(option: any) => option && setNewGoogleReviewPlace(option.place)}
                                                        />
                                                    </div>
                                                    <div className='flex self-stretch w-full flex-row items-start justify-start gap-[10px]'>
                                                        {newGoogleReviewPlace
                                                            && <button
                                                                    className='cursor-pointer bg-link-blue rounded-md px-4 py-2 flex flex-row items-center justify-center gap-[8px] text-white'
                                                                    onClick={updateGoogleReviewPlaceId}
                                                                >
                                                                    Set This Place As My Google Review Source
                                                            </button>
                                                        }
                                                        <button
                                                            className='cursor-pointer bg-brown rounded-md px-4 py-2 flex flex-row items-center justify-center gap-[8px] text-white'
                                                            onClick={() => { setIsShowingGoogleReviewEditor(false); setNewGoogleReviewPlace(null); }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                                {(newGoogleReviewPlace && newGoogleReviewPlace.place_id) &&
                                                    <div className='flex flex-col self-stretch w-full items-start justify-start gap-[16px]'>
                                                        <GooglePlaceReviewCardSet placeId={newGoogleReviewPlace.place_id} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {privateProfileData?.googleReviewPlaceId
                                            ? <>
                                                <div className='text-3xl-1'><b>Current Google Review Source</b></div>
                                                <GooglePlaceReviewCardSet placeId={privateProfileData?.googleReviewPlaceId} />
                                            </>
                                            : <i>No Review Source Added</i>
                                        }
                                    </div>
                                </WaitContainer>
                            </div>
                        </LockContainer>
                    </div>

                    {/* Hidden Future Items */}
                    <>
                        {/* ////  HIDDEN  ///////////////////////////////////////////////////////////// */}

                        {/* Profile Landscape */}
                        <div className='hidden h-[367px]'>
                            <img className='absolute top-[0px] left-[0px] w-[1280px] h-[366.25px] object-cover' alt='' src='/img/mra-profile/video@2x.png' />
                            <div className='absolute top-[298px] left-[0px] w-[1280px] flex flex-row py-0 px-[26px] box-border items-start justify-start gap-[14px]'>
                                <div className='flex-1 flex flex-row items-center justify-start gap-[14px]'>
                                    <img className='relative rounded-[28.18px] w-[42px] h-[42px]' alt='' src='/img/mra-profile/btnprofile.svg' />
                                    <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/btnprofile1.svg' />
                                    <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/btnprofile2.svg' />
                                    <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/btnprofile3.svg' />
                                </div>
                                <div className='w-[42px] h-[42px] flex flex-row items-center justify-end relative gap-[10px]'>
                                    <div className='relative rounded-6xs bg-colors-gray-700 box-border w-11 h-11 opacity-[0.8] z-[0] border-[1px] border-solid border-colors-gs-surface-secondary-dark' />
                                    <img
                                        className='absolute my-0 mx-[!important] top-[calc(50%_-_13.82px)] left-[calc(50%_-_13.18px)] rounded-[14.09px] w-[27px] h-[27px] z-[1]'
                                        alt=''
                                        src='/img/mra-profile/iconexpandwide.svg'
                                    />
                                </div>
                            </div>
                            <div className='absolute top-[26px] right-[26px] flex flex-col items-end justify-start gap-[14px]'>
                                <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/icondownload.svg' />
                                <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/iconlike.svg' />
                                <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/iconshare.svg' />
                            </div>
                        </div>

                        {/* Photo Slideshow */}
                        <div className='hidden self-stretch relative rounded-xl shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-[300px]'>
                            <img className='absolute top-[0px] left-[0px] w-[1280px] h-[300px] object-cover' alt='' src='/img/mra-profile/slideshow@2x.png' />
                            <div className='absolute top-[calc(50%_-_30px)] left-[calc(50%_-_623px)] w-[1246px] flex flex-row items-center justify-center'>
                                <div className='flex-1 flex flex-row items-center justify-start'>
                                    <img className='relative rounded-21xl w-[62px] h-[62px]' alt='' src='/img/mra-profile/btnprofile4.svg' />
                                </div>
                                <div className='self-stretch flex flex-row items-center justify-end'>
                                    <img className='relative rounded-21xl w-[62px] h-[62px]' alt='' src='/img/mra-profile/btnprofile5.svg' />
                                </div>
                            </div>
                            <img className='absolute bottom-[0px] left-[calc(50%_-_41px)] w-[83px] h-[83px]' alt='' src='/img/mra-profile/iconellipses-horiz.svg' />
                        </div>

                        {/* Values, Community Involvement */}
                        <div className='hidden self-stretch flex flex-row items-start justify-start gap-[50px]'>
                            <div className='flex-1 rounded-xl h-[220px] flex flex-col items-start justify-start gap-[20px]'>
                                <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Values</div>
                                <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                    <div className='self-stretch flex-1 flex flex-col items-start justify-start'>
                                        <div className='self-stretch flex-1 relative tracking-[0.1px] leading-[24px]'>
                                            Our Values: Integrity, Meeting people where they are at, Philanthropy, Trust. Our Mission: To provide skilled consultation, negotiation, and representation to
                                            bring the highest possible value for all your residential real estate needs. We value, advocate, and collaborate with our clients. Creating a stress-free
                                            experience.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex-1 rounded-xl h-[220px] flex flex-col items-start justify-start gap-[20px]'>
                                <div className='self-stretch relative tracking-[-0.29px] leading-[40px] font-semibold'>Community Involvement</div>
                                <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                    <div className='self-stretch flex-1 flex flex-col items-start justify-start'>
                                        <div className='self-stretch flex-1 relative tracking-[0.1px] leading-[24px]'>
                                            Austin Pet Rescue, Austin Children’s Shelter Contributor, Red Day Volunteer, President of non-profit Cain Cares
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Industry Involvement, Awards */}
                        <div className='hidden self-stretch flex flex-row items-start justify-start gap-[50px]'>
                            <div className='flex-1 rounded-xl h-[231px] flex flex-col items-start justify-start gap-[20px]'>
                                <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Industry Involvement</div>
                                <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                    <div className='self-stretch flex-1 flex flex-col items-start justify-start'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Austin Board of Realtors Development Committee Member, KW Cares Committee Member, KW JAMS Committee Member, Annual Agent Meet Host
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex-1 rounded-xl h-[231px] flex flex-col items-start justify-start gap-[20px]'>
                                <div className='self-stretch relative tracking-[-0.29px] leading-[40px] font-semibold'>Awards & Recognition</div>
                                <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            At Home With Diversity®, Certified Residential Specialist, Certified Negotiation Expert, Certified Real Estate Brokerage Manager, Austin Business Journal Top
                                            50 Award Winner, Platinum Award Winner 2013, 2014, Triple Platinum Award Winner 2019, 2020, 2021
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Reviews: Google & Yelp */}
                        <div className='hidden self-stretch flex flex-col items-start justify-start gap-[50px]'>
                            
                            {/* Google Reviews */}
                            <div className='self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Google Reviews</div>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[20px] text-sm'>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <img
                                                    className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image@2x.png'
                                                />
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                <div className='relative leading-[21px] font-semibold'>Ashley Vavasour</div>
                                                <div className='relative text-xs leading-[17px]'>23 days ago</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>{`As a cooperating agent on the buying side, my experience with KodiKay Cain and Megan has been easy  `}</div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px] overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <img
                                                    className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image1@2x.png'
                                                />
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                <div className='relative leading-[21px] font-semibold'>Melanie Youngblood</div>
                                                <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>We have worked with CRG three times and each time has been such a breath of fresh air.</div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px] overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <img
                                                    className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image2@2x.png'
                                                />
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                <div className='relative leading-[21px] font-semibold'>Joseph Campbell</div>
                                                <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>
                                                            I’ve worked with many realtors, but the Cain group is second to none. Highly recommended for Austin location.
                                                        </div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px] overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <img
                                                    className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image3@2x.png'
                                                />
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                <div className='relative leading-[21px] font-semibold'>Cody Reid</div>
                                                <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>The Cain team were great to work with starting from early conversations with KodiKay</div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Yelp Reviews */}
                            <div className='self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Yelp Reviews</div>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[20px] text-sm'>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <img
                                                    className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image4@2x.png'
                                                />
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                <div className='relative leading-[21px] font-semibold'>Dianne Moore</div>
                                                <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>I helped many buyers purchase a home that was listed with the KW/Cain Group</div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <img
                                                    className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image5@2x.png'
                                                />
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                <div className='relative leading-[21px] font-semibold'>Joseph A.</div>
                                                <div className='relative text-xs leading-[17px]'>July 23</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>KodiKay, Megan and Connor were fantastic in helping us in the home buying process!</div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <img
                                                    className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image6@2x.png'
                                                />
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                <div className='relative leading-[21px] font-semibold'>Christine Gate</div>
                                                <div className='relative text-xs leading-[17px]'>August 2</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>The Cain Team knows the Austin area. I highly recommend their team.</div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px] text-center text-xl text-colors-gs-white'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                            <div className='relative w-[42px] h-[42px]'>
                                                <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl bg-brown'>
                                                    <div className='absolute h-3/6 w-[87.5%] top-[25%] left-[6.25%] tracking-[-0.24px] leading-[26px] font-semibold flex items-center justify-center'>
                                                        F
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px] text-left text-sm text-colors-gs-brand-primary'>
                                                <div className='relative leading-[21px] font-semibold'>Freddy Barrios</div>
                                                <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                            </div>
                                        </div>
                                        <div className='self-stretch h-[170px] flex flex-col items-start justify-start text-left text-sm text-colors-gs-brand-primary'>
                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                    <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                        <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                    </div>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px]'>
                                                            KodiKay, Rickey, and Connor are exceptional agents in Austin, known for their expertise and connections
                                                        </div>
                                                    </div>
                                                    <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                        <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                        <div className='flex-1 flex flex-col items-start justify-start'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                            <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* MRA Ratings and Reviews */}
                        <div className='hidden self-stretch rounded-xl shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-col items-start justify-start gap-[20px]'>
                            <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>MRA Ratings and Reviews</div>
                            <div className='self-stretch rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col py-5 px-6 items-start justify-start gap-[16px] text-sm text-colors-gs-dark'>
                                <div className='self-stretch rounded-md bg-colors-gs-white flex flex-row items-start justify-start gap-[16px] text-base text-colors-gs-brand-primary'>
                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                        <div className='flex flex-row pt-0 px-0 pb-1 items-center justify-start'>
                                            <div className='relative tracking-[0.2px] leading-[24px] font-medium'>Buyer Score</div>
                                        </div>
                                        <div className='self-stretch flex-1 rounded-md bg-surface-secondary flex flex-row p-2 items-start justify-start gap-[16px] text-xs text-colors-gs-tertiary'>
                                            <div className='self-stretch rounded flex flex-col items-start justify-start gap-[4px]'>
                                                <div className='self-stretch relative tracking-[0.26px] leading-[18px] font-medium'>Score</div>
                                                <b className='self-stretch flex-1 relative text-base tracking-[0.1px] leading-[24px] flex text-colors-gs-brand-primary text-center items-center justify-center'>
                                                    4
                                                </b>
                                            </div>
                                            <div className='rounded overflow-hidden flex flex-col items-start justify-start gap-[4px]'>
                                                <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Rating</div>
                                                <div className='flex flex-row flex-wrap items-center justify-start gap-[2px]'>
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharp.svg' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                        <div className='flex flex-row pt-0 px-0 pb-1 items-center justify-start'>
                                            <div className='relative tracking-[0.2px] leading-[24px] font-medium'>{`Seller Score `}</div>
                                        </div>
                                        <div className='self-stretch flex-1 rounded-md bg-surface-secondary flex flex-row p-2 items-start justify-start gap-[16px] text-xs text-colors-gs-tertiary'>
                                            <div className='self-stretch rounded flex flex-col items-start justify-start gap-[4px]'>
                                                <div className='self-stretch relative tracking-[0.26px] leading-[18px] font-medium'>Score</div>
                                                <b className='self-stretch flex-1 relative text-base tracking-[0.1px] leading-[24px] flex text-colors-gs-brand-primary text-center items-center justify-center'>
                                                    3
                                                </b>
                                            </div>
                                            <div className='rounded overflow-hidden flex flex-col items-start justify-start gap-[4px]'>
                                                <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Rating</div>
                                                <div className='flex flex-row flex-wrap items-center justify-start gap-[2px]'>
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharp.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharp.svg' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                        <div className='flex flex-row pt-0 px-0 pb-1 items-center justify-start'>
                                            <div className='relative tracking-[0.2px] leading-[24px] font-medium'>Agent Score</div>
                                        </div>
                                        <div className='self-stretch flex-1 rounded-md bg-surface-secondary flex flex-row p-2 items-start justify-start gap-[16px] text-xs text-colors-gs-tertiary'>
                                            <div className='self-stretch rounded flex flex-col items-start justify-start gap-[4px]'>
                                                <div className='self-stretch relative tracking-[0.26px] leading-[18px] font-medium'>Score</div>
                                                <b className='self-stretch flex-1 relative text-base tracking-[0.1px] leading-[24px] flex text-colors-gs-brand-primary text-center items-center justify-center'>
                                                    5
                                                </b>
                                            </div>
                                            <div className='rounded overflow-hidden flex flex-col items-start justify-start gap-[4px]'>
                                                <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Rating</div>
                                                <div className='flex flex-row flex-wrap items-center justify-start gap-[2px]'>
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-4 items-start justify-start text-brand-secondary'>
                                    <div className='self-stretch rounded-sm flex flex-col items-center justify-start gap-[4px] text-center text-base text-link-blue'>
                                        <div className='flex flex-row items-center justify-start gap-[8px]'>
                                            <img className='relative w-4 h-4 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconadd36.svg' />
                                            <div className='relative tracking-[0.02em] leading-[24px]'>WRITE A REVIEW</div>
                                        </div>
                                        <div className='relative text-21xl tracking-[-1.57px] leading-[46px] text-colors-gs-dark'>5.0</div>
                                        <div className='flex flex-row items-center justify-start gap-[1px]'>
                                            <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                            <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                            <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                            <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                            <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                        </div>
                                        <div className='relative text-sm leading-[21px] text-colors-gs-tertiary inline-block min-w-[16px]'>(13)</div>
                                    </div>
                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                        <div className='relative leading-[21px]'>5</div>
                                        <div className='flex-1 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border flex flex-col py-0 pr-2 pl-0 items-start justify-center'>
                                            <div className='self-stretch rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-alert' />
                                        </div>
                                    </div>
                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                        <div className='relative leading-[21px]'>4</div>
                                        <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                            <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-2' />
                                        </div>
                                    </div>
                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                        <div className='relative leading-[21px]'>3</div>
                                        <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                            <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-px' />
                                        </div>
                                    </div>
                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                        <div className='relative leading-[21px]'>2</div>
                                        <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                            <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-0.5' />
                                        </div>
                                    </div>
                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                        <div className='relative leading-[21px]'>1</div>
                                        <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                            <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-0.5' />
                                        </div>
                                    </div>
                                </div>
                                <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-5 items-start justify-start gap-[16px]'>
                                    <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[16px] text-colors-gs-brand-primary'>
                                        <div className='rounded-sm hidden flex-col items-start justify-start'>
                                            <div className='relative rounded-21xl w-14 h-14'>
                                                <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white' />
                                                <img
                                                    className='absolute h-[88.89%] w-[88.89%] top-[5.56%] right-[5.56%] bottom-[5.56%] left-[5.56%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image7@2x.png'
                                                />
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-sm flex flex-col items-start justify-start'>
                                            <div className='self-stretch relative leading-[21px] font-medium'>Review from Seller</div>
                                            <div className='relative text-2xs tracking-[0.26px] leading-[18px] font-medium text-colors-gs-tertiary'>August 1, 2023</div>
                                        </div>
                                        <div className='flex flex-row items-center justify-end gap-[16px] text-right'>
                                            <div className='flex flex-row items-center justify-end'>
                                                <div className='relative leading-[21px] font-medium'>Rating: 5.0</div>
                                            </div>
                                            <div className='flex flex-row items-center justify-end gap-[2px]'>
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                            </div>
                                        </div>
                                    </div>
                                    <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator0.svg' />
                                    <div className='self-stretch rounded-sm flex flex-col items-start justify-start text-base'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px] font-semibold'>Quality and timing second to none</div>
                                    </div>
                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                        <div className='self-stretch relative leading-[21px]'>
                                            The Bakas Team, from beginning to end, handled the home sale process with complete support and empathy for my sister who was moving from Chicago to North
                                            Carolina...selling a home loaded with memories after an 18-year stay. Their marketing efforts, including professional photography that showed the home
                                            beautifully, generated many showings. An offer quickly came in and Bob&apos;s fine-tuned negotiation skills enabled the property to sell at top dollar. We
                                            cannot thank the Bakas Team enough for making this home selling experience a very positive one. My sister and I highly recommend The Bakas Team to anyone
                                            thinking about selling or buying real estate. They are professional, knowledgeable, friendly, responsive, results-oriented, and empathetic when the need arises.
                                            Choosing The Bakas Team with Keller William Realty is a decision you will not regret.
                                        </div>
                                    </div>
                                </div>
                                <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-5 items-start justify-start gap-[16px]'>
                                    <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[16px] text-colors-gs-brand-primary'>
                                        <div className='rounded-sm hidden flex-col items-start justify-start'>
                                            <div className='relative rounded-21xl w-14 h-14'>
                                                <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white' />
                                                <img
                                                    className='absolute h-[88.89%] w-[88.89%] top-[5.56%] right-[5.56%] bottom-[5.56%] left-[5.56%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image7@2x.png'
                                                />
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-sm flex flex-col items-start justify-start'>
                                            <div className='self-stretch relative leading-[21px] font-medium'>Review from KodiKay</div>
                                            <div className='relative text-2xs tracking-[0.26px] leading-[18px] font-medium text-colors-gs-tertiary'>July 28, 2023</div>
                                        </div>
                                        <div className='flex flex-row items-center justify-end gap-[16px] text-right'>
                                            <div className='flex flex-row items-center justify-end'>
                                                <div className='relative leading-[21px] font-medium'>Rating: 5.0</div>
                                            </div>
                                            <div className='flex flex-row items-center justify-end gap-[2px]'>
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                            </div>
                                        </div>
                                    </div>
                                    <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator0.svg' />
                                    <div className='self-stretch rounded-sm flex flex-col items-start justify-start text-base'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px] font-semibold'>So happy I went with Teresa!</div>
                                    </div>
                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                        <div className='self-stretch relative leading-[21px]'>
                                            I’m so happy I found Teresa on MRA! She kept me in the loop during the whole process and had my client’s back beginning to end. Thank you Teresa!
                                        </div>
                                    </div>
                                </div>
                                <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-5 items-start justify-start gap-[16px]'>
                                    <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[16px] text-colors-gs-brand-primary'>
                                        <div className='rounded-sm hidden flex-col items-start justify-start'>
                                            <div className='relative rounded-21xl w-14 h-14'>
                                                <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white' />
                                                <img
                                                    className='absolute h-[88.89%] w-[88.89%] top-[5.56%] right-[5.56%] bottom-[5.56%] left-[5.56%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                    alt=''
                                                    src='/img/mra-profile/image7@2x.png'
                                                />
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-sm flex flex-col items-start justify-start'>
                                            <div className='self-stretch relative leading-[21px] font-medium'>Review from Seller</div>
                                            <div className='relative text-2xs tracking-[0.26px] leading-[18px] font-medium text-colors-gs-tertiary'>July 8, 2023</div>
                                        </div>
                                        <div className='flex flex-row items-center justify-end gap-[16px] text-right'>
                                            <div className='flex flex-row items-center justify-end'>
                                                <div className='relative leading-[21px] font-medium'>Rating: 5.0</div>
                                            </div>
                                            <div className='flex flex-row items-center justify-end gap-[2px]'>
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                            </div>
                                        </div>
                                    </div>
                                    <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator0.svg' />
                                    <div className='self-stretch rounded-sm flex flex-col items-start justify-start text-base'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px] font-semibold'>One of the best home buying experience I’ve ever had!</div>
                                    </div>
                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                        <div className='self-stretch relative leading-[21px]'>
                                            Knowledgeable, friendly, experienced. Also, responded quickly to any and all of questions that I may have had. Would highly recommend KodiKay and her team!
                                        </div>
                                    </div>
                                </div>
                                <div className='self-stretch flex flex-row items-center justify-center gap-[8px] text-center text-base text-link-blue'>
                                    <div className='relative tracking-[0.02em] leading-[24px]'>{`View 10 more `}</div>
                                    <img className='relative w-3 h-3 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconchevrondownsolid.svg' />
                                </div>
                            </div>
                        </div>

                    </>
                </form>
                <div className='h-[125px]' />
            </div>
        </div>
    );
};

export { AgentProfile };
