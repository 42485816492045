import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash';
import { getAvatar, getImage, getImageByWidthAspectRatio } from '../../utils/get-avatar';
import {
    getCurrentSubscriptionByEmail,
    getCurrentSubscriptionByStripeId,
    getDesignationsTypes,
    getIsPaidUserId,
    getPublicProfile,
    getSpecialtiesTypes,
    getUsStates,
    postRetrievePartnerstackLink,
} from '../../utils/api';
import { DEFAULT_AVATAR_PATH } from '../AuthenticatedNavBar';
import useZustandAuthStore from '../../_Zustand/auth-store';
import { useDeepCompareEffect } from '../../utils/use-deep-compare-effect';
import { useNavigate, useParams } from 'react-router-dom';
import { HideChildren } from '../../components/HideChildren';
import { DIRECTION_INBOUND_STR, DIRECTION_OUTBOUND_STR, SocialMediaIdEnum, TManageDesignationList, TManageSpecialtyList, TPublicProfileView, TUsStateList, statusesInActive, statusesInClosed, statusesInOpportunity, statusesInUnderContract } from '../../types';
import GooglePlaceReviewCardSet from './GooglePlaceReviewCardSet';
import { FREE_CITIES_LIMIT, FREE_ZIPCODES_LIMIT } from '../constants';
import { ReactSession } from 'react-client-session';
import { WaitSpinner } from '../../components/WaitSpinner';
import { Page404 } from '../../components/Page404/Page404';
import { GalleryItemTypeEnum } from './AgentProfile';
import { toast } from 'react-toastify';
import { isMobileBrowser } from '../../utils/mobile';

export enum SubscriptionTypeEnum {
    FREE = 'FREE',
    PROFESSIONAL = 'PROFESSIONAL',
    TEAMS = 'TEAMS',
}

export enum LicenseVerificationTypeEnum {
    Online = 0,
    UploadedDocument = 1,
    Realify = 2,
}

const AgentProfilePublic: FunctionComponent = () => {
    const { authState, setUsStateList, setDesignationList, setSpecialtiesList, usStates, designationList, specialtiesList } = useZustandAuthStore();
    const [ publicProfileData, setPublicProfileData ] = useState<TPublicProfileView | null>(null);
    const [refCounts, setRefCounts] = useState<any|null>(null);

    const { profileId } = useParams();
    console.log('ProfileId: ', profileId);

    const primaryImage = publicProfileData?.primaryImage;
    const avatarUrl: string = primaryImage ? getAvatar(primaryImage, 200) : DEFAULT_AVATAR_PATH;

    const [product, setProduct] = useState<string>('');
    useDeepCompareEffect(() => {
        console.log('Product', publicProfileData?.product);
        setProduct(publicProfileData?.product as string);

        if (publicProfileData?.product !== SubscriptionTypeEnum.FREE) {
            postRetrievePartnerstackLink(encodeURIComponent(publicProfileData?.email as string))
                .then((result) => {
                    const rdata = JSON.parse(result.rdata);
                    const psLink = rdata.link;
                    setPartnerstackLink(psLink);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [publicProfileData?.product]);

    const [lgCurrGalleryIndex, setLgCurrGalleryIndex] = useState<number>(-1);
    const [lgGalleryItems, setLgGalleryItems] = useState<any>([]);
    const [imageCache, setImageCache] = useState<any[]>([]);
    const preloadImages = (items) => {
        const imCache = items
                            .filter((i) => i.type === GalleryItemTypeEnum.IMAGE)
                            .map((i) => {
                                const img = new Image();
                                img.src = i.src;
                                return img; 
                            });
        setImageCache(imCache);
    };
    const viewLgMediaItem = (index) => {
        setLgCurrGalleryIndex(index);
    };
    const setLgMediaPrev = () => {
        if (!lgGalleryItems || !Array.isArray(lgGalleryItems)) {
            return;
        }

        if (lgGalleryItems.length === 1)
        {
            setLgCurrGalleryIndex(0);
        }

        let newIndex = 0;
        if (lgCurrGalleryIndex === 0) {
            newIndex = lgGalleryItems.length - 1;
        }
        else {
            newIndex = lgCurrGalleryIndex - 1;
        }

        setLgCurrGalleryIndex(newIndex);
    };
    const setLgMediaNext = () => {
        if (!lgGalleryItems || !Array.isArray(lgGalleryItems)) {
            return;
        }

        if (lgGalleryItems.length === 1)
        {
            setLgCurrGalleryIndex(0);
        }

        let newIndex = 0;
        if (lgCurrGalleryIndex === lgGalleryItems.length - 1) {
            newIndex = 0;
        }
        else {
            newIndex = lgCurrGalleryIndex + 1;
        }

        setLgCurrGalleryIndex(newIndex);
    };
    const populateGallery = (privateProfileData): void => {
        const lgGalleryItems = privateProfileData?.userMedias?.map((m) => {
            if (m.type === "YOUTUBE") {
                return {
                    type: m.type,
                    src: `https://www.youtube.com/embed/${m.content}`,
                    thumb: `https://img.youtube.com/vi/${m.content}/mqdefault.jpg`,
                    poster: `https://img.youtube.com/vi/${m.content}/maxresdefault.jpg`,
                    subHtml: '<span></span>',
                    id: m.id,
                };
            }
            else {
                return {
                    type: m.type,
                    src: getImageByWidthAspectRatio(m.content, 1280),
                    thumb: getImage(m.content, 200),
                    subHtml: '<span></span>',
                    id: m.id,
                }
            }
        });
        console.log("lgGalleryItems: ", lgGalleryItems);

        preloadImages(lgGalleryItems);
        setLgGalleryItems(lgGalleryItems);
        if (Array.isArray(lgGalleryItems) && lgGalleryItems.length > 0) {
            setLgCurrGalleryIndex(0);
        }
    };
    const [isGalleryImageExpanded, setIsGalleryImageExpanded] = useState<boolean>(false);
    const [expandedGalleryImageSrc, setExpandedGalleryImageSrc] = useState<string>('');
    const [expandedScreenTailwind, setExpandedScreenTailwind] = useState<string>('');
    const expandGalleryImage = (imgSrc): void => {        
        setExpandedScreenTailwind(' h-screen ');

        setIsGalleryImageExpanded(true);
        setExpandedGalleryImageSrc(imgSrc);
    };
    const hideExpandedGalleryImage = (): void => {
        setIsGalleryImageExpanded(false);
        setExpandedGalleryImageSrc('');
    };


    const navigate = useNavigate();
    
    const sendDirectReferral = (): void => {
        ReactSession.set("newDirectReferralAgent", publicProfileData);
        navigate(`/referral/${publicProfileData?.publicProfile}`);
    }
    const getAllSupportedData = async (): Promise<void> => {
        // Getting this nevertheless: needed for registration form for license verification
        getUsStates()
            .then((usStates: unknown): void => {
                console.log("usStates: ", usStates);
                setUsStateList(usStates as TUsStateList); // for zustand state mgmt
            })
            .catch((error) => console.error(error));

        getDesignationsTypes()
            .then((designations: TManageDesignationList): void => {
                console.log("designations", designations);
                setDesignationList(designations as TManageDesignationList); // for zustand state mgmt
            })
            .catch((error) => console.error(error));

        // get specialties
        getSpecialtiesTypes()
            .then((specialties: TManageSpecialtyList): void => {
                console.log("specialties", specialties);
                setSpecialtiesList(specialties as TManageSpecialtyList); // for zustand state mgmt
            })
            .catch((error) => console.error(error));
    };

    // Fetching Public Profile Data
    const [isFetchingProfile, setIsFetchingProfile] = useState<boolean>(false);
    const [isValidProfile, setIsValidProfile] = useState<boolean>(false);
    const fetchPublicProfile = (callback?): void => {
        setIsFetchingProfile(true);
        getPublicProfile(profileId as string)
            .then((publicProfileData: TPublicProfileView) => {

                setIsValidProfile(true);
                
                const pubProfileDataCopy = { ...publicProfileData };

                getIsPaidUserId(pubProfileDataCopy.id)
                    .then((product) => {
                        if (!product) {
                            setProduct(SubscriptionTypeEnum.FREE);
                        } else if (product !== SubscriptionTypeEnum.FREE) {                            
                            pubProfileDataCopy['product'] = product;
                            setProduct(product);
                            
                            populateGallery(publicProfileData);
                        } else {
                            pubProfileDataCopy['product'] = SubscriptionTypeEnum.FREE;
                            setProduct(SubscriptionTypeEnum.FREE);
                        }

                        setPublicProfileData(pubProfileDataCopy);
                        console.log('Public Profile: ', pubProfileDataCopy);

                        if (pubProfileDataCopy.referrals) {
                            const allRefs = pubProfileDataCopy.referrals;
                            console.log('>>>> allRefs: ', allRefs);

                            const refCnt = {
                                opportunity: {
                                    out: allRefs.filter((r) => [...statusesInOpportunity].includes(r.status) && r.direction === DIRECTION_OUTBOUND_STR).length,
                                    in: allRefs.filter((r) => [...statusesInOpportunity].includes(r.status) && r.direction === DIRECTION_INBOUND_STR).length,
                                },
                
                                active: {
                                    out: allRefs.filter((r) => [...statusesInActive].includes(r.status) && r.direction === DIRECTION_OUTBOUND_STR).length,
                                    in: allRefs.filter((r) => [...statusesInActive].includes(r.status) && r.direction === DIRECTION_INBOUND_STR).length,
                                },
                
                                underContract: {
                                    out: allRefs.filter((r) => [...statusesInUnderContract].includes(r.status) && r.direction === DIRECTION_OUTBOUND_STR).length,
                                    in: allRefs.filter((r) => [...statusesInUnderContract].includes(r.status) && r.direction === DIRECTION_INBOUND_STR).length,
                                },
                
                                closed: {
                                    out: allRefs.filter((r) => [...statusesInClosed].includes(r.status) && r.direction === DIRECTION_OUTBOUND_STR).length,
                                    in: allRefs.filter((r) => [...statusesInClosed].includes(r.status) && r.direction === DIRECTION_INBOUND_STR).length,
                                }
                            };
                            setRefCounts(refCnt);
                        }

                        setIsFetchingProfile(false);

                        if (callback) {
                            callback();
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        
                        pubProfileDataCopy['product'] = 'FREE';
                        setProduct('FREE');

                        setPublicProfileData(pubProfileDataCopy);
                    });
            })
            .catch((error) => {
                console.error(error);

                setIsValidProfile(false);
                setIsFetchingProfile(false);
                
                if (callback) {
                    callback();
                }
            });
    };

    
    // Partnerstack Link
    const [partnerstackLink, setPartnerstackLink] = useState<string>('');
    const followPsLink = () => {
        if(partnerstackLink && partnerstackLink !== '') {
            window.open(partnerstackLink, '_blank');
        }
    };
    const copyPsLinkToClipboard = () => {
        if (partnerstackLink && partnerstackLink !== '') {
            navigator.clipboard.writeText(partnerstackLink);
            toast.info("Copied invite link to the clipboard!")
        }
    };

    const [isMobile, setIsMobile] = useState<boolean>(false);
    useEffect(() => {
        getAllSupportedData();
        fetchPublicProfile();
        setIsMobile(isMobileBrowser());
    }, []);

    return (
        <>
            {isFetchingProfile
                ? <div className='relative flex flex-col w-full h-[500px] items-center justify-center'>
                    <WaitSpinner sizePx={200} />
                </div>
                : <>
                    {(!isValidProfile && !publicProfileData)
                        ? <Page404 />
                        : <div className='relative mt-[25px] w-full h-full flex flex-col xs:items-start lg:items-center'>
                            <div className='relative w-[1240px] h-full p-0 flex flex-col items-center justify-center text-21xl text-primary font-small-semibold gap-[50px]'>
                                
                                {/* Hero Image/ Banner */}
                                <HideChildren hideIf={product === SubscriptionTypeEnum.FREE}>
                                    <div className="flex flex-col w-full self-stretch items-start justify-center">
                                    {publicProfileData?.heroImage
                                        && <div className='self-stretch flex flex-col h-[320px] p-0 grow items-start justify-start text-lg'>
                                            <img
                                                className="h-full w-full object-left-top object-cover rounded-md"
                                                alt=""
                                                src={getImageByWidthAspectRatio(publicProfileData?.heroImage, 1280)}
                                            />                            
                                        </div>
                                    }
                                    </div>
                                </HideChildren>


                                {/* Profile Info */}
                                <div className='self-stretch w-full flex flex-row items-start justify-between gap-[30px]'>
                                    {/* Avatar  */}
                                    <div className='cursor-pointer z-20 w-[186px] bg-transparent relative group rounded-full'>
                                        <img className='cursor-pointer relative w-[186px] h-[186px] rounded-full' alt='Profile Pic' src={avatarUrl} />
                                    </div>

                                    {/* Name and Brokerage */}
                                    <div className='self-stretch flex-1 flex flex-row items-start justify-start pt-[20px]'>
                                        <div className='flex-1 flex flex-col items-start justify-end gap-[8px]'>
                                            
                                            {/* Name  */}
                                            <div className='flex flex-row items-center justify-start gap-[10px]'>
                                                <b className='relative tracking-[-0.29px] leading-[40px] text-5xl'>
                                                    {publicProfileData?.firstName} {publicProfileData?.lastName}
                                                </b>
                                                {authState.profileUserData?.publicProfile === profileId && <a className='text-lg text-link-blue no-underline hover:underline' href={`/profile`}>Edit Profile</a>}
                                                <img className='hidden relative w-[26px] h-[26px]' alt='' src='/img/mra-profile/iconsolidheart.svg' />
                                            </div>

                                            {/* Brokerage */}
                                            <div className='flex flex-col items-start justify-start gap-[14px] text-lg'>
                                                
                                                {/* Brokerage Name & Address */}
                                                <div className='flex flex-col items-start justify-end'>
                                                    <div className='relative tracking-[-0.24px] leading-[26px] text-3xl-1'>
                                                        {publicProfileData?.brokerageName}
                                                    </div>

                                                    {/* Office City and State */}
                                                    {(publicProfileData?.officeCity || publicProfileData?.officeStateId) && (
                                                        <div className='flex flex-row items-center justify-start gap-[4px]'>
                                                            <div className='flex items-center relative leading-[140%] font-medium text-xl mt-[6px]'>
                                                                {publicProfileData?.officeAddress1 ? `${publicProfileData?.officeAddress1}, ` : ''}
                                                                <br />
                                                                {publicProfileData?.officeAddress2 ? `${publicProfileData?.officeAddress2}, ` : ''}
                                                                <br />
                                                                {publicProfileData?.officeCity ? `${publicProfileData?.officeCity}, ` : ''}
                                                                {publicProfileData?.officeStateId ? `${usStates?.find((s) => s.id === publicProfileData?.officeStateId)?.abbreviation}, ` : ''}
                                                                {publicProfileData?.officeZip ? `${publicProfileData?.officeZip}` : ''}
                                                            </div>
                                                        </div> 
                                                    )}
                                                </div>

                                                {/* First Year or Service */}
                                                <div className='flex flex-col items-start justify-end'>
                                                    
                                                    {publicProfileData?.firstYearOfService && (
                                                        <div className='relative tracking-[0.1px] leading-[24px] flex justify-start items-center gap-[5px]'>
                                                            <span>Realtor since {publicProfileData?.firstYearOfService}</span>
                                                        </div>
                                                    )}

                                                    {/* {publicProfileData?.dateJoined && (
                                                        <div className='relative tracking-[0.1px] leading-[24px]'>MRA Member since {new Date(publicProfileData?.dateJoined).getFullYear()}</div>
                                                    )} */}
                                                </div>
                                               

                                                <div className='flex flex-row items-center gap-[16px]'>
                                                    {/* <span className='font-medium text-lg'>Social Media</span> */}
                                                    {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url
                                                        && <a
                                                                target="_blank"
                                                                className='no-underline cursor-pointer'
                                                                title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                                                href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                                            >
                                                                <img src='/img/mra-social-media/facebook.svg' className='w-[30px]' />
                                                            </a>
                                                    }
                                                    {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url
                                                        && <a
                                                                target="_blank"
                                                                className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                                                href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                                            >
                                                                <img src='/img/mra-social-media/instagram.svg' className='w-[30px]' />
                                                            </a>
                                                    }
                                                    {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url
                                                        && <a
                                                                target="_blank"
                                                                className='no-underline'
                                                                title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                                                href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                                            >
                                                                <img src='/img/mra-social-media/twitter.svg' className='w-[30px]' />
                                                            </a>
                                                    }
                                                    {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url
                                                        && <a
                                                                target="_blank"
                                                                className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                                                href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                                            >
                                                                <img src='/img/mra-social-media/youtube.svg' className='w-[30px]' />
                                                            </a>
                                                    }
                                                    {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url
                                                        && <a
                                                                target="_blank"
                                                                className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                                                href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                                            >
                                                                <img src='/img/mra-social-media/tiktok.svg' className='w-[30px]' />
                                                            </a>
                                                    }
                                                    {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url
                                                        && <a
                                                                target="_blank"
                                                                className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                                                href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                                            >
                                                                <img src='/img/mra-social-media/linkedin.svg' className='w-[30px]' />
                                                            </a>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    {/* Send Referral */}
                                    <div className='min-w-[380px] flex-1 flex flex-col items-center justify-center gap-[10px]'>                                        
                                        
                                        {/* Send Referral Button */}
                                        <div className='flex flex-col self-stretch items-end justify-end mt-[10px]'>
                                            <div className='flex flex-row items-end justify-end gap-[12px]'>
                                                {(authState.id !== publicProfileData?.id) &&
                                                    <button
                                                        className='cursor-pointer py-8 px-8 rounded-full bg-tomato box-border h-10 flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gs-border'
                                                        autoFocus={false}
                                                        onClick={sendDirectReferral}
                                                    >
                                                        <img className='relative w-[18px] h-[18px] overflow-hidden shrink-0 hidden' alt='' src='/img/mra-profile/icongiftsolid.svg' />
                                                        <div className='relative font-normal text-colors-gs-white text-center text-5xl'>
                                                            Send A Referral To {publicProfileData?.firstName} 
                                                        </div>
                                                        <img className='relative w-[18px] h-[18px]' alt='' src='/img/mra-profile/iconsharesolid.svg' />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Contact Info and Additional Websites */}
                                <div className='self-stretch flex flex-row items-start justify-center gap-[30px]'>
                                    {/* Contact Info */}
                                    <div className='w-3/5 rounded-xl self-stretch flex flex-col items-start justify-start gap-[20px] text-lg'>
                                        {/* Contact Info Header */}
                                        <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                            <span>Contact Info</span>
                                        </div>
                                        
                                        {/* Contact Info Content */}
                                        <div className='rounded-lg flex self-stretch grow flex-col items-start justify-start gap-[20px] p-5 bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>

                                            {/* Phone Number(s) */}
                                            {/* <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[16px]'> */}
                                                
                                                {/* Cell Phone Number */}
                                                {publicProfileData?.cellPhoneNumber
                                                    && <div className='flex self-stretch rounded-sm flex-row items-center justify-start gap-[8px] text-lg'>
                                                        <div className='font-semibold'>Cell</div>
                                                        <div className='flex-1'>
                                                            {publicProfileData?.cellPhoneNumber
                                                                && <a className='text-link-blue no-underline hover:underline' href={`tel:+1 ${publicProfileData?.cellPhoneNumber}`}>{publicProfileData?.cellPhoneNumber}</a>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                {/* Office Phone Number */}
                                                {publicProfileData?.officePhoneNumber
                                                    && <div className='flex self-stretch rounded-sm flex-row items-center justify-start gap-[8px] text-lg'>
                                                        <div className='font-semibold'>Office</div>
                                                        <div className='flex-1'>
                                                            {publicProfileData?.officePhoneNumber
                                                                && <>
                                                                    <a className='text-link-blue no-underline hover:underline' href={`tel:+1 ${publicProfileData?.officePhoneNumber}`}>{publicProfileData?.officePhoneNumber}</a>
                                                                    &nbsp;
                                                                    {publicProfileData?.officePhoneExtension
                                                                        && <>
                                                                            (Ext: {publicProfileData?.officePhoneExtension})
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            {/* </div> */}

                                            {/* Email: I think this should not go in the public */}
                                            <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[8px] text-lg'>
                                                <img className='relative w-6 h-6 overflow-hidden shrink-0 hidden' alt='' src='/img/mra-profile/iconmobile.svg' />
                                                <div className='relative font-semibold '>Email</div>
                                                <div className='flex flex-row px-2'>
                                                    <a className='text-link-blue hover:underline cursor-pointer no-underline' target='_blank' href={`mailto: ${publicProfileData?.email as string}`}>
                                                        {publicProfileData?.email}
                                                    </a>
                                                </div>
                                            </div>

                                            {/* Primary Website */}
                                            {publicProfileData?.primaryWebsite
                                                && <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[8px] text-lg'>
                                                    <div className='self-stretch font-semibold'>Website</div>
                                                    <div className='flex flex-row px-2'>
                                                        <a className='text-link-blue hover:underline cursor-pointer no-underline max-w-[650px]' target='_blank' href={publicProfileData?.primaryWebsite as string}>
                                                            {publicProfileData?.primaryWebsite}
                                                        </a>
                                                    </div>
                                                </div>
                                            }

                                            {/* Partnerstack Link */}
                                            {(partnerstackLink && partnerstackLink !== '' && authState.id !== 26) // 26 is Josh Prod Test User ID
                                                && <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[12px] text-lg'>
                                                    <div className='relative font-semibold '>Invite Link</div>
                                                    <div className='relative text-link-blue cursor-pointer no-underline hover:underline' onClick={followPsLink}>
                                                        {partnerstackLink}
                                                    </div>
                                                    <img src='/img/mra-profile/copy-icon.svg' className="w-[20px] h-[20px] cursor-pointer" onClick={copyPsLinkToClipboard} />
                                                </div>
                                            }

                                            {/* Social Media - Moved to Main Profile */}
                                            {/* {(publicProfileData?.socialMedia && Array.isArray(publicProfileData?.socialMedia) && publicProfileData?.socialMedia.length > 0)
                                                && <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[8px]'>
                                                        <div className='relative leading-[21px] font-semibold inline-block w-[90px] shrink-0'>Social Media</div>
                                                        <div className='flex-1 relative leading-[21px]'>
                                                            <div className='flex flex-row gap-[10px]'>
                                                                {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url
                                                                    && <a
                                                                            target="_blank"
                                                                            className='no-underline cursor-pointer'
                                                                            title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                                                            href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.facebook_business)?.url}
                                                                        >
                                                                            <img src='/img/mra-social-media/facebook.svg' className='w-[30px]' />
                                                                        </a>
                                                                }
                                                                {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url
                                                                    && <a
                                                                            target="_blank"
                                                                            className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                            title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                                                            href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.instagram)?.url}
                                                                        >
                                                                            <img src='/img/mra-social-media/instagram.svg' className='w-[30px]' />
                                                                        </a>
                                                                }
                                                                {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url
                                                                    && <a
                                                                            target="_blank"
                                                                            className='no-underline'
                                                                            title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                                                            href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.twitter)?.url}
                                                                        >
                                                                            <img src='/img/mra-social-media/twitter.svg' className='w-[30px]' />
                                                                        </a>
                                                                }
                                                                {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url
                                                                    && <a
                                                                            target="_blank"
                                                                            className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                            title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                                                            href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.youTube)?.url}
                                                                        >
                                                                            <img src='/img/mra-social-media/youtube.svg' className='w-[30px]' />
                                                                        </a>
                                                                }
                                                                {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url
                                                                    && <a
                                                                            target="_blank"
                                                                            className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                            title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                                                            href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.tiktok)?.url}
                                                                        >
                                                                            <img src='/img/mra-social-media/tiktok.svg' className='w-[30px]' />
                                                                        </a>
                                                                }
                                                                {publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url
                                                                    && <a
                                                                            target="_blank"
                                                                            className='hover:underline text-link-blue no-underline cursor-pointer'
                                                                            title={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                                                            href={publicProfileData?.socialMedia.find((s) => s.type === SocialMediaIdEnum.linkedIn)?.url}
                                                                        >
                                                                            <img src='/img/mra-social-media/linkedin.svg' className='w-[30px]' />
                                                                        </a>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                            } */}
                                        </div>
                                    </div>

                                    {/* Additional Websites */}
                                    <div className='w-2/5 rounded-xl self-stretch flex flex-col items-start justify-start gap-[20px] text-lg'>
                                        
                                        {(publicProfileData?.userWebsites && publicProfileData.userWebsites.length > 0)
                                            && <HideChildren hideIf={product === SubscriptionTypeEnum.FREE}>
                                                <div className='rounded-xl flex self-stretch grow flex-col items-start justify-start gap-[20px]'>
                                                    
                                                    {/* Additional Websites Header */}
                                                    <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                                        <span>Additional Websites</span>
                                                    </div>

                                                    {/* Additional Websites list */}
                                                    <div className='self-stretch flex flex-col rounded-lg bg-colors-gs-white p-5 items-start justify-start text-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>
                                                        <div className='self-stretch flex flex-col items-start justify-start gap-[11px]'>
                                                            <div className='self-stretch flex flex-col items-start justify-start gap-[12px] text-xl'>                                            
                                                                {publicProfileData?.userWebsites.map((s, index) => (
                                                                    <div
                                                                        key={'view-' + s.id + '' + s.userId + '' + index}
                                                                        className='flex flex-row gap-[5px] items-center'
                                                                    >
                                                                        <a
                                                                            href={atob(decodeURI(s.url))}
                                                                            target='_blank'
                                                                            className='text-link-blue cursor-pointer no-underline hover:underline'
                                                                        >
                                                                            {atob(decodeURI(s.url))}
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                                {publicProfileData?.userWebsites && publicProfileData?.userWebsites.length === 0 && <i>No Websites Added</i>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </HideChildren>
                                        }
                                    </div>
                                </div>

                                {/* Bio */}
                                {publicProfileData?.general
                                    && <div className='self-stretch flex flex-col items-start justify-start gap-[20px] text-lg'>
                                        {/* Bio Header */}
                                        <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                            <span>About Me</span>
                                        </div>

                                        {/* Bio */}
                                        <div className='self-stretch flex flex-row grow rounded-lg pt-5 px-5 pb-[25px] box-border items-start justify-start gap-[16px] bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                            {/* <div className='self-stretch flex flex-row items-start justify-start text-[20px] leading-[30px]  whitespace-pre-line'>
                                                {publicProfileData?.general}
                                            </div> */}
                                            <div
                                                className='w-fit text-[20px] leading-[30px]'
                                                dangerouslySetInnerHTML={{ __html: publicProfileData?.general }}
                                            />
                                        </div>
                                    </div>
                                }

                                {/* Referrals Statistics */}
                                <HideChildren hideIf={product === SubscriptionTypeEnum.FREE}>
                                    <div className='self-stretch flex flex-col items-start justify-start gap-[20px] text-11xl text-dark font-h3'>
                                        <div className='relative tracking-[-0.8px] leading-[137%] font-semibold'>Referral Statistics</div>
                                        <div className='flex flex-row items-center justify-start gap-[16px] text-37xl text-colors-gs-brand-primary font-large'>
                                            
                                            {/* Opportunities */}
                                            {/* <div className='rounded-md bg-gray1 shadow-[0px_12px_16px_-4px_rgba(12,_26,_36,_0.04),_0px_12px_16px_-4px_rgba(12,_26,_36,_0.04)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'> */}
                                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                                        {(refCounts && refCounts.opportunity)
                                                            ? `${refCounts.opportunity.out + refCounts.opportunity.in}`
                                                            : `-`}
                                                        </div>
                                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                                            Opportunities
                                                        </div>
                                                    </div>
                                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-money.svg' />
                                                </div>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[13px] text-center text-base text-colors-gs-tertiary'>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Inbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.opportunity)
                                                                ? `${refCounts.opportunity.in}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Outbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.opportunity)
                                                                ? `${refCounts.opportunity.out}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Active */}
                                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                                            {(refCounts && refCounts.active)
                                                                ? `${refCounts.active.out + refCounts.active.in}`
                                                                : `-`}
                                                        </div>
                                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                                            Active
                                                        </div>
                                                    </div>
                                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-pen-nib-solid.svg' />
                                                </div>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px] text-center text-base text-colors-gs-tertiary'>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Inbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.active)
                                                                ? `${refCounts.active.in}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Outbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.active)
                                                                ? `${refCounts.active.out}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Under Contract */}
                                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                                            {(refCounts && refCounts.underContract)
                                                                ? `${refCounts.underContract.out + refCounts.underContract.in}`
                                                                : `-`}
                                                        </div>
                                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                                            Contract
                                                        </div>
                                                    </div>
                                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-file-pen-solid.svg' />
                                                </div>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px] text-center text-base text-colors-gs-tertiary'>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Inbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.underContract)
                                                                ? `${refCounts.underContract.in}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Outbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.underContract)
                                                                ? `${refCounts.underContract.out}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Closed */}
                                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                                            {(refCounts && refCounts.closed)
                                                                ? `${refCounts.closed.out + refCounts.closed.in}`
                                                                : `-`}
                                                        </div>
                                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                                            Closed
                                                        </div>
                                                    </div>
                                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-award.svg' />
                                                </div>
                                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px] text-center text-base text-colors-gs-tertiary'>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Inbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.closed)
                                                                ? `${refCounts.closed.in}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                            Outbound
                                                        </div>
                                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                                            {(refCounts && refCounts.closed)
                                                                ? `${refCounts.closed.out}`
                                                                : `-`}
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </HideChildren>

                                {/* Locations & Licenses*/}
                                <div className='self-stretch flex flex-row items-start justify-start gap-[20px]'>
                                    {/* Location Served */}
                                    <div className='w-full self-stretch grow flex flex-col items-start justify-start rounded-xl gap-[20px]'>
                                        
                                        <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                            <span>Locations Served</span>
                                        </div>

                                        <div className='self-stretch rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start gap-[16px] text-base'>
                                            <div className='w-full flex flex-row items-start justify-start gap-[16px]'>                                
                                                
                                                {/* City Preferences */}
                                                <div className='w-1/2 flex self-stretch flex-col gap-[16px]'>
                                                    <div className='w-full flex flex-col gap-[16px] items-start justify-start'>
                                                        <div className='w-full self-stretch flex flex-row justify-start items-center gap-[10px] tracking-[0.02em] leading-[24px]'>
                                                            <div>Cities</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch rounded-sm flex flex-row flex-wrap items-start justify-start gap-[8px] text-center text-base-6 text-colors-gs-tertiary'>
                                                        {publicProfileData?.servedCities &&
                                                            !!publicProfileData?.servedCities.length && <>
                                                                {
                                                                    product === SubscriptionTypeEnum.FREE
                                                                    ? publicProfileData?.servedCities.slice(0, FREE_CITIES_LIMIT).map((c) => (
                                                                        <div
                                                                            key={c.locationId}
                                                                            className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.40000057220459px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                                        >
                                                                            <div className='relative font-semibold min-w-[20px] flex flex-row justify-start items-center gap-[10px]'>
                                                                                <span>
                                                                                    {c.city}, {usStates?.find((s) => s.id === c.stateId)?.abbreviation}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        ))
                                                                    : publicProfileData?.servedCities.map((c) => (
                                                                        <div
                                                                            key={c.locationId}
                                                                            className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.40000057220459px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                                        >
                                                                            <div className='relative font-semibold min-w-[20px] flex flex-row justify-start items-center gap-[10px]'>
                                                                                <span>
                                                                                    {c.city}, {usStates?.find((s) => s.id === c.stateId)?.abbreviation}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </>
                                                        }
                                                            
                                                        {(!publicProfileData?.servedCities || publicProfileData?.servedCities.length === 0) && <i>No Cities Provided</i>}
                                                    </div>
                                                </div>

                                                {/* Zipcode Preferences */}
                                                <div className='w-1/2 flex self-stretch flex-col gap-[16px]'>
                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='relative tracking-[0.02em] leading-[24px]'>Zip Codes</div>
                                                    </div>
                                                    <div className='self-stretch rounded-sm flex flex-row flex-wrap items-start justify-start gap-[8px] text-center text-base-6 text-colors-gs-tertiary'>
                                                        {publicProfileData?.servedZipCodes
                                                        && !!publicProfileData?.servedZipCodes.length
                                                        && <>
                                                            {
                                                                product === SubscriptionTypeEnum.FREE
                                                                ? publicProfileData?.servedZipCodes.slice(0, FREE_ZIPCODES_LIMIT).map((c) => (
                                                                        <div
                                                                            key={c.locationId}
                                                                            className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.40000057220459px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                                        >
                                                                            <div className='relative font-semibold min-w-[20px] flex flex-row justify-start items-center gap-[10px]'>
                                                                                <span>{c.zip}</span>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                : publicProfileData?.servedZipCodes.map((c) => (
                                                                    <div
                                                                        key={c.locationId}
                                                                        className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.40000057220459px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                                    >
                                                                        <div className='relative font-semibold min-w-[20px] flex flex-row justify-start items-center gap-[10px]'>
                                                                            <span>{c.zip}</span>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            </>
                                                        }
                                                        {!publicProfileData?.servedZipCodes || (publicProfileData?.servedZipCodes.length === 0 && <i>No Zip Codes Provided</i>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Licenses */}
                                    <div className='w-1/2 self-stretch grow flex flex-col items-start justify-start rounded-xl gap-[20px]'>
                                        <div className='flex flex-row gap-[20px] justify-start items-center relative tracking-[-0.29px] leading-[137%] text-11xl text-dark font-h3 font-semibold'>
                                            <span>Licenses/Registrations</span>
                                        </div>
                                        <div className='self-stretch flex-1 flex flex-col rounded-lg bg-colors-gs-white p-5 items-start justify-start text-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] '>
                                            <div className='self-stretch flex flex-col items-start justify-start gap-[11px]'>
                                                <div className='self-stretch flex flex-col items-start justify-start gap-[12px] text-xl'>
                                                    {publicProfileData?.stateLicenses && publicProfileData.stateLicenses.length > 0 && (
                                                        <ul>
                                                            {publicProfileData?.stateLicenses.map((s, index) => (
                                                                <li key={s.stateId + '' + s.yearLicensed + '' + index}>
                                                                    {usStates?.find((us) => us.id === s.stateId)?.name} since {s.yearLicensed}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    {publicProfileData?.stateLicenses && publicProfileData.stateLicenses.length === 0 && <i>No Licenses Added</i>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <>
                                        {/* Websites */}
                                        {/* <div className="self-stretch rounded-xl w-[351px] flex flex-col items-start justify-start gap-[20px]">
                                            <div className="w-full flex flex-row items-center gap-[16px]">
                                                <div className="flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold">
                                                    Websites
                                                </div>
                                                <EditPencilIcon canEdit={canEdit} onClick={() => { }} />
                                            </div>
                                            <div className="self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-lg">
                                                <div className="self-stretch flex flex-col items-start justify-start gap-[11px]">
                                                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-base text-link-blue">
                                                        <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                                            www.palmrealtygroup.com
                                                        </div>
                                                        <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                                            www.cainrealty.com
                                                        </div>
                                                        <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                                            www.luxhomes.com
                                                        </div>
                                                        <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                                            www.palmluxhomes.com
                                                        </div>
                                                        <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                                            www.milliondollarguild.com
                                                        </div>
                                                        <div className="self-stretch relative tracking-[0.1px] leading-[24px]">
                                                            www.uscommerce.com
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        */}
                                    </>
                                </div>

                                {/* Designations & Specialties */}
                                <HideChildren hideIf={product === SubscriptionTypeEnum.FREE}>
                                    <div className='self-stretch flex flex-row items-start justify-center gap-[60px]'>
                                        {/* Designations */}
                                        <div className='flex-1 rounded-xl self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='w-full flex flex-row items-center gap-[16px]'>
                                                <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold'>Designations</div>
                                            </div>
                                            <div className='self-stretch w-full rounded-lg bg-colors-gs-white flex flex-col p-5 items-start justify-start text-base font-large shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                                <div className='self-stretch w-full flex flex-col items-start justify-start gap-[20px]'>
                                                    <div className='self-stretch relative tracking-[0.1px] leading-[24px] flex flex-col gap-[0px]'>
                                                        {publicProfileData && publicProfileData.designations && (
                                                            <div className='flex flex-col gap-[3px]'>
                                                                {publicProfileData?.designations?.length === 0 ? (
                                                                    <i> No Designations</i>
                                                                ) : (
                                                                    <ol>
                                                                        {(publicProfileData?.designations as Array<any>)
                                                                            .map((d) => {
                                                                                const { abbreviation, description } = designationList?.find((dl) => dl.abbreviation === d) as any;
                                                                                return {
                                                                                    ...d,
                                                                                    abbreviation,
                                                                                    description,
                                                                                };
                                                                            })
                                                                            .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation))
                                                                            .map((s, idx) => {
                                                                                return (
                                                                                    <li key={idx}>
                                                                                        <div className='my-[5px] rounded-8xs-8 w-fit bg-colors-gs-surface-primary box-border overflow-hidden flex flex-row p-[5px] px-[10px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'>
                                                                                            <div>
                                                                                                {s?.abbreviation}
                                                                                                {s?.description ? ` - ${s?.description}` : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                    </ol>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Specialties */}
                                        <div className='flex-1 rounded-xl self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='w-full flex flex-row items-center gap-[16px]'>
                                                <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold'>Specialties</div>
                                            </div>
                                            <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white flex flex-col p-5 items-start justify-start text-lg font-large shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                                <div className='self-stretch w-full flex flex-col items-start justify-start gap-[20px]'>
                                                    <div className='self-stretch relative tracking-[0.1px] leading-[24px] flex flex-col gap-[0px]'>
                                                        {publicProfileData && publicProfileData.specialties && (
                                                            <div className='flex flex-row flex-wrap items-start justify-start gap-[8px] text-center text-base-6 text-black'>
                                                                {publicProfileData?.specialties.length == 0 ? (
                                                                    <i>No Specialties</i>
                                                                ) : (
                                                                    publicProfileData?.specialties.map((s) => {
                                                                        const sp = specialtiesList?.find((l) => l.id === s.specialtyId);
                                                                        return (
                                                                            <div
                                                                                key={s.specialtyId}
                                                                                className='rounded-8xs-8 bg-colors-gs-surface-primary box-border h-[38.4px] overflow-hidden flex flex-row py-0 px-[14.5px] items-center justify-center gap-[9.6px] border-[1.2px] border-solid border-colors-gs-border'
                                                                            >
                                                                                <span>{sp?.name}</span>
                                                                            </div>
                                                                        );
                                                                    })
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </HideChildren>

                                {/* Media Gallery */}
                                <HideChildren hideIf={product === SubscriptionTypeEnum.FREE}>
                                    {/* Media */}
                                    <div className='self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                        
                                        <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold gap-[20px]'>
                                            Gallery
                                        </div>

                                        <div className='self-stretch w-full rounded-lg bg-colors-gs-white shadow-md flex flex-col box-border items-center justify-start text-md p-10'>
                                            {(lgGalleryItems && Array.isArray(lgGalleryItems) && lgGalleryItems.length > 0)
                                                ? <div className='h-[720px] rounded-lg self-stretch w-full flex flex-col items-center justify-center gap-0'>
                                                    
                                                    {/* CURRENT IMAGE */}
                                                    <div className="h-[620px] flex rounded-lg self-stretch w-full justify-center items-start gap-[10px]">
                                                        {(lgGalleryItems && lgCurrGalleryIndex > -1)
                                                            && <>
                                                                {lgGalleryItems[lgCurrGalleryIndex].type === "YOUTUBE"
                                                                    ? <iframe
                                                                        className="cursor-pointer self-stretch w-full h-[620px] flex rounded-lg"
                                                                        height="620"
                                                                        src={lgGalleryItems[lgCurrGalleryIndex].src}>
                                                                    </iframe>
                                                                    : <>
                                                                        <img
                                                                            className="h-[620px] flex rounded-lg self-stretch cursor-zoom-in"
                                                                            onClick={() => expandGalleryImage(lgGalleryItems[lgCurrGalleryIndex].src)}
                                                                            src={lgGalleryItems[lgCurrGalleryIndex].src}
                                                                        />
                                                                        
                                                                        {/* Zoomed Image */}
                                                                        {(isGalleryImageExpanded && !isMobile)
                                                                            && <>
                                                                                <div
                                                                                    className='fixed top-0 left-0 w-screen h-screen bg-black opacity-90 cursor-zoom-out z-30'
                                                                                    onClick={hideExpandedGalleryImage}
                                                                                ></div>
                                                                                <div
                                                                                    className='fixed top-0 left-0 w-screen h-screen cursor-zoom-out z-40 table-cell'
                                                                                    onClick={hideExpandedGalleryImage}
                                                                                >
                                                                                    <img
                                                                                        // className={`${expandedScreenTailwind} absolute top-0 left-0 right-0 bottom-0 m-auto rounded-md cursor-zoom-out z-50 shadow-md`}
                                                                                        className={`h-auto max-w-screen max-h-screen absolute top-0 left-0 right-0 bottom-0 m-auto rounded-md cursor-zoom-out z-50 shadow-md`}
                                                                                        onClick={hideExpandedGalleryImage}
                                                                                        src={expandedGalleryImageSrc}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {/* <div className="absolute top-0 right-0">
                                                                            <img
                                                                                className='w-[50px] cursor-pointer z-50'
                                                                                title='Click to expand this image'
                                                                                src='/img/mra-profile/icon-trash.svg'
                                                                                onClick={() => expandGalleryImage(lgGalleryItems[lgCurrGalleryIndex].src)}
                                                                            />
                                                                        </div> */}
                                                                    </>
                                                                }
                                                                
                                                            </>
                                                        }
                                                    </div>

                                                    {/* THUMBNAILS */}
                                                    <div className="p-[5px] flex flex-1 rounded-lg self-stretch w-full justify-center items-center gap-[10px]">
                                                        {(lgGalleryItems.length > 1)
                                                            && <div
                                                                    className='cursor-pointer hover:bg-gray-100 hover:rounded-md p-[10px]'
                                                                    onClick={setLgMediaPrev}
                                                                >
                                                                    ← PREV
                                                                </div>
                                                        }
                                                        {lgGalleryItems.map((i, index) =>
                                                            <div
                                                                key={i.thumb}
                                                                className={`${(index === lgCurrGalleryIndex) ? 'bg-tomato' : 'bg-transparent'} p-[4px] relative w-[120px] h-[100px] flex self-stretch rounded-lg`}
                                                                onClick={() => viewLgMediaItem(index)}
                                                            >
                                                                <img
                                                                    className="cursor-pointer w-[120px] h-[100px] flex rounded-lg self-stretch"
                                                                    src={i.thumb}
                                                                />
                                                                {i.type === "YOUTUBE" &&
                                                                    <div className="flex cursor-pointer absolute items-center justify-center w-full h-full ">
                                                                        <img
                                                                            key={`overlay-${i.thumb}`}
                                                                            className="w-[40px]"
                                                                            src="/img/mra-profile/youtube-play-btn.svg"
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        )}
                                                        {(lgGalleryItems.length > 1)
                                                            && <div
                                                                    className='cursor-pointer hover:bg-gray-100 hover:rounded-md p-[10px]'
                                                                    onClick={setLgMediaNext}
                                                                >
                                                                    NEXT →
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                : <i className='h-[75px] text-md self-stretch w-full flex items-center justify-start px-4'>No Media Uploaded</i>
                                            }
                                        </div>
                                        
                                    </div>
                                </HideChildren>

                                {/* Google Reviews Setup */}
                                <HideChildren hideIf={product === SubscriptionTypeEnum.FREE}>
                                    <div className='self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                        <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold items-center'>
                                            Google Reviews
                                        </div>
                                        <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white flex flex-col p-5 items-start justify-start text-lg font-large shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                            {publicProfileData?.googleReviewPlaceId
                                                ? <div className='flex flex-col gap-[12px]'>
                                                    {/* <div className='text-5xl font-semibold'>Current Review Source</div> */}
                                                    <GooglePlaceReviewCardSet placeId={publicProfileData?.googleReviewPlaceId} />
                                                </div>
                                                : <i>No Google Reviews</i>
                                            }
                                        </div>
                                    </div>
                                </HideChildren>

                                {/* MRA Reviews */}
                                <div className='self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                    <div className='flex justify-start relative tracking-[-0.29px]  leading-[137%] text-11xl text-dark font-h3 font-semibold gap-[20px]'>
                                        MRA Member Reviews
                                    </div>
                                    {(publicProfileData?.reviews && publicProfileData?.reviews.filter((r) => r.status === 1).length > 0)
                                        ? <div className='self-stretch grow flex-row flex-wrap rounded-lg flex items-start justify-start gap-[20px] text-base'>
                                            {publicProfileData?.reviews.filter((r) => r.status === 1).map((r, index) =>
                                                <div key={`${index}-${r.author_url}`} className='border border-solid hover:border-gray-400 border-transparent w-[335px] flex flex-col p-6 items-start justify-start gap-[12px] rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>
                                                    
                                                    {/* Reviewer Details */}
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt={r.reviewerFirstName}
                                                                src={getAvatar(r.reviewerAvatar, 200)}
                                                                key={r.id}
                                                                referrerPolicy="no-referrer"
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>{r.reviewerFirstName} {r.reviewerLastName}</div>
                                                            <div className='relative text-xs leading-[17px]'>{new Date(r.ratingDate).toLocaleString()}</div>
                                                        </div>
                                                    </div>

                                                    <div className='self-stretch flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>

                                                            {/* RATING STARS */}                  
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                {r.rating
                                                                    ? <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>                                                
                                                                        {[...Array(Math.trunc(r.rating as number) + 1).keys()].slice(1).map((i) => 
                                                                            <img key={`rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                        )}
                                                                        {(r.rating as number % 1 !== 0) && <div className='relative w-2.5 h-5 overflow-hidden flex items-center p-0'>
                                                                                <img className='object-contain w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                            </div>
                                                                        }
                                                                        &nbsp;
                                                                        {r.rating}
                                                                    </div>
                                                                    : <span>Rating: <i>Not Available</i></span>
                                                                }
                                                                

                                                                {/* RATING CONTENT TEXT */}
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px] min-h-[100px] max-h-[100px] text-ellipsis overflow-y-auto p-2'>{r.feedback}</div>
                                                                </div>
                                                                {/* <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div> */}
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        : <div className='self-stretch rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start gap-[16px] text-base'>
                                            <i>No MRA Reviews Yet</i>
                                        </div>
                                    }
                                </div>

                                {/* Hidden Items */}
                                <>
                                    {/* ////  HIDDEN  ///////////////////////////////////////////////////////////// */}

                                    {/* Profile Landscape */}
                                    <div className='hidden h-[367px]'>
                                        <img className='absolute top-[0px] left-[0px] w-[1280px] h-[366.25px] object-cover' alt='' src='/img/mra-profile/video@2x.png' />
                                        <div className='absolute top-[298px] left-[0px] w-[1280px] flex flex-row py-0 px-[26px] box-border items-start justify-start gap-[14px]'>
                                            <div className='flex-1 flex flex-row items-center justify-start gap-[14px]'>
                                                <img className='relative rounded-[28.18px] w-[42px] h-[42px]' alt='' src='/img/mra-profile/btnprofile.svg' />
                                                <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/btnprofile1.svg' />
                                                <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/btnprofile2.svg' />
                                                <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/btnprofile3.svg' />
                                            </div>
                                            <div className='w-[42px] h-[42px] flex flex-row items-center justify-end relative gap-[10px]'>
                                                <div className='relative rounded-6xs bg-colors-gray-700 box-border w-11 h-11 opacity-[0.8] z-[0] border-[1px] border-solid border-colors-gs-surface-secondary-dark' />
                                                <img
                                                    className='absolute my-0 mx-[!important] top-[calc(50%_-_13.82px)] left-[calc(50%_-_13.18px)] rounded-[14.09px] w-[27px] h-[27px] z-[1]'
                                                    alt=''
                                                    src='/img/mra-profile/iconexpandwide.svg'
                                                />
                                            </div>
                                        </div>
                                        <div className='absolute top-[26px] right-[26px] flex flex-col items-end justify-start gap-[14px]'>
                                            <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/icondownload.svg' />
                                            <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/iconlike.svg' />
                                            <img className='relative rounded-[28.18px] w-11 h-11' alt='' src='/img/mra-profile/iconshare.svg' />
                                        </div>
                                    </div>

                                    {/* Photo Slideshow */}
                                    <div className='hidden self-stretch relative rounded-xl shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-[300px]'>
                                        <img className='absolute top-[0px] left-[0px] w-[1280px] h-[300px] object-cover' alt='' src='/img/mra-profile/slideshow@2x.png' />
                                        <div className='absolute top-[calc(50%_-_30px)] left-[calc(50%_-_623px)] w-[1246px] flex flex-row items-center justify-center'>
                                            <div className='flex-1 flex flex-row items-center justify-start'>
                                                <img className='relative rounded-21xl w-[62px] h-[62px]' alt='' src='/img/mra-profile/btnprofile4.svg' />
                                            </div>
                                            <div className='self-stretch flex flex-row items-center justify-end'>
                                                <img className='relative rounded-21xl w-[62px] h-[62px]' alt='' src='/img/mra-profile/btnprofile5.svg' />
                                            </div>
                                        </div>
                                        <img className='absolute bottom-[0px] left-[calc(50%_-_41px)] w-[83px] h-[83px]' alt='' src='/img/mra-profile/iconellipses-horiz.svg' />
                                    </div>

                                    {/* Production History */}
                                    <div className='hidden self-stretch flex flex-row items-start justify-start'>
                                        <div className='flex-1 rounded-xl flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Production History</div>
                                            <div className='rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] w-[1280px] flex flex-col py-4 px-0 box-border items-start justify-start text-base'>
                                                <div className='self-stretch flex flex-col items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch h-[22px] flex flex-row py-0 px-4 box-border items-center justify-start gap-[16px]'>
                                                        <div className='flex-1 flex flex-row py-2 px-0 items-center justify-start'>
                                                            <div className='relative tracking-[0.02em] leading-[24px]'>2022</div>
                                                        </div>
                                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                                            <div className='relative tracking-[0.02em] leading-[24px]'>2021</div>
                                                        </div>
                                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                                            <div className='relative tracking-[0.02em] leading-[24px]'>2020</div>
                                                        </div>
                                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                                            <div className='relative tracking-[0.02em] leading-[24px]'>2019</div>
                                                        </div>
                                                        <div className='flex-1 flex flex-row py-2 px-0 items-start justify-start'>
                                                            <div className='relative tracking-[0.02em] leading-[24px]'>2018</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch flex flex-row py-0 px-4 items-center justify-start gap-[16px] text-sm'>
                                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>65</div>
                                                            </div>
                                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator1.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$36,879,000</div>
                                                            </div>
                                                        </div>
                                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator2.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>54</div>
                                                            </div>
                                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator3.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$9,230,000</div>
                                                            </div>
                                                        </div>
                                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator4.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>39</div>
                                                            </div>
                                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator5.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$1,752,000</div>
                                                            </div>
                                                        </div>
                                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[18px]'>
                                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator6.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>47</div>
                                                            </div>
                                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator7.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$879,000</div>
                                                            </div>
                                                        </div>
                                                        <div className='flex-1 rounded-md bg-surface-secondary flex flex-row py-2.5 px-3 items-center justify-start gap-[24px]'>
                                                            <div className='rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Units Sold</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator8.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>38</div>
                                                            </div>
                                                            <div className='flex-1 rounded-lg flex flex-col items-start justify-center gap-[4px]'>
                                                                <div className='relative leading-[21px]'>Sales Volume</div>
                                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator9.svg' />
                                                                <div className='relative text-xl tracking-[-0.24px] leading-[26px] font-semibold'>$745,000</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Values, Community Involvement */}
                                    <div className='hidden self-stretch flex flex-row items-start justify-start gap-[50px]'>
                                        <div className='flex-1 rounded-xl h-[220px] flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Values</div>
                                            <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start'>
                                                    <div className='self-stretch flex-1 relative tracking-[0.1px] leading-[24px]'>
                                                        Our Values: Integrity, Meeting people where they are at, Philanthropy, Trust. Our Mission: To provide skilled consultation, negotiation, and representation to bring
                                                        the highest possible value for all your residential real estate needs. We value, advocate, and collaborate with our clients. Creating a stress-free experience.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-xl h-[220px] flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='self-stretch relative tracking-[-0.29px] leading-[40px] font-semibold'>Community Involvement</div>
                                            <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start'>
                                                    <div className='self-stretch flex-1 relative tracking-[0.1px] leading-[24px]'>
                                                        Austin Pet Rescue, Austin Children’s Shelter Contributor, Red Day Volunteer, President of non-profit Cain Cares
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Industry Involvement, Awards */}
                                    <div className='hidden self-stretch flex flex-row items-start justify-start gap-[50px]'>
                                        <div className='flex-1 rounded-xl h-[231px] flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Industry Involvement</div>
                                            <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                                <div className='self-stretch flex-1 flex flex-col items-start justify-start'>
                                                    <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                        Austin Board of Realtors Development Committee Member, KW Cares Committee Member, KW JAMS Committee Member, Annual Agent Meet Host
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex-1 rounded-xl h-[231px] flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='self-stretch relative tracking-[-0.29px] leading-[40px] font-semibold'>Awards & Recognition</div>
                                            <div className='self-stretch flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-5 items-start justify-start text-base'>
                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                    <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                                        At Home With Diversity®, Certified Residential Specialist, Certified Negotiation Expert, Certified Real Estate Brokerage Manager, Austin Business Journal Top 50
                                                        Award Winner, Platinum Award Winner 2013, 2014, Triple Platinum Award Winner 2019, 2020, 2021
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Reviews: Google & Yelp */}
                                    <div className='hidden self-stretch flex flex-col items-start justify-start gap-[50px]'>
                                        {/* Google Reviews */}
                                        <div className='self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Google Reviews</div>
                                            <div className='self-stretch flex flex-row items-start justify-start gap-[20px] text-sm'>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image@2x.png'
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>Ashley Vavasour</div>
                                                            <div className='relative text-xs leading-[17px]'>23 days ago</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>{`As a cooperating agent on the buying side, my experience with KodiKay Cain and Megan has been easy  `}</div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px] overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image1@2x.png'
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>Melanie Youngblood</div>
                                                            <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>We have worked with CRG three times and each time has been such a breath of fresh air.</div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px] overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image2@2x.png'
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>Joseph Campbell</div>
                                                            <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>
                                                                        I’ve worked with many realtors, but the Cain group is second to none. Highly recommended for Austin location.
                                                                    </div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px] overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image3@2x.png'
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>Cody Reid</div>
                                                            <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>The Cain team were great to work with starting from early conversations with KodiKay</div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Yelp Reviews */}
                                        <div className='self-stretch flex flex-col items-start justify-start gap-[20px]'>
                                            <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>Yelp Reviews</div>
                                            <div className='self-stretch flex flex-row items-start justify-start gap-[20px] text-sm'>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image4@2x.png'
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>Dianne Moore</div>
                                                            <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>I helped many buyers purchase a home that was listed with the KW/Cain Group</div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image5@2x.png'
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>Joseph A.</div>
                                                            <div className='relative text-xs leading-[17px]'>July 23</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>KodiKay, Megan and Connor were fantastic in helping us in the home buying process!</div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px]'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <img
                                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image6@2x.png'
                                                            />
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px]'>
                                                            <div className='relative leading-[21px] font-semibold'>Christine Gate</div>
                                                            <div className='relative text-xs leading-[17px]'>August 2</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>The Cain Team knows the Austin area. I highly recommend their team.</div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-6 items-start justify-start gap-[12px] text-center text-xl text-colors-gs-white'>
                                                    <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                        <div className='relative w-[42px] h-[42px]'>
                                                            <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl bg-brown'>
                                                                <div className='absolute h-3/6 w-[87.5%] top-[25%] left-[6.25%] tracking-[-0.24px] leading-[26px] font-semibold flex items-center justify-center'>F</div>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.6300048828125px] text-left text-sm text-colors-gs-brand-primary'>
                                                            <div className='relative leading-[21px] font-semibold'>Freddy Barrios</div>
                                                            <div className='relative text-xs leading-[17px]'>1 month ago</div>
                                                        </div>
                                                    </div>
                                                    <div className='self-stretch h-[170px] flex flex-col items-start justify-start text-left text-sm text-colors-gs-brand-primary'>
                                                        <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                            <div className='self-stretch flex-1 flex flex-col items-start justify-start gap-[7px]'>
                                                                <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                    <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                                </div>
                                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                                    <div className='self-stretch relative leading-[21px]'>
                                                                        KodiKay, Rickey, and Connor are exceptional agents in Austin, known for their expertise and connections
                                                                    </div>
                                                                </div>
                                                                <div className='relative text-base tracking-[0.02em] leading-[24px] text-colors-gray-700'>Read more</div>
                                                            </div>
                                                            <div className='self-stretch flex flex-col items-start justify-end text-xs text-colors-gray-700'>
                                                                <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                                                    <img className='relative w-[30px] h-[30px]' alt='' src='/img/mra-profile/iconssocialyelp1.svg' />
                                                                    <div className='flex-1 flex flex-col items-start justify-start'>
                                                                        <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                                        <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Yelp</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* MRA Ratings and Reviews */}
                                    <div className='hidden self-stretch rounded-xl shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-col items-start justify-start gap-[20px]'>
                                        <div className='relative tracking-[-0.29px] leading-[40px] font-semibold'>MRA Ratings and Reviews</div>
                                        <div className='self-stretch rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col py-5 px-6 items-start justify-start gap-[16px] text-sm text-colors-gs-dark'>
                                            <div className='self-stretch rounded-md bg-colors-gs-white flex flex-row items-start justify-start gap-[16px] text-base text-colors-gs-brand-primary'>
                                                <div className='flex-1 flex flex-col items-start justify-start'>
                                                    <div className='flex flex-row pt-0 px-0 pb-1 items-center justify-start'>
                                                        <div className='relative tracking-[0.2px] leading-[24px] font-medium'>Buyer Score</div>
                                                    </div>
                                                    <div className='self-stretch flex-1 rounded-md bg-surface-secondary flex flex-row p-2 items-start justify-start gap-[16px] text-xs text-colors-gs-tertiary'>
                                                        <div className='self-stretch rounded flex flex-col items-start justify-start gap-[4px]'>
                                                            <div className='self-stretch relative tracking-[0.26px] leading-[18px] font-medium'>Score</div>
                                                            <b className='self-stretch flex-1 relative text-base tracking-[0.1px] leading-[24px] flex text-colors-gs-brand-primary text-center items-center justify-center'>
                                                                4
                                                            </b>
                                                        </div>
                                                        <div className='rounded overflow-hidden flex flex-col items-start justify-start gap-[4px]'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Rating</div>
                                                            <div className='flex flex-row flex-wrap items-center justify-start gap-[2px]'>
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharp.svg' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 flex flex-col items-start justify-start'>
                                                    <div className='flex flex-row pt-0 px-0 pb-1 items-center justify-start'>
                                                        <div className='relative tracking-[0.2px] leading-[24px] font-medium'>{`Seller Score `}</div>
                                                    </div>
                                                    <div className='self-stretch flex-1 rounded-md bg-surface-secondary flex flex-row p-2 items-start justify-start gap-[16px] text-xs text-colors-gs-tertiary'>
                                                        <div className='self-stretch rounded flex flex-col items-start justify-start gap-[4px]'>
                                                            <div className='self-stretch relative tracking-[0.26px] leading-[18px] font-medium'>Score</div>
                                                            <b className='self-stretch flex-1 relative text-base tracking-[0.1px] leading-[24px] flex text-colors-gs-brand-primary text-center items-center justify-center'>
                                                                3
                                                            </b>
                                                        </div>
                                                        <div className='rounded overflow-hidden flex flex-col items-start justify-start gap-[4px]'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Rating</div>
                                                            <div className='flex flex-row flex-wrap items-center justify-start gap-[2px]'>
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharp.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharp.svg' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 flex flex-col items-start justify-start'>
                                                    <div className='flex flex-row pt-0 px-0 pb-1 items-center justify-start'>
                                                        <div className='relative tracking-[0.2px] leading-[24px] font-medium'>Agent Score</div>
                                                    </div>
                                                    <div className='self-stretch flex-1 rounded-md bg-surface-secondary flex flex-row p-2 items-start justify-start gap-[16px] text-xs text-colors-gs-tertiary'>
                                                        <div className='self-stretch rounded flex flex-col items-start justify-start gap-[4px]'>
                                                            <div className='self-stretch relative tracking-[0.26px] leading-[18px] font-medium'>Score</div>
                                                            <b className='self-stretch flex-1 relative text-base tracking-[0.1px] leading-[24px] flex text-colors-gs-brand-primary text-center items-center justify-center'>
                                                                5
                                                            </b>
                                                        </div>
                                                        <div className='rounded overflow-hidden flex flex-col items-start justify-start gap-[4px]'>
                                                            <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Rating</div>
                                                            <div className='flex flex-row flex-wrap items-center justify-start gap-[2px]'>
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                                <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid.svg' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-4 items-start justify-start text-brand-secondary'>
                                                <div className='self-stretch rounded-sm flex flex-col items-center justify-start gap-[4px] text-center text-base text-link-blue'>
                                                    <div className='flex flex-row items-center justify-start gap-[8px]'>
                                                        <img className='relative w-4 h-4 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconadd36.svg' />
                                                        <div className='relative tracking-[0.02em] leading-[24px]'>WRITE A REVIEW</div>
                                                    </div>
                                                    <div className='relative text-21xl tracking-[-1.57px] leading-[46px] text-colors-gs-dark'>5.0</div>
                                                    <div className='flex flex-row items-center justify-start gap-[1px]'>
                                                        <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                                        <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                                        <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                                        <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                                        <img className='relative w-3.5 h-3.5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid1.svg' />
                                                    </div>
                                                    <div className='relative text-sm leading-[21px] text-colors-gs-tertiary inline-block min-w-[16px]'>(13)</div>
                                                </div>
                                                <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                    <div className='relative leading-[21px]'>5</div>
                                                    <div className='flex-1 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border flex flex-col py-0 pr-2 pl-0 items-start justify-center'>
                                                        <div className='self-stretch rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-alert' />
                                                    </div>
                                                </div>
                                                <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                    <div className='relative leading-[21px]'>4</div>
                                                    <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                                        <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-2' />
                                                    </div>
                                                </div>
                                                <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                    <div className='relative leading-[21px]'>3</div>
                                                    <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                                        <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-px' />
                                                    </div>
                                                </div>
                                                <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                    <div className='relative leading-[21px]'>2</div>
                                                    <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                                        <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-0.5' />
                                                    </div>
                                                </div>
                                                <div className='self-stretch flex flex-row items-center justify-start gap-[12px]'>
                                                    <div className='relative leading-[21px]'>1</div>
                                                    <div className='flex-1 relative rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-colors-gs-border h-2'>
                                                        <div className='absolute top-[0px] left-[0px] bg-colors-gs-alert w-0.5' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-5 items-start justify-start gap-[16px]'>
                                                <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[16px] text-colors-gs-brand-primary'>
                                                    <div className='rounded-sm hidden flex-col items-start justify-start'>
                                                        <div className='relative rounded-21xl w-14 h-14'>
                                                            <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white' />
                                                            <img
                                                                className='absolute h-[88.89%] w-[88.89%] top-[5.56%] right-[5.56%] bottom-[5.56%] left-[5.56%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image7@2x.png'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex-1 rounded-sm flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px] font-medium'>Review from Seller</div>
                                                        <div className='relative text-2xs tracking-[0.26px] leading-[18px] font-medium text-colors-gs-tertiary'>August 1, 2023</div>
                                                    </div>
                                                    <div className='flex flex-row items-center justify-end gap-[16px] text-right'>
                                                        <div className='flex flex-row items-center justify-end'>
                                                            <div className='relative leading-[21px] font-medium'>Rating: 5.0</div>
                                                        </div>
                                                        <div className='flex flex-row items-center justify-end gap-[2px]'>
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator0.svg' />
                                                <div className='self-stretch rounded-sm flex flex-col items-start justify-start text-base'>
                                                    <div className='self-stretch relative tracking-[0.1px] leading-[24px] font-semibold'>Quality and timing second to none</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                    <div className='self-stretch relative leading-[21px]'>
                                                        The Bakas Team, from beginning to end, handled the home sale process with complete support and empathy for my sister who was moving from Chicago to North
                                                        Carolina...selling a home loaded with memories after an 18-year stay. Their marketing efforts, including professional photography that showed the home beautifully,
                                                        generated many showings. An offer quickly came in and Bob&apos;s fine-tuned negotiation skills enabled the property to sell at top dollar. We cannot thank the Bakas
                                                        Team enough for making this home selling experience a very positive one. My sister and I highly recommend The Bakas Team to anyone thinking about selling or buying
                                                        real estate. They are professional, knowledgeable, friendly, responsive, results-oriented, and empathetic when the need arises. Choosing The Bakas Team with Keller
                                                        William Realty is a decision you will not regret.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-5 items-start justify-start gap-[16px]'>
                                                <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[16px] text-colors-gs-brand-primary'>
                                                    <div className='rounded-sm hidden flex-col items-start justify-start'>
                                                        <div className='relative rounded-21xl w-14 h-14'>
                                                            <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white' />
                                                            <img
                                                                className='absolute h-[88.89%] w-[88.89%] top-[5.56%] right-[5.56%] bottom-[5.56%] left-[5.56%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image7@2x.png'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex-1 rounded-sm flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px] font-medium'>Review from KodiKay</div>
                                                        <div className='relative text-2xs tracking-[0.26px] leading-[18px] font-medium text-colors-gs-tertiary'>July 28, 2023</div>
                                                    </div>
                                                    <div className='flex flex-row items-center justify-end gap-[16px] text-right'>
                                                        <div className='flex flex-row items-center justify-end'>
                                                            <div className='relative leading-[21px] font-medium'>Rating: 5.0</div>
                                                        </div>
                                                        <div className='flex flex-row items-center justify-end gap-[2px]'>
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator0.svg' />
                                                <div className='self-stretch rounded-sm flex flex-col items-start justify-start text-base'>
                                                    <div className='self-stretch relative tracking-[0.1px] leading-[24px] font-semibold'>So happy I went with Teresa!</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                    <div className='self-stretch relative leading-[21px]'>
                                                        I’m so happy I found Teresa on MRA! She kept me in the loop during the whole process and had my client’s back beginning to end. Thank you Teresa!
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='self-stretch rounded-md bg-colors-gs-surface-primary flex flex-col p-5 items-start justify-start gap-[16px]'>
                                                <div className='self-stretch rounded-sm flex flex-row items-center justify-start gap-[16px] text-colors-gs-brand-primary'>
                                                    <div className='rounded-sm hidden flex-col items-start justify-start'>
                                                        <div className='relative rounded-21xl w-14 h-14'>
                                                            <div className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white' />
                                                            <img
                                                                className='absolute h-[88.89%] w-[88.89%] top-[5.56%] right-[5.56%] bottom-[5.56%] left-[5.56%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                                alt=''
                                                                src='/img/mra-profile/image7@2x.png'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex-1 rounded-sm flex flex-col items-start justify-start'>
                                                        <div className='self-stretch relative leading-[21px] font-medium'>Review from Seller</div>
                                                        <div className='relative text-2xs tracking-[0.26px] leading-[18px] font-medium text-colors-gs-tertiary'>July 8, 2023</div>
                                                    </div>
                                                    <div className='flex flex-row items-center justify-end gap-[16px] text-right'>
                                                        <div className='flex flex-row items-center justify-end'>
                                                            <div className='relative leading-[21px] font-medium'>Rating: 5.0</div>
                                                        </div>
                                                        <div className='flex flex-row items-center justify-end gap-[2px]'>
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                            <img className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarsharpsolid2.svg' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className='self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0' alt='' src='/img/mra-profile/separator0.svg' />
                                                <div className='self-stretch rounded-sm flex flex-col items-start justify-start text-base'>
                                                    <div className='self-stretch relative tracking-[0.1px] leading-[24px] font-semibold'>One of the best home buying experience I’ve ever had!</div>
                                                </div>
                                                <div className='self-stretch flex flex-col items-start justify-start'>
                                                    <div className='self-stretch relative leading-[21px]'>
                                                        Knowledgeable, friendly, experienced. Also, responded quickly to any and all of questions that I may have had. Would highly recommend KodiKay and her team!
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='self-stretch flex flex-row items-center justify-center gap-[8px] text-center text-base text-link-blue'>
                                                <div className='relative tracking-[0.02em] leading-[24px]'>{`View 10 more `}</div>
                                                <img className='relative w-3 h-3 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconchevrondownsolid.svg' />
                                            </div>
                                        </div>
                                    </div>
                                </>

                                <div className='h-[125px]' />
                            </div>
                        </div>
                }
                </>
            }
            
        </>
    );
};

export { AgentProfilePublic };
