import React, { FunctionComponent } from "react";

type Props = {
    children?: React.ReactNode;
    hideIf?: boolean;
}

const HideChildren: FunctionComponent<Props> = ({ children, hideIf = true }) => {
    return (hideIf ? <></> : children)
};

export { HideChildren };
