import React, { FunctionComponent } from 'react';
import useZustandAuthStore from '../../_Zustand/auth-store';

type Props = {
    onOpenSupport: () => void;
}

const FooterCommon: FunctionComponent<Props> = ({ onOpenSupport }) => {

    const { authState } = useZustandAuthStore();

    return (
        <div className="mt-auto border-t border-solid border-slate-200 w-full flex justify-center">
            <div className="py-[10px] self-stretch flex flex-row w-full items-center justify-between text-left text-xs text-tertiary bg-white font-h5-semibold">
                <div className="flex-1 relative leading-[17px] ml-[20px]">
                    Copyright © {new Date().getFullYear()} My Referral Agent
                </div>
                <div className="flex flex-row items-center justify-end gap-[28px] mr-[20px]">
                    
                    {/* <div className="relative leading-[17px]">The MRA Team</div> */}
                    
                    {(!!authState.apiToken)
                        && <div
                                className="relative leading-[17px] cursor-pointer text-secondary hover:underline"
                                onClick={onOpenSupport}
                            >
                                Get Support
                            </div>
                    }

                    {/* <div className="relative leading-[17px]">Blog</div> */}
                    
                    <a className="relative leading-[17px] no-underline text-secondary hover:underline" target='_blank' href="https://www.myreferralagent.com/privacy-policy-and-terms-of-service">Privacy Policy &amp; Terms of Service</a>
                </div>
            </div>
        </div>
    );
};

export { FooterCommon };
