import fetch from 'cross-fetch';
import setTimeoutLong from './set-timeout-long';

const timeoutMessage = 'Connection timed out.';

const timedOutFetch = (
    timeout: number,
) => (
    url: string,
    init?: RequestInit,
): Promise<Response> => new Promise(
    (res, rej) => {
        let isTimedOut = false;
        const controller = new AbortController();
        
        const clearTimeoutLong = setTimeoutLong(
            () => {
                isTimedOut = true;
                console.log("Timeout from timedOutFetch()");
                controller.abort();
                rej(new Error(timeoutMessage));
            },
            timeout,
        );
        
        const wrap = (cb: (data: unknown) => void) => (data: unknown): void => {
            if (isTimedOut) {
                return;
            }
            clearTimeoutLong();
            cb(data);
        };
        
        fetch(url, { ...init, signal: controller.signal })
            .then(wrap(res as (data: unknown) => void), wrap(rej));
            // .catch(wrap(rej));
    },
);

export default timedOutFetch;
