export const toCurrency = (inputInt): string => {
    if (!inputInt) {
        return ''
    }
    const parts = inputInt.toString().split('.');
    const value = parts[0].replace(/\D/g, '');
    
    const newValue = `$${new Intl.NumberFormat('en-US').format(value)}`;
    
    return value === '' && newValue === '$0' ? '' : newValue;
}