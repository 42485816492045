import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import useZustandAuthStore from "../_Zustand/auth-store";
import { ReactSession } from 'react-client-session';

type Props = {
    mainLogout: () => void;
};
const LicenseVerificationPending: React.FunctionComponent<Props> = ({ mainLogout }) => {

    const { user } = useAuth0();
    const { setReferralInvitation, resetZustandState } = useZustandAuthStore();
    
    const doLogout = () => {
        mainLogout();
    }

    useEffect(() => {
        setReferralInvitation(ReactSession.get("referralInvitation"));
        resetZustandState();
        document.cookie = '';
        localStorage.clear();
        sessionStorage.clear();
    }, []);

    return (
        <div className="flex flex-grow items-top justify-center ">
            <div className="w-full flex xs:flex-col lg:flex-row justify-center">

                {/* left pane */}
                <div className="hidden flex flex-1 flex-col justify-top pr-[30px] py-[10px] bg-brown">
                    <div className="ml-[30px] flex flex-col items-center justify-between text-white">
                        <div className="flex flex-1 flex-col xs:px-[16px] xs:py-[50px] lg:pt-[113px] items-center justify-between text-white bg-brown">
                            <div className="xs:w-fit lg:w-[450px] flex flex-col justify-center items-center gap-[50px]">

                                <div className="relative xs:text-21xl lg:text-37xl xs:leading-[40px] lg:leading-[62px] inline-block">
                                    Send, receive and track referrals with some of the best real estate
                                    agents on earth
                                </div>

                                <div className="self-stretch relative text-lg leading-[25px] font-dm-sans justify-start">
                                    We&apos;d love for you to join us!
                                </div>

                                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] font-dm-sans">
                                    <div className="flex flex-row items-center justify-start">
                                        <div className="relative rounded-21xl w-9 h-9">
                                            {/* <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white" /> */}
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image1@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image2@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image3@2x.png"
                                            />
                                        </div>
                                    </div>
                                    <div className="relative leading-[150%] font-sm">
                                        {/* Join 2.5M+ users */}
                                        Be part of an elite group of referrers!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* right pane */}
                <div className="flex flex-1 flex-col justify-top pr-[10px] py-[10px] bg-surface-secondary">
                    <div className="flex flex-col items-center justify-between ml-[10px]">
                        <div className="flex flex-1 flex-col xs:px-[16px] xs:py-[50px] lg:pt-[113px] items-center justify-between text-white">
                            <div className="xs:w-fit lg:w-[450px] flex flex-col justify-center items-center gap-[12px] ">
                                <div className="flex flex-col items-end justify-start">
                                    <div className="flex flex-col max-w-full items-center justify-start py-0 px-5 box-border gap-[20px] text-base text-colors-gs-brand-secondary font-medium-semibold">
                                        <div className="self-stretch flex text-5xl font-bold text-start">
                                            License verification in progress!
                                        </div>
                                        <div className="self-stretch flex flex-col items-start justify-start gap-[22px] text-left">
                                            <div className="self-stretch flex flex-col relative leading-[24px] py-[20px] gap-[16px]">
                                                <div className="flex xs:flex-col lg:flex-row items-center gap-0 flex-wrap">
                                                <div className='flex flex-row flex-wrap gap-[5px] lg:items-center'>
                                                        <div className="ml-[-8px] flex w-fit rounded-md px-2 py-1 bg-surface-primary font-semibold">
                                                            {user?.email}
                                                        </div>
                                                        <div className='flex flex-row gap-0 items-center'>
                                                            (<div
                                                                    className='cursor-pointer text-link-blue'
                                                                    title='Use another email?'
                                                                    onClick={(e) => {
                                                                        doLogout();
                                                                    }}
                                                                >
                                                                Logout
                                                                </div>)
                                                        </div>
                                                    </div>
                                                </div>
                                                <span>
                                                    We are still verifying your REALTOR&reg; license.
                                                    You will receive an email from us when the verification process is complete. We truly appreciate your patience.
                                                </span>
                                                <span>
                                                    If you have not received a welcome email or have any questions, please reach out to us at&nbsp;
                                                    <a href='mailto:support@myreferralagent.com' target="_blank" className="text-link-blue">support@myreferralagent.com</a>.
                                                </span>
                                                <span>-The MRA Team</span>
                                            </div>
                                        </div>
                                        <div className="self-stretch flex flex-col items-start justify-start pt-[18px] px-0 pb-0">
                                            <div className="self-stretch relative tracking-[0.1px] leading-[24px] font-semibold">
                                                Please close this page.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export { LicenseVerificationPending };