import React, { FunctionComponent } from "react";

const ReferralActivityDataTable: FunctionComponent = () => {
    return (
        <div className="rounded-xl bg-colors-gs-white shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-col items-start justify-start pt-0 px-0 pb-4 gap-[8px] text-left text-sm text-tertiary font-large-med border-[1px] border-solid border-gray-200">
            <div className="rounded-t-xl rounded-b-none bg-gray-100 box-border w-[1280px] h-10 flex flex-row items-center justify-start py-0 px-4 gap-[20px] text-smi border-b-[1px] border-solid border-colors-gs-border">
                <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border gap-[10px]">
                    <div className="flex-1 relative font-semibold">
                        Client Name
                    </div>
                    <img
                        className="relative w-4 h-4 overflow-hidden shrink-0"
                        alt=""
                        src="/img/mra-dashboard-activity/iconchevrondownsolid.svg"
                    />
                </div>
                <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border">
                    <div className="relative font-semibold">Agent Name</div>
                </div>
                <div className="w-[108px] flex flex-row items-start justify-start py-2 px-0 box-border">
                    <div className="relative font-semibold">
                        Potential Income
                    </div>
                </div>
                <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                    <div className="relative font-semibold">In/Out</div>
                </div>
                <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                    <div className="relative font-semibold">Type</div>
                </div>
                <div className="w-[124px] flex flex-row items-center justify-start py-2 px-0 box-border">
                    <div className="relative font-semibold">Milestone</div>
                </div>
                <div className="w-[118px] flex flex-row items-center justify-start py-2 px-0 box-border">
                    <div className="relative font-semibold">Status</div>
                </div>
                <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                    <div className="relative font-semibold">Submitted on</div>
                </div>
                <div className="flex-1 flex flex-row items-center justify-start py-2 px-0">
                    <div className="relative font-semibold">Actions</div>
                </div>
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-center justify-start">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Steve Blankenship
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                2750 Everest Ln, Austin, TX 78534
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            Teresa Cagle
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$2,170*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt="outbound"
                            src="/img/mra-dashboard-activity/iconarrowupright.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Outbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Buyer</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi text-teal">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Not Yet Accepted
                        </div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            May 19, 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-center justify-start">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Pamela Clements
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                3400 Blythwood Dr., Austin, TX 78753
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            Shawn Finigan
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$2,850*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt=""
                            src="/img/mra-dashboard-activity/iconarrowdownleft2.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Outbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Buyer</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi text-teal">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Incomplete
                        </div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Jun 12, 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-center justify-start">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Keith Cimaszewki
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                720 Garden Oaks Dr., Austin, TX 78753
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            Chris Henrick
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$5,300*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt="outbound"
                            src="/img/mra-dashboard-activity/iconarrowupright.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Outbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Seller</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Returned</div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Jun 8, 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-center justify-start">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Maggie Dodge
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                185 Sundown Pkwy, Austin, TX 78746
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            Deseree Rowels
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$2,800*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt=""
                            src="/img/mra-dashboard-activity/iconarrowdownleft2.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Inbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Buyer</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Incomplete
                        </div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Jul 3, 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-center justify-start">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Todd Hanson
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                905 Applegate Dr. Austin TX 78753
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            Susan Swain
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$4,360*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt=""
                            src="/img/mra-dashboard-activity/iconarrowdownleft3.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Inbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Seller</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Incomplete
                        </div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Aug 8, 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-start justify-center">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Sarah Hunt
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                1600 Wycliff Ln. Austin, TX 78727
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            Michael Levi
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$2,770*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt=""
                            src="/img/mra-dashboard-activity/iconarrowdownleft2.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Inbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Seller</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Incomplete
                        </div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            July 21, 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-start justify-center">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Lillian Kearsley
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                287 Brown Park Pl, Austin, TX 78755
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            David Palms
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$2,610*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt=""
                            src="/img/mra-dashboard-activity/iconarrowdownleft2.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Inbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Buyer</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Incomplete
                        </div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Jul, 11 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-[0.8px] shrink-0"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="bg-colors-gs-white w-[1280px] flex flex-col items-start justify-center">
                <div className="self-stretch flex flex-row items-center justify-start py-1 px-4 gap-[20px]">
                    <div className="w-[300px] flex flex-row items-start justify-start py-2 px-0 box-border text-base text-colors-gs-brand-primary">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="self-stretch relative tracking-[0.23px] leading-[162.5%] font-semibold">
                                Danielle Kurek
                            </div>
                            <div className="self-stretch relative text-sm leading-[21px] text-tertiary">
                                120 Dorsett Oaks Cir, Austin, TX 78726
                            </div>
                        </div>
                    </div>
                    <div className="w-[120px] flex flex-row items-start justify-start py-2 px-0 box-border text-link-blue">
                        <div className="relative leading-[21px]">
                            Chris Henrick
                        </div>
                    </div>
                    <div className="w-[108px] flex flex-row items-center justify-start py-2 px-0 box-border text-lg text-colors-gs-brand-primary">
                        <b className="relative leading-[25px]">$3,140*</b>
                    </div>
                    <div className="w-[50px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <img
                            className="relative w-[26px] h-[26px]"
                            alt="outbound"
                            src="/img/mra-dashboard-activity/iconarrowupright.svg"
                        />
                        <div className="relative leading-[21px] hidden">
                            Inbound
                        </div>
                    </div>
                    <div className="w-[70px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">Buyer</div>
                    </div>
                    <div className="w-[124px] flex flex-row items-start justify-start py-2 px-0 box-border text-center text-smi">
                        <div className="rounded bg-honeydew box-border h-8 overflow-hidden flex flex-row items-center justify-center py-0 px-3 gap-[8px] border-[1px] border-solid border-powderblue-100">
                            <div className="relative font-semibold inline-block min-w-[16px]">
                                Opportunities
                            </div>
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconadd1.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[118px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Incomplete
                        </div>
                    </div>
                    <div className="w-[110px] flex flex-row items-start justify-start py-2 px-0 box-border">
                        <div className="relative leading-[21px]">
                            Aug 5, 2023
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-start py-2 px-0 gap-[8px]">
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconemail.svg"
                            />
                        </div>
                        <div className="relative w-8 h-8">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md bg-gray-50 box-border border-[1px] border-solid border-colors-gs-border" />
                            </div>
                            <img
                                className="absolute h-3/6 w-6/12 top-[25%] right-[25%] bottom-[25%] left-[25%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/img/mra-dashboard-activity/iconopen.svg"
                            />
                        </div>
                    </div>
                </div>
                <img
                    className="self-stretch relative max-w-full overflow-hidden h-0 shrink-0 hidden"
                    alt=""
                    src="/img/mra-dashboard-activity/separator.svg"
                />
            </div>
            <div className="box-border w-[1280px] flex flex-row items-center justify-between pt-4 px-4 pb-0 text-center text-base text-gray-500 border-t-[2px] border-solid border-colors-gs-border">
                <div className="flex-1 flex flex-row items-center justify-center gap-[16px]">
                    <button
                        className="cursor-pointer py-2 px-4 bg-colors-gs-white rounded-md box-border h-10 flex flex-row items-center justify-start gap-[8px] border-[1px] border-solid border-colors-gray-400"
                        autoFocus={false}
                    >
                        <img
                            className="relative w-[18px] h-[18px] overflow-hidden shrink-0 hidden"
                            alt=""
                            src="/img/mra-dashboard-activity/icongiftsolid3.svg"
                        />
                        <div className="relative text-base tracking-[0.1px] leading-[24px] font-semibold font-large-med text-colors-gs-brand-primary text-center">
                            Previous
                        </div>
                        <img
                            className="relative w-[18px] h-[18px] hidden"
                            alt=""
                            src="/img/mra-dashboard-activity/iconfollowsolid3.svg"
                        />
                    </button>
                    <div className="flex-1 flex flex-row items-center justify-center gap-[24px]">
                        <div className="rounded bg-link-blue h-7 flex flex-col items-center justify-center py-0.5 px-2.5 box-border text-colors-gs-white">
                            <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                1
                            </div>
                        </div>
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            2
                        </div>
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            3
                        </div>
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium hidden">
                            4
                        </div>
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium hidden">
                            5
                        </div>
                    </div>
                    <button
                        className="cursor-pointer py-2 px-4 bg-colors-gs-white rounded-md box-border h-[34px] flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-colors-gray-400"
                        autoFocus={false}
                    >
                        <img
                            className="relative w-[18px] h-[18px] overflow-hidden shrink-0 hidden"
                            alt=""
                            src="/img/mra-dashboard-activity/icongiftsolid3.svg"
                        />
                        <div className="relative text-base tracking-[0.1px] leading-[24px] font-semibold font-large-med text-colors-gs-brand-primary text-center">
                            Next
                        </div>
                        <img
                            className="relative w-[18px] h-[18px] hidden"
                            alt=""
                            src="/img/mra-dashboard-activity/iconfollowsolid3.svg"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReferralActivityDataTable;
