import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import AsyncSelect from 'react-select/async';
import { StylesConfig } from "react-select";
import { reloadImgSrc } from "../../utils/reload-img-src";

type Props = {
    placeId: string,
}

const GooglePlaceReviewCardSet: FunctionComponent<Props> = ({ placeId }) => {

    const [name, setName] = useState<string>('');
    const [reviews, setReviews] = useState<google.maps.places.PlaceReview[]>([]);
    const [placeDetails, setPlaceDetails] = useState<any>();

    useEffect(() => {
        if (!placeId) {
            return;
        }

        const map = new google.maps.Map(document.createElement('div'));
        const googlePlacesService = new google.maps.places.PlacesService(map);
        googlePlacesService.getDetails({
            placeId,
            fields: ['name','formatted_address', 'reviews', 'rating', 'url'],
        }, (placeDetails, status) => {
            if (status === 'OK') {
                setPlaceDetails(placeDetails);
                console.log(placeDetails);
                
                const placeName = `${placeDetails?.name as string}, ${placeDetails?.formatted_address as string}`;
                setName(placeName);
                setReviews(placeDetails?.reviews as google.maps.places.PlaceReview[]);
            }
        });
    }, [placeId])
    
    return (
        <>
        {placeDetails
            && <div className="flex flex-col self-stretch w-full items-start justify-start gap-[10px]">
                <div className="flex flex-col self-stretch w-full flex-wrap items-start justify-start gap-[16px]">
                    {/* Place Details and Overall Rating */}
                    <div className="flex flex-row gap-[3px] text-3xl-1 items-center">
                        <img src="/img/mra-profile/iconlocationdot.svg" />
                        {name}
                    </div>
                    <div className="text-3xl-1 flex items-center gap-[10px]">
                        <div>Overall Rating:</div>
                        {placeDetails.rating
                            ? <div className="flex flex-1 items-center">
                                {[...Array(Math.trunc(placeDetails.rating as number) + 1).keys()].slice(1).map((i) => 
                                    <img key={`overall-rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                )}
                                {(placeDetails.rating % 1 !== 0)
                                    && <div className='relative w-2.5 h-5 overflow-hidden flex items-center p-0'>
                                        <img className='object-contain w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                    </div>
                                }
                                &nbsp;
                                {placeDetails.rating}
                            </div>
                            : <i>Not Available</i>
                        }
                    </div>
                    <div className="flex flex-row gap-[10px]">
                        {reviews ? `Displaying ${reviews.length} reviews.` : `No Reviews`}
                        <span
                            className="cursor-pointer text-link-blue hover:underline flex items-center gap-[5px]"
                            onClick={() => window.open(placeDetails.url, "_blank")}
                        >
                            See All Reviews in Google <img
                                                            className="relative w-[20px] h-[20px] overflow-hidden shrink-0 rounded-xl"
                                                            alt=""
                                                            src="/img/mra-referral-timeline/icon-open.svg"
                                                            
                                                        />
                        </span>
                    </div>

                    {/* Upto 5 reviews */}
                    <div className="flex flex-row self-stretch w-full flex-wrap items-start justify-start gap-[20px]">

                        {/* 5 REVIEW CARDS */}
                        {reviews && reviews.map((r, idx) => <div key={idx}>

                                {/* Review Card */}
                                <div className='border border-solid hover:border-gray-400 border-transparent w-[335px] h-[390px] flex flex-col p-6 items-start justify-start gap-[12px] rounded-lg bg-colors-gs-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]'>                                
                                    {/* Reviewer Info */}
                                    <div className='self-stretch flex flex-row h-[64px] items-center justify-start gap-[12px]'>
                                        <div className='relative w-[62px] h-[62px]'>
                                            <img
                                                className='absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover'
                                                alt={r.author_name}
                                                src={r.profile_photo_url}
                                                key={r.author_url}
                                                referrerPolicy="no-referrer"
                                            />
                                        </div>
                                        <div className='flex flex-col items-start justify-center gap-[4px] max-w-[203.4px] h-[80px]'>
                                            <div className='relative leading-[21px] font-semibold'>{r.author_name}</div>
                                            <div className='relative text-xs leading-[17px]'>{r.relative_time_description}</div>
                                        </div>
                                    </div>

                                    {/* Rating Stars */}
                                    {r.rating
                                        ? <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'>
                                            {[...Array(Math.trunc(r.rating as number) + 1).keys()].slice(1).map((i) => 
                                                <img key={`rating-star-${i}`} className='relative w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                            )}
                                            {(r.rating as number % 1 !== 0) && <div className='relative w-2.5 h-5 overflow-hidden flex items-center p-0'>
                                                    <img className='object-contain w-5 h-5 overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconstarroundedsolid.svg' />
                                                </div>
                                            }
                                            &nbsp;
                                            {r.rating}
                                        </div>
                                        : <div className='w-[100px] h-[22.55px] flex flex-row items-center justify-start'><span>Rating: <i>Not Available</i></span></div>
                                    }           

                                    {/* Rating Content Text */}
                                    <div className='self-stretch flex h-[220px] flex-col items-start justify-start'>
                                        <div className='self-stretch relative leading-[21px] max-h-[220px] text-ellipsis overflow-y-auto p-2'>{r.text}</div>
                                    </div>      

                                    {/* Card Footer */}
                                    <div className='self-stretch flex flex-col h-[400px] items-start justify-end text-xs text-colors-gray-700'>
                                        <div className='self-stretch flex flex-row items-center justify-start gap-[10px]'>
                                            <img className='relative w-[30px] h-[30px] overflow-hidden shrink-0' alt='' src='/img/mra-profile/iconssocialgoogle1.svg' />
                                            <div className='flex-1 flex flex-col items-start justify-start'>
                                                <div className='relative tracking-[0.26px] leading-[18px] font-medium'>Posted on</div>
                                                <div className='relative text-sm leading-[21px] font-medium text-colors-gs-alert-red'>Google</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default GooglePlaceReviewCardSet;
