import { StripeElementChangeEvent } from '@stripe/stripe-js';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
    TBorderName, TButtonGroupEmbed, TButtonGroupOnPress,
    TCbVoid,
    TColorsProp, TCustomProps, TInputEmbed, TInputOnChange,
    TListEmbed, TOnPress, TShapeName, TSpecSize, TStyle, TUnknownObject,
} from './utils/sierra';
import { IdToken, User } from '@auth0/auth0-react';


export type TStringList = ReadonlyArray<string>;

export type TStringNullList = ReadonlyArray<string | null>;

export type TNumberList = ReadonlyArray<number>;

export type TNumberNullList = ReadonlyArray<number | null>;

export type TBooleanList = ReadonlyArray<boolean>;

export type TDict<T = unknown> = Record<string, T>;

export type TCb<T> = (value: T) => void;

export type TPartialNull<T> = Readonly<{
    [P in keyof T]: T[P] | null;
}>;

export type RequiredAll<T> = {
    [P in keyof T]: NonNullable<T[P]>;
}

export type RequiredProps<T, K extends keyof T> = Omit<T, K> & RequiredAll<Pick<T, K>>;

export type TSaga = Generator<unknown, void, unknown>;

// export type TAction<
//     TPayload extends TDict = TDict,
//     TType = string,
//     > = Action<TType> & Readonly<{
//         payload: TPayload;
//     }>;

export type TItem<T extends Array<unknown>> = T extends (infer U)[] ? U : never;
export type TUseAsyncNameCb<P = never> = (error?: Error, data?: P) => void;

// export type TActionCreator<T extends TEmptyObject> = (payload: T) => TAction<T>;

// export type TActionCreatorOptional<T extends TEmptyObject> = (payload?: Partial<T>) => TAction<Partial<T>>;

// export type TActionCreatorEmpty = () => TAction<TEmptyObject>;

// export type TDispatch = Dispatch<TAction>;

// export type TDispatchReact<TPayload extends TDict = TDict> = React.Dispatch<TAction<TPayload>>;

export type TRole = 'Partial' | 'Administrator' | 'Agent' | 'Manager' | 'Supervisor';

export type TRoleList = ReadonlyArray<TRole>;

export type TAsyncStatus = 'active' | 'done' | 'failed' | 'none';

export type TAlign = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';

export type TJustify = 'flex-start' | 'flex-end' | 'center' | 'space-between' |
    'space-around' | 'space-evenly';

export type TDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';

export type TName = Readonly<{
    firstName: string;
    lastName: string;
}>;

export type TIdObject = Readonly<{
    id: number;
}>;

export type TTestIdObject = Readonly<{
    testId?: string | null;
}>;

export type TKey = Readonly<{
    key: number;
}>;

export type TOrderedId = Readonly<{
    id: number;
    order: number;
}>;

export type TIsLoading = Readonly<{
    isLoading: boolean;
}>;

// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
type TAutoComplete = 'off' | 'name' | 'honorific-prefix' | 'given-name' | 'additional-name' |
    'family-name' | 'honorific-suffix' | 'nickname' | 'email' | 'username' | 'new-password' |
    'current-password' | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' |
    'address-line1' | 'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' |
    'address-level2' | 'address-level1' | 'country' | 'country-name' | 'postal-code' | 'cc-name' |
    'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' |
    'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' |
    'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year'
    | 'url' | 'photo';

export type TInfoItemManageUser = Readonly<{
    name: string;
    value?: string | null;
    isLink?: boolean;
}>;

export type TInfoItemManageUserList = ReadonlyArray<TInfoItemManageUser>;

export type TPropsHeaderField = Readonly<{
    isRequired?: boolean | null;
    sizeText?: TSpecSize | null;
    colors?: TColorsProp | null;
    maxCharLabel?: number | null;
    header?: React.ReactNode;
}>;

export type TPropsField = Readonly<{
    label?: string | null;
    sizeLabel?: TSpecSize | null;
    sizeField?: TSpecSize | null;
    fontLabel?: string | null;
    lines?: number | null;
    padding?: string | number | null;
    margin?: string | number | null;
    testId?: string | null;
    style?: TStyle;
    flex?: number | null;
    alignLabel?: 'auto' | 'left' | 'right' | 'center' | 'justify' | null;
    colorsLabel?: TColorsProp | null;
    isDisabled?: boolean | null;
}>
    & TPropsHeaderField;

export type TPropsInputCustom = Readonly<{
    value?: string | null;
    placeholder?: string | null;
    colors?: TColorsProp | null;
    size?: TSpecSize | null;
    styleInput?: TStyle | null;
    border?: TBorderName | null;
    paddingInput?: string | null;
    shape?: TShapeName | null;
    isMulti?: boolean | null;
    secureTextEntry?: boolean | null;
    max?: number | null;
    kind?: 'normal' | 'simple';
    sizeText?: TSpecSize | null;
    autoComplete?: TAutoComplete | null;
    onChange?: TModalInputOnChange | null;
}>;

export type TPropsInputLine = Readonly<{
    controls?: React.ReactElement | null;
    useClear?: boolean | null;
    showCaret?: boolean | null;
    notes?: TListEmbed | null;
    isHidden?: boolean | null;
    disableAutoFormats?: boolean | null;
    children?: React.ReactNode | null;
    buttonGroup?: TButtonGroupEmbed | null;
    onPressButton?: TButtonGroupOnPress | null;
    onBlur?: TCbVoid;
    onMention?: TInputOnChange | null;
}>
    & TPropsInputCustom
    & TPropsRequiredField
    & TInputEmbed;

export type TPropsModalInput = TPropsInputLine & TPropsField;


export type TPropsRouteSecure = Readonly<{
    roleList?: TRoleList;
}>
    & RouteProps;

export type TPropsRouteSecureList = ReadonlyArray<TPropsRouteSecure>;

export type TOrderedIdList = ReadonlyArray<TOrderedId>;

export type TIdList = ReadonlyArray<TIdObject>;

export type TSortFunc<T extends ReadonlyArray<unknown>> = (list: T) => T;

export type TSortOrderName = keyof typeof SortOrder;

export type TRenderFn<T> = (item: T, index: number) => (React.ReactElement | null);

export type TSerializedObject = TDict<
string |
number |
boolean |
ReadonlyArray<number | string | boolean | null | undefined> |
null |
undefined>;

export type TProfileUserData = Readonly<{
    id: number;
    invitedById: number;
    invitedByFirstName: number;
    invitedByLastName: number;
    autorenewal: boolean;
    brokerageName: string;
    email: string;
    firstName: string;
    lastName: string;
    primaryImage: string | null;
    profileStep: number;
    role: TRole;
    downgradeFromFullMember: boolean | null;
    status: UserStatusEnum;
    renewalAmount: number | null;
    showRenewal: null;
    upgradeAmount: number | null;
    officeAddress1: string | null;
    officeAddress2: string | null;
    officeCity: string | null;
    officeStateId: number | null;
    officeZip: string | null;
    officePhoneNumber: string | null;
    cellPhoneNumber: string | null;
    publicProfile: string | null;
}>;

export type TSetStateFunc<T> = (prev: T) => T;

export type TSetState<T> = (newState: T | TSetStateFunc<T>) => void;

export type TChangeState<T> = (newState: T) => void;

export type TOptionsRouteTo = Readonly<{
    isNeedReload?: boolean;
}>
    & TOptionsNavigate;

export type TOptionsNavigate = Readonly<{
    isReplaced?: boolean;
    isOpenInNewTab?: boolean;
}>;

export type TRouteTo = (
    shortPath: TPaths,
    params?: TCustomProps,
    options?: TOptionsRouteTo,
) => void;

export type TZoneAnonymousName = 'sm' | 'md' | 'lg' | 'xlg';

export type TZoneAnonymousDict<T = number> = Readonly<Record<TZoneAnonymousName, T>>;

export type TVerificationType = 'online' | 'document';

export type TAccount = TProfileUserData & Readonly<{
    id: number;
    networkMembers: TNamedIdList;
    inviteCode?: string;
}>;

export type TStateOperation = Readonly<{
    message?: string | null;
    data?: unknown;
    status: TAsyncStatus;
}>;

export type TStateOperations = Readonly<Record<string, TStateOperation | null>>;

export type TTempAccount = Readonly<{
    accessToken: string;
    account: TAccount;
}>;

export type TStateAuth = Readonly<{
    loginState: TAsyncStatus;
    accountState: TAsyncStatus;
    accessToken: string;
    expiresIn: number;
    tokenType: string;
    account: TAccount | null;
    accountOriginal?: TTempAccount | null; // exists when an account is emulated
}>;

export type TStateAuth0 = Readonly<{
    id: number | undefined;
    user: User | undefined;
    apiToken: string | undefined;
    idTokenClaims: IdToken | undefined;
}>;

export type TAuthHeaders = Readonly<{
    Authorization: string;
}>;

export type TStateApp = Readonly<{
    theme: string;
    isRestored: boolean;
    isLogouting: boolean;
    isLoadingDashboard: boolean;
}>
    & TGlobalModals;

export type TStateFeature = Readonly<{
    viewFeatures: TViewFeaturesExtended;
}>;

export type TStateLicenses = Readonly<{
    pendingLicenses: TManageStateLicenseList;
    rejectedLicenses: TManageStateLicenseList;
    approvedLicenses: TManageStateLicenseList;
}>;

export type TStateStateLicenses = Readonly<{
    isLoading: boolean;
    licenses: TStateLicenses;
}>;

export type TState = Readonly<{
    auth: TStateAuth;
    operations: TStateOperations;
    app: TStateApp;
    specialties: TStateSpecialties;
    designations: TStateDesignations;
    languages: TStateLanguages;
    usStates: TStateUsStates;
    sourceOfBusiness: TStateSourceOfBusiness;
    stateLicenses: TStateStateLicenses;
    dashboard: TStateDashboard;
}>;

/*
export type TState = Readonly<{
    auth: TStateAuth;
    app: TStateApp;
    operations: TStateOperations;
    counters: TStateCounters;
    specialties: TStateSpecialties;
    designations: TStateDesignations;
    languages: TStateLanguages;
    usStates: TStateUsStates;
    referralsUserOutboundDict: TStateReferralsUserOutboundDict;
    referralsUserInboundDict: TStateReferralsUserInboundDict;
    dashboard: TStateDashboard;
    viewReferral: TStateViewReferral;
    viewReferralInbound: TStateViewReferralInbound;
    viewReferralInboundAccept: TStateViewReferralInboundAccept;
    sourceOfBusiness: TStateSourceOfBusiness;
    searchMembers: TStateSearchMembers;
    knowledgeBase: TStateKnowledgeBase;
    features: TStateFeature;
    stateLicenses: TStateStateLicenses;
}>;
*/

export type TSelector<TOut = unknown> = (state: TState) => TOut;

export type TStatePartial = Partial<TState>;

export type TDelayed = Readonly<{
    isDelayed?: boolean;
}>;

export type TFetchMockFn = (
    url: string,
    init: RequestInit,
    isAnon: boolean,
) => unknown;

export type TMock = Readonly<{
    match: string | RegExp;
    result: TFetchMockFn | unknown;
}>;

export type TMocks = Readonly<Record<string, TMock>>;

export type TZones<T extends string = string> = Readonly<{
    [key in T]: number;
}>;

export type TAvatarInfo = Readonly<{
    firstName: string;
    lastName: string;
    avatarUrl: string;
}>;

export type TModalInputOnChange = (value: string) => void;

export type TApiResultToken = Readonly<{
    Autorenewal: boolean;
    BrokerageName: string;
    Email: string;
    FirstName: string;
    Id: number;
    LastName: string;
    NetworkMembers: string;
    PrimaryImage: string;
    ProfileStep: number;
    Role: TRole;
    OfficeAddress1: string;
    OfficeAddress2: string;
    OfficeCity: string;
    OfficeStateId: number;
    OfficeZip: string;
    OfficePhoneNumber: string;
    showRenewal?: boolean | null;
    // eslint-disable-next-line camelcase
    access_token: string;
    // eslint-disable-next-line camelcase
    expires_in: number;
    // eslint-disable-next-line camelcase
    token_type: string;
    status: UserStatusEnum;
    upgradeAmount?: number;
}>;

export type TOrderedDesc = Readonly<{
    order: number,
    description: string,
}>;

export type TOrderedDescList = ReadonlyArray<TOrderedDesc>;

export type TOrderedDescEditable = TOrderedDesc & TEditableWithKey;

export type TOrderedDescListEditable = ReadonlyArray<TOrderedDescEditable>;

export type TBrend<T> = Readonly<{ // use for specific id
    brend?: T;
}>;

export type TLabeledId = Readonly<{
    id: number,
    label: string,
}>;

export type TLabeledIdList = ReadonlyArray<TLabeledId>;

export type TNamedId = Readonly<{
    id: number,
    name: string,
}>;

export type TNamedIdList = ReadonlyArray<TNamedId>;

export type TNamedIdUppercase = Readonly<{
    Id: number,
    Name: string,
}>;

export type TNamedIdUppercaseList = ReadonlyArray<TNamedIdUppercase>;

export type TLabeledName = Readonly<{
    label: string,
    name: string,
}>;

export type TLabeledNameList = ReadonlyArray<TLabeledName>;

export enum SpecialtyType {
    residential = 0,
    commercial = 1,
}

export type TSpecialty = TNamedId & Readonly<{
    type: SpecialtyType,
}>;

export type TSpecialtyList = ReadonlyArray<TSpecialty>;

export type TUserSpecialty = Readonly<{
    specialtyId: number,
    description?: string | null,
    order: number,
    name: string;
}>;

export type TUserSpecialtyList = ReadonlyArray<TUserSpecialty>;

export type TSocialMedia = TNamedId & Readonly<{
    url: string | null,
    default: string | null;
    validator: string | null;
}>;

export type TSocialMediaList = ReadonlyArray<TSocialMedia>;

export enum UserDesignationStatus {
    pending = 0,
    verified = 1,
    rejected = 2,
}

export type TUserDesignationStatusList = ReadonlyArray<UserDesignationStatus>;

export type TUserDesignation = Readonly<{
    designationId: number;
    status: UserDesignationStatus;
}>;

export type TUserDesignationList = ReadonlyArray<TUserDesignation>;

export type TDesignation = Readonly<{
    id: number;
    abbreviation: string;
    description: string;
}>;

export type TDesignationList = ReadonlyArray<TDesignation>;

export type TLanguage = TNamedId;

export type TLanguageList = ReadonlyArray<TLanguage>;

export type TUsState = TNamedId & Readonly<{
    abbreviation: string,
}>;

export type TUsStateList = ReadonlyArray<TUsState>;

export enum TeamMemberRole {
    sales = 1,
    support = 2,
    other = 3,
}

export type TTeamMember = Readonly<{
    firstName: string;
    lastName: string;
    role: TeamMemberRole;
    order: number;
    profileUrl?: string | null;
}>;

export type TTeamMemberList = ReadonlyArray<TTeamMember>;

export type TEditable = Readonly<{
    isEditing?: boolean;
    isDeleted?: boolean;
}>;

export type TEditableWithKey =
    TEditable &
    Readonly<{
        key: number;
    }>;

export type TEditableWithLabeledKey =
    TEditableWithKey &
    Readonly<{
        label: string;
    }>;

export type TEditableWithLabeledKeyList = ReadonlyArray<TEditableWithLabeledKey>;

export type TTeamMemberExtended = TEditableWithKey & TTeamMember;

export type TTeamMemberExtendedList = ReadonlyArray<TTeamMemberExtended>;

export type TBusinessSource = Readonly<{
    primarySourceOfBusinessId: number;
    order: number;
    description?: string | null;
    name: string;
}>;

export type TBusinessSourceList = ReadonlyArray<TBusinessSource>;

export type TCounty = Readonly<{
    name: string;
    stateId: number;
}>;

export type TCountyList = ReadonlyArray<TCounty>;

export type TCity = Readonly<{
    name: string;
    county: string;
    stateId: number;
}>;

export type TCityList = ReadonlyArray<TCity>;

export type TZipCode = Readonly<{
    zip: string;
    city: string;
    county: string;
    stateId: number;
}>;

export type TZipCodeList = ReadonlyArray<TZipCode>;

export enum LicenseStatus {
    none = 0,
    verified = 1,
    rejected = 2
}

export type TLicense = Readonly<{
    id: number;
    stateLicensed: TUsState,
    licenseNumber: string;
    yearLicensed: number;
    status: LicenseStatus;
}>;

export type TLicenseList = ReadonlyArray<TLicense>;

export type TLicensePayload = Readonly<{
    id?: number;
    status?: LicenseStatus;
}> & Omit<TLicense, 'id' | 'status'>;

export type TSourceOfBusiness = TNamedId;

export type TSourceOfBusinessList = ReadonlyArray<TSourceOfBusiness>;

export type TSearchAgent = Readonly<{
    agentQualityScore: number;
    brokerageName: string;
    firstName: string;
    id: number;
    lastName: string;
    leaderName: string | null;
    officeCity: string;
    officeStateId: number;
    primaryImage: string | null;
    isFree: boolean;
}>;

export type TSearchAgentList = ReadonlyArray<TSearchAgent>;

export type TProfilePrimary = Readonly<{
    firstName: string;
    lastName: string;
    email: string;
    cellPhoneNumber: string | null;
    firstYearOfService: number | null;
    officePhoneNumber: string;
    officePhoneExtension: string | null;
    officeAddress1: string | null;
    officeAddress2: string | null;
    officeCity: string;
    officeStateId: number | null;
    officeZip: string | null;
}>;

export type TProfileBusinessOverview = Readonly<{
    brokerageName: string | null;
    missionStatement: string | null;
    professionalTitle: string | null;
    slogan: string | null;
    teamName: string | null;
    teamMembers: TTeamMemberList,
    expansionNetwork: boolean;
    expansionNetworkName: string | null;
    expansionNetworkSubordinate: boolean;
}>;

export type TProfileBackground = Readonly<{
    areasOfSpecializationCurrentlyTaught: string | null;
    communityEnvolvement: string | null;
    educationalEventsAttendent: string | null;
    general: string | null;
    industryInvolvement: string | null;
    industryInvolvements: TOrderedDescList;
    communityInvolvements: TOrderedDescList;
    educationalEvents: TOrderedDescList;
    areasOfSpecialization: TOrderedDescList;
}>;

export type TProfileLogos = Readonly<{
    primaryLogo: string | null;
    brokerLogo: string | null;
    teamLogo: string | null;
}>;

export type TProfileLicenses = Readonly<{
    licenses: TLicenseList;
}>;

export type TProfileWebsites = Readonly<{
    blogSite: string | null;
    geographicFarmSite1: string | null;
    geographicFarmSite2: string | null;
    miscSite: string | null;
    primaryWebsite: string | null;
    sellerSite: string | null;
    valuationSite: string | null;
}>;

export type TProfileSocialMedia = Readonly<{
    media: TSocialMediaList;
}>;

export type TProfileSpecialties = Readonly<{
    specialties: TSpecialtyList;
}>;

export type TProfileLocationSpecialties = Readonly<{
    states: TNumberList;
    counties: TCountyList;
    cities: TCityList;
    zipCodes: TZipCodeList;
}>;

export type TProfileUserSpecialties = {
    specialties: TUserSpecialtyList;
};

export type TProfileDesignations = {
    designations: TDesignationList;
};

export type TProfileUserDesignations = {
    designations: TUserDesignationList;
};

export type TProfileLanguages = {
    languages: TNamedIdList;
};

export type TProfileUserLanguages = {
    languageIds: TNumberList;
};

export type TProfileSourcesOfBusiness = {
    sources: TNamedIdList;
};

export type TProfileUserSourcesOfBusiness = {
    sources: TBusinessSourceList;
};

export type TProfileStates = {
    states: TUsStateList;
};

export type TLocationItem = Readonly<{
    locationId?: number,
    city: string | null;
    county: string;
    stateAbbreviation: string | null;
    stateId: number;
    type: LocationType;
    zip: string | null;
}>;

export type TLocationItemList = ReadonlyArray<TLocationItem>;

export type TRatingDict = Readonly<{
    rate1: number;
    rate2: number;
    rate3: number;
    rate4: number;
    rate5: number;
}>;

export type TStateProfile = Readonly<{
    specialties: TProfileSpecialties;
    designations: TProfileDesignations;
    languages: TProfileLanguages;
    sourcesOfBusiness: TStateSourceOfBusiness;
    states: TProfileStates;
    inviteCode: string;
    publicProfile: string;
}> & TProfileStepData;

export type TProfileStepData = Readonly<{
    primary: TProfilePrimary;
    businessOverview: TProfileBusinessOverview;
    background: TProfileBackground;
    logos: TProfileLogos;
    licenses: TProfileLicenses;
    websites: TProfileWebsites;
    socialMedia: TProfileSocialMedia;
    userSpecialties: TProfileUserSpecialties;
    userDesignations: TProfileUserDesignations;
    userLanguages: TProfileUserLanguages;
    userSourcesOfBusiness: TProfileUserSourcesOfBusiness;
    locationSpecialties: TProfileLocationSpecialties;
}>;

export type TStateCounters = Readonly<{
    outboundUnmatched: number;
    outboundInterviewing: number;
    outboundMatched: number;
    outboundPending: number;
    outboundWorking: number;
    outboundInEscrow: number;
    outboundClosed: number;
    outboundLongTerm: number;
    outboundCanceled: number;
    inboundCandidate: number;
    inboundWorking: number;
    inboundInEscrow: number;
    inboundClosed: number;
    inboundDeclined: number;
    inboundProfileViewed: number;
    inboundNotAccepted: number;
    inboundCanceled: number;
}>;

export type TSpecialtyEntities = Readonly<Record<string, TSpecialty>>;


export type TStateSpecialties = Readonly<{
    entities: TSpecialtyEntities;
}>;

export type TDesignationEntities = Readonly<Record<string, TDesignation>>;

export type TStateDesignations = Readonly<{
    entities: TDesignationEntities;
}>;

export type TLanguagesEntities = Readonly<Record<string, TLanguage>>;

export type TStateLanguages = Readonly<{
    entities: TLanguagesEntities;
}>;

export type TStateBrokerages = Readonly<{
    brokerages: TStringList;
}>;

export type TUsStateEntities = Readonly<Record<string, TUsState>>;

export type TStateUsStates = Readonly<{
    entities: TUsStateEntities;
}>;

export type TStateAutocompleteMember = Readonly<{
    val: string;
    leaderId: number;
    leader: string | null;
}>;

export type TStateAutocompleteMemberList = ReadonlyArray<TStateAutocompleteMember>;

export type TAutocompleteMemberResult = Readonly<{
    names: TStateAutocompleteMemberList;
    search: string;
}>;

export type TAutocompleteBrokerageResult = Readonly<{
    brokerages: TStringList;
    search: string;
}>;

export type TStateAutocompleteLocationData = Readonly<{
    city: string | null;
    county: string;
    stateAbbreviation: string | null;
    stateId: number;
    type: number;
    zip: string | null;
}>;

export type TStateAutocompleteLocationList = ReadonlyArray<TStateAutocompleteLocationData>;

export type TStateSearchAgent = Readonly<{
    agents: TSearchAgentList;
    count: number;
}>;

export type TFoundUserModel = Readonly<{
    id: number;
    primaryImage: string;
    firstName: string;
    lastName: string;
    area: string;
    email: string;
    firstYearOfService?: number;
    phone: string;
    isPartial: boolean;
}>;

export type TFoundUserModelList = ReadonlyArray<TFoundUserModel>;

export type TStateSearchMembersAll = Readonly<{
    emailSearchResult: TFoundUserModel | null;
    nameAndPhoneSearchResult: TFoundUserModelList | null;
    result: number;
}>;

export type TStateInviteAgent = Readonly<{
    invitationId: number;
    agentId?: number;
    agentFirstName?: string;
}>;

export type TUserData = Readonly<{
    clientRatingCount: number;
    email: string | null;
    lat?: number | null;
    lng?: number | null;
    qualityScore: number;
    isFree?: boolean | null;
}> & TSelectedUserData;

export type TUserDataList = ReadonlyArray<TUserData>;

export type TSelectedUserData = Readonly<{
    agentRating: number;
    agentRatingCount: number;
    brokerageName: string;
    buyerRating: number;
    buyerRatingCount: number;
    dateJoined: string;
    cellPhoneNumber: string | null;
    firstName: string;
    firstYearOfService: number | null;
    id: number;
    lastName: string;
    lastYearSales: number;
    lastYearTransactions: number;
    officePhoneNumber: string | null;
    primaryImage: string | null;
    salesYear: number;
    sellerRating: number;
    sellerRatingCount: number;
    servedCities: TLocationItemList;
    servedCounties: TLocationItemList;
    servedZipCodes: TLocationItemList;
}>;

export type TActionUserData = Readonly<{
    firstName: string;
    lastName: string;
    cellPhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    clientFirstName?: string | null;
    clientLastName?: string | null;
}>;

export type TActionUserExtendedData = Readonly<{
    referralId: number;
    agentId: number | null;
}> & TActionUserData;

export type TSelectedUserDataList = ReadonlyArray<TSelectedUserData>;


export type TStateUsers = Readonly<{
    agents: TUserDataList;
    count: number;
}>;

export type TAgentSpecialty = Readonly<{
    description: string | null;
    name: string;
    order: number;
    specialtyId?: number | null;
}>;

export type TAgentSpecialtyList = ReadonlyArray<TAgentSpecialty>;

export type TAgentTeamMember = Readonly<{
    profileUrl?: string | null;
    role: string;
}> & TName;

export type TAgentTeamMemberList = ReadonlyArray<TAgentTeamMember>;

export type TAgentProfileSocialMedia = Readonly<{
    name: string;
    type: number;
    url: string;
}>;

export type TAgentProfileSocialMediaList = ReadonlyArray<TAgentProfileSocialMedia>;

export type TAgentProfileData = Readonly<{
    areasOfSpecializationCurrentlyTaught: TStringList;
    blogSite: string | null;
    communityInvolvement: TStringList;
    designations: TStringList;
    educationalEventsAttended: TStringList;
    email: string | null;
    general: string | null;
    geographicFarmSite1: string | null;
    geographicFarmSite2: string | null;
    industryInvolvement: TStringList;
    languages: TStringList;
    miscSite: string | null;
    missionStatement: string | null;
    officeAddress: string | null;
    officeCity: string | null;
    officeState: string | null;
    officeZip: string | null;
    primaryWebsite: string | null;
    professionalTitle: string | null;
    qualityScore: number;
    ratings: TUserRatingList;
    sellerSite: string | null;
    slogan: string | null;
    socialMedia: TAgentProfileSocialMediaList;
    sourcesOfBusiness: TBusinessSourceList;
    specialties: TAgentSpecialtyList;
    teamMembers: TAgentTeamMemberList;
    teamName: string | null;
    totalRatingsCount: number;
    valuationSite: string | null;
}> & TSelectedUserData;

export type TStateReferralNew = Readonly<{
    agent: TSearchAgent | null;
    id: number;
    inviteCode: string;
    sourcesOfBusiness: TProfileSourcesOfBusiness;
    states: TProfileStates;
    designations: TProfileDesignations;
    specialties: TProfileSpecialties;
    languages: TProfileLanguages;
    userName: string;
}>;

export type TNoteData = Readonly<{
    date: Date;
    message: string;
    font?: string | null;
}>;

export type TNoteDataList = ReadonlyArray<TNoteData>;

export type TNoteDataEntities = Readonly<Record<number, TNoteDataList>>;

export type TSearchAgentIndices = Readonly<{
    id: number,
    memberName: string | null,
}>;

export type TSearchAgentIndicesList = ReadonlyArray<TSearchAgentIndices>;

export type TSendReferralToMemberData = Readonly<{
    agentMessage?: string | null;
    agentId?: number | null;
    agentName?: string | null;
}>;


export enum ReferralType {
    buy = 1,
    sell = 2,
    both = 3,
}

export enum ReferralTypeStr {
    buy = "Buyer",
    sell = "Seller",
    both = "Buyer/Seller",
}

export enum ReferralMissingInfo {
    referralSource = 0,
    timeSpoken = 1,
    clientInfo = 2,
    referralType = 3,
    referralDetails = 4,
    agent = 5,
    agreement = 6,
}


export enum ReferralPropertyType {
    singleFamily = 1,
    condominium = 2,
    townhome = 3,
    multiFamily = 4,
    farmRanch = 5,
    lotsLand = 6,
}

export enum ReferralPropertyTypeStr {
    singleFamily = "Single Family",
    condominium = "Condominium",
    townhome = "Townhome",
    multiFamily = "Multi-family",
    farmRanch = "Farm/Ranch",
    lotsLand = "Lots/Land",
}

export type TReferralPropertyTypeList = ReadonlyArray<ReferralPropertyType>;

export type TReferralMissingInfoList = ReadonlyArray<ReferralMissingInfo>;

export enum ReferralOwner {
    user = 0, // referringAgent (in original App)
    agent = 1, // referredAgent (in original App)
}

export type TUserReferralModelBase = Readonly<{
    id: number;
    clientFirstName: string | null;
    clientLastName: string | null;
    clientPreApproved: boolean | null;
    type: ReferralType | null;
    status: ReferralStatus | null;
    addedDate: string;
    buyContractClosingDate: string | null;
    sellContractClosingDate: string | null;
    buyPrice: number | null;
    buyTimeFrame: number | null;
    buyLocations: string | null;
    sellPrice: number | null;
    sellTimeFrame: number | null;
    sellCity: string | null;
    sellState: number | null;
    feeReceiveDate: string | null;
    fee: number | null;
    transactionClosedDate: string | null;
    owner: ReferralOwner;
    missingInfo: TReferralMissingInfoList;
    declinedAgentFirstName: string | null;
    declinedAgentLastName: string | null;
}>;

export type TUserReferralModelInbound = Readonly<{
    clientPhone: string;
    clientEmail: string;
    userId: number | null;
    userFirstName: string;
    userLastName: string;
    userCellPhone: string;
    userOfficePhone: string;
    userEmail: string;
    userPrimaryImage: string;
    clientRatingReceived: boolean | null;
    clientRatingId: number | null;
    clientRatingSentCount: number | null;
    invitationId: number;
}> & TUserReferralModelBase;

export type TUserReferralModelInboundList = ReadonlyArray<TUserReferralModelInbound>;


export type TUserReferralModelOutbound = Readonly<{
    agentId: number | null;
    agentFirstName: string | null;
    agentLastName: string | null;
    agentOfficePhone: string | null;
    agentCellPhone: string | null;
    invitedAgentFirstName: string | null;
    invitedAgentLastName: string | null;
    paymentSentDate: string | null;
    invitationId: number | null;
}> & TUserReferralModelBase;

export type TUserReferralModelOutboundList = ReadonlyArray<TUserReferralModelOutbound>;

export type TStateReferralsUserOutbound = Readonly<{
    referrals: TUserReferralModelOutboundList;
    count: number;
}>;

export type TStateReferralsUserInbound = Readonly<{
    referrals: TUserReferralModelInboundList;
    count: number;
}>;

export type TStateReferralsUserOutboundDict = Readonly<{
    [key in TOutboundTableName]: TStateReferralsUserOutbound;
}>;

export type TStateReferralsUserInboundDict = Readonly<{
    [key in TInboundTableName]: TStateReferralsUserInbound;
}>;


export enum SortOrder {
    asc = 0,
    desc = 1,
}

export enum ReferralFilterSort {
    addedDate = 0,
    client = 1,
    type = 2,
    agent = 3,
    status = 4,
    user = 5,
}

// 'closed' is a table 'complete'
export type TOutboundTableName = 'active' | 'closed' | 'invited' | 'long-term' | 'pending';

export type TInboundTableName = 'active' | 'long-term' | 'closed' | 'pending';

export enum CommunicationAction {
    sendEmail = 0,
    logPhone = 1,
    addNote = 2,
}

export type TStateReferralCall = Readonly<{
    calleeId?: number | null;
    date?: string | null;
    notes: string;
    referralId: number;
}>;

export type TStateReferralCallList = ReadonlyArray<TStateReferralCall>;

export enum ReferralAgentSide {
    user = 0, // referringAgent (in original App)
    agent = 1, // referredAgent (in original App)
}

export type TStateReferralEmail = Readonly<{
    date: string | null;
    message: string;
    recipientId: number | null;
    referralId: number;
    senderId: number | null;
    subject: string;
}>;

export type TStateReferralEmailList = ReadonlyArray<TStateReferralEmail>;

export type TStateReferralNote = Readonly<{
    agentId: number;
    date: string | null;
    note: string;
    referralId: number;
}>;

export type TStateReferralNoteList = ReadonlyArray<TStateReferralNote>;

export type TVerticleAlign = 'bottom' | 'middle' | 'top' | 'unset';

export type TTextAlign = 'center' | 'left' | 'right' | 'unset';

export type TPaginationData = Readonly<{
    currentPage: number;
    panelSize: number;
}>;

export enum DashboardVerifyStatus {
    none = 0,
    submitted = 1,
    verified = 2
}

export enum ReferralNotificationType {
    selectedAsAgent = 0,
    canceledInvitation = 1,
    referralAccepted = 2,
    referralDeclined = 3,
    updateRequested = 4,
    updatePosted = 5,
    signedAgreementUploaded = 6,
    signedAgreementConfirmed = 7,
    signedAgreementDeclined = 8,
    signedAgreementRequested = 9,
    contractClosed = 10, // dealClosed in original app
    leftFeedback = 11,
    referralReturned = 12,
    activeClient = 13,
    underContract = 14,
    feeReceived = 15,
    selectedAsReferringAgent = 16,
    outboundReferralAccepted = 17, // inboundReferralAccepted in original app. why?
    outboundReferralDeclined = 18, // inboundReferralDeclined in original app. why?
}

export type TReferralNotification = Readonly<{
    type: ReferralNotificationType;
    message: string | null;
    date: string;
    agentFirstName: string;
    agentLastName: string;
    agentPrimaryImage: string | null;
    clientFirstName: string | null;
    clientLastName: string | null;
    referralId: number | null;
    actionDate: string | null;
    status: ReferralStatus | null;
    owner: ReferralOwner | null;
    invitationId: number | null;
}>;

export type TReferralNotificationList = ReadonlyArray<TReferralNotification>;

export type TStateDashboard = Readonly<{
    profileCompleted: boolean;
    transactionsUploaded: boolean;
    referralsAdded: boolean;
    photoUploaded: boolean;
    hasVerifiedLisense: boolean;
    licensesVerified: DashboardVerifyStatus;
    designationsVerified: DashboardVerifyStatus;
    transactionsVerified: DashboardVerifyStatus;
    referralsVerified: DashboardVerifyStatus;
    inboundNotifications: TReferralNotificationList;
    inboundNotificationCount: number;
    outboundNotifications: TReferralNotificationList;
    outboundNotificationCount: number;
    referralCounters: TStateCounters;
    pendingReferralInvitations: number;
}>;

export type TStateProfileEmulated = Readonly<{
    // eslint-disable-next-line camelcase
    access_token: string;
    downgradeFromFullMember: boolean;
    id: number;
    renewalAmount: number;
    showRenewal: boolean | null;
}> & TProfileUserData;

export type TStataNotifications = Readonly<{
    count: number;
    notifications: TReferralNotificationList;
}>;


export type TRouter = (shortPath: TPaths, params?: TCustomProps) => void;


// TODO mb rename index.declinereferral and publicempty.declinereferral
export type TPaths = 'newreferral' | 'referral' | 'referrals' | 'viewreferral' |
    'acceptreferral' | 'declinereferral' | 'publicempty.declinereferral' |
    'inboundreferrals' | 'inboundreferral' | 'verifyreferrals' |
    'new-agent-inbound-referral' | 'new-agent-inbound-referral-admin' |
    'agent-inbound-referral' | 'agent-inbound-referral-admin' | 'accept-outbound-referral' |
    'invite' | 'dummyprofile' | 'dummytransactions' | 'pastreferrals' | 'newpastreferral' |
    'dashboard' | 'profile' | 'login' | TShortPathProfile | 'transactions' |
    'pastreferral' | 'ratings' | 'accountsettings' | 'searchmembers' | 'knowledgeBase' |
    'knowledgeBaseSearchArticles' | 'knowledgeBaseCategory' | 'knowledgeBaseArticle' |
    'features' | 'statelicences' | 'managefeatures' | 'manage-users' | 'designations' |
    'verifytransactions' | 'verifypastreferrals' | 'promotions' | 'newpromotion' |
    'promotion' | 'register' | 'upgrade' | 'confirmemail' | 'forgot-password' |
    'preregister' | 'referralinvite' | 'activate' | 'public.acceptreferral' |
    'reset-password' | 'sharedev' | 'rate' | 'publicempty.profile' | 'member-profile' |
    'referrals-with-member' | 'apisettings';
    
export type TUrlProfile = 'primaryandoffice' | 'businessoverview' |
    'background' | 'photoandlogos' | 'licenses' |
    'websites' | 'socialmedia' | 'specialities' | // exac speciali̲ties (like in original app)
    'designations' | 'languages' | 'business-source' | 'locations';

export type TShortPathProfile = `profile.${TUrlProfile}`;

export enum ReferralNotificationDirection {
    outbound = 0,
    inbound = 1,
}

export type TPosition = 'right' | 'left' | 'top' | 'bottom';

export enum ReferralSource {
    rawLead = 1,
    personalReferral = 2,
    businessReferral = 3,
}

export enum ReferralSourceStr {
    rawLead = "Raw Lead",
    personalReferral = "Personal Referral",
    businessReferral = "Business Referral",
}

export enum ReferralTimeFrame {
    less30 = 1,
    from30To90 = 2,
    more90 = 3,
    unknown = 4,
}

export enum ReferralTimeFrameStr {
    less30 = "< 30 days",
    from30To90 = "30 to 90 days",
    more90 = "> 90 days",
    unknown = "unknown",
    null = "unknown"
}

export const DIRECTION_OUTBOUND_STR: string = "outbound";
export const DIRECTION_INBOUND_STR: string = "inbound";


export enum ReferralStatus {
    incomplete = 0,
    sentToAgent = 1,
    sentInvitation = 2,
    accepted = 3,
    declined = 4,
    signedAgreementUploaded = 5,
    signedAgreementConfirmed = 6,
    signedAgreementDeclined = 7,
    clientNotContacted = 8,
    clientContacted = 9,
    clientContinueContacting = 10,
    appointmentSet = 11,
    lookingHouses = 12,
    underContract = 13,
    complete = 14,
    returned = 15,
    longTermOpportunity = 16,
    returnedNotIntendToBuy = 17,
    hiredAnotherAgent = 18,
    activeClient = 19,
    underContractClosingDate = 20,
    contractClosed = 21, // same as TransactionClosed in backend
    sentToReferringAgent = 22,
    invitedReferringAgent = 23,
    canceled = 24,

    agreementSent = 40,
    clientAgreementSigned = 41,
    referralCheckIssued = 42,
    referralCheckReceived = 43,
}


export const statusesInPending = [
    ReferralStatus.sentToAgent,
    ReferralStatus.sentInvitation,
    ReferralStatus.agreementSent,
];

export const statusesInOpportunity = [
    // ReferralStatus.incomplete,
    ReferralStatus.sentToAgent,
    ReferralStatus.sentInvitation,
    // ReferralStatus.accepted,
    ReferralStatus.agreementSent,
    ReferralStatus.signedAgreementUploaded,
];
export const statusesInActive = [
    ReferralStatus.clientAgreementSigned,
    ReferralStatus.signedAgreementConfirmed,
    ReferralStatus.clientContacted,
    ReferralStatus.appointmentSet,
    ReferralStatus.lookingHouses,
];
export const statusesInUnderContract = [
    ReferralStatus.underContract,
    ReferralStatus.underContractClosingDate,
    ReferralStatus.contractClosed, // same as transactionclosed in backend
    ReferralStatus.referralCheckIssued,
];
export const statusesInClosed = [
    ReferralStatus.referralCheckReceived,
];
export const statusesInDeclined = [
    ReferralStatus.declined,
    ReferralStatus.signedAgreementDeclined,
    ReferralStatus.canceled,
    ReferralStatus.returned,
    ReferralStatus.returnedNotIntendToBuy,
    ReferralStatus.hiredAnotherAgent,
];

export enum ReferralStatusStr {
    incomplete = "Referral Incomplete",
    sentToAgent = "Referral to be accepted",
    sentInvitation = "Sent invitation to external agent",
    accepted = "Referral accepted",
    declined = "Referral declined",
    signedAgreementUploaded = "Signed Agreement",
    signedAgreementConfirmed = "Signed Agreement confirmed",
    signedAgreementDeclined = "Agreement declined",
    clientNotContacted = "Client not contacted",
    clientContacted = "Client contacted",
    clientContinueContacting = "Ongoing communication with client",
    appointmentSet = "Appointment set with client",
    lookingHouses = "Touring properties",
    underContract = "Under contract with client",
    complete = "Referral complete",
    returned = "Referral returned",
    longTermOpportunity = "Long Term referral",
    returnedNotIntendToBuy = "Referral returned - client not buying",
    hiredAnotherAgent = "Client hired another agent",
    activeClient = "Active with client",
    underContractClosingDate = "Client contract closing date set",
    contractClosed = "Client contract closed",
    sentToReferringAgent = "Sent to Referring Agent",
    invitedReferringAgent = "Invited Referring Agent",
    canceled = "Referral cancelled",

    agreementSent = "Agreement sent for signing",
    clientAgreementSigned = "Client Representation agreement signed",
    referralCheckIssued = "Referral Check Issued",
    referralCheckReceived = "Referral Check Received",
}

export enum UpdateReferralStatus {
    spoken = 1,
    notSpoken = 2,
    referralUnresponsive = 3,
    returnReferral = 4,
    setAppointment = 5,
    notSetAppointment = 6,
    notIntendToBuySell = 7,
    hiredAnotherPrior = 8,
    hiredAnotherAfter = 9,
    notIntendToBuyContinueContacting = 10,
    notIntendToBuyReturnReferral = 11,
    activeClient = 12,
    choosingAgent = 13,
    notYetHappenedAppointment = 14,
    returnReferralOther = 15,
    underContract = 16,
    showingHouses = 17,
    underContractClosingDate = 18,
    contractFell = 19,
    other = 20,
    contractClosed = 21,
    termsChanged = 22,
    contactedClient = 23,
    clientAgreementSigned = 24,
    referralCheckIssued = 25,
    referralCheckReceived = 26,
}

export type TReferralStatusList = ReadonlyArray<ReferralStatus>;
export type TUpdateReferralStatusList = ReadonlyArray<UpdateReferralStatus>;


export type TReferralAction = Readonly<{
    date: string
    type: ReferralAction;
    agentId: number | null;
    agentFirstName: string;
    agentLastName: string;
    invitedAgentFirstName: string;
    invitedAgentLastName: string;
    changedByFirstName: string | null;
    changedByLastName: string | null;
    changedByType: ChangedByTypeEnum;
    message: string;
    messageHtml?: string | null;
    owner: boolean;
    actionDate: string | null;
    price: number | null;
    address: string;
    address1: string;
    address2: string;
    city: string;
    zip: string;
    stateId: number;
    dateChanged: boolean | null;
    invitationId?: number | null;
}>;

export type TReferralActionList = ReadonlyArray<TReferralAction>;

export enum ReferralActionStr {
    referralCreated = "Referral created",
    agentSelected = "Agent invited",
    agentInvited = "Agent invited",
    referralAccepted = "Referral accepted",
    referralDeclined = "Referral declined",
    invitationCanceled = "Referral canceled",
    agentPhoned = "Phone call logged",
    agentEmailed = "Agent emailed",
    signedAgreementUploaded = "Referral agreement signed",
    signedAgreementConfirmed = "Referral agreement signature confirmed",
    signedAgreementDeclined = "Referral agreement signature declined",
    resentAgreementRequest = "Re-sent referral agreement signature request",
    sentStatusUpdateRequest = "Sent status update request",
    clientContacted = "Client contacted",
    appointmentScheduled = "Appointment scheduled",
    statusUpdateMessage = "Status update message",
    clientNotContacted = "Client not contacted",
    referralUnresponsive = "Referral unresponsive",
    referralReturned = "Referral returned",
    addedNote = "Message sent",
    movedToLongTerm = "Moved to long term",
    clientActive = "Client active",
    showingHouses = "Touring properties",
    underContract = "Property under contract",
    underContractClosingDate = "Property under contract",
    underContractNoClosingDate = "Property under contract without closing date",
    contractFell = "Touring properties (previous contract fell)",
    contractClosed = "Contract closed",
    termsChanged = "Terms changed",
    choosingAgent = "Client is still choosing agent",
    userStatusUpdateMessage = "User status update message",
    feeCheckReceived = "Fee check received",
    feeCheckNotReceived = "Fee check not received",
    feeStatusUpdateRequest = "Fee status update request",
    referringAgentSelected = "Referring agent selected",
    referringAgentInvited = "Referring agent invited",
    outboundReferralAccepted = "Outbound referral accepted",
    outboundReferralDeclined = "Outbound referral declined",
    agentEmailedReply = "Agent emailed reply",

    agreementSent = "Referral agreement sent",
    clientAgreementSigned = "Client representation agreement signed",
    referralCheckIssued = "Under Contract: Disbursement instructions includes Referral Fee",
    referralCheckReceived = "Referral check received",
    appointmentNotScheduled = "Appointment not scheduled",
}

export enum ReferralAction {
    referralCreated = 0, // created in original app
    agentSelected = 1,
    agentInvited = 2,
    referralAccepted = 3, // accepted in original app
    referralDeclined = 4, // declined in original app
    invitationCanceled = 5,
    agentPhoned = 6,
    agentEmailed = 7,
    signedAgreementUploaded = 8,
    signedAgreementConfirmed = 9,
    signedAgreementDeclined = 10,
    resentAgreementRequest = 11,
    sentStatusUpdateRequest = 12,
    clientContacted = 13,
    appointmentScheduled = 14,
    statusUpdateMessage = 15,
    clientNotContacted = 16,
    referralUnresponsive = 17,
    referralReturned = 18,
    addedNote = 19,
    movedToLongTerm = 20,
    clientActive = 21,
    showingHouses = 22,
    underContract = 23,
    underContractClosingDate = 24,
    underContractNoClosingDate = 25,
    contractFell = 26,
    contractClosed = 27,
    termsChanged = 28,
    choosingAgent = 29,
    userStatusUpdateMessage = 30,
    feeCheckReceived = 31,
    feeCheckNotReceived = 32,
    feeStatusUpdateRequest = 33, // feeStatusUpdateRequested in original app
    referringAgentSelected = 34,
    referringAgentInvited = 35,
    outboundReferralAccepted = 36, // inboundReferralAccepted in original app
    outboundReferralDeclined = 37, // inboundReferralDeclined in original app
    agentEmailedReply = 38, // may content html message

    agreementSent = 40,
    clientAgreementSigned = 41,
    referralCheckIssued = 42,
    referralCheckReceived = 43,
    appointmentNotScheduled = 44,
}

export type TViewReferralModelBase = Readonly<{
    id: number;
    
    agentId: number;
    agentFirstName: string | null;
    agentLastName: string | null;
    agentEmail: string | null;
    agentPrimaryImage: string | null;
    
    clientFirstName: string | null;
    clientLastName: string | null;
    clientEmail: string | null;
    clientPhone: string | null;
    clientPreapproved: boolean | null;
    source: ReferralSource | null;
    type: ReferralType | null;
    buyLocations: TStringList | null;
    buyLocationsObjs?: TLocationItemList | null;
    buyPropertyType: string | null;
    buyRangeMax: number | null;
    buyRangeMin: number | null;
    buyTimeFrame: ReferralTimeFrame | null;
    sellLocation: string | null;
    sellPropertyType: string | null;
    sellTargetPrice: number | null;
    sellTimeFrame: ReferralTimeFrame | null;
    fee: number;
    signedAgreement: string | null;
    feeReceiveDate: string | null;
    actions: TReferralActionList;
}>;

export type TViewReferralModel = Readonly<{
    agentBrokerageName: string;
    agentOfficePhone: string | null;
    agentCellPhone: string | null;
    agentEmail: string | null;
    agentPrimaryImage: string | null;
    status: ReferralStatus | null;

    owner: ReferralOwner | null;

    transactionClosedDate: string | null;

    buyPrice: number | null;
    sellPrice: number | null;
}> & TViewReferralModelBase;

export type TViewReferralInboundModel = Readonly<{
    userId: number;
    userFirstName: string | null;
    userLastName: string | null;
    userBrokerageName: string | null;
    userOfficePhone: string | null;
    userCellPhone: string | null;
    userEmail: string | null;
    userPrimaryImage: string | null;
    userPublicProfile: string | null;
    userOfficeAddress1: string | null;
    userOfficeAddress2: string | null;
    userOfficeCity: string | null;
    userOfficeStateId: number | null;
    userOfficeZip: string | null;

    agentBrokerageName: string | null;
    agentOfficePhone: string | null;
    agentCellPhone: string | null;
    agentPublicProfile: string | null;
    agentOfficeAddress1: string | null;
    agentOfficeAddress2: string | null;
    agentOfficeCity: string | null;
    agentOfficeStateId: number | null;
    agentOfficeZip: string | null;

    status: ReferralStatus;
    agreement: string | null;
    useGeneratedAgreement: boolean;

    buyClosingDate: string | null;
    buyPrice: number | null;
    buyAddress1: string | null;
    buyAddress2: string | null;
    buyCity: string | null;
    buyStateId: number | null;
    buyZip: string | null;
    buyNote: string | null;

    sellClosingDate: string | null;
    sellPrice: number | null;
    sellAddress1: string | null;
    sellAddress2: string | null;
    sellCity: string | null;
    sellStateId: number | null;
    sellZip: string | null;
    sellNote: string | null;

    owner: ReferralOwner;

    stepDueDate: string | null;
}> & TViewReferralModelBase;

export type TStateViewReferral = Readonly<{
    inviteCode: string | null;
    referral: TViewReferralModel;
    errorMessage?: never;
    status?: never;
    referralId?: never;
}>;

export type TStateViewReferralError = Readonly<{
    errorMessage: string;
    status?: ReferralInvitationStatus;
    referralId?: number;
    inviteCode?: never;
    referral?: never;
}>;

export type TStateViewReferralInbound = Readonly<{
    referral: TViewReferralInboundModel;
    states: TUsStateList;
    inviteCode: string | null;
    reviews: any[];
}>;

export enum ReferralInvitationStatus {
    sent = 0,
    canceled = 1,
    declined = 2,
    accepted = 3,
    otherAgent = 10,
}

export type TStateViewReferralInboundAccept = Readonly<{
    status: ReferralInvitationStatus | null;
    referralId?: number | null;
    userId?: number | null;
    message?: string;
    designatedAgentEmail?: string | null;
}> & TStateViewReferralInbound;

export enum FeeStatus {
    received = 1,
    notReceived = 2,
    other = 3,
}

export type TReferralTableFilter = Readonly<{
    statuses: TReferralStatusList;
    sortField: ReferralFilterSort;
    sortOrder: SortOrder;
    pageSize: number;
    pageNumber: number;
}>;

export type TReferralTableFilterOutboundDict = Readonly<{
    [key in TOutboundTableName]: TReferralTableFilter;
}>;

export type TReferralTableFilterInboundDict = Readonly<{
    [key in TInboundTableName]: TReferralTableFilter;
}>;

export type TStateReferralNewInbound = Readonly<{
    referralId: number;
    userId: number | null;
    userFirstName: string | null;
    userLastName: string | null;
}>;

export type TStateReferralExistInbound = Readonly<{
    referral: TAgentReferralInboundModel;
    states: TUsStateList;
    userId: number | null;
    userFirstName: string | null;
    userLastName: string | null;
}>;

export type TStateSourceOfBusiness = Readonly<{
    sources: TNamedIdList;
}>;

export type TStateSearchMembers = Readonly<{
    favoritesDict: TDict<boolean>;
}>;

export type TBooleanData = Readonly<{
    [key: string]: boolean | null | undefined;
}>;

export enum LocationType {
    City = 2,
    County = 1,
    Zip = 3,
}

export type TAgentInformationData = Readonly<{
    agentId?: number | null;
    agentFirstName?: string | null;
    agentLastName?: string | null;
    agentSelectDate?: string | null;
    agentName?: string | null;
    agentEmail?: string | null;
    agentPhone?: string | null;
    agentMessage?: string | null;
}>;

export type TClientInformationData = Readonly<{
    clientFirstName: string;
    clientLastName: string;
    clientPhone: string;
    clientEmail: string;
}>;

export type TModalAlertData = Readonly<{
    text?: string | null;
    label?: string | null;
}>;


export type TInvitedAgentInformationData = Readonly<{
    invitedAgentFirstName: string;
    invitedAgentLastName: string;
    invitedAgentPhone: string;
    invitedAgentEmail: string;
    agentMessage: string;
}>;

export type TSellFormData = Readonly<{
    sellAddress1?: string | null;
    sellAddress2?: string | null;
    sellCity?: string | null;
    sellCounty?: string | null;
    sellStateId?: number | null;
    sellZip?: string | null;
    sellPropertyType?: ReferralPropertyType | null;
    sellTargetPrice: number;
    sellTimeFrame?: ReferralTimeFrame | null;
    sellNote?: string | null;
}>;


export type TBuyFormData = Readonly<{
    buyLocationType: LocationType | null;
    buyLocations: TLocationItemList;
    buyPropertyTypes: TNumberList;
    buyRangeMin: string;
    buyRangeMax: string;
    buyTimeFrame?: ReferralTimeFrame | null;
    buyNote: string | null;
}>;

export type TReferralAgreementData = Readonly<{
    fee: number;
    agreement?: string | null;
    agreementFileName?: string | null;
    useGeneratedAgreement: boolean;
}>;

export type TAgentReferralInboundModel = Readonly<{
    id: number;
    type?: ReferralType | null;
    buyLocations: TLocationItemList;
    status: ReferralStatus;
    userId?: number | null;
}>
    & Partial<TSellFormData>
    & Omit<Partial<TBuyFormData>, 'buyLocations'>
    & Omit<TReferralAgreementData, 'useGeneratedAgreement'>
    & TPartialNull<TClientInformationData>
    & TPartialNull<TInvitedAgentInformationData>;

export type TKindViewReferral = 'outbound' | 'inbound' | 'inbound-accept';

export type TRegistrationAvailableData = Readonly<{
    available: boolean;
    discount: boolean;
    code: TRegisterDiscountCodeModel | null;
    expansionCode: TRegisterDiscountCodeModel | null;
}>;

export enum DiscountCodeType {
    amount = 0,
    percentage = 1,
}

export type TRegisterDiscountCodeModel = Readonly<{
    code: string | null;
    amount: number;
    type: DiscountCodeType;
    title: string | null;
    description: string | null;
    priceHeader: string | null;
    priceDescription: string | null;
    subItems: TStringList;
    length: number;
}>;

export type TPartialRegisterUserModel = Readonly<{
    firstName: string | null;
    lastName: string | null;
    brokerageName: string | null;
    email: string | null;
    phone: string | null;
    cellPhoneNumber?: string | null;
    invitedBy?: number | null;
}>;

export type TExpansionNetwork = TNamedId;

export type TExpansionNetworkList = ReadonlyArray<TExpansionNetwork>;

export type TSelectedItem = Readonly<{
    label: string;
    id: string;
    isHiddenButtonClose?: boolean | null;
}>;

export type TSelectedItemList = ReadonlyArray<TSelectedItem>;

export enum DiscountCodeCheckType {
    user = 0,
    code = 1,
}

export type TCheckCodeResponse = Readonly<{
    valid: boolean;
    type: DiscountCodeCheckType | null;
    code: TRegisterDiscountCodeModel | null;
    errorMessage: string | null;
}>;

export enum RegisterType {
    full = 0,
    free = 1,
}

export type TStepName = 'background' | 'business-overview' | 'designations' |
    'languages' | 'licenses' | 'location-specialties' | 'logos' | 'primary' |
    'sources-of-business' | 'specialties' | 'social-media' | 'websites';

export type TNameLogo = 'primary-logo' | 'broker-logo' | 'team-logo';

export type TResponseAddLogo = Readonly<{
    name: TNameLogo;
    image: string;
}>;

export type TAddLicenseResponse = Readonly<{
    license: TLicensePayload | TLicense;
    id: number;
}>;

export enum UserTransactionsStatus {
    pending = 0,
    verified = 1,
    rejected = 2,
}

export type TUserTransaction = Readonly<{
    id: number;
    name: string | null;
    email: string | null;
    officePhone: string | null;
    notes: string | null;
    documentId: string | null;
    status: UserTransactionsStatus;
    periodId: number;
    period: string;
    unitsSold: number | null;
    totalSales: number | null;
}>;

export type TUserTransactionList = ReadonlyArray<TUserTransaction>;

export type TResponseGetOpenedPerionds = Readonly<{
    transactions: TUserTransactionList;
}>;

export type TResponseSaveTransactions = Readonly<{
    documentId?: string;
    id?: number;
}>;

export enum PastReferralType
{
    buy = 0,
    sell = 1,
    both = 2,
}

export enum PastReferralStatus {
    initial = 0,
    submitted = 1,
    approved = 2,
    infoRequired = 3,
    rejected = 4,
    pendingRatingApproval = 5,
    completed = 6,
}

export type TPastReferralStatusList = ReadonlyArray<PastReferralStatus>;

export type TViewPastReferralRating = Readonly<{
    id: number;
    received: boolean;
    sentCount: number;
}>;

export type TViewPastReferral = Readonly<{
    id: number;
    agent: string;
    agentPhone: string;
    agentEmail: string;
    client: string;
    clientPhone: string;
    clientEmail: string;
    type: PastReferralType | null;
    createdDate: string;
    status: PastReferralStatus;
    verifiedDate: string | null;
    clientRating: TViewPastReferralRating;
    agentRating: TViewPastReferralRating;
}>;

export type TViewPastReferralList = ReadonlyArray<TViewPastReferral>;

export enum RatingRater {
    client = 0,
    agent = 1,
}

export type TRatingRequestPastReferralData = Readonly<{
    id: number;
    name: string;
    phone: string;
    email: string;
    verifiedDate: string | null;
    rater: RatingRater;
    sentCount: number;
    showEmails?: boolean | null;
}>;

export type TReferralTransactionModel = Readonly<{
    id?: number | null;
    date: string | null;
    amount: number | null;
    address: string | null;
    city: string | null;
    stateId: number | null;
    zip: string | null;
    settlementStatement: string | null;
    settlementStatementFileName: string | null;
}>;

export type TPastReferralModel = Readonly<{
    id?: number | null;
    clientFirstName: string | null;
    clientLastName: string | null;
    clientPhone: string | null;
    clientEmail: string | null;
    agentFirstName: string | null;
    agentLastName: string | null;
    agentPhone: string | null;
    agentEmail: string | null;
    type: PastReferralType | null;
    status: PastReferralStatus;
    buyTransaction: TReferralTransactionModel;
    sellTransaction: TReferralTransactionModel;
    referralAgreement: string | null;
    referralAgreementFileName: string | null;
}>;

export type TPastReferralSendModel = Readonly<{
    buyTransaction?: TReferralTransactionModel | null;
    sellTransaction?: TReferralTransactionModel | null;
}>
    & Omit<TPastReferralModel, 'buyTransaction' | 'sellTransaction'>;

export type TRequestPastReferralModel = Readonly<{
    status: PastReferralStatus;
}> & TPartialNull<Omit<TPastReferralModel, 'status'>>;

export type TResponseGetPastReferral = Readonly<{
    referral: TRequestPastReferralModel;
    states: TProfileStates;
}>;

export type TUserRating = Readonly<{
    address: string | null;
    feedback: string;
    rater: RatingRater;
    rating: number;
    ratingDate: string | null;
}>;

export type TUserRatingList = ReadonlyArray<TUserRating>;

export type TRatingData = Readonly<{
    totalCount: number;
    ratings: TUserRatingList;
}>;

export type TRatingDataWithCounts =
    TRatingData &
    Readonly<{
        agentQualityScore: number;
        buyerRating: number;
        buyerRatingCount: number;
        sellerRating: number;
        sellerRatingCount: number;
        agentRating: number;
        agentRatingCount: number;
    }>;


export enum UserStatisticEmailFrequency {
    disabled = 0,
    daily = 1,
    weekly = 2,
    monthly = 3,
}

export type TNotificationSettingsModel = Readonly<{
    notificationEmails: TStringList;
    notificationFrequency: UserStatisticEmailFrequency;
}>;

export type TNotificationSettingsModelOnlyEmails = Readonly<{
    notificationEmails: TStringList | null;
}>;

export type TExpansionNetworkMemberSettingsModel = Readonly<{
    id: number;
    firstName: string | null;
    lastName: string | null;
    brokerage: string | null;
    address: string | null;
    status: UserStatusEnum;
}>;

export type TExpansionNetworkMemberSettingsModelList = ReadonlyArray<TExpansionNetworkMemberSettingsModel>;

export type TAccountSettingsModel = Readonly<{
    inviteCode: string | null;
    publicProfile: string | null;
    notificationSettings: TNotificationSettingsModel;
    networkMembers: TExpansionNetworkMemberSettingsModelList;
}>;

export type TResponseGetSearchMembers = Readonly<{
    favoriteMembers: TNumberList;
    designations: TDesignationList;
    specialties: TSpecialtyList;
    languages: TLanguageList;
    states: TUsStateList;
}>;

export type TPrimaryLocationData = Readonly<{
    locationType: LocationType | null;
    selectedLocations: TLocationItemList;
}>;

export type TAgentQualityData = Readonly<{
    agentQuality: number;
    buyerRating: number;
    sellerRating: number;
    agentRating: number;
    notRatedInclude: boolean;
}>;

export type TYearsOfExperienceData = Readonly<{
    years: number | null;
}>;

export type TPreviousYearSalesData = Readonly<{
    saleSum: number | null;
}>;

export type TSpecialtiesData = Readonly<{
    residentialOptions: TSpecialtyList;
    commertialOptions: TSpecialtyList;
}>;

export type TDesignationsData = Readonly<{
    designations: TDesignationList;
}>;

export type TBrokerageData = Readonly<{
    selectedBrokerages: TBrokerageItemExtendedList;
}>;

export type TLanguageData = Readonly<{
    languages: TLanguageList;
}>;

export type TMemberItem = Readonly<{
    type: UserFilterSearchType;
}>
    & TStateAutocompleteMember;

export type TMemberItemList = ReadonlyArray<TMemberItem>;

export type TMemberItemExtended = Readonly<{
    key: string;
    label: string;
}>
    & TMemberItem;

export type TMemberItemExtendedList = ReadonlyArray<TMemberItemExtended>;

export type TBrokerageItemExtended = Readonly<{
    key: string;
    label: string;
}>
    & TSearchTextModel;

export type TBrokerageItemExtendedList = ReadonlyArray<TBrokerageItemExtended>;

export type TNameData = Readonly<{
    selectedMembers: TMemberItemExtendedList;
}>;

export type TCriteriaData = Readonly<{
    primaryLocationData: TPrimaryLocationData;
    agentQualityData: TAgentQualityData;
    yearsOfExperienceData: TYearsOfExperienceData;
    previousYearSalesData: TPreviousYearSalesData;
    specialtiesData: TSpecialtiesData;
    designationsData: TDesignationsData;
    brokerageData: TBrokerageData;
    languageData: TLanguageData;
    nameData: TNameData;
}>;

export enum ViewType {
    List,
    Map,
}

export type TSearchParameters = Readonly<{
    sortField: OrderByUserFilter;
    viewType: ViewType;
    pageSize: number;
}>;

export type TSearchCandidatesResultsData = Readonly<{
    searchParameters: TSearchParameters;
    paginationData: TPaginationData;
}>;

export type TSelectCandidatesData = Readonly<{
    criteriaData: TCriteriaData;
    searchCandidatesResultsData: TSearchCandidatesResultsData;
}>;

export type TReferralTypeData = Readonly<{
    type?: ReferralType | null;
    buyFormData: TBuyFormData;
    sellFormData: TSellFormData;
}>;

export type TValidationClientInformation = Readonly<{
    firstNamePassed: boolean;
    lastNamePassed: boolean;
    phonePassed: boolean;
    emailPassed: boolean;
}>;

export type TValidationReferralAgreement = Readonly<{
    customAgreementPassed: boolean;
}>;

export type TValidationBuyForm = Readonly<{
    buyLocationsPassed: boolean;
    buyPropertyTypesPassed: boolean;
    buyTimeFramePassed: boolean;
}>;

export type TValidationSellForm = Readonly<{
    sellAddressPassed: boolean;
    sellCityPassed: boolean;
    sellZipPassed: boolean;
    sellPropertyTypePassed: boolean;
    sellTimeFramePassed: boolean;
}>;

export type TValidationReferralType = Readonly<{
    typePassed: boolean;
    buyFormValidation: TValidationBuyForm;
    sellFormValidation: TValidationSellForm;
}>;

export type TCriteriaNames = 'agentQuality' | 'yearsOfExperience' | 'previousYearSales' |
    'specialties' | 'designations' | 'brokerage' | 'language' | 'name';

export type TAppliedCriteria = Readonly<Record<TCriteriaNames, boolean>>;

export type TAppliedCriteriaCb = (appliedCriteria: TAppliedCriteria) => void;

export type TChangeSelectedCandidateCb = (id: number, user?: TSelectedUserData | null) => void;

export type TClientInformationDataWithMessage = Readonly<{
    message: string;
}> & TClientInformationData;

export type TReferralSourceData = Readonly<{
    source: number | null;
    timeSpoken: number | null;
}>;

export enum UserLocationSpecialtyType {
    state = 0,
    county = 1,
    city = 2,
    zipCode = 3,
    neighborhood = 4,
}

export type TSearchServiceAreaModel = Readonly<{
    type: UserLocationSpecialtyType;
    county?: string | null;
    city?: string | null;
    stateId: number;
    zip?: string | null;
}>;

export type TSearchServiceAreaModelList = ReadonlyArray<TSearchServiceAreaModel>;

export type TSearchQualityScoreModel = Readonly<{
    rate1?: number | null;
    rate2?: number | null;
    rate3?: number | null;
    rate4?: number | null;
    includeNotRated?: boolean | null;
}>;

export enum OrderByUserFilter {
    name = 0,
    qualityScore = 1,
    sales = 2,
}

export enum UserFilterSearchType {
    equals = 0,
    starts = 1,
}

export type TSearchTextModel = Readonly<{
    val: string;
    type: UserFilterSearchType;
}>;

export type TSearchTextModelList = ReadonlyArray<TSearchTextModel>;

export enum UserFilterSearchMemberRelation {
    none = 0,
    favorites = 1,
    referred = 2,
    referring = 3,
}

export type TSearchUsersQueryModel = Readonly<{
    serviceAreas?: TSearchServiceAreaModelList | null;
    qualityScore?: TSearchQualityScoreModel | null;
    yearsOfExperience?: number | null;
    previousYearSales?: number | null;
    specialties?: TNumberList | null;
    designations?: TNumberList | null;
    languages?: TNumberList | null;
    page: number;
    pageSize: number;
    sortField: OrderByUserFilter;
    brokerages?: TSearchTextModelList | null;
    names?: TSearchTextModelList | null;
    userIds?: TNumberList | null;
    relation: UserFilterSearchMemberRelation;
    sendEmailOnMemberAbsence: boolean | null;
}>;

export type TInviteUserModel = Readonly<{
    name: string;
    image: string;
}>;

export type TInviteUserModelList = ReadonlyArray<TInviteUserModel>;

export type TResponseGetInvites = Readonly<{
    inviteCode: string;
    users: TInviteUserModelList;
    usedInvitations: number;
}>;

export enum KnowledgeBaseArticleStatus {
    draft = 0,
    published = 1,
}

export type TKnowledgeBaseArticleViewModel = Readonly<{
    id: number;
    order: number;
    name: string;
    status: KnowledgeBaseArticleStatus;
}>;

export type TKnowledgeBaseArticleViewModelList = ReadonlyArray<TKnowledgeBaseArticleViewModel>;

export type TKnowledgeBaseArticleViewModelEditable =
    TKnowledgeBaseArticleViewModel &
    Readonly<{
        isDeleted?: boolean;
    }>;

export type TKnowledgeBaseArticleViewModelEditableList =
    ReadonlyArray<TKnowledgeBaseArticleViewModelEditable>;

export type TKnowledgeBaseCategoryViewModel = Readonly<{
    id: number;
    name: string;
    order: number;
    articlesNumber: number;
    articles: TKnowledgeBaseArticleViewModelList;
}>;

export type TKnowledgeBaseCategoryViewModelList = ReadonlyArray<TKnowledgeBaseCategoryViewModel>;

export type TStateKnowledgeBase = Readonly<{
    categoryList: TKnowledgeBaseCategoryViewModelList;
}>;

export type TServiceAreaItem = Readonly<{
    city: string | null,
    county: string,
    stateId: number,
    type: number,
    zip?: string,
}>;

export type TServiceAreaItemList = ReadonlyArray<TServiceAreaItem>;

export type TQualityScore = Readonly<{
    rate1?: number,
    rate2?: number,
    rate3?: number,
    rate4?: number,
    includeNotRated?: boolean,
}>;

export enum ReferralAgentSelection {
    search = 1,
    particular = 2,
}

export enum ReferralTimeSpoke {
    pastDay = 1,
    past2Days = 2,
    past3Days = 3,
    pastWeek = 4,
    past2Weeks = 5,
    pastMonth = 6,
    past2Months = 7,
    past3Months = 8,
    more3Month = 9,
    neverSpoken = 10,
}

export type TReferralModel = Readonly<{
    id: number;
    owner?: ReferralOwner;
    actions?: TReferralActionList | null;
    source?: ReferralSource | null;
    timeSpoken?: ReferralTimeSpoke | null;
    clientFirstName?: string | null;
    clientLastName?: string | null;
    clientPhone?: string | null;
    clientEmail?: string | null;
    type?: ReferralType | null;
    sellAddress1?: string | null;
    sellAddress2?: string | null;
    sellCity?: string | null;
    sellState?: number | null;
    sellZip?: string | null;
    sellPropertyType?: ReferralPropertyType | null;
    sellTargetPrice?: number | null;
    sellTimeFrame?: ReferralTimeFrame | null;
    sellNote?: string | null;
    buyLocationType?: LocationType | null;
    buyLocations?: TLocationItemList | null;
    buyPropertyTypes?: TReferralPropertyTypeList | null;
    buyTimeFrame?: ReferralTimeFrame | null;
    buyRangeMin?: number | null;
    buyRangeMax?: number | null;
    buyNote?: string | null;
    agentType?: ReferralAgentSelection | null;
    addedDate?: string | null;
    candidates: TUserDataList;
    status: ReferralStatus;
    guid?: string | null;
    invitedAgentFirstName?: string | null;
    invitedAgentLastName?: string | null;
    invitedAgentPhone?: string | null;
    invitedAgentEmail?: string | null;
    agentMessage?: string | null;
    invitedAgentSendDate?: string | null;
    agentSelectDate?: string | null;
    agreement?: string | null;
    agreementFileName?: string | null;
    signedReferralAgreement?: boolean;
    uploadAgreementDate?: string | null;
    returnAgreementMessage?: string | null;
    useGeneratedAgreement: boolean;
    fee: number;
    candidateSectionAchived: boolean;
    clientPreapproved: boolean;

    
    agentId?: number | null;
    agentName?: string | null;
    agentFirstName?: string | null;
    agentLastName?: string | null;
    agentBrokerageName?: string | null;
    agentPhone?: string | null;
    agentEmail?: string | null;
    agentPrimaryImage?: string | null;
    agentPublicProfile?: string | null;    
    agentCellPhoneNumber?: string | null;
    agentOfficePhoneNumber?: string | null;
    agentOfficeAddress1?: string | null;
    agentOfficeAddress2?: string | null;
    agentOfficeCity?: string | null;
    agentOfficeStateId?: number | null;
    agentOfficeZip?: string | null;
    
    userId?: number | null;
    userName?: string | null;
    userFirstName?: string | null;
    userLastName?: string | null;
    userBrokerageName?: string | null;
    userPhone?: string | null;
    userEmail?: string | null;
    userPrimaryImage?: string | null;
    userPublicProfile?: string | null;
    userCellPhoneNumber?: string | null;
    userOfficePhoneNumber?: string | null;
    userOfficeAddress1?: string | null;
    userOfficeAddress2?: string | null;
    userOfficeCity?: string | null;
    userOfficeStateId?: number | null;
    userOfficeZip?: string | null;
}>;

// Agreement
// AgreementFileName
// SignedReferralAgreement
// UploadAgreementDate
// ReturnAgreementMessage
// UseGeneratedAgreement

export type TReferralSaveModel =
    Omit<TReferralModel, 'candidates' | 'guid'>
    & Readonly<{
        candidates: TNumberList;
        callIndeces?: TNumberList | null; // its look like always undefind
        emailIndeces?: TNumberList | null; // its look like always undefind
        noteIndeces?: TNumberList | null; // its look like always undefind
        invitationId?: number | null
    }>;

export type TResponseGetExistReferral = Readonly<{
    referral: TReferralModel;
    userName: string | null;
    states: TProfileStates;
    designations: TProfileDesignations;
    specialties: TProfileSpecialties;
    languages: TProfileLanguages;
    sourcesOfBusiness: TProfileSourcesOfBusiness;
    calls: TStateReferralCallList;
    emails: TStateReferralEmailList;
    notes: TStateReferralNoteList;
    inviteCode: string;
    reviews: any[];
}>;

export type TSendReferralsCallsData = Readonly<{
    calleeId: number | null;
    notes: string;
    referralId: number;
}>;

export type TSendReferralsNoteData = Readonly<{
    agentId: number;
    note: string;
    referralId: number;
    date?: string | null;
}>;

export type TGenerateReferralAgreement = Readonly<{
    type?: ReferralType | null;
    clientFirstName?: string | null;
    clientLastName?: string | null;
    fee: number;
    sellNote?: string | null;
    buyLocationType?: LocationType | null;
    buyLocations: TLocationItemList;
    buyTimeFrame?: ReferralTimeFrame | null;
    buyRangeMin?: string | null;
    buyRangeMax?: string | null;
    buyNote?: string | null;
    agentId?: number | null;
}>;

export type TOnLoadTableOutbound = Readonly<{
    table: TOutboundTableName;
}>;

export type TOnLoadTableInbound = Readonly<{
    table: TInboundTableName;
}>;

export type TKnowledgeBaseArticleSearchResult = Readonly<{
    id: number;
    name: string;
    content: string;
    status: KnowledgeBaseArticleStatus;
}>;

export type TKnowledgeBaseArticleSearchResultList = ReadonlyArray<TKnowledgeBaseArticleSearchResult>;

export type TPropssKeyData = Readonly<{
    code: string;
    onPress: TCbVoid;
}>;

export type TKnowledgeBaseRelatedArticle = TNamedId;

export type TKnowledgeBaseRelatedArticleList = ReadonlyArray<TKnowledgeBaseRelatedArticle>;

export type TPropsKeyDataList = ReadonlyArray<TPropssKeyData>;

export type TKnowledgeBaseArticleDetailView = Readonly<{
    id: number;
    name: string;
    content: string;
    status: KnowledgeBaseArticleStatus;
    categoryId: number;
    categoryName: string;
    relatedArticles: TKnowledgeBaseRelatedArticleList;
}>;

export type TKnowledgeBaseCategory = TNamedId;

export type TKnowledgeBaseCategoryList = ReadonlyArray<TKnowledgeBaseCategory>;

export type TKnowledgeBaseArticleEdit = Readonly<{
    id?: number;
    name: string;
    content: string;
    status: KnowledgeBaseArticleStatus;
    categoryId?: number;
    relatedArticles?: TKnowledgeBaseRelatedArticleList;
}>;

export type TKnowledgeBaseArticleEditResponse = Readonly<{
    categories: TKnowledgeBaseCategoryList;
}>
    & Required<TKnowledgeBaseArticleEdit>;

export type TKnowledgeBaseArticleSave = Readonly<{
    name: string;
    content: string;
    status: KnowledgeBaseArticleStatus;
    categoryId: number;
    relatedArticles?: TNumberList;
}>;

export type TKnowledgeBaseCategoryEdit =
    TNamedId &
    Readonly<{
        order: number;
        articlePresence: boolean;
    }>;

export type TKnowledgeBaseCategoryEditList = ReadonlyArray<TKnowledgeBaseCategoryEdit>;

export type TVariantButtonRadio = 'circle' | 'square';
export enum ImageInfoType {
    image = 0,
    pdf = 1,
}

export type TImageInfo = Readonly<{
    name: string;
    url: string;
    size: number;
    type: ImageInfoType;
}>;

export type TImageInfoList = ReadonlyArray<TImageInfo>;

export type TSaveArticleData = Readonly<{
    id: number;
    name: string;
    status: KnowledgeBaseArticleStatus;
    categoryId: number;
}>;

export type TKnowledgeBaseCategoryExpandedView = Readonly<{
    id: number;
    name: string;
    articles: TKnowledgeBaseArticleViewModelList;
}>;

export type TKnowledgeBaseCategoryExpandedViewEditable =
    Omit<TKnowledgeBaseCategoryExpandedView, 'articles'> &
    Readonly<{
        articles: TKnowledgeBaseArticleViewModelEditableList;
    }>;


export type TUpdateCategoryArticles = Readonly<{
    articles: TOrderedIdList;
    removedArticles: TNumberList;
}>;

export type TKnowledgeBaseCategoryUpdate = Readonly<{
    id: number;
    name: string;
    order: number;
    reassignedArticles?: TNumberList | null;
}>;

export type TKnowledgeBaseCategoryUpdateList = ReadonlyArray<TKnowledgeBaseCategoryUpdate>;

export type TKnowledgeBaseCategoryEditable =
    Pick<TKnowledgeBaseCategoryEdit, 'articlePresence' | 'order'> &
    Partial<TNamedId> &
    TEditableWithKey &
    Readonly<{
        reassignCategoryNumber?: number;
        reassignArticlesTo?: TKnowledgeBaseCategoryEditable;
        reassignedArticles?: TNumberList;
    }>;

export type TKnowledgeBaseCategoryEditableList = ReadonlyArray<TKnowledgeBaseCategoryEditable>;

export type TFeature = Readonly<{
    id: number;
    title: string;
    description: string;
    votes: number;
    approved: boolean;
}>;

export type TFeatureList = ReadonlyArray<TFeature>;

export type TViewFeatures = Readonly<{
    features: TFeatureList;
    votes: TNumberList;
    count: number;
}>;

export type TViewFeaturesExtended =
    TViewFeatures &
    Readonly<{
        activeTab: TTabFeature;
        isLoading: boolean;
    }>;

export enum FeatureSearch {
    popular = 0,
    newest = 1,
    completed = 2,
}

export type TPayloadGetFeatures = Readonly<{
    searchTerm?: string | null;
    count: number;
    page: number;
    searchType: FeatureSearch;
}>;

export type TNavigationTab<T extends string> = Readonly<{
    label: string;
    name: T;
}>;

export type TNavigationTabList<T extends string> = ReadonlyArray<TNavigationTab<T>>;

export type TTabFeature = 'popular' | 'newest' | 'completed' | 'votes';
export type TTabStateLicenses = 'pending' | 'rejected' | 'approved';

export type TManageStateLicenseUser = Readonly<{
    id: number;
    name: string;
    dateJoined: string;
    email: string;
    cellPhone: string;
    primaryWebsite?: string | null;
    firstYearOfService?: number | null;
    brokerageName: string;
    teamName?: string | null;
    officePhone: string;
    officeAddress1: string;
    officeAddress2: string;
    officeAddress3: string;
    imageUrl: string;
    general: string;
}>;

export type TManageStateLicense = Readonly<{
    id: number;
    state: string;
    number: string;
    status: LicenseStatus;
    user: TManageStateLicenseUser;
    updatedDate?: string | null;
    updatedByUser?: string | null;
}>;

export type TManageStateLicenseList = ReadonlyArray<TManageStateLicense>;

export type TUserWebsite = Readonly<{
    id: number;
    userId: number;
    createdAt: Date;
    url: string;
    verifiedByUserId?: number | null;
    verifiedDate?: Date | null;
}>

export type TUserWebsiteList = ReadonlyArray<TUserWebsite>;

export type TUserMedia = Readonly<{
    id: number;
    userId: number;
    createdAt: Date;
    type: string;
    content: string;
    verifiedByUserId?: number | null;
    verifiedDate?: Date | null;
}>

export type TUserMediaList = ReadonlyArray<TUserMedia>;

export type TPayloadPutStateLicensesReject = Readonly<{
    status: LicenseStatus.rejected;
    verificationUrl?: undefined;
    yearLicensed?: number;
    licenseExpiryDate?: Date;
}>;

export type TPayloadPutStateLicensesApprove = Readonly<{
    status: LicenseStatus.verified;
    verificationUrl: string;
    yearLicensed?: number;
    licenseExpiryDate?: Date;
}>;

export type TPayloadPutStateLicenses = TPayloadPutStateLicensesReject | TPayloadPutStateLicensesApprove;


export enum UserNoteType {
    license = 0,
    transaction = 1,
    user = 2,
    pastReferral = 3,
};
export type TUserNote = Readonly<{
    content: string;
    noteType: UserNoteType;
    id: number;
    byUserId: number;
    byUserName: string;
    dateAdded: string;
}>;

export type TUserNoteList = ReadonlyArray<TUserNote>;

export type TUserNoteRequest = Readonly<{
    content: string;
    noteType: UserNoteType;
}>;

export enum FeatureStatus {
    initial = 0,
    submitted = 10,
    approved = 20,
    rejected = 21,
    completed = 30,
}

export enum ManageFeatureFilterSortField {
    createDate = 0,
    user = 1,
}

export type TManageFeatureSearchFilter = Readonly<{
    searchTerm: string;
    count: number;
    page: number;
    status: FeatureStatus;
    sortField: keyof typeof ManageFeatureFilterSortField;
    sortOrder: SortOrder;
}>;

export type TManageFeatureUser = Readonly<{
    id: number;
    firstName: string;
    lastName: string;
    initial: string;
    primaryImage: string;
    dateJoined: string;
    email: string;
    cellPhone: string;
    outboundUnmatched: number;
    outboundInterviewing: number;
    outboundMatched: number;
    outboundWorking: number;
    outboundInEscrow: number;
    outboundClosed: number;
    inboundCandidate: number;
    inboundNotAccepted: number;
    inboundWorking: number;
    inboundInEscrow: number;
    inboundDeclined: number;
    inboundClosed: number;
}>;

export type TManageFeature = Readonly<{
    id: number;
    createDate: string;
    title: string;
    description: string;
    user: TManageFeatureUser;
}>;

export type TManageFeatureList = ReadonlyArray<TManageFeature>;

export type TManageFeatures = Readonly<{
    submitted: number;
    active: number;
    completed: number;
    features: TManageFeatureList;
    count: number;
}>;

export type TSortData<T extends string | number = string | number> = Readonly<{
    sortField: T;
    sortOrder: SortOrder;
}>;

export type THeaderItem<T extends string | number = string> = Readonly<{
    label: string;
    name?: T;
    isSortable?: boolean;
    isDescByDefault?: boolean; // is desc sorting by default (first click)
}>;

export type THeaderItemList<T extends string | number = string> = ReadonlyArray<THeaderItem<T>>;

export type TPressHeader<T extends string | number> = (
    name: T,
    isDescByDefault?: boolean,
) => void;

export type TAddFeature = Readonly<{
    title: string;
    description: string;
}>;

export type TInfoLineItem = Readonly<{
    name: string;
    value?: string | null;
    isLink?: boolean | null;
    isOneLine?: boolean | null;
    children?: React.ReactElement;
    colorsValue?: TColorsProp | null;
    styleValue?: TStyle | null;
    testId?: string | null;
    onPress?: TOnPress | null;
}>;

export type TInfoLineList = ReadonlyArray<TInfoLineItem>;

export enum UserSearchFilterSortOrder {
    name = 0,
    primaryImage = 1, // 'photo' on server
    dateJoined = 2,
    stateLicensed = 3,
    transactions = 4,
    referred = 5,
    lastLogin = 6,
}

export enum UserStatusEnum {
    active = 0,
    onHold = 1,
    inactive = 2,
    banned = 3,
}

export type TUserStatusEnumObject = Readonly<{
    status: UserStatusEnum;
}>;

export enum MembershipTypeFilter {
    all = 0,
    full = 1,
    free = 2,
}

export type UserRepositorySearchFilter = Readonly<{
    searchTerm?: string | null;
    // roles?: TStringList | null; // not used yet
    pageNumber: number;
    pageSize: number;
    sortField: UserSearchFilterSortOrder;
    sortOrder: SortOrder;
    status: UserStatusEnum;
    membershipType: MembershipTypeFilter;
}>;

export type TManageUsersCounts = Readonly<{
    active?: number;
    onHold?: number;
    inactive?: number;
    banned?: number;
}>;
export type TUserTransactionRow = Readonly<{
    period: string;
    status: UserTransactionsStatus;
}>;

export type TUserTransactionRowList = ReadonlyArray<TUserTransactionRow>;

export type TUserDesignationRow = Readonly<{
    name: string;
    status: UserDesignationStatus;
}>;

export type TUserDesignationRowList = ReadonlyArray<TUserDesignationRow>;

export type TReferredUsersModel = Readonly<{
    total: number;
    full: number;
    free: number;
}>;

export type TLoginInfo = Readonly<{
    lastLogin: string | null;
    last30: number;
    last90: number;
}>;

export type TUserRow = Readonly<{
    id: number;
    name: string;
    primaryImage?: string | null;
    dateJoined: string;
    stateLicensed: TStringList;
    transactions: TUserTransactionRowList;
    designations: TUserDesignationRowList;
    pastReferrals: TPastReferralStatusList;
    cellPhone?: string | null;
    status: UserStatusEnum;
    role: TRole;
    referred: TReferredUsersModel;
    loginInfo: TLoginInfo;
}>;

export type TUserRowList = ReadonlyArray<TUserRow>;

export type TResponseGetManageUsers = Readonly<{
    counts: TManageUsersCounts;
    totalCount: number;
    users: TUserRowList;
}>;

export type TPayloadGetDesignations = Readonly<{
    designation: number;
    searchTerm?: string | null;
    status?: TUserDesignationStatusList;
}>;

export type TManageDesignationUser = Readonly<{
    id: number;
    name: string;
    primaryImage: string;
    dateJoined: string;
    stateLicensed: TStringList;
    email: string;
    cellPhone: string;
    primaryWebsite: string;
    firstYearOfService?: number | null;
    brokerageName: string;
    teamName: string;
    officePhone: string;
    officeAddress: string;
}>;

export type TManageUserDesignation = Readonly<{
    id: number;
    abbreviation: string;
    status: UserDesignationStatus;
    verifiedByDate?: string | null;
    verifiedBy: string;
    user: TManageDesignationUser;
}>;

export type TManageUserDesignationList = ReadonlyArray<TManageUserDesignation>;

export type TManageSpecialty = Readonly<{
    id: number;
    name: string;
    option: number;
}>;

export type TManageSpecialtyList = ReadonlyArray<TManageSpecialty>;

export type TManageDesignation = Readonly<{
    id: number;
    abbreviation: string;
    pendingCount: number;
    rejectedCount: number;
    description?: string;
}>;

export type TManageDesignationList = ReadonlyArray<TManageDesignation>;

export type UpdateUserDesignation = Readonly<{
    status: UserDesignationStatus.verified;
    verificationUrl: string;
}> | Readonly<{
    status: UserDesignationStatus.rejected;
}>;

export type TSendDesignationQuestion = Readonly<{
    userId: number;
    body: string;
}>;

export type TManageTransactionUser = Readonly<{
    id: number;
    name: string;
    dateJoined: string;
    email: string;
    cellPhone: string;
    primaryImage: string;
    brokerageName: string;
}>;

export enum TransactionDocumentType {
    pdf = 0,
    xls = 1,
}

export type TManageTransactionDocument = Readonly<{
    id: number;
    documentId: string;
    documentType: TransactionDocumentType;
    period: string;
    dateUploaded: string;
    unitsSold: number;
    totalSales: number;
}>;

export type TManageTransactionDocumentList = ReadonlyArray<TManageTransactionDocument>;

export type TManageTransaction = Readonly<{
    brokerName: string;
    brokerEmail: string;
    brokerOfficePhone: string;
    notesForClarification?: string | null;
    status: UserTransactionsStatus;
    verifiedByUser?: string | null;
    verifiedDate?: string | null;
    emailRequestSent: boolean;
    user: TManageTransactionUser;
    documents: TManageTransactionDocumentList;
}>;

export type TManageTransactionList = ReadonlyArray<TManageTransaction>;

export type TManageTransactions = Readonly<{
   transactions: TManageTransactionList;
}>;

export type TUserTransactionsConfirmVia = 'phone' | 'email' | 'kWLookUp';

export type TConfirmTransactionsUser = Readonly<{
    ids: TNumberList;
    confirmationName: string;
    titlePosition: string;
    confirmedVia: TUserTransactionsConfirmVia;
    notes: string;
}>;

export type TSendTransactionsQuestion = Readonly<{
    userId: number;
    body: string;
}>;

export type SendTransactionsRequestModel = Readonly<{
    transactionIds: TNumberList;
    email: string;
    subject: string;
    message: string;
    ccEmails: string;
}>;

export enum ReferralRaterType {
    client = 0,
    agent = 1,
}

export type TManagePastReferralUser = Readonly<{
    id: number;
    name: string;
    primaryImage: string;
    dateJoined: string;
    email: string;
    cellPhone: string;
}>;

export enum ReferralTransactionType {
    buy = 0,
    sell = 1,
}

export type TManageReferralTransaction = Readonly<{
    id: number;
    type: ReferralTransactionType;
    address: string;
    amount?: number | null;
    date?: string | null;
    statementId: string;
    state: string;
}>;

export type TManageReferralTransactionList = ReadonlyArray<TManageReferralTransaction>;

export type TManagePastReferral = Readonly<{
    id: number;
    ratingId: number;
    rater: ReferralRaterType;
    status: PastReferralStatus;
    type?: PastReferralType | null;
    clientName: string;
    clientPhone?: string | null;
    clientEmail: string;
    agentName: string;
    agentPhone: string;
    agentEmail: string;
    submittedDate?: string | null;
    referralAgreement: string;
    approvalNotes?: string | null;
    verifiedDate?: string | null;
    verifiedByFirstName?: string | null;
    verifiedByLastName?: string | null;
    user: TManagePastReferralUser;
    transactions: TManageReferralTransactionList;
    agentRatingReceived?: boolean | null;
    clientRatingReceived?: boolean | null;
    agentRatingId?: number | null;
    clientRatingId?: number | null;
    agentRatingSentCount?: number | null;
    clientRatingSentCount?: number | null;
    ratings: TNumberList;
}>;

export type TManagePastReferralList = ReadonlyArray<TManagePastReferral>;

export type TManagePastReferrals = Readonly<{
    referrals: TManagePastReferralList;
    totalCount: number;
    pendingVerification: number;
    pendingRatings: number;
    rejected: number;
    completed: number;
    ratingReceived: number;
}>;

export enum PastReferralSortField {
    name = 0,
    submittedDate = 1,
    type = 2,
}

export type TManagePastReferralsRatingsQuery = Readonly<{
    searchTerm?: string;
    pageSize: number;
    pageNumber: number;
    sortField: PastReferralSortField;
    sortOrder: SortOrder;
    includeRatingFlags?: boolean;
}>;

export type TManageRejectedPastReferralsQuery = Readonly<{
    searchTerm?: string;
    pageSize: number;
    pageNumber: number;
    sortField: PastReferralSortField;
    sortOrder: SortOrder;
}>;

export enum ReferralRatingStatus {
    pending = 0,
    emailSent = 1,
    emailBounced = 2,
    pendingApproval = 3,
    rejected = 4,
    approved = 5,
}

export type TReferralRatingStatusList = ReadonlyArray<ReferralRatingStatus>;

export type TManagePastReferralsQuery = Readonly<{
    searchTerm?: string;
    statuses?: TPastReferralStatusList | null;
    ratingStatus?: TReferralRatingStatusList | null;
    pageSize: number;
    pageNumber: number;
    sortField: PastReferralSortField;
    sortOrder: SortOrder;
    includeRatingFlags?: boolean;
}>;

export type TManagePastReferralRating = Readonly<{
    id: number;
    rater: ReferralRaterType;
    status: ReferralRatingStatus;
    rate1: number;
    rate2: number;
    rate3: number;
    rate4: number;
    rate5: number;
    feedback: string;
    ratingDate?: string | null;
}>;

export type TConfirmReferral = Readonly<{
    feedback: string;
    rate1: number;
    rate2: number;
    rate3: number;
    rate4: number;
    rate5: number;
}>;

export enum ReferralSortField {
    addedDate = 0,
    user = 1,
    agent = 2,
    client = 3,
    type = 4,
    status = 5,
}

export type TManageReferralsQuery = Readonly<{
    searchTerm: string;
    pageSize: number;
    pageNumber: number;
    statuses: TReferralStatusList;
    sortField: ReferralSortField;
    sortOrder: SortOrder;
    includeRatings?: boolean | null;
}>;

export type TManageReferral = Readonly<{
    id: number;
    addedDate: string;
    type: ReferralType | null;
    status: ReferralStatus;
    userId: number;
    userFirstName: string;
    userLastName: string;
    userPrimaryImage: string;
    agentId: number | null;
    agentFirstName: string;
    agentLastName: string;
    agentPrimaryImage: string | null;
    owner: ReferralOwner;
    clientName: string;
    missingInfo: TReferralMissingInfoList | null;
    buyPrice: number | null;
    sellPrice: number | null;
    buyContractClosingDate: string | null;
    sellContractClosingDate: string | null;
    transactionClosedDate: string | null;
    feeReceiveDate: string | null;
    agentRatingId: number | null;
    agentRatingApproved: boolean | null;
    clientRatingId: number | null;
    clientRatingApproved: boolean | null;
}>;

export type TManageReferralList = ReadonlyArray<TManageReferral>;

export type TManageReferrals = Readonly<{
    pending: number;
    active: number;
    received: number;
    rejected: number;
    complete: number;
    total: number;
    referrals: TManageReferralList;
}>;

export type TManageReferralRatingsQuery = Readonly<{
    searchTerm: string;
    pageSize: number;
    pageNumber: number;
    status: ReferralRatingStatus;
    sortField: ReferralSortField;
    sortOrder: SortOrder;
}>;

export type TClientEmailUpdateResult = Readonly<{
    clientFirstName: string;
    clientLastName: string;
    agentFirstName: string;
    agentLastName: string;
}>;

export type TSendEmail = Readonly<{
    subject: string;
    body: string;
}>;

export enum DiscountCodeStatus {
    active = 0,
    inactive = 1,
    removed = 2,
}

export enum DiscountCodeSearchField {
    createdDate = 0,
    name = 1,
    code = 2,
    amount = 3,
    length = 4,
    redemption = 5,
    used = 6,
}

export type TDiscountCodeSearch = Readonly<{
    status: DiscountCodeStatus;
    sortField: DiscountCodeSearchField;
    sortOrder: SortOrder;
    pageSize: number;
    pageNumber: number;
}>;

export type TDiscountCodeSearchResult = Readonly<{
    id: number;
    createdDate: string;
    startDate?: string | null;
    endDate?: string | null;
    name: string;
    code: string;
    amount?: number | null;
    type: DiscountCodeType;
    length?: number | null;
    redemptions?: number | null;
    userCount?: number | null;
}>;

export type TDiscountCodeSearchResultList = ReadonlyArray<TDiscountCodeSearchResult>;

export type TDiscountCodesResponse = Readonly<{
    active: number;
    inactive: number;
    deleted: number;
    codes: TDiscountCodeSearchResultList;
}>;

export type TEditDiscountCode = Readonly<{
    name: string;
    code: string;
    amount: number | null;
    type: DiscountCodeType;
    startDate?: string | null;
    endDate?: string | null;
    length: number | null;
    redemptions?: number | null;
    title: string;
    description: string;
    priceHeader: string;
    priceDescription: string;
    subItems: string;
}>;

export type TRequirementData = Readonly<{
    licenseConfirmed: boolean;
    agentConfirmed: boolean;
    salesConfirmed: boolean;
}>;

export type TBillingInfoData = Readonly<{
    nameOnCard: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    cardNumberData: StripeElementChangeEvent | null;
    expiryData: StripeElementChangeEvent | null;
    cvcData: StripeElementChangeEvent | null;
}>;

export type TPartialMemberElevention = Readonly<{
    cardToken: string;
    expansionNetwork?: number | null;
}>;

export type TRegisterFormData = Readonly<{
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    confirmEmail: string;
    password: string;
    isPasswordVisible: boolean;
    brokerageName: string;
    expansionNetwork: TExpansionNetwork | null;
    code: string | null; // promotionCode
    captchaToken: string | null;
}>;

export type TValidationFormRegister = Readonly<{
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    confirmEmail: boolean;
    password: boolean;
    brokerageName: boolean;
    captchaToken: boolean;
}>;

export type TGlobalModals = Readonly<{
    isRenewSubscriptionVisible?: boolean | null;
    isOnHoldStatusAlertVisible?: boolean | null;
}>;

export type TResponseRenewMembership = Readonly<{
    stripeError?: boolean | null;
    message?: string | null;
}>;

export type TEmailConfirmation = Readonly<{
    userId: number;
    token: string;
}>;

export type TResponseEmailConfirmation = Readonly<{
    id: number;
    firstName: string;
    lastName: string;
    profileStep: number;
    role: TRole;
    primaryImage: string;
    brokerageName: string;
    token: string;
    status: UserStatusEnum;
    upgradeAmount: number;
}>;

export enum ReferralDirectionEnum {
    outbound = 1,
    inbound = 2,
}

export type TInvitationStatusResult = Readonly<{
    available: boolean;
    errorMessage: string | null;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    userFirstName: string;
    userLastName: string;
    direction?: ReferralDirectionEnum | null;
    userId?: number | null;
    clientFirstName: string | null;
    clientLastName: string | null;
}>;

type TInvitationAcceptBase = Readonly<{
    firstName: string;
    lastName: string;
    brokerageName: string;
    email: string;
    password: string;
    phone: string;
    expansionNetwork?: number | null;
}>;

type TInvitationAcceptFull = Readonly<{
    fullFeatureMember: true;
    cardToken: string;
}>
    & TInvitationAcceptBase;

type TInvitationAcceptPartial = Readonly<{
    fullFeatureMember: false;
}>
    & TInvitationAcceptBase;

export type TInvitationAccept = TInvitationAcceptFull | TInvitationAcceptPartial;

export type TInvitationAcceptResponse = Readonly<{
    emailExists: boolean;
    id: number;
    token: string;
    referralId?: number | null;
    invitationCount: number;
    direction: ReferralOwner;
    status: UserStatusEnum;
    upgradeAmount: number;
}>;

export type TResetPassword = Readonly<{
    email?: string;
    token?: string;
    password: string;
}>;

export type TPastReferralRatingInfo = Readonly<{
    name: string;
    avatar: string;
    type: ReferralRaterType;
    rated: boolean;
}>;

export type TPastReferralRating = Readonly<{
    rate1: number;
    rate2: number;
    rate3: number;
    rate4: number;
    rate5: number;
    feedback: string;
}>;

export enum SocialMediaType {
    unknown = 0,
    facebook = 1,
    twitter = 2,
    linkedIn = 3,
    pinterest = 4,
    youTube = 5,
    vimeo = 6,
    googlePlus = 7,
    instagram = 8,
    flickr = 9,
}

export enum SocialMediaIdEnum {
    unknown = 0,
    facebook_personal = 1,
    facebook_business = 2,
    twitter = 3,
    linkedIn = 4,
    pinterest = 5,
    youTube = 6,
    vimeo = 7,
    googlePlus = 8,
    instagram = 9,
    flickr = 10,
    tiktok = 11,
}

export type TUserSocialMedium = Readonly<{ // "Medium" like on server
    name: string;
    url: string;
    type: SocialMediaIdEnum
}>;

export type TUserSocialMediumList = ReadonlyArray<TUserSocialMedium>;

export type TPublicProfileView = Readonly<{
    id: number;
    email?: string | null;
    primaryImage?: string | null;
    firstName: string;
    lastName: string;
    cellPhoneNumber: string,
    officePhoneNumber: string,
    officePhoneExtension: string,
    officeAddress1: string,
    officeAddress2: string,
    officeCity: string,
    officeStateId: number,
    officeZip: string,
    professionalTitle?: string | null;
    teamName?: string | null;
    brokerageName: string;
    dateJoined: string;
    general: string;
    qualityScore: number;
    firstYearOfService?: string | null;
    lastYearSales?: string | null;
    salesYear?: string | null;
    slogan?: string | null;
    missionStatement?: string | null;
    communityInvolvement: TStringList;
    industryInvolvement: TStringList;
    educationalEventsAttended: TStringList;
    areasOfSpecializationCurrentlyTaught: TStringList;
    specialties: TAgentSpecialtyList;
    designations: TStringList;
    sourcesOfBusiness: TBusinessSourceList;
    languages: TStringList;
    servedCities: TLocationItemList;
    servedCounties: TLocationItemList;
    servedZipCodes: TLocationItemList;
    blogSite?: string | null;
    geographicFarmSite1?: string | null;
    geographicFarmSite2?: string | null;
    miscSite?: string | null;
    primaryWebsite?: string | null;
    sellerSite?: string | null;
    valuationSite?: string | null;
    socialMedia: TUserSocialMediumList;
    totalRatingsCount: number;
    buyerRating: number;
    buyerRatingCount: number;
    sellerRating: number;
    sellerRatingCount: number;
    agentRating: number;
    agentRatingCount: number;
    ratings: TUserRatingList;
    lastYearTransactions: number;
    teamMembers: TAgentTeamMemberList;
    inviteCode: string;
    product: string;
    stripeId: string;
    googleReviewPlaceId?: string;
    userWebsites?: TUserWebsiteList;
    userMedias?: TUserMediaList;
    heroImage?: string | null;
    reviews?: any[];
    publicProfile?: string | null;
    stateLicenses?: any[];

    referrals?: any[];

    refStatInboundOpportunitiesCount?: number;
    refStatOutboundOpportunitiesCount?: number;
    refStatInboundActiveCount?: number;
    refStatOutboundActiveCount?: number;
    refStatInboundUnderContractCount?: number;
    refStatOutboundUnderContractCount?: number;
    refStatInboundClosedCount?: number;
    refStatOutboundClosedCount?: number;
    refStatInboundDeclinedCount?: number;
    refStatOutboundDeclinedCount?: number;       
    refStatInboundReturnedCount?: number;
    refStatOutboundReturnedCount?: number;
}>;

export type TPrivateProfileView = Readonly<{
    primaryImage?: string | null;
    firstName: string;
    lastName: string;
    statisticEmails?: string | null;
    cellPhoneNumber: string,
    officePhoneNumber: string,
    officePhoneExtension: string,
    officeAddress1?: string,
    officeAddress2?: string,
    officeCity?: string,
    officeStateId?: number,
    officeZip?: number,
    professionalTitle?: string | null;
    teamName?: string | null;
    brokerageName: string;
    dateJoined: string;
    general: string;
    qualityScore: number;
    firstYearOfService?: string | null;
    lastYearSales?: string | null;
    salesYear?: string | null;
    slogan?: string | null;
    missionStatement?: string | null;
    communityInvolvement: TStringList;
    industryInvolvement: TStringList;
    educationalEventsAttended: TStringList;
    areasOfSpecializationCurrentlyTaught: TStringList;
    specialties: TAgentSpecialtyList;
    designations: TUserDesignationList;
    sourcesOfBusiness: TBusinessSourceList;
    languages: TStringList;
    servedCities: TLocationItemList;
    servedCounties: TLocationItemList;
    servedZipCodes: TLocationItemList;
    blogSite?: string | null;
    geographicFarmSite1?: string | null;
    geographicFarmSite2?: string | null;
    miscSite?: string | null;
    primaryWebsite?: string | null;
    sellerSite?: string | null;
    valuationSite?: string | null;
    socialMedia: TUserSocialMediumList;
    totalRatingsCount: number;
    buyerRating: number;
    buyerRatingCount: number;
    sellerRating: number;
    sellerRatingCount: number;
    agentRating: number;
    agentRatingCount: number;
    ratings: TUserRatingList;
    lastYearTransactions: number;
    teamMembers: TAgentTeamMemberList;
    inviteCode: string;
    stripeId: string;
    product?: string;
    stateLicenses?: Array<any>;
    partnerstackKey?: string;
    invitedById?: number;
    invitedByAgentFirstName?: string;
    invitedByAgentLastName?: string;
    invitedByPartnerstackKey?: string;
    googleReviewPlaceId?: string;
    userWebsites?: TUserWebsiteList;
    userMedias?: TUserMediaList;
    heroImage?: string | null;
    
    refStatInboundOpportunitiesCount?: number;
    refStatOutboundOpportunitiesCount?: number;
    refStatInboundActiveCount?: number;
    refStatOutboundActiveCount?: number;
    refStatInboundUnderContractCount?: number;
    refStatOutboundUnderContractCount?: number;
    refStatInboundClosedCount?: number;
    refStatOutboundClosedCount?: number;
    refStatInboundDeclinedCount?: number;
    refStatOutboundDeclinedCount?: number;       
    refStatInboundReturnedCount?: number;
    refStatOutboundReturnedCount?: number;

    referrals?: any;
}>;

export type TDeclineReferralInfo = Readonly<{
    userFirstName: string;
    userLastName: string;
    userPrimaryImage: string;
    source?: string | null;
    type?: ReferralType | null;
    buyLocations?: string | null;
    buyPropertyType: string;
    buyMinimumPrice: number;
    buyMaximumPrice: number;
    buyTimeFrame?: string | null;
    sellLocation?: string | null;
    sellPropertyType?: string | null;
    sellTargetPrice: number;
    sellTimeFrame?: string | null;
    owner: ReferralOwner;
    clientFirstName: string;
    clientLastName: string;
}>;

export type TResponseGetDeclinedReferral = Readonly<{
    errorMessage?: string | null;
    referral?: TDeclineReferralInfo | null;
}>;

export type TSearchAgentQuery = Readonly<{
    serviceAreas?: TSearchServiceAreaModelList | null;
    qualityScore?: TQualityScore | null;
    yearsOfExperience?: number | null;
    previousYearSales?: number | null;
    specialties?: TNumberList | null;
    designations?: TNumberList | null;
    languages?: TNumberList | null;
    agentName?: string;
    page: number;
    pageSize: number;
    sortField: OrderByUserFilter;
    userId: number;
    brokerages?: TSearchTextModelList | null;
}>;

export type TSearchAgentMapResultItem = Readonly<{
    id: number;
    primaryImage?: string | null;
    lat?: number | null;
    lng?: number | null;
}>;

export type TSearchAgentMapResult = ReadonlyArray<TSearchAgentMapResultItem>;

export type TMemberAbsenceEmail = Readonly<{
    source: ReferralSource;
    type: ReferralType;
    locations: TStringList;
    properties: TReferralPropertyTypeList;
    priceMin?: number;
    priceMax?: number;
    targetPrice?: number;
    timeFrame: ReferralTimeFrame;
}>;

export type TValidationData<T extends string> = Readonly<{
    isValid: boolean;
    boxName: T;
}>;

export type TValidationDataList<T extends string> = ReadonlyArray<TValidationData<T>>;

export type TQuickSearchClient = Readonly<{
    id: number;
    invitationId: number | null;
    status: ReferralStatus;
    clientFirstName: string | null;
    clientLastName: string | null;
    addedDate: string;
    primaryImage: string | null;
    agentFirstName: string;
    agentLastName: string;
    owner: ReferralOwner;
    referralDirection: ReferralDirectionEnum;
    isAgentSelected: boolean;
}>;

export type TQuickSearchClientList = ReadonlyArray<TQuickSearchClient>;

export enum MessageType {
    note = 0,
    email = 1,
    action = 2,
}

export type TQuickSearchMessage = Readonly<{
    agentId: number | null;
    userId: number | null;
    invitationId: number | null;
    status: ReferralStatus;
    referralId: number;
    date: string;
    message: string;
    type: MessageType;
    recipientFirstName: string;
    recipientLastName: string;
    senderFirstName: string;
    senderLastName: string;
    referralDirection: ReferralDirectionEnum;
    owner: ReferralOwner;
}>;

export type TQuickSearchMessageList = ReadonlyArray<TQuickSearchMessage>;

export type TQuickSearchMember = Readonly<{
    id: number;
    firstName: string;
    lastName: string;
    primaryImage: string | null;
    brokerageName: string;
}>;

export type TQuickSearchMemberList = ReadonlyArray<TQuickSearchMember>;

export type TQuickSearchResponse = Readonly<{
    clients: TQuickSearchClientList;
    messages: TQuickSearchMessageList;
    members: TQuickSearchMemberList;
}>;

export type TOnLoadReferralsTableOutboundData = Readonly<{
    table: TOutboundTableName;
}>
    & TStateReferralsUserOutbound;

export type TOnLoadReferralsTableInboundData = Readonly<{
    table: TInboundTableName;
}>
    & TStateReferralsUserInbound;

export type TResponseGetUserReferralsWithAgentCount = Readonly<{
    countOutbound: number;
    countInbound: number;
}>;

export enum UserHistoryType {
    dateJoined = 0,
    fromFreeToFull = 2,
    fromFullToFree = 3,
    paymentRenewal = 4,
    editProfilePrimary = 5,
    editProfileBusinessOverview = 6,
    editProfileBackground = 7,
    editProfileLogo = 8,
    editProfileLicense = 9,
    editProfileWebsites = 10,
    editProfileSocialMedia = 11,
    editProfileSpecialties = 12,
    editProfileDesignations = 13,
    editProfileLanguages = 14,
    editProfileSourcesOfBusiness = 15,
    editProfileLocations = 16,
}

export enum ChangedByTypeEnum {
    self = 0,
    admin = 1,
    expansionNetworkHead = 2,
}

export enum UserHistoryActionType {
    common = 0,
    payment = 1,
    statusChange = 2,
    deletedExpansionNetwork = 3,
}

export type TUserHistoryCommon = Readonly<{
    date: string;
    type: UserHistoryType;
    userFirstName: string;
    userLastName: string;
    changedByFirstName: string;
    changedByLastName: string;
    changedByType: ChangedByTypeEnum;
    historyType: UserHistoryActionType.common;
}>;

export enum CardTypeEnum {
    unknown = 0,
    visa = 1,
    masterCard = 2,
    americanExpress = 3,
    none = 10,
}

export enum UserPaymentAction {
    register = 0,
    paymentRenewal = 1,
    upgradeMembershipType = 2,
    downgradeMembershipType = 3,
}

export enum MembershipTypePayment {
    none = 0,
    free = 1,
    full = 2,
}

export type TUserHistoryPayment = Readonly<{
    date: string;
    actionType: UserPaymentAction;
    from: MembershipTypePayment;
    to: MembershipTypePayment;
    cardType: CardTypeEnum;
    cardLast4: string | null;
    discountCodeName: string | null;
    discount: number | null;
    discountCodeType: DiscountCodeType | null;
    amount: number;
    userFirstName: string;
    userLastName: string;
    changedByFirstName: string;
    changedByLastName: string;
    invitedByFirstName: string | null;
    invitedByLastName: string | null;
    changedByType: ChangedByTypeEnum;
    expansionNetworkName: string | null;
    historyType: UserHistoryActionType.payment;
    transactionId: string | null;
    invitationDiscount: number | null;
    invitationDiscountType: DiscountCodeType | null;
}>;

export type TUserHistoryStatusChange = Readonly<{
    date: string;
    changedByType: ChangedByTypeEnum;
    userFirstName: string;
    userLastName: string;
    changedByFirstName: string;
    changedByLastName: string;
    from: UserStatusEnum;
    to: UserStatusEnum;
    historyType: UserHistoryActionType.statusChange;
}>;

export type TUserHistoryDeletedExpansionNetwork = Readonly<{
    date: string;
    changedByType: ChangedByTypeEnum;
    userFirstName: string;
    userLastName: string;
    changedByFirstName: string;
    changedByLastName: string;
    expansionNetworkName: string;
    historyType: UserHistoryActionType.deletedExpansionNetwork;
    deletedMembersCount: number;
}>;

export type TUserHistory = TUserHistoryCommon
    | TUserHistoryPayment
    | TUserHistoryStatusChange
    | TUserHistoryDeletedExpansionNetwork;

export type TUserHistoryList = ReadonlyArray<TUserHistory>;

export type TChangePartOf<T extends TUnknownObject> = TCb<Partial<T>>

export type TInfoItemViewReferral = Readonly<{
    label: string;
    text: string;
    isVisible: boolean;
    style?: TStyle | null;
    custom?: TCustomProps | null;
    href?: string | null;
}>;

export type TInfoItemViewReferralList = ReadonlyArray<TInfoItemViewReferral>;

export type TIdApiKey = number & TBrend<'apiKey'>;

export type TApiKeyModel = Readonly<{
    id: TIdApiKey;
    name: string;
    apiKey: string;
    createdDate: string;
    lastUsedDate: string | null;
}>;

export type TApiKeyModelList = ReadonlyArray<TApiKeyModel>;

export enum ApiKeySearchField {
    name = 0,
    createdDate = 1,
    lastUsed = 2,
}

export type TUserApiKeyUpdateModel = Readonly<{
    name: string;
}>;

export type TUserApiKeyFilter = Readonly<{
    sortField: ApiKeySearchField;
    sortOrder: SortOrder;
}>;

export enum CreateUserApiKeyResultEnum {
    success = 1,
    alreadyExists = 2,
}
export type TCreateUserApiKeyResult = Readonly<{
    result: CreateUserApiKeyResultEnum;
}>;

export type TUserApiKeyUsageData = Readonly<{
    id: number;
    name: string;
    calls: number;
}>;

export type TUserApiKeyUsageDataList = ReadonlyArray<TUserApiKeyUsageData>;

export enum ApiKeyUsageSearchField {
    name = 0,
    calls = 1,
}

export type TUserApiKeyUsageFilter = Readonly<{
    sortField: ApiKeyUsageSearchField;
    sortOrder: SortOrder;
    periodDays: number;
}>;

export type TReloadUsers = (data?: Readonly<{
    currentPage?: number;
    clearPageSize?: boolean | null;
    criteriaData?: Partial<TCriteriaData>;
}>) => void;

export type TPayloadGetNewAgentInboundReferralData = Readonly<{
    userId?: number;
}>;

export type TResponseGetNewAgentInboundReferralData = Readonly<{
    states: TUsStateList;
}> & TStateReferralNewInbound;


export type TPayloadGetAgentInboundReferral = Readonly<{
    referralId: number;
}>;

export type TUpdateReferralContactStatusData = Readonly<{
    status: UpdateReferralStatus;
    message?: string | null;
    date?: string | null;
    price?: number | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    stateId?: number | null;
    zip?: string | null;
}>;

export type TPropsRequiredField = Readonly<{
    typeDisplay?: 'first' | 'all';
    colorsErrorText?: TColorsProp | null;
    errorDataList?: TErrorDataList | null;
    paddingTextError?: string | null;
    marginTextError?: string | null;
}>;

export type TErrorData = Readonly<{
    text: string;
    isVisible: boolean;
}>;

export type TErrorDataList = ReadonlyArray<TErrorData>;


export type TSendInviteAgentData = Readonly<{
    userId?: number;
    referralId: number;
    
    buyLocations?: string[];
    buyPropertyTypes: number[];
    buyTimeFrame?: number;
    buyMaximumPrice?: number;
    buyMinimumPrice?: number;
    buyNote?: string;
    
    sellLocation?: string;
    sellPropertyType?: number;
    sellTargetPrice?: number
    sellTimeFrame?: number;
    sellNote?: string;
    
    email: string;
    firstName: string;
    lastName: string;
    message: string;
    phone: string;
    
    clientPreapproved: boolean;
    referralSource: number;
    referralType: number;
}>;

export type TPayloadSendInviteAgentData = Readonly<{
    referralSourceData: TReferralSourceData;
    referralTypeData: TReferralTypeData;
    inviteData: TClientInformationDataWithMessage;
    userId?: number;
    referralId: number;
}>;
