import { createBody } from './create-body';
import { fetch, fetchNApi } from './fetch';

export const put = <T = unknown>(
    url: string,
    data: unknown = null,
    init: RequestInit | null = null,
    isAnon: boolean = false,
): Promise<T> => fetch<T>(
    url,
    {
        method: 'PUT',
        body: createBody(data),
        ...init,
    },
    isAnon,
);

export const putNApi = <T = unknown>(
    url: string,
    data: unknown = null,
    init: RequestInit | null = null,
    isAnon: boolean = false,
): Promise<T> => fetchNApi<T>(
    url,
    {
        method: 'PUT',
        body: createBody(data),
        ...init,
    },
    isAnon,
);