import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import Modal from 'react-modal';
import { WaitContainer } from "./WaitContainer";

type Props = {
    enabledGenericActionModal: boolean;
    setEnabledGenericActionModal: Function;
    genericActionTitle: string;

    genericActionMessage: string;
    genericActionMessagePlaceholder?: string;
    setGenericActionMessage: Function;

    genericActionFunction: MouseEventHandler<any>;
    genericActionButtonLabel: string;

    styleOverride?: any,

    isPerformingAction: boolean;

    children?: React.ReactNode;
}
const GenericActionModal: FunctionComponent<Props> = ({
    enabledGenericActionModal,
    setEnabledGenericActionModal,
    
    genericActionMessagePlaceholder,
    genericActionMessage,
    setGenericActionMessage,
    
    genericActionTitle, genericActionFunction, genericActionButtonLabel,

    styleOverride,

    isPerformingAction,

    children,
}) => {
    const MAX_GENERIC_ACTION_CHARS = 500;
    const genericActionModalStyle = {
        overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '15%',
            margin: '0 auto',
            width: '600px',
            height: '500px',
            borderRadius: '16px',
            overflow: 'none',
        },
    };
    const [genericActionMessageCharsLeft, setGenericActionMessageCharsLeft] = useState<number>(MAX_GENERIC_ACTION_CHARS);

    const genericActionMessageLengthCheck = (e): void => {
        const str = e.target.value;
        setGenericActionMessage(str);
        setGenericActionMessageCharsLeft(MAX_GENERIC_ACTION_CHARS - str.length);
    }

    return <Modal
                isOpen={enabledGenericActionModal}
                contentLabel={genericActionTitle}
                style={styleOverride ?? genericActionModalStyle}
                ariaHideApp={false}
            >
                <div className="flex flex-col h-[95%] gap-0 p-[10px]">
                    <div className="flex flex-col gap-[20px]">                    
                        <h2 className="flex items-center justify-start">{genericActionTitle}</h2>

                        <div className={`flex flex-col justify-start gap-[10px]`}>
                            {children}
                            <textarea className="flex resize-none overflow-y-auto text-md font-sans bg-light-background-background-1 rounded-[5px] py-2.5 px-[10px] items-center justify-start text-left border border-solid border-gray-400 outline-0"
                                rows={8}
                                cols={50}
                                maxLength={MAX_GENERIC_ACTION_CHARS}
                                onChange={genericActionMessageLengthCheck}
                                placeholder={genericActionMessagePlaceholder || "Add additional details here ..."}
                                value={genericActionMessage}
                                disabled={!enabledGenericActionModal}
                            />
                            <span>{genericActionMessageCharsLeft} characters left</span>
                        </div>
                        
                        <div className="self-stretch w-full flex flex-row justify-end">
                            <WaitContainer shouldWaitIf={isPerformingAction}>
                                <div className="flex flex-row gap-[20px]">
                                    <button
                                        className="items-center justify-center cursor-pointer w-[100px] [border:none] rounded-md h-10 box-border gap-[10px]"
                                        onClick={() => setEnabledGenericActionModal(false)}
                                    >
                                        <div className="relative text-sm leading-[21px] font-semibold font-large-med text-secondary text-center">
                                            Cancel
                                        </div>
                                    </button>
                                    <button
                                        className={`bg-secondary items-center justify-center cursor-pointer [border:none] rounded-md h-10 box-border px-[20px] gap-[10px]`}
                                        onClick={genericActionFunction}
                                    >
                                        <div className="relative text-sm leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center">
                                            {genericActionButtonLabel ?? 'Submit'}
                                        </div>
                                    </button>
                                </div>
                            </WaitContainer>
                        </div>
                        
                    </div>
                </div>
            </Modal>
};

export default GenericActionModal;
