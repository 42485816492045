import React, { FunctionComponent, RefObject } from "react";
import Select from "react-select";
import BuyerLocationsInput from "./BuyerLocationsInput";
// eslint-disable-next-line import/no-cycle
import { NewReferralFormStoreType, type LocationType } from "../NewReferralOutbound";
import { ReferralPropertyType } from "../../../types";
import { toCurrency } from "../../../utils/to-currency";
import { globalReactSelectTheme } from "../../../constants/react-select-theme";


type Step3BuyerPropsType = {
    formDataRef: RefObject<NewReferralFormStoreType>;
    buyerLocations: Array<LocationType>;
    buyerLocationsReadOnly: boolean;
    onLocationsUpdated: (locations: Array<LocationType>) => void;
    onChangeBuyerPropertyType: (any) => void;
    onChangeBuyerTimeFrame: (any) => void;
    onChangeBuyerMinPrice: (number) => void;
    onChangeBuyerMaxPrice: (number) => void;
    onChangeBuyerComments: (any) => void;
    onChangeClientPreApproved: (any) => void;
};

const Step3ReferralTypeBuyer: FunctionComponent<Step3BuyerPropsType> = ({
    formDataRef,
    buyerLocations,
    buyerLocationsReadOnly,
    onLocationsUpdated,
    onChangeBuyerPropertyType,
    onChangeBuyerTimeFrame,
    onChangeBuyerMinPrice,
    onChangeBuyerMaxPrice,
    onChangeBuyerComments,
    onChangeClientPreApproved,
}) => {

    const clientPreApproved = formDataRef.current?.form.client.clientPreapproved;
    const formDataStep = formDataRef.current?.form.referralType.buyer;
    console.log("Step3-Buyer: FormDataStep: ", formDataStep);

    onLocationsUpdated(formDataStep?.locations as LocationType[]);

    const formatAndUpdateMinPrice = (event): void => {
        const strValue = event.target.value;
        if (strValue === '') {
            onChangeBuyerMinPrice(-1);
        }
        const intValue = parseInt(strValue.replace(/\D/g, ''), 10);
        if (intValue <= 99999999) {
            onChangeBuyerMinPrice(intValue);
        }
    }

    const formatAndUpdateMaxPrice = (event): void => {
        const strValue = event.target.value;
        if (strValue === '') {
            onChangeBuyerMaxPrice(-1);
        }
        const intValue = parseInt(strValue.replace(/\D/g, ''), 10);
        if (intValue <= 99999999) {
            onChangeBuyerMaxPrice(intValue);
        }
    }

    const toCurrencyInline = (event): void => {
        const str = event.target.value;
        event.target.value = toCurrency(str);
    }

    const buyTimeFrameOptions = [
        { label: 'Within 30 days ', value: 1 },
        { label: '30 - 90 days ', value: 2 },
        { label: 'After 90 days ', value: 3 },
        { label: 'Unknown', value: 4 }
    ];

    return (
        <div className="self-stretch flex flex-col py-0 pr-0 pl-3 items-start justify-start rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]">
            <div className="self-stretch rounded-lg flex flex-col p-6 items-start justify-start gap-[30px]">
                <span className="text-xl">Buyer Referral</span>

                {/* Client Pre-Approved */}
                <div className="flex flex-col items-start justify-start gap-[4px]">
                    <div className="relative tracking-[0.1px] leading-[24px]">
                        <span className="font-semibold">Client Pre-Approved?</span>
                        <span className="text-brand">*</span>
                    </div>
                    <div className="flex flex-row items-start justify-start gap-[20px]">
                        <div className="cursor-pointer flex flex-row items-center justify-start gap-[12px]">
                            <label className="relative cursor-pointer flex flex-row items-center leading-[24px] gap-[8px]">
                                <input
                                    className="order-5 cursor-pointer relative w-4 h-4"
                                    type="radio"
                                    name="preApproved"
                                    value="yes"
                                    id="clientApprovedYes"
                                    tabIndex={5}
                                    onClick={onChangeClientPreApproved}
                                    defaultChecked={clientPreApproved}
                                    required
                                />
                                Yes
                            </label>
                        </div>
                        <div className="cursor-pointer flex flex-row items-center justify-start gap-[12px]">
                            <label className="relative cursor-pointer flex flex-row items-center leading-[24px] gap-[8px]">
                                <input
                                    className="order-6 cursor-pointer relative w-4 h-4"
                                    type="radio"
                                    name="preApproved"
                                    value="no"
                                    id="clientApprovedNo"
                                    tabIndex={6}
                                    onClick={onChangeClientPreApproved}
                                    defaultChecked={!clientPreApproved}
                                />
                                No
                            </label>
                        </div>
                    </div>
                </div>

                {/* Location Preferences */}
                <div className="self-stretch rounded flex flex-col items-start justify-start">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                        <div className="relative">
                            <span className="tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Location Preferences</span>
                                <span className="font-special text-slategray">*</span>
                            </span>
                            <span className="text-sm">
                                <span className="tracking-[0.1px] leading-[24px]">
                                    <span className="font-semibold font-special">{`  `}</span>
                                </span>
                                <span className="leading-[21px]">
                                    (enter one or more city or zip code)
                                </span>
                            </span>
                        </div>
                        <BuyerLocationsInput
                            key="buyerInfo.buyerinput"
                            onLocationsUpdated={onLocationsUpdated}
                            locations={buyerLocations}
                            readonly={buyerLocationsReadOnly}
                            required
                        />
                    </div>
                </div>

                <div className="self-stretch flex flex-row items-start justify-start gap-[20px]">

                    {/* Property Types */}
                    <div className="w-[28%] flex flex-col items-start justify-start">
                        <div className="w-full flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Property Type</span>
                                <span className="text-brand">*</span>
                            </div>
                            <div className="self-stretch flex flex-row pt-3 pb-0 pr-0 pl-[5px] items-start justify-start">
                                <div className="w-[50%] flex flex-col items-start justify-start gap-[16px]">
                                    <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                        <input
                                            className="cursor-pointer relative w-4 h-4"
                                            type="checkbox"
                                            name="singleFamily"
                                            value={ReferralPropertyType.singleFamily}
                                            defaultChecked={formDataStep?.propertyTypes.includes(ReferralPropertyType.singleFamily)}
                                            onChange={onChangeBuyerPropertyType}
                                        />
                                        <div className="relative tracking-[0.1px] leading-[24px]">
                                            Single Family
                                        </div>
                                    </label>
                                    <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                        <input
                                            className="cursor-pointer relative w-4 h-4"
                                            type="checkbox"
                                            value={ReferralPropertyType.condominium}
                                            defaultChecked={formDataStep?.propertyTypes.includes(ReferralPropertyType.condominium)}
                                            onChange={onChangeBuyerPropertyType}
                                        />
                                        <div className="relative tracking-[0.1px] leading-[24px]">
                                            Condo
                                        </div>
                                    </label>
                                    <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                        <input
                                            className="cursor-pointer relative w-4 h-4"
                                            type="checkbox"
                                            value={ReferralPropertyType.townhome}
                                            defaultChecked={formDataStep?.propertyTypes.includes(ReferralPropertyType.townhome)}
                                            onChange={onChangeBuyerPropertyType}
                                        />
                                        <div className="relative tracking-[0.1px] leading-[24px]">
                                            Townhome
                                        </div>
                                    </label>
                                </div>
                                <div className="w-[40%] flex-1 flex flex-col items-start justify-start gap-[16px]">
                                    <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                        <input
                                            className="cursor-pointer relative w-4 h-4"
                                            type="checkbox"
                                            value={ReferralPropertyType.multiFamily}
                                            defaultChecked={formDataStep?.propertyTypes.includes(ReferralPropertyType.multiFamily)}
                                            onChange={onChangeBuyerPropertyType}
                                        />
                                        <div className="relative tracking-[0.1px] leading-[24px]">
                                            Multi-Family
                                        </div>
                                    </label>
                                    <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                        <input
                                            className="cursor-pointer relative w-4 h-4"
                                            type="checkbox"
                                            value={ReferralPropertyType.farmRanch}
                                            defaultChecked={formDataStep?.propertyTypes.includes(ReferralPropertyType.farmRanch)}
                                            onChange={onChangeBuyerPropertyType}
                                        />
                                        <div className="relative tracking-[0.1px] leading-[24px]">
                                            Farm/Ranch
                                        </div>
                                    </label>
                                    <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                        <input
                                            className="cursor-pointer relative w-4 h-4"
                                            type="checkbox"
                                            value={ReferralPropertyType.lotsLand}
                                            defaultChecked={formDataStep?.propertyTypes.includes(ReferralPropertyType.lotsLand)}
                                            onChange={onChangeBuyerPropertyType}
                                        />
                                        <div className="relative tracking-[0.1px] leading-[24px]">
                                            Lots/Land
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* TimeFrame and Buy Price Range */}
                    <div className="w-[40%] flex flex-col items-start justify-start gap-[20px]">

                        <div className="self-stretch w-full flex flex-col items-start justify-start gap-[8px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Time-frame</span>
                                <span className="text-brand">*</span>
                            </div>
                            <Select
                                className="w-[47%]"
                                onChange={onChangeBuyerTimeFrame}
                                theme={globalReactSelectTheme}
                                options={buyTimeFrameOptions}
                                defaultValue={buyTimeFrameOptions.find((o) => o.value === formDataStep?.timeFrame as number)}
                                required
                            />
                        </div>

                        <div className="self-stretch w-full flex flex-row items-start justify-start gap-[20px]">

                            <div className="w-[45%] flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                    <span className="font-semibold">Minimum Price</span>
                                    <span className="text-brand">*</span>
                                </div>
                                <input
                                    className="font-special text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    name="buyMinPrice"
                                    type="text"
                                    maxLength={12}
                                    onKeyUp={toCurrencyInline}
                                    // onBlur={formatAndUpdateMinPrice}
                                    onChange={formatAndUpdateMinPrice}
                                    defaultValue={formDataStep?.minPrice === -1 ? '' : toCurrency(formDataStep?.minPrice)}
                                    required
                                />
                            </div>

                            <div className="w-[45%] flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                    <span className="font-semibold">Maximum Price</span>
                                    <span className="text-brand">*</span>
                                </div>
                                <input
                                    className="font-special text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    name="buyMaxPrice"
                                    type="text"
                                    maxLength={12}
                                    onKeyUp={toCurrencyInline}
                                    // onBlur={formatAndUpdateMaxPrice}
                                    onChange={formatAndUpdateMaxPrice}
                                    defaultValue={formDataStep?.maxPrice === -1 ? '' : toCurrency(formDataStep?.maxPrice)}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* Comments */}
                    <div className="w-[30%] flex-1 flex flex-col items-start justify-start">
                        <div className="self-stretch h-[163px] flex flex-col items-start justify-start gap-[3px]">
                            <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                Other details or comments
                            </div>
                            <textarea
                                className="bg-light-background-background-1 rounded-[5px] font-sans text-base self-stretch flex-1 flex flex-col p-4 items-start justify-start border-[1px] border-solid border-gray-400 resize-none"
                                placeholder="Example: Beach View"
                                // onBlur={onChangeBuyerComments}
                                onChange={onChangeBuyerComments}
                                defaultValue={formDataStep?.comments}
                                autoComplete="off"
                                maxLength={150}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step3ReferralTypeBuyer;
