import React, { FunctionComponent } from "react"

type MRAVerifiedLogoProps = {
    sizePx?: number;
};

const MRAVerifiedLogo: FunctionComponent<MRAVerifiedLogoProps> = ({ sizePx = 24 }) => {
    return (
        <img
            className="relative items-center"
            alt="[MRA Verified Logo]"
            src="/img/mra-newref-out/iconverified.svg"
            width={sizePx}
            height={sizePx}
            title="Realtor license is verified"
        />
    )
}

export { MRAVerifiedLogo };