import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { auth0Audience } from "../../App";
import { Logout } from "../../_Authenticated/Logout";
import { useParams, useSearchParams } from "react-router-dom";
import { WaitSpinner } from "../../components/WaitSpinner";

const RegistrationOptions: React.FunctionComponent = () => {

    const { loginWithRedirect, logout } = useAuth0();

    const onGoogleClick = (): void => {
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: `${window.location.origin}/auth`,
                audience: auth0Audience,
                prompt: 'select_account',
                connection: 'google-oauth2'
            }
        });
    }

    const onEmailClick = (): void => {
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: `${window.location.origin}/auth`,
                audience: auth0Audience,
                prompt: 'select_account',
                screen_hint: 'signup'
            }
        });
    }

    const onLoginClick = (): void => {
        // logout();
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: `${window.location.origin}/auth`,
                audience: auth0Audience,
                prompt: 'select_account',
            }
        });
    }

    const [isEmailVerifiedStatus, setIsEmailVerifiedStatus] = useState<boolean>(false);

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const message = searchParams.get("message");
        let timeout;
        
        if (message === "Your email was verified. You can continue using the application.") {
            setIsEmailVerifiedStatus(true);

            toast.info("Email address is verified. Proceeding ... ");

            timeout = setTimeout(onLoginClick, 4000);
        }
        else if (message?.includes("This URL can be used only once")) {
            
            toast.info("Already verified email! Proceed to login!");

            timeout = setTimeout(onLoginClick, 2000);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, []);

    return (
        <div className="flex flex-grow items-top justify-center ">
            <div className="w-full flex flex-row xs:flex-col lg:flex-row justify-center">

                {/* left pane */}
                <div className="flex flex-1 flex-col justify-top pr-[30px] py-[10px] bg-brown">
                    <div className="ml-[30px] flex flex-col items-center justify-between text-white">
                        <div className="flex flex-1 flex-col xs:px-[16px] xs:py-[50px] lg:pt-[113px] items-center justify-between text-white bg-brown">
                            <div className="xs:w-fit lg:w-[450px] flex flex-col justify-center items-center gap-[50px]">

                                <div className="relative xs:text-21xl lg:text-37xl xs:leading-[40px] lg:leading-[62px] inline-block">
                                    Send, receive and track referrals with some of the best real estate
                                    agents on earth
                                </div>

                                <div className="self-stretch relative text-lg leading-[25px] font-dm-sans justify-start">
                                    We&apos;d love for you to join us!
                                </div>

                                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] font-dm-sans">
                                    <div className="flex flex-row items-center justify-start">
                                        <div className="relative rounded-21xl w-9 h-9">
                                            {/* <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white" /> */}
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image1@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image2@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image3@2x.png"
                                            />
                                        </div>
                                    </div>
                                    <div className="relative leading-[150%] font-sm">
                                        {/* Join 2.5M+ users */}
                                        Be part of an elite group of referrers!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* right pane */}
                <div className="flex flex-1 flex-col justify-top pr-[30px] py-[10px] bg-surface-secondary">
                    <div className="ml-[30px] flex flex-col items-center justify-between">
                        <div className="flex flex-1 flex-col xs:px-[16px] xs:py-[50px] lg:pt-[113px] items-center justify-between">
                            
                            {isEmailVerifiedStatus
                                ? <div className="xs:w-fit lg:w-[450px] flex flex-col justify-center items-center gap-[12px] ">

                                    <div className="flex flex-col items-end justify-start">
                                        <b className="relative text-xl tracking-[-0.24px] leading-[26px] font-small-medium text-colors-gs-brand-secondary text-center">
                                            Quickly exchange referrals with top agents
                                            <br /><br />
                                            {/* Sign up with MRA! */}
                                        </b>
                                    </div>

                                    <div className="flex flex-col gap-[12px]">
                                        <div className="flex w-full text-secondary items-center justify-center text-lg" >
                                            Thank you for verifying your email address.
                                            <br /><br />
                                            Processing ... 
                                            <br /><br />
                                        </div>
                                        <WaitSpinner sizePx={60}/>
                                    </div>
                                    
                                </div>
                                : <div className="xs:w-fit lg:w-[500px] flex flex-col justify-center items-center gap-[12px] text-secondary">
                                    {/* Text Content: Signing up takes less than .... blah */}
                                    <div className="flex flex-col items-end justify-start">
                                        <div className="flex flex-col gap-[10px] relative leading-[26px] text-secondary font-thin text-center">
                                            <span className="text-10xl leading-[32px]">
                                                Signing up takes <span className="font-semibold">less than a minute</span> and can lead to <span className="font-semibold">extra referrals every month!</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h-5"></div>
                                    <div className="flex flex-col gap-[20px] justify-center items-center">
                                        {/* Have your license ready */}
                                        <div>Have your real estate <span className="font-semibold">State License/Registration Number</span> handy.</div>

                                        {/* Create an account button */}
                                        <div className="flex flex-col gap-[12px]">
                                            <div
                                                className="rounded-[33.08px] bg-[#394a55] w-[320px] h-[68px] flex flex-row py-0 px-[20px] box-border items-center justify-center gap-[12.6px] cursor-pointer"
                                                onClick={onEmailClick}
                                            >
                                                <div className="relative text-xl font-semibold text-white text-center">
                                                    Create an account
                                                </div>
                                            </div>
                                        </div>

                                        {/* ---- OR ---- */}
                                        <div className="flex flex-row gap-[20px] text-secondary items-center">
                                            <hr className="flex w-[80px] h-[2px] p-0 bg-gray-400 border-0" />
                                            <div className="flex flex-col gap-[10px] relative tracking-[-0.24px] leading-[26px] text-secondary font-thin text-center">
                                                OR
                                            </div>
                                            <hr className="flex w-[80px] h-[2px] p-0 bg-gray-400 border-0" />
                                        </div>

                                        {/* Already have an mra account */}
                                        <div className="flex flex-col items-end justify-start">
                                            <div className="flex flex-col gap-[10px] relative text-secondary font-thin text-center">
                                                <span>Already have an MRA account?</span>
                                            </div>
                                        </div>

                                        {/* Sign In Button */}
                                        <div className="flex flex-col">
                                            <div
                                                className="border-[4px] border-solid border-secondary rounded-[33.08px] bg-white w-[320px] h-[68px] flex flex-row py-0 px-[20px] box-border items-center justify-center gap-[10px] cursor-pointer"
                                                onClick={onLoginClick}
                                            >
                                                <div className="relative text-xl font-semibold text-secondary text-center">
                                                    Sign in
                                                </div>
                                            </div>
                                        </div>
                                        <div className='h-[50px]' />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export { RegistrationOptions };
