import React, { FunctionComponent, useState, useEffect, ReactElement, useMemo, useCallback, useRef } from "react";
// import { useSelector } from "react-redux";
import _ from "lodash";
import { ReferralType, TPublicProfileView, TSearchAgentQuery, TSearchServiceAreaModelList, TUserDataList, UserLocationSpecialtyType } from "../../../types";
import type { LocationType } from "../NewReferralOutbound";
import { MRAVerifiedLogo } from "../../../components/MRAVerifiedLogo";
import { getDashboardUsers, postReferralsUsersRequest } from "../../../utils/api";
import WarningYellowIcon from "../../../components/WarningYellowIcon";
import MemberSearchResultCard from "./MemberSearchResultCard";
import { WaitSpinner } from "../../../components/WaitSpinner";
import { useDeepCompareEffect } from "../../../utils/use-deep-compare-effect";
import useZustandAuthStore from "../../../_Zustand/auth-store";

type MemberSearchResultsProps = {
    referralType: ReferralType;
    buyerLocations: Array<LocationType>;
    onChangeSearchCandidates: (any) => void;
};

const MemberSearchResults: FunctionComponent<MemberSearchResultsProps> = ({
    referralType,
    buyerLocations,
    onChangeSearchCandidates,
}) => {
    
    const { authState, usStates } = useZustandAuthStore();
    const userId = authState.id;
    
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
    const [verifiedMembersFound, setVerifiedMembersFound] = useState<TPublicProfileView[]>([]);
    const [membersFound, setMembersFound] = useState<TPublicProfileView[] & any>([]);
    const [cardSelected, setMemberCardsSelected] = useState<Array<any>>([]);

    const setLocalStateMemberList = (agents): void => {
        if (agents && Array.isArray(agents) && agents.length > 0) {
            setMemberCardsSelected(agents.map((a) => ({id: a.id, selected: false})));
            setMembersFound(agents);
    
            // TODO: Segregate by Verified and Unverified
            setVerifiedMembersFound(agents);
        }
    };

    const getMembersByBuyerLocation = (buyerLocations): void => {

        console.log("here");

        const serviceAreas = buyerLocations.map((l) => { return {
            type: !l.zipcode ? UserLocationSpecialtyType.city : UserLocationSpecialtyType.zipCode,
            stateId: usStates?.find((s) => s.abbreviation === l.stateAbbreviation)?.id || null,
            zip: l.zipcode || null,
            city: l.city || null,
            county: null,
        };}) as TSearchServiceAreaModelList;

        const agentQuery: TSearchAgentQuery = {
            serviceAreas,
            page: 1,
            pageSize: 25,
            userId,
            sortField: 0,
        };

        setIsSearchLoading(true);
        postReferralsUsersRequest(agentQuery).then((response) => {
            const {agents} = response;
            const agentsFiltered = agents.filter((a) => a.id !== userId);
            console.log(agentsFiltered);

            getDashboardUsers(agentsFiltered.map((a) => a.id)).then((result: any) => {
                setIsSearchLoading(false);
                console.log(result);
                // const mergedInfo = agentsFiltered.map((a) => {return {...a, ...result.find((r) => r.userId === a.id)};})
                // console.log("MergedInfo: ", result);
                setLocalStateMemberList(result);
            }).catch((error) => {
                setIsSearchLoading(false);
                console.error(error)
            });
        });
    };

    useDeepCompareEffect(() => {
        
        if(buyerLocations.length > 0) {
            getMembersByBuyerLocation(buyerLocations);
        }
        else {
            setLocalStateMemberList([]);
            onChangeSearchCandidates([]);
        }

    }, [buyerLocations]);

    const pickMember = (member): void => {
        console.log("Member Selected: ", member.firstName);
        
        const selectedArr = cardSelected.map((m) => ({...m, selected: false})) ;
        selectedArr.find((m) => m.id === member.id).selected = true;
        setMemberCardsSelected(selectedArr);

        onChangeSearchCandidates([member]);
    };

    // const genMemberSelector = (member): ReactElement => <input
    //     className="cursor-pointer relative w-4 h-4"
    //     type="radio"
    //     name="memberSelectionRadio"
    //     onClick={() => pickMember(member)}
    //     required
    // />

    return (
        <div className="self-stretch rounded-lg bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[16px] text-left text-lg text-primary font-h5-semibold">
                
            {/* Verified MRA Members */}
            <div className="self-stretch rounded-lg bg-light-background-background-1 flex flex-col p-4 items-start justify-start gap-[16px] text-left text-lg text-primary font-h5-semibold">
                {/* Verified MRA Members HEADER */}
                <div className="self-stretch flex flex-row items-center justify-start gap-[14px]">
                    <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
                        <div className="relative leading-[140%] font-medium flex flex-col gap-[10px]">
                            <div>
                                {!isSearchLoading && verifiedMembersFound.length > 0 &&
                                    <div className="flex items-center justify-center">
                                        {/* {verifiedMembersFound.length} MRA Verified&nbsp;<MRAVerifiedLogo sizePx={16} />&nbsp;Members Found */}
                                        {verifiedMembersFound.length} MRA Members Found
                                    </div>
                                }
                                {!isSearchLoading && verifiedMembersFound.length === 0 &&
                                <span className="flex flex-row gap-[10px] align-bottom">
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {(referralType === ReferralType.buy) &&
                                        ((buyerLocations.length > 0)
                                            ? <span>
                                                Sorry, No MRA Verified <MRAVerifiedLogo /> Members Found.
                                            </span>
                                            : <span className="flex items-center flex-row gap-[10px]">
                                                <WarningYellowIcon sizePx={25} /> Please provide Buyer location(s).
                                            </span>
                                        )
                                    }
                                    {(referralType === ReferralType.sell) &&
                                        ((buyerLocations.length > 0)
                                            ? <span>
                                                Sorry, No MRA Verified <MRAVerifiedLogo /> Members Found.
                                            </span>
                                            : <span>
                                                <WarningYellowIcon sizePx={25} /> Please provide Seller Address.
                                            </span>
                                        )
                                    }
                                </span>
                                }
                                {isSearchLoading && <div className="flex justify-center items-center flex-row gap-[16px]">
                                    <WaitSpinner sizePx={28} />
                                    <i>Finding MRA Members for the location(s) provided ... </i>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {/* Sorting */}
                    <div className="hidden flex flex-row items-center justify-end gap-[8px] text-base">
                        <img
                            className="relative w-5 h-5 overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-newref-out/iconsort2.svg"
                        />
                        <div className="relative tracking-[0.02em] leading-[24px]">
                            Sort by
                        </div>
                        <button
                            className="cursor-pointer py-2 px-4 bg-light-background-background-1 rounded-md box-border w-[165px] flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-gray-400"
                            onClick={() => {}}
                        >
                            <div className="flex-1 relative text-base tracking-[0.2px] leading-[24px] font-medium font-h5-semibold text-dark text-center">
                                Sales Volume
                            </div>
                            <img
                                className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                                alt=""
                                src="/img/mra-newref-out/iconchevrondownbold.svg"
                            />
                        </button>
                    </div>
                </div>

                {/* Verified MRA Members Search Results */}
                {!isSearchLoading && membersFound.length > 0 &&
                    <div className="self-stretch rounded-sm flex flex-row p-0 items-center justify-start ">
                        <div className="rounded-sm flex flex-row flex-wrap justify-start gap-[10px]">
                            {!isSearchLoading && membersFound.length > 0 && membersFound.map((m) =>
                                <MemberSearchResultCard
                                    key={`${m.id}`}
                                    member={m}
                                    // memberSelector={genMemberSelector(m)}
                                    isMemberSelected={cardSelected.find((c) => c.id === m.id).selected}
                                    licensesVerified={m.licensesVerified > 0}
                                    pickMember={pickMember}
                                />
                            )}
                        </div>
                    </div>
                }
            </div>

            {/* HIDDEN: SURROUNDING Verified MRA Members */}
            <div className="hidden self-stretch rounded-lg bg-light-background-background-1 flex flex-col p-4 items-start justify-start gap-[16px] text-left text-lg text-primary font-h5-semibold">
                {/* SURROUNDING Verified MRA Members HEADER */}
                <div className="self-stretch flex flex-row items-center justify-between">
                    <div className="relative">
                        <span className="leading-[140%] font-medium">
                            <span>3 MRA Verified Members</span>
                        </span>
                        <span>
                            <span className="leading-[140%] font-medium">{` `}</span>
                            <span className="leading-[26px] font-h5-semibold">
                                in the surrounding area
                            </span>
                        </span>
                    </div>
                </div>

                {/* SURROUNDING Verified MRA Members Search Results */}
                <div className="self-stretch rounded-sm flex flex-row items-center justify-center text-sm">
                    <div className="hidden flex-row py-3.5 px-3 items-center justify-start">
                        <img
                            className="relative w-[5.14px] h-[8.57px]"
                            alt=""
                            src="/img/mra-newref-out/vector-stroke7.svg"
                        />
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-center gap-[24px]">
                        <div className="flex-1 rounded-md bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-gray-300">
                            <div className="self-stretch flex flex-row items-start justify-start gap-[20px]">
                                <div className="flex-1 flex flex-col items-start justify-start">
                                    <div className="flex flex-row pt-0 px-0 pb-[7px] items-center justify-start gap-[4px] text-xs">
                                        <img
                                            className="relative w-20 h-4"
                                            alt=""
                                            src="/img/mra-newref-out/rating10.svg"
                                        />
                                        <div className="relative tracking-[0.26px] leading-[18px] font-medium">
                                            (11 Reviews)
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[8px] text-lg">
                                        <b className="relative leading-[25px]">
                                            Jill Lindstrand
                                        </b>
                                        <img
                                            className="relative w-6 h-6 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconfavorite11.svg"
                                        />
                                    </div>
                                    <div className="relative leading-[21px] opacity-[0.6]">
                                        EXP Realty LLC
                                    </div>
                                    <div className="h-[25px] flex flex-row items-center justify-start gap-[6px]">
                                        <img
                                            className="relative w-4 h-4 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconlocationdot6.svg"
                                        />
                                        <div className="relative leading-[21px] opacity-[0.6]">
                                            Myrtle Beach, SC
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded-11xl bg-gray-600 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-7 flex flex-row py-2 pr-3.5 pl-px box-border items-center justify-center">
                                    <div className="rounded-sm bg-light-background-background-1 w-[26px] h-[26px] flex flex-row p-1 box-border items-center justify-center">
                                        <img
                                            className="relative w-3 h-3 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconadd11.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px] text-center text-2xs">
                                <div className="w-[66px] h-[66.91px] flex flex-col items-center justify-start">
                                    <img
                                        className="relative w-[66px] h-[66px]"
                                        alt=""
                                        src="/img/mra-newref-out/avatar9.svg"
                                    />
                                </div>
                                <div className="rounded bg-surface-secondary w-[103px] flex flex-col py-2 px-3 box-border items-center justify-start gap-[4px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium">
                                        Sales Volume
                                    </div>
                                    <div className="self-stretch relative text-lg leading-[26px] font-semibold">
                                        56
                                    </div>
                                </div>
                                <div className="flex-1 rounded bg-surface-secondary flex flex-col py-2 px-3 items-center justify-start gap-[4px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium">
                                        2022 Transactions
                                    </div>
                                    <div className="self-stretch relative text-lg leading-[26px] font-semibold">
                                        56
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-xs">
                                <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-col py-1 px-2 items-start justify-start">
                                    <div className="self-stretch relative leading-[17px] opacity-[0.75]">
                                        Activity range
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        $90K - $350K
                                    </div>
                                </div>
                                <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-col py-1 px-2 items-start justify-start">
                                    <div className="self-stretch relative leading-[17px] opacity-[0.75]">
                                        Experience
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        20 years
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row py-1 px-0 items-center justify-start gap-[14px] text-center text-base text-tertiary">
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.2500056028366089px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-[10.000044822692871px] items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconpaperplane3.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconemail3.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconnotepad3.svg"
                                    />
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-end gap-[8px]">
                                    <div className="relative tracking-[0.02em] leading-[24px]">
                                        View profile
                                    </div>
                                    <img
                                        className="relative w-4 h-4"
                                        alt=""
                                        src="/img/mra-newref-out/iconarrowright13.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 rounded-md bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-gray-300">
                            <div className="self-stretch flex flex-row items-start justify-start gap-[20px]">
                                <div className="flex-1 flex flex-col items-start justify-start">
                                    <div className="flex flex-row pt-0 px-0 pb-[7px] items-center justify-start gap-[4px] text-xs">
                                        <img
                                            className="relative w-20 h-4"
                                            alt=""
                                            src="/img/mra-newref-out/rating11.svg"
                                        />
                                        <div className="relative tracking-[0.26px] leading-[18px] font-medium">
                                            (10 Reviews)
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[8px] text-lg">
                                        <b className="relative leading-[25px]">
                                            Laurent Perrier
                                        </b>
                                        <img
                                            className="relative w-6 h-6 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconfavorite12.svg"
                                        />
                                    </div>
                                    <div className="relative leading-[21px] opacity-[0.6]">
                                        Palms Realty
                                    </div>
                                    <div className="h-[25px] flex flex-row items-center justify-start gap-[6px]">
                                        <img
                                            className="relative w-4 h-4 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconlocationdot7.svg"
                                        />
                                        <div className="relative leading-[21px] opacity-[0.6]">
                                            Myrtle Beach, SC
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded-11xl bg-gray-600 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-7 flex flex-row py-2 pr-3.5 pl-px box-border items-center justify-center">
                                    <div className="rounded-sm bg-light-background-background-1 w-[26px] h-[26px] flex flex-row p-1 box-border items-center justify-center">
                                        <img
                                            className="relative w-3 h-3 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconadd12.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px] text-center text-2xs">
                                <div className="w-[66px] h-[66.91px] flex flex-col items-center justify-start">
                                    <img
                                        className="relative w-[66px] h-[66px]"
                                        alt=""
                                        src="/img/mra-newref-out/avatar10.svg"
                                    />
                                </div>
                                <div className="rounded bg-surface-secondary w-[103px] flex flex-col py-2 px-3 box-border items-center justify-start gap-[4px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium">
                                        Sales Volume
                                    </div>
                                    <div className="self-stretch relative text-lg leading-[26px] font-semibold">
                                        47
                                    </div>
                                </div>
                                <div className="flex-1 rounded bg-surface-secondary flex flex-col py-2 px-3 items-center justify-start gap-[4px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium">
                                        2022 Transactions
                                    </div>
                                    <div className="self-stretch relative text-lg leading-[26px] font-semibold">
                                        31
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-xs">
                                <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-col py-1 px-2 items-start justify-start">
                                    <div className="self-stretch relative leading-[17px] opacity-[0.75]">
                                        Activity range
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        $140K - $400K
                                    </div>
                                </div>
                                <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-col py-1 px-2 items-start justify-start">
                                    <div className="self-stretch relative leading-[17px] opacity-[0.75]">
                                        Experience
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        7 yrs 10 mo
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row py-1 px-0 items-center justify-start gap-[14px] text-center text-base text-tertiary">
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.2500056028366089px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-[10.000044822692871px] items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconpaperplane4.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconemail4.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconnotepad4.svg"
                                    />
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-end gap-[8px]">
                                    <div className="relative tracking-[0.02em] leading-[24px]">
                                        View profile
                                    </div>
                                    <img
                                        className="relative w-4 h-4"
                                        alt=""
                                        src="/img/mra-newref-out/iconarrowright14.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 rounded-md bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-gray-300">
                            <div className="self-stretch flex flex-row items-start justify-start gap-[20px]">
                                <div className="flex-1 flex flex-col items-start justify-start">
                                    <div className="flex flex-row pt-0 px-0 pb-[7px] items-center justify-start gap-[4px] text-xs">
                                        <img
                                            className="relative w-20 h-4"
                                            alt=""
                                            src="/img/mra-newref-out/rating12.svg"
                                        />
                                        <div className="relative tracking-[0.26px] leading-[18px] font-medium">
                                            (7 Reviews)
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[8px] text-lg">
                                        <b className="relative leading-[25px]">
                                            Michael Levi
                                        </b>
                                        <img
                                            className="relative w-6 h-6 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconfavorite13.svg"
                                        />
                                    </div>
                                    <div className="relative leading-[21px] opacity-[0.6]">
                                        Simmon Stratton Team
                                    </div>
                                    <div className="h-[25px] flex flex-row items-center justify-start gap-[6px]">
                                        <img
                                            className="relative w-4 h-4 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconlocationdot8.svg"
                                        />
                                        <div className="relative leading-[21px] opacity-[0.6]">
                                            Myrtle Beach, SC
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded-11xl bg-gray-600 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-7 flex flex-row py-2 pr-3.5 pl-px box-border items-center justify-center">
                                    <div className="rounded-sm bg-light-background-background-1 w-[26px] h-[26px] flex flex-row p-1 box-border items-center justify-center">
                                        <img
                                            className="relative w-3 h-3 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconadd13.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px] text-center text-2xs">
                                <div className="w-[66px] h-[66.91px] flex flex-col items-center justify-start">
                                    <img
                                        className="relative w-[66px] h-[66px]"
                                        alt=""
                                        src="/img/mra-newref-out/avatar11.svg"
                                    />
                                </div>
                                <div className="rounded bg-surface-secondary w-[103px] flex flex-col py-2 px-3 box-border items-center justify-start gap-[4px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium">
                                        Sales Volume
                                    </div>
                                    <div className="self-stretch relative text-lg leading-[26px] font-semibold">
                                        32
                                    </div>
                                </div>
                                <div className="flex-1 rounded bg-surface-secondary flex flex-col py-2 px-3 items-center justify-start gap-[4px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[17px] font-medium">
                                        2022 Transactions
                                    </div>
                                    <div className="self-stretch relative text-lg leading-[26px] font-semibold">
                                        42
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-xs">
                                <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-col py-1 px-2 items-start justify-start">
                                    <div className="self-stretch relative leading-[17px] opacity-[0.75]">
                                        Activity range
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        $240K - $670K
                                    </div>
                                </div>
                                <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-col py-1 px-2 items-start justify-start">
                                    <div className="self-stretch relative leading-[17px] opacity-[0.75]">
                                        Experience
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        4 yrs 2 mo
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row py-1 px-0 items-center justify-start gap-[14px] text-center text-base text-tertiary">
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.2500056028366089px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-[10.000044822692871px] items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconpaperplane5.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconemail5.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconnotepad5.svg"
                                    />
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-end gap-[8px]">
                                    <div className="relative tracking-[0.02em] leading-[24px]">
                                        View profile
                                    </div>
                                    <img
                                        className="relative w-4 h-4"
                                        alt=""
                                        src="/img/mra-newref-out/iconarrowright15.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row py-3.5 px-3 items-center justify-start">
                        <img
                            className="relative w-[5.14px] h-[8.57px]"
                            alt=""
                            src="/img/mra-newref-out/vector-stroke8.svg"
                        />
                    </div>
                </div>
            </div>

            {/* HIDDEN: ADDITIONAL UN-Verified MRA Members */}
            <div className="hidden self-stretch rounded-lg bg-light-background-background-1 flex flex-col p-4 items-start justify-start gap-[16px] text-left text-lg text-primary font-h5-semibold">
                {/* ADDITIONAL UN-Verified MRA Members HEADER */}
                <div className="self-stretch flex flex-row items-center justify-between">
                    <div className="relative leading-[140%] font-medium">
                        5 Additional Members Found
                    </div>
                </div>

                {/* ADDITIONAL UN-Verified MRA Members Search Results */}
                <div className="self-stretch rounded-sm flex flex-row items-center justify-center">
                    <div className="hidden flex-row py-3.5 px-3 items-center justify-start">
                        <img
                            className="relative w-[5.14px] h-[8.57px]"
                            alt=""
                            src="/img/mra-newref-out/vector-stroke9.svg"
                        />
                    </div>
                    <div className="flex-1 flex flex-row items-start justify-center gap-[24px]">
                        <div className="flex-1 rounded-md bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-gray-300">
                            <div className="self-stretch flex flex-row items-start justify-between">
                                <div className="flex flex-col items-start justify-start">
                                    <div className="hidden flex-row items-center justify-start">
                                        <img
                                            className="relative w-20 h-4"
                                            alt=""
                                            src="/img/mra-newref-out/rating6.svg"
                                        />
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[12px]">
                                        <b className="relative leading-[25px]">
                                            David Palms
                                        </b>
                                        <img
                                            className="relative w-6 h-6 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconfavorite6.svg"
                                        />
                                    </div>
                                    <div className="relative text-sm leading-[21px] opacity-[0.6]">
                                        Palms Realty
                                    </div>
                                </div>
                                <div className="rounded-11xl bg-gray-600 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-7 flex flex-row py-2 pr-3.5 pl-px box-border items-center justify-center">
                                    <div className="rounded-sm bg-light-background-background-1 w-[26px] h-[26px] flex flex-row p-1 box-border items-center justify-center">
                                        <img
                                            className="relative w-3 h-3 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconadd7.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded flex flex-row py-1 px-0 items-center justify-start gap-[14px] text-smi text-tertiary">
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.2500056028366089px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-[10.000044822692871px] items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconpaperplane3.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconemail3.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconnotepad3.svg"
                                    />
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                                    <div className="relative">View profile</div>
                                    <img
                                        className="relative w-4 h-4 hidden"
                                        alt=""
                                        src="/img/mra-newref-out/iconarrowright16.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 rounded-md bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-gray-300">
                            <div className="self-stretch flex flex-row items-start justify-between">
                                <div className="flex flex-col items-start justify-start">
                                    <div className="hidden flex-row items-center justify-start">
                                        <img
                                            className="relative w-20 h-4"
                                            alt=""
                                            src="/img/mra-newref-out/rating7.svg"
                                        />
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[12px]">
                                        <b className="relative leading-[25px]">
                                            Susan Swain
                                        </b>
                                        <img
                                            className="relative w-6 h-6 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconfavorite7.svg"
                                        />
                                    </div>
                                    <div className="relative text-sm leading-[21px] opacity-[0.6]">
                                        Niles Realty Group
                                    </div>
                                </div>
                                <div className="rounded-11xl bg-gray-600 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-7 flex flex-row py-2 pr-3.5 pl-px box-border items-center justify-center">
                                    <div className="rounded-sm bg-light-background-background-1 w-[26px] h-[26px] flex flex-row p-1 box-border items-center justify-center">
                                        <img
                                            className="relative w-3 h-3 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconadd8.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded flex flex-row py-1 px-0 items-center justify-start gap-[14px] text-smi text-tertiary">
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.2500056028366089px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-[10.000044822692871px] items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconpaperplane6.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconemail6.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconnotepad6.svg"
                                    />
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                                    <div className="relative">View profile</div>
                                    <img
                                        className="relative w-4 h-4 hidden"
                                        alt=""
                                        src="/img/mra-newref-out/iconarrowright17.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 rounded-md bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-gray-300">
                            <div className="self-stretch flex flex-row items-start justify-between">
                                <div className="flex flex-col items-start justify-start">
                                    <div className="hidden flex-row items-center justify-start">
                                        <img
                                            className="relative w-20 h-4"
                                            alt=""
                                            src="/img/mra-newref-out/rating8.svg"
                                        />
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[12px]">
                                        <b className="relative leading-[25px]">
                                            Chris Henrick
                                        </b>
                                        <img
                                            className="relative w-6 h-6 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconfavorite8.svg"
                                        />
                                    </div>
                                    <div className="relative text-sm leading-[21px] opacity-[0.6]">
                                        Rowles Real Estate
                                    </div>
                                </div>
                                <div className="rounded-11xl bg-gray-600 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-7 flex flex-row py-2 pr-3.5 pl-px box-border items-center justify-center">
                                    <div className="rounded-sm bg-light-background-background-1 w-[26px] h-[26px] flex flex-row p-1 box-border items-center justify-center">
                                        <img
                                            className="relative w-3 h-3 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconadd9.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded flex flex-row py-1 px-0 items-center justify-start gap-[14px] text-smi text-tertiary">
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.2500056028366089px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-[10.000044822692871px] items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconpaperplane7.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconemail7.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconnotepad7.svg"
                                    />
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                                    <div className="relative">View profile</div>
                                    <img
                                        className="relative w-4 h-4 hidden"
                                        alt=""
                                        src="/img/mra-newref-out/iconarrowright18.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 rounded-md bg-light-background-background-1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-gray-300">
                            <div className="self-stretch flex flex-row items-start justify-between">
                                <div className="flex flex-col items-start justify-start">
                                    <div className="hidden flex-row items-center justify-start">
                                        <img
                                            className="relative w-20 h-4"
                                            alt=""
                                            src="/img/mra-newref-out/rating9.svg"
                                        />
                                    </div>
                                    <div className="flex flex-row items-center justify-start gap-[12px]">
                                        <b className="relative leading-[25px]">
                                            Richard Gran
                                        </b>
                                        <img
                                            className="relative w-6 h-6 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconfavorite9.svg"
                                        />
                                    </div>
                                    <div className="relative text-sm leading-[21px] opacity-[0.6]">
                                        Empowered Homes
                                    </div>
                                </div>
                                <div className="rounded-11xl bg-gray-600 shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] h-7 flex flex-row py-2 pr-3.5 pl-px box-border items-center justify-center">
                                    <div className="rounded-sm bg-light-background-background-1 w-[26px] h-[26px] flex flex-row p-1 box-border items-center justify-center">
                                        <img
                                            className="relative w-3 h-3 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-newref-out/iconadd10.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded flex flex-row py-1 px-0 items-center justify-start gap-[14px] text-smi text-tertiary">
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.2500056028366089px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-[10.000044822692871px] items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconpaperplane8.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconemail8.svg"
                                    />
                                </div>
                                <div className="rounded-5xs-5 bg-light-background-background-1 shadow-[0px_1.25px_2.5px_rgba(51,_65,_86,_0.08)] flex flex-row p-2.5 items-center justify-center border-[1.3px] border-solid border-gray-300">
                                    <img
                                        className="relative w-5 h-5 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-newref-out/iconnotepad8.svg"
                                    />
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                                    <div className="relative">View profile</div>
                                    <img
                                        className="relative w-4 h-4 hidden"
                                        alt=""
                                        src="/img/mra-newref-out/iconarrowright19.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row py-3.5 px-3 items-center justify-start">
                        <img
                            className="relative w-[5.14px] h-[8.57px]"
                            alt=""
                            src="/img/mra-newref-out/vector-stroke5.svg"
                        />
                    </div>
                </div>
            </div>

                
        </div>
    );
};

export default MemberSearchResults;
