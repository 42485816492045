import React, { FunctionComponent, useEffect, useState } from "react";
import { DIRECTION_OUTBOUND_STR, statusesInActive, statusesInClosed, statusesInDeclined, statusesInOpportunity, statusesInUnderContract } from "../../../types";
import ReferralActivityCard from "./ReferralActivityCard";
import { useNavigate } from "react-router-dom";

enum ReferralBucketEnum {
    OPPORTUNITY = "OPPORTUNITY",
    ACTIVE = "ACTIVE",
    UNDER_CONTRACT = "UNDER_CONTRACT",
    CLOSED = "CLOSED",
    DECLINED = "DECLINED",
}

type Props = {
    referrals: any[];
};
const ReferralActivityDataBuckets: FunctionComponent<Props> = ({
    referrals,
}) => {

    const navigate = useNavigate();

    const initSortedBuckets = {
        [ReferralBucketEnum.OPPORTUNITY]: [],
        [ReferralBucketEnum.ACTIVE]: [],
        [ReferralBucketEnum.UNDER_CONTRACT]: [],
        [ReferralBucketEnum.CLOSED]: [],
        [ReferralBucketEnum.DECLINED]: [],
    };

    const [bucketSelected, setBucketSelected] = useState<ReferralBucketEnum>(ReferralBucketEnum.OPPORTUNITY);
    const [sortedBuckets, setSortedBuckets] = useState<any>(initSortedBuckets);
    const [currBucket, setCurrBucket] = useState<any[]>([]);


    const viewReferralTimeline = (referral): void => {
        navigate(`/referrals/${referral.id}`);
    };


    useEffect(() => {
        console.log("SM- currBucket: ", currBucket);
    }, [currBucket])

    useEffect(() => {
        console.log("SM- Referrals Changed ... ", referrals.length);
        const sBuckets = {
            [ReferralBucketEnum.OPPORTUNITY]: [...referrals.filter((r) => statusesInOpportunity.includes(r.status))],
            [ReferralBucketEnum.ACTIVE]: [...referrals.filter((r) => statusesInActive.includes(r.status))],
            [ReferralBucketEnum.UNDER_CONTRACT]: [...referrals.filter((r) => statusesInUnderContract.includes(r.status))],
            [ReferralBucketEnum.CLOSED]: [...referrals.filter((r) => statusesInClosed.includes(r.status))],
            [ReferralBucketEnum.DECLINED]: [...referrals.filter((r) => statusesInDeclined.includes(r.status))],
        };
        setSortedBuckets(sBuckets);
        setCurrBucket(sBuckets[bucketSelected]);
    }, [referrals, bucketSelected]);


    return (
        <div className="self-stretch w-full flex flex-row items-start justify-start text-right text-base text-tertiary font-large-med">
            {/* TABS */}
            <div className="self-stretch w-[181px] flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start gap-[5px]">

                    <div
                        className={`${bucketSelected === ReferralBucketEnum.OPPORTUNITY && 'bg-darkseagreen'} self-stretch cursor-pointer rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md flex flex-row items-center justify-end p-3.5`}
                        onClick={() => setBucketSelected(ReferralBucketEnum.OPPORTUNITY)}
                    >
                        <b className="relative tracking-[0.2px] leading-[24px]">
                            Opportunities ({sortedBuckets[ReferralBucketEnum.OPPORTUNITY].length})
                        </b>
                    </div>

                    <div
                        className={`${bucketSelected === ReferralBucketEnum.ACTIVE && 'bg-darkseagreen'} self-stretch cursor-pointer rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md flex flex-row items-center justify-end p-3.5`}
                        onClick={() => setBucketSelected(ReferralBucketEnum.ACTIVE)}
                    >
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            Active ({sortedBuckets[ReferralBucketEnum.ACTIVE].length})
                        </div>
                    </div>

                    <div
                        className={`${bucketSelected === ReferralBucketEnum.UNDER_CONTRACT && 'bg-darkseagreen'} self-stretch cursor-pointer rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md flex flex-row items-center justify-end p-3.5`}
                        onClick={() => setBucketSelected(ReferralBucketEnum.UNDER_CONTRACT)}
                    >
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            Under Contract ({sortedBuckets[ReferralBucketEnum.UNDER_CONTRACT].length})
                        </div>
                    </div>

                    <div
                        className={`${bucketSelected === ReferralBucketEnum.CLOSED && 'bg-darkseagreen'} self-stretch cursor-pointer rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md flex flex-row items-center justify-end p-3.5`}
                        onClick={() => setBucketSelected(ReferralBucketEnum.CLOSED)}
                    >
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            Closed ({sortedBuckets[ReferralBucketEnum.CLOSED].length})
                        </div>
                    </div>

                    <div
                        className={`${bucketSelected === ReferralBucketEnum.DECLINED && 'bg-darkseagreen'} self-stretch cursor-pointer rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md flex flex-row items-center justify-end p-3.5`}
                        onClick={() => setBucketSelected(ReferralBucketEnum.DECLINED)}
                    >
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            Declined ({sortedBuckets[ReferralBucketEnum.DECLINED].length})
                        </div>
                    </div>
                </div>
            </div>

            {/* DATA CARDS */}
            <div className="flex-1 flex flex-col items-start justify-start text-left text-sm text-colors-gs-brand-primary">
                {currBucket.length === 0 ? (
                    // <div className="self-stretch h-[200px] rounded-md bg-gradient-to-br from-darkseagreen to-surface-primary flex flex-row flex-wrap items-start justify-start p-[40px]">
                    <div className="self-stretch min-h-[232px] rounded-md bg-darkseagreen flex flex-row flex-wrap items-start justify-start p-[40px]">
                        <i>No Referrals Matching the Filter/Search Criteria</i>
                    </div>
                ) : (
                    // <div className="self-stretch rounded-tl-none rounded-tr-md rounded-b-md bg-gradient-to-br from-darkseagreen to-surface-primary flex flex-row flex-wrap items-start justify-start py-[30px] px-[39px] gap-[40px]">
                    <div className="self-stretch rounded-tl-none rounded-tr-md rounded-b-md bg-darkseagreen flex flex-row flex-wrap items-start justify-start py-[40px] px-[40px] gap-[40px]">
                        {currBucket.map((r) =>
                            <div key={r.id} className="cursor-pointer border border-solid border-transparent hover:border hover:border-solid hover:border-green-200 hover:shadow-xl rounded-md" onClick={() => viewReferralTimeline(r)}>
                                <ReferralActivityCard referral={r} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReferralActivityDataBuckets;
