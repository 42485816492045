import React, { FunctionComponent } from 'react';
import IconStatus from './Dashboard/components/IconStatus';

enum AvatarTypeEnum {
    rounded,
    round,
    roundWithOnlineStatus,
    freePill,
    proPill,
    teamsPill
}

type AvatarProps = {
    avatarUrl: string;
    avatarType?: AvatarTypeEnum;
    isUserOnline?: boolean;
    isSocialGoogle?: boolean;
};

const Avatar: FunctionComponent<AvatarProps> = ({
    avatarUrl,
    avatarType = AvatarTypeEnum.round,
    isUserOnline = false,
    isSocialGoogle = false,
}: AvatarProps) => {
    return (
        <>
            {avatarType === AvatarTypeEnum.rounded && (
                <div className="relative w-12 h-12">
                    <div className="absolute h-full w-full top-0 left-0 rounded-md bg-white" />
                    <img
                        className="absolute h-[calc(100%-4px)] w-[calc(100%-4px)] top-[2px] left-[2px] rounded-md"
                        alt=""
                        src={avatarUrl}
                    />
                </div>
            )}
            {avatarType === AvatarTypeEnum.round && (
                <>
                {isSocialGoogle
                    ? <div className="relative py-[3px] pr-1 pl-1 bg-white rounded-full flex flex-row items-center justify-center gap-[8px]">
                        <img
                            className='relative h-12 w-12'
                            src="/img/mra-dashboard/googleicon.svg"
                            alt=""
                        />
                        <img
                            className="relative h-12 w-12 rounded-full"
                            alt=""
                            src={avatarUrl}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                    : <div className="relative w-12 h-12">
                        <div className="absolute h-full w-full top-0 left-0 rounded-full bg-white flex items-center justify-center" />
                        <img
                            className="absolute h-[calc(100%-4px)] w-[calc(100%-4px)] top-[2px] left-[2px] rounded-full"
                            alt=""
                            src={avatarUrl}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                }
                </>
            )}
            {avatarType === AvatarTypeEnum.roundWithOnlineStatus && (
                <div className="relative w-12 h-12">
                    <div className="absolute h-full w-full top-0 left-0 rounded-full bg-white" />
                    <img
                        className="absolute h-[calc(100%-4px)] w-[calc(100%-4px)] top-[2px] left-[2px] rounded-3xl"
                        alt=""
                        src={avatarUrl}
                        referrerPolicy="no-referrer"
                    />
                    <IconStatus isOnline={isUserOnline} />
                </div>
            )}
            {avatarType === AvatarTypeEnum.freePill && (
                <div className="relative py-[3px] pr-1 pl-[13px] bg-white rounded-full flex flex-row items-center justify-center gap-[8px]">
                    <div className="relative leading-[21px]">
                        <b>FREE</b>
                    </div>
                    <img
                        className="relative w-12 h-12 rounded-3xl"
                        alt=""
                        src={avatarUrl}
                        referrerPolicy="no-referrer"
                    />
                </div>
            )}
            {avatarType === AvatarTypeEnum.proPill && (
                
                <div className="relative py-[3px] pr-1 pl-[13px] bg-white rounded-full flex flex-row items-center justify-center gap-[8px]">
                    
                    <div className="relative leading-[21px]">
                        <b>PRO</b>
                    </div>
                    {isSocialGoogle &&
                        <img
                            className='relative h-[35px] w-[35px]'
                            src="/img/mra-dashboard/googleicon.svg"
                            alt=""
                        />
                    }
                    <img
                        className="border border-solid border-gray-600 relative w-12 h-12 rounded-full"
                        alt=""
                        src={avatarUrl}
                        referrerPolicy="no-referrer"
                    />
                </div>
            )}
            {avatarType === AvatarTypeEnum.teamsPill && (
                
                <div className="relative py-[3px] pr-1 pl-[13px] bg-white rounded-full flex flex-row items-center justify-center gap-[8px]">
                    
                    <div className="relative leading-[21px]">
                        <b>TEAMS</b>
                    </div>
                    {isSocialGoogle &&
                        <img
                            className='relative h-[35px] w-[35px]'
                            src="/img/mra-dashboard/googleicon.svg"
                            alt=""
                        />
                    }
                    <img
                        className="border border-solid border-gray-600 relative w-12 h-12 rounded-full"
                        alt=""
                        src={avatarUrl}
                        referrerPolicy="no-referrer"
                    />
                </div>
            )}
        </>
    );
};

export { Avatar, AvatarTypeEnum };
