import React, { FunctionComponent, useEffect, useState } from "react";
import { NewReferralCard } from "./components/NewReferralCard";
import {
    getAllSkyflowW9,
    getCurrentSubscriptionByStripeId,
    getPrivateProfileById,
    getReferralsInbound,
    getReferralsUser,
    getReferralsUsersInbound,
    getReferralsUsersInboundPending,
} from "../../utils/api";
import {
    DIRECTION_INBOUND_STR,
    DIRECTION_OUTBOUND_STR,
    ReferralAction,
    ReferralFilterSort,
    ReferralStatus,
    SortOrder,
    TPrivateProfileView,
    TStateReferralsUserInbound,
    TUserReferralModelInbound,
    TViewReferralInboundModel,
    statusesInActive,
    statusesInClosed,
    statusesInDeclined,
    statusesInOpportunity,
    statusesInPending,
    statusesInUnderContract,
} from "../../types";
import { useNavigate } from "react-router-dom";
import useZustandAuthStore from "../../_Zustand/auth-store";
import ReferralActivity from "./ReferralActivity/ReferralActivity";
import { SubscriptionTypeEnum } from "../AgentProfile/AgentProfile";
import { toast } from "react-toastify";

const Dashboard: FunctionComponent = () => {
    const navigate = useNavigate();

    const { authState } = useZustandAuthStore();

    const profile = authState.profileUserData;
    const firstName = profile?.firstName;
    const lastName = profile?.lastName;

    const [privateProfileData, setPrivateProfileData] = useState<any>(null);
    
    const NEW_REFERRAL_FETCH_INTERVAL_MS = 30000;
    const sendReferral = (): void => {
        if (privateProfileData.product) {
            if (privateProfileData.product === SubscriptionTypeEnum.FREE) {
                const outRefsCount = privateProfileData.referrals.filter((r) => r.direction === DIRECTION_OUTBOUND_STR).length;
                console.log("outRefsCount: ", outRefsCount);
                if (outRefsCount >= 5) {
                    toast.error("Upgrade to send more referrals.")
                }
                else {
                    navigate(`/referral`);
                }
            }
            else {
                navigate(`/referral`);
            }
        }
    };
    
    const [referrals, setReferrals] = useState<any[] | TUserReferralModelInbound[]>([] as any[]);
    const [newReferrals, setNewReferrals] = useState<Array<TViewReferralInboundModel>>([] as Array<TViewReferralInboundModel>);
    const [refCounts, setRefCounts] = useState<any|null>(null);    
    const curateReferrals = (allRefs) => {
        if (allRefs) {
            
            console.log(">>>>> HERE: ", allRefs);
            setReferrals(allRefs);
                        
            const refCnt = {
                opportunity: {
                    out: allRefs.filter((r) => [...statusesInOpportunity].includes(r.status) && authState.id === r.userId).length,
                    in: allRefs.filter((r) => [...statusesInOpportunity].includes(r.status) && authState.id === r.agentId).length,
                },

                active: {
                    out: allRefs.filter((r) => [...statusesInActive].includes(r.status) && authState.id === r.userId).length,
                    in: allRefs.filter((r) => [...statusesInActive].includes(r.status) && authState.id === r.agentId).length,
                },

                underContract: {
                    out: allRefs.filter((r) => [...statusesInUnderContract].includes(r.status) && authState.id === r.userId).length,
                    in: allRefs.filter((r) => [...statusesInUnderContract].includes(r.status) && authState.id === r.agentId).length,
                },

                closed: {
                    out: allRefs.filter((r) => [...statusesInClosed].includes(r.status) && authState.id === r.userId).length,
                    in: allRefs.filter((r) => [...statusesInClosed].includes(r.status) && authState.id === r.agentId).length,
                }
            };
            setRefCounts(refCnt);

            const newRefs = allRefs.filter((r) => [...statusesInPending].includes(r.status)).sort((a, b) => b.id - a.id);
            setNewReferrals([...newRefs]);
        }
    };
    const determineUserProduct = (privateProfileData) => {
        const privProfileDataCopy = { ...privateProfileData };

        if (!privProfileDataCopy.stripeId) {
            privProfileDataCopy['product'] = 'FREE';

            setPrivateProfileData(privProfileDataCopy);
            console.log('Private Profile: ', privProfileDataCopy);

        } else {
            getCurrentSubscriptionByStripeId(privProfileDataCopy.stripeId)
                .then((subscription) => {
                    if (subscription && subscription.product) {
                        const product = subscription.product;
                        privProfileDataCopy['product'] = product;

                        
                    } else {
                        privProfileDataCopy['product'] = 'FREE';
                    }

                    setPrivateProfileData(privProfileDataCopy);
                    console.log('Private Profile: ', privProfileDataCopy);

                })
                .catch((error) => {
                    console.error(error);
                    setPrivateProfileData(privProfileDataCopy);
                });
        }
    };

    const [ pendingTasks, setPendingTasks ] = useState<Array<any>>([{init: -1}]);
    const determinePendingTasks = (profile, privateProfileData) => {
        
        const pTasks: any[] = [];
        if (!profile?.officeAddress1 || !profile.officeCity || !profile.officeStateId || !profile.officeZip) {
            pTasks.push({link: '/profile#set-office-address', content: [{type: 'text', content: 'Add your '}, {type: 'link', content: 'Office Address'}, {type: 'text', content: ' to your profile.'}]});
        }
        if (!profile?.officePhoneNumber) {
            pTasks.push({link: '/profile#set-office-phone-number', content: [{type: 'text', content: 'Add your '}, {type: 'link', content: 'Office Phone Number'}, {type: 'text', content: ' to your profile.'}]});
        }
        if (!profile?.cellPhoneNumber) {
            pTasks.push({link: '/profile#add-mobile-phone-number', content: [{type: 'text', content: 'Add your '}, {type: 'link', content: 'Cell Phone Number'}, {type: 'text', content: ' to your profile.'}]});
        }
        if (privateProfileData.servedCities.length === 0 && privateProfileData.servedZipCodes.length === 0) {
            pTasks.push({link: '/profile#add-a-location', content: [{type: 'text', content: 'Add at least one '}, {type: 'link', content: 'Location'}, {type: 'text', content: ' to your profile.'}]});
        }

        getSkyflowW9Status(pTasks);        
    };
    const getSkyflowW9Status = (pTasks) => {
        const w9TaskToAdd = {link: '/profile#add-broker-w9', content: [{type: 'text', content: "Add your "}, {type: 'link', content: "Broker's W-9"}, {type: 'text', content: ' to your profile.'}]};
        getAllSkyflowW9(authState.id)
            .then((response) => {
                console.log(response);
                if (Array.isArray(response.records) && response.records.length > 0) {
                    const w9List = response.records;
                    if (!w9List[0].fields.w9_file) {                    
                        pTasks.push(w9TaskToAdd);                    
                    }
                } else {
                    pTasks.push(w9TaskToAdd);
                }
                setPendingTasks(pTasks);
            })
            .catch((error) => {
                console.error(error);
                if (error.status === 404) {
                    pTasks.push(w9TaskToAdd);
                }
                setPendingTasks(pTasks);
            });
    };

    // get pending profile tasks once
    useEffect(() => {
        if (!authState.id) {
            return;
        }

        getPrivateProfileById()
            .then((privateProfileData: TPrivateProfileView) => {
                determineUserProduct(privateProfileData);
                determinePendingTasks(authState.profileUserData, privateProfileData);
                curateReferrals(privateProfileData.referrals);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [authState.id]);

    
    // get all referrals interval polling
    useEffect(() => {
        if (!authState.id) {
            return;
        }

        const interval = setInterval(() => {
            getPrivateProfileById()
                .then((privateProfileData: TPrivateProfileView) => {
                    determineUserProduct(privateProfileData);
                    determinePendingTasks(authState.profileUserData, privateProfileData);
                    curateReferrals(privateProfileData.referrals);
                })
                .catch((error) => {
                    console.error(error);
                });
        }, NEW_REFERRAL_FETCH_INTERVAL_MS);
        return () => {
            clearInterval(interval);
        };
    }, [authState.id]);

    useEffect(() => {
        history.replaceState('', 'MRA Profile', '/dashboard');
        history.pushState('', 'MRA Profile', '/dashboard');
    }, []);


    return (
        <div className="relative mt-[25px] w-full h-full flex flex-col xs:items-start lg:items-center">
            <div className="relative w-[1240px] h-full p-3 flex flex-col items-center justify-start gap-[44px] text-21xl text-primary font-small-semibold">
                {/* Row 1 & 2 */}
                <div className="w-full flex flex-col items-center justify-center gap-[44px]">
                    
                    {/* First Row: Welcome Message + Send Referral Button */}
                    {/* <div className="w-full flex items-center xs:gap-[20px] lg:gap-0 xs:flex-col lg:flex-row xs:flex-wrap lg:flex-nowrap xs:justify-start lg:justify-between"> */}
                    <div className="w-full flex items-center flex-row justify-between">
                        <div className="flex-1 break-all flex items-start justify-start gap-[16px] tracking-[-1.57px] leading-[46px]">
                            Hi {firstName} {lastName}!
                        </div>

                        {(privateProfileData && privateProfileData.product && pendingTasks && Array.isArray(pendingTasks) && (pendingTasks.length === 0 || (pendingTasks.length === 1 && pendingTasks[0] && !!pendingTasks[0].init) ))
                            && <div
                                    className="break-all flex items-end justify-end text-center text-[22.05px] text-white cursor-pointer"
                                    onClick={sendReferral}
                                >
                                    <div className="rounded-[33.08px] bg-tomato h-[63px] flex flex-row py-0 px-[38px] box-border items-center justify-center gap-[12.6px]">
                                        <div className="relative leading-[33.08px] font-semibold">Send Referral</div>
                                        <img
                                            className="relative w-[22.05px] h-[22.05px]"
                                            alt=""
                                            src="/img/mra-dashboard/iconsolidshare.svg"
                                        />
                                    </div>
                                </div>
                        }
                    </div>

                    {/* Row 1.1: Pending Tasks} */}
                    {(pendingTasks && Array.isArray(pendingTasks) && pendingTasks.length > 0 && !pendingTasks[0].init)
                        && <div className="w-full rounded-xl flex flex-col items-start justify-start gap-[28px]">
                            <div className="w-full flex flex-row items-start justify-start gap-[16px] py-0.5 px-1 box-border rounded-none">
                                <div className="flex">Pending Tasks</div>
                                <div className="flex items-center justify-center">
                                    <div className="rounded-full bg-tomato w-[30px] h-[30px] flex flex-col flex-1 items-center justify-center text-base text-white">
                                        {pendingTasks.length}
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex flex-col flex-wrap justify-start gap-[7.5px] border-box rounded-xl text-3xl-1 ml-[5px]">
                                Please complete these tasks to enable sending referrals.
                                <ol>
                                    {pendingTasks && Array.isArray(pendingTasks) && pendingTasks.map((task, idx) =>
                                        <li key={`pending-${idx}`}>
                                            {task && task.content && Array.isArray(task.content) && task.content.map((c, cidx) =>
                                                <>
                                                    {c.type === 'text' && <span key={`ptask-content-${cidx}`} className='text-secondary'>{c.content}</span>}
                                                    {c.type === 'link' && <span key={`ptask-content-${cidx}`} onClick={() => navigate(task.link)} className="text-link-blue hover:underline cursor-pointer">{c.content}</span>}
                                                </>
                                            )}
                                        </li>
                                    )}
                                </ol>
                            </div>
                        </div>
                    }

                    {/* Row 1.2: New Referrals: if any */}
                    {!!newReferrals.length && newReferrals.length > 0 && (
                        <div className="hidden w-full rounded-xl flex flex-col items-start justify-start gap-[28px]">
                            {/* <div className="w-full flex flex-row items-start xs:justify-center lg:justify-start gap-[16px] py-0.5 px-1 box-border rounded-none shadow-sm"> */}
                            <div className="w-full flex flex-row items-start justify-start gap-[16px] py-0.5 px-1 box-border rounded-none shadow-sm">
                                <div className="flex">New Referrals</div>
                                <div className="flex items-center justify-center">
                                    <div className="rounded-full bg-tomato w-[30px] h-[30px] flex flex-col flex-1 items-center justify-center text-base text-white">
                                        {newReferrals.length}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="w-full flex flex-row flex-wrap xs:justify-center lg:justify-start gap-y-[20px] gap-[7.5px] border-box rounded-xl"> */}
                            <div className="w-full flex flex-row flex-wrap justify-start gap-y-[20px] gap-[7.5px] border-box rounded-xl">
                                {newReferrals &&
                                    newReferrals.map((referral) => (
                                        <NewReferralCard
                                            key={referral.id}
                                            referral={referral}
                                        />
                                    ))}
                            </div>
                        </div>
                    )}

                    {/* Referral Statistics */}
                    <div className="w-full rounded-xl flex flex-col items-start justify-start gap-[28px]">
                        <div className="w-full flex flex-row items-start justify-start gap-[16px] py-0.5 px-1 rounded-none">
                            <div className="flex">Referral Statistics</div>
                        </div>
                        
                        {/* <div className="w-full flex flex-row flex-wrap justify-start gap-[16px] rounded-xl"> */}
                        <div className='flex flex-row items-center justify-start gap-[16px] text-37xl text-colors-gs-brand-primary font-large'>
                            {/* Opportunities */}
                            {/* <div className='rounded-md bg-gray1 shadow-[0px_12px_16px_-4px_rgba(12,_26,_36,_0.04),_0px_12px_16px_-4px_rgba(12,_26,_36,_0.04)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'> */}
                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                            {(refCounts && refCounts.opportunity)
                                                ? `${refCounts.opportunity.out + refCounts.opportunity.in}`
                                                : `-`}
                                        </div>
                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                            Opportunities
                                        </div>
                                    </div>
                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-money.svg' />
                                </div>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[13px] text-center text-base text-colors-gs-tertiary'>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Inbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.opportunity)
                                                ? `${refCounts.opportunity.in}`
                                                : `-`}
                                        </b>
                                    </div>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Outbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.opportunity)
                                                ? `${refCounts.opportunity.out}`
                                                : `-`}
                                        </b>
                                    </div>
                                </div>
                            </div>

                            {/* Active */}
                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                            {(refCounts && refCounts.active)
                                                ? `${refCounts.active.out + refCounts.active.in}`
                                                : `-`}
                                        </div>
                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                            Active
                                        </div>
                                    </div>
                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-pen-nib-solid.svg' />
                                </div>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px] text-center text-base text-colors-gs-tertiary'>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Inbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.active)
                                                ? `${refCounts.active.in}`
                                                : `-`}
                                        </b>
                                    </div>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Outbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.active)
                                                ? `${refCounts.active.out}`
                                                : `-`}
                                        </b>
                                    </div>
                                </div>
                            </div>

                            {/* Under Contract */}
                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                            {(refCounts && refCounts.underContract)
                                                ? `${refCounts.underContract.out + refCounts.underContract.in}`
                                                : `-`}
                                        </div>
                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                            Contract
                                        </div>
                                    </div>
                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-file-pen-solid.svg' />
                                </div>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px] text-center text-base text-colors-gs-tertiary'>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Inbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.underContract)
                                                ? `${refCounts.underContract.in}`
                                                : `-`}
                                        </b>
                                    </div>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Outbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.underContract)
                                                ? `${refCounts.underContract.out}`
                                                : `-`}
                                        </b>
                                    </div>
                                </div>
                            </div>

                            {/* Closed */}
                            <div className='rounded-md bg-gray1 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] [backdrop-filter:blur(27.2px)] w-[297.5px] flex flex-col p-6 box-border items-center justify-center gap-[16px]'>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px]'>
                                    <div className='flex-1 flex flex-col items-start justify-start gap-[16px]'>
                                        <div className='self-stretch relative tracking-[-2.07px] leading-[62px]'>
                                            {(refCounts && refCounts.closed)
                                                ? `${refCounts.closed.out + refCounts.closed.in}`
                                                : `-`}
                                        </div>
                                        <div className='relative text-10xl tracking-[-0.29px] leading-[40px] font-semibold'>
                                            Closed
                                        </div>
                                    </div>
                                    <img className='relative rounded-lg w-[60px] h-[60px]' alt='' src='/img/mra-profile/icon-award.svg' />
                                </div>
                                <div className='self-stretch flex flex-row items-start justify-start gap-[16px] text-center text-base text-colors-gs-tertiary'>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Inbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.closed)
                                                ? `${refCounts.closed.in}`
                                                : `-`}
                                        </b>
                                    </div>
                                    <div className='flex-1 rounded-md bg-colors-gs-white flex flex-col p-2.5 items-start justify-start gap-[10px] border-[1px] border-solid border-surface-secondary'>
                                        <div className='self-stretch relative tracking-[0.1px] leading-[24px]'>
                                            Outbound
                                        </div>
                                        <b className='self-stretch relative text-lg leading-[25px] text-colors-gs-tertiary-dark'>
                                            {(refCounts && refCounts.closed)
                                                ? `${refCounts.closed.out}`
                                                : `-`}
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                

                    {/* Row 2: Tasks and Past Due Cards */}
                    <div className="hidden self-stretch rounded-xl flex flex-row items-start justify-start gap-[30px] text-center text-sm text-white">
                        {/* Tasks */}
                        <div className="flex-1 rounded-lg flex flex-col items-start justify-start">
                            <div className="self-stretch rounded-lg bg-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[16px]">
                                <div className="w-full flex flex-row items-start justify-start gap-[16px]  py-0.5 px-4 box-border rounded-none shadow-sm ">
                                    <div className="relative leading-[140%] font-medium">
                                        Tasks
                                    </div>
                                    <div className="rounded-md bg-white shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-row p-2 items-center justify-end border-[1px] border-solid border-gray-300">
                                        <img
                                            className="relative w-4 h-4 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-dashboard/iconopen.svg"
                                        />
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-row p-2.5 items-center justify-start gap-[10px]">
                                        <div className="rounded-lgi bg-tomato shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] w-[22px] h-[22px] flex flex-row items-center justify-center">
                                            <div className="relative leading-[21px] font-semibold flex items-center justify-center w-[17px] shrink-0">
                                                !
                                            </div>
                                        </div>
                                        <div className="flex-1 relative leading-[21px] font-medium text-left text-tertiary">
                                            <span>{`Leave an update for `}</span>
                                            <span className="text-link-blue">
                                                1 Buyer
                                            </span>
                                            <span>{` and `}</span>
                                            <span className="text-link-blue">
                                                2 Sellers
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-row p-2.5 items-center justify-start gap-[10px]">
                                        <div className="rounded-lgi bg-tomato shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] w-[22px] h-[22px] flex flex-row items-center justify-center">
                                            <div className="relative leading-[21px] font-semibold flex items-center justify-center w-[17px] shrink-0">
                                                !
                                            </div>
                                        </div>
                                        <div className="flex-1 relative leading-[21px] font-medium text-link-blue text-left">
                                            Complete your profile
                                        </div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-row p-2.5 items-center justify-start gap-[10px]">
                                        <div className="rounded-lgi bg-tomato shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] w-[22px] h-[22px] flex flex-row items-center justify-center">
                                            <div className="relative leading-[21px] font-semibold flex items-center justify-center w-[17px] shrink-0">
                                                !
                                            </div>
                                        </div>
                                        <div className="flex-1 relative leading-[21px] font-medium text-left text-tertiary">
                                            <span>{`Leave an update for `}</span>
                                            <span className="text-link-blue">
                                                1 Buyer
                                            </span>
                                            <span>{` and `}</span>
                                            <span className="text-link-blue">
                                                2 Sellers
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Past Due */}
                        <div className="self-stretch w-[45%] flex flex-col items-start justify-start">
                            <div className="self-stretch rounded-lg bg-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col p-4 items-start justify-start gap-[16px]">
                                <div className="self-stretch flex flex-row items-start justify-between text-left text-lg text-primary">
                                    <div className="relative leading-[140%] font-medium">
                                        Past Due
                                    </div>
                                    <div className="rounded-md bg-white shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-row p-2 items-center justify-end border-[1px] border-solid border-gray-300">
                                        <img
                                            className="relative w-4 h-4 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/mra-dashboard/iconopen.svg"
                                        />
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-row p-2.5 items-center justify-start gap-[10px]">
                                        <div className="rounded-lgi bg-alert-red shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] w-[22px] h-[22px] flex flex-row items-center justify-center">
                                            <div className="relative leading-[21px] font-semibold flex items-center justify-center w-[17px] shrink-0">
                                                !
                                            </div>
                                        </div>
                                        <div className="flex-1 relative leading-[21px] text-left text-link-blue">
                                            <span className="text-tertiary">
                                                <span className="font-medium">
                                                    Reply to
                                                </span>
                                            </span>
                                            <span>
                                                <span className="text-tertiary">{` `}</span>
                                                <span>Jonathan Myers</span>
                                                <span className="text-primary">
                                                    ,
                                                </span>
                                                <span className="text-link-blue">{` `}</span>
                                            </span>
                                            <span className="font-medium">
                                                <span className="text-tertiary">{`buyer’s agent for `}</span>
                                                <span>
                                                    101 Main St. San Antonio, TX
                                                </span>
                                                <span className="text-tertiary">
                                                    .
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-row p-2.5 items-center justify-start gap-[10px]">
                                        <div className="rounded-lgi bg-alert-red shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] w-[22px] h-[22px] flex flex-row items-center justify-center">
                                            <div className="relative leading-[21px] font-semibold flex items-center justify-center w-[17px] shrink-0">
                                                !
                                            </div>
                                        </div>
                                        <div className="flex-1 relative leading-[21px] text-left text-tertiary">
                                            <span>
                                                <span>Leave a</span>
                                                <span className="font-medium font-small-semibold">
                                                    {" "}
                                                    Review
                                                </span>
                                                <span className="font-small-semibold">
                                                    {" "}
                                                    for
                                                </span>
                                            </span>
                                            <span className="font-medium text-link-blue">
                                                <span>{` `}</span>
                                                <span className="text-link-blue">
                                                    Steve Collins
                                                </span>
                                                <span className="text-primary">
                                                    ,
                                                </span>
                                                <span className="text-link-blue">{` `}</span>
                                                <span className="text-tertiary">{`seller’s agent for `}</span>
                                                <span>
                                                    600 River Run Dr. Austin, TX
                                                </span>
                                                <span className="text-tertiary">
                                                    .
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-center justify-start">
                                    <div className="self-stretch flex-1 rounded bg-surface-primary flex flex-row p-2.5 items-center justify-start gap-[10px]">
                                        <div className="rounded-lgi bg-tomato shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] w-[22px] h-[22px] flex flex-row items-center justify-center">
                                            <div className="relative leading-[21px] font-semibold flex items-center justify-center w-[17px] shrink-0">
                                                !
                                            </div>
                                        </div>
                                        <div className="flex-1 relative leading-[21px] font-medium text-left text-tertiary">
                                            <span>{`Leave an update for `}</span>
                                            <span className="text-link-blue">
                                                1 Buyer
                                            </span>
                                            <span>{` and `}</span>
                                            <span className="text-link-blue">
                                                2 Sellers
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

                {/* Row 3: Badges */}
                <div className="hidden self-stretch flex flex-col items-start justify-start text-lg">
                    <div className="rounded-lg bg-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] w-full flex flex-col p-4 box-border items-start justify-start gap-[16px]">
                        {/* Badges Title */}
                        <div className="flex flex-row items-start justify-start gap-[16px]">
                            <div className="flex flex-row items-center justify-start">
                                <div className="relative leading-[140%] font-medium">
                                    Earn Badges
                                </div>
                            </div>
                            <div className="rounded-lgi bg-tomato shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] w-[41px] h-[26px] flex flex-row items-center justify-center text-center text-xs text-white">
                                <b className="relative leading-[16px]">5/12</b>
                            </div>
                        </div>

                        {/* Each Badge */}
                        <div className="self-stretch rounded-sm flex flex-row items-start justify-between text-center text-base text-tertiary">
                            <div className="rounded-md bg-surface-primary flex flex-col p-4 items-center justify-start gap-[16px]">
                                <img
                                    className="relative w-[140px] h-[166px]"
                                    alt=""
                                    src="/img/mra-dashboard/badgenewuserunlocked.svg"
                                />
                                <div className="self-stretch flex flex-col items-center justify-center gap-[6px]">
                                    <div className="relative tracking-[0.2px] leading-[24px]">
                                        Badge Unlocked!
                                    </div>
                                    <div className="relative tracking-[0.2px] leading-[24px] font-medium text-primary">
                                        New User
                                    </div>
                                </div>
                            </div>

                            <div className="rounded-md bg-surface-primary flex flex-col p-4 items-center justify-start gap-[16px]">
                                <img
                                    className="relative w-[140px] h-[166px]"
                                    alt=""
                                    src="/img/mra-dashboard/badgesenderunlocked2.svg"
                                />
                                <div className="self-stretch flex flex-col items-center justify-center gap-[6px]">
                                    <div className="relative tracking-[0.2px] leading-[24px]">
                                        Badge Unlocked!
                                    </div>
                                    <div className="relative tracking-[0.2px] leading-[24px] font-medium text-primary">
                                        Referral Sent
                                    </div>
                                </div>
                            </div>

                            <div className="rounded-md bg-surface-primary flex flex-col p-4 items-center justify-start gap-[16px]">
                                <img
                                    className="relative w-[140px] h-[166px]"
                                    alt=""
                                    src="/img/mra-dashboard/badgereceiverunlocked2.svg"
                                />
                                <div className="self-stretch flex flex-col items-center justify-center gap-[6px]">
                                    <div className="relative tracking-[0.2px] leading-[24px]">
                                        Badge Unlocked!
                                    </div>
                                    <div className="relative tracking-[0.2px] leading-[24px] font-medium text-primary">
                                        Referral Received
                                    </div>
                                </div>
                            </div>

                            <div className="rounded-md bg-surface-primary flex flex-col p-4 items-center justify-start gap-[16px]">
                                <img
                                    className="relative w-[140px] h-[166px]"
                                    alt=""
                                    src="/img/mra-dashboard/badgerelocationunlocked2.svg"
                                />
                                <div className="self-stretch flex flex-col items-center justify-center gap-[6px]">
                                    <div className="relative tracking-[0.2px] leading-[24px]">
                                        Badge Unlocked!
                                    </div>
                                    <div className="relative tracking-[0.2px] leading-[24px] font-medium text-primary">
                                        First Relocation
                                    </div>
                                </div>
                            </div>

                            <div className="rounded-md bg-surface-primary flex flex-col p-4 items-center justify-start gap-[16px]">
                                <img
                                    className="relative w-[140px] h-[166px]"
                                    alt=""
                                    src="/img/mra-dashboard/badgecompleteprofileunlocked2.svg"
                                />
                                <div className="self-stretch flex flex-col items-center justify-center gap-[6px]">
                                    <div className="relative tracking-[0.2px] leading-[24px]">
                                        Badge Unlocked!
                                    </div>
                                    <div className="relative tracking-[0.2px] leading-[24px] font-medium text-primary">
                                        Complete Profile
                                    </div>
                                </div>
                            </div>

                            <div className="rounded-md bg-surface-primary flex flex-col p-4 items-center justify-start gap-[16px]">
                                <img
                                    className="relative w-[140px] h-[166px]"
                                    alt=""
                                    src="/img/mra-dashboard/badge100viewsunlocked2.svg"
                                />
                                <div className="self-stretch flex flex-col items-center justify-center gap-[6px]">
                                    <div className="relative tracking-[0.2px] leading-[24px]">
                                        Badge Unlocked!
                                    </div>
                                    <div className="relative tracking-[0.2px] leading-[24px] font-medium text-primary">
                                        100 Views
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-center justify-start text-sm text-link-blue">
                            <div className="relative leading-[21px]">
                                Show more...
                            </div>
                        </div>
                    </div>
                </div>

                <ReferralActivity
                    referrals={referrals}
                />
            </div>
        </div>
    );
};

export { Dashboard };
