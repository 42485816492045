import { IdToken, User } from '@auth0/auth0-react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware'
import { TManageDesignationList, TManageSpecialtyList, TPrivateProfileView, TProfileUserData, TUsStateList } from '../types';
import { getIsProduction } from '../utils/get-is-production';

export interface Auth {
    id: number,
    user: User | undefined,
    apiToken: string | undefined,
    idTokenClaims: IdToken | undefined,
    profileUserData: TProfileUserData | undefined,
}

export interface AuthState {
    authState: Auth,
    setAuthState: (auth: Auth) => void;
    
    usStates: TUsStateList | undefined,
    setUsStateList: (usStates: TUsStateList) => void;

    designationList: TManageDesignationList | undefined,
    setDesignationList: (designationList: TManageDesignationList) => void;

    specialtiesList: TManageSpecialtyList | undefined;
    setSpecialtiesList: (specialtyList: TManageSpecialtyList) => void;

    privateProfileData: TPrivateProfileView | undefined;
    setPrivateProfileData: (privateProfileData: TPrivateProfileView) => void;

    skyflowW9CollectController: any | undefined | null;
    setSkyflowW9CollectController: (skyflowW9CollectController: any) => void;

    referralInvitation: string;
    setReferralInvitation: (referralInvitation) => void

    resetZustandState: () => void;
}

const initialAuthState = { id: -1, profileUserData: undefined, user: undefined, apiToken: "", idTokenClaims: undefined, usStates: undefined };

const useZustandAuthStore = create<AuthState>()(devtools(
    (set) => ({
        authState: {...initialAuthState},
        setAuthState: (authState: Auth) => {
            set(() => ({ authState }));
        },
        
        usStates: [],
        setUsStateList: (usStates: TUsStateList) => {
            set(() => ({ usStates}));
        },
        
        designationList: [],
        setDesignationList: (designationList: TManageDesignationList) => {
            set(() => ({ designationList }));
        },

        specialtiesList: [],
        setSpecialtiesList: (specialtiesList: TManageSpecialtyList) => {
            set(() => ({ specialtiesList }));
        },

        privateProfileData: {} as TPrivateProfileView | undefined,
        setPrivateProfileData: (privateProfileData: TPrivateProfileView) => {
            set(() => ({ privateProfileData }));
        },

        skyflowW9CollectController: null as any | undefined | null,
        setSkyflowW9CollectController: (skyflowW9CollectController: any) => {
            set(() => ({ skyflowW9CollectController }));
        },

        referralInvitation: null as any,
        setReferralInvitation: (referralInvitation: string) => {
            set(() => ({ referralInvitation }));
        },

        resetZustandState: () => {
            set(() => ({
                authState: {...initialAuthState},
                usStates: [],
                designationList: [],
                specialtiesList: [],
                privateProfileData: {} as TPrivateProfileView | undefined,
                skyflowW9CollectController: null,
            }));
        },
    }),
    {enabled: !getIsProduction()}
));

export default useZustandAuthStore;