import React, { FunctionComponent } from "react";
import { WaitSpinner } from "../WaitSpinner";

type Props = {
    shouldWaitIf: boolean;
    message?: string;
    waitSizePx?: number;
    children?: React.ReactNode;
    opacity?: number; // 0 to 100
}

const WaitContainer: FunctionComponent<Props> = ({ waitSizePx = 20, message = "", shouldWaitIf: shouldWait, children, opacity = 0 }) => {

    return  <>
    {shouldWait
        ? <div className={`flex items-center self-stretch ${shouldWait && 'relative h-fit p-0'}`}>
            <div className="self-stretch absolute w-full h-full flex flex-col gap-[3px] items-center justify-center text-black">
                <WaitSpinner sizePx={waitSizePx} />
                {message &&
                    <div className="p-[2px] rounded-xl">{message}</div>
                }
            </div>
            <div id="container" className={` opacity-${opacity} flex bg-white blur-sm self-stretch pointer-events-none cursor-wait`}>
                {children}
            </div>
        </div>
    
        : <>{children}</>
    }
    </>

};

export { WaitContainer };