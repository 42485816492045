import { FunctionComponent } from 'react';
import { Tooltip } from 'react-tooltip';

type Props = {
    id: string,
    message: string,
}
const MRATooltip: FunctionComponent<Props> = ({ message, id }) => {
    return <>
            <img
                className="text-lg py-1 px-2 text-sm superscript cursor-pointer w-[24px]"
                src="/img/mra-profile/icon-alert-question.svg"
                data-tooltip-id={id}
                data-tooltip-html={message}
            />
            <Tooltip id={id} />
        </>
}

export { MRATooltip };
