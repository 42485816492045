import { fetchTimeout } from '../constants';
import timedOutFetch from './fetch-timeout-handler';
import handleApiResponse from './handle-api-response';
import logger from './logger';
import { domainApi, nodeDomainApi } from '../constants/domain-api';
import useZustandAuthStore from '../_Zustand/auth-store';
import handleNApiResponse from './handle-napi-response';
import { v4 as uuidv4 } from 'uuid';
import { datadogLogs } from '@datadog/browser-logs'

const datadogLogFetchCall = (data) => {
    let { reqUrl, postDataStr, correlationId, responseStr, email, status, method } = data;
    if (reqUrl.includes('signature-file-url')) {
        responseStr = "REDACTED";
    }
    datadogLogs.logger.info(`${new Date().toUTCString()} UTC - ${email} - ${correlationId} \n- URL: [${method}] ${reqUrl} \n- ResponseStatus: ${status} \n- InputData: ${postDataStr} \n- ResponseContent: ${responseStr}`);
}

const getAuthHeadersZustand = (): any => {
    const headers = {Authorization: `Bearer ${useZustandAuthStore.getState().authState.apiToken}`};
    const email = useZustandAuthStore.getState().authState.user?.email;
    return { headers, email };
}

const tFetch = timedOutFetch(fetchTimeout.defaultTimeOut);

const defaultParams = { method: 'GET' };

const fetch = async <T = unknown>(
    url: string,
    init: RequestInit = defaultParams,
    isAnon: boolean = false,
): Promise<T> => {
    const isApi = url.startsWith('/api');
    let finalInit = init;
    const correlationId = uuidv4();
    
    if (isApi) {
        const authHeadersFromStorage = await getAuthHeadersZustand();
        const storageHeaders = authHeadersFromStorage.headers;
        const authHeaders: HeadersInit | null = isAnon ? null : storageHeaders;

        let headers: HeadersInit = {
            ...authHeaders,
            ...(init.headers || null),
            "x-correlation-id": correlationId,
        };

        if (!(init.body instanceof FormData)) {
            headers = { ...headers, 'Content-Type': 'application/json' };
        }
        
        finalInit = {
            ...init,
            headers,
        };
    }
    
    const finalUrl = isApi ? `${domainApi}${url.slice(4)}` : url;
    logger.info(`Fetch ${isApi ? 'API' : 'EXTERNAL'} ${finalInit.method}:`, finalUrl);
    
    try {

        const response = await tFetch(finalUrl, finalInit);
        
        if (isApi) {
            const data = await handleApiResponse(response);
            if (datadogLogFetchCall) {
                const postdata = init.body;
                let postDataStr = "";
                if (postdata) {
                    postDataStr = (postdata !== undefined && postdata !== null && (postdata.constructor == Object || postdata.constructor == Array)) ? JSON.stringify(postdata, null, 2) : postdata.toString();
                }
                const responseStr = ((data !== undefined && data !== null) && (data.constructor === Object || data.constructor === Array)) ? JSON.stringify(data, null, 2) : data;
                const authHeadersFromStorage = await getAuthHeadersZustand();
                const email = authHeadersFromStorage.email;
                const status = `${response.status} ${response.statusText}`;
                const method = init.method;

                datadogLogFetchCall({ reqUrl: finalUrl, postDataStr, correlationId, responseStr, email, status, method });
            }
            return data as T;
        }
        
        const json = response.json();
        return json;
    } catch (error: unknown) {
        if (datadogLogFetchCall) {
            const postdata = init.body;
            let postDataStr = "";
            if (postdata) {
                postDataStr = (postdata !== undefined && postdata !== null && (postdata.constructor == Object || postdata.constructor == Array)) ? JSON.stringify(postdata, null, 2) : postdata.toString();
            }
            const responseStr = "ERROR: " + ((error !== undefined && error !== null) && (error.constructor === Object || error.constructor === Array)) ? JSON.stringify(error, null, 2) : error;
            const authHeadersFromStorage = await getAuthHeadersZustand();
            const email = authHeadersFromStorage.email;
            const status = `-`;
            const method = init.method;

            datadogLogFetchCall({ reqUrl: finalUrl, postDataStr, correlationId, responseStr, email, status, method });
        }
        logger.warn('INIT', finalUrl, finalInit);
        throw error;
    }
};

const fetchNApi = async <T = unknown>(
    url: string,
    init: RequestInit = defaultParams,
    isAnon: boolean = false,
): Promise<T> => {
    const isApi = url.startsWith('/api');
    let finalInit = init;
    const correlationId = uuidv4();

    if (isApi) {
        const authHeadersFromStorage = await getAuthHeadersZustand();
        const storageHeaders = authHeadersFromStorage.headers;
        const authHeaders: HeadersInit | null = isAnon ? null : storageHeaders;
        
        let headers: HeadersInit = {
            ...authHeaders,
            ...(init.headers || null),
            "x-correlation-id": correlationId,
        };

        if (!(init.body instanceof FormData)) {
            headers = { ...headers, 'Content-Type': 'application/json' };
        }
        
        finalInit = {
            ...init,
            headers,
        };
    }
    
    console.log("nodeDomainApi: " , nodeDomainApi);
    const finalUrl = isApi ? `${nodeDomainApi}${url.slice(4)}` : url;
    logger.info(`Fetch ${isApi ? 'API' : 'EXTERNAL'} ${finalInit.method}:`, finalUrl);
    
    try {
        const response = await tFetch(finalUrl, finalInit);
        
        const data = await handleNApiResponse(response);
        if (datadogLogFetchCall) {
            const postdata = init.body;
            let postDataStr = "";
            if (postdata) {
                postDataStr = (postdata !== undefined && postdata !== null && (postdata.constructor == Object || postdata.constructor == Array)) ? JSON.stringify(postdata, null, 2) : postdata.toString();
            }
            const responseStr = ((data !== undefined && data !== null) && (data.constructor === Object || data.constructor === Array)) ? JSON.stringify(data, null, 2) : data;
            const authHeadersFromStorage = await getAuthHeadersZustand();
            const email = authHeadersFromStorage.email;
            const status = `${response.status} ${response.statusText}`;
            const method = init.method;

            datadogLogFetchCall({ reqUrl: finalUrl, postDataStr, correlationId, responseStr, email, status, method });
        }
        return data as T;
        
    } catch (error: unknown) {
        if (datadogLogFetchCall) {
            const postdata = init.body;
            let postDataStr = "";
            if (postdata) {
                postDataStr = (postdata !== undefined && postdata !== null && (postdata.constructor == Object || postdata.constructor == Array)) ? JSON.stringify(postdata, null, 2) : postdata.toString();
            }
            const responseStr = "ERROR: " + ((error !== undefined && error !== null) && (error.constructor === Object || error.constructor === Array)) ? JSON.stringify(error, null, 2) : error;
            const authHeadersFromStorage = await getAuthHeadersZustand();
            const email = authHeadersFromStorage.email;
            const status = `-`;
            const method = init.method;

            datadogLogFetchCall({ reqUrl: finalUrl, postDataStr, correlationId, responseStr, email, status, method });
        }
        logger.warn('INIT', finalUrl, finalInit);
        throw error;
    }
};

export { fetch, fetchNApi };
