import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCheckoutSession, postCreatePartnerstackCustomer, postCreatePartnerstackPartner, postCreatePartnerstackTransaction, postSendUpgradeSuccessEmail, putStripeId, putUserPartnerstackKey } from "../../utils/api";
import useZustandAuthStore from "../../_Zustand/auth-store";
import { WaitSpinner } from "../../components/WaitSpinner";

const PaymentResult: FunctionComponent = () => {
    const { authState, privateProfileData } = useZustandAuthStore();
    const { verdict, checkoutSessionId } = useParams(); //comes from route

    const navigate = useNavigate();

    const [ processing, setProcessing ] = useState<boolean>(false);
    const [ errorMessage, setErrorMessage ] = useState<string>('');
    const [ processingMessage, setProcessingMessage ] = useState<string>('');
    const [ processingMessage2, setProcessingMessage2 ] = useState<string>('');
    const [ completedProcessing, setCompletedProcessing ] = useState<boolean>(false);

    const getStripeCustomerId = () => {

        if (completedProcessing) return;

        const inviterId = privateProfileData?.invitedById;
        const inviterPartnerKey = privateProfileData?.invitedByPartnerstackKey;
        const inviterFullName = `${privateProfileData?.invitedByAgentFirstName} ${privateProfileData?.invitedByAgentLastName}`;

        setProcessing(true);
        setProcessingMessage('Processing Payment!');
        
        const email = authState.user?.email;
        if (!email) {
            console.error("Email Null");
            return;
        }

        setProcessingMessage('Processing (0%)');
        setProcessingMessage2('Please do not refresh this page!');

        // checkoutSessionId comes from Stripe in the URL which is matched by the route definition
        getCheckoutSession(checkoutSessionId as string)
            .then((session) => {
                console.log("Checkout Session:", session);
                
                const stripeId = session.customer;

                if(!stripeId || session.status === 'open' || session.payment_status === 'unpaid') {
                    console.error("No Stripe ID");
                    setTimeout(getStripeCustomerId, 3000);
                    return;
                }
                
                console.log("Stripe Cust ID: ", stripeId);
                console.log("Session Status: ", session.status);
                console.log("Payment Status: ", session.payment_status);
                console.log("Subscription ID: ", session.subscription);
                console.log("Total Details: ", session.total_details);

                // send success email
                postSendUpgradeSuccessEmail({
                    firstName: privateProfileData?.firstName,
                    lastName: privateProfileData?.lastName,
                    email: authState.user?.email,
                }).then((result) => {
                    console.log("SuccessEmail Sent");
                }).catch((error) => {
                    console.error(error);
                });

                const stripeAmount = session.amount_subtotal;
                const stripeCurrency = session.currency;
                const stripeTransactionKey = session.id;
                const stripeCreatedAt = session.created * 1000;
                
                // save Stripe ID to our DB
                putStripeId(authState.id, session.customer)
                    .then((result) => {
                        console.log("StripeID Updated!", result);

                        // create partnerstack partner
                        // save partner_key just created to our DB
                        postCreatePartnerstackPartner({
                            email: authState.user?.email as string,
                            firstName: privateProfileData?.firstName as string,
                            lastName: privateProfileData?.lastName as string,
                        })
                            .then((result) => {
                                setProcessingMessage('Processing (25%) - Partnerstack membership');
                                console.log("Partnerstack Partnership Created for :", authState.user?.email);
                                console.log(result.data);
                                const partnerKey = result.data.partner_key;

                                // saving partner_key to mra database
                                putUserPartnerstackKey(authState.id, partnerKey)
                                    .then((result: any) => {
                                        console.log(result);
                                        console.log("Successfully saved partner_key: ", partnerKey);

                                        setProcessingMessage('Processing (50%) - Partnerstack membership');

                                        const name = `${privateProfileData?.firstName as string} ${privateProfileData?.lastName as string}`;
                                        
                                        // what happens if inviterPartnerKey is null?
                                        if (!inviterPartnerKey) {
                                            console.log("All good here ... ");
                                            console.log("Successfully saved partner_key: ", partnerKey);

                                            setProcessingMessage('Processing (100%) - Partnerstack membership');
                                            setProcessing(false);
                                            setCompletedProcessing(true);
                                        }
                                        else {

                                             // josh prod test user: ugly hard coded but needed for now
                                            if (inviterId === 26) {
                                                setProcessingMessage('Processing Complete.');
                                                setProcessing(false);
                                                setCompletedProcessing(true);
                                            }
                                            else {
                                                // if A is a paid member and A invites B and B upgrades
                                            // ... then B should become a customer of A
                                            // ... and record a transaction that B made because A invited B.
                                            // Making B a customer of A and recording transaction
                                            postCreatePartnerstackCustomer({
                                                id: authState.id,
                                                email: authState.user?.email as string,                                
                                                name,
                                                inviterPartnerKey,
                                                stripeId,
                                            })
                                                .then((result) => {
                                                    setProcessingMessage(`Processing (75%) - Linking as Customer of Partnerstack Partner ${inviterFullName}`);
                                                    console.log("PartnerStack Customer Created for: ", authState.user?.email);
                                                    console.log(result.data);

                                                    const psCustomerKey = result.data.key;
                                                    postCreatePartnerstackTransaction({
                                                        mra_id: authState.id,
                                                        amount: stripeAmount,
                                                        currency: stripeCurrency.toUpperCase(),
                                                        created_at: stripeCreatedAt,
                                                    })
                                                        .then(() =>{
                                                            console.log(result);
                                                            console.log("Successfully Created Transaction: ", partnerKey);

                                                            setProcessingMessage('Processing Complete.');
                                                            setProcessing(false);
                                                            setCompletedProcessing(true);
                                                            
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            setErrorMessage("Error: " + error.message);
                                                            setProcessing(false);
                                                            setCompletedProcessing(true);
                                                        });
                                                        
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    if (error.message === "Customer with this email already exists") {
                                                        setErrorMessage(`Seems like you are already a Partnerstack customer of someone else and cannot be a Partnerstack customer of ${inviterFullName}. If this is incorrect please contact us and we'll help you rectify this.`);
                                                    }                                                
                                                    else {
                                                        setErrorMessage("Error: " + error.message);
                                                    }
                                                    setProcessing(false);
                                                    setCompletedProcessing(true);
                                                });
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        setErrorMessage("Error: " + error.message);
                                        setProcessing(false);
                                        setCompletedProcessing(true);
                                    });
                            })
                            .catch((error) => {
                                console.log(error);

                                // handle already partner here ... during renewals this will fail
                                // User with email 'savio+dagentsavio_test@myreferralagent.com' is already member/partner of this company
                                if (error.message.includes('is already member/partner of this company')) {
                                    setProcessingMessage('Processing Complete.');
                                    setProcessing(false);
                                    setCompletedProcessing(true);
                                    return;
                                }

                                setErrorMessage("Error: " + error.message);
                                setProcessing(false);
                                setCompletedProcessing(true);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrorMessage("Error: " + error.message);
                        setProcessing(false);
                        setCompletedProcessing(true);
                    });
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage("Error: " + error.message);
                setProcessing(false);
                setCompletedProcessing(true);
            });
    }

    useEffect(() => {
        if (verdict === "canceled") {
            return;
        }

        if (!privateProfileData?.firstName) {
            return;
        }

        if (verdict === "success" && errorMessage === "") {
            getStripeCustomerId();
        }
        
    }, [privateProfileData?.firstName]);

    useEffect(() => {

        history.replaceState('', 'MRA Profile', '/profile');
        history.pushState('', 'MRA Profile', '/profile');
    }, []);

    return (
        <div className="relative my-0 w-full h-full flex flex-col items-center justify-center bg-surface-primary">
            
                <div className="relative w-full h-full flex flex-col items-center justify-center bg-surface-primary">
                    {processing
                        ? <div className="w-[700px] flex flex-col text-3xl-1 items-center justify-center capitalize gap-[30px]">
                            <div className="flex flex-col gap-[10px] items-center justify-start">
                                {processingMessage
                                    && <img
                                        className="w-[300px]"
                                        src="/img/mra-profile/partnerstack-logo-horizontal.svg"
                                    />
                                }
                                <span>{processingMessage}</span>
                            </div>
                            <span className="text-xl">{processingMessage2}</span>
                            <WaitSpinner sizePx={100} />
                        </div>
                        : <div className="w-[700px] flex flex-col text-3xl-1 items-center justify-center gap-[50px]">
                                {(verdict === 'success' && errorMessage === "")
                                    && <>
                                        <div className="flex flex-col items-center justify-center gap-[30px]">
                                            <img src="/img/mra-profile/VerdictPageSuccess.svg" />
                                            <span className="text-10xl font-bold">Thank you for your payment.</span>
                                            <span className="text-3xl-1 font-bold">We hope you enjoy the benefits of our premium service!</span>
                                            <span className="text-xl">A payment receipt will be sent to your email.</span>
                                        </div>

                                        <div
                                            className="break-all flex items-end justify-end text-center text-[22.05px] text-white cursor-pointer"
                                            onClick={() => navigate("/profile")}
                                            >
                                            <div className="rounded-[33.08px] bg-secondary h-[63px] flex flex-row py-0 px-[38px] box-border items-center justify-center gap-[12.6px]">
                                                <div className="relative leading-[33.08px] font-semibold">
                                                    Complete Your Profile
                                                </div>
                                                <img
                                                    className="relative w-[22.05px] h-[22.05px]"
                                                    alt=""
                                                    src="/img/mra-dashboard/iconsolidshare.svg"
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {(verdict === 'canceled')
                                    && <>
                                        <div className="flex flex-col items-center justify-center gap-[40px] font-bold text-lg">
                                            <img src="/img/mra-profile/VerdictPageCancelled.svg" />
                                            <span>Payment Cancelled</span>
                                        </div>

                                        <div
                                            className="break-all flex items-end justify-end text-center text-[22.05px] text-white cursor-pointer"
                                            onClick={() => navigate("/profile")}
                                            >
                                            <div className="rounded-[33.08px] bg-secondary h-[63px] flex flex-row py-0 px-[38px] box-border items-center justify-center gap-[12.6px]">
                                                <div className="relative leading-[33.08px] font-semibold">
                                                    Go to profile
                                                </div>
                                                <img
                                                    className="relative w-[22.05px] h-[22.05px]"
                                                    alt=""
                                                    src="/img/mra-dashboard/iconsolidshare.svg"
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {(errorMessage !== "")
                                    && <>
                                        <div className="flex flex-col items-center justify-center gap-[20px] font-bold text-lg">
                                            <img src="/img/mra-profile/VerdictPageDeclined.svg" />
                                            <span>Something&apos;s not right.</span>
                                            <span>Please reach out to us if this issue persists.</span>
                                        </div>

                                        <div
                                            className="break-all flex items-end justify-end text-center text-[22.05px] text-white cursor-pointer"
                                            onClick={() => navigate("/profile")}
                                            >
                                            <div className="rounded-[33.08px] bg-secondary h-[63px] flex flex-row py-0 px-[38px] box-border items-center justify-center gap-[12.6px]">
                                                <div className="relative leading-[33.08px] font-semibold">
                                                    Go to profile
                                                </div>
                                                <img
                                                    className="relative w-[22.05px] h-[22.05px]"
                                                    alt=""
                                                    src="/img/mra-dashboard/iconsolidshare.svg"
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                    }
                </div>
        </div>
    );
};

export { PaymentResult };
