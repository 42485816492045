import React, {
    FunctionComponent,
    RefObject,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import _, { first } from "lodash";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import logger from "../../utils/logger";
import StepsHeader from "./components/StepsHeader";
import Step1Source from "./components/Step1Source";
import Step2Client from "./components/Step2Client";
import Step3ReferralType from "./components/Step3ReferralType";
import Step4Agreement from "./components/Step4Agreememt";
import SaveAndContinue from "./components/SaveAndContinue";
import { DIRECTION_OUTBOUND_STR, MembershipTypeFilter, ReferralPropertyType, ReferralStatus, ReferralType, SortOrder, TLocationItem, TNumberList, TPrivateProfileView, TPublicProfileView, TReferralModel, TResponseGetExistReferral, TSelectedUserData, TSendInviteAgentData, TUsState, TUsStateList, TUserDataList, UserRepositorySearchFilter, UserSearchFilterSortOrder, UserStatusEnum } from "../../types";
import { getAllSkyflowW9, getCurrentSubscriptionByStripeId, getCustomerByEmail, getPrivateProfileById, getPublicProfile, getPublicProfileByEmail, getPublicProfileById, getReferralById, getReferralsNew, getUsers, postAccountInvitationalPreRegister, postDropboxSignEmbeddedSignatureRequest, postNewReferral, postNewReferralInbound, postReferralsInviteAgent, postReferralsInviteAgentEmailOnly, postReferralsUsersRequest, updateReferral } from "../../utils/api";
import Modal from 'react-modal';

// eslint-disable-next-line import/no-cycle
import Step5SelectCandidates from "./components/Step5SelectCandidates";
// eslint-disable-next-line import/no-cycle
import { initialReferralFormData } from "./constants";

import './components/react-tabs.css';
import { useNavigate, useParams } from "react-router-dom";
import useZustandAuthStore from "../../_Zustand/auth-store";
import { WaitContainer } from "../../components/WaitContainer";
import { getIsProduction } from "../../utils/get-is-production";
import { ReactSession } from 'react-client-session';
import { getAvatar } from "../../utils/get-avatar";
import { error } from "console";
import { SubscriptionTypeEnum } from "../AgentProfile/AgentProfile";
import { CUSTOM_ROW_ID_ATTRIBUTE } from "skyflow-js/types/core/constants";
import MemberSearchResultCard from "./components/MemberSearchResultCard";
import { useDeepCompareEffect } from "../../utils/use-deep-compare-effect";

export type NonMraCandidate = {
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
}

export type AddressType = {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: number; // stateId
    zipcode: string;
};

export type CityLocationType = {
    city: string;
    stateId: number;
    stateAbbreviation: string;
    placeId?: string;
};

export type ZipCodeLocationType = {
    zipcode: number;
    city?: string;
    stateId: number;
    stateAbbreviation: string;
    placeId?: string;
};

export type LocationType = CityLocationType | ZipCodeLocationType;

export enum CandidateTypeEnum {
    SEARCH_MRA_MEMBERS = 1,
    INVITE_NON_MRA_MEMBERS = 2,
}

export type NewReferralFormStoreType = {
    form: {
        isInbound: boolean,
        source: {
            clientSource: number;
            lastInteracted: number;
            completed: boolean;
        },
        client: {
            clientFirstName: string;
            clientLastName: string;
            clientPhone: string;
            clientEmail: string;
            clientPreapproved: boolean;
            completed: boolean;
        },
        referralType: {
            type: number,
            buyer: {
                locations: Array<LocationType>;
                propertyTypes: Array<ReferralPropertyType>;
                timeFrame: number;
                minPrice: number;
                maxPrice: number;
                comments: string;
            },
            seller: {
                address: AddressType;
                propertyType: ReferralPropertyType;
                targetPrice: number;
                timeFrame: number;
                comments: string;
            },
            selectedAtLeastOnce: boolean;
            completed: boolean;
        },
        agreement: {
            autogenerated: boolean,
            feePercent: number,
            completed: boolean;
        },
        candidateSelection: {
            candidateType: CandidateTypeEnum,
            searchCandidates: TUserDataList | TPublicProfileView[],
            nonMraCandidates: Array<NonMraCandidate>,
            completed: boolean;
        }
        newReferralId: number,
    }
};

const NewReferralOutbound: FunctionComponent = () => {
    // logger.info("Rendering: NewReferralOutbound.");

    const navigate = useNavigate();

    const { newDirectReferralAgentId } = useParams();
    const [directSelectedAgent, setDirectSelectedAgent] = useState<any>(null);
    console.log('NewDirectReferralAgentId: ', newDirectReferralAgentId);

    const [isBuyerLocationsReadonly, setIsBuyerLocationsReadonly] = useState<boolean>(false);

    const [buyerLocations, setBuyerLocations] = useState<Array<LocationType>>([]);
    const onLocationsUpdated = (locations: Array<LocationType>): void => {
        formData.current.form.referralType.buyer.locations = locations;
        setBuyerLocations(locations);
    };

    const { usStates, authState } = useZustandAuthStore();
    const { id } = authState;

    const confirm = useConfirm();

    const LAST_STEP_NUMBER = 5;
    const [currStepNumber, setStepNumber] = useState<number>(1);
    const [candidatesSelected, setCandidatesSelected] = useState<boolean>(false);

    const startDate: Date = useMemo(() => new Date(), []);


    const [referralType, setReferralType] = useState<ReferralType>(ReferralType.buy);
    const [sellerAddress, setSellerAddress] = useState<AddressType>({} as AddressType);

    const [isInbound, setIsInbound] = useState<boolean>(false);
    

    const navigatetoDashboard = (): void => {
        
        avoidRefreshMishaps();

        // navigate("/dashboard");
        location.href="/dashboard";
        
    };

    const [minStepCompleted, setMinStepCompleted] = useState<number>(0);

    const refStep1 = useRef<HTMLFormElement>();
    const refStep2 = useRef<HTMLFormElement>();
    const refStep3 = useRef<HTMLFormElement>();
    const refStep4 = useRef<HTMLFormElement>();
    const refStep5 = useRef<HTMLFormElement>();

    const formData = useRef<NewReferralFormStoreType>(initialReferralFormData());

    console.log("FormData: ", formData.current.form);

    const logFormData = (): void => {
        console.log(JSON.stringify(formData.current.form, null, 4));
    };
    

    // STEP 1 ////
    const onChangeClientSource = (selected): void => {
        const clientSource = selected.value;
        formData.current.form.source.clientSource = parseInt(clientSource, 10);
    };
    const onChangeLastInteraction = (selected): void => {
        const lastInteracted = selected.value;
        formData.current.form.source.lastInteracted = parseInt(lastInteracted, 10);
    };
    const onChangeIsInbound = (evt) => {
        const isIb = evt.target.checked
        setIsInbound(isIb);
        formData.current.form.isInbound = isIb;
    };
    const onValidateStep1 = (): boolean => {
        if (!refStep1.current?.checkValidity()) {
            refStep1.current?.reportValidity();
            return false;
        }

        if (minStepCompleted < currStepNumber) {
            setMinStepCompleted(1);
        }

        formData.current.form.source.completed = true;
        console.log("Step 1 Valid!");
        console.log("Step 1 FormData: ", formData.current.form);
        return true;
    };


    // STEP 2 ////
    const onChangeClientFirstName = (event): void => {
        const clientFirstName = event.target.value;
        formData.current.form.client.clientFirstName = clientFirstName;
    };
    const onChangeClientLastName = (event): void => {
        const clientLastName = event.target.value;
        formData.current.form.client.clientLastName = clientLastName;
    };
    const onChangeClientPhone = (event): void => {
        const clientPhone = event.target.value;
        if (clientPhone === "") {
            return;
        }
        else {
            formData.current.form.client.clientPhone = clientPhone;
        }
    };
    const onChangeClientEmail = (event): void => {
        const clientEmail = event.target.value;
        if (clientEmail === "") {
            return;
        }
        else {
            formData.current.form.client.clientEmail = clientEmail;
        }
    };
    const onValidateStep2 = (): boolean => {
        if (!refStep2.current?.checkValidity()) {
            refStep2.current?.reportValidity();
            return false;
        }

        if (minStepCompleted < currStepNumber) {
            setMinStepCompleted(2);
        }

        formData.current.form.client.completed = true;
        console.log("Step 2 Valid!");
        console.log("Step 2 FormData: ", formData.current.form);
        return true;
    };


    // STEP 3 ////
    const onChangeReferralType = (event): void => {
        const { value } = event.target;
        const intValue = parseInt(value, 10);
        formData.current.form.referralType.type = intValue;
        if (formData.current.form.referralType.selectedAtLeastOnce) {
            toast.warn("Be Aware: Switching between Buyer and Seller could cause changes to your Location Preferences, if different.")
        }
        formData.current.form.referralType.selectedAtLeastOnce = true;
        setReferralType(intValue);
    };
    const onChangeBuyerPropertyType = (event): void => {
        const { checked, value } = event.target;
        const propertyTypes = [...formData.current.form.referralType.buyer.propertyTypes];
        if (checked) {
            propertyTypes.push(parseInt(value, 10));
            formData.current.form.referralType.buyer.propertyTypes = [...new Set(propertyTypes)].sort();
        }
        else {
            formData.current.form.referralType.buyer.propertyTypes = propertyTypes.filter((p) => p !== value);
        }
    };
    const onChangeClientPreApproved = (event): void => {
        const { value } = event.target;
        const clientPreApproved = value === "yes";
        formData.current.form.client.clientPreapproved = clientPreApproved;
    };
    const onValidateStep3 = (): boolean => {
        const { referralType } = formData.current.form;

        // if buyer or seller form is loaded then this will be true
        if (refStep3.current?.singleFamily.setCustomValidity) {

            // check buyer property type selection
            if (referralType.type === ReferralType.buy && referralType.buyer.propertyTypes.length === 0) {
                refStep3.current?.singleFamily.setCustomValidity("Please pick at least one Buyer Property Type!");
                refStep3.current?.reportValidity();
                return false;
            }
            // check seller property type selection
            if (referralType.type === ReferralType.sell && !!referralType.seller.propertyType) {
                refStep3.current?.singleFamily.setCustomValidity("Please pick at least one Seller Property Type!");
                refStep3.current?.reportValidity();
                return false;
            }
            refStep3.current?.singleFamily.setCustomValidity("");
        }

        if (refStep3.current?.buyMaxPrice) {
            // check if buyer max price is >= min price
            if (referralType.type === ReferralType.buy && referralType.buyer.maxPrice < referralType.buyer.minPrice) {
                // refStep3.current?.buyMaxPrice.setCustomValidity("The Max Price should be higher than the Min Price!");
                // refStep3.current?.reportValidity();
                // setTimeout(() => { refStep3.current?.buyMaxPrice.setCustomValidity("") }, 4000);
                toast.error("Max Price should be higher than Min Price");
                return false;
            }
            refStep3.current?.buyMaxPrice.setCustomValidity("");
        }
        // minPrice check is a dual of maxPrice check. No need to implement both.


        // must pick buyer or seller
        if (!refStep3.current?.checkValidity()) {
            refStep3.current?.reportValidity();
            return false;
        }

        if (minStepCompleted < currStepNumber) {
            setMinStepCompleted(3);
        }

        referralType.completed = true;
        
        if (referralType.type === ReferralType.sell) {
            const { address } = formData.current.form.referralType.seller;
            setSellerAddress(address);
            
            const { city, state: stateId } = address;
            const locations = [
                { city, stateId, stateAbbreviation: usStates?.find((s) => s.id === stateId)?.abbreviation as string, placeId: ''},
                // { city, stateId, stateAbbreviation: usStates?.find((s) => s.id === stateId)?.abbreviation as string, zipcode, placeId: ''}
            ];
            setBuyerLocations(locations);
        }

        console.log("Step 3 valid!");
        console.log("Step 3 FormData: ", formData.current.form);
        console.log(formData.current.form.referralType);
        toast.dismiss();
        return true;
    };

    // STEP 3 - BUYER ////
    const onChangeBuyerTimeFrame = (select): void => {
        const { value } = select;
        formData.current.form.referralType.buyer.timeFrame = value;
    };
    const onChangeBuyerMinPrice = (intValue): void => {
        formData.current.form.referralType.buyer.minPrice = intValue;
    };
    const onChangeBuyerMaxPrice = (intValue): void => {
        formData.current.form.referralType.buyer.maxPrice = intValue;

        // console.log(refStep3.current?.buyMaxPrice);
        // console.log(formData.current.form);
        // if (refStep3.current?.buyMaxPrice) {
        //     // check if buyer max price is >= min price
        //     if (referralType.type === ReferralType.buy && referralType.buyer.maxPrice < referralType.buyer.minPrice) {
        //         console.log("max < min");
        //         refStep3.current?.buyMaxPrice.setCustomValidity("Max Price should be higher than Min Price!");
        //         refStep3.current?.buyMaxPrice.reportValidity();
        //         setTimeout(() => {
        //             refStep3.current?.buyMaxPrice.setCustomValidity("");
        //         }, 3000);
        //         return;
        //     }
        //     refStep3.current?.buyMaxPrice.setCustomValidity("");
        // }
    };
    const onChangeBuyerComments = (event): void => {
        const { value } = event.target;
        formData.current.form.referralType.buyer.comments = value;
    };

    // STEP 3 - SELLER ////
    const onChangeSellerAddressLine1 = (event): void => {
        const { value } = event.target;
        formData.current.form.referralType.seller.address.addressLine1 = value;
    };
    const onChangeSellerAddressLine2 = (event): void => {
        const { value } = event.target;
        formData.current.form.referralType.seller.address.addressLine2 = value;
    };
    const onChangeSellerCity = (event): void => {
        const { value } = event.target;
        formData.current.form.referralType.seller.address.city = value;
    };
    const onChangeSellerState = (select): void => {
        formData.current.form.referralType.seller.address.state = select.value;
    };
    const onChangeSellerZipCode = (event): void => {
        const { value } = event.target;
        formData.current.form.referralType.seller.address.zipcode = value;
    };
    const onChangeSellerPropertyType = (event): void => {
        const { checked, value } = event.target;
        if (checked) {
            formData.current.form.referralType.seller.propertyType = parseInt(value, 10);
        }
    };
    const onChangeSellerTimeFrame = (select): void => {
        const { value } = select;
        formData.current.form.referralType.seller.timeFrame = value;
    };
    const onChangeSellerTargetPrice = (intValue): void => {
        formData.current.form.referralType.seller.targetPrice = intValue;
    };
    const onChangeSellerComments = (event): void => {
        const { value } = event.target;
        formData.current.form.referralType.seller.comments = value;
    };


    // STEP 4 ///
    const onChangeFeePercent = (event): void => {
        const value = parseFloat(event.target.value);
        formData.current.form.agreement.feePercent = value;
    };
    const onChangeAutogeneratedAgreement = (event): void => {
        const value = (event.target.value) === "mra"
        formData.current.form.agreement.autogenerated = value;
    };
    const onValidateStep4 = (): boolean => {
        const { agreement } = formData.current.form;
        if (!refStep4.current?.checkValidity()) {
            refStep3.current?.reportValidity();
            return false;
        }

        if (minStepCompleted < currStepNumber) {
            setMinStepCompleted(4);
        }

        agreement.completed = true;
        
        if (directSelectedAgent) {
            formData.current.form.candidateSelection.candidateType = CandidateTypeEnum.SEARCH_MRA_MEMBERS;
            formData.current.form.candidateSelection.completed = false;
            formData.current.form.candidateSelection.searchCandidates = [directSelectedAgent];
            setCandidatesSelected(true);
        }

        console.log("Step 4 valid!");
        console.log("Step 4 FormData: ", formData.current.form);
        return true;
    };


    // STEP 5 - MRA AGENT SELECTION ///
    const onChangeAgentSelectionType = (selected): void => {
        const { value } = selected;
        formData.current.form.candidateSelection.completed = false;
        formData.current.form.candidateSelection.candidateType = value;
        setCandidatesSelected(false);
    };
    const onChangeSearchCandidatesUpdated = (candidatesArr): void => {
        formData.current.form.candidateSelection.searchCandidates = candidatesArr;

        if (formData.current.form.candidateSelection.candidateType === CandidateTypeEnum.SEARCH_MRA_MEMBERS) {
            if (candidatesArr.length > 0) {
                setCandidatesSelected(true);
            }
            else {
                setCandidatesSelected(false);
            }
        }
    };
    // STEP 5 - NON-MRA INVITEE///
    const onChangeNonMraInviteeFirstName = (event): void => {
        const { value } = event.target;
        formData.current.form.candidateSelection.nonMraCandidates[0].firstName = value;
        CheckNonMraInviteeComplete();
    };
    const onChangeNonMraInviteeLastName = (event): void => {
        const { value } = event.target;
        formData.current.form.candidateSelection.nonMraCandidates[0].lastName = value;
        CheckNonMraInviteeComplete();
    };
    const onChangeNonMraInviteePhone = (event): void => {
        const { value } = event.target;
        formData.current.form.candidateSelection.nonMraCandidates[0].phone = value;
        CheckNonMraInviteeComplete();
    };
    const onChangeNonMraInviteeEmail = (event): void => {
        const { value } = event.target;
        formData.current.form.candidateSelection.nonMraCandidates[0].email = value;
        CheckNonMraInviteeComplete();
    };

    // sets candidate-selected -> true if email is almost complete automatically
    const CheckNonMraInviteeComplete = (): void => {
        // const { candidateType, nonMraCandidates } = formData.current.form.candidateSelection;
        // const { email, firstName, lastName, phone } = nonMraCandidates[0];
        // const incomplete = (!email || !firstName || !lastName || !phone);
        // if (candidateType === CandidateTypeEnum.INVITE_NON_MRA_MEMBERS) {
        //     if (incomplete) {
        //         setCandidatesSelected(false);
        //     }
        //     else {
        //         if (onValidateStep5(false)) {
        //             setCandidatesSelected(true);
        //         }
        //         else {
        //             setCandidatesSelected(false);
        //         }
        //     }
        // }
    };
    const isEmailValid = (email: string): boolean => {
        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        return regexp.test(email);
    };
    const onEmailKeyUp = (event): void => {
        // const email = event.target.value;
        // if (isEmailValid(email)) {
        //     if (onValidateStep5(false)) {
        //         setCandidatesSelected(true);
        //     }
        // }
        // else {
        //     setCandidatesSelected(false);
        // }
    };
    const onValidateStep5 = (shouldReportValidity?: boolean): boolean => {

        const { candidateSelection } = formData.current.form;
        if (!refStep5.current?.checkValidity()) {

            if (shouldReportValidity === false) {}
            else {
                refStep5.current?.reportValidity();
            }
            
            candidateSelection.completed = false;
            setCandidatesSelected(false);
            setMinStepCompleted(4);
            return false;
        }

        if (candidateSelection.candidateType === CandidateTypeEnum.SEARCH_MRA_MEMBERS
            && candidateSelection.searchCandidates.length > 0) {}
        else if(candidateSelection.candidateType === CandidateTypeEnum.INVITE_NON_MRA_MEMBERS
            && candidateSelection.nonMraCandidates.length > 0) {
                setCandidatesSelected(true);
            }
        else {
            candidateSelection.completed = false;
            setMinStepCompleted(4);
            setCandidatesSelected(false);
            return false;
        }

        // all good here
        if (minStepCompleted < currStepNumber) {
            setCandidatesSelected(true);
            setMinStepCompleted(5);
        }
        candidateSelection.completed = true;

        console.log("Step 5 valid!");
        console.log("Step 5 FormData: ", formData.current.form);

        return true;
    };

    
    const validateStep = (stepNumber): boolean => {
        switch (stepNumber) {
            case 1: return onValidateStep1();
            case 2: return onValidateStep2();
            case 3: return onValidateStep3();
            case 4: return onValidateStep4();
            case 5: return onValidateStep5();
            default: return false;
        }
    };
    const isAllStepsCompleted = (): boolean => {
        return (
            formData.current.form.source.completed &&
            formData.current.form.client.completed &&
            formData.current.form.referralType.completed &&
            formData.current.form.agreement.completed &&
            formData.current.form.candidateSelection.completed
        );
    };
    const onContinue = (): void => {
        if (sendingReferral) {
            return;
        }
        if (!validateStep(currStepNumber)) {
            console.log("Step ", currStepNumber, " form invalid data!");
            return;
        }

        console.log(formData.current.form);
        console.log("Step ", currStepNumber, " valid!");

        if (currStepNumber < LAST_STEP_NUMBER) {
            setStepNumber(currStepNumber + 1);
        } else {
            logger.info("REACHED THE LAST STEP");
        }
    };
    const onCancel = (): void => {

        if (sendingReferral) {
            return;
        }

        logger.info("Cancelling");

        // ReactSession.set("newDirectReferralAgent", null);

        if (formData.current.form.source.completed) {
            confirm({ description: "Are you sure you wish to cancel creating this referral? All your changes will be lost." })
                .then(() => {
                    navigatetoDashboard();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else {
            navigatetoDashboard();
        }
    };
    const onPrevious = (): void => {
        if (sendingReferral) {
            return;
        }
        if (currStepNumber > 0) {
            setStepNumber(currStepNumber - 1);
        } else {
            logger.info("REACHED THE FIRST STEP");
        }
    };



    const getNewReferralId = (callback): void => {
        getReferralsNew().then((result: any) => {
            console.log(result);
            callback(result.id);
        });
    };
    const [isShowingInvitedEmailAlreadyMemberModal, setIsShowingInvitedEmailAlreadyMemberModal] = useState<boolean>(false);
    const [memberThatAlreadyPresent, setMemberThatAlreadyPresent] = useState<TPublicProfileView | null>(null);
    const [isMemberLocationsMatchReferral, setIsMemberLocationsMatchReferral] = useState<boolean>(true);
    const [referralLocationsAtMatching, setReferralLocationsAtMatching] = useState<any[]|null>(null);
    const [sendingReferralToInvitationMatch, setSendingReferralToInvitationMatch] = useState<boolean>(false);
    const isEmailInvitationAlreadyAMember = (callback): void => {

        const { email } = formData.current.form.candidateSelection.nonMraCandidates[0];
        
        getPublicProfileByEmail(btoa(email))
            .then((member) => {
                console.log(member);

                // ALREADY AN MRA MEMBER
                if(member.email && member.email === email && callback) {
                    if (isInbound) {
                        toast.error("Cannot create an Inbound Referral with an MRA member yet.");
                        setSendingReferral(false);
                        return;
                    }
                    else {
                        callback(member);
                    }
                }
            })
            .catch((error) => {
                setIsShowingInvitedEmailAlreadyMemberModal(false);

                // NOT AN MRA MEMBER
                if (error.message.includes("Something went wrong and we couldn't locate the profile")) {
                    console.log(`Member with email ${email} not found!`);
                    if (callback) {
                        callback(null);
                    }
                    return;
                }
                
                console.error(error);
                toast.error("Sorry something went wrong. Please reach out to support or try again later!");
            });
        
    };
    const [sendingReferral, setSendingReferral] = useState<boolean>(false);
    const onSendReferral = (): void => {
        if (!validateStep(currStepNumber)) {
            toast.error(`Step ${currStepNumber} has invalid information.`);
            return;
        }

        if (!isAllStepsCompleted()) {
            toast.error(`Please make sure all steps are completed ...`);
            return;
        }

        // cleanup buyer or seller before sending
        const initForm = {...initialReferralFormData().form};
        if (formData.current.form.referralType.type === ReferralType.buy) {
            // empty the seller object
            const sellerInitObj = {...initForm.referralType.seller};
            formData.current.form.referralType.seller = {...sellerInitObj};
        }
        else {
            // empty the buyer object
            const buyerInitObj = {...initForm.referralType.buyer};
            formData.current.form.referralType.buyer = {...buyerInitObj};
        }
        
        setSendingReferral(true);

        // check candidate selection type is non-mra-member
        if (formData.current.form.candidateSelection.candidateType === CandidateTypeEnum.SEARCH_MRA_MEMBERS) {
            sendReferralNow();
        }
        else {
            isEmailInvitationAlreadyAMember((memberInfo: TPublicProfileView) => {
                if (!memberInfo) {
                    sendReferralNow();
                }
                else {                        
                    // SHOWING MATCHED MEMBER MODAL
                    toast.warn("This email address is already an MRA member");

                    // create modal show member and pass sendReferralNow and formData reference to modal                        
                    setMemberThatAlreadyPresent(memberInfo);
                    setIsShowingInvitedEmailAlreadyMemberModal(true);

                    // if member has a location match with referral locations or not
                    const {buyer, seller, type} = formData.current.form.referralType;
                    const referralLocationsForModal = (type === ReferralType.buy)
                                                        ? buyer.locations.map((l) => [l.city, l.stateAbbreviation])
                                                        : [[seller.address.city, usStates?.find((s) => s.id === seller.address.state)?.abbreviation]];

                    const referralCityList = (type === ReferralType.buy)
                                                        ? buyer.locations.map((l) => `${l.stateId}${l.city}`.toLowerCase())
                                                        : [ `${seller.address.state}${seller.address.city}`.toLowerCase() ];
                    const memberCityList = memberInfo.servedCities.map((sc) => `${sc.stateId}${sc.city}`.toLowerCase());
                    
                    console.log("Comparing memberCities and referralCities: ", memberCityList, referralCityList);
                    console.log("FormData: ", formData.current.form);
                    const locationsMatch = memberCityList.some((r) => referralCityList.includes(r));
                    
                    setIsMemberLocationsMatchReferral(locationsMatch);
                    setReferralLocationsAtMatching(referralLocationsForModal);

                    return;
                }
            });
        }
    };
    const sendReferralNow = () => {
        setSendingReferral(true);

        // console.log("Sending Referral: FormData: ", formData.current);

        getNewReferralId((referralId) => {
            formData.current.form.newReferralId = referralId;
            const fData = formData.current;
            
            // MRA MEMBERS
            if (formData.current.form.candidateSelection.candidateType === CandidateTypeEnum.SEARCH_MRA_MEMBERS) {
                createNewReferralToMraMember(referralId, fData, ((result) => {
                    console.log("Done: Result: ", result);

                    const createdReferral = JSON.parse(result.content).result.referral;
                    createSignatureRequestAndUpdateReferral(referralId, createdReferral);
                    
                }), ((error) => {
                    console.error(error);

                    toast.error(`There was an error: ${error.message}`);
                    setSendingReferral(false);
                    setSendingReferralToInvitationMatch(false);

                    setTimeout(navigatetoDashboard, 4000);
                }));
            }
            // NON-MRA MEMBERS: Create a provisional new User and then create new referral
            else {
                const form = formData.current.form;
                const nonMraCandidate = form.candidateSelection.nonMraCandidates[0];
                const { email, firstName, lastName, phone } = nonMraCandidate;

                postAccountInvitationalPreRegister({
                    brokerageName: '',
                    email,
                    firstName,
                    lastName,
                    phone,
                    cellPhoneNumber: phone,
                }).then((result: any) => {

                    // toast.dismiss(sendingToastProgress);
                    // sendingToastProgress = toast.loading("Sending Referral ... 65%");

                    console.log("Pre-Register Account Created: ", result);
                    const agentId = result.userId;

                    createNewReferralToNonMraMember(referralId, agentId, fData, ((result) => {
                        console.log("Done: Result: ", result);

                        // toast.dismiss(sendingToastProgress);
                        // sendingToastProgress = toast.loading("Sending Referral ... 75%");

                        if (isInbound) {
                            // Now creating agreement here as invited user has yet to sign up and update broker details!
                            console.log(`Inbound Referral Successfully Created!`);
                            toast.info(`Inbound Referral Successfully Created! ${firstName} will send you an agreement after viewing this referral!`);

                            setSendingReferralToInvitationMatch(false);
                            avoidRefreshMishaps();
                            // setSendingReferral(false);
                            setTimeout(navigatetoDashboard, 4000);
                        }
                        else {
                            const createdReferral = JSON.parse(result.content).result.referral;
                            createSignatureRequestAndUpdateReferral(referralId, createdReferral);
                        }

                    }), ((error) => {

                        // toast.dismiss(sendingToastProgress);
                        console.error("Error: ", error);
                        toast.error(`Error: ${error.message}`);
                        setSendingReferralToInvitationMatch(false);

                        setTimeout(navigatetoDashboard, 4000);
                    }));

                }).catch((error) => {

                    // toast.dismiss(sendingToastProgress);
                    console.error("Error: ", error);
                    toast.error(`Error: ${error.message}`);
                    setSendingReferral(false);
                    setSendingReferralToInvitationMatch(false);

                    setTimeout(navigatetoDashboard, 4000);
                });
                
            }
        });
    };

    const createDropboxSignSignatureRequest = async (
        referral: TReferralModel,
        referralId,
        usStates,
    ): Promise<any> => {
        try {
            
            const response = await postDropboxSignEmbeddedSignatureRequest(referral, referralId, usStates);
            console.log(response);
            const signatureRequestId = response.signature_request.signature_request_id;
            const signatureId = response.signature_request.signatures[0].signature_id;

            console.log("signatureId", signatureId);
            console.log("signatureRequestId", signatureRequestId);

            return { signatureId, signatureRequestId };
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
    const avoidRefreshMishaps = (): void => {
        history.replaceState('', 'MRA Dashboard', '/dashboard');
        history.pushState('', 'MRA Dashboard', '/dashboard');
        history.pushState('', 'MRA Dashboard', '/dashboard');
        history.pushState('', 'MRA Dashboard', '/dashboard');
    };
    const createNewReferralToMraMember = (referralId, fData: NewReferralFormStoreType, callback, errorCallback): void => {

        const buyerLocationsCopy = [...buyerLocations];
        const buyerZipCodes = buyerLocations.filter((l) => (l as ZipCodeLocationType).zipcode).map((l: any) => l.zipcode);
        const buyerOptions = {
            buyLocations: buyerLocationsCopy.reduce((acc: TLocationItem[], curr: any) => {
                                if (!acc.find((l) => (l.city === curr.city && l.stateId === curr.stateId) )) {
                                    acc.push({
                                        city: curr.city,
                                        county: "",
                                        stateAbbreviation: curr.stateAbbreviation,
                                        stateId: curr.stateId,
                                        zip: curr.zipcode ?? -1,
                                        type: 2,
                                    } as TLocationItem);
                                }
                                return acc;
                            }, []),
            buyPropertyTypes: fData.form.referralType.buyer.propertyTypes,
            buyLocationType: 2, // TODO: for city for now
            buyRangeMin: fData.form.referralType.buyer.minPrice,
            buyRangeMax: fData.form.referralType.buyer.maxPrice,
            buyNote: `${(buyerZipCodes && buyerZipCodes.length > 0) ? `Preferred Zipcodes: ${buyerZipCodes.join(',')}\n` : ''}${fData.form.referralType.buyer.comments}`,
            buyTimeFrame: fData.form.referralType.buyer.timeFrame,
        };

        const sellerOptions = {
            sellAddress1: fData.form.referralType.seller.address.addressLine1,
            sellAddress2: fData.form.referralType.seller.address.addressLine2,
            sellCity: fData.form.referralType.seller.address.city,
            sellState: fData.form.referralType.seller.address.state,
            sellZip: fData.form.referralType.seller.address.zipcode,
            sellTargetPrice: fData.form.referralType.seller.targetPrice,
            sellTimeFrame: fData.form.referralType.seller.timeFrame,
            sellPropertyType: fData.form.referralType.seller.propertyType,
            sellNote: fData.form.referralType.seller.comments,
        };

        const postDataPrep = {
            id: fData.form.newReferralId,
            source: fData.form.source.clientSource,
            timeSpoken: fData.form.source.lastInteracted,
            clientFirstName: fData.form.client.clientFirstName,
            clientLastName: fData.form.client.clientLastName,
            clientEmail: fData.form.client.clientEmail,
            clientPhone: fData.form.client.clientPhone,
            agentType: fData.form.candidateSelection.candidateType.valueOf(),
            fee: fData.form.agreement.feePercent,
            useGeneratedAgreement: fData.form.agreement.autogenerated,
            agentId: fData.form.candidateSelection.candidateType === CandidateTypeEnum.SEARCH_MRA_MEMBERS ? fData.form.candidateSelection.searchCandidates[0].id : null,
            agentName: fData.form.candidateSelection.candidateType === CandidateTypeEnum.SEARCH_MRA_MEMBERS
                ? `${fData.form.candidateSelection.searchCandidates[0].firstName} ${fData.form.candidateSelection.searchCandidates[0].lastName}`
                : null,
            agentMessage: "",
            type: fData.form.referralType.type,
            addedDate: null,
            invitationId: null,
            candidates: [fData.form.candidateSelection.candidateType === CandidateTypeEnum.SEARCH_MRA_MEMBERS ? fData.form.candidateSelection.searchCandidates[0].id : null] as TNumberList,
            status: ReferralStatus.sentToAgent,
            candidateSectionAchived: false,
            clientPreapproved: fData.form.client.clientPreapproved,
        }

        const postData = {
            ...postDataPrep,
            ...(fData.form.referralType.type === ReferralType.buy ? buyerOptions : sellerOptions),
        }

        console.log("Sending Referral: PostData: ", postData);
        postNewReferral(referralId, postData).then((result) => {
            callback(result);
        }).catch((error) => {
            errorCallback(error);
        });
    };
    const createNewReferralToNonMraMember = (referralId, agentId, fData: NewReferralFormStoreType, callback, errorCallback): void => {

        const buyerLocationsCopy = [...buyerLocations];
        const buyerZipCodes = buyerLocations.filter((l) => (l as ZipCodeLocationType).zipcode).map((l: any) => l.zipcode);
        const buyerOptions = {
            buyLocations: buyerLocationsCopy.reduce((acc: TLocationItem[], curr: any) => {
                if (!acc.find((l) => (l.city === curr.city && l.stateId === curr.stateId) )) {
                    acc.push({
                        city: curr.city,
                        county: "",
                        stateAbbreviation: curr.stateAbbreviation,
                        stateId: curr.stateId,
                        zip: curr.zipcode ?? -1,
                        type: 2,
                    } as TLocationItem);
                }
                return acc;
            }, []),
            buyPropertyTypes: fData.form.referralType.buyer.propertyTypes,
            buyLocationType: 2, // TODO: for city for now
            buyRangeMin: fData.form.referralType.buyer.minPrice,
            buyRangeMax: fData.form.referralType.buyer.maxPrice,
            buyNote: `${(buyerZipCodes && buyerZipCodes.length > 0) ? `Preferred Zipcodes: ${buyerZipCodes.join(',')}\n` : ''}${fData.form.referralType.buyer.comments}`,
            buyTimeFrame: fData.form.referralType.buyer.timeFrame,
        };

        const sellerOptions = {
            sellAddress1: fData.form.referralType.seller.address.addressLine1,
            sellAddress2: fData.form.referralType.seller.address.addressLine2,
            sellCity: fData.form.referralType.seller.address.city,
            sellState: fData.form.referralType.seller.address.state,
            sellZip: fData.form.referralType.seller.address.zipcode,
            sellTargetPrice: fData.form.referralType.seller.targetPrice,
            sellTimeFrame: fData.form.referralType.seller.timeFrame,
            sellPropertyType: fData.form.referralType.seller.propertyType,
            sellNote: fData.form.referralType.seller.comments,
        };

        const postDataPrep = {
            id: fData.form.newReferralId,
            source: fData.form.source.clientSource,
            timeSpoken: fData.form.source.lastInteracted,
            clientFirstName: fData.form.client.clientFirstName,
            clientLastName: fData.form.client.clientLastName,
            clientEmail: fData.form.client.clientEmail,
            clientPhone: fData.form.client.clientPhone,
            agentType: fData.form.candidateSelection.candidateType.valueOf(),
            fee: fData.form.agreement.feePercent,
            useGeneratedAgreement: fData.form.agreement.autogenerated,
            agentId,
            agentName: fData.form.candidateSelection.candidateType === CandidateTypeEnum.INVITE_NON_MRA_MEMBERS
                ? `${fData.form.candidateSelection.nonMraCandidates[0].firstName} ${fData.form.candidateSelection.nonMraCandidates[0].lastName}`
                : null,
            agentMessage: "",
            type: fData.form.referralType.type,
            addedDate: null,
            invitationId: null,
            candidates: [],
            status: ReferralStatus.sentInvitation,
            candidateSectionAchived: false,
            clientPreapproved: fData.form.client.clientPreapproved,
        }

        const postData = {
            ...postDataPrep,
            ...(fData.form.referralType.type === ReferralType.buy ? buyerOptions : sellerOptions),
        }

        if (isInbound) {
            console.log("Creating Inbound Referral: PostData: ", postData);
            postNewReferralInbound(referralId, postData).then((result) => {
                callback(result);
            }).catch((error) => {
                errorCallback(error);
            });
        }
        else {
            console.log("Sending Referral: PostData: ", postData);
            postNewReferral(referralId, postData).then((result) => {
                callback(result);
            }).catch((error) => {
                errorCallback(error);
            });
        }
        
    };
    const createSignatureRequestAndUpdateReferral = (referralId, referralInfo) => {

        // Creating Signature Request for this Referral
        createDropboxSignSignatureRequest(referralInfo, referralId, usStates as TUsStateList)
            .then((response) => {
                console.log("Done: Result: ", response);
                const { signatureRequestId, signatureId } = response;
        
                referralInfo.status = ReferralStatus.agreementSent;
                referralInfo.agreement = JSON.stringify({ signatureRequestId, signatureId });
                console.log("Updating Referral with Agreement Details: PostData: ", referralInfo);

                // Adding Signature Details to Referral
                updateReferral(referralId, referralInfo)
                    .then((result) => {
                        
                        // REFERRAL SENT
                        console.log("Added signature details to referral: ", result);

                        console.log(`Referral Successfully Sent!`);
                        toast.info(`Referral Successfully Sent!`);

                        setSendingReferralToInvitationMatch(false);
                        avoidRefreshMishaps();
                        // setSendingReferral(false);
                        setTimeout(navigatetoDashboard, 2000);

                    }).catch((error) => {
                        console.error(error);
                        toast.error("Something went wrong when adding Signature Details to Referral. Please contact support with help to resolve this.");

                        avoidRefreshMishaps()
                        // setSendingReferral(false);
                        setSendingReferralToInvitationMatch(false);
                        setTimeout(navigatetoDashboard, 4000);
                    });
        })
        .catch((error) => {
            console.error(error);
            toast.error("Something went wrong with creating the signature request. Please contact support with help to resolve this.");

            avoidRefreshMishaps();
            // setSendingReferral(false);
            setSendingReferralToInvitationMatch(false);
            setTimeout(navigatetoDashboard, 4000);
        });
    };

    const tabHighlighted = ' text-secondary border-b-[3px] border-solid border-dark';
    const tabNumberCircleHighlighted = ' bg-secondary text-white';
    const tabNumberCircleNormal = '  border-[1px] border-solid border-gray-300';
    const tabNumberNormal = ' ';
    const numberPosition = ' top-[15%] left-[36%] ';

    const [isFreeLimitExhausted, setIsFreeLimitExhausted] = useState<boolean>(false);
    const [isFetchingW9Info, setIsFetchingW9Info] = useState<boolean>(false);
    
    useEffect(() => {
        setIsFetchingW9Info(true);

        const checkFreeSendExhaustedAndW9o = (privateProfileData, product) => {

            const outRefsCount = privateProfileData.referrals.filter((r) => r.direction === DIRECTION_OUTBOUND_STR).length;
            if (product === "FREE") {
                if (outRefsCount >= 5) {
                    toast.info("Please upgrade to send more referrals");
                    setIsFreeLimitExhausted(true);
                    setIsFetchingW9Info(false);
                    return;
                }
            }

            getAllSkyflowW9(id)
                .then((response) => {
                    const w9Exists = Array.isArray(response.records) && response.records.length > 0 && response.records[0].fields.w9_file;
                    if (w9Exists) {
                        const profileData = authState.profileUserData;
                        if (
                               !profileData?.officeAddress1 || profileData?.officeAddress1 === ''
                            || !profileData?.officeCity || profileData?.officeCity === ''
                            || !profileData?.officeStateId || profileData?.officeStateId === -1
                            || !profileData?.officeZip || profileData?.officeZip === ''
                        ) {
                            toast.error("Error: Brokerage Address not available in your Profile.")
                            setTimeout(() => {
                                setIsFetchingW9Info(false);
                                navigate('/dashboard');
                            }, 3000);
                        }
                        else {
                            setIsFetchingW9Info(false);
                        }
                    }
                    else {
                        console.log('No W9s');
                        toast.error("Please upload your broker's most recent W-9 to create referrals!");
                        setTimeout(() => {
                            setIsFetchingW9Info(false);
                            navigate('/dashboard');
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setIsFetchingW9Info(false);
                    toast.error("Please upload your broker's most recent W-9 to create referrals!");
                    setTimeout(() => navigate('/dashboard'), 3000);
                });
        };

        let product = SubscriptionTypeEnum.FREE;
        getPrivateProfileById()
            .then((privateProfileData: TPrivateProfileView) => {                

                if (!privateProfileData.stripeId) {
                    product = SubscriptionTypeEnum.FREE;
                    checkFreeSendExhaustedAndW9o(privateProfileData, product);
                } else {
                    getCurrentSubscriptionByStripeId(privateProfileData.stripeId)
                        .then((subscription) => {
                            if (subscription && subscription.product) {
                                product = subscription.product;
                            } else {
                                product = SubscriptionTypeEnum.FREE;
                            }

                            checkFreeSendExhaustedAndW9o(privateProfileData, product);
                        })
                        .catch((error) => {
                            console.error(error);
                            checkFreeSendExhaustedAndW9o(privateProfileData, product);
                        });
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);


    // Direct Member Pick
    useEffect(() => {
        if (newDirectReferralAgentId && newDirectReferralAgentId !== '') {
            getPublicProfile(newDirectReferralAgentId)
                .then((selectedPublicProfileData: TPublicProfileView) => {
                    
                    setDirectSelectedAgent(selectedPublicProfileData);
                    // setIsBuyerLocationsReadonly(true);

                    const cityLocations = selectedPublicProfileData.servedCities.map((c) => {
                        const { city, stateId, stateAbbreviation } = c;
                        return {
                            city,
                            stateId,
                            stateAbbreviation,
                            placeId: '',
                        };
                    });
                    
                    const zipLocations = selectedPublicProfileData.servedZipCodes.map((z) => {
                        const { stateId, stateAbbreviation, zip } = z;
                        return {
                                    stateId: stateId as number,
                                    stateAbbreviation,
                                    zipcode: parseInt(zip as string, 10),
                                    placeId: ''
                            } as ZipCodeLocationType;
                    });

                    const buyerLocations = [
                        ...cityLocations as CityLocationType[],
                        ...zipLocations as ZipCodeLocationType[],
                    ];

                    onLocationsUpdated(buyerLocations);
                    
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [newDirectReferralAgentId]);

    return (
        <div
            className="relative mt-[25px] w-[1280px] h-full mx-auto gap-[44px] text-left text-21xl text-primary font-small-semibold"
            id="DashboardContent"
        >
            {/*  Modal */}
            <Modal
                isOpen={isShowingInvitedEmailAlreadyMemberModal}
                contentLabel="Invited Email Already Member"
                style={{
                    overlay: {
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    },
                    content: {
                        margin: '0 auto',
                        width: '700px',
                        height: '810px',
                        borderRadius: '16px',
                    },
                }}
                ariaHideApp={false}
            >
                <div className="flex flex-col self-stretch p-[10px] items-center justify-center gap-[10px]">
                    <div className="flex self-stretch items-end justify-end cursor-pointer">
                        <img
                            className="w-[30px] h-[30px]"
                            onClick={() => setIsShowingInvitedEmailAlreadyMemberModal(false)}
                            src="/img/mra-dashboard/close-search-button.svg"
                        />
                    </div>
                </div>
                {memberThatAlreadyPresent
                    && <div className="flex flex-col self-stretch p-[10px] items-center justify-center gap-[12px]">
                        
                        <div className="flex flex-col self-stretch p-[10px] items-center justify-center gap-[8px] text-3xl-1 font-bold">
                            {memberThatAlreadyPresent.firstName} {memberThatAlreadyPresent.lastName} is already an MRA Member!
                        </div>
                        
                        <MemberSearchResultCard
                            member={memberThatAlreadyPresent as TPublicProfileView}
                            licensesVerified={false}
                            isMemberSelected={false}
                            enableViewProfileLink={false}
                        />

                        {!isMemberLocationsMatchReferral &&
                            <div className="self-stretch flex flex-col text-brand-red font-bold justify-center items-center gap-[5px]">
                                <span className="uppercase">Please Note</span>
                                <span>Referral location preferences do not match this member's served location(s) !</span>
                            </div>                            
                        }
                        {referralLocationsAtMatching
                            && <div className="self-stretch flex flex-col text-secondary justify-center items-center gap-[10px]">
                                Referral Location Preference: {referralLocationsAtMatching.map((l) => `${l[0]} ${l[1]}`).reduce((prev, curr) => [prev, ', ', curr])}.
                            </div>
                        }

                        <div className="h-[8px]"></div>
                        
                        <div className="self-stretch w-full flex flex-row justify-end">
                            <WaitContainer shouldWaitIf={sendingReferralToInvitationMatch}>
                                <div className="flex flex-row gap-[20px]">
                                    <button
                                        className="items-center justify-center cursor-pointer w-[100px] [border:none] rounded-md h-10 box-border gap-[10px]"
                                        onClick={() => {
                                            setSendingReferral(false);
                                            setIsShowingInvitedEmailAlreadyMemberModal(false);
                                            setSendingReferralToInvitationMatch(false);
                                        }}
                                    >
                                        <div className="relative text-sm leading-[21px] font-semibold font-large-med text-secondary text-center">
                                            Cancel
                                        </div>
                                    </button>
                                    <button
                                        className="items-center justify-center cursor-pointer w-[180px] [border:none] bg-secondary rounded-md h-10 box-border gap-[10px]"
                                        onClick={() => {
                                            // change the invitation type in formData
                                            formData.current.form.candidateSelection.candidateType = CandidateTypeEnum.SEARCH_MRA_MEMBERS;
                                            formData.current.form.candidateSelection.nonMraCandidates = [];
                                            onChangeSearchCandidatesUpdated([memberThatAlreadyPresent]);
                                            formData.current.form.candidateSelection.completed = true;

                                            console.log("FormData: ", formData.current.form);

                                            if(onValidateStep5(false)) {
                                                setSendingReferralToInvitationMatch(true);
                                                sendReferralNow();
                                            }
                                            else {
                                                console.error("Step 5 invalid");
                                                setSendingReferralToInvitationMatch(false);
                                            }
                                            
                                        }}
                                    >
                                        <div className="relative text-sm leading-[21px] font-semibold font-large-med text-light-background-background-1 text-center">
                                            Send Referral {!isMemberLocationsMatchReferral ? 'Anyway':''}
                                        </div>
                                    </button>
                                </div>
                            </WaitContainer>
                        </div>
                    </div>
                }
            </Modal>

            <WaitContainer shouldWaitIf={isFetchingW9Info} waitSizePx={200}>
                {!isFreeLimitExhausted
                    ? <div className="self-stretch rounded-lg bg-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] w-full flex flex-col p-10 box-border items-start justify-start gap-[16px] text-left text-base text-primary font-h5-semibold">
                    {(directSelectedAgent && !!directSelectedAgent.id)
                        && <div className="self-stretch w-fit flex flex-row gap-[20px] bg-surface-primary text-primary rounded-md py-[15px] px-[20px]">
                            <div className="flex items-center">
                                <img
                                    className="rounded-lg w-[100px] h-[100px]"
                                    src={getAvatar(directSelectedAgent.primaryImage, 100)}
                                />
                            </div>
                            <div className="self-stretch flex flex-1 flex-col gap-[10px] p-[10px]">
                                <div className="text-3xl-1">
                                    New Referral with {directSelectedAgent.firstName} {directSelectedAgent.lastName}
                                    <div
                                        className="cursor-pointer text-link-blue hover:underline text-sm"
                                        onClick={() => navigate(`/members/${directSelectedAgent.publicProfile}`)}
                                    >
                                        View Profile
                                    </div>
                                </div>
                                <div className="flex flex-row gap-[10px]">
                                    Cities:
                                    <div className="flex flex-row gap-[10px]">
                                        {directSelectedAgent.servedCities.map((c) => 
                                            <div className="text-md border border-solid border-gray-500 rounded-md px-[5px] py-[2px] bg-gray-100">
                                                {c.city}, {c.stateAbbreviation}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <StepsHeader startDate={startDate} />
                    
                    <WaitContainer waitSizePx={100} shouldWaitIf={sendingReferral} message="Sending This Referral ... ">
                        <Tabs className="w-full" selectedIndex={currStepNumber - 1} onSelect={(index) => setStepNumber(index + 1)} forceRenderTabPanel>
                            <TabList>
                                <Tab>
                                    <div className={`bg-light-background-background-1 flex flex-row py-[15px] px-3 items-center justify-start gap-[10px] ${currStepNumber === 1 && tabHighlighted}`}>
                                        <div className="relative bg-light-background-background-1 w-[30px] h-[30px]">
                                            <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] ${currStepNumber === 1 ? tabNumberCircleHighlighted : tabNumberCircleNormal}`} />
                                            <div className={`absolute ${numberPosition} leading-[21px] font-medium ${currStepNumber === 1 ? tabNumberCircleHighlighted : tabNumberNormal}`}>
                                                1
                                            </div>
                                        </div>
                                        <div className="relative text-base tracking-[0.2px] leading-[24px] font-medium text-primary text-left">
                                            Source
                                        </div>
                                    </div>
                                </Tab>
                                <Tab disabled={minStepCompleted < 1}>
                                    <div className={`bg-light-background-background-1 flex flex-row py-[15px] px-3 items-center justify-start gap-[10px] ${currStepNumber === 2 && tabHighlighted}`}>
                                        <div className="relative bg-light-background-background-1 w-[30px] h-[30px]">
                                            <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] ${currStepNumber === 2 ? tabNumberCircleHighlighted : tabNumberCircleNormal}`} />
                                            <div className={`absolute ${numberPosition} leading-[21px] font-medium ${currStepNumber === 2 ? tabNumberCircleHighlighted : tabNumberNormal}`}>
                                                2
                                            </div>
                                        </div>
                                        <div className="relative text-base tracking-[0.2px] leading-[24px] font-medium text-left">
                                            Client Information
                                        </div>
                                    </div>
                                </Tab>
                                <Tab disabled={minStepCompleted < 2}>
                                    <div className={`bg-light-background-background-1 flex flex-row py-[15px] px-3 items-center justify-start gap-[10px] ${currStepNumber === 3 && tabHighlighted}`}>
                                        <div className="relative bg-light-background-background-1 w-[30px] h-[30px]">
                                            <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] ${currStepNumber === 3 ? tabNumberCircleHighlighted : tabNumberCircleNormal}`} />
                                            <div className={`absolute ${numberPosition} leading-[21px] font-medium ${currStepNumber === 3 ? tabNumberCircleHighlighted : tabNumberNormal}`}>
                                                3
                                            </div>
                                        </div>
                                        <div className="relative text-base tracking-[0.2px] leading-[24px] font-medium text-left">
                                            Referral Type
                                        </div>
                                    </div>
                                </Tab>
                                <Tab disabled={minStepCompleted < 3}>
                                    <div className={`bg-light-background-background-1 flex flex-row py-[15px] px-3 items-center justify-start gap-[10px] ${currStepNumber === 4 && tabHighlighted}`}>
                                        <div className="relative bg-light-background-background-1 w-[30px] h-[30px]">
                                            <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] ${currStepNumber === 4 ? tabNumberCircleHighlighted : tabNumberCircleNormal}`} />
                                            <div className={`absolute ${numberPosition} leading-[21px] font-medium ${currStepNumber === 4 ? tabNumberCircleHighlighted : tabNumberNormal}`}>
                                                4
                                            </div>
                                        </div>
                                        <div className="relative text-base tracking-[0.2px] leading-[24px] font-medium text-left">
                                            Agreement
                                        </div>
                                    </div>
                                </Tab>
                                <Tab disabled={minStepCompleted < 4}>
                                    <div className={`bg-light-background-background-1 flex flex-row py-[15px] px-3 items-center justify-start gap-[10px] ${currStepNumber === 5 && tabHighlighted}`}>
                                        <div className="relative bg-light-background-background-1 w-[30px] h-[30px]">
                                            <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] ${currStepNumber === 5 ? tabNumberCircleHighlighted : tabNumberCircleNormal}`} />
                                            <div className={`absolute ${numberPosition} leading-[21px] font-medium ${currStepNumber === 5 ? tabNumberCircleHighlighted : tabNumberNormal}`}>
                                                5
                                            </div>
                                        </div>
                                        <div className="relative text-base tracking-[0.2px] leading-[24px] font-medium text-left">
                                            Select Agent
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <Step1Source
                                            formDataRef={formData}
                                            directSelectedAgent={directSelectedAgent}
                                            onChangeClientSource={onChangeClientSource}
                                            onChangeLastInteraction={onChangeLastInteraction}
                                            onChangeIsInbound={onChangeIsInbound}
                                            refStep1={refStep1 as RefObject<HTMLFormElement>}
                                        />
                            </TabPanel>
                            <TabPanel>
                                <Step2Client
                                            formDataRef={formData}
                                            onChangeClientFirstName={onChangeClientFirstName}
                                            onChangeClientLastName={onChangeClientLastName}
                                            onChangeClientPhone={onChangeClientPhone}
                                            onChangeClientEmail={onChangeClientEmail}                                            
                                            refStep2={refStep2 as RefObject<HTMLFormElement>}
                                        />
                            </TabPanel>
                            <TabPanel>
                                <Step3ReferralType
                                            formDataRef={formData}
                                            onChangeReferralType={onChangeReferralType}

                                            buyerLocations={buyerLocations}
                                            buyerLocationsReadonly={isBuyerLocationsReadonly}
                                            onLocationsUpdated={onLocationsUpdated}
                                            onChangeBuyerPropertyType={onChangeBuyerPropertyType}
                                            onChangeBuyerTimeFrame={onChangeBuyerTimeFrame}
                                            onChangeBuyerMinPrice={onChangeBuyerMinPrice}
                                            onChangeBuyerMaxPrice={onChangeBuyerMaxPrice}
                                            onChangeBuyerComments={onChangeBuyerComments}
                                            onChangeClientPreApproved={onChangeClientPreApproved}

                                            onChangeSellerAddressLine1={onChangeSellerAddressLine1}
                                            onChangeSellerAddressLine2={onChangeSellerAddressLine2}
                                            onChangeSellerCity={onChangeSellerCity}
                                            onChangeSellerState={onChangeSellerState}
                                            onChangeSellerZipCode={onChangeSellerZipCode}
                                            onChangeSellerPropertyType={onChangeSellerPropertyType}
                                            onChangeSellerTargetPrice={onChangeSellerTargetPrice}
                                            onChangeSellerTimeFrame={onChangeSellerTimeFrame}
                                            onChangeSellerComments={onChangeSellerComments}
                                            refStep3={refStep3 as RefObject<HTMLFormElement>}
                                        />
                            </TabPanel>
                            <TabPanel>
                                <Step4Agreement
                                            formDataRef={formData}
                                            onChangeFeePercent={onChangeFeePercent}
                                            onChangeAutogeneratedAgreement={onChangeAutogeneratedAgreement}
                                            refStep4={refStep4 as RefObject<HTMLFormElement>}
                                        />
                            </TabPanel>
                            <TabPanel>
                                <Step5SelectCandidates
                                            formDataRef={formData}
                                            referralType={referralType}
                                            sellerAddress={sellerAddress}

                                            directSelectedAgent={directSelectedAgent}

                                            isInbound={isInbound}
                                            
                                            buyerLocations={buyerLocations}
                                            onLocationsUpdated={onLocationsUpdated}

                                            onChangeAgentSelectionType={onChangeAgentSelectionType}
                                            onChangeSearchCandidates={onChangeSearchCandidatesUpdated}

                                            onChangeNonMraInviteeFirstName={onChangeNonMraInviteeFirstName}
                                            onChangeNonMraInviteeLastName={onChangeNonMraInviteeLastName}
                                            onChangeNonMraInviteePhone={onChangeNonMraInviteePhone}
                                            onChangeNonMraInviteeEmail={onChangeNonMraInviteeEmail}
                                            onEmailKeyUp={onEmailKeyUp}

                                            refStep5={refStep5 as RefObject<HTMLFormElement>}
                                        />
                            </TabPanel>
                        </Tabs>
                    </WaitContainer>

                    <SaveAndContinue
                        stepNumber={currStepNumber}
                        candidatesSelected={candidatesSelected}
                        onCancel={onCancel}
                        onPrevious={onPrevious}
                        onContinue={onContinue}
                        onSendReferral={onSendReferral}
                        sendingReferral={sendingReferral}
                    />
                </div>
                : <div className="self-stretch rounded-lg bg-white shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] w-full flex flex-row p-10 box-border items-start justify-start gap-[8px] text-left text-base text-primary font-h5-semibold">
                    You have exhausted your Free Limit of 5 outbound referrals! Please <div className='text-link-blue no-underline hover:underline' onClick={() => {navigate('/profile#mra-subscription')}}>upgrade</div> to send more referrals!
                </div>
                }
            </WaitContainer>
        </div>
    );
};

export { NewReferralOutbound };
