import { createBody } from './create-body';
import { fetch } from './fetch';

export const del = <T = unknown>(
    url: string,
    data: unknown = null,
    init: RequestInit | null = null,
    isAnon: boolean = false,
): Promise<T> => fetch<T>(
    url,
    {
        method: 'DELETE',
        body: createBody(data),
        ...init,
    },
    isAnon,
);
