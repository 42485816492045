import React, { FunctionComponent, RefObject } from "react";
import Select from "react-select";
import { ReferralPropertyType, TUsState } from "../../../types";
import useZustandAuthStore from "../../../_Zustand/auth-store";
import { globalReactSelectTheme } from "../../../constants/react-select-theme";
import { NewReferralFormStoreType } from "../NewReferralOutbound";
import { toCurrency } from "../../../utils/to-currency";

type Step3SellerPropsType = {
    formDataRef: RefObject<NewReferralFormStoreType>;
    onChangeSellerAddressLine1: (any) => void;
    onChangeSellerAddressLine2: (any) => void;
    onChangeSellerCity: (any) => void;
    onChangeSellerState: (any) => void;
    onChangeSellerZipCode: (any) => void;
    onChangeSellerPropertyType: (any) => void;
    onChangeSellerTargetPrice: (number) => void;
    onChangeSellerTimeFrame: (any) => void;
    onChangeSellerComments: (any) => void;
};

const Step3bReferralTypeSeller: FunctionComponent<Step3SellerPropsType> = ({
    formDataRef,
    onChangeSellerAddressLine1,
    onChangeSellerAddressLine2,
    onChangeSellerCity,
    onChangeSellerState,
    onChangeSellerZipCode,
    onChangeSellerPropertyType,
    onChangeSellerTargetPrice,
    onChangeSellerTimeFrame,
    onChangeSellerComments,
}) => {

    const formDataStep = formDataRef.current?.form.referralType.seller;
    console.log("Step3-Seller: FormDataStep: ", formDataStep);

    const { usStates } = useZustandAuthStore();
    const usStatesList = usStates as Array<TUsState>;

    const sellTimeFrameOptions = [
        { label: 'Within 30 days ', value: 1 },
        { label: '30 - 90 days ', value: 2 },
        { label: 'After 90 days ', value: 3 },
        { label: 'Unknown', value: 4 }
    ];

    const stateOptions = Object.values(usStatesList).map((s) => { return { 'label': s.name, 'value': s.id }; });
    const selectStyle = {
        control: (base) => ({
            ...base,
            height: 40,
            minHeight: 35
        })
    };

    const formatAndUpdateSellerTargetPrice = (event): void => {
        const strValue = event.target.value;
        if (strValue === '') {
            onChangeSellerTargetPrice(-1);
        }
        const intValue = parseInt(strValue.replace(/\D/g, ''), 10);
        onChangeSellerTargetPrice(intValue);
    }

    const toCurrencyInline = (event): void => {
        const str = event.target.value;
        const parts = str.split('.');
        const value = parts[0].replace(/\D/g, '');

        const newValue = `$${new Intl.NumberFormat('en-US').format(value)}`;

        event.target.value = value === '' && newValue === '$0' ? '' : newValue;
    }

    return (
        <div className="self-stretch flex flex-col py-0 pr-0 pl-3 items-start justify-start rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)]">
            <div className="self-stretch  flex flex-col p-6 gap-[30px]">
                
                <span className="text-xl">Seller Referral</span>

                {/* left column */}
                <div className="flex-1 rounded-lg flex flex-row items-start justify-start gap-[90px]">

                    {/* Address */}
                    <div className="flex flex-col items-start justify-start gap-[20px]">
                        <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Address</span>
                                <span className="text-slategray">*</span>
                            </div>
                            <input
                                className="font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                type="text"
                                placeholder="Street number & street name"
                                // onBlur={onChangeSellerAddressLine1}
                                onChange={onChangeSellerAddressLine1}
                                defaultValue={formDataStep?.address.addressLine1}
                                autoComplete="off"
                                tabIndex={1}
                                required
                            />
                        </div>
                        <input
                            className="font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                            type="text"
                            placeholder="Apt, suite, unit, building, floor, etc."
                            // onBlur={onChangeSellerAddressLine2}
                            onChange={onChangeSellerAddressLine2}
                            defaultValue={formDataStep?.address.addressLine2}
                            autoComplete="off"
                            tabIndex={2}
                        />
                        <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">City</span>
                                <span className="text-slategray">*</span>
                            </div>
                            <input
                                className="font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                type="text"
                                placeholder=""
                                // onBlur={onChangeSellerCity}
                                onChange={onChangeSellerCity}
                                defaultValue={formDataStep?.address.city}
                                autoComplete="off"
                                tabIndex={3}
                                required
                            />
                        </div>
                        <div className="flex flex-row items-start justify-start gap-[16px]">
                            <div className="w-[214px] flex flex-col items-start justify-start gap-[4px]">
                                <div className="relative tracking-[0.1px] leading-[24px]">
                                    <span className="font-semibold">State</span>
                                    <span className="text-slategray">*</span>
                                </div>
                                <Select
                                    className="w-full"
                                    styles={selectStyle}
                                    theme={globalReactSelectTheme}
                                    options={stateOptions}
                                    onChange={onChangeSellerState}
                                    defaultValue={stateOptions.find((s) => s.value === formDataStep?.address.state)}
                                    tabIndex={4}
                                    required
                                />
                            </div>
                            <div className="w-[100px] flex flex-col items-start justify-start gap-[4px]">
                                <div className="relative tracking-[0.1px] leading-[24px]">
                                    <span className="font-semibold">Zip Code</span>
                                    <span className="text-slategray">*</span>
                                </div>
                                <input
                                    className="font-h5-semibold text-base bg-light-background-background-1 self-stretch rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[15px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    type="number"
                                    placeholder=""
                                    min={10001}
                                    max={99999}
                                    // onBlur={onChangeSellerZipCode}
                                    onChange={onChangeSellerZipCode}
                                    defaultValue={(formDataStep?.address.zipcode === '-1' || formDataStep?.address.zipcode === -1) ? '' : formDataStep?.address.zipcode}
                                    autoComplete="off"
                                    tabIndex={5}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* right column */}
                    <div className="flex flex-col items-start justify-start gap-[20px]">

                        {/* Property Types */}
                        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                            <div className="w-full flex flex-col items-start justify-start gap-[4px]">
                                <div className="relative tracking-[0.1px] leading-[24px]">
                                    <span className="font-semibold">Property Type</span>
                                    <span className="text-brand">*</span>
                                </div>
                                <div className="self-stretch flex flex-row pt-3 pb-0 pr-0 pl-[5px] items-start justify-start">
                                    <div className="w-[40%] flex flex-col items-start justify-start gap-[8px]">
                                        <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                            <input
                                                className="cursor-pointer relative w-4 h-4"
                                                type="radio"
                                                name="singleFamily"
                                                value={ReferralPropertyType.singleFamily}
                                                defaultChecked={formDataStep?.propertyType === ReferralPropertyType.singleFamily}
                                                onChange={onChangeSellerPropertyType}
                                                tabIndex={6}
                                                required
                                            />
                                            <div className="relative tracking-[0.1px] leading-[24px]">
                                                Single Family
                                            </div>
                                        </label>
                                        <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                            <input
                                                className="cursor-pointer relative w-4 h-4"
                                                type="radio"
                                                name="singleFamily"
                                                value={ReferralPropertyType.condominium}
                                                defaultChecked={formDataStep?.propertyType === ReferralPropertyType.condominium}
                                                onChange={onChangeSellerPropertyType}
                                                required
                                            />
                                            <div className="relative tracking-[0.1px] leading-[24px]">
                                                Condo
                                            </div>
                                        </label>
                                        <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                            <input
                                                className="cursor-pointer relative w-4 h-4"
                                                type="radio"
                                                name="singleFamily"
                                                value={ReferralPropertyType.townhome}
                                                defaultChecked={formDataStep?.propertyType === ReferralPropertyType.townhome}
                                                onChange={onChangeSellerPropertyType}
                                                required
                                            />
                                            <div className="relative tracking-[0.1px] leading-[24px]">
                                                Townhome
                                            </div>
                                        </label>
                                    </div>
                                    <div className="w-[40%] flex-1 flex flex-col items-start justify-start gap-[8px]">
                                        <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                            <input
                                                className="cursor-pointer relative w-4 h-4"
                                                type="radio"
                                                name="singleFamily"
                                                value={ReferralPropertyType.multiFamily}
                                                defaultChecked={formDataStep?.propertyType === ReferralPropertyType.multiFamily}
                                                onChange={onChangeSellerPropertyType}
                                                required
                                            />
                                            <div className="relative tracking-[0.1px] leading-[24px]">
                                                Multi-Family
                                            </div>
                                        </label>
                                        <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                            <input
                                                className="cursor-pointer relative w-4 h-4"
                                                type="radio"
                                                name="singleFamily"
                                                value={ReferralPropertyType.farmRanch}
                                                defaultChecked={formDataStep?.propertyType === ReferralPropertyType.farmRanch}
                                                onChange={onChangeSellerPropertyType}
                                                required
                                            />
                                            <div className="relative tracking-[0.1px] leading-[24px]">
                                                Farm/Ranch
                                            </div>
                                        </label>
                                        <label className="w-full flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                                            <input
                                                className="cursor-pointer relative w-4 h-4"
                                                type="radio"
                                                name="singleFamily"
                                                value={ReferralPropertyType.lotsLand}
                                                defaultChecked={formDataStep?.propertyType === ReferralPropertyType.lotsLand}
                                                onChange={onChangeSellerPropertyType}
                                                required
                                            />
                                            <div className="relative tracking-[0.1px] leading-[24px]">
                                                Lots/Land
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Target Price */}
                        <div className="w-[370px] h-[72px] flex flex-col items-start justify-start gap-[4px]">
                            <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                                Target Price
                                <span className="text-slategray">*</span>
                            </div>

                            <div className="w-full flex flex-row items-start justify-start ">
                                <input
                                    className="w-full font-h5-semibold text-base bg-light-background-background-1 rounded-[5px] overflow-hidden flex flex-row py-2.5 px-[10px] items-center justify-start border-[1px] border-solid border-gray-400"
                                    type="text"
                                    placeholder=""
                                    onKeyUp={toCurrencyInline}
                                    // onBlur={formatAndUpdateSellerTargetPrice}
                                    onChange={formatAndUpdateSellerTargetPrice}
                                    defaultValue={formDataStep?.targetPrice === -1 ? '' : toCurrency(formDataStep?.targetPrice)}
                                    autoComplete="off"
                                    required
                                />
                                {/* <input type="text" name="currency-field" id="currency-field" pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" value="" data-type="currency" placeholder="$1,000,000.00" /> */}
                            </div>
                        </div>

                        <div className="self-stretch w-full flex flex-col items-start justify-start gap-[8px]">
                            <div className="relative tracking-[0.1px] leading-[24px]">
                                <span className="font-semibold">Time-frame</span>
                                <span className="text-brand">*</span>
                            </div>
                            <Select
                                className="w-[47%]"
                                onChange={onChangeSellerTimeFrame}
                                theme={globalReactSelectTheme}
                                options={sellTimeFrameOptions}
                                defaultValue={sellTimeFrameOptions.find((o) => o.value === formDataStep?.timeFrame)}
                                required
                            />
                        </div>
                    </div>
                    
                </div>

                {/* Comments */}
                <div className="w-[330px] h-[160px] flex flex-col items-start justify-start gap-[4px]">
                    <div className="relative tracking-[0.1px] leading-[24px] font-semibold">
                        Other details or comments
                    </div>
                    <textarea
                        className="bg-light-background-background-1 font-sans text-base self-stretch flex-1 rounded-md flex flex-col p-4 items-start justify-start border-[1px] border-solid border-gray-400 overflow-hidden resize-none outline-none"
                        placeholder="Example: Willing to adjust to market conditions."
                        // onBlur={onChangeSellerComments}
                        onChange={onChangeSellerComments}
                        defaultValue={formDataStep?.comments}
                        autoComplete="off"
                        maxLength={150}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step3bReferralTypeSeller;
