import { FunctionComponent, useState } from "react";
import AsyncSelect from 'react-select/async';
import { StylesConfig } from "react-select";
import type { LocationType } from "../NewReferralOutbound";
import { onlyNumbersAndSpace, validateZipcode } from "../../../utils/validate-zipcode";
import { TUsState } from "../../../types";
import { useDeepCompareEffect } from "../../../utils/use-deep-compare-effect";
import useZustandAuthStore from "../../../_Zustand/auth-store";
import { globalReactSelectTheme } from "../../../constants/react-select-theme";
import { getGMapsAddressForZipCode } from "../../../utils/api";
import { debounce } from "lodash";
import { toast } from "react-toastify";

type BuyerLocationsInputPropsType = {
    locations: Array<LocationType>;
    onLocationsUpdated: (locations: Array<LocationType>) => void;
    required?: boolean,
    isMultiple?: boolean,
    includeZipCode?: boolean,
    readonly?: boolean,
}

const BuyerLocationsInput: FunctionComponent<BuyerLocationsInputPropsType> = ({
    locations,
    onLocationsUpdated,
    required = false,
    isMultiple = true,
    includeZipCode = true,
    readonly = false,
}) => {

    const { usStates } = useZustandAuthStore();    
    const [formattedLocations, setFormattedLocations] = useState<Array<LocationType>>([]);
    const googlePlacesAutocompleteService = new google.maps.places.AutocompleteService();
    let runSelectCallback;
    
    const loadOptions = (inputValue: string, updateOptionsCallback: (options: any) => void): void => {
        runSelectCallback = updateOptionsCallback;
        
        if (!inputValue) return;
        if (onlyNumbersAndSpace(inputValue)) {
            if(includeZipCode && validateZipcode(inputValue)) {
                console.log("Fetching zipcode data");
                // googlePlacesAutocompleteService.getPlacePredictions({ input: inputValue, componentRestrictions: {country: 'us'} }, curateGooglePlaceZipcodeSuggestions);
                getGMapsAddressForZipCode(inputValue)
                    .then((response) => response.json())
                    .then((result) => curateZipcodeSuggestions(result.result))
                    .catch((error) => console.error(error))
            }
        }
        else {
            console.log("Fetching city data");
            googlePlacesAutocompleteService.getPlacePredictions({ input: inputValue, types: ['(cities)'], componentRestrictions: {country: 'us'} }, curateGooglePlaceCitySuggestions);
        }
    };

    const curateZipcodeSuggestions = (result): void => {
        if(runSelectCallback) {
            const confirmationLevel = result.address.addressComponents.find((c) => c.componentType === 'postal_code').confirmationLevel;
            if(confirmationLevel === 'CONFIRMED') {
                const formattedAddress = result.address.formattedAddress;

                const { 
                    locality: city,
                    administrativeArea: stateAbbreviation,
                    postalCode: zipcode,
                } = result.address.postalAddress;
                const stateId = usStates?.find((s) => s.abbreviation === stateAbbreviation)?.id;                
                const placeId = result.geocode.placeId;
    
                const zipcodeOptionList = [{
                    label: `ZipCode: ${formattedAddress}`,
                    value: zipcode,
                    location: { zipcode, city, stateAbbreviation, stateId, placeId }
                }]
                runSelectCallback(zipcodeOptionList);
            }
            else if(confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE') {
                toast.error("We could not validate this zipcode. Please reach out to us if this is incorrect.");
                runSelectCallback([]);
            }
        }
    };

    const curateGooglePlaceZipcodeSuggestions = (
        predictions: google.maps.places.AutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
    ): void => {
        console.log(status);
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return;
        }
        
        if(runSelectCallback) {
            const zipcodeOptionsList = predictions
                .filter((p) => p.types.includes("postal_code")).map((p) => {
                    const city = p.terms[0].value;
                    const stateAbbreviation = p.terms[1].value;
                    const stateId = usStates?.find((s) => s.abbreviation === stateAbbreviation)?.id;
                    const zipcode = p.terms[2].value;
                    const placeId = p.place_id;

                    return {
                        label: `ZipCode: ${p.description}`,
                        value: zipcode,
                        location: { zipcode, city, stateAbbreviation, stateId, placeId }
                    }
                });

            runSelectCallback(zipcodeOptionsList);
        }
    };
    
    const curateGooglePlaceCitySuggestions = (
        predictions: google.maps.places.QueryAutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
    ): void => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return;
        }
        
        if(runSelectCallback) {
            runSelectCallback(predictions.filter((p) => {
                const st = p.terms[1].value;
                return (st.length === 2 && usStates?.filter((s: TUsState) => s.abbreviation === st).length === 1)
            }).map((p) => {
                const city = p.terms[0].value;
                const stateAbbreviation = p.terms[1].value;
                const stateId = usStates?.find((s) => s.abbreviation === stateAbbreviation)?.id;
                const placeId = p.place_id;
                const cityState = `${city}, ${stateAbbreviation}`;
                return {
                    label: `City: ${cityState}`,
                    value: cityState,
                    location: { city, stateAbbreviation, stateId, placeId, p }
                }
            }));
        }
    };

    const updateLocations = (selected): void => {
        if(Array.isArray(selected)) {
            const locs = selected.map((s) => s.location);
            onLocationsUpdated(locs);
        }
        else {
            onLocationsUpdated(selected);
        }
        
    };

    const locationsToSelectOptionsFormat = (locations): any => {
        
        const formattedLocations: Array<any> = [];

        if (locations && locations.length > 0) {
            locations.forEach((location: any): void => {
                if(location) {
                    if(location.zipcode) {
                        formattedLocations.push({
                            label: `Zip: ${location.zipcode}`,
                            value: location.zipcode,
                            location
                        });
                    }
                    else {
                        const { city, stateAbbreviation } = location;
                        const cityState = `${city}, ${stateAbbreviation}`;
                        formattedLocations.push({
                            label: `City: ${cityState}`,
                            value: cityState,
                            location,
                        });
                    }
                }
            });
        }
        return (formattedLocations);
    };

    

    const placeholder = `Enter a city${includeZipCode ? ' or a zipcode':''}`;
    const colourStyles: StylesConfig<any, true> = {
        control: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            padding: '5px',
            boxShadow: '0px 0px 3px 1px rgba(128, 128, 128, 0.3)',
            border: 'none',
            ':focus': {
                border: 'none',
            }
        }),
        option: (styles) => ({
            ...styles,
            margin: 'none',
            backgroundColor: 'white',
            ':hover': {
                backgroundColor: `#eeeff3`,
                cursor: 'pointer',
            },
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            color: data.color,
            backgroundColor: '#E6F5FE',
            border: '1px solid #78AFD5',
            borderRadius: '4px',
            marginRight: '10px'
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            cursor: 'pointer',
            ':hover': {
                backgroundColor: data.color,
                // border: '1px solid #8af',
                color: 'red',
            },
        }),
    };

    useDeepCompareEffect(() => {
        console.log("floc check");
        const floc = locationsToSelectOptionsFormat(locations);
        if (floc && Array.isArray(floc)) {
            setFormattedLocations(floc);
        }
    }, [locations]);

    return (
        <div className="flex w-full min-w-[300px]">
            <AsyncSelect
                
                isMulti={isMultiple ? isMultiple as true : undefined}
                
                value={formattedLocations}
                onChange={updateLocations}
                loadOptions={loadOptions}

                className="basic-multi-select w-full"
                placeholder={placeholder}                
                styles={colourStyles}
                theme={globalReactSelectTheme}
                required={required}
                isDisabled={readonly}
                onFocus={e => {
                    if (e.target.autocomplete) {
                        e.target.autocomplete = "off";
                    }
                }}
            />
        </div>
    );
}

export default BuyerLocationsInput;
