import { TCbVoid } from 'sierra-ui';

const MAX_STEP = 30 * 1000;
const MIN_STEP = 200;


// Runs setTimeout in steps, until the target time reached
// See https://github.com/facebook/react-native/issues/12981
// Returns a clearing callback (instead of a timer ID)
const setTimeoutLong = (cb: TCbVoid, delay: number): TCbVoid => {
    // One step, simple case
    if (delay < MAX_STEP) {
        const timer: number | null = setTimeout(cb, delay) as unknown as number;
        return () => clearTimeout(timer);
    }
    
    let isStopped: boolean = false;
    let currentTimer: number | null = null;
    const stop = (): void => {
        if (isStopped) {
            return;
        }
        isStopped = true;
        if (currentTimer) {
            clearTimeout(currentTimer);
            currentTimer = null;
        }
    };
    
    const startedAt = Date.now();
    const endsAt = startedAt + delay;
    
    const update = (res: TCbVoid): void => {
        const step = Math.min(MAX_STEP, endsAt - Date.now());
        currentTimer = setTimeout(res, step) as unknown as number;
    };
    
    (async () => {
        while (endsAt - MIN_STEP > Date.now()) {
            // eslint-disable-next-line no-await-in-loop
            await new Promise<void>(update);
            currentTimer = null;
        }
        if (!isStopped) {
            cb();
        }
    })();
    
    return stop;
};

export default setTimeoutLong;
