import { TStringList } from 'sierra-ui';
import {
    TStateOperation,
} from '../types';
import { getIsProduction } from '../utils/get-is-production';


export const defaultOperationState: TStateOperation = {
    message: null,
    status: 'none',
    data: null,
};

export const fetchTimeout = {
    defaultTimeOut: 1000 * 60 * 5,
};

export const guid = (): string => {
    const s4 = (): string => (
        Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    );

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const keyGoogleMap: string = 'AIzaSyBwFY0T_u9x4gCGXCFeEznkTIn2jFsl2CQ';
export const keyStripe: string = getIsProduction()
    ? 'pk_live_4OwscOigTsQ4ySED8tndz4QV'
    : 'pk_test_xa9h3y1Z23tnrCXO7n8YKr3S';
export const keyRecaptcha: string = '6LfI5S8UAAAAAEnTgP_-bttcI8Lja-51XxdnUBun';

export const stateList: TStringList = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
    'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
    'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
    'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah',
    'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
    'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland ans Labrador',
    'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan',
];

export const months = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
];

export const titlePageDefault: string = 'My Referral Agent';

export const searchRequestDelay: number = 700;
