import React, { FunctionComponent } from 'react';

type StepsHeaderProps = {
    startDate: Date;
}

const StepsHeader: FunctionComponent<StepsHeaderProps> = ({startDate}) => {
    return (
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-xl">
            <div className="self-stretch flex flex-row items-start justify-start">
                <div className="flex-1 relative tracking-[-0.24px] leading-[26px] font-semibold">
                    Create A New Referral
                </div>
            </div>
            <div className="relative text-sm leading-[21px] font-medium text-tertiary">
                {startDate.toString()}
            </div>
            <div className="self-stretch relative bg-gainsboro h-0.5" />
        </div>
    );
}

export default StepsHeader;
