export const writeDeepLink = () => {
    const path = window.location.pathname;
    console.log(path);
    if (path.startsWith("/referrals/")) {
        const timestamp = Date.now();
        console.log("Saving DeepLink");
        sessionStorage.setItem("MRA_PREAUTH_DEEPLINK", JSON.stringify({path, timestamp}));
        console.log(getDeepLink());
    }
};

export const resetDeepLink = () => {
    sessionStorage.removeItem("MRA_PREAUTH_DEEPLINK");
};

export const followDeepLinkRedirect = (deeplinkPath: string) => {
    resetDeepLink();
    location.href = deeplinkPath;
};

export const getDeepLink = () => {
    const deeplinkObj = sessionStorage.getItem("MRA_PREAUTH_DEEPLINK");
    if (!deeplinkObj) {
        return null;
    }

    try {
        const {path, timestamp} = JSON.parse(deeplinkObj);
        if (!path) {
            return null;
        }
    
        if (!timestamp) {
            return null;
        }
    
        const ONE_HOUR_IN_MS = 60 * 60 * 1000;
        if ((Date.now() - timestamp) > ONE_HOUR_IN_MS) {
            return null;
        }
    
        return path;
    }
    catch (err) {
        console.error(err);
        return null;
    }
    
};
