import { FunctionComponent, RefObject  } from "react";
import Select from "react-select";
import { globalReactSelectTheme } from "../../../constants/react-select-theme";
import { NewReferralFormStoreType } from "../NewReferralOutbound";
import { MRATooltip } from "../../../components/MRATooltip";

type Step1PropsType = {
    formDataRef: RefObject<NewReferralFormStoreType>;
    directSelectedAgent: boolean;
    onChangeClientSource: (any) => void;
    onChangeLastInteraction: (any) => void;
    onChangeIsInbound: (any) => void;
    refStep1: RefObject<HTMLFormElement>;
};

const Step1Source: FunctionComponent<Step1PropsType> = ({ formDataRef, directSelectedAgent, onChangeClientSource, onChangeLastInteraction, onChangeIsInbound, refStep1 }) => {

    // logger.info("\tRendering: Step1");

    const formDataStep = formDataRef.current?.form.source;
    console.log("Step1: FormDataStep: ", formDataStep);
    
    const clientSourceOptions = [
        {value: 2, label: 'Personal Referral'},
        // {value: 3, label: 'Business Referral'},
        {value: 1, label: 'Raw Lead'},
    ];

    const lastInteractionOptions = [
        {value: 10, label: 'Never'},
        {value: 1, label: 'Yesterday'},
        {value: 2, label: '2 Days Ago'},
        {value: 3, label: '1 Week ago'},
        {value: 6, label: '1 Month ago'},
        {value: 8, label: '3 Months ago'},
    ];

    return (
            
        <form ref={refStep1} role="presentation" autoComplete="off">
            <div className="self-stretch flex-1 flex flex-col p-5 items-start justify-start gap-[32px]">

                {!directSelectedAgent
                    && <div className="cursor-pointer flex flex-row items-center justify-start gap-[12px]">
                        <label className="cursor-pointer flex flex-row items-center leading-[24px] gap-[20px]">
                            <input
                                className="cursor-pointer w-4 h-4"
                                type="checkbox"
                                name="isInbound"
                                id="isInboundChkbox"
                                tabIndex={5}
                                onClick={onChangeIsInbound}
                                defaultChecked={false}
                            />
                            <div className="flex flex-row items-center gap-[2px] font-semibold">
                                <span>This is an Inbound Referral</span>
                                <MRATooltip id="inbound-referral-checkbox" message="You are creating a referral on behalf of the Referring Agent (Sender)" />
                            </div>
                            
                        </label>
                    </div>
                }

                <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                    <div className="relative tracking-[0.1px] leading-[24px]">
                        <span className="font-semibold">
                            How do you know the client(s)?
                        </span>
                        <span className="text-brand">*</span>
                    </div>
                    <Select
                        className="w-full"
                        name="clientSource"
                        theme={globalReactSelectTheme}
                        options={clientSourceOptions}
                        defaultValue={clientSourceOptions.find((o) => o.value === formDataStep?.clientSource)}
                        onChange={onChangeClientSource}
                        placeholder="Select ..."
                        required
                    />
                </div>

                <div className="w-[330px] flex flex-col items-start justify-start gap-[4px]">
                    <div className="relative tracking-[0.1px] leading-[24px]">
                        <span className="font-semibold">
                            When was your last interaction?
                        </span>
                        <span className="text-brand">*</span>
                    </div>
                
                    <Select
                        className="w-full"
                        name="clientLastInteraction"
                        theme={globalReactSelectTheme}
                        options={lastInteractionOptions}
                        defaultValue={lastInteractionOptions.find((o) => o.value === formDataStep?.lastInteracted)}
                        onChange={onChangeLastInteraction}
                        placeholder="Select ..."
                        required
                    />
                
                </div>
            </div>
        </form>
    );
};

export default Step1Source;
