import { emptyArray, emptyObject, emptyString } from '../utils/sierra';
import {
    TStateAuth, TStateApp, TStateOperations, TStateProfile, TStateCounters,
    TStateSpecialties, TStateDesignations, TStateLanguages, TStateUsStates,
    TStateReferralsUserOutbound, TStateReferralsUserOutboundDict, TStateDashboard,
    DashboardVerifyStatus, TStateViewReferral, ReferralOwner,
    TStateReferralsUserInboundDict, TStateReferralsUserInbound,
    TViewReferralModelBase,
    TStateViewReferralInbound, ReferralStatus, TStateViewReferralInboundAccept,
    TStateSourceOfBusiness,
    TSelectCandidatesData,
    LocationType,
    TPaginationData,
    TValidationClientInformation,
    TClientInformationData,
    TReferralTypeData,
    TAppliedCriteria,
    TReferralAgreementData,
    TValidationReferralType,
    TValidationBuyForm,
    TValidationSellForm,
    TStateSearchMembers,
    TStateKnowledgeBase,
    TStateFeature,
    TStateStateLicenses,
    TManagePastReferralRating,
    ReferralRaterType,
    ReferralRatingStatus,
    TBillingInfoData,
    TRequirementData,
    OrderByUserFilter,
} from '../types';



export const initialAuth: TStateAuth = {
    loginState: 'none',
    accountState: 'none',
    account: null,
    accountOriginal: null,
    accessToken: emptyString,
    expiresIn: 0,
    tokenType: emptyString,
};

export const initialApp: TStateApp = {
    theme: 'default',
    isRestored: false,
    isLogouting: false,
    isRenewSubscriptionVisible: false,
    isOnHoldStatusAlertVisible: false,
    isLoadingDashboard: false,
};


export const initialProfile: TStateProfile = {
    primary: {
        firstName: emptyString,
        lastName: emptyString,
        email: emptyString,
        cellPhoneNumber: emptyString,
        firstYearOfService: 2001,
        officePhoneNumber: emptyString,
        officePhoneExtension: null,
        officeAddress1: emptyString,
        officeAddress2: null,
        officeCity: emptyString,
        officeStateId: 0,
        officeZip: emptyString,
    },
    businessOverview: {
        brokerageName: emptyString,
        missionStatement: emptyString,
        professionalTitle: emptyString,
        slogan: emptyString,
        teamName: emptyString,
        teamMembers: emptyArray,
        expansionNetwork: false,
        expansionNetworkName: emptyString,
        expansionNetworkSubordinate: false,
    },
    background: {
        areasOfSpecializationCurrentlyTaught: null,
        communityEnvolvement: emptyString,
        educationalEventsAttendent: null,
        general: emptyString,
        industryInvolvement: null,
        industryInvolvements: emptyArray,
        communityInvolvements: emptyArray,
        educationalEvents: emptyArray,
        areasOfSpecialization: emptyArray,
    },
    logos: {
        primaryLogo: emptyString,
        brokerLogo: null,
        teamLogo: null,
    },
    licenses: {
        licenses: emptyArray,
    },
    websites: {
        blogSite: null,
        geographicFarmSite1: null,
        geographicFarmSite2: null,
        miscSite: null,
        primaryWebsite: emptyString,
        sellerSite: null,
        valuationSite: null,
    },
    socialMedia: {
        media: emptyArray,
    },
    specialties: {
        specialties: emptyArray,
    },
    userSpecialties: {
        specialties: emptyArray,
    },
    designations: {
        designations: emptyArray,
    },
    userDesignations: {
        designations: emptyArray,
    },
    languages: {
        languages: emptyArray,
    },
    userLanguages: {
        languageIds: emptyArray,
    },
    sourcesOfBusiness: {
        sources: emptyArray,
    },
    userSourcesOfBusiness: {
        sources: emptyArray,
    },
    states: {
        states: emptyArray,
    },
    locationSpecialties: {
        states: emptyArray,
        counties: emptyArray,
        cities: emptyArray,
        zipCodes: emptyArray,
    },
    inviteCode: emptyString,
    publicProfile: emptyString,
};

export const initialOperations: TStateOperations = emptyObject;

export const initialCounters: TStateCounters = {
    outboundUnmatched: 0,
    outboundInterviewing: 0,
    outboundMatched: 0,
    outboundPending: 0,
    outboundWorking: 0,
    outboundInEscrow: 0,
    outboundClosed: 0,
    outboundLongTerm: 0,
    outboundCanceled: 0,
    inboundCandidate: 0,
    inboundWorking: 0,
    inboundInEscrow: 0,
    inboundClosed: 0,
    inboundDeclined: 0,
    inboundProfileViewed: 0,
    inboundNotAccepted: 0,
    inboundCanceled: 0,
};


export const initialSpecialties: TStateSpecialties = {
    entities: emptyObject,
};

export const initialDesignations: TStateDesignations = {
    entities: emptyObject,
};

export const initialLanguages: TStateLanguages = {
    entities: emptyObject,
};

export const initialUsStates: TStateUsStates = {
    entities: emptyObject,
};

export const initialReferralsUserOutbound: TStateReferralsUserOutbound = {
    count: 0,
    referrals: emptyArray,
};

export const initialReferralsUserInbound: TStateReferralsUserInbound = {
    count: 0,
    referrals: emptyArray,
};

export const initialReferralsUserOutboundDict: TStateReferralsUserOutboundDict = {
    active: initialReferralsUserOutbound,
    closed: initialReferralsUserOutbound,
    invited: initialReferralsUserOutbound,
    'long-term': initialReferralsUserOutbound,
    pending: initialReferralsUserOutbound,
};

export const initialReferralsUserInboundDict: TStateReferralsUserInboundDict = {
    active: initialReferralsUserInbound,
    'long-term': initialReferralsUserInbound,
    closed: initialReferralsUserInbound,
    pending: initialReferralsUserInbound,
};

export const initialDashboardData: TStateDashboard = {
    profileCompleted: true,
    transactionsUploaded: true,
    referralsAdded: true,
    photoUploaded: true,
    hasVerifiedLisense: false,
    licensesVerified: DashboardVerifyStatus.none,
    designationsVerified: DashboardVerifyStatus.none,
    transactionsVerified: DashboardVerifyStatus.none,
    referralsVerified: DashboardVerifyStatus.none,
    inboundNotifications: emptyArray,
    inboundNotificationCount: 0,
    outboundNotifications: emptyArray,
    outboundNotificationCount: 0,
    referralCounters: initialCounters,
    pendingReferralInvitations: 0,
};

const initialViewReferralDataBase: TViewReferralModelBase = {
    id: 0,
    agentId: 0,
    agentFirstName: '',
    agentLastName: '',
    agentPrimaryImage: '',
    clientFirstName: null,
    clientLastName: null,
    clientEmail: null,
    clientPhone: null,
    clientPreapproved: false,
    source: null,
    type: null,
    fee: 0,
    signedAgreement: null,

    buyLocations: emptyArray,
    buyPropertyType: null,
    buyRangeMax: null,
    buyRangeMin: null,
    buyTimeFrame: null,

    sellLocation: null,
    sellPropertyType: null,
    sellTargetPrice: null,
    sellTimeFrame: null,

    actions: emptyArray,
    feeReceiveDate: null,
};

export const initialViewReferralData: TStateViewReferral = {
    inviteCode: null,
    
    referral: {
        ...initialViewReferralDataBase,
        agentBrokerageName: '',
        agentOfficePhone: null,
        agentCellPhone: null,
        agentEmail: null,
        agentPrimaryImage: null,
        status: null,
        owner: ReferralOwner.user,
        transactionClosedDate: null,
        buyPrice: null,
        sellPrice: null,
    },
};

export const initialViewReferralInboundData: TStateViewReferralInbound = {
    inviteCode: null,
    states: emptyArray,
    referral: {
        ...initialViewReferralDataBase,
        userId: 0,
        userFirstName: null,
        userLastName: null,
        userBrokerageName: null,
        userOfficePhone: null,
        userCellPhone: null,
        userEmail: null,
        userPrimaryImage: null,

        status: ReferralStatus.incomplete,
        agreement: null,
        useGeneratedAgreement: true,

        buyClosingDate: null,
        buyPrice: null,
        buyAddress1: null,
        buyAddress2: null,
        buyCity: null,
        buyStateId: null,
        buyZip: null,
        sellClosingDate: null,
        sellPrice: null,
        sellAddress1: null,
        sellAddress2: null,
        sellCity: null,
        sellStateId: null,
        sellZip: null,

        owner: ReferralOwner.agent,

        stepDueDate: null,
    },
};

export const initialViewReferralInboundAcceptData: TStateViewReferralInboundAccept = {
    ...initialViewReferralInboundData,
    status: null,
    referralId: null,
};

export const initialSourceOfBusiness: TStateSourceOfBusiness = {
    sources: emptyArray,
};

export const initialPaginationData: TPaginationData = {
    currentPage: 1,
    panelSize: 7, // recommended not less when 7
};

export const initialSelectCandidatesData: TSelectCandidatesData = {
    criteriaData: {
        primaryLocationData: {
            locationType: LocationType.City,
            selectedLocations: emptyArray,
        },
        agentQualityData: {
            agentQuality: 0,
            agentRating: 0,
            buyerRating: 0,
            sellerRating: 0,
            notRatedInclude: false,
        },
        yearsOfExperienceData: {
            years: null,
        },
        previousYearSalesData: {
            saleSum: null,
        },
        specialtiesData: {
            commertialOptions: emptyArray,
            residentialOptions: emptyArray,
        },
        designationsData: {
            designations: emptyArray,
        },
        brokerageData: {
            selectedBrokerages: emptyArray,
        },
        languageData: {
            languages: emptyArray,
        },
        nameData: {
            selectedMembers: emptyArray,
        },
    },
    searchCandidatesResultsData: {
        searchParameters: {
            sortField: OrderByUserFilter.name,
            pageSize: 20,
            viewType: 0,
        },
        paginationData: initialPaginationData,
    },
};


export const initialClientInformationValidation: TValidationClientInformation = {
    emailPassed: false,
    firstNamePassed: false,
    lastNamePassed: false,
    phonePassed: false,
};

export const initialClientInformationData: TClientInformationData = {
    clientFirstName: '',
    clientLastName: '',
    clientEmail: '',
    clientPhone: '',
};

export const initialReferralTypeData: TReferralTypeData = {
    buyFormData: {
        buyLocationType: LocationType.City,
        buyRangeMax: '0',
        buyRangeMin: '0',
        buyNote: null,
        buyLocations: emptyArray,
        buyPropertyTypes: emptyArray,
    },
    sellFormData: {
        sellAddress1: '',
        sellAddress2: '',
        sellNote: null,
        sellTargetPrice: 0,
        sellZip: '',
    },
};

export const initialAppliedCriteria: TAppliedCriteria = {
    agentQuality: false,
    brokerage: false,
    designations: false,
    language: false,
    name: false,
    previousYearSales: false,
    specialties: false,
    yearsOfExperience: false,
};

export const initialReferralAgreementData: TReferralAgreementData = {
    fee: 25,
    useGeneratedAgreement: true,
};

export const initialBuyFormValidation: TValidationBuyForm = {
    buyLocationsPassed: false,
    buyPropertyTypesPassed: false,
    buyTimeFramePassed: false,
};

export const initialSellFormValidation: TValidationSellForm = {
    sellAddressPassed: false,
    sellCityPassed: false,
    sellPropertyTypePassed: false,
    sellTimeFramePassed: false,
    sellZipPassed: false,
};

export const initialReferralTypeValidation: TValidationReferralType = {
    typePassed: false,
    buyFormValidation: initialBuyFormValidation,
    sellFormValidation: initialSellFormValidation,
};

export const initialSearchMembers: TStateSearchMembers = {
    favoritesDict: emptyObject,
};

export const initialKnowledgeBase: TStateKnowledgeBase = {
    categoryList: emptyArray,
};

export const initialFeature: TStateFeature = {
    viewFeatures: {
        count: 0,
        features: emptyArray,
        votes: emptyArray,
        activeTab: 'popular',
        isLoading: false,
    },
};

export const initialStateLicenses: TStateStateLicenses = {
    isLoading: false,
    licenses: {
        approvedLicenses: emptyArray,
        pendingLicenses: emptyArray,
        rejectedLicenses: emptyArray,
    },
};

export const initialRatingData: TManagePastReferralRating = {
    id: 0,
    rater: ReferralRaterType.client,
    status: ReferralRatingStatus.pending,
    rate1: 0,
    rate2: 0,
    rate3: 0,
    rate4: 0,
    rate5: 0,
    feedback: '',
};

export const initialBillingInfo: TBillingInfoData = {
    nameOnCard: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    cardNumberData: null,
    expiryData: null,
    cvcData: null,
};

export const initialRequirements: TRequirementData = {
    licenseConfirmed: false,
    agentConfirmed: false,
    salesConfirmed: false,
};
