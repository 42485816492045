import React, { FunctionComponent, useEffect, useState } from "react";
import Select from "react-select";
import ReferralActivityDataBuckets from "./ReferralActivityDataBuckets";
import ReferralActivityDataKanban from "./ReferralActivityDataKanban";
import ReferralActivityDataTable from "./ReferralActivityDataTable";
import {
    DIRECTION_INBOUND_STR,
    DIRECTION_OUTBOUND_STR,
    ReferralType,
    TUserReferralModelInbound,
} from "../../../types";
import { APPLICATION_FIRST_DATE_EPOCH_MS } from "../../constants";
import { getLocaleDateWithTzStrShort } from "../../../utils/get-locale-date-str";
import { useDeepCompareEffect } from "../../../utils/use-deep-compare-effect";
import { globalReactSelectTheme } from "../../../constants/react-select-theme";
import { getIsProduction } from "../../../utils/get-is-production";

export enum DataViewModeEnum {
    KANBAN = 1,
    TABLE = 2,
    TABS = 3,
}

type Props = {
    referrals: TUserReferralModelInbound[] | any;
};

enum SortByEnum {
    Client = "Client",
    Agent = "Agent",
    Date = "Date",
    Price = "Price",
}

const ReferralActivity: FunctionComponent<Props> = ({
    referrals
}) => {
    const [isInboundEnabled, setIsInboundEnabled] = useState<boolean>(true);
    const [isOutboundEnabled, setIsOutboundEnabled] = useState<boolean>(true);

    const [isBuyersEnabled, setIsBuyersEnabled] = useState<boolean>(true);
    const [isSellersEnabled, setIsSellersEnabled] = useState<boolean>(true);

    const [isSortingDesc, setIsSortingDesc] = useState<boolean>(true);
    const sortingOptions = [
        { label: SortByEnum.Date.valueOf(), value: SortByEnum.Date },
        { label: SortByEnum.Client.valueOf(), value: SortByEnum.Client },
        { label: SortByEnum.Agent.valueOf(), value: SortByEnum.Agent },
        { label: SortByEnum.Price.valueOf(), value: SortByEnum.Price },
    ];

    const [filteredReferrals, setFilteredReferrals] = useState<any[]>(
        [] as any[],
    );

    const applyFilters = (): void => {
        // each referral also have a direction property

        let filtered: any[] = [];

        if (isInboundEnabled) {
            filtered = [...referrals.filter((r) => r.direction === "inbound")];
        }

        if (isOutboundEnabled) {
            filtered = [...filtered, ...referrals.filter((r) => r.direction === "outbound")];
        }

        if (!isBuyersEnabled && !isSellersEnabled) {
            filtered = [];
        } else {
            if (isBuyersEnabled && !isSellersEnabled) {
                filtered = [...filtered.filter((r) => r.type === ReferralType.buy)];
            }

            if (isSellersEnabled && !isBuyersEnabled) {
                filtered = [...filtered.filter((r) => r.type === ReferralType.sell)];
            }
        }

        if (!getIsProduction()) {
            // only showing referrals after FIRST DATE EPOCH in authenticated -> constants.ts
            filtered = [...filtered
                            .filter((r) => APPLICATION_FIRST_DATE_EPOCH_MS < new Date(r.addedDate).getTime())
                            .filter((r) => r.type !== null)
                        ];
        }

        console.log("filtered: ", filtered);

        const searchTextLower = searchText.toLowerCase().trim();
        
        // apply text filter
        if (searchTextLower !== '') {
            filtered = [...filtered
                            .filter((r) => {
                                const searchTextIncludedInClientFirstName = r.clientFirstName.toLowerCase().includes(searchTextLower);
                                const searchTextIncludedInClientLastName = r.clientLastName.toLowerCase().includes(searchTextLower);
                                const searchTestIncludedInClientFullName = `${r.clientFirstName} ${r.clientLastName}`.toLowerCase().includes(searchTextLower);

                                const searchTextIncludedInReferredAgentFirstName = r.agentFirstName.toLowerCase().includes(searchTextLower);
                                const searchTextIncludedInReferredAgentLastName = r.agentLastName.toLowerCase().includes(searchTextLower);
                                const searchTextIncludedInReferredAgentFullName = `${r.agentFirstName} ${r.agentLastName}`.toLowerCase().includes(searchTextLower);

                                const searchTextIncludedInReferringAgentFirstName = r.userFirstName.toLowerCase().includes(searchTextLower);
                                const searchTextIncludedInReferringAgentLastName = r.userLastName.toLowerCase().includes(searchTextLower);
                                const searchTextIncludedInReferringAgentFullName = `${r.userFirstName} ${r.userLastName}`.toLowerCase().includes(searchTextLower);

                                let searchTextIncludedInCity = false;
                                if (r.sellCity) {
                                    searchTextIncludedInCity = r.sellCity.toLowerCase().includes(searchTextLower);
                                }
                                if (r.buyLocations && Array.isArray(r.buyLocations) && r.buyLocations.length > 0) {
                                    const buyCitiesIncluded = r.buyLocations.map((l) => l.city.toLowerCase().includes(searchTextLower));
                                    searchTextIncludedInCity = buyCitiesIncluded.every((v) => v);
                                }
                                return searchTextIncludedInClientFirstName || searchTextIncludedInClientLastName || searchTestIncludedInClientFullName
                                        || searchTextIncludedInReferredAgentFirstName || searchTextIncludedInReferredAgentLastName || searchTextIncludedInReferredAgentFullName
                                        // || searchTextIncludedInReferringAgentFirstName || searchTextIncludedInReferringAgentLastName || searchTextIncludedInReferringAgentFullName
                                        || searchTextIncludedInCity;
                            })
                        ];
        }

        filtered.sort((a, b) => new Date(b.addedDate).getTime() - new Date(a.addedDate).getTime());

        console.log("FILTERED: ", filtered.map((r) => getLocaleDateWithTzStrShort(r.addedDate)));

        setFilteredReferrals(filtered);
    };

    const [sortBy, setSortBy] = useState<SortByEnum>(SortByEnum.Date);
    const applySort = (): void => {
        const filtered = [...filteredReferrals];
        let sortedFiltered: any[] = [];
        switch (sortBy) {
            default:
                return;

            case SortByEnum.Date:
                sortedFiltered = filtered.sort((a, b) => {
                    const aTime = new Date(a.addedDate).getTime();
                    const bTime = new Date(b.addedDate).getTime();
                    return isSortingDesc ? bTime - aTime : aTime - bTime;
                });
                break;

            case SortByEnum.Client:
                sortedFiltered = filtered.sort((a, b) => {
                    const aName = `${a.clientFirstName} ${a.clientLastName}`;
                    const bName = `${b.clientFirstName} ${b.clientLastName}`;
                    return isSortingDesc
                        ? bName.localeCompare(aName)
                        : aName.localeCompare(bName);
                });
                break;

            case SortByEnum.Agent:
                sortedFiltered = filtered.sort((a, b) => {
                    const aName =
                        a.direction === DIRECTION_OUTBOUND_STR
                            ? `${a.agentFirstName} ${a.agentLastName}`
                            : `${a.userFirstName} ${a.userLastName}`;
                    const bName =
                        b.direction === DIRECTION_OUTBOUND_STR
                            ? `${b.agentFirstName} ${b.agentLastName}`
                            : `${b.userFirstName} ${b.userLastName}`;
                    return isSortingDesc
                        ? bName.localeCompare(aName)
                        : aName.localeCompare(bName);
                });
                break;

            case SortByEnum.Price:
                sortedFiltered = filtered.sort((a, b) => {
                    const aPrice = a.type === ReferralType.buy ? a.buyRangeMax : a.sellTargetPrice;
                    const bPrice = b.type === ReferralType.buy ? b.buyRangeMax : b.sellTargetPrice;
                    return isSortingDesc ? (bPrice - aPrice) : (aPrice - bPrice);
                });
                break;
        }

        setFilteredReferrals(sortedFiltered);
    };

    const [searchText, setSearchText] = useState<string>('');
    const filterSearchText = (e) => {
        const text = e.target.value;
        console.log("searchText: ", text);
        setSearchText(text);
    };

    const [dataViewMode, setDataViewMode] = useState<DataViewModeEnum>(DataViewModeEnum.TABS);

    // useEffect(() => {
    //     applyFilters();
    //     applySort();
    // }, []);

    useDeepCompareEffect(() => {
        if (!!referrals && Array.isArray(referrals) && referrals.length > 0) {
            applyFilters();
        }
    }, [referrals, isInboundEnabled, isOutboundEnabled, isBuyersEnabled, isSellersEnabled, searchText]);

    useDeepCompareEffect(() => {
        if (!!filteredReferrals && Array.isArray(filteredReferrals) && filteredReferrals.length > 0) {
            applySort();
        }
    }, [filteredReferrals, sortBy, isSortingDesc]);

    return (
        <div className="relative self-stretch w-full flex flex-col items-start justify-start gap-[24px] text-left text-21xl text-colors-gs-brand-primary font-large-med">
            {/* Referral Activity Title */}
            <div className="relative tracking-[-1.57px] leading-[46px]">
                Referral Activity
            </div>

            {/* YTD Boxes */}
            <div className="hidden self-stretch flex flex-row items-start justify-start gap-[16px] text-center text-5xl text-teal">
                {/* Opportunities */}
                <div className="rounded-md bg-colors-gs-white shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] box-border w-[279.75px] flex flex-row items-start justify-start p-4 border-[1px] border-solid border-colors-gs-border">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[14px]">
                        <div className="self-stretch rounded-md w-[66px] flex flex-col items-center justify-center">
                            <div className="self-stretch flex-1 rounded-md bg-darkseagreen flex flex-col items-center justify-center p-2">
                                <b className="self-stretch flex-1 relative tracking-[0.26px] leading-[30px] flex items-center justify-center">
                                    31
                                </b>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col items-start justify-start text-left text-base text-colors-gs-brand-primary">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5">
                                <div className="relative tracking-[0.1px] leading-[14px] font-semibold inline-block w-[149px] h-3.5 shrink-0">
                                    YTD Opportunities
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-center text-xs">
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-2">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Buyers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        17
                                    </div>
                                </div>
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-[9px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Sellers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        14
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Active */}
                <div className="flex-1 rounded-md bg-colors-gs-white shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-row items-start justify-between p-4 border-[1px] border-solid border-colors-gs-border">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[14px]">
                        <div className="self-stretch rounded-md w-[66px] flex flex-col items-center justify-center">
                            <div className="self-stretch flex-1 rounded-md bg-darkseagreen flex flex-col items-center justify-center p-2">
                                <b className="self-stretch flex-1 relative tracking-[0.26px] leading-[30px] flex items-center justify-center">
                                    19
                                </b>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col items-start justify-start text-left text-base text-colors-gs-brand-primary">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5">
                                <div className="flex-1 relative tracking-[0.1px] leading-[14px] font-semibold">
                                    YTD Active
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-center text-xs">
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-2">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Buyers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        12
                                    </div>
                                </div>
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-[9px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Sellers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        7
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Under Contract */}
                <div className="flex-1 rounded-md bg-colors-gs-white shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-row items-start justify-between p-4 border-[1px] border-solid border-colors-gs-border">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[14px]">
                        <div className="self-stretch rounded-md w-[66px] flex flex-col items-center justify-center">
                            <div className="self-stretch flex-1 rounded-md bg-darkseagreen flex flex-col items-center justify-center p-2">
                                <b className="self-stretch flex-1 relative tracking-[0.26px] leading-[30px] flex items-center justify-center">
                                    11
                                </b>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col items-start justify-start text-left text-base text-colors-gs-brand-primary">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5">
                                <div className="flex-1 relative tracking-[0.1px] leading-[14px] font-semibold">
                                    YTD Under Contract
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-center text-xs">
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-2">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Buyers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        5
                                    </div>
                                </div>
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-[9px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Sellers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        6
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Closed */}
                <div className="flex-1 rounded-md bg-colors-gs-white shadow-[0px_1px_2px_rgba(51,_65,_86,_0.08)] flex flex-row items-start justify-between p-4 border-[1px] border-solid border-colors-gs-border">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[14px]">
                        <div className="self-stretch rounded-md w-[66px] flex flex-col items-center justify-center">
                            <div className="self-stretch flex-1 rounded-md bg-darkseagreen flex flex-col items-center justify-center p-2">
                                <b className="self-stretch flex-1 relative tracking-[0.26px] leading-[30px] flex items-center justify-center">
                                    58
                                </b>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col items-start justify-start text-left text-base text-colors-gs-brand-primary">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5">
                                <div className="flex-1 relative tracking-[0.1px] leading-[14px] font-semibold">
                                    YTD Closed
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-center text-xs">
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-2">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Buyers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        28
                                    </div>
                                </div>
                                <div className="flex-1 rounded bg-colors-gs-surface-primary flex flex-col items-start justify-start py-1 px-[9px]">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Sellers
                                    </div>
                                    <div className="self-stretch relative text-sm leading-[21px] font-semibold">
                                        30
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Potential Numbers */}
            <div className="hidden self-stretch rounded-md bg-colors-gs-surface-secondary flex flex-row items-center justify-start py-4 px-3 text-center text-10xl">
                {/* Potential Income */}
                <div className="self-stretch flex-1 flex flex-row items-center justify-center">
                    <span className="tracking-[-0.2px] leading-[40px] font-light flex items-center justify-center">
                        Potential Income:
                    </span>
                    <span className="leading-[30px]  flex items-center justify-center">
                        <span className="text-5xl tracking-[-0.29px] font-large-med">
                            &nbsp;
                        </span>
                        <span className="text-10xl tracking-[0.26px] font-semibold font-large-med">
                            $258,990
                        </span>
                    </span>
                    <span className="text-xl text-tertiary flex items-center justify-center">
                        <span className="text-[17.73px] leading-[26.59px]">
                            ・
                        </span>
                    </span>
                    <span className="text-xl text-tertiary flex items-center justify-center">
                        <span className="tracking-[-0.24px] leading-[28px]">
                            27 Referrals
                        </span>
                    </span>
                </div>

                {/* Received Income */}
                <div className="self-stretch flex-1 flex flex-row items-center justify-center">
                    <span className="tracking-[-0.2px] leading-[40px] font-light flex items-center justify-center">
                        Received Income:
                    </span>
                    <span className="leading-[30px] flex items-center justify-center">
                        <span className="text-5xl tracking-[-0.29px] font-large-med">
                            &nbsp;
                        </span>
                        <span className="text-10xl tracking-[0.26px] font-semibold font-large-med">
                            $124,060
                        </span>
                    </span>
                    <span className="text-xl text-tertiary flex items-center justify-center">
                        <span className="text-[17.73px] leading-[26.59px]">
                            ・
                        </span>
                    </span>

                    <span className="text-xl text-tertiary flex items-center justify-center">
                        <span className="tracking-[-0.24px] leading-[28px]">
                            32 Referrals
                        </span>
                    </span>
                </div>
            </div>

            {/* Filters & DataView Picker */}
            <div className="self-stretch flex flex-row items-center justify-between text-sm text-gray-600">
                {/* Row 3A - Filter Boolean Buttons - Inbound/Outbound - Buyers/Sellers */}
                <div className="self-stretch flex flex-row items-center justify-start">
                    
                    {/* Inbound/Outbound Buttons */}
                    <div className="flex flex-row items-center justify-start gap-0">
                        
                        {/* INBOUND BUTTON FILTER */}
                        <button
                            className={`
                                ${isInboundEnabled
                                    ? "bg-honeydew shadow-[inset_0px_0px_5px_2px_rgba(0,0,0,0.1)]"
                                    : "bg-transparent"
                                } border-[#ccddd3] border-solid border-t-[1.4px] border-b-[1.4px] border-l-[1.4px] border-r-[0.7px] cursor-pointer py-0 px-[18px] rounded-tl-[8.33px] rounded-tr-none rounded-br-none rounded-bl-[8.33px] box-border h-[50px] flex flex-row items-center justify-center gap-[10px]
                            `}
                            autoFocus={false}
                            onClick={() => {
                                setIsInboundEnabled(!isInboundEnabled);
                            }}
                        >
                            <img
                                className="relative w-4 h-4"
                                alt=""
                                src="/img/mra-dashboard-activity/iconarrowdownleft1.svg"
                            />
                            <div className="relative text-lg leading-[140%] font-medium font-large-med text-teal text-center">
                                Inbound
                            </div>
                        </button>

                        {/* OUTBOUND BUTTON FILTER */}
                        <button
                            className={`
                                ${isOutboundEnabled
                                    ? "bg-honeydew shadow-[inset_0px_0px_5px_2px_rgba(0,0,0,0.1)]"
                                    : "bg-transparent"
                                } border-[#ccddd3] border-solid border-t-[1.4px] border-b-[1.4px] border-l-[0.7px] border-r-[0.7px] cursor-pointer py-0 px-[18px] box-border h-[50px] flex flex-row items-center justify-center gap-[10px]
                            `}
                            autoFocus={false}
                            onClick={() => {
                                setIsOutboundEnabled(!isOutboundEnabled);
                            }}
                        >
                            <img
                                className="relative w-4 h-4"
                                alt=""
                                src="/img/mra-dashboard-activity/iconarrowupright1.svg"
                            />
                            <div className="relative text-lg leading-[140%] font-medium font-large-med text-tertiary text-center">
                                Outbound
                            </div>
                        </button>
                    </div>
                    
                    {/* Buyer/Seller Buttons */}
                    <div className="flex flex-row items-center justify-start gap-0">
                        {/* BUYER BUTTON FILTER */}
                        <button
                            className={`
                                ${isBuyersEnabled
                                    ? "bg-honeydew shadow-[inset_0px_0px_5px_2px_rgba(0,0,0,0.1)]"
                                    : "bg-transparent"
                                } border-[#ccddd3] border-solid border-t-[1.4px] border-b-[1.4px] border-l-[0.7px] border-r-[0.7px] cursor-pointer py-0 px-[18px] box-border w-[133px] h-[50px] flex flex-row items-center justify-center gap-[10px]
                            `}
                            autoFocus={false}
                            onClick={() => {
                                setIsBuyersEnabled(!isBuyersEnabled);
                            }}
                        >
                            <img
                                className="relative w-4 h-4 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconbuy.svg"
                            />
                            <div className="relative text-lg leading-[140%] font-medium font-large-med text-teal text-center">
                                Buyers
                            </div>
                        </button>

                        {/* SELLER BUTTON FILTER */}
                        <button
                            className={`
                                ${isSellersEnabled
                                    ? "bg-honeydew shadow-[inset_0px_0px_5px_2px_rgba(0,0,0,0.1)]"
                                    : "bg-transparent"
                                } border-[#ccddd3] border-solid border-t-[1.4px] border-b-[1.4px] border-l-[0.7px] border-r-[1.4px] cursor-pointer py-0 px-[18px] rounded-tl-none rounded-tr-[8.33px] rounded-br-[8.33px] rounded-bl-none box-border w-[133px] h-[50px] flex flex-row items-center justify-center gap-[10px]
                            `}
                            autoFocus={false}
                            onClick={() => {
                                setIsSellersEnabled(!isSellersEnabled);
                            }}
                        >
                            <img
                                className="relative w-4 h-4 hidden"
                                alt=""
                                src="/img/mra-dashboard-activity/iconmoney.svg"
                            />
                            <div className="relative text-lg leading-[140%] font-medium font-large-med text-teal text-center">
                                Sellers
                            </div>
                        </button>
                    </div>
                </div>

                {/* Row 3B - Data View / Sort / Search */}
                <div className="self-stretch flex flex-row items-center justify-end gap-[24px]">
                    {/* Data View Picker */}
                    {/* Hidden for now - Only Tabs View */}
                    <div className="hidden flex flex-row items-center justify-end">
                        {/* Data View Picker - Kanban */}
                        <div className="rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md bg-colors-gs-white box-border h-10 flex flex-row items-center justify-center py-0 px-4 border-[1px] border-solid border-colors-gray-400">
                            <img
                                className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                                alt=""
                                src="/img/mra-dashboard-activity/iconchart2.svg"
                            />
                        </div>

                        {/* Data View Picker - Table */}
                        <div className="bg-colors-gs-white box-border h-10 flex flex-row items-center justify-center py-0 px-4 border-t-[1px] border-solid border-colors-gray-400 border-b-[1px]">
                            <img
                                className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                                alt=""
                                src="/img/mra-dashboard-activity/iconlist2.svg"
                            />
                        </div>

                        {/* Data View Picker - Tabs/Buckets */}
                        <div className="rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-honeydew box-border h-10 flex flex-row items-center justify-center py-0 px-4 border-[1px] border-solid border-powderblue-100">
                            <img
                                className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                                alt=""
                                src="/img/mra-dashboard-activity/icontabs2.svg"
                            />
                        </div>
                    </div>

                    {/* Sorting */}
                    <div className="flex flex-row items-center gap-[6px]">
                        
                        <span>Sort By</span>
                        <Select
                            className="w-[100px]"
                            options={sortingOptions}
                            theme={globalReactSelectTheme}
                            defaultValue={sortingOptions[0]}
                            onChange={(selected) =>
                                setSortBy(selected?.value as SortByEnum)
                            }
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    borderRadius: "5px",
                                }),
                                option: (base) => ({
                                    ...base,
                                }),
                            }}
                        />
                        
                        <div
                            className="cursor-pointer flex flex-col items-center"
                            onClick={() => setIsSortingDesc(!isSortingDesc)}
                        >
                            {isSortingDesc ? (
                                <img
                                    className="relative overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/mra-dashboard-activity/icon-descending-arrow.svg"
                                />
                            ) : (
                                <img
                                    className="relative overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/mra-dashboard-activity/icon-ascending-arrow.svg"
                                />
                            )}
                        </div>
                    </div>

                    {/* Search */}
                    <div className="rounded-md bg-colors-gs-white box-border w-[240px] flex flex-row items-center justify-start border-[1px] border-solid border-gray-400">
                        <div className="rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md bg-gray-50 box-border w-10 h-10 flex flex-row items-center justify-center p-2  border-r-[1px] border-solid border-gray-400">
                            <img
                                className="relative w-4 h-4 overflow-hidden shrink-0"
                                alt=""
                                src="/img/mra-dashboard-activity/iconsearch.svg"
                            />
                        </div>
                        <input
                            className="font-h2 text-sm bg-[transparent] self-stretch w-full flex flex-row items-center justify-start p-2 placeholder-[#bbb]"
                            placeholder="Search"
                            type="text"
                            onKeyUp={filterSearchText}
                            onKeyDown={filterSearchText}
                        />
                    </div>
                </div>
            </div>

            {/* Data Views: Kanban/Table/Buckets */}
            <div className="self-stretch flex flex-col max-h-[600px] overflow-auto rounded-md">
                {dataViewMode === DataViewModeEnum.KANBAN && (
                    <ReferralActivityDataKanban />
                )}

                {dataViewMode === DataViewModeEnum.TABLE && (
                    <ReferralActivityDataTable />
                )}

                {dataViewMode === DataViewModeEnum.TABS && (
                    <ReferralActivityDataBuckets
                        referrals={filteredReferrals}
                    />
                )}
            </div>
        </div>
    );
};

export default ReferralActivity;
