
export const getLocaleDateWithTzStrFullDay = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' });
    // const dtTime = dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

    return `${dtStr}`;
}

export const getLocaleDateWithTzStrShort = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    // const dtTime = dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

    return `${dtStr}`;
}


export const getLocaleDateWithTzStrShortForTimeline = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    // const dtTime = dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

    return `${dtStr}`;
}

export const getLocaleDateTimeWithTzStrFullDay = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
    const dtTime = dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

    return `${dtStr}, ${dtTime}`;
}

export const getLocaleDateTimeWithTzStrShort = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    const dtTime = dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

    return `${dtStr}, ${dtTime}`;
}

export const getLocaleDateTimeWithTzStrShortForTimeline = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    const dtTime = dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

    return `${dtStr}\n${dtTime}`;
}

export const getLocaleDateTimeWithTzStr = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
    const dtTime = dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' });

    return `${dtStr}, ${dtTime}`;
}

export const getLocaleDateTime = (date?: string): string => {
    const dt = new Date(date ?? "");

    const dtStr = dt.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });

    return `${dtStr}`;
}

