import _ from "lodash";
import { useEffect, useRef } from "react";

const useDeepCompareMemoize = (newValue) => {
    const ref = useRef();
    const prev = ref.current;
    
    if (!_.isEqual(prev, newValue)) {
        ref.current = newValue;
    }
    
    return ref.current;
}

export const useDeepCompareEffect = (callback, dependencies): void => {
    useEffect(
        callback,
        dependencies.map(useDeepCompareMemoize)
    )
}
