type TLoggerMethod = (...args: ReadonlyArray<unknown>) => void;

type TLogger = Readonly<{
    debug: TLoggerMethod;
    info: TLoggerMethod;
    warn: TLoggerMethod;
    error: TLoggerMethod;
}>;

const isTest = false;


const logger: TLogger = {
    debug: (...args) => {
        if (isTest) {
            return;
        }
        // eslint-disable-next-line no-console
        console.debug(...args);
    },
    info: (...args) => {
        if (isTest) {
            return;
        }
        // eslint-disable-next-line no-console
        console.info(...args);
    },
    warn: (...args) => {
        if (isTest) {
            return;
        }
        // eslint-disable-next-line no-console
        console.warn(...args);
    },
    error: (...args) => {
        if (isTest) {
            return;
        }
        
        const [where, message, ...data] = args;
        const whereStr = typeof where === 'string' ? where : 'UNKNOWN_LOCATION';
        
        // eslint-disable-next-line no-console
        console.error(whereStr, message, ...data);
    },
};

export default logger;
