import React, { FunctionComponent } from 'react';
const AnonymousNavbar: FunctionComponent = () => {

    const onLogoClick = (): void => {
        window.open('https://www.myreferralagent.com/', '_blank')?.focus();
    };

    return (
        <div className="relative z-20 w-full flex flex-row box-border items-center justify-center bg-primary text-light-background-background-1 font-h5-semibold">

            <div className="z-10 w-[1280px] h-fit flex box-border items-center xs:flex-col lg:flex-row xs:justify-center lg:justify-between">

                {/* Name and Logo */}
                <div className="flex flex-row items-center justify-start gap-[8px] p-2 cursor-pointer" onClick={onLogoClick}>
                    <img
                        className="relative w-[49px] h-[31px] flex items-center justify-center"
                        alt=""
                        src="/img/mra-dashboard/vector3.svg"
                    />
                    <div className="relative text-white font-semibold">
                        MY REFERRAL AGENT
                    </div>
                </div>

                {/* Links */}
                <div className="flex flex-row items-center justify-end gap-[24px] text-sm p-2 ">
                    <a className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer text-white no-underline hover:underline" target='_blank' href="https://www.myreferralagent.com/about-us">
                        About Us
                    </a>
                    <a className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer text-white no-underline hover:underline" target='_blank' href="https://www.myreferralagent.com/pricing">
                        Pricing
                    </a>
                    {/* <div className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer">
                        Communities
                    </div>
                    <div className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer">
                        Blog
                    </div>
                    <div className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer">
                        Learn
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default AnonymousNavbar;
