import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import useZustandAuthStore from "../../_Zustand/auth-store";
import { ReactSession } from 'react-client-session';

const RegistrationEmailVerify: React.FunctionComponent = () => {

    const { user } = useAuth0();
    const { setReferralInvitation, resetZustandState } = useZustandAuthStore();

    
    useEffect(() => {
        setReferralInvitation(ReactSession.get("referralInvitation"));
        resetZustandState();
        document.cookie = '';
        localStorage.clear();
        sessionStorage.clear();
    }, []);

    return (
        <div className="flex flex-grow items-top justify-center ">
            <div className="w-full flex flex-row xs:flex-col lg:flex-row justify-center">

                {/* left pane */}
                <div className="flex flex-1 flex-col justify-top pr-[30px] py-[10px] bg-brown">
                    <div className="ml-[30px] flex flex-col items-center justify-between text-white">
                        <div className="flex flex-1 flex-col xs:px-[16px] xs:py-[50px] lg:pt-[113px] items-center justify-between text-white bg-brown">
                            <div className="xs:w-fit lg:w-[450px] flex flex-col justify-center items-center gap-[50px]">

                                <div className="relative xs:text-21xl lg:text-37xl xs:leading-[40px] lg:leading-[62px] inline-block">
                                    Send, receive and track referrals with some of the best real estate
                                    agents on earth
                                </div>

                                <div className="self-stretch relative text-lg leading-[25px] font-dm-sans justify-start">
                                    We&apos;d love for you to join us!
                                </div>

                                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] font-dm-sans">
                                    <div className="flex flex-row items-center justify-start">
                                        <div className="relative rounded-21xl w-9 h-9">
                                            {/* <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] bg-colors-gs-white" /> */}
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image1@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image2@2x.png"
                                            />
                                        </div>
                                        <div className="relative rounded-21xl w-9 h-9 ml-[-11px]">
                                            <img
                                                className="border-solid border-[2px] border-white absolute h-[88.89%] w-[88.89%] rounded-81xl max-w-full overflow-hidden max-h-full object-cover"
                                                alt=""
                                                src="/img/mra-reg-1/image3@2x.png"
                                            />
                                        </div>
                                    </div>
                                    <div className="relative leading-[150%] font-sm">
                                        {/* Join 2.5M+ users */}
                                        Be part of an elite group of referrers!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* right pane */}
                <div className="flex flex-1 flex-col justify-top pr-[30px] py-[10px] bg-surface-secondary">
                    <div className="flex flex-col pt-[20px] items-center justify-between ml-[10px]">
                        <div className="flex flex-1 flex-col xs:px-[16px] xs:py-[50px] lg:pt-[113px] items-center justify-between text-white">
                            <div className="xs:w-fit lg:w-[450px] flex flex-col justify-center items-center gap-[12px] ">
                                <div className="flex flex-col items-end justify-start">
                                    <div className="flex flex-col max-w-full items-center justify-start py-0 px-5 box-border gap-[20px] text-center text-base text-colors-gs-brand-secondary font-medium-semibold">
                                        <div className="m-0 self-stretch relative text-21xl leading-[46px] font-bold font-inherit mq950:text-11xl mq950:leading-[37px] mq450:text-4xl mq450:leading-[28px]">
                                            Verify Your Email Address
                                        </div>
                                        <div className="self-stretch flex flex-col items-start justify-start gap-[22px] text-left">
                                            <div className="self-stretch flex flex-col relative leading-[24px] py-[20px] gap-[16px]">
                                                <div className="flex xs:flex-col lg:flex-row items-center gap-0 flex-wrap">
                                                    <span>We have sent a verification email to</span>
                                                    <div className="ml-[-8px] flex w-fit rounded-md px-2 py-1 bg-surface-primary font-semibold">{user?.email}</div>
                                                </div>
                                                <span>Click on the <b>VERIFY EMAIL</b> button in that email to continue creating your account with us. If you do not find this email, check your 'Spam' or 'Junk' folders.
                                                </span>
                                                <span>
                                                    If you have not received the verification email or have any questions, please reach out to us at &nbsp;
                                                    <a href='mailto:support@myreferralagent.com' target="_blank" className="text-link-blue">support@myreferralagent.com</a>.
                                                </span>
                                                <span>-The MRA Team</span>
                                            </div>
                                        </div>
                                        <div className="self-stretch flex flex-col items-start justify-start pt-[18px] px-0 pb-0">
                                            <div className="self-stretch relative tracking-[0.1px] leading-[24px] font-semibold">
                                                Please close this page.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export { RegistrationEmailVerify };
