import React, { FunctionComponent, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getAvatar } from '../utils/get-avatar';
import { Avatar, AvatarTypeEnum } from './Avatar';
import { RoleEnum } from './constants';
import { useNavigate } from 'react-router-dom';
import useZustandAuthStore from '../_Zustand/auth-store';
import { useDeepCompareEffect } from '../utils/use-deep-compare-effect';
import { postSearchMembers } from '../utils/api';
import { OrderByUserFilter, TUsState, UserFilterSearchMemberRelation } from '../types';
import { onlyNumbersAndSpace, validateZipcode } from '../utils/validate-zipcode';
import { WaitSpinner } from '../components/WaitSpinner';
import { debounce } from 'lodash';

export const DEFAULT_AVATAR_PATH = '/img/default-avatar.png';

type Props = {
    mainLogout: () => void;
}
const AuthenticatedNavBar: FunctionComponent<Props> = ({mainLogout}) => {

    const navigate = useNavigate();

    // const { logout: logoutAuth0 } = useAuth0();
    const { authState, privateProfileData } = useZustandAuthStore();
    // console.log(authState);
    const { usStates } = useZustandAuthStore();

    const primaryImage = privateProfileData?.primaryImage;
    const avatarUrl: string = primaryImage
        ? getAvatar(primaryImage, 200)
        : (authState.user?.picture ?? DEFAULT_AVATAR_PATH);

    const isSocialGoogle = authState.user?.sub?.includes("google-oauth2");

    const role = authState.profileUserData?.role;
    const isAdmin: boolean = role === RoleEnum.ADMINISTRATOR;
    const isAgent: boolean = role === RoleEnum.AGENT;

    const logout = (): void => {
        mainLogout();
    }

    const navigatetoDashboard = (): void => {
        // navigate("/dashboard");
        location.href = "/dashboard";
    };

    const impersonate = (): void => {
        toast.warning("Impersonation is NOT yet implemented since Auth0 changes! Sorry!");
    }

    const openProfile = (): void => {
        navigate("/profile");
    }

    const searchInputRef = useRef<any>(null);
    const [searchString, setSearchString] = useState<string>('');
    const searchTimeout = useRef<any>(null);    
    const [runningNameSearch, setRunningNameSearch] = useState<boolean>(false);
    const [runningLocationSearch, setRunningLocationSearch] = useState<boolean>(false);
    const [globalSearchResult, setGlobalSearchResult] = useState<any>(null);
    // const globalSearch = (e): void => {
    //     const str = e.target.value;
    //     setSearchString(str);
        
    //     if (searchTimeout.current) {
    //         console.log("Clearing Timeout")
    //         clearTimeout(searchTimeout.current);
    //     }

    //     searchTimeout.current = setTimeout(() => {
    //         console.log("Sending Search For: ", str);
    //         makeGlobalSearchCall(str);
    //     }, 500);
    // };
    
    const googlePlacesAutocompleteService = new google.maps.places.AutocompleteService();
    const makeGlobalSearchCall = (str): void => {
        // setGlobalSearchResult(null);
        
        if (str === '') {
            setGlobalSearchResult(null);
            return;
        }
        
        const startTimestamp = Date.now();
        
        // zip/city search
        if (onlyNumbersAndSpace(str)) { // potential zipcode search
            if(validateZipcode(str)) { // zipcode search
                setRunningLocationSearch(true);
                console.log("Fetching zipcode data");
                googlePlacesAutocompleteService.getPlacePredictions({
                        input: str,
                        componentRestrictions: {country: 'us'}
                    }, (
                        predictions: google.maps.places.AutocompletePrediction[] | null,
                        status: google.maps.places.PlacesServiceStatus
                    ): void => {
                        console.log(status);
                        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
                            return;
                        }
                        
                        const googleLocationsFound = predictions
                            .filter((p) => p.types.includes("postal_code"))
                            .map((p) => {
                                const stateAbbreviation = p.terms[1].value;
                                const stateId = usStates?.find((s) => s.abbreviation === stateAbbreviation)?.id;
                                const zipcode = p.terms[2].value;
                                
                                return {
                                    type: 3,
                                    zip: zipcode,
                                    stateId,
                                }
                            });
                        
                        makeLocationSearchCallInMRA(googleLocationsFound, (resultAgents) => {
                            console.log("NAME RESULTS:", resultAgents.map((a) => `${a.firstName} ${a.lastName}`));
                
                            setGlobalSearchResult({
                                startTimestamp,
                                query: str,
                                nameAgents: [],
                                locationAgents: resultAgents,
                            });

                            setRunningLocationSearch(false);
                        });
                    },
                );
            }
        }
        else { // agent-name or city search
            
            // agent-name search
            setRunningNameSearch(true);
            setRunningLocationSearch(true);
            
            // searching google for valid locations to eventually search in MRA DB
            googlePlacesAutocompleteService.getPlacePredictions({
                input: str,
                types: ['(cities)'],
                componentRestrictions: {country: 'us'}
                },
                (
                    predictions: google.maps.places.QueryAutocompletePrediction[] | null,
                    status: google.maps.places.PlacesServiceStatus
                ): void => {

                    // no valid locations found in google with $str
                    // -> make only name search in mra db
                    if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
                        makeNameSearchCallInMRA(startTimestamp, str, []);
                    }
                    // some locations found in google with $str
                    // -> make location and then name search in mra db and merge both results
                    else {
                        const locations = predictions
                        .filter((p) => {
                            const st = p.terms[1].value;
                            return (st.length === 2 && usStates?.filter((s: TUsState) => s.abbreviation === st).length === 1)
                        })
                        .map((p) => {
                            const city = p.terms[0].value;
                            const stateAbbreviation = p.terms[1].value;
                            const stateId = usStates?.find((s) => s.abbreviation === stateAbbreviation)?.id;
                            return {
                                type: 2,
                                city: city,
                                stateId,
                            }
                        });
                    
                        makeLocationSearchCallInMRA(locations, (locationResults) => {
                            makeNameSearchCallInMRA(startTimestamp, str, locationResults);
                        });
                    }
            });
        }
    };
    const debouncedGlobalSearch = debounce(makeGlobalSearchCall, 500);
    const globalSearch = (e): void => {
        const str = e.target.value.trim();

        setSearchString(str);
        if (!str || str === '') {
            setGlobalSearchResult(null);
            return;
        }

        console.log("Searching ... ", str);

        debouncedGlobalSearch(str);
    };
    const makeLocationSearchCallInMRA = (serviceAreas, callback?): void => {
        const locSearchData = {
            serviceAreas,
            page: 1,
            pageSize: 10,
            sortField: OrderByUserFilter.name,
            sendEmailOnMemberAbsence: false,
            relation: UserFilterSearchMemberRelation.none,
        };
        postSearchMembers(locSearchData)
            .then((result) => {
                console.log("LOCATION RESULTS:", result);
                console.log("LOCATION RESULTS MAPPED:", result.agents.map((a) => `${a.firstName} ${a.lastName}`));
                
                if (callback) {
                    callback(result.agents);
                }
            })
            .catch((error) => {
                console.error(error);
                setRunningLocationSearch(false);
            });
    };
    const makeNameSearchCallInMRA = (startTimestamp, str, locationResults): void => {
        const nameSearchData = {
            names: [
                {  
                    val: str,
                    type: 1,
                }
            ],
            page: 1,
            pageSize: 10,
            sortField: OrderByUserFilter.name,
            sendEmailOnMemberAbsence: false,
            relation: UserFilterSearchMemberRelation.none,
        };

        postSearchMembers(nameSearchData)
            .then((result) => {
                console.log("NAME RESULTS:", result.agents.map((a) => `${a.firstName} ${a.lastName}`));
                
                setGlobalSearchResult({
                    startTimestamp,
                    query: str,
                    nameAgents: result.agents,
                    locationAgents: locationResults,
                });

                setRunningLocationSearch(false);
                setRunningNameSearch(false);
                
            })
            .catch((error) => {
                console.error(error);
                setRunningNameSearch(false);
            });
    };


    const goToProfile = (publicProfile): void => {
        window.open(`/members/${publicProfile}`, "_blank")?.focus();
    }

    const [avatarType, setAvatarType] = useState<AvatarTypeEnum>(AvatarTypeEnum.freePill);
    useDeepCompareEffect(() => {
        if (privateProfileData?.product === "FREE") {
            setAvatarType(AvatarTypeEnum.round);
        }
        else if (privateProfileData?.product === "PROFESSIONAL") {
            setAvatarType(AvatarTypeEnum.proPill);
        }
        else if (privateProfileData?.product === "TEAMS") {
            setAvatarType(AvatarTypeEnum.teamsPill);
        }
        else {
            setAvatarType(AvatarTypeEnum.round);
        }
    }, [privateProfileData?.product]);

    return (
        <>  
            {/* Search Results Popup */}
            {(searchString !== '') && (globalSearchResult || runningLocationSearch || runningNameSearch)
                && <div className='absolute z-50 w-[1000px] max-h-[700px] overflow-y-auto top-[58px] left-0 right-0 m-auto border border-solid border-gray-300 rounded-md shadow-2xl flex flex-row gap-[30px] bg-white text-black p-[20px]'>
                    <div className='flex w-1/2 flex-1 flex-col gap-[10px]'>
                        <div className='p-[10px] text-3xl-1 border-b-4 border-solid border-blue-400'>
                            Members
                        </div>
                        <>
                            {runningNameSearch
                                ? <div className='flex self-stretch w-full items-center justify-center'><WaitSpinner sizePx={30} /></div>
                                : <div className='flex flex-col gap-[0px]'>
                                    {(globalSearchResult && globalSearchResult.nameAgents && Array.isArray(globalSearchResult.nameAgents) && (globalSearchResult.nameAgents.length > 0))
                                        ? globalSearchResult.nameAgents.map((a) => 
                                            <div
                                                key={a.id}
                                                className='hover:bg-gray-100 cursor-pointer flex flex-row items-start gap-[10px] p-[10px] border-b border-solid border-gray-300 rounded-md'
                                                onClick={() => goToProfile(a.publicProfile)}
                                            >
                                                <img className='rounded-lg w-[75px] h-[75px] border border-solid border-gray-200' src={getAvatar(a.primaryImage, 75)} />
                                                <div className='flex flex-col items-start gap-[10px]'>
                                                    <div className='text-3xl-1'>{a.firstName} {a.lastName}</div>
                                                    <div>{a.brokerageName}</div>
                                                    <div className='capitalize'>
                                                        {
                                                            a.servedCities
                                                                .map((c) => `${c.city}, ${usStates?.find((s) => s.id === c.stateId)?.abbreviation}`)
                                                                .join(' - ')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        : <i>No Matching Members ... </i>
                                    }
                                </div>
                            }
                        </>
                    </div>
                    <div className='flex w-1/2 flex-1 flex-col gap-[10px]'>
                        <div className='p-[10px] text-3xl-1 border-b-4 border-solid border-blue-400'>
                            Locations
                        </div>
                        <>
                            {runningLocationSearch
                                ? <div className='flex self-stretch w-full items-center justify-center'><WaitSpinner sizePx={30} /></div>
                                : <div className='flex flex-col gap-[0px]'>
                                    {(Array.isArray(globalSearchResult.locationAgents) && (globalSearchResult.locationAgents.length > 0))
                                        ? globalSearchResult.locationAgents.map((a) => 
                                            <div
                                                key={a.id}
                                                className='hover:bg-gray-100 cursor-pointer flex flex-row items-start gap-[10px] p-[10px] border-b border-solid border-gray-300 rounded-md'
                                                onClick={() => goToProfile(a.publicProfile)}
                                            >
                                                <img className='rounded-lg w-[75px] h-[75px] border border-solid border-gray-200' src={getAvatar(a.primaryImage, 75)} />
                                                <div className='flex flex-col items-start gap-[10px]'>
                                                    <div className='text-3xl-1'>{a.firstName} {a.lastName}</div>
                                                    <div>{a.brokerageName}</div>
                                                    <div className='capitalize'>
                                                        {(onlyNumbersAndSpace(globalSearchResult.query) && validateZipcode(globalSearchResult.query))
                                                            ? `Zip: ${a.servedZipCodes
                                                                .find((z) => z.zip.toLowerCase().includes(globalSearchResult.query.toLowerCase()))
                                                                .zip}`
                                                            : a.servedCities
                                                                .map((c, index) => {
                                                                    return ((c.city.toLowerCase().includes(globalSearchResult.query.toLowerCase()))
                                                                        ? <span key={index}><b>{c.city}, {usStates?.find((s) => s.id === c.stateId)?.abbreviation}</b></span>
                                                                        : <span key={index}>{c.city}, {usStates?.find((s) => s.id === c.stateId)?.abbreviation}</span>);
                                                                })
                                                                .reduce((prev, curr) => [prev, ' - ', curr])
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        : <i>No Matching Locations ...</i>
                                    }
                                </div>
                            }
                        </>
                    </div>
                </div>
            }

            <div className="absolute top-0 left-0 z-20 w-full flex flex-row box-border items-center lg:justify-center xs:justify-start text-left bg-primary text-light-background-background-1 font-h5-semibold">

                {/* <div className="z-10 w-[1280px] h-fit flex box-border items-center xs:flex-col lg:flex-row xs:justify-center lg:justify-between"> */}
                <div className="z-10 ml-[40px] w-[1320px] h-fit flex box-border items-center flex-row justify-between">

                    {/* Name and Logo */}
                    <div className="flex flex-row items-center justify-start w-[240px] gap-[8px] p-2 cursor-pointer" onClick={navigatetoDashboard}>
                        <img
                            className="relative w-[49px] h-[31px] flex items-center justify-center"
                            alt=""
                            src="/img/mra-dashboard/vector3.svg"
                        />
                        <div className="relative text-white font-semibold">
                            MY REFERRAL AGENT
                        </div>
                    </div>

                    {/* Search */}
                    <div className="flex flex-row items-center justify-end text-base text-gray-400 p-2 ">
                        <div className="rounded-md bg-dark box-border w-full h-full flex flex-row items-center justify-start border-[1px] border-solid border-gray-900">
                            
                            <div className="rounded-t-lg rounded-b-none w-10 h-10 flex flex-row p-2 box-border items-center justify-center">
                                <img
                                    className="relative w-4 h-4"
                                    alt=""
                                    src="/img/mra-dashboard/iconsearch3.svg"
                                />
                            </div>

                            {/* xs:invisible lg:visible xs:w-0 xs:h-0 xs:p-0 lg:p-2 lg:h-full lg: */}
                            <div className="self-stretch rounded-t-none rounded-br-lg rounded-bl-none w-[355px] overflow-hidden shrink flex flex-row py-2 pr-4 pl-3 box-border items-center justify-start">
                                <input
                                    type="text"
                                    className="flex-1 relative bg-transparent text-white placeholder-gray-500 text-md"
                                    placeholder='Search People and Locations'
                                    onKeyUp={globalSearch}
                                    ref={searchInputRef}
                                />

                                {/* Close Icon Button */}
                                {(searchString != '')
                                    && <div
                                            className='cursor-pointer flex justify-center items-center p-[2px]'
                                            onClick={() => {
                                                searchInputRef.current.value = '';
                                                setSearchString('');
                                                setGlobalSearchResult(null);
                                            }}
                                        >
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard/close-search-button.svg"
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* Links & Avatar */}
                    <div className="flex flex-row items-center justify-end gap-[24px] text-sm p-2 ">

                        {/* <div className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer" onClick={navigatetoDashboard}>
                            Dashboard
                        </div> */}
                        <a 
                            className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer no-underline text-white"
                            target="_self"
                            href="/dashboard"
                        >
                            Dashboard
                        </a>

                        {/* <div className="rounded flex py-[11px] px-1 items-center justify-start cursor-default">
                            Members
                        </div> */}

                        {/* Impersonation */}
                        {/*
                        {isAdmin && (
                            <div className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer" onClick={impersonate}>
                                Impersonate
                            </div>
                        )}
                        */}

                        {/* <div
                            className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer"
                            onClick={openProfile}
                        >
                            Profile
                        </div> */}
                        <a
                            className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer text-white no-underline"
                            href="/profile"
                        >
                            Profile
                        </a>


                        <div
                            className="rounded flex py-[11px] px-1 items-center justify-start cursor-pointer"
                            onClick={logout}
                        >
                            Logout
                        </div>

                        <img
                            className="hidden relative w-4 h-[11.73px] overflow-hidden shrink-0 opacity-[0.7]"
                            alt=""
                            src="/img/mra-dashboard/iconmessages.svg"
                        />
                        <img
                            className="hidden relative w-4 h-4 overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-dashboard/iconbelldingregular.svg"
                        />
                        <img
                            className="hidden relative w-4 h-4 overflow-hidden shrink-0 opacity-[0.7]"
                            alt=""
                            src="/img/mra-dashboard/iconcart.svg"
                        />
                        <button
                            className="bg-white rounded-21xl flex flex-row items-center justify-center gap-[8px]"
                        // onClick={logout}
                        // onClick={() => logoutAuth0({ logoutParams: { returnTo: window.location.origin } })}
                        >
                            {/* <!-- Defaults to round --> <Avatar avatarUrl={avatarUrl} /> */}
                            {/* <Avatar avatarUrl={avatarUrl} avatarType={AvatarTypeEnum.rounded} /> */}
                            {/* <Avatar avatarUrl={avatarUrl} avatarType={AvatarTypeEnum.round} /> */}
                            {/* <Avatar avatarUrl={avatarUrl} avatarType={AvatarTypeEnum.roundWithOnlineStatus} isUserOnline={false} /> */}
                            {/* <Avatar avatarUrl={avatarUrl} avatarType={AvatarTypeEnum.freePill} /> */}

                            <Avatar
                                avatarUrl={avatarUrl}
                                avatarType={avatarType}
                                isSocialGoogle={isSocialGoogle}
                            />
                        </button>
                    </div>
                </div>

            </div>
            <div className='absolute top-0 left-0 z-10 h-[64px] w-[1280px] bg-primary'></div>
        </>
    );
};

export default AuthenticatedNavBar;
