import { RefObject, forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';

// Editor is an uncontrolled React component
const RichTextEditor = forwardRef((props: any, ref: RefObject<HTMLDivElement> | any) => {
      
    const { readOnly, defaultValue, onTextChange, onSelectionChange, options, limit } = props;

      const containerRef = useRef(null);
      const defaultValueRef = useRef(defaultValue);
      const onTextChangeRef = useRef(onTextChange);
      const onSelectionChangeRef = useRef(onSelectionChange);
      const optionsRef = useRef(options);
      const limitRef = useRef(limit);

      const stripScripts = (htmlstr) => {
        var div = document.createElement('div');
        div.innerHTML = htmlstr;
        var scripts = div.getElementsByTagName('script');
        var i = scripts.length;
        while (i--) {
            if (scripts[i] && scripts[i].parentNode) {
                scripts[i].parentNode?.removeChild(scripts[i]);
            }
        }
        return div.innerHTML;
      };
      const stripURLs = (htmlstr) => {
        return htmlstr.replaceAll(/(?:https?|ftp):\/\/[\n\S]+/g, '');
      };
      const stripLoneNull = (htmlstr) => {
        console.log(htmlstr)
        if (htmlstr === '<p>null</p>') {
          return htmlstr.replace('<p>null</p>', '');
        }
        return htmlstr;
      };
  
      useLayoutEffect(() => {
        onTextChangeRef.current = onTextChange;
        onSelectionChangeRef.current = onSelectionChange;
      });
  
      useEffect(() => {
        ref.current?.enable(!readOnly);
      }, [ref, readOnly]);
  
      useEffect(() => {
        const container = containerRef.current as unknown as HTMLElement;
        const editorContainer = container.appendChild(
            container.ownerDocument.createElement('div'),
        );
        const quill = new Quill(editorContainer, optionsRef.current);
        const size: any = Quill.import('attributors/style/size');
            size.whitelist = ['14px', '16px', '18px'];

        Quill.register(size, true);
  
        ref.current = quill;
  
        quill.on(Quill.events.TEXT_CHANGE, (...args) => {
            onTextChangeRef.current?.(...args);

            const limit = limitRef.current;
            if (quill.getLength() > limit) {
                quill.deleteText(limit, quill.getLength());
            }

            const currHTML = quill.getSemanticHTML();
            
            /*
            // TODO: need to test more
            const scriptsFreeHTML = stripScripts(currHTML);
            const urlFreeHTML = stripURLs(scriptsFreeHTML);

            const cleanHTML = urlFreeHTML;
            */

            const cleanHTML = stripLoneNull(currHTML)

            if (currHTML !== cleanHTML) {
                quill.clipboard.dangerouslyPasteHTML(cleanHTML);
            }
            
        });
  
        quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
          onSelectionChangeRef.current?.(...args);
        });

        if (defaultValueRef.current) {
            quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);
            
            // Remove the first \n
            const delta = quill.getContents();
            const ins = delta.ops[0].insert as string;
            delta.ops[0].insert = ins.replace('\n', '');
            quill.setContents(delta);

        }
  
        return () => {
          ref.current = null;
          container.innerHTML = '';
        };
      }, [ref]);
  
      return <div className='w-full h-full' ref={containerRef}></div>;
    },
);
  
RichTextEditor.displayName = '';
  
export default RichTextEditor;

export { RichTextEditor };
