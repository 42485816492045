import { FunctionComponent, useEffect, useState } from "react";
import useZustandAuthStore from "../../_Zustand/auth-store";
import { ReferralDirectionEnum, TUsState } from "../../types";
import { useParams } from "react-router-dom";
import { DEFAULT_AVATAR_PATH } from "../AuthenticatedNavBar";
import { Page404 } from "../../components/Page404";
import { getReferralById } from "../../utils/api";
import { OutboundReferralTimeline } from "./OutboundReferralTimeline";
import { InboundReferralTimeline } from "./InboundReferralTimeline";
import { WaitSpinner } from "../../components/WaitSpinner";
import { WaitContainer } from "../../components/WaitContainer";

type TParams = Readonly<{
    referralId?: string;
}>;

const ReferralTimeline: FunctionComponent = () => {

    const { authState, usStates } = useZustandAuthStore();
    const usStatesList = usStates as Array<TUsState>;
    const stateOptions = Object.values(usStatesList).map((s) => {
        return { label: s.name, value: s.id, obj: s };
    });

    const defaultAvatar = DEFAULT_AVATAR_PATH;

    const params = useParams<TParams>();
    const referralId = +(params.referralId as string);

    const [isValidReferral, setIsValidReferral] = useState<boolean>(false);
    const [isFetchingReferral, setIsFetchingReferral] = useState<boolean>(false);
    const [referralDirection, setReferralDirection] = useState<ReferralDirectionEnum | null>(null);

    console.log("Rendering ReferralTimeline");

    const getReferral = (callback?): void => {

        setIsFetchingReferral(true);
        getReferralById(referralId)
        .then((referralInfo) => {
            setIsFetchingReferral(false);
            console.log(referralInfo);
            if (referralInfo.referral.id === referralId) {
                setIsValidReferral(true);
            }
            if (authState.id === referralInfo.referral.userId) {
                setReferralDirection(ReferralDirectionEnum.outbound)
            }
            else if (authState.id === referralInfo.referral.agentId) {
                setReferralDirection(ReferralDirectionEnum.inbound)
            }
            else {
                setIsValidReferral(false);
            }
            
        })
        .catch((error) => {
            setIsFetchingReferral(false);
            console.log(error);

            if (error.message.includes('Referral not found') || error.message.includes("You don't have permission to view user data")) {
                setIsValidReferral(false);
            }
            if (callback) {
                callback();
            }
        });
    }

    useEffect(() => {
        getReferral();
    }, []);

    return (
        <WaitContainer shouldWaitIf={isFetchingReferral} message="Loading Referral ... " waitSizePx={100}>
            {!isValidReferral
                ? <div className="w-full flex flex-row gap-[40px] justify-center">
                    <div className="w-[60%] mt-[5%] relative rounded-lg flex flex-col p-20 box-border items-start justify-start gap-[20px] text-base bg-light-background-background-1 shadow-xl">
                        <Page404 />
                    </div>
                </div>
                : <>
                {referralDirection && 
                    <>
                        {referralDirection === ReferralDirectionEnum.inbound
                            ? <InboundReferralTimeline referralId={referralId} />
                            : <OutboundReferralTimeline referralId={referralId} />
                        }
                    </>
                }
                </>
            }
        </WaitContainer>
    )
}

export { ReferralTimeline };
