import React, { FunctionComponent } from 'react';

const RegistrationNavBar: FunctionComponent = () => {

    const onLogoClick = (): void => {
        window.open('https://www.myreferralagent.com/', '_blank')?.focus();
    };

    return (
        <div className="relative z-20 w-full flex flex-row box-border items-center justify-center bg-primary text-light-background-background-1">

            <div className="z-10 w-[1280px] h-[60px] flex box-border items-center flex-row justify-center">

                {/* Name and Logo */}
                <div className="flex flex-row items-center justify-start gap-[8px] p-2 cursor-pointer" onClick={onLogoClick}>
                    <img
                        className="relative w-[49px] h-[31px] flex items-center justify-center"
                        alt=""
                        src="/img/mra-dashboard/vector3.svg"
                    />
                    <div className="relative text-white font-semibold">
                        MY REFERRAL AGENT
                    </div>
                </div>

            </div>
        </div>
    );
};

export default RegistrationNavBar;
