import React, { FunctionComponent } from "react";

const ReferralActivityDataKanban: FunctionComponent = () => {
    return (
        <div className="absolute top-[370px] left-[0px] w-[1280px] h-[1770px] text-left text-sm text-colors-gs-brand-primary font-large-med">
            <div className="absolute top-[82px] left-[0px] bg-gainsboro-100 w-[1263px] h-[1688px]" />
            <div className="absolute top-[82px] left-[13.36px] bg-gainsboro-100 w-[1249.18px] h-[82px]" />
            <div className="absolute top-[99px] left-[964px] flex flex-col items-start justify-start gap-[16px]">
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Danielle Kurek
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                120 Dorsett Oaks Cir, Austin, TX 78726
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Chris Henrick
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $443,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        August 5, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        26%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        RaghuRam Samudrala
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                42 Harcourt Dr, Austin, TX 78725
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Richard Gran
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $267,600
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        June 15, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        22%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Donna Southwell
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                720 Rustown Dr, Austin, TX 78740
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Shawn Finigan
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $534,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 3, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        24%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Steve Young
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                629 Sage Hen Cir, Austin, TX 78764
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Deseree Rowels
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $354,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        June 19, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        22%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Cherice Williams
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                2500 Pony Chase, Austin, TX 78750
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Susan Swain
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $489,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        August 3, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        25%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Daniel Sturdivant
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                1800 Alma Rd., Austin, TX 78840
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        David Palms
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $523,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 21, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $560,000
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Nathan Walker
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                1214 Willowbrook Dr., Cedar Park, TX 78613
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Jill Lindstrand
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $650,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick3.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 12, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $688,000
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[99px] left-[648px] flex flex-col items-start justify-start gap-[16px]">
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Sarah Hunt
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                1600 Wycliff Ln. Austin, TX 78727
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Michael Levi
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $487,600
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 21, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $24%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Lillian Kearsley
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                287 Brown Park Pl, Austin, TX 78755
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        David Palms
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $99,000,000 - $99,000,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        Sept 23, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        20%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Ina Saltz
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                821 Jennings Blvd., Austin, TX 78729
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Chris Henrick
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $671,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 12, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        26%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Donald Shannon
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                547 Idlewood Cove, Austin, TX 78753
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Richard Gran
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $456,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        June 23, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        24%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        David Spelling
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                5772 Treys Way, Austin, TX 78753
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Desiree Rowels
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $99,000,000 - $99,000,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        May 22, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        20%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[99px] left-[332px] flex flex-col items-start justify-start gap-[16px]">
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Maggie Dodge
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                200 Cologne Ln, Austin, TX 78664
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Deseree Rowels
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $459,800
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 3, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        22%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Todd Hanson
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                1685 Eton Ln, Austin, TX 78746
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Jane Smith
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $440,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        Sept 23, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        25%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Shawn Finnegan
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                905 Applegate Dr. Austin, TX 78753
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Teresa Cagle
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $99,000,000 - $99,000,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        August 2, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        20%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Julie Hansch
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                3911 Red Bud Ln Round Rock TX 78664
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Shawn Finigan
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $425,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 27, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        20%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Jennifer Holbrook
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                13301 Black Canyon Dr., Austin, TX 78729
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Jill Lindstrand
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $526,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 8, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        25%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Ben Meer
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                4025 Breezewood Dr., Austin, TX 78753
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Michael Levi
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $536,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 10, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        24%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Buyer
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 hidden flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Kelly Rader-Murphy
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                100 Sundance Ridge, Dripping Springs, TX 78230
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Desiree Rowels
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $646,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick3.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 19, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        23%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[99px] left-[16px] flex flex-col items-start justify-start gap-[16px]">
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Steve Blankenship
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                2750 Everest Ln, Austin, TX 78534
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Teresa Cagle
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $670,490
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        May 19, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        24%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Pamela Clements
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                315 Mosley Ln, Austin, TX 78230
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Jill Lindtrand
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $99,000,000 - $99,000,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        July 27, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        20%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Chris Adams
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                21 Cedardale Dr., Austin, TX 78753
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Shawn Finigan
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $456,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        June 26, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        26%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Stacy Davidson
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                3400 Blythwood Dr., Austin, TX 78753
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Teresa Cagle
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $423,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        May 26, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        20%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Candy Duncan
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                720 Garden Oaks Dr., Austin, TX 78753
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        David Palms
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $552,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        June 20, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        21%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Matthew Feldman
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                185 Sundown Pkwy, Austin, TX 78746
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Michael Levi
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $645,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick2.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        May 18, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        22%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-[0px_2px_4px_rgba(0,_0,_0,_0.14),_0px_0px_2px_rgba(0,_0,_0,_0.12)] flex flex-col items-start justify-start py-0 pr-3 pl-0">
                    <div className="rounded-md bg-colors-gs-white w-[281px] flex flex-col items-start justify-start p-3.5 box-border gap-[10px]">
                        <div className="self-stretch flex flex-col items-start justify-start">
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[5px]">
                                <div className="flex-1 flex flex-col items-start justify-center">
                                    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2">
                                        <div className="flex flex-row items-start justify-start gap-[6px]">
                                            <img
                                                className="relative w-4 h-4"
                                                alt=""
                                                src="/img/mra-dashboard-activity/referraltypeicon.svg"
                                            />
                                            <div className="relative leading-[16px] font-black">
                                                Seller
                                            </div>
                                        </div>
                                        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 pr-[18px] pl-0 text-smi text-brand-red">
                                            <div className="relative font-semibold">
                                                Not yet accepted
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-stretch relative text-smi font-semibold">
                                        Matthew Feldman
                                    </div>
                                </div>
                                <img
                                    className="relative w-8 h-8"
                                    alt=""
                                    src="/img/mra-dashboard-activity/btnsecondary.svg"
                                />
                            </div>
                            <div className="self-stretch relative text-xs leading-[17px]">
                                185 Sundown Pkwy, Austin, TX 78746
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-2xs">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Referring Agent
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-link-blue">
                                        Michael Levi
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Budget
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        $645,000
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Pre-Approved
                                    </div>
                                    <img
                                        className="relative w-4 h-4 overflow-hidden shrink-0"
                                        alt=""
                                        src="/img/mra-dashboard-activity/iconcheckthick3.svg"
                                    />
                                </div>
                            </div>
                            <div className="self-stretch w-[90px] flex flex-col items-start justify-start gap-[8px]">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Submitted on
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        May 18, 2023
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Timeframe
                                    </div>
                                    <div className="self-stretch relative text-xs leading-[17px] text-gray-800">
                                        30-90 days
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch relative tracking-[0.26px] leading-[18px] font-medium">
                                        Fee Percentage
                                    </div>
                                    <div className="relative leading-[17px] text-gray-800">
                                        26%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[0px] left-[960px] w-80 h-[83px] text-base">
                <img
                    className="absolute h-full w-full top-[0%] right-[-0.39%] bottom-[0%] left-[0.39%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/img/mra-dashboard-activity/bg1.svg"
                />
                <div className="absolute top-[calc(50%_-_28px)] left-[16px] w-[266px] flex flex-row items-center justify-start py-0 pr-0 pl-3 box-border">
                    <div className="flex-1 flex flex-row items-center justify-start">
                        <div className="flex flex-col items-start justify-start gap-[6px]">
                            <div className="relative leading-[24px]">
                                <span className="tracking-[0.1px] font-semibold">
                                    Closed
                                </span>
                                <span className="tracking-[0.2px] font-medium">
                                    {" "}
                                    (32)
                                </span>
                            </div>
                            <div className="relative text-xl tracking-[-0.24px] leading-[26px] font-semibold">
                                $124,060*
                            </div>
                        </div>
                        <div className="self-stretch flex-1 flex flex-col items-end justify-start py-0 pr-1 pl-0 text-sm text-brand-red">
                            <div className="relative leading-[21px]">
                                View only
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[0px] left-[640px] w-80 h-[82.53px] text-base">
                <img
                    className="absolute h-full w-full top-[0%] right-[-0.39%] bottom-[0%] left-[0.39%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/img/mra-dashboard-activity/bg2.svg"
                />
                <div className="absolute top-[calc(50%_-_29.26px)] left-[16px] w-[266px] flex flex-row items-center justify-start py-0 pr-0 pl-3 box-border">
                    <div className="flex-1 flex flex-row items-center justify-start">
                        <div className="flex flex-col items-start justify-start gap-[6px]">
                            <div className="relative">
                                <span className="tracking-[0.1px] leading-[24px] font-semibold">
                                    Under Contract
                                </span>
                                <span className="font-medium">
                                    <span className="text-lg leading-[140%]">{` `}</span>
                                    <span className="tracking-[0.2px] leading-[24px]">
                                        (5)
                                    </span>
                                </span>
                            </div>
                            <div className="relative text-xl tracking-[-0.24px] leading-[26px] font-semibold">
                                $16,517*
                            </div>
                        </div>
                        <div className="self-stretch flex-1 flex flex-col items-end justify-start py-0 pr-1 pl-0 text-sm text-brand-red">
                            <div className="relative leading-[21px]">
                                View only
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[0px] left-[320px] w-80 h-[82.53px] text-base">
                <img
                    className="absolute h-full w-full top-[0%] right-[-0.39%] bottom-[0%] left-[0.39%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/img/mra-dashboard-activity/bg2.svg"
                />
                <div className="absolute top-[calc(50%_-_29.26px)] left-[16px] w-[266px] flex flex-row items-center justify-start py-0 pr-0 pl-3 box-border">
                    <div className="flex-1 flex flex-row items-center justify-start">
                        <div className="flex flex-col items-start justify-start gap-[6px]">
                            <div className="relative">
                                <span className="tracking-[0.1px] leading-[24px] font-semibold">
                                    Active
                                </span>
                                <span className="font-medium">
                                    <span className="text-lg leading-[140%]">{` `}</span>
                                    <span className="tracking-[0.2px] leading-[24px]">
                                        (7)
                                    </span>
                                </span>
                            </div>
                            <div className="relative text-xl tracking-[-0.24px] leading-[26px] font-semibold">
                                $22,410*
                            </div>
                        </div>
                        <div className="self-stretch flex-1 flex flex-col items-end justify-start py-0 pr-1 pl-0 text-sm text-brand-red">
                            <div className="relative leading-[21px]">
                                View only
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[0px] left-[0px] w-80 h-[82.53px] text-base">
                <img
                    className="absolute h-full w-full top-[0%] right-[-0.39%] bottom-[0%] left-[0.39%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/img/mra-dashboard-activity/bg2.svg"
                />
                <div className="absolute top-[calc(50%_-_28.76px)] left-[16px] w-[266px] flex flex-row items-center justify-start py-0 pr-0 pl-3 box-border">
                    <div className="flex-1 flex flex-row items-center justify-start">
                        <div className="flex flex-col items-start justify-start gap-[6px]">
                            <div className="relative leading-[24px]">
                                <span className="tracking-[0.1px] font-semibold">
                                    Opportunities
                                </span>
                                <span className="tracking-[0.2px] font-medium">
                                    {" "}
                                    (15)
                                </span>
                            </div>
                            <div className="relative text-xl tracking-[-0.24px] leading-[26px] font-semibold">
                                $220,065*
                            </div>
                        </div>
                        <div className="self-stretch flex-1 flex flex-col items-end justify-start py-0 pr-1 pl-0 text-sm text-brand-red">
                            <div className="relative leading-[21px]">
                                View only
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferralActivityDataKanban;
