import React, { useEffect } from "react";
import { ConfirmProvider } from "material-ui-confirm";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import { Env, LogLevel, SkyflowElements } from "skyflow-react-js";
import { Auth } from "./Auth.";
import { TEmptyObject } from "./utils/sierra";
import { getSkyflowBearerToken } from "./utils/api";
import { getIsProduction } from "./utils/get-is-production";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { datadogLogs } from '@datadog/browser-logs'

export const auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE;

const App: React.FunctionComponent<TEmptyObject> = () => {

    const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
    const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

    const vaultID = import.meta.env.VITE_SKYFLOW_VAULT_ID;
    const vaultURL = import.meta.env.VITE_SKYFLOW_VAULT_URL;

    const skyflowConfig = {
        vaultID,
        vaultURL,
        getBearerToken: getSkyflowBearerToken,
        options: {
            logLevel: getIsProduction() ? LogLevel.ERROR : LogLevel.DEBUG,
            env: getIsProduction() ? Env.PROD : Env.DEV,
        },
    };
    
    const sentryMonitoredUrls = [
        window.location.origin,
        "http://localhost:8081",
        "http://localhost:8086",
        "https://future-webapi.myreferralagent.com",
        "https://future-napi.myreferralagent.com",
        "https://webapi.myreferralagent.com",
        "https://napi.myreferralagent.com",
    ];

    const sentryConf = {
        dsn: "https://fb99ecdff11b1a92288386fd6427592b@o4506651206352896.ingest.sentry.io/4506651207467008",
        maxValueLength: 10000,
        integrations: [
            new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
                tracingOrigins: sentryMonitoredUrls,
            }),
            Sentry.replayIntegration({
                networkDetailAllowUrls: sentryMonitoredUrls,
                maskAllText: false,
                blockAllMedia: false,
                beforeAddRecordingEvent: (event) => {
                    // Do not capture fetch/xhr requests, unless the response code is 500
                    if (
                        event.data.tag === "performanceSpan" &&
                        (event.data.payload.op === "resource.fetch" ||
                        event.data.payload.op === "resource.xhr") &&
                        event.data.payload.data?.statusCode !== 500
                    ) {
                        return null;
                    }
                    
                    return event;
                },
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        tracePropagationTargets: sentryMonitoredUrls,
        environment: import.meta.env.VITE_APP_ENV,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            
    };

    if (getIsProduction()) {
        sentryConf["release"] = `v1_13`;
        sentryConf["replaysSessionSampleRate"] = 0.1;
        
        window.console.log = () => {};
        window.console.info = () => {};
        window.console.debug = () => {};
    }
    else {
        sentryConf["replaysSessionSampleRate"] = 1.0;
    }

    // sentry errors only in staging and production
    if (import.meta.env.VITE_APP_ENV !== 'development') {
        Sentry.init(sentryConf);
        
        const originalErrorConsole = window.console.error;
        window.console.error = (...args) => {

            originalErrorConsole.apply(args);
            
            if (args[0] instanceof Error) {
                Sentry.captureException(args[0]);
            }
            else if (args[0].message) {
                Sentry.captureException(new Error(args[0].message));
            }
            else if (typeof args[0] === 'string') {
                Sentry.captureException(new Error(args[0]));
            }
            else {
                Sentry.captureException.apply(args);
            }
        }
    }
    
    // datadog logs only in production
    if (import.meta.env.VITE_APP_ENV === 'production')
    {
        datadogLogs.init({
            clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
            site: 'us5.datadoghq.com',
            service: 'REACT_UI',
            env: import.meta.env.VITE_APP_ENV,
            forwardErrorsToLogs: false,
            sessionSampleRate: 100,
        });
    }

    useEffect(() => {
        console.log(import.meta.env);
        console.log("...");
    }, [])

    return (
        <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            authorizationParams={{
                redirect_uri: `${window.location.origin}/auth`,
                audience: auth0Audience,
                prompt: "select_account",
            }}
            cacheLocation="localstorage"
            useRefreshTokens={true}
        >
            <ConfirmProvider>
                <ToastContainer pauseOnFocusLoss={false} />
                <SkyflowElements config={skyflowConfig}>
                    <Auth />
                </SkyflowElements>
            </ConfirmProvider>
        </Auth0Provider>
    );
};

export { App };
