import { ReferralType } from "../../types";
import type {NewReferralFormStoreType, NonMraCandidate } from "./NewReferralOutbound";

// eslint-disable-next-line import/no-cycle
import { CandidateTypeEnum } from "./NewReferralOutbound";

export const initialReferralFormData = (): NewReferralFormStoreType => { return Object.create({
    form: {
        isInbound: false,
        source: {
            clientSource: -1,
            lastInteracted: -1,
            completed: false,
        },
        client: {
            clientFirstName: "",
            clientLastName: "",
            clientPhone: "",
            clientEmail: "",
            clientPreApproved: false,
            completed: false,
        },
        referralType: {
            selectedAtLeastOnce: false,
            type: ReferralType.buy,
            buyer: {
                locations: [],
                propertyTypes: [],
                timeFrame: -1,
                minPrice: -1,
                maxPrice: -1,
                comments: "",
            },
            seller: {
                address: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: -1,
                    zipcode: -1,
                },
                propertyType: -1,
                targetPrice: -1,
                comments: ""
            },
            completed: false,
        },
        agreement: {
            autogenerated: true,
            feePercent: 25,
            completed: false,
        },
        candidateSelection: {
            candidateType: CandidateTypeEnum.SEARCH_MRA_MEMBERS,
            searchCandidates: [],
            nonMraCandidates: [{} as NonMraCandidate],
            completed: false,
        },
        newReferralId: -1,
    }
})};