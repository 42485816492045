import { fetch, fetchNApi } from './fetch';

export const get = <T = unknown>(
    url: string,
    init: RequestInit | null = null,
    isAnon: boolean = false,
): Promise<T> => fetch<T>(url, { method: 'GET', ...init }, isAnon);

export const getNApi = <T = unknown>(
    url: string,
    init: RequestInit | null = null,
    isAnon: boolean = false,
): Promise<T> => fetchNApi<T>(url, { method: 'GET', ...init }, isAnon);
