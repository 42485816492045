import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { SubscriptionTypeEnum } from './AgentProfile';


type Props = {
    upgradeTo: (plan: SubscriptionTypeEnum) => void,
};

const ProductPlans: FunctionComponent<Props> = ({ upgradeTo }) => {

    return <div className="w-full relative bg-colors-gs-surface-primary overflow-hidden flex flex-col items-center justify-start gap-[50px] text-center text-[40px] text-colors-gs-brand-primary font-h5">

            <div className="self-stretch flex flex-col items-center justify-start pt-9 px-0 pb-0 gap-[16px]">
                <b className="self-stretch relative tracking-[-1.57px] leading-[57px]">
                    Why go Pro?
                </b>
                <div className="self-stretch relative text-[23px] tracking-[-0.29px] leading-[30px]">
                    Compare plans and features
                </div>
            </div>

            {/* <div className="self-stretch rounded-3xl bg-colors-gs-white flex flex-col items-center justify-start pt-10 px-10 text-left text-base">
                <iframe src="https://www-myreferralagent.webflow.io/features" />
            </div> */}

            <div className="self-stretch rounded-3xl bg-colors-gs-white flex flex-col items-center justify-start pt-10 px-10 text-left text-base">
                
                <div className="self-stretch rounded-t-3xl rounded-b-none bg-colors-gs-white flex flex-row items-start justify-start px-[23px] gap-[60px] text-center text-13xl">
                    <div className="self-stretch w-[360px] flex flex-col items-start justify-end text-left text-lg">
                        <div className="relative tracking-[-0.24px] leading-[26px] font-semibold">
                            Feature List
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <div className="relative tracking-[-0.57px] leading-[46px] font-extrabold">
                            Basic
                        </div>
                        <div className="relative text-lg tracking-[-0.29px] leading-[30px] font-semibold">
                            Your plan
                        </div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <div className="relative tracking-[-0.57px] leading-[46px] font-extrabold">
                            Pro
                        </div>
                        <div className="relative text-lg tracking-[-0.29px] leading-[30px] font-semibold text-colors-gs-brand-red">
                            Most Popular
                        </div>
                    </div>
                </div>

                <div className="self-stretch rounded-t-3xl rounded-b-none bg-colors-gs-white flex flex-row items-start justify-start px-[23px] pb-[10px] gap-[60px] text-center text-13xl">
                    <div className="self-stretch w-[360px] flex flex-col items-start justify-end text-left text-lg">                        
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">                        
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-center">

                        <div
                            className="self-stretch bg-colors-gs-white flex flex-row items-center justify-center cursor-pointer py-[10px]"
                            onClick={() => upgradeTo(SubscriptionTypeEnum.PROFESSIONAL)}
                        >
                            <div className="self-stretch flex flex-col items-center justify-center">
                                <div
                                    className="cursor-pointer [border:none] py-0 bg-brand-red rounded-[33.75px] w-[180px] h-[54px] flex flex-row items-center justify-center box-border"
                                    autoFocus={false}
                                >
                                    <img
                                        className="w-[21.67px] relative h-[21.67px] overflow-hidden shrink-0 hidden"
                                        alt=""
                                        src="/img/mra-product-plans/iconeditsolid.svg"
                                    />
                                    <b className="relative text-[20.25px] tracking-[0.22px] leading-[25.31px] font-h5 text-colors-gs-white text-center">
                                        Buy Pro
                                    </b>
                                    <img
                                        className="w-[21.67px] relative h-[21.67px] overflow-hidden shrink-0 hidden"
                                        alt=""
                                        src="/img/mra-product-plans/iconeditsolid.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Receive referrals
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            Unlimited
                        </div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            Unlimited
                        </div>
                    </div>
                </div>

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Support
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        {/* <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        /> */}
                        Basic
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        {/* <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        /> */}
                        Premium
                    </div>
                </div>

                

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Publicly available profile
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        {/* <div className="relative tracking-[-0.24px] leading-[28px]">•</div> */}
                        Limited
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Send referrals
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            5
                        </div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            Unlimited
                        </div>
                    </div>
                </div>
                

                {/* <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Send to multiple agents at a time
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            2
                        </div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            5
                        </div>
                    </div>
                </div> */}

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Cities
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            3
                        </div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            15
                        </div>
                    </div>
                </div>
                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Zip codes
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            5
                        </div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <div className="relative tracking-[0.2px] leading-[24px] font-medium">
                            30
                        </div>
                    </div>
                </div>
                
                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            MRA reviews
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Digital signing for Referral Agreements
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">
                            <img
                                className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                                alt=""
                                src="/img/mra-product-plans/iconcheckthick.svg"
                            />
                        </div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>
                
                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Designations verification
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>

                {/*
                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            MRA verified badge
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>
                
                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Invitation links
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>
                */}

                {/* <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Showcase social media links
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div> */}

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Showcase 5 media pieces (including videos)
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>
                
                {/* <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Showcase production history
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div> */}

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Showcase designations
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>
                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Showcase areas of specialization
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>
                
                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Showcase up to 6 external websites
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>

                <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[23px] gap-[60px] border-[0.5px] border-solid border-colors-gs-border">
                    <div className="w-[360px] flex flex-col items-start justify-start">
                        <div className="relative tracking-[0.1px] leading-[24px]">
                            Showcase Google reviews
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start text-xl text-colors-gray-500">
                        <div className="relative tracking-[-0.24px] leading-[28px]">•</div>
                    </div>
                    <div className="w-[180px] flex flex-col items-center justify-start">
                        <img
                            className="w-[26px] relative h-[26px] overflow-hidden shrink-0"
                            alt=""
                            src="/img/mra-product-plans/iconcheckthick.svg"
                        />
                    </div>
                </div>

                
                <div
                    className="self-stretch bg-colors-gs-white flex flex-row items-center justify-start pt-5 px-[23px] pb-10 gap-[60px] cursor-pointer"
                    onClick={() => upgradeTo(SubscriptionTypeEnum.PROFESSIONAL)}
                >
                    <div className="w-[360px]" />
                    <div className="self-stretch flex-1" />

                    <div className="self-stretch flex flex-col items-start justify-start">
                        <div
                            className="cursor-pointer [border:none] py-0 px-[27px] bg-brand-red w-[180px] rounded-[33.75px] h-[54px] flex flex-row items-center justify-center box-border gap-[13.5px]"
                            autoFocus={false}
                        >
                            <img
                                className="w-[21.67px] relative h-[21.67px] overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-product-plans/iconeditsolid.svg"
                            />
                            <b className="relative text-[20.25px] tracking-[0.22px] leading-[25.31px] font-h5 text-colors-gs-white text-center">
                                Buy Pro
                            </b>
                            <img
                                className="w-[21.67px] relative h-[21.67px] overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/img/mra-product-plans/iconeditsolid.svg"
                            />
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
}

export { ProductPlans }