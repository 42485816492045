export const instagramUrlPattern = /^(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
export const facebookUrlPattern = /^(?:(?:http|https):\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i;
export const xUrlPattern = /^(?:(?:http|https):\/\/)?((w{3}\.)?)x|twitter\.com\/(#!\/)?[a-z0-9_]+$/i;
export const linkedinUrlPattern = /^(?:(?:http|https):\/\/)?((w{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i;
export const youtubeVideoUrlPattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/i;
export const youtubeChannelUrlPattern = /^https?:\/\/(www\.)?youtube\.com\/(channel\/UC[\w-]{21}[AQgw]|(c\/|user\/)?[\w-]+)$/i;
export const tiktokUrlPattern = /^https?:\/\/(?:m|www|vm)?\.?tiktok\.com\/((?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|\w+)/i;

export const isInstagramUrlValid = (url): boolean => {
    // return instagramUrlPattern.test(url);
    return true;
};
// window["isInstagramUrlValid"] = isInstagramUrlValid;

export const isFacebookUrlValid = (url): boolean => {
    // return facebookUrlPattern.test(url);
    return true;
};
// window["isFacebookUrlValid"] = isFacebookUrlValid;

export const isXUrlValid = (url): boolean => {
    // return xUrlPattern.test(url);
    return true;
};
// window["isXUrlValid"] = isXUrlValid;

export const isYoutubeChannelUrlValid = (url): boolean => {
    // return youtubeChannelUrlPattern.test(url);
    return true;
};
// window["isYoutubeChannelUrlValid"] = isYoutubeChannelUrlValid;

export const isLinkedInUrlValid = (url): boolean => {
    // return linkedinUrlPattern.test(url);
    return true;
};
// window["isLinkedInUrlValid"] = isLinkedInUrlValid;

export const isTiktokUrlValid = (url): boolean => {
    // return tiktokUrlPattern.test(url);
    return true;
};
// window["isLinkedInUrlValid"] = isLinkedInUrlValid;

export const isYoutubeUrlValid = (url): boolean => {
    // return youtubeVideoUrlPattern.test(url);
    return true;
};

export const getValidYoutubeId = (url): string => {
    const matches = url.match(youtubeVideoUrlPattern);
    if (!matches) {
        return '';
    }
    return matches[1];
};
