import React, { FunctionComponent } from "react";

type Props = {
    sizePx?: number;
}
const WarningYellowIcon: FunctionComponent<Props> = ({sizePx = 16}) => {
    return <img src="/img/warning-yellow.svg" alt='Warning' width={sizePx} />;
};

export default WarningYellowIcon;
