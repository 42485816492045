import React, { FunctionComponent, RefObject, useState } from "react";
import { ReferralType } from "../../../types";
// eslint-disable-next-line import/no-cycle
import Step3ReferralTypeBuyer from "./Step3aReferralTypeBuyer";
import Step3bReferralTypeSeller from "./Step3bReferralTypeSeller";
import type { LocationType, NewReferralFormStoreType } from "../NewReferralOutbound";

type Step3PropsType = {
    
    formDataRef: RefObject<NewReferralFormStoreType>;
    onChangeReferralType: (any) => void;

    buyerLocations: Array<LocationType>;
    buyerLocationsReadonly: boolean;
    onLocationsUpdated: (locations: Array<LocationType>) => void;
    onChangeBuyerPropertyType: (any) => void;
    onChangeBuyerTimeFrame: (any) => void;
    onChangeBuyerMinPrice: (number) => void;
    onChangeBuyerMaxPrice: (number) => void;
    onChangeBuyerComments: (any) => void;
    onChangeClientPreApproved: (any) => void;

    onChangeSellerAddressLine1: (any) => void;
    onChangeSellerAddressLine2: (any) => void;
    onChangeSellerCity: (any) => void;
    onChangeSellerState: (any) => void;
    onChangeSellerZipCode: (any) => void;
    onChangeSellerPropertyType: (any) => void;
    onChangeSellerTargetPrice: (number) => void;
    onChangeSellerTimeFrame: (any) => void;
    onChangeSellerComments: (any) => void;

    refStep3: (RefObject<HTMLFormElement>);
};

const Step3ReferralType: FunctionComponent<Step3PropsType> = ({
    formDataRef,
    onChangeReferralType,

    buyerLocations,
    buyerLocationsReadonly,
    onLocationsUpdated,
    onChangeBuyerPropertyType,
    onChangeBuyerTimeFrame,
    onChangeBuyerMinPrice,
    onChangeBuyerMaxPrice,
    onChangeBuyerComments,
    onChangeClientPreApproved,

    onChangeSellerAddressLine1,
    onChangeSellerAddressLine2,
    onChangeSellerCity,
    onChangeSellerState,
    onChangeSellerZipCode,
    onChangeSellerPropertyType,
    onChangeSellerTargetPrice,
    onChangeSellerTimeFrame,
    onChangeSellerComments,

    refStep3,
}) => {

    // logger.info("\tRendering: Step3");
    const formDataStep = formDataRef.current?.form.referralType;
    console.log("Step3: FormDataStep: ", formDataStep);

    const [referralType, setReferralType] = useState<ReferralType | null>(formDataStep?.selectedAtLeastOnce ? formDataStep?.type ?? null : null);

    const updateReferralType = (event): void => {
        const { value } = event.target;
        const rt = (value === ReferralType.buy.toString()) ? ReferralType.buy : ReferralType.sell;
        setReferralType(rt);
        onChangeReferralType(event);
    }

    return (
        <form ref={refStep3} role="presentation" autoComplete="off">
            <div className="self-stretch flex flex-col p-5 items-start justify-start text-base">
                <div className="self-stretch text-xl font-semibold">
                    What type of Referral is this?*
                </div>

                
                <div className="w-full flex flex-row items-start justify-start gap-[20px] py-5">

                    <div className="w-fit">
                        <div className="self-stretch flex flex-row items-center justify-start">
                            <label className="relative cursor-pointer flex flex-row items-center leading-[24px] gap-[8px]">
                                <input
                                    className="cursor-pointer w-4 h-4"
                                    type="radio"
                                    value={ReferralType.buy}
                                    name="referralType"
                                    onClick={updateReferralType}
                                    defaultChecked={formDataStep?.selectedAtLeastOnce && formDataStep?.type === ReferralType.buy}
                                    required
                                />
                                Buyer
                            </label>
                        </div>
                    </div>

                    <div className="border border-black">
                        <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
                            <label className="relative cursor-pointer flex flex-row items-center leading-[24px] gap-[8px]">
                                <input
                                    className="relative cursor-pointer w-4 h-4"
                                    type="radio"
                                    value={ReferralType.sell}
                                    name="referralType"
                                    onClick={updateReferralType}
                                    defaultChecked={formDataStep?.selectedAtLeastOnce && formDataStep?.type === ReferralType.sell}
                                />
                                Seller
                            </label>
                        </div>
                    </div>
                </div>

                {referralType === ReferralType.buy &&
                    <Step3ReferralTypeBuyer
                        formDataRef={formDataRef}
                        buyerLocations={buyerLocations}
                        buyerLocationsReadOnly={buyerLocationsReadonly}
                        onLocationsUpdated={onLocationsUpdated}
                        onChangeBuyerPropertyType={onChangeBuyerPropertyType}
                        onChangeBuyerTimeFrame={onChangeBuyerTimeFrame}
                        onChangeBuyerMinPrice={onChangeBuyerMinPrice}
                        onChangeBuyerMaxPrice={onChangeBuyerMaxPrice}
                        onChangeBuyerComments={onChangeBuyerComments}
                        onChangeClientPreApproved={onChangeClientPreApproved}
                    />
                }

                {referralType === ReferralType.sell &&
                    <Step3bReferralTypeSeller
                        formDataRef={formDataRef}
                        onChangeSellerAddressLine1={onChangeSellerAddressLine1}
                        onChangeSellerAddressLine2={onChangeSellerAddressLine2}
                        onChangeSellerCity={onChangeSellerCity}
                        onChangeSellerState={onChangeSellerState}
                        onChangeSellerZipCode={onChangeSellerZipCode}
                        onChangeSellerPropertyType={onChangeSellerPropertyType}
                        onChangeSellerTargetPrice={onChangeSellerTargetPrice}
                        onChangeSellerTimeFrame={onChangeSellerTimeFrame}
                        onChangeSellerComments={onChangeSellerComments}
                    />
                }
            </div>
        </form>
    );
};

export default Step3ReferralType;
