import React, { FunctionComponent } from "react";

type IconStatusType = {
    isOnline: boolean;
};

const IconStatus: FunctionComponent<IconStatusType> = ({
    isOnline = false,
}) => {
    return isOnline ? (
        <img
            className="absolute h-[30%] w-[30%] bottom-0 right-0"
            alt=""
            src="img/mra-dashboard/statusonline.svg"
        />
    ) : (
        <img
            className="absolute h-[30%] w-[30%] bottom-0 right-0"
            alt=""
            src="img/mra-dashboard/statusoffline.svg"
        />
    );
};

export default IconStatus;
