import React, { FunctionComponent } from "react";
import AsyncSelect from 'react-select/async';
import { StylesConfig } from "react-select";
import { globalReactSelectTheme } from "../../constants/react-select-theme";

type Props = {
    onPlaceSelected: (option: any) => void;
    placeholder?: string;
}

const GooglePlaceLookup: FunctionComponent<Props> = ({ onPlaceSelected, placeholder }) => {
    const googlePlacesAutocompleteService = new google.maps.places.AutocompleteService();    

    const loadOptions = (inputValue: string, updateOptionsCallback: (options: any) => void): void => {
        const request = {
            input: inputValue,
            componentRestrictions: {
                country: 'us',
            },
        };

        googlePlacesAutocompleteService.getPlacePredictions(request, (
            predictions: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
        ) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
                return;
            }

            console.log(status);
            console.log(predictions);

            if(updateOptionsCallback) {
                const options = predictions
                                .map((p) => {                    
                                        return {
                                            label: p.description,
                                            value: p.place_id,
                                            place: p
                                        };
                                });
                updateOptionsCallback(options);
            }
        });
    };


    const colourStyles: StylesConfig<any, true> = {
        control: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            padding: '5px',
            boxShadow: '0px 0px 3px 1px rgba(128, 128, 128, 0.3)',
            border: 'none',
            ':focus': {
                border: 'none',
            }
        }),
        option: (styles) => ({
            ...styles,
            margin: 'none',
            backgroundColor: 'white',
            color: 'black',
            ':hover': {
                backgroundColor: `#eeeff3`,
                cursor: 'pointer',
            },
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            color: data.color,
            backgroundColor: '#E6F5FE',
            border: '1px solid #78AFD5',
            borderRadius: '4px',
            marginRight: '10px'
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            cursor: 'pointer',
            ':hover': {
                backgroundColor: data.color,
                // border: '1px solid #8af',
                color: 'red',
            },
        }),
    };

    return (
        <div className="flex w-full min-w-[300px]">
            <AsyncSelect
                loadOptions={loadOptions}
                className="basic-multi-select w-full"
                placeholder={placeholder || "Search Google Maps"}
                onChange={onPlaceSelected}
                theme={globalReactSelectTheme}
                styles={colourStyles}
                required
            />
        </div>
    );
}

export default GooglePlaceLookup;
