import { StylesConfig } from "react-select";

// export const APPLICATION_FIRST_DATE_EPOCH_MS: number = new Date(2023, 10, 31).getTime();
export const APPLICATION_FIRST_DATE_EPOCH_MS: number = new Date(2024, 0, 18).getTime(); // 1705564800000

export const FREE_CITIES_LIMIT = 3;
export const PAID_CITIES_LIMIT = 15;

export const FREE_ZIPCODES_LIMIT = 5;
export const PAID_ZIPCODES_LIMIT = 30;

export const MAX_MISC_WEBSITES = 6;
export const MAX_GALLERY_ITEMS = 5;

export const DEFAULT_BANNER_ID = 'mra-site/odglip7nkp0vl7fblp8i';
    

export enum RoleEnum {
    ADMINISTRATOR = 'Administrator',
    AGENT = "Agent",
}

export const reactSelectColorStyles: StylesConfig<any, true> = {
    container: (styles) => ({
        ...styles,
        width: '100%'
    }),
    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        padding: '5px',
        'box-shadow': '0px 0px 3px 1px rgba(128, 128, 128, 0.3)',
        border: 'none',
        ':focus': {
            border: 'none',
        }
    }),
    option: (styles) => ({
        ...styles,
        margin: 'none',
        backgroundColor: 'white',
        ':hover': {
            backgroundColor: `#eeeff3`,
            cursor: 'pointer',
        },
    }),
    multiValue: (styles, { data }) => ({
        ...styles,
        color: data.color,
        backgroundColor: '#E6F5FE',
        border: '1px solid #78AFD5',
        borderRadius: '4px',
        marginRight: '10px'
    }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: data.color,
            // border: '1px solid #8af',
            color: 'red',
        },
    }),
};
