
export const createBody = (data: unknown): FormData | string | null => {
    if (!data) {
        return null;
    }
    if (data instanceof FormData) {
        return data;
    }
    if (typeof data === 'string') {
        return data;
    }
    return JSON.stringify(data);
};
