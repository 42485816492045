import React, { FunctionComponent, useEffect, useState } from 'react';
import CollectContainer from 'skyflow-js/types/core/external/collect/collect-container';
import { FileInputElement, useCollectContainer } from 'skyflow-react-js';
import { ICollectElementOptions } from 'skyflow-react-js/lib/elements';

type Props = {
    id: string;
    table: string;
    column: string;
    skyflowID: string;
    classes?: Record<string, unknown>;
    onW9Container: (w9Container) => void;
    onReady: (e: any) => void;
    onChange: (e: any) => void;
    onBlur: (e: any) => void;
}

const W9FileUploader: FunctionComponent<Props> = ({id, table, column, skyflowID, classes, onW9Container, onReady, onChange, onBlur}) => {

    const w9Container: CollectContainer = useCollectContainer();    
    const options: ICollectElementOptions = {
        preserveFileName: true,
        allowedFileType: [".pdf", ".png", ".jpg", ".jpeg"],
        required: true,
    }
    
    useEffect(() => {
        if (w9Container) {
            onW9Container(w9Container);    
        }
    }, [w9Container]);
    
    return <>
        {w9Container &&
            <FileInputElement
                id={id}
                container={w9Container}
                table={table}
                column={column}
                skyflowID={skyflowID}
                classes={classes}
                options={options}
                onReady={onReady}
                onChange={onChange}
                onBlur={onBlur}
                errorText=''
            />
        }
    </>
};

export { W9FileUploader };
